import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import CardMedia from '@material-ui/core/CardMedia';
import {
  ASYNC_STORAGE_KEYS,
  IMAGE_SK,
  USER_SK,
  VIDEO_SK,
} from '../../constants';
import { Fade } from '@mui/material';
import { AlertDialog, MediaViewer } from '..';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from '../../i18n';
import { isNonEmptyString } from '../../utils';
import IconNext from '../../assets/images/icon_next_media.png';
import IconBack from '../../assets/images/icon_back_media.png';
import styles from './UserMediaGrid.module.scss';

const loggedInUserIdentifier = localStorage.getItem(
  ASYNC_STORAGE_KEYS.userIdentifier
);

const UserMediaGrid = ({
  media,
  userDetails,
  baseMediaUrl,
  baseMediaThumbUrl,
  history,
}) => {
  const [showCaption, setShowCaption] = useState(false);
  const [controls, setControls] = useState(false);
  const [mediaViewerState, setMediaViewerState] = useState({
    isOpen: false,
    selected: 0,
  });
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const isMulti = media.length > 1 ? true : false;

  const profileImgUrl = userDetails?.profileImg.isThumbGenerated
    ? userDetails?.profileImg?.url?.thumbUrl
    : userDetails?.profileImg?.url?.originalUrl;

  const viewPhoto = (selected, type) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }
    setMediaViewerState({
      isOpen: true,
      selected,
    });
  };

  const showLoginPrompt = () => {
    let data = {
      title: translate('common.loginPromtDialogTitle'),
      description: translate('common.loginPromtDialogMessage'),
      positiveButtonCallback: () => {
        history.push('register-login');
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const closeMediaViewer = () => {
    setMediaViewerState({
      isOpen: false,
      selected: 0,
    });
  };

  return (
    <>
      <Slide
        canSwipe={isMulti ? true : false}
        className={isMulti ? styles['pli-media-grid'] : ''}
        arrows={isMulti}
        indicators={isMulti}
        autoplay={false}
        transitionDuration={200}
        prevArrow={
          <img src={IconBack} alt="backIcon" id={styles['backIcon']} />
        }
        nextArrow={
          <img src={IconNext} alt="nextIcon" id={styles['nextIcon']} />
        }
      >
        {media.map((item, key) => {
          return (
            <div
              className={styles['card-media']}
              key={key}
              onMouseEnter={() => {
                setShowCaption(true);
                setControls(item.fileType === VIDEO_SK);
              }}
              onMouseLeave={() => {
                setShowCaption(false);
                setControls(false);
              }}
              onClick={() => viewPhoto(key, item.fileType)}
            >
              <CardMedia
                id={styles['pli-user-media']}
                className={styles.mediaContainer}
                component={item.fileType === IMAGE_SK ? 'img' : item.fileType}
                image={item.source.uri}
                controls={controls}
              />
              {item.caption && (
                <Fade in={showCaption} mountOnEnter unmountOnExit>
                  <div className={styles['card-media-caption']}>
                    {item.caption}
                  </div>
                </Fade>
              )}
            </div>
          );
        })}
      </Slide>
      <MediaViewer
        open={mediaViewerState.isOpen}
        entityType={USER_SK}
        mediaList={media}
        selected={mediaViewerState.selected}
        profileImgUrl={profileImgUrl}
        userRole={userDetails?.userRole}
        userName={userDetails?.name}
        baseMediaUrl={baseMediaUrl}
        baseMediaThumbUrl={baseMediaThumbUrl}
        close={closeMediaViewer}
      />
      <AlertDialog open={alertOpen} data={alertData} />
    </>
  );
};

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaThumbUrl,
    baseMediaUrl,
  };
};

export default connect(mapStateToProps, null)(withRouter(UserMediaGrid));
