/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import dateFormat from 'dateformat';

import {
  Grid,
  Box,
  TextField,
  styled,
  Select,
  MenuItem,
  ThemeProvider,
  createTheme,
  Checkbox,
  Typography,
} from '@mui/material';
import {
  LocalizationProvider,
  DatePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  AddCircleOutlineOutlined as AddCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

import { isNonEmptyString } from '../../../../utils';
import { editProfile } from '../../../../store/actions';
import { translate } from '../../../../i18n';
import {
  EMAIL_SK,
  ERROR_SK,
  FACEBOOK_SK,
  FEMALE_SK,
  GOOGLE_SK,
  MALE_SK,
  OTHER_SK,
  PHONE_SK,
  SUCCESS_SK,
  APPLE_SK
} from '../../../../constants';

import { Button, Snack } from '../../../../common';

import styles from './User.module.scss';
import colors from "../../../../css/variable.scss";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
          },
        },
      },
    },
  },
});

const KeyboardDatePicker = styled(MuiKeyboardDatePicker)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#0D5984',
    },
  },
});

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryColor,
    },
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& input': {
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryColor,
    },
  },
});

const AddNewBox = ({ label, handleClick }) => {
  return (
    <Box id="add-new-box" onClick={handleClick}>
      <AddCircleOutlineIcon />
      {` ${label}`}
    </Box>
  );
};

const WorkAndEducationCard = ({
  id,
  label,
  checkLabel,
  item,
  deleteItem,
  handleNameChange,
  handleFromChange,
  handleToChange,
}) => {
  return (
    <>
      <Box className="work-n-education-form">
        <Typography>{label}</Typography>
        <DeleteIcon fontSize="small" id="delete-icon" onClick={deleteItem} />
        <CustomTextField
          value={item.name}
          onChange={(e) => handleNameChange(id, e.target.value)}
          size="small"
          fullWidth
        />
        <br />
        <br />
        <ThemeProvider theme={theme}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From"
                  value={item.from}
                  onChange={(newValue) => {
                    handleFromChange(id, newValue);
                  }}
                  renderInput={(params) => (
                    <CssTextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To"
                  value={item.to}
                  onChange={(newValue) => {
                    handleToChange(id, newValue);
                  }}
                  renderInput={(params) => (
                    <CssTextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Box id="tip-check-box">
          <Checkbox size="small" />
          {checkLabel}
        </Box>
      </Box>
      <br />
    </>
  );
};

const ProfileTab = ({
  userDetails,
  userIdentifier,
  editProfileStatus,
  editProfile: _editProfile,
}) => {

  const loginType = userDetails.bio.loginType;

  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState(FEMALE_SK);
  const [dob, setDob] = useState('');
  const [currentCity, setCurrentCity] = useState('');
  const [homeTown, setHomeTown] = useState('');
  const [interests, setInterests] = useState([]);
  const [schools, setSchools] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [workLocations, setWorkLocations] = useState([]);
  const [placesVisited, setPlacesVisited] = useState([]);
  const [snackData, setSnackData] = useState({
    open: false,
    message: '',
    type: SUCCESS_SK,
  });
  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth();
  const thisDay = today.getUTCDate();
  const validYear = thisYear - 13;

  useEffect(() => {
    const {
      memberStatus,
      name,
      email,
      mobileNumber,
      gender,
      dob,
      city,
      homeTown,
    } = userDetails.bio;
    const { schools, universities, workLocations } =
      userDetails.workAndEducation;

    setStatus(memberStatus);
    setName(name);
    setEmail(email);
    setPhone(mobileNumber);
    setGender(gender);
    setDob(isNonEmptyString(dob) ? dob : null);
    setCurrentCity(city);
    setHomeTown(homeTown);
    setInterests(userDetails.interests);
    setSchools(schools);
    setUniversities(universities);
    setWorkLocations(workLocations);
    setPlacesVisited(userDetails.placesVisited);
  }, [userDetails]);

  const saveChanges = () => {
    let date = "";
    let _schools = filterEmptyItems(schools);
    let _universities = filterEmptyItems(universities);
    let _workLocations = filterEmptyItems(workLocations);
    let interestIdentifiers = getInterestIdentifiers();

    if (dob) {
      date = dateFormat(dob, 'yyyy/mm/dd').replaceAll('/', '-');
    };

    if (!name) {
      setSnackData({
        open: true,
        message: translate('userInterestScreen.nameRequire'),
        type: ERROR_SK,
      });
      return
    }
    if (isValidate()) {
      _editProfile(
        {
          bio: {
            // memberStatus: status,
            name,
            email,
            mobileNumber: phone,
            gender,
            dob: date,
            city: currentCity,
            homeTown,
          },
          workAndEducation: {
            schools: _schools,
            universities: _universities,
            workLocations: _workLocations,
          },
          interestIdentifiers,
        },
        name
      );
    }
  };

  const isValidate = () => {
    let selectedInterests = interests.filter((interest) => {
      return interest.isSelected === 1;
    });
    if (selectedInterests.length < 3) {
      setSnackData({
        open: true,
        message: translate('userInterestScreen.leftErrorMessage'),
        type: ERROR_SK,
      });
      return false;
    } else if (!name) {
      setSnackData({
        open: true,
        message: translate('userInterestScreen.nameRequire'),
        type: ERROR_SK,
      });
      return false;
    }
    return true;
  };

  const getInterestIdentifiers = () => {
    return interests
      .filter((interest) => {
        return interest.isSelected === 1;
      })
      .map((interest) => {
        return {
          identifier: interest.identifier,
        };
      });
  };

  const filterEmptyItems = (items) => {
    return items.filter(
      (item) =>
        isNonEmptyString(item.name) && item.from !== null && item.to !== null
    );
  };

  const handleCheck = (e, item) => {
    let checked = e.target.checked ? 1 : 0;
    let _interests = _.clone(interests);
    _interests.forEach((_item) => {
      if (_item.identifier === item.identifier) {
        _item.isSelected = checked;
      }
    });
    setInterests(_interests);
  };

  const addNewCard = (setData) => {
    setData((prevState) => {
      return [
        ...prevState,
        {
          name: '',
          from: null,
          to: null,
        },
      ];
    });
  };

  const deleteCard = (index, setData) => {
    setData((prevState) => {
      return prevState.filter((_, key) => key !== index);
    });
  };

  const updateState = (fieldName, value, index, arr, set) => {
    let _arr = _.clone(arr);
    _arr.forEach((item, key) => {
      if (key === index) {
        item[fieldName] = value;
      }
    });
    set(_arr);
  };

  const handleSchoolNameChange = (index, value) => {
    updateState('name', value, index, schools, setSchools);
  };

  const handleSchoolFromChange = (index, value) => {
    updateState('from', formatDate(value), index, schools, setSchools);
  };

  const handleSchoolToChange = (index, value) => {
    updateState('to', formatDate(value), index, schools, setSchools);
  };

  const handleUniversityNameChange = (index, value) => {
    updateState('name', value, index, universities, setUniversities);
  };

  const handleUniversityFromChange = (index, value) => {
    updateState(
      'from',
      formatDate(value),
      index,
      universities,
      setUniversities
    );
  };

  const handleUniversityToChange = (index, value) => {
    updateState('to', formatDate(value), index, universities, setUniversities);
  };

  const handleWorkNameChange = (index, value) => {
    updateState('name', value, index, workLocations, setWorkLocations);
  };

  const handleWorkFromChange = (index, value) => {
    updateState(
      'from',
      formatDate(value),
      index,
      workLocations,
      setWorkLocations
    );
  };

  const handleWorkToChange = (index, value) => {
    updateState(
      'to',
      formatDate(value),
      index,
      workLocations,
      setWorkLocations
    );
  };

  const formatDate = (date) => {
    return dateFormat(date, 'yyyy/mm/dd').replaceAll('/', '-');
  };

  const closeSnack = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  // Checks if email input should be disabled
  const isEmailDisabled = () => {
    // If login type is EMAIL_SK, disable email input
    if (loginType === EMAIL_SK) {
      return true;
    }
    // If login type is not PHONE_SK and email is provided, disable email input
    else if (loginType !== PHONE_SK && email) {
      return true;
    }
    // Otherwise, enable email input
    else {
      return false;
    }
  };

  // Checks if phone input should be disabled
  const isPhoneDisabled = () => {
    // If login type is PHONE_SK, disable phone input
    if (loginType === PHONE_SK) {
      return true;
    }
    // If login type is not EMAIL_SK and phone is not provided, enable phone input
    else if (loginType !== EMAIL_SK && !phone) {
      return false;
    }
    // Otherwise, disable phone input
    else {
      return false;
    }
  };


  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />;
  };

  return (
    <>
      {/* <Grid container direction={{xs: 'column', md: 'column', lg: 'row' }}>
            <Grid item xs={3}>
              <Box id='label-box'>
                {translate('editProfile.status')}
              </Box>
            </Grid>
            <Grid item xs={9}>
              <CssTextField
                value={status}
                variant='outlined'
                size='small'
                fullWidth
                onChange={(e) => setStatus(e.target.value)}
              />
            </Grid>
          </Grid>
          <br /> */}
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.name')}
            {(loginType === EMAIL_SK || loginType === PHONE_SK) && <span style={{ color: '#FF0000' }}>*</span>}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <CssTextField
            value={name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setName(e.target.value)}
            disabled={loginType === FACEBOOK_SK || loginType === GOOGLE_SK || loginType === APPLE_SK}
          />
        </Grid>
      </Grid>
      <br />

      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.email')}
          </Box>
        </Grid>
        <Grid item xs={9}>
          {isEmailDisabled() ? (
            <CssTextField
              variant="outlined"
              value={email}
              size="small"
              fullWidth
              disabled={true}
            >
              {email}
            </CssTextField>
          ) : (
            <CssTextField
              value={email}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.phone')}
          </Box>
        </Grid>
        <Grid item xs={9}>
          {isPhoneDisabled() ? (
            <CssTextField
              variant="outlined"
              value={phone}
              size="small"
              fullWidth
              disabled={true}
            >
              {email}
            </CssTextField>
          ) : (
            <CssTextField
              value={phone}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setPhone(e.target.value)}
            />
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.gender')}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <ThemeProvider theme={theme}>
            <Select
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
              }}
              size="small"
              fullWidth
              id={styles.genderSelectBox}
            >
              <MenuItem value={FEMALE_SK}>
                {translate('common.female')}
              </MenuItem>
              <MenuItem value={MALE_SK}>
                {translate('common.male')}
              </MenuItem>
              <MenuItem value={OTHER_SK}>
                {translate('common.other')}
              </MenuItem>
            </Select>
          </ThemeProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.dateOfBirth')}
          </Box>
        </Grid>

        <Grid item xs={9}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              openTo="year"
              format="dd-MM-yyyy"
              views={['year', 'month', 'date']}
              value={dob}
              variant="inline"
              inputVariant="outlined"
              minDate={'1940-01-01'}
              initialFocusedDate={`${validYear}/${thisMonth}/${thisDay}`}
              maxDate={`${validYear}/${thisMonth}/${thisDay}`}
              size="small"
              fullWidth
              onChange={(newValue) => {
                setDob(newValue);
              }}
              TextFieldComponent={TextFieldComponent}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.currentCity')}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <CssTextField
            value={currentCity}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setCurrentCity(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container direction={{ xs: 'column', md: 'column', lg: 'row' }}>
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.homeTown')}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <CssTextField
            value={homeTown}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setHomeTown(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        direction={{ xs: 'column', md: 'column', lg: 'row' }}
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <Box id={styles['label-box']}>
            {translate('editProfile.interests')}
            <span style={{ color: '#FF0000' }}>*</span>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box id={styles['interests-box']}>
            <Grid container spacing={{ xs: 1, md: 2 }}>
              {interests.map((item, key) => (
                <Grid key={key} item xs={6} sm={3}>
                  <div className={styles['interest-item']}>
                    <Checkbox
                      checked={Boolean(item.isSelected)}
                      onChange={(e) => handleCheck(e, item)}
                      size="small"
                      icon={
                        <RadioButtonUncheckedIcon
                          id={styles['interest-item-unchecked-icon']}
                        />
                      }
                      checkedIcon={
                        <CheckCircleOutlineIcon
                          id={styles['interest-item-checked-icon']}
                        />
                      }
                    />
                    <img alt="img" src={item.imageUrl} />
                    <div>{item.interestName}</div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* <br />
          <Accordion
            id='accordion'
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="accordion-sum"
            >
              <Typography>{translate('editProfile.workAndEducation')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container
                direction={{xs: 'column', md: 'column', lg: 'row' }}
                alignItems='flex-start'
              >
                <Grid item xs={3}>
                  <Box id='label-box'>
                    {translate('editProfile.school')}
                  </Box>
                </Grid>
                <Grid item xs={9} sx={{ width: '100%' }}>
                  {schools?.map((school, index) => (
                    <WorkAndEducationCard
                      key={index}
                      label={translate('editProfile.nameOfSchool')}
                      checkLabel={translate('editProfile.currentlyStudy')}
                      id={index}
                      item={school}
                      deleteItem={() => deleteCard(index, setSchools)}
                      handleNameChange={handleSchoolNameChange}
                      handleFromChange={handleSchoolFromChange}
                      handleToChange={handleSchoolToChange}
                    />
                  ))}
                  <AddNewBox
                    label={translate('editProfile.addASchool')}
                    handleClick={() => addNewCard(setSchools)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container
                direction={{xs: 'column', md: 'column', lg: 'row' }}
                alignItems='flex-start'
              >
                <Grid item xs={3}>
                  <Box id='label-box'>
                    {translate('editProfile.university')}
                  </Box>
                </Grid>
                <Grid item xs={9} sx={{ width: '100%' }}>
                  {universities?.map((university, index) => (
                    <WorkAndEducationCard
                      key={index}
                      label={translate('editProfile.nameOfUniversity')}
                      checkLabel={translate('editProfile.currentlyStudy')}
                      id={index}
                      item={university}
                      deleteItem={() => deleteCard(index, setUniversities)}
                      handleNameChange={handleUniversityNameChange}
                      handleFromChange={handleUniversityFromChange}
                      handleToChange={handleUniversityToChange}
                    />
                  ))}
                  <AddNewBox
                    label={translate('editProfile.addAUniversity')}
                    handleClick={() => addNewCard(setUniversities)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container
                direction={{xs: 'column', md: 'column', lg: 'row' }}
                alignItems='flex-start'
              >
                <Grid item xs={3}>
                  <Box id='label-box'>
                    {translate('editProfile.work')}
                  </Box>
                </Grid>
                <Grid item xs={9} sx={{ width: '100%' }}>
                  {workLocations?.map((work, index) => (
                    <WorkAndEducationCard
                      key={index}
                      label={translate('editProfile.nameOfCompany')}
                      checkLabel={translate('editProfile.currentlyWork')}
                      id={index}
                      item={work}
                      deleteItem={() => deleteCard(index, setWorkLocations)}
                      handleNameChange={handleWorkNameChange}
                      handleFromChange={handleWorkFromChange}
                      handleToChange={handleWorkToChange}
                    />
                  ))}
                  <AddNewBox
                    label={translate('editProfile.addAWorkPlace')}
                    handleClick={() => addNewCard(setWorkLocations)}
                  />
                </Grid>
              </Grid>
              <br />
            </AccordionDetails>
          </Accordion> */}
      {/* <br />
          <Accordion
            id='accordion'
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="accordion-sum"
            >
              <Typography>{translate('editProfile.recentVisitedPlaces')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {placesVisited?.map((place, key) => {
                return (
                  <Box key={key}>
                    <Typography id='location-address'>
                      {place.locationAddress}
                    </Typography>
                    <Typography id='visited-at'>
                      {formattedDate(place.visitedAt)}
                    </Typography>
                    <br />
                  </Box>
                )
              })}
              {placesVisited?.length === 0 &&
                <Typography id='visited-at'>
                  {translate('editProfile.noVisitedPlace')}
                </Typography>}
            </AccordionDetails>
          </Accordion> */}
      <br />
      <br />
      <Grid container>
        <Grid item xs={12} textAlign="center">
          <Button
            id={styles.button}
            variant="contained"
            size="small"
            onClick={saveChanges}
          >
            {translate('editProfile.updateAccount')}
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />

      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={closeSnack}
      />
    </>
  );
};

const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { userDetails, userIdentifier, editProfileStatus } = profile;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    userDetails,
    userIdentifier,
    editProfileStatus,
  };
};

export default connect(mapStateToProps, {
  editProfile,
})(ProfileTab);
