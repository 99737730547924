import { takeLatest, call, put, select } from 'redux-saga/effects';
import { api } from '../../api';
import {
    FETCH_WEATHER_REQUEST,
    FETCH_WEATHER_LOADING,
    FETCH_WEATHER_SUCCESS,
    FETCH_WEATHER_ERROR,
    FETCH_EXCHANGE_RATE_REQUEST,
    FETCH_EXCHANGE_RATE_LOADING,
    FETCH_EXCHANGE_RATE_SUCCESS,
    FETCH_EXCHANGE_RATE_ERROR,
    FETCH_DESTINATION_REQUEST,
    FETCH_DESTINATION_LOADING,
    FETCH_DESTINATION_SUCCESS,
    FETCH_DESTINATION_ERROR,
    FETCH_SOURCE_REQUEST,
    FETCH_SOURCE_LOADING,
    FETCH_SOURCE_SUCCESS,
    FETCH_SOURCE_ERROR,
    FETCH_ROUTE_REQUEST,
    FETCH_ROUTE_LOADING,
    FETCH_ROUTE_SUCCESS,
    FETCH_ROUTE_ERROR,
    FETCH_SERVICES_LOADING,
    FETCH_SERVICES_REQUEST,
    FETCH_SERVICES_SUCCESS,
    FETCH_SERVICES_ERROR,
    FETCH_CALCULATIONS_LOADING,
    FETCH_CALCULATIONS_REQUEST,
    FETCH_CALCULATIONS_SUCCESS,
    FETCH_CALCULATIONS_ERROR,
    EMERGENCY_SK,
    FETCH_HIGHWAY_CONTACT_SUCCESS,
    FETCH_HIGHWAY_CONTACT_ERROR,
    FETCH_HIGHWAY_CONTACT_LOADING,
    FETCH_HIGHWAY_CONTACT_REQUEST,
} from '../../constants';
import { getWeatherFormattedDate, getDayName, } from '../../utils';
import statesAndDivisions from '../../config/statesAndDivisions';


/**
 * worker saga: Calls the get weather API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchWeather({ payload: { city, units, exclude, } }) {
    try {
        yield put({
            type: FETCH_WEATHER_LOADING,
        });

        // eslint-disable-next-line prefer-const
        let {
            location,
        } = yield select(getWeatherData);

        if (city !== '') {
            location = {
                latitude: city.lat,
                longitude: city.lon
            }
        }

        // api call
        const response = yield call(
            { context: api, fn: api.fetchWeather },
            {
                location,
                units,
                exclude,
            },
        );

        // parse the data from response
        const {
            current: { dt, temp, weather },
            daily: _daily,
        } = response;
        const icon = weather[0].icon;
        const desc = weather[0].main;
        const date = getWeatherFormattedDate(new Date(dt * 1000));

        let daily = [];
        _daily.forEach((day, key) => {
            if (key !== 0 && key < 7) {
                let icon = day.weather[0].icon;
                let temp = {
                    max: day.temp.max,
                    min: day.temp.min,
                };
                let dayName = getDayName(new Date(day.dt * 1000));
                daily.push({
                    dayName,
                    icon,
                    temp,
                });
            }
        });

        // send response into action payload
        yield put({
            type: FETCH_WEATHER_SUCCESS,
            payload: {
                weatherData: {
                    current: {
                        date,
                        temp,
                        desc,
                        icon,
                    },
                    daily,
                },
            },
        });

    } catch (error) {
        yield put({
            type: FETCH_WEATHER_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the get exchange rate API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchExchangeRate() {
    try {
        yield put({
            type: FETCH_EXCHANGE_RATE_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchExchangeRate },
        );

        // parse the data from response
        const {
            rates: exchangeRateData,
        } = response;

        // send parsed data into action payload
        yield put({
            type: FETCH_EXCHANGE_RATE_SUCCESS,
            payload: {
                exchangeRateData: exchangeRateData,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_EXCHANGE_RATE_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}


/**
 * worker saga: Calls the get highway contacts API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchHighwayContacts() {
    try {
        yield put({
            type: FETCH_HIGHWAY_CONTACT_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchHighwayContacts },
        );

        //parse the data from response
        const {
            userResponse: contacts,
        } = response;

        let emergency = [];
        let police = [];
        let highwayEmergency = [];
        let highwayPolice = [];
        contacts.forEach((contact) => {

            if (contact.category === EMERGENCY_SK) {
                if (contact.state !== "Highway") {
                    emergency.push(contact);
                }
                if (contact.state === 'Highway') {
                    highwayEmergency.push(contact);
                }
            }
            else {
                if (contact.state !== "Highway") {
                    police.push(contact);
                }
                if (contact.state === 'Highway') {
                    highwayPolice.push(contact);
                }
            }

        });

        let original = [];
        statesAndDivisions.cities.forEach(city => {
            let _emergency = [];
            let _police = [];
            police.forEach((item) => {
                if (city.state.toLowerCase() === item.state.toLowerCase()) {
                    _police.push(item);
                }
            });
            emergency.forEach((item) => {
                if (city.state.toLowerCase() === item.state.toLowerCase()) {
                    _emergency.push(item);
                }
            });
            if (_emergency.length !== 0 || _police.length !== 0) {
                original.push({
                    city,
                    police: _police,
                    emergency: _emergency,
                });
            }

        })




        // send parsed data into action payload
        yield put({
            type: FETCH_HIGHWAY_CONTACT_SUCCESS,
            payload: {
                highwayContactsData: {
                    emergency,
                    police,
                    original,
                    highway: {
                        emergency: highwayEmergency,
                        police: highwayPolice
                    }
                },

            },
        });
    } catch (error) {
        yield put({
            type: FETCH_HIGHWAY_CONTACT_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}


/**
 * worker saga: Calls the get sources API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchSources() {
    try {
        yield put({
            type: FETCH_SOURCE_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchSources },
        );

        //parse the data from response
        const {
            userResponse,
        } = response;

        // send parsed data into action payload
        yield put({
            type: FETCH_SOURCE_SUCCESS,
            payload: {
                sources: userResponse,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_SOURCE_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}


/**
 * worker saga: Calls the get destination API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchDestinations({ payload: { source } }) {

    try {
        yield put({
            type: FETCH_DESTINATION_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchDestinations }, { source }
        );

        //parse the data from response
        const {
            userResponse,
        } = response;
        // send parsed data into action payload
        yield put({
            type: FETCH_DESTINATION_SUCCESS,
            payload: {
                destinations: userResponse,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_DESTINATION_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the get route API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchRoutes({ payload: { sourceId, destinationId } }) {
    try {
        yield put({
            type: FETCH_ROUTE_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchRoutes }, { sourceId, destinationId }
        );

        //parse the data from response
        const {
            userResponse,
        } = response;

        // send parsed data into action payload
        yield put({
            type: FETCH_ROUTE_SUCCESS,
            payload: {
                routes: userResponse,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_ROUTE_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the get services API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchServices({ payload: { routeIdentifier } }) {

    try {
        yield put({
            type: FETCH_SERVICES_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchServices }, { routeIdentifier }
        );

        //parse the data from response
        const {
            userResponse,
        } = response;

        // send parsed data into action payload
        yield put({
            type: FETCH_SERVICES_SUCCESS,
            payload: {
                services: userResponse,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_SERVICES_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * worker saga: Calls the get calculations API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchCalculations({ payload: { routeIdentifier, transportationId, accommodationId, mealPlanId, activityIds, person, day } }) {

    try {
        yield put({
            type: FETCH_CALCULATIONS_LOADING,
        });
        // api call
        const response = yield call(
            { context: api, fn: api.fetchCalculations }, { routeIdentifier, transportationId, accommodationId, mealPlanId, activityIds, person, day }
        );

        //parse the data from response
        const {
            userResponse,
        } = response;

        // send parsed data into action payload
        yield put({
            type: FETCH_CALCULATIONS_SUCCESS,
            payload: {
                calculations: userResponse,
            },
        });
    } catch (error) {
        yield put({
            type: FETCH_CALCULATIONS_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

/**
 * Fetch data from redux store only for
 * fetchWeather generator function
 */
const getWeatherData = ({ app, }) => {
    const { location, } = app;

    return {
        location,
    };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(FETCH_WEATHER_REQUEST, fetchWeather);
    yield takeLatest(FETCH_EXCHANGE_RATE_REQUEST, fetchExchangeRate);
    yield takeLatest(FETCH_SOURCE_REQUEST, fetchSources);
    yield takeLatest(FETCH_DESTINATION_REQUEST, fetchDestinations);
    yield takeLatest(FETCH_ROUTE_REQUEST, fetchRoutes);
    yield takeLatest(FETCH_SERVICES_REQUEST, fetchServices);
    yield takeLatest(FETCH_CALCULATIONS_REQUEST, fetchCalculations);
    yield takeLatest(FETCH_HIGHWAY_CONTACT_REQUEST, fetchHighwayContacts);

}
