import React from "react";
import NO_RESULT_IMAGE from "../../../assets/images/no_result_img.png";
import { AppBar, BackIcon } from "../../../common";
import { Box } from "@mui/material";
import { BusinessSuggestion } from ".";
import styles from "../SearchResult.module.scss";
import { Typography } from "@material-ui/core";
import { translate } from "../../../i18n";

const NoResult = ({
  show,
  showTitleA
}) => {
  return (
    <Box id={show ? styles["no-result-root"] : styles["no-result-root-filter"]}>
      <AppBar mode="search" />
      <BackIcon />
      <Box id={show ? styles["no-result-inner-box-all"] : styles["no-result-inner-box"]}>
        <img
          alt="img"
          className={styles["no-result-img"]}
          src={NO_RESULT_IMAGE}
        />
        <Typography id={styles["no-result-info"]}>
          {translate("searchResultScreen.noResultFoundMessage")}
        </Typography>
      </Box>
      {(show || showTitleA) && <Box id={showTitleA ? styles["no-result-business-box-filter"] : styles["no-result-business-box"]}>
        <BusinessSuggestion showTitle={true} showTitleA={showTitleA} />
      </Box>}
    </Box>
  );
};

export default NoResult;
