import {
  FETCH_BANNER_IMAGES_REQUEST,
  FETCH_HOME_PAGE_DATA_REQUEST,
  DELETE_HOME_POST_REQUEST,
  UPDATE_USER_FOLLOW_STATUS,
  SHARE_POST_SUCCESS,
  SHARE_POST_REQUEST,
  REPORT_POST_REQUEST,
  REPORT_POST_SUCCESS,
  SAVE_FAVOURITE_PLI_REQUEST,
  DELETE_FAVOURITE_PLI_REQUEST,
  UPDATE_BLOGGER_NETWORK_STATUS,
  UPDATE_SHARE_COUNT,
  RESET_SHARE_POST_STATUS,
  RESET_DELETE_POST_STATUS,
  RESET_REPORT_POST_STATUS,
  UPDATE_USER_RELATION_STATUS,
  EDIT_POST,
  RESET_EDIT_POST_STATUS,
  DELETE_BLOG_REQUEST,
  UPDATE_REACTION,
  UPDATE_COMMENT_COUNT,
  UPDATE_VIEW_COUNT,
  RESET_HOME_PAGE_DATA_STATUS,
  RESET_FAVOURITE_STATUS,
  UPDATE_HOME_PAGE_DATA,
} from "../../constants";

/**
 * Initiate get banner images api request.
 */
export const fetchBannerImages = () => ({
  type: FETCH_BANNER_IMAGES_REQUEST,
});

/**
 * Initiate fetch home page data api request. This same action
 * will be called for pagination too.
 *
 * @param {number} page  - Page number of the posts to be fetched
 */
export const fetchHomePageData = (page = 1) => ({
  type: FETCH_HOME_PAGE_DATA_REQUEST,
  payload: {
    page,
  },
});

/**
 * Dispatch an action to delete a post in HomeScreen
 *
 * @param {string} identifier - post identifier
 */
export const deletePost = (identifier) => ({
  type: DELETE_HOME_POST_REQUEST,
  payload: {
    identifier,
  },
});

/**
 * Dispatch an action to delete a blog
 *
 * @param {string} blogIdentifier - blog identifier
 */
export const deleteBlog = (blogIdentifier) => ({
  type: DELETE_BLOG_REQUEST,
  payload: {
    blogIdentifier,
  },
});

/**
 *  update user follow / un-follow status
 * @param status
 * @param identifier
 * @return {{type: string, payload: {status: *, identifier: *}}}
 */
export const updateUserFollowStatus = (status, identifier) => ({
  type: UPDATE_USER_FOLLOW_STATUS,
  payload: {
    status,
    identifier,
  },
});

/**
 *  update blogger follow / un-follow status
 * @param status
 * @param identifier
 * @return {{type: string, payload: {status: *, identifier: *}}}
 */
export const updateBloggerNetworkStatus = (
  networkType,
  status,
  identifier
) => ({
  type: UPDATE_BLOGGER_NETWORK_STATUS,
  payload: {
    networkType,
    status,
    identifier,
  },
});

export const sharePost = () => ({
  type: SHARE_POST_REQUEST,
});
/**
 * Dispatch an action containing data related to new shared post
 * which will be stored in post list of home reducer
 *
 * @param {object} sharedPost  - Data pertaining to shared post
 */
export const sharePostSucess = (sharedPost) => ({
  type: SHARE_POST_SUCCESS,
  payload: {
    sharedPost,
  },
});

export const reportPost = () => ({
  type: REPORT_POST_REQUEST,
});

export const reportPostSuccess = () => ({
  type: REPORT_POST_SUCCESS,
});

export const saveFavourite = (
  entityIdentifier,
  entityType,
  userIdentifier
) => ({
  type: SAVE_FAVOURITE_PLI_REQUEST,
  payload: {
    entityIdentifier,
    entityType,
    userIdentifier,
  },
});

export const deleteFavourite = (
  favouriteIdentifier,
  entityType,
  entityIdentifier
) => ({
  type: DELETE_FAVOURITE_PLI_REQUEST,
  payload: {
    favouriteIdentifier,
    entityType,
    entityIdentifier,
  },
});

export const updateShareCount = (identifier) => ({
  type: UPDATE_SHARE_COUNT,
  payload: {
    identifier,
  },
});

export const resetSharePostStatusToDefault = () => ({
  type: RESET_SHARE_POST_STATUS,
});

export const resetDeletePostStatusToDefault = () => ({
  type: RESET_DELETE_POST_STATUS,
});

export const resetReportPostStatusToDefault = () => ({
  type: RESET_REPORT_POST_STATUS,
});

/**
 *  update user relation status
 * @param status
 * @param user
 */
export const updateUserRelationStatus = (status, user) => ({
  type: UPDATE_USER_RELATION_STATUS,
  payload: {
    status,
    user,
  },
});

export const editPost = (identifier, visibility, description) => ({
  type: EDIT_POST,
  payload: {
    identifier,
    visibility,
    description,
  },
});

export const resetEditPostStatus = () => ({
  type: RESET_EDIT_POST_STATUS,
});

export const updateReaction = (identifier, newReaction, likesCount) => ({
  type: UPDATE_REACTION,
  payload: {
    identifier,
    newReaction,
    likesCount,
  },
});

export const updateCommentCount = (identifier, count) => ({
  type: UPDATE_COMMENT_COUNT,
  payload: {
    identifier,
    count,
  },
});

export const updateViewCount = (identifier, count) => ({
  type: UPDATE_VIEW_COUNT,
  payload: {
    identifier,
    count,
  },
});

export const resetHomePageDataStatusToDefault = () => ({
  type: RESET_HOME_PAGE_DATA_STATUS,
})

export const resetFavouriteStatusToDefault = () => ({
  type: RESET_FAVOURITE_STATUS,
})

export const updateHomePageData = ({ favouriteIdentifier, identifier }) => ({
  type: UPDATE_HOME_PAGE_DATA,
  payload: {
    favouriteIdentifier,
    identifier,
  }
})