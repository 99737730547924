import React from "react";
import Carousel from "react-multi-carousel";
import "../../../../node_modules/react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ArticleByAttractionItem from "./ArticleByAttractionItem";
import styles from "../../../components/AttractionDetails/AttractionDetails.module.scss";

const ArticleByAttraction = (props) => {
  const { articles, language, saveSuccessCallback, unsaveSuccessCallback } =
    props;

  const responsive = {
    desktop1: {
      breakpoint: { max: 3000, min: 1279 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },

    desktop2: {
      breakpoint: { max: 1279, min: 960 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 960, min: 750 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 750, min: 500 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      swipeable={true}
      autoPlay={false}
      draggable={true}
      centerMode={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass={styles["carousel-pad"]}
    >
      {articles?.map((item, key) => {
        return (
          <ArticleByAttractionItem
            item={item}
            key={key}
            article={articles}
            language={language}
            saveSuccessCallback={saveSuccessCallback}
            unsaveSuccessCallback={unsaveSuccessCallback}
          />
        );
      })}
    </Carousel>
  );
};
const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;

  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(mapStateToProps, {})(withRouter(ArticleByAttraction));
