import { Rating } from "@mui/material";
import styles from "./RatingStar.module.scss";

const RatingStar = ({
    /***
     * The rating prop can be of various data types, 
     * including string, float, number or integer.
     */
    rating,
    size,
    isNewsfeed
}) => {

    /***
     * Use of Number() ensures conversion of "rating" prop to a valid number data type.
     * Because the "Rating" component expects a numerical value for the value prop to display the correct rating. 
     * */
    const avgRating = Number(rating);

    return <>
        <Rating
            value={avgRating}
            precision={0.5}
            size={size}
            readOnly
            id={isNewsfeed && styles["rating"]}
        />
    </>

}

export default RatingStar;