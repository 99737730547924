export const loadState = key => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return undefined;
        }
        return serializedState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch {
        // ignore write errors
    }
};

export const removeState = key => {
    try {
        localStorage.removeItem(key);
    } catch {
        // ignore write errors
    }
}
