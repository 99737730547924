/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import { api, Status } from '../../../../api';
import { translate } from '../../../../i18n';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReviewListItem from '../../../Reviews/ReviewListItem';
import styles from "./ReviewTab.module.scss";

const ReviewTab = ({
  identifier,
  type,
  isReviewUpdated,
}) => {

  const [page, setPage] = useState(1);
  const [reviewsState, setReviewsState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { apiStatus: Status.DEFAULT, data: [], count: 0 }
  );
  const [contentAvailableForRefresh, setContentAvailableForRefresh] = useState(true);

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {
    if (page !== 1) {
      refreshList(page);
    }
  }, [page]);

  useEffect(() => {
    if (isReviewUpdated) {
      refreshList();
    }
  }, [isReviewUpdated]);

  const refreshList = (_page = 1) => {
    if (reviewsState.apiStatus !== Status.LOADING) {
      setReviewsState({ apiStatus: Status.LOADING });
      api
        .fetchReviews({
          identifier,
          type,
          page: _page,
        })
        .then(({ userResponse: { reviewList, totalReviewCount } }) => {
          let _reviewList = [];
          reviewList.forEach((review) => {
            _reviewList.push({
              ...review,
              userDetails: {
                name: review.userDetails.name,
                location: review.userDetails.location,
                userRole: review.userDetails.userRole,
                profileImg: {
                  isThumbGenerated: review.userDetails.profileImg.isThumbGenerated,
                  url: review.userDetails.profileImg.url,
                },
              },
            });
          });
          let _data =
            _page === 1 ? _reviewList : [...reviewsState.data, ..._reviewList];
          setReviewsState({
            data: _data,
            count: totalReviewCount,
            apiStatus: Status.SUCCESS,
          });
          if (_data.length === parseInt(totalReviewCount)) {
            setContentAvailableForRefresh(false);
          }
        })
        .catch(() => {
          setReviewsState({ apiStatus: Status.ERROR });
        });
    }
  };

  return (
    <div>
      {reviewsState.apiStatus === Status.SUCCESS &&
        reviewsState.data.length !== 0 && (
          <div className={styles["review-count-label"]}>
            {`${translate('businessProfile.reviewCountLabel')} : ${reviewsState.count
              }`}
          </div>
        )}
      <div className="review-section">
        <InfiniteScroll
          dataLength={reviewsState.data.length} //This is important field to render the next data
          next={() => setPage(page + 1)}
          hasMore={contentAvailableForRefresh}
          loader={
            <div className={styles["spinner"]}>
              <CircularProgress size={22} className="loading_spinner" />
            </div>
          }
          endMessage={
            <div className={styles["nomore-data-info"]} style={{ paddingTop: '15px' }}>
              {reviewsState.data.length === 0 &&
                reviewsState.apiStatus === Status.SUCCESS ? (
                <span>{translate('businessProfile.noReview')}</span>
              ) : (
                <span></span>
              )}
            </div>
          }
        >
          <ReviewListItem
            reviewList={reviewsState?.data}
            isBusiness={true}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ReviewTab;

