import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import rootSaga from './sagas';
import { sessionExpired, networkConnection } from './app/appActions';

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history),];

const store = createStore(reducers, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

// ==================================================
// Global actions, called from outside the components
// ==================================================
const sessionExpiredAction = () => {
  store.dispatch(sessionExpired);
};

const networkConnectionAction = () => {
  store.dispatch(networkConnection("offline"));
}

// ==================================================

export default store;
export { sessionExpiredAction, networkConnectionAction };
