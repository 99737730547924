import { call, put, takeLatest } from "@redux-saga/core/effects";
import { api } from "../../api";
import { FETCH_BUSINESS_DETAILS_ERROR, FETCH_BUSINESS_DETAILS_LOADING, FETCH_BUSINESS_DETAILS_REQUEST, FETCH_BUSINESS_DETAILS_SUCCESS } from "../../constants";

/**
 * worker saga: Calls the fetch bloggers List API.
 *
 * @param {Object} action                               - action object dispatched by user
 * @param {string} action.payload.businessIdentifier    - business identifier
 */
function* fetchBusinessDetails({ payload: { businessIdentifier, } }) {
  try {
    yield put({
      type: FETCH_BUSINESS_DETAILS_LOADING
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchBusinessDetails },
      { businessIdentifier, },
    );
    // parse the data from response
    const { userResponse: businessDetails } = response;

    // send parsed data into action payload
    yield put({
      type: FETCH_BUSINESS_DETAILS_SUCCESS,
      payload: {
        businessDetails,
      },
    });
  } catch (error) {
    yield put({
      type: FETCH_BUSINESS_DETAILS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}


// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_BUSINESS_DETAILS_REQUEST, fetchBusinessDetails);
}