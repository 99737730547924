import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { notificationReceived, saveFcmToken } from '../store/actions';
import store from '../store';
import { getBuildConfig } from '../config/buildConfig';

export function initFCM() {
  const buildConfig = getBuildConfig();

  const firebaseConfig = {
    apiKey: buildConfig.apiKey,
    authDomain: buildConfig.authDomain,
    projectId: buildConfig.projectId,
    storageBucket: buildConfig.storageBucket,
    messagingSenderId: buildConfig.messagingSenderId,
    appId: buildConfig.appId,
    measurementId: buildConfig.measurementId,
  };

  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  const vapidKey = buildConfig.vapidKey;

  // Retrieves an FCM token using the messaging object and the provided Application Identity key pairs (vapidKey)
  getToken(messaging, { vapidKey }).then((fcmToken) => {
    // Check if an FCM token is successfully obtained
    if (fcmToken) {
      // If a token is available, save it using the API's saveFCMToken method
      store.dispatch(saveFcmToken(fcmToken));
    } else {
      // If no token is available, log a message to indicate that permission is required on the UI
      console.log('No registration token available. Request permission to generate one.');
    }

  }).catch((err) => {
    // Catch any errors that occur while retrieving the token and log them
    console.log('An error occurred while retrieving token. ', err);
  });

  // Registers a listener for incoming FCM messages
  onMessage(messaging, (remoteMessage) => {
    // Dispatches an action to handle the received notification in the store
    store.dispatch(notificationReceived);
    // Logs the received foreground message
    console.log('Received foreground message', remoteMessage);
  });

}