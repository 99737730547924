import React, { useState } from "react";
import { translate } from "../../../../../i18n";
import {
    OutlinedInput,
    InputAdornment,
    IconButton,
    ThemeProvider,
    createTheme,
    Button,
    FormControl,
    FormHelperText,
    CircularProgress,
    Grid,
    InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "../AccountControlTab.module.scss";
import { useSelector } from "react-redux";
import { AiOutlineRight } from "react-icons/ai";
import {
    api,
    Status
} from "../../../../../api";
import {
    EMAIL_LK,
    FACEBOOK_SK,
    GOOGLE_SK,
    EMAIL_SK,
    PHONE_SK
} from "../../../../../constants";
import DeleteDeactivateConfirmationModal from "./DeleteDeactivateConfirmationModal";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { getBuildConfig } from "../../../../../config/buildConfig";
import { AiOutlineGoogle } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import { LIMITS } from "../../../../../constants";
import countryPhoneCodes from "../../../../../config/countryPhoneCode";
import { Snack } from "../../../../../common";
import colors from "../../../../../css/variable.scss";

const FACEBOOK_APP_ID = getBuildConfig().facebookAppId;
const GOOGLE_CLIENT_ID = getBuildConfig().googleClientId;

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primaryColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primaryColor,
                        backgroundColor: "none !important",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                    },
                },
            },
        },
    },
});

const DeleteAccount = ({
    selectedAction,
    userName,
    /**
    * lognType: Represents the login type retrieved from the local storage.
    * To determine the current login type.
    */
    loginType,
    /**
    * loginTypeString: Represents the login type as a HTML string 
    * with the first letter capitalized.
    * To display the login type.
    */
    loginTypeString
}) => {

    const { userDetails } = useSelector((state) => state.profile);

    const [isReasonStep, setIsReasonStep] = useState(true);
    const [reason, setReason] = useState("");
    const [isPromptStep, setIsPromptStep] = useState(false);
    const [isVerificationStep, setIsVerificationStep] = useState(false);
    const [isAccountDeletionStep, setIsAccountDeletionStep] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [verifyApiStatus, setVerifyApiStatus] = useState(Status.DEFAULT);
    const [verifiedState, setVerifiedState] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [openDeleteDeactivateConfirmationModal, setOpenDeleteDeactivateConfirmationModal] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const countryCode = countryPhoneCodes[122].countryCode;
    const countryFlag = countryPhoneCodes[122].flag;

    const handleReasonChange = (e) => {
        let _reason = e.target.value;
        setReason(_reason);
    };

    const handleSkip = () => {
        setReason("");
        goToPromptStep();
    }

    const goToPromptStep = () => {
        setIsReasonStep(false);
        setIsPromptStep(true);
    };

    const goToVerificationStep = () => {
        setIsPromptStep(false);
        setIsVerificationStep(true);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Callback function for successful Facebook login
    const facebookLoginResponse = ({ accessToken }) => {
        // Check if tokenId is available
        if (accessToken) {
            // Call handleAccountVerification function with the tokenId
            handleAccountVerification(accessToken);
        }
    };

    // Callback function for successful Google login
    const googleLoginResponse = ({ tokenId }) => {
        // Check if tokenId is available
        if (tokenId) {
            // Call handleAccountVerification function with the tokenId
            handleAccountVerification(tokenId);
        }
    };

    const googleLoginFailure = (error) => {
        console.log(error);
    };

    const handleAccountVerification = (tokenId) => {

        // Set the API status to loading
        setVerifyApiStatus(Status.LOADING);

        const bio = userDetails?.bio;

        let loginTypeToUse;
        let emailToUse;
        let mobileNumberToUse;

        // Check if the loginType is not provided
        if (!loginType) {
            // Check the loginType from userDetails.bio
            if (bio.loginType === EMAIL_LK) {
                // If the loginType is email, assign the values to emailToUse
                loginTypeToUse = bio.loginType;
                emailToUse = bio.email;
            } else if (bio.loginType === PHONE_SK) {
                // If the loginType is PHONE_NUMBER_LK, assign the values to mobileNumberToUse
                loginTypeToUse = bio.loginType;
                mobileNumberToUse = bio.mobileNumber;
            }
        } else {
            loginTypeToUse = loginType;
        }

        // Call the API to verify the account
        api.verifyAccount({
            accountControlType: selectedAction,
            reason: reason,
            loginType: loginTypeToUse,
            email: emailToUse,
            mobileNumber: mobileNumberToUse,
            password,
            token: tokenId
        })
            .then(({ userResponse }) => {
                setVerifiedState(userResponse);
                // If the API call is successful
                // Set the API status to success
                setVerifyApiStatus(Status.SUCCESS);
                setErrorMessage("");
                if (!loginType) {
                    // If loginType is falsy (undefined, null, false), it means it's not a social account
                    // Open the DeleteDeactivateConfirmationModal
                    setOpenDeleteDeactivateConfirmationModal(true);
                } else {
                    // If loginType is truthy (not falsy), it means it's a social account (Google || Facebook || Apple)
                    // Set the verification step to false and go to account deletion step
                    setIsVerificationStep(false);
                    setIsAccountDeletionStep(true);
                }
            })
            .catch((error) => {
                // If there's an error in the API call
                // Set the API status to error
                setVerifyApiStatus(Status.ERROR);
                loginType && setSnackOpen(true);
                // Set the error message
                setErrorMessage(error.message);
            });
    };

    const closeSanck = () => {
        setSnackOpen(false);
    };

    const openConfirmationModel = () => {
        setOpenDeleteDeactivateConfirmationModal(true);
    }

    const handleClose = () => {
        setOpenDeleteDeactivateConfirmationModal(false);
    }

    /*********
        Step 1: Reason
    *   Copmonent is to collect user feedback and 
        reasons for account deletion, which can help the app improve and support its community in the future.
    *********/
    const renderAccountDeletionReason = () => {
        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <h3>{translate("editProfile.reasonTitle")}</h3>
                        <p>{translate("editProfile.reasonMsg")}</p>
                        <div className={styles["mian-content-bottom"]}>
                            <div className={styles["content-input-label"]}>
                                <div>{translate("editProfile.reason")}</div>
                                <div className={styles["content-skip"]} onClick={handleSkip}>
                                    <span>{translate("editProfile.skip")}</span> <AiOutlineRight />
                                </div>
                            </div>
                            <textarea rows="10" maxLength={LIMITS.REASON_MAX_LENGTH} onChange={handleReasonChange} />
                        </div>
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button id={styles["account-control-btn"]} onClick={() => goToPromptStep()}>{translate("common.continue")}</Button>
                    </div>

                </div >
            </>
        )
    };

    /*********
        Step 2: Prompt
    *   It presents a message informing the user 
        about the consequences of deleting their account and 
        asks for confirmation before proceeding.
    *********/
    const renderAccountDeletionPrompt = () => {

        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{ __html: translate("editProfile.promptMsg").replace(/%s/g, userName) }} />
                        <p>{translate("editProfile.deletePromptMsg0")}</p>
                        <ul>
                            <li>{translate("editProfile.deletePromptMsg1")}</li>
                            <li>{translate("editProfile.deletePromptMsg2")}</li>
                            <li>{translate("editProfile.deletePromptMsg3")}</li>
                        </ul>
                    </div>

                    <div className={styles["main-content-btn-container"]}>
                        <Button id={styles["account-control-btn"]} onClick={() => goToVerificationStep()}>{translate("common.continue")}</Button>
                    </div>
                </div >
            </>
        )
    }

    /*********
        Step 3: Normal Account Verification 
    *   Component is to collect the user's password and 
        verify it to ensure the account deletion request is legitimate.
    *********/
    const renderNormalAccountDeletionVerification = () => {

        return (
            <form autoComplete="off" className={styles["verification-form"]}>
                <div className={styles["form-container"]} id="form-container">
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{ __html: translate("editProfile.normalVerificationMsg").replace(/%s/g, userName) }} />
                    </div>
                    <div className={styles["input-group"]} >
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={4} alignItems="center">
                                    <FormControl fullWidth>
                                        <InputLabel className={styles["input-item-label"]}>{userDetails.bio.loginType === EMAIL_LK ? translate("common.email") : translate("common.phone")}</InputLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    {userDetails.bio.loginType === EMAIL_LK ?
                                        <FormControl fullWidth>
                                            <OutlinedInput
                                                className={styles['input-box']}
                                                disabled={true}
                                                value={userDetails.bio.email}
                                            />
                                        </FormControl>
                                        : <div className={styles['phone-container']}>
                                            <FormControl
                                                variant="outlined"
                                                id={styles["phone-inbox-select"]}
                                            >
                                                <div>
                                                    <img
                                                        alt="img"
                                                        src={countryFlag}
                                                        className={styles["flag-img"]}
                                                    ></img>{" "}
                                                    {countryCode}
                                                </div>
                                            </FormControl>
                                            <FormControl fullWidth id={styles["phone-input-box"]}>
                                                <OutlinedInput
                                                    className={styles['input-box']}
                                                    disabled={true}
                                                    value={userDetails.bio.mobileNumber.substr(3)}
                                                />
                                            </FormControl>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} alignItems="center">
                                    <FormControl fullWidth>
                                        <InputLabel className={styles["input-item-label"]}>{translate("common.password")} <span>*</span></InputLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            className={styles["input-box"]}
                                            id={styles['outlined-adornment-password']}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => handlePasswordChange(e)}
                                            value={password}
                                            inputProps={{
                                                autoComplete: "new-password"
                                            }}
                                            error={verifyApiStatus === Status.ERROR}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id={styles["error-message"]}>{errorMessage}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button
                            id={styles["account-control-btn"]}
                            onClick={() => handleAccountVerification()}
                            disabled={!password}
                        >
                            {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : translate("editProfile.deleteAccount")}
                        </Button>
                    </div>
                </div >
            </form>
        )
    }

    /*********
        Step 3: Social Account Verification
    *   Component is to prompt the user to log in to their social media account 
        to verify their ownership of the Smoove account. 
    *********/
    const renderSocialAccountDeletionVerification = () => {

        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{
                            __html: translate("editProfile.socialVerificationMsg")
                                .replace(/%s/g, loginTypeString)
                                .replace(/!@#\$%/g, userName)
                        }} />
                    </div>
                    {loginType === GOOGLE_SK && <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={googleLoginResponse}
                        onFailure={googleLoginFailure}
                        autoLoad={false}
                        render={(renderProps) => (
                            <div className={styles["verify-social-container"]} onClick={renderProps.onClick}>
                                <Button id={styles["account-control-btn"]}>
                                    {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : <>
                                        <AiOutlineGoogle className={styles["social-icon"]} />
                                        <p>{translate("editProfile.continueWithGoogle")}</p>
                                    </>}
                                </Button>
                            </div>
                        )}
                    />}
                    {loginType === FACEBOOK_SK && <FacebookLogin
                        appId={FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields={EMAIL_SK}
                        callback={facebookLoginResponse}
                        render={(renderProps) => (
                            <div className={styles["verify-social-container"]} onClick={renderProps.onClick}>
                                <Button id={styles["account-control-btn"]}>
                                    {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : <>
                                        <ImFacebook className={styles["social-icon"]} />
                                        <p>{translate("editProfile.continueWithFacebook")}</p>
                                    </>}
                                </Button>
                            </div>
                        )}
                    />}
                </div>
            </>
        )
    }

    /*********
        Step 4: Final Setp for Account Deletion
    *   Component is to provide a final confirmation step 
        to the user before permanently deleting their account.  
    *********/
    const renderAccountDeletion = () => {

        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{ __html: translate("editProfile.deleteAccountMsg").replace(/%s/g, userName) }} />
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button
                            id={styles["account-control-btn"]}
                            onClick={() => openConfirmationModel()}
                        >
                            {translate("editProfile.deleteAccount")}
                        </Button>
                    </div>
                </div >
            </>
        )
    }

    // Define a function to render the appropriate deletion component based on conditions
    const renderDeletionComponent = () => {

        if (isReasonStep) {
            // Render AccountDeletionReason component if the condition is met
            return renderAccountDeletionReason();
        } else if (isPromptStep) {
            // Render AccountDeletionPrompt component if the condition is met
            return renderAccountDeletionPrompt();
        } else if (isVerificationStep && !loginType) {
            // Render NormalAccountDeletionVerification if the condition is met
            return renderNormalAccountDeletionVerification();
        } else if (isVerificationStep && loginType) {
            // Render SocialAccountDeletionVerification component if the condition is met
            return renderSocialAccountDeletionVerification();
        } else if (isAccountDeletionStep) {
            return renderAccountDeletion();
        }

        // Return null if none of the conditions are met
        return null;
    };

    return (
        <>
            <div id={styles["account-control-title"]}>{translate("editProfile.deleteAccount")}</div>
            {renderDeletionComponent()}
            <DeleteDeactivateConfirmationModal
                open={openDeleteDeactivateConfirmationModal}
                handleClose={handleClose}
                verifiedState={verifiedState}
                reason={reason}
                accountControlType={selectedAction}
                userName={userName}
            />
            <Snack
                open={snackOpen}
                message={errorMessage}
                type={'error'}
                onClose={closeSanck}
            />
        </>
    );
};

export default DeleteAccount;
