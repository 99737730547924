import { translate } from '../i18n';

const initialState = {
  latitude: 0,
  longitude: 0,
  isLocationEnabled: false,
};

/**
* check the location permission and get FINE location
*/
export async function checkPermissionAndGetLocation(isRequired = true) {
  return new Promise((resolve, reject) => {
    //https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API/Using_the_Geolocation_API
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          isLocationEnabled: true,
        });
      },
      error => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            resolve({
              latitude: 0,
              longitude: 0,
              errorMsg: translate('common.permissionDenied'),
            });
            break;
          case error.POSITION_UNAVAILABLE:
            // if location services are unavailable.
            resolve({
              latitude: 0,
              longitude: 0,
              errorMsg: translate('common.locationUnavailable'),
            });
            break;
          case error.TIMEOUT:
            console.log(translate('common.locationRequestTimedOut'));
            resolve(initialState);
            break;
          default:
            console.log(translate('common.generalError'));
            resolve(initialState);
            break;
        }
      },
      { enableHighAccuracy: false, timeout: 10000 },
    );
  });
}

