import React, { useEffect, useState } from "react";

import {
    api,
    Status,
} from "../../api";

/* Constant */
import {
    DESCENDING_SK
} from "../../constants";

/* Material UI component */
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";

/* Common component */
import {
    Spinner,
    ButtonSeeAll
} from "../../common";

/* Language translation */
import { translate } from "../../i18n";

/* Icons from assets */
import moreReviewIcon from "../../assets/images/icon_more_review.png";
import lessReviewIcon from "../../assets/images/icon_less_review.png";

/* Scss - styles */
import styles from './Reviews.module.scss';
import ReviewListItem from "./ReviewListItem";

const Reviews = ({
    type,
    identifier,
    isReviewListUpdated,
    entityStatus
}) => {

    const [reviewList, setReviewList] = useState([]);
    const [totalReviewCount, setTotalReviewCount] = useState(0);
    const [apiStatus, setApiStaus] = useState(Status.DEFAULT);
    const [expand, setExpandReview] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (entityStatus === Status.SUCCESS) {
            fetchReviewList();
        }
    }, [entityStatus]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isReviewListUpdated) {
            setPage(1);
            fetchReviewList();
        }
    }, [isReviewListUpdated]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        page !== 1 && fetchReviewList();
    }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchReviewList = () => {
        setApiStaus(Status.LOADING);
        api.fetchReviews({
            type,
            identifier,
            page,
            sortOrder: DESCENDING_SK,
        }).then(({ userResponse: { reviewList: newList, totalReviewCount: count } }) => {
            const list = page === 1 ? newList : [...reviewList, ...newList];
            setReviewList(list);
            setTotalReviewCount(count);
            setApiStaus(Status.SUCCESS);
        }).catch(() => {
            setApiStaus(Status.ERROR);
        });
    };

    const handleLoadMoreReviews = () => {
        if (apiStatus !== Status.LOADING) {
            setPage(prevState => prevState + 1);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <div className='review-section'>
                        <Accordion sx={{ boxShadow: "none" }}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id={styles["panel1a-header"]}
                            >
                                <div className={styles['expand']} onClick={() => setExpandReview(!expand)}>
                                    <span id={styles['expand-label']}>{translate('common.reviews')}</span>
                                    <div><img alt='img' src={expand ? lessReviewIcon : moreReviewIcon} className={styles['more-review-icon']} /></div>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                                {apiStatus === Status.LOADING && page === 1
                                    ? <div style={{ flex: 1 }}><Spinner /></div>
                                    : apiStatus === Status.SUCCESS && reviewList.length === 0
                                        ? <div className={styles['no-review-label']}> {translate('businessProfile.noReview')}</div>
                                        : <ReviewListItem reviewList={reviewList} />}
                                {reviewList.length !== totalReviewCount && <div id={styles["seemore-wrapper"]}>
                                    <ButtonSeeAll
                                        id={styles["seemore"]}
                                        variant='contained'
                                        size='small'
                                        onClick={handleLoadMoreReviews}
                                    >
                                        {translate('common.seeMore')}
                                    </ButtonSeeAll>
                                </div>}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
                <Grid item xs={1} />
            </Grid >
        </>
    )
};

export default Reviews;