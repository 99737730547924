/* eslint-disable no-unused-vars */
import React from 'react';
import { ARTICLE_SK, ATTRACTION_SK, HOTEL_SK, POST_SK, RESTAURANT_SK, USER_SK } from '../../../../constants';
import ArticleListItem from './ArticleListItem';
import AttractionListItem from './AttractionListItem';
import BusinessListItem from './BusinessListItem';
// import PostListItem from './PostListItem';
// import UserListItem from './UserListItem';
import { updateReaction, updateCommentCount } from '../../../../store/actions';
import { connect } from 'react-redux';

const SearchListItem = ({
  mode,
  type,
  item,
  isLoggedIn,
  baseMediaUrl,
  baseMediaThumbUrl,
  showLoginPrompt,
  history,
  // updateReaction,
  // updateCommentCount,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
  isSearchResult
}) => {
  switch (type) {
    case ATTRACTION_SK:
      return (
        <AttractionListItem
          mode={mode}
          item={item}
          isLoggedIn={isLoggedIn}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          showLoginPrompt={showLoginPrompt}
          history={history}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
          isSearchResult={isSearchResult}
        />
      );
    case ARTICLE_SK:
      return (
        <ArticleListItem
          mode={mode}
          item={item}
          isLoggedIn={isLoggedIn}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          showLoginPrompt={showLoginPrompt}
          history={history}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
        />
      );
    case HOTEL_SK:
      return (
        <BusinessListItem
          mode={mode}
          type={type}
          item={item}
          isLoggedIn={isLoggedIn}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          showLoginPrompt={showLoginPrompt}
          history={history}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
          isSearchResult={isSearchResult}
        />
      );
    case RESTAURANT_SK:
      return (
        <BusinessListItem
          mode={mode}
          type={type}
          item={item}
          isLoggedIn={isLoggedIn}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          showLoginPrompt={showLoginPrompt}
          history={history}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
          isSearchResult={isSearchResult}
        />
      );
    // case POST_SK:
    //   return (
    //     <PostListItem
    //       item={item}
    //       isLoggedIn={isLoggedIn}
    //       baseMediaUrl={baseMediaUrl}
    //       baseMediaThumbUrl={baseMediaThumbUrl}
    //       showLoginPrompt={showLoginPrompt}
    //       history={history}
    //       updateReaction={updateReaction}
    //       updateCommentCount={updateCommentCount}
    //     />
    //   );
    // case USER_SK:
    //   return (
    //     <UserListItem
    //       mode={mode}
    //       item={item}
    //       isLoggedIn={isLoggedIn}
    //       baseMediaUrl={baseMediaUrl}
    //       baseMediaThumbUrl={baseMediaThumbUrl}
    //       showLoginPrompt={showLoginPrompt}
    //       history={history}
    //     />
    //   );
    default:
      return null;
  }
};

export default connect(null, {
  updateReaction,
  updateCommentCount,
})(SearchListItem);