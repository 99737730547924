// import React, { useEffect, useState } from 'react';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import {
//   Avatar,
//   Grid,
//   IconButton,
//   Badge,
//   makeStyles,
//   withStyles,
// } from '@material-ui/core';
// import { getPeriodicTime } from '../../utils';
// import Rating from '@mui/material/Rating';
// import Divider from '@mui/material/Divider';
// import { BLOGGER_SK, BUSINESS_SK, HOTEL_SK } from '../../constants';
// import HOTEL_MARK from '../../assets/images/icon_hotel_mark.png';
// import RESTAURANT_MARK from '../../assets/images/icon_restaurant_mark.png';
// import { translate } from '../../i18n';
// import articleProfileImg from '../../assets/images/article_profile.png';
// import cancelIcon from '../../assets/images/icon_remove_media.png';
// import { Box, Dialog, InputBase, Typography } from '@mui/material';
// import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';

// const StyledAvatar = withStyles((theme) => ({
//   root: {
//     border: `2px solid #FFB703`,
//   },
// }))(Avatar);

// const ReviewDetails = ({
//   open,
//   review: reviewData,
//   baseMediaUrl,
//   baseMediaThumbUrl,
//   setOpenDialog,
//   businessType,
//   handleClose: _handleClose,
// }) => {
//   const classes = useStyle();
//   const { replies } = reviewData;
//   const [review, setReview] = useState(reviewData);

//   useEffect(() => {
//     setTimeout(adjustTextareaHeight, 50);
//     if (!open) {
//       setReview(reviewData);
//     }
//   }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

//   useEffect(() => {
//     setReview(reviewData);
//   }, [reviewData]); // eslint-disable-line react-hooks/exhaustive-deps

//   const swap = (index) => {
//     setReview((prevState) => {
//       let data = [...prevState.mediaUrl];
//       let temp = data[0];
//       data[0] = data[index];
//       data[index] = temp;
//       return {
//         ...prevState,
//         mediaUrl: data,
//       };
//     });
//   };

//   const adjustTextareaHeight = () => {
//     let element = document.getElementById('review-description');
//     if (element) {
//       element.style.height = `${element.scrollHeight}px`;
//     }
//   };

//   const renderBusinessAvatar = (reply) => (
//     <Badge
//       overlap="circular"
//       anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'right',
//       }}
//       badgeContent={
//         <img
//           id="badge-img"
//           alt="badgeimg"
//           src={businessType === HOTEL_SK ? HOTEL_MARK : RESTAURANT_MARK}
//         />
//       }
//     >
//       <StyledAvatar>
//         <Avatar
//           id="create-post-avatar"
//           src={reply.profileImg.url.originalUrl}
//         />
//       </StyledAvatar>
//     </Badge>
//   );

//   const renderArticleAvatar = () => (
//     <Avatar id="create-post-avatar" src={articleProfileImg} />
//   );

//   const avatar =
//     review.userDetails?.userRole === BLOGGER_SK ? (
//       <Badge
//         overlap="circular"
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         badgeContent={
//           <img id="create-post-badge" alt="badgeimg" src={bloggerMarkIcon} />
//         }
//       >
//         <Avatar
//           src={
//             review.userDetails?.profileImg?.isThumGenerated
//               ? review.userDetails?.profileImg?.url?.thumbUrl
//               : review.userDetails?.profileImg?.url?.originalUrl
//           }
//           id="create-post-blogger-avatar"
//         />
//       </Badge>
//     ) : (
//       <Avatar
//         src={
//           review.userDetails?.profileImg?.isThumGenerated
//             ? review.userDetails?.profileImg?.url?.thumbUrl
//             : review.userDetails?.profileImg?.url?.originalUrl
//         }
//         id="create-post-avatar"
//       />
//     );

//   return (
//     <Dialog
//       open={open}
//       scroll="body"
//       onClose={_handleClose}
//       PaperProps={{
//         id: 'review-details-dialog-container',
//       }}
//     >
//       <div className="review-details-container">
//         <IconButton className={classes.closeButton} onClick={_handleClose}>
//           <img id="icon-remove-media" alt="icon" src={cancelIcon} />
//         </IconButton>
//         <Card className={classes.root}>
//           <CardHeader
//             avatar={avatar}
//             title={
//               <Typography
//                 id="create-post-profile-name"
//                 sx={{ lineHeight: '1.5 !important' }}
//               >
//                 {review.userDetails?.name}
//               </Typography>
//             }
//             subheader={
//               <Box id="create-review-subheader">
//                 {getPeriodicTime(review.createdAt)}
//               </Box>
//             }
//           />
//           <CardContent>
//             <div id="review-desc-container">
//               <div className="review-title">{review.title}</div>
//               <div>
//                 <Rating
//                   readOnly
//                   value={review.rating}
//                   precision={0.5}
//                   size="small"
//                 />
//               </div>
//               <InputBase
//                 id="review-description"
//                 value={review.description}
//                 multiline
//                 readOnly
//                 fullWidth
//               />
//             </div>
//           </CardContent>
//           {review.mediaUrl?.length !== 0 && (
//             <CardMedia id="review-details-media">
//               {review.mediaUrl?.length === 1 && (
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[0].filePath.originalUrl}
//                     />
//                   </Grid>
//                 </Grid>
//               )}
//               {review.mediaUrl?.length === 2 && (
//                 <Grid container spacing={2}>
//                   <Grid item xs={6}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[0]?.filePath.originalUrl}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[1].filePath.originalUrl}
//                     />
//                   </Grid>
//                 </Grid>
//               )}
//               {review.mediaUrl?.length === 3 && (
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[0].filePath.originalUrl}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Grid container spacing={1}>
//                       <Grid item xs={6}>
//                         <img
//                           alt="img"
//                           id="second"
//                           src={review.mediaUrl[1].filePath.originalUrl}
//                           onClick={() => swap(1)}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <img
//                           alt="img"
//                           id="second"
//                           src={review.mediaUrl[2].filePath.originalUrl}
//                           onClick={() => swap(2)}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )}
//               {review.mediaUrl?.length === 4 && (
//                 <Grid container spacing={1}>
//                   <Grid item xs={12}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[0].filePath.originalUrl}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Grid container spacing={1}>
//                       <Grid item xs={4}>
//                         <img
//                           alt="img"
//                           id="second"
//                           src={review.mediaUrl[1].filePath.originalUrl}
//                           onClick={() => swap(1)}
//                         />
//                       </Grid>
//                       <Grid item xs={4}>
//                         <img
//                           alt="img"
//                           id="second"
//                           src={review.mediaUrl[2].filePath.originalUrl}
//                           onClick={() => swap(2)}
//                         />
//                       </Grid>
//                       <Grid item xs={4}>
//                         <img
//                           alt="img"
//                           id="second"
//                           src={review.mediaUrl[3].filePath.originalUrl}
//                           onClick={() => swap(3)}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )}
//               {review.mediaUrl?.length > 4 && (
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <img
//                       alt="img"
//                       id="first"
//                       src={review.mediaUrl[0].filePath.originalUrl}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Grid container spacing={1}>
//                       {review.mediaUrl?.slice(1).map((media, key) => {
//                         return (
//                           <Grid item xs={3} key={key}>
//                             <img
//                               alt="img"
//                               id="second-sm"
//                               src={media.filePath.originalUrl}
//                               onClick={() => swap(key + 1)}
//                             />
//                           </Grid>
//                         );
//                       })}
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )}
//             </CardMedia>
//           )}
//           {replies?.length > 0 &&
//             replies.map((reply, key) => (
//               <div key={key} className="replies-section">
//                 <Divider />
//                 <CardHeader
//                   avatar={
//                     reply.type === BUSINESS_SK
//                       ? renderBusinessAvatar(reply)
//                       : renderArticleAvatar()
//                   }
//                   title={
//                     <Typography
//                       id="create-post-profile-name"
//                       sx={{ lineHeight: '1.5 !important' }}
//                     >
//                       {reply.type === BUSINESS_SK
//                         ? reply.businessName
//                         : translate('common.appName')}
//                     </Typography>
//                   }
//                   subheader={
//                     <Box id="create-review-subheader">
//                       {getPeriodicTime(reply.createdAt)}
//                     </Box>
//                   }
//                 />
//                 <div className="reply-description">
//                   <div id="description">{reply.description}</div>
//                 </div>
//               </div>
//             ))}
//         </Card>
//       </div>
//     </Dialog>
//   );
// };

// const useStyle = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute !important',
//     right: 0,
//     top: 0,
//     color: '#010D18 !important',
//     zIndex: 1000,
//   },
// }));
// export default ReviewDetails;


import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  Grid,
  IconButton,
  Badge,
  Box,
  Dialog,
  InputBase,
  Typography
} from '@mui/material';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import {
//   Avatar,
//   Grid,
//   IconButton,
//   Badge,
// } from '@material-ui/core';
import { getPeriodicTime } from '../../utils';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import {
  BLOGGER_SK,
  HOTEL_SK,
  BUSINESS_SK
} from '../../constants';
import HOTEL_MARK from '../../assets/images/icon_hotel_mark.png';
import RESTAURANT_MARK from '../../assets/images/icon_restaurant_mark.png';
import { translate } from '../../i18n';
import articleProfileImg from '../../assets/images/article_profile.png';
// import { Box, Dialog, InputBase, Typography } from '@mui/material';
import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';
import styles from './ReviewDetails.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import '../../css/variable.scss';

const ReviewDetails = ({
  open,
  review: reviewData,
  businessType,
  handleClose: _handleClose,
}) => {

  const { replies } = reviewData;
  const [review, setReview] = useState(reviewData);
  const [isPortrait, setIsPortrait] = useState(false)

  useEffect(() => {
    setTimeout(adjustTextareaHeight, 50);
    if (!open) {
      setReview(reviewData);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReview(reviewData);
  }, [reviewData]); // eslint-disable-line react-hooks/exhaustive-deps

  const swap = (index) => {
    setReview((prevState) => {
      let data = [...prevState.mediaUrl];
      let temp = data[0];
      data[0] = data[index];
      data[index] = temp;
      return {
        ...prevState,
        mediaUrl: data,
      };
    });
  };

  const adjustTextareaHeight = () => {
    let element = document.getElementById('review-description');
    if (element) {
      element.style.height = `${element.scrollHeight}px`;

      console.log(element.style.height, 'height')
    }
  };

  // Check the image of dimension for responsive design, and determining the visible area of an img element.
  const checkDimension = (e) => {
    const img = e.target;
    // "naturalWidth" and "naturalHeight" are properties of an HTML img element that provide the intrinsic (original) dimensions of the image file that the element is referencing.
    if (img.naturalHeight >= img.naturalWidth) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  }

  const renderBusinessAvatar = (reply) => (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <img
          id={styles["badge-img"]}
          alt="badgeimg"
          src={businessType === HOTEL_SK ? HOTEL_MARK : RESTAURANT_MARK}
        />
      }
    >
      <div className={styles["styled-avatar"]}>
        <Avatar
          id={styles["create-post-avatar"]}
          src={reply.profileImg.url.originalUrl}
        />
      </div>
    </Badge>
  );

  const renderArticleAvatar = () => (
    <Avatar id={styles["create-post-avatar"]} src={articleProfileImg} />
  );

  const avatar =
    review.userDetails?.userRole === BLOGGER_SK ? (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <img id={styles["create-post-badge"]} alt="badgeimg" src={bloggerMarkIcon} />
        }
      >
        <Avatar
          src={
            review.userDetails?.profileImg?.isThumGenerated
              ? review.userDetails?.profileImg?.url?.thumbUrl
              : review.userDetails?.profileImg?.url?.originalUrl
          }
          id={styles["create-post-blogger-avatar"]}
        />
      </Badge>
    ) : (
      <Avatar
        src={
          review.userDetails?.profileImg?.isThumGenerated
            ? review.userDetails?.profileImg?.url?.thumbUrl
            : review.userDetails?.profileImg?.url?.originalUrl
        }
        id={styles["create-post-avatar"]}
      />
    );

  return (
    <Dialog
      open={open}
      scroll="body"
      onClose={_handleClose}
      PaperProps={{
        id: styles['review-details-dialog-container'],
      }}
      disableScrollLock
    >
      <div className={styles["review-details-container"]}>
        <IconButton id={styles["close-button"]} onClick={_handleClose}>
          <CloseIcon />
        </IconButton>
        <Card >
          <CardHeader
            id={styles["review-header"]}
            avatar={avatar}
            title={
              <Typography
                id={styles["create-post-profile-name"]}
                sx={{ lineHeight: '1.5 !important' }}
              >
                {review.userDetails?.name}
              </Typography>
            }
            subheader={
              <Box id={styles["create-review-subheader"]}>
                {getPeriodicTime(review.createdAt)}
              </Box>
            }
          />
          <CardContent>
            <div id={styles["review-desc-container"]}>
              <div id={styles["rating"]}>
                <Rating
                  readOnly
                  value={review.rating}
                  precision={0.5}
                  size="medium"
                />
              </div>
              {review?.title && <div id={styles["create-review-title"]}>{review.title}</div>}
              {review.description && <InputBase
                id={styles["review-description"]}
                value={review.description}
                multiline
                readOnly
                fullWidth
              />}
            </div>
          </CardContent>
          {review.mediaUrl?.length !== 0 && (
            <CardMedia id={styles["review-details-media"]}>
              {review.mediaUrl?.length === 1 && (
                <Grid container>
                  <Grid item xs={12} id={styles["first-image-container"]}>
                    <img
                      alt="img"
                      onLoad={(e) => checkDimension(e)}
                      id={isPortrait ? styles["portrait"] : styles["first"]}
                      src={review.mediaUrl[0].filePath.originalUrl}
                    />
                  </Grid>
                </Grid>
              )}
              {review.mediaUrl?.length === 2 && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <img
                      alt="img"
                      id={styles["first"]}
                      src={review.mediaUrl[0]?.filePath.originalUrl}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      alt="img"
                      id={styles["first"]}
                      src={review.mediaUrl[1].filePath.originalUrl}
                    />
                  </Grid>
                </Grid>
              )}
              {review.mediaUrl?.length === 3 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} id={styles["first-image-container"]}>
                    <img
                      alt="img"
                      onLoad={(e) => checkDimension(e)}
                      id={isPortrait ? styles["portrait"] : styles["first"]}
                      src={review.mediaUrl[0].filePath.originalUrl}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <img
                          alt="img"
                          id={styles["second"]}
                          src={review.mediaUrl[1].filePath.originalUrl}
                          onClick={() => swap(1)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          alt="img"
                          id={styles["second"]}
                          src={review.mediaUrl[2].filePath.originalUrl}
                          onClick={() => swap(2)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {review.mediaUrl?.length === 4 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} id={styles["first-image-container"]}>
                    <img
                      alt="img"
                      onLoad={(e) => checkDimension(e)}
                      id={isPortrait ? styles["portrait"] : styles["first"]}
                      src={review.mediaUrl[0].filePath.originalUrl}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <img
                          alt="img"
                          id={styles["second"]}
                          src={review.mediaUrl[1].filePath.originalUrl}
                          onClick={() => swap(1)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          alt="img"
                          id={styles["second"]}
                          src={review.mediaUrl[2].filePath.originalUrl}
                          onClick={() => swap(2)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          alt="img"
                          id={styles["second"]}
                          src={review.mediaUrl[3].filePath.originalUrl}
                          onClick={() => swap(3)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {review.mediaUrl?.length > 4 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} id={styles["first-image-container"]}>
                    <img
                      alt="img"
                      onLoad={(e) => checkDimension(e)}
                      id={isPortrait ? styles["portrait"] : styles["first"]}
                      src={review.mediaUrl[0].filePath.originalUrl}

                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={styles["gallery-length-5"]}>
                      {review.mediaUrl?.slice(1).map((media, key) => {
                        return (
                          <Grid item key={key}>
                            <img
                              alt="img"
                              id={styles["second-sm"]}
                              src={media.filePath.originalUrl}
                              onClick={() => swap(key + 1)}
                            />
                          </Grid>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              )}
            </CardMedia>
          )}
          {replies?.length > 0 &&
            replies.map((reply, key) => (
              <div key={key} className="replies-section">
                <Divider />
                <CardHeader
                  id={styles["reply-header"]}
                  avatar={
                    reply.type === BUSINESS_SK
                      ? renderBusinessAvatar(reply)
                      : renderArticleAvatar()
                  }
                  title={
                    <Typography
                      id={styles["create-post-profile-name"]}
                      sx={{ lineHeight: '1.5 !important' }}
                    >
                      {reply.type === BUSINESS_SK
                        ? reply.businessName
                        : translate('common.appName')}
                    </Typography>
                  }
                  subheader={
                    <Box id={styles["create-review-subheader"]}>
                      {getPeriodicTime(reply.createdAt)}
                    </Box>
                  }
                />
                <div className={styles["reply-description"]}>
                  <InputBase
                    id={styles["description"]}
                    value={reply.description}
                    multiline
                    readOnly
                    fullWidth
                  />
                </div>
              </div>
            ))}
        </Card>
      </div>
    </Dialog>
  );
};

export default ReviewDetails;
