import { 
    FETCH_WEATHER_REQUEST,
    FETCH_EXCHANGE_RATE_REQUEST,
    FETCH_SOURCE_REQUEST,
    FETCH_DESTINATION_REQUEST,
    FETCH_ROUTE_REQUEST,
    FETCH_SERVICES_REQUEST,
    FETCH_CALCULATIONS_REQUEST,
    RESET_DATA,
    FETCH_HIGHWAY_CONTACT_REQUEST
 } from "../../constants";

/**
 * Initiate get weather api request.
 */
export const fetchWeather = (city = '', units = 'metric', exclude = 'hourly,minutely') => ({
    type: FETCH_WEATHER_REQUEST,
    payload: {
        city,
        units,
        exclude,
    }
});

/**
 * Initiate get exchange rate api request.
 */
 export const fetchExchangeRate = () => ({
    type: FETCH_EXCHANGE_RATE_REQUEST,
});

/**
 * Initiate get highway contact api request.
 */
 export const fetchHighwayContacts = () => ({
    type: FETCH_HIGHWAY_CONTACT_REQUEST,
});


/**
 * Initiate get source api request.
 */
 export const fetchSources = () => ({
    type: FETCH_SOURCE_REQUEST,
});

/**
* Initiate get destination api request.
*/
export const fetchDestinations = (source) => ({
    type: FETCH_DESTINATION_REQUEST,
    payload: {
        source
    },
});

/**
* Initiate get route api request.
*/
export const fetchRoutes = (sourceId, destinationId) => ({
type: FETCH_ROUTE_REQUEST,
payload: {
    sourceId,
    destinationId,
}
});

/**
* Initiate get route api request.
*/
export const fetchServices = (routeIdentifier) => ({
type: FETCH_SERVICES_REQUEST,
payload: {
    routeIdentifier
}
});

/**
* Initiate get route api request.
*/
export const fetchCalculations = (routeIdentifier, transportationId, accommodationId, mealPlanId, activityIds, person, day) => ({
type: FETCH_CALCULATIONS_REQUEST,
payload: {
    routeIdentifier,
    transportationId, 
    accommodationId, 
    mealPlanId, 
    activityIds, 
    person, 
    day
}
});

export const resetData = ()=>({
type: RESET_DATA
});