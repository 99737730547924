/**
 * Maps server error code
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  noErrorCodeFromServer: 910381,
  sessionExpired: '1000',
  phoneAlreadyExist: '1001',
  emailAlreadyExist: '1002',
  featurePrivate: '1003',
  blockedUser: '1004',
  invalidSession: '1172'
};
