import React, { useMemo } from 'react';
import { useLocation, Redirect } from 'react-router';
import {
    ATTRACTION_SK,
    ARTICLE_SK,
    HOTEL_SK,
    RESTAURANT_SK,
    POST_SK,
    RECOMMENDATIONS_LK,
    NEARBY_LK,
    LANDMARK_LK
} from '../../constants';
import { isBase64String } from '../../utils';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * RouteHandler component is responsible for validating the route and rendering its children.
 * If the route is invalid, it redirects to "/404".
 *
 * @param {object} children - The child components to be rendered.
 */
const RouteHandler = ({ children }) => {

    const { pathname } = useLocation();
    const query = useQuery();

    /**
     * Checks if the current route is valid.
     *
     * @returns {boolean} - Returns true if the route is valid, false otherwise.
     */
    const isRouteValid = () => {
        const paths = pathname.split('/'); // Split the pathname into an array of segments.
        const entityIdentifier = paths[1]; // Extract the entity identifier from the second segment.
        const type = paths[2]?.toLocaleLowerCase().replaceAll('%20', ' '); // Extract the type from the third segment, converting it to lowercase and replacing '%20' with spaces.
        const userIdentifier = query.get('id'); // Get the user identifier from the query parameters.
        const attractionList = paths[3]; // Extract the attraction list from the fourth segment.
        const businessIdentifier = query.get('id'); // Get the Business identifier from the query parameters.

        if (paths.length <= 5) {
            // Check for valid route conditions based on segment values and lengths.
            if (
                (type === ATTRACTION_SK ||
                    type === ARTICLE_SK ||
                    type === HOTEL_SK ||
                    type === RESTAURANT_SK ||
                    type === POST_SK) &&
                entityIdentifier !== 'list'
            ) {
                if (!isBase64String(entityIdentifier)) {
                    return false; // Invalid route if entity identifier is not a valid base64 string.
                }
            } else if (
                (type !== ATTRACTION_SK ||
                    type !== ARTICLE_SK ||
                    type !== HOTEL_SK ||
                    type !== RESTAURANT_SK ||
                    type !== POST_SK) &&
                paths[1] !== 'list' &&
                paths.length === 3
            ) {
                return false; // Invalid route if the type is not a valid entity type, the second segment is not 'list', and the length is 3.
            } else if (
                paths[1] === 'list' &&
                paths.length === 3 &&
                (type !== ATTRACTION_SK + 's' &&
                    type !== ARTICLE_SK + 's' &&
                    type !== HOTEL_SK + 's' &&
                    type !== RESTAURANT_SK + 's')
            ) {
                return false; // Invalid route if second segment is 'list', length is 3, and type is not a valid plural form.
            } else if (
                paths.length === 4 &&
                attractionList !== RECOMMENDATIONS_LK &&
                attractionList !== NEARBY_LK &&
                attractionList !== LANDMARK_LK
            ) {
                return false; // Invalid route if the length is 4 and attraction list is not valid.
            } else if (attractionList === LANDMARK_LK && businessIdentifier) {
                if (!isBase64String(businessIdentifier)) {
                    return false; // Invalid route if Business identifier is not a valid base64 string.
                }
            } else if (paths[1] === 'user') {
                if (!userIdentifier || !isBase64String(userIdentifier)) {
                    return false; // Invalid route if the segment is 'user' and user identifier is missing or not a valid base64 string.
                }
            }
        } else {
            return false
        }

        return true; // Route is valid if all checks pass.
    };

    if (!isRouteValid()) {
        return <Redirect to="/404" />; // Redirect to "/404" if the route is invalid.
    }

    return children; // Render the child components if the route is valid.
};

export default RouteHandler;
