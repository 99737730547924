import React, { useMemo, useState } from 'react';
import ItemCard from './components/ItemCard';
import {
  Menu,
  MenuItem,
  styled,
  Button as MuiButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getNetworkStatusOptions } from '../../../../config/networkStatusOptions';
import { BLOCKED_SK, CURRENT_USER_LK, FOLLOWING_SK, FRIEND_ACCEPT_PENDING_SK, FRIEND_REQUEST_DELETE_SK, FRIEND_REQUEST_PENDING_SK, FRIEND_SK, NO_CONNECTION_SK, UNFOLLOWED_SK, UNFRIEND_SK } from '../../../../constants';
import { translate } from '../../../../i18n';
import { api } from '../../../../api';
import { Button } from '../../../../common';

const ButtonOutlined = styled(MuiButton)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: '#FFB703',
  border: '1px solid #FFB703',
  color: '#010D18',
  textTransform: 'Capitalize',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#FFB703',
    border: '1px solid #FFB703',
  },
}));

const ButtonNoAction = styled(MuiButton)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'transparent',
  border: '1px solid #E0E0E0',
  boxShadow: 'none',
  color: '#000000',
  textTransform: 'Capitalize',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  '&:hover': {
    backgroundColor: 'transparent',
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
  },
}));

const FriendListItem = ({
  item,
  profileType,
  currentUserIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  updateUserFollowStatus,
  updateUserRelationStatus,
  updateFollowerCount,
  updateFollowingCount,
  showConfirmPrompt: _showConfirmPrompt,
  showSnack: _showSnack,
  reload: _reload,
}) => {
  const paperProps = {
    style: {
      backgroundColor: '#F2F2EE',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#6D787E',
    },
  };
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };
  const transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const networkStatusOptions = useMemo(() => getNetworkStatusOptions());// eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // relation status state.
  const [relationStatus, setRelationStatus] = useState(
    item.networkStatusWithCurrentUser.relationStatus,
  );

  // change relation status locally.
  const setRelationStatusLocally = networkStatus => {
    if (profileType === CURRENT_USER_LK) {
      return;
    }
    switch (networkStatus) {
      case FRIEND_REQUEST_PENDING_SK:
        setRelationStatus(FRIEND_REQUEST_PENDING_SK);
        break;
      case FRIEND_SK:
        setRelationStatus(FRIEND_SK);
        break;
      case FRIEND_ACCEPT_PENDING_SK:
        setRelationStatus(FRIEND_ACCEPT_PENDING_SK);
        break;
      default:
        setRelationStatus(NO_CONNECTION_SK);
        break;
    }
  };

  // call api to update member network status.
  const updateUserNetworkStatus = (networkStatus, user) => {
    api
      .updateMemberNetwork({
        status: networkStatus,
        userIdentifier: user.identifier,
      })
      .then(({ userResponse: { status } }) => {
        _showSnack(status);
        if (networkStatus === FOLLOWING_SK || networkStatus === UNFOLLOWED_SK) {
          updateUserFollowStatus(networkStatus, user.identifier);
          updateFollowingCount(networkStatus);
        }
        else if (networkStatus === FRIEND_REQUEST_PENDING_SK) {
          updateUserRelationStatus(networkStatus, user);
          setRelationStatusLocally(networkStatus);
          updateUserFollowStatus(FOLLOWING_SK, user.identifier);
        }
        else {
          updateUserRelationStatus(networkStatus, user);
          updateFollowerCount(UNFOLLOWED_SK);
          updateFollowingCount(UNFOLLOWED_SK);
        }
      });
  };

  const descriptionForDialog = networkStatus => {
    switch (networkStatus) {
      case BLOCKED_SK:
        return translate('userProfile.doYouWantToBlock');
      case FRIEND_REQUEST_DELETE_SK:
        return translate('userProfile.doYouWantToDeleteRequest');
      default:
        // UNFRIEND_SK
        return translate('userProfile.doYouWantToUnfriend');
    }
  };

  const showConfirmPrompt = (networkStatus, user) => {
    let data = {
      description: descriptionForDialog(networkStatus),
      positiveButtonCallback: () => {
        updateUserNetworkStatus(networkStatus, user);
      },
    };
    _showConfirmPrompt(data);
  };

  // check which relation or follow status is going to be updated.
  const updateFriendRelationship = (statusId, user) => {
    switch (statusId) {
      case 0:
        showConfirmPrompt(UNFRIEND_SK, user);
        break;
      case 1:
        if (user.networkStatusWithCurrentUser.followStatus === FOLLOWING_SK) {
          updateUserNetworkStatus(UNFOLLOWED_SK, user);
        } else {
          updateUserNetworkStatus(FOLLOWING_SK, user);
        }
        break;
      default:
        showConfirmPrompt(BLOCKED_SK, user);
        break;
    }
  };

  // render for action dropdown.
  const renderRow = (option, followStatus) => {
    let option_ = option;
    if (
      option.title === translate('common.unfollow') &&
      followStatus !== FOLLOWING_SK
    ) {
      option_ = {
        title: translate('common.follow'),
      };
    }
    return option_.title;
  };

  const action = (item) => {
    if (profileType === CURRENT_USER_LK) {
      return (
        <>
          <Button
            id='button'
            variant='contained'
            size='small'
            sx={{ height: '40px', }}
            onClick={handleMenuClick}
          >
            {translate('userProfile.friend')}
            <MoreVertIcon id='more-vert-icon' />
          </Button>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={isOpen}
            onClose={handleMenuClose}
            PaperProps={paperProps}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            {networkStatusOptions.map((option, key) => (
              <MenuItem
                key={key}
                onClick={() => {
                  handleMenuClose();
                  updateFriendRelationship(key, item);
                }}
              >
                <div className='menu-item'>
                  {renderRow(option, item.networkStatusWithCurrentUser.followStatus)}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </>
      );
    }
    else {
      if (item.identifier === currentUserIdentifier) {
        return null;
      }
      switch (relationStatus) {
        case FRIEND_REQUEST_PENDING_SK: {
          return (
            <ButtonNoAction
              id='action-button'
              variant='contained'
              size='small'
            >
              {translate('userProfile.invited')}
            </ButtonNoAction>
          );
        }
        case FRIEND_SK: {
          return (
            <ButtonNoAction
              id='action-button'
              variant='contained'
              size='small'
            >
              {translate('userProfile.friends')}
            </ButtonNoAction>
          );
        }
        case FRIEND_ACCEPT_PENDING_SK: {
          return (
            <ButtonNoAction
              id='action-button'
              variant='contained'
              size='small'
            >
              {translate('userProfile.requested')}
            </ButtonNoAction>
          );
        }
        default:
          return (
            <ButtonOutlined
              id='action-button'
              variant='contained'
              size='small'
              onClick={() => updateUserNetworkStatus(FRIEND_REQUEST_PENDING_SK, item)}
            >
              {translate('userProfile.addFriend')}
            </ButtonOutlined>
          );
      }
    }
  };

  return (
    <ItemCard
      item={item}
      baseMediaUrl={baseMediaUrl}
      baseMediaThumbUrl={baseMediaThumbUrl}
      action={action(item)}
      reload={_reload}
    />
  );
};

export default FriendListItem;