import { Status, } from '../../api';
import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    RESET_LOGIN_STATE,
    RESET_SIGNUP_STATE,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    VERIFY_OTP_LOADING,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_ERROR,
    RESEND_OTP_LOADING,
    RESEND_OTP_SUCCESS,
    RESEND_OTP_ERROR,
    RESET_OTP_VERIFICATION_STATE,
    FORGET_PASSWORD_LOADING,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    RESET_FORGET_PASSWORD_STATE,
    RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_CHANGE_PASSWORD_STATE,
    SAVE_OTP,
    SAVE_OTP_RECEIVE_TYPE,
    INITIAlIZE_INTEREST_LOADING,
    INITIAlIZE_INTEREST_SUCCESS,
    INITIALIZE_APP_ERROR,
    LOGOUT_LOADING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR

} from '../../constants';

const initialState = {
    // common, used by multiple screens
    userIdentifier: '',
    sessionId: '',
    otpExpiryTimeInSecs: 0,
    resendOtpStatus: Status.DEFAULT,
    resendOtpErrorMessage: '',
    otp: '',
    otp_type: '',
    // =========================================================
    // ==================== LoginScreen ========================
    // =========================================================
    loginStatus: Status.DEFAULT,
    loginErrorMessage: '',
    isLoginClear: false,
    // =========================================================
    // =============== ForgetPasswordScreen ====================
    // =========================================================
    forgetPasswordStatus: Status.DEFAULT,
    forgetPasswordErrorMessage: '',
    // =========================================================
    // =================== SignupScreen ========================
    // =========================================================
    signupStatus: Status.DEFAULT,
    signupErrorMessage: '',
    isSignupClear: false,
    // =========================================================
    // =============== OtpVerificationScreen ===================
    // =========================================================
    verifyOtpStatus: Status.DEFAULT,
    verifyOtpErrorMessage: '',
    // =========================================================
    // =============== Change Password Screen ==================
    // =========================================================
    resetPasswordStatus: Status.DEFAULT,
    resetPasswordSuccessMessage: '',
    resetPasswordErrorMessage: '',
    // =========================================================
    // =============== Interest List ===================
    // =========================================================
    initialInterestStatus: Status.DEFAULT,
    interestList: [],
    initialInterestErrorMessage: '',
    logoutStatus: Status.DEFAULT,
    logoutError: '',

}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginStatus: Status.LOADING,
                loginErrorMessage: '',
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginStatus: Status.SUCCESS,
                loginErrorMessage: '',
                sessionId: payload.sessionId,
                userIdentifier: payload.userIdentifier,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loginStatus: Status.ERROR,
                loginErrorMessage: payload.errorMessage,
            };
        case RESET_LOGIN_STATE:
            return {
                ...state,
                loginStatus: Status.DEFAULT,
                loginErrorMessage: '',
                isLoginClear: true,
            };
        case SIGNUP_LOADING:
            return {
                ...state,
                signupStatus: Status.LOADING,
                signupErrorMessage: '',
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signupStatus: Status.SUCCESS,
                signupErrorMessage: '',
                userIdentifier: payload.userIdentifier,
                otpExpiryTimeInSecs: payload.otpExpiryTimeInSecs,
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                signupStatus: Status.ERROR,
                signupErrorMessage: payload.errorMessage,
            };
        case RESET_SIGNUP_STATE:
            return {
                ...state,
                signupStatus: Status.DEFAULT,
                signupErrorMessage: '',
                otpExpiryTimeInSecs: 0,
                isSignupClear: true,
            };

        case VERIFY_OTP_LOADING:
            return {
                ...state,
                verifyOtpStatus: Status.LOADING,
                verifyOtpErrorMessage: '',
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOtpStatus: Status.SUCCESS,
                verifyOtpErrorMessage: '',
                sessionId: payload.sessionId,
                userIdentifier: payload.userIdentifier,
            };
        case VERIFY_OTP_ERROR:
            return {
                ...state,
                verifyOtpStatus: Status.ERROR,
                verifyOtpErrorMessage: payload.errorMessage,
            };
        case RESEND_OTP_LOADING:
            return {
                ...state,
                resendOtpStatus: Status.LOADING,
                resendOtpErrorMessage: '',
            };
        case RESEND_OTP_SUCCESS:
            return {
                ...state,
                resendOtpStatus: Status.SUCCESS,
                resendOtpErrorMessage: '',
            };
        case RESEND_OTP_ERROR:
            return {
                ...state,
                resendOtpStatus: Status.ERROR,
                resendOtpErrorMessage: payload.errorMessage,
            };
        case RESET_OTP_VERIFICATION_STATE:
            return {
                ...state,
                verifyOtpStatus: Status.DEFAULT,
                verifyOtpErrorMessage: '',
                resendOtpStatus: Status.DEFAULT,
                resendOtpErrorMessage: '',
            };
        case FORGET_PASSWORD_LOADING:
            return {
                ...state,
                forgetPasswordStatus: Status.LOADING,
                forgetPasswordErrorMessage: '',
            };
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetPasswordStatus: Status.SUCCESS,
                forgetPasswordErrorMessage: '',
                userIdentifier: payload.userIdentifier,
                otpExpiryTimeInSecs: payload.otpExpiryTimeInSecs,
            };
        case FORGET_PASSWORD_ERROR:
            return {
                ...state,
                forgetPasswordStatus: Status.ERROR,
                forgetPasswordErrorMessage: payload.errorMessage,
            };
        case RESET_FORGET_PASSWORD_STATE:
            return {
                ...state,
                forgetPasswordStatus: Status.DEFAULT,
                forgetPasswordErrorMessage: '',
                otpExpiryTimeInSecs: 0,
            };

        case RESET_PASSWORD_LOADING:
            return {
                ...state,
                resetPasswordStatus: Status.LOADING,
                resetPasswordErrorMessage: '',
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordStatus: Status.SUCCESS,
                resetPasswordSuccessMessage: payload.successMessage,
                resetPasswordErrorMessage: '',
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordStatus: Status.ERROR,
                resetPasswordErrorMessage: payload.errorMessage,
            };
        case RESET_CHANGE_PASSWORD_STATE:
            return {
                ...state,
                resetPasswordStatus: Status.DEFAULT,
                resetPasswordErrorMessage: '',
                resendOtpStatus: Status.DEFAULT,
                resendOtpErrorMessage: '',
            };
        case SAVE_OTP:
            return {
                ...state,
                otp: payload.otp
            }

        case SAVE_OTP_RECEIVE_TYPE:
            return {
                ...state,
                otp_type: payload.otp_type
            }

        case INITIAlIZE_INTEREST_LOADING:
            return {
                ...state,
                initialInterestStatus: Status.LOADING,
                initialInterestErrorMessage: '',
            };
        case INITIAlIZE_INTEREST_SUCCESS:
            return {
                ...state,
                initialInterestStatus: Status.SUCCESS,
                initialInterestErrorMessage: '',
                interestList: payload
            };
        case INITIALIZE_APP_ERROR:
            return {
                ...state,
                initialInterestStatus: Status.ERROR,
                initialInterestErrorMessage: payload.errorMessage,
            };
        case LOGOUT_LOADING:
            return {
                ...state,
                logoutStatus: Status.LOADING
            };
        case LOGOUT_SUCCESS:
            return {
                initialState,
                logoutStatus: Status.SUCCESS
            };
        case LOGOUT_ERROR:
            return {
                ...state,
                logoutStatus: Status.ERROR,
                logoutError: payload.errorMessage
            };
        default:
            return state;
    };

};