import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getBuildConfig } from '../../config/buildConfig';

const GOOGLE_RECAPTCHA_SITE_KEY = getBuildConfig().googleRecaptchaSiteKey;

const Recaptcha = ({ setRecaptchaToken }) => {
    const onChange = (value) => {
        setRecaptchaToken(value);
    }
    return (
        <ReCAPTCHA
            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChange}
        />
    );
}

export default Recaptcha;