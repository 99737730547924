import React, { useState } from 'react';
import { useScrollTrigger } from '@material-ui/core';
import CreateEditReviewModal from '../CreateEditReviewModal/CreateEditReviewModal.js';
import styles from './CreateReviewIcon.module.scss';
import StarIcon from '@mui/icons-material/Star';

const CreateReviewIcon = ({
  type,
  identifier,
  updateReviewList,
  title,
  updateAvgRating
}) => {
  const [open, setOpen] = useState(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const onClose = () => {
    setOpen(false);
    updateReviewList(false);
  };

  return (
    <>
      <div
        role="presentation"
        className={trigger ? styles.rootOnScroll : styles.root}
      >
        <StarIcon
          className={styles['float-icon']}
          onClick={() => setOpen(true)}
        />
        {/* <img
          alt="img"
          src={ICON}
          className={styles['float-icon']}
          onClick={() => setOpen(true)}
        /> */}
      </div>
      <CreateEditReviewModal
        open={open}
        type={type}
        identifier={identifier}
        updateReviewList={updateReviewList}
        onClose={onClose}
        // title={title}
        updateAvgRating={updateAvgRating}
      />
    </>
  );
};

export default CreateReviewIcon;
