import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchUserNetwork,
  updateUserFollowStatus,
  updateUserRelationStatus,
  resetUpdateMemberNetworkStatus,
} from '../../../../store/actions';
import {
  CURRENT_USER_LK,
  FRIEND_PROFILE_LK,
  FRIEND_SK,
  PRIVATE_SK,
  SERVER_ERROR_CODES,
} from '../../../../constants';
import { api, Status } from '../../../../api';
import { OutlinedInput, InputAdornment, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { translate } from '../../../../i18n';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import FriendListItem from './FriendListItem';
import _ from 'lodash';
import { isNonEmptyString } from '../../../../utils';

const CssTextField = styled(OutlinedInput)({
  '& fieldset': {
    border: '1px solid #979DA0',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
});

const FriendListTab = ({
  profileType,
  identifier,
  name,
  /**
   * Array which contain friends.
   *
   * @source redux
   */
  friends,
  /**
   * Tells about the status of the fetch user network api call, when pagination = 1
   *
   * @source redux
   */
  friendsStatus,
  /**
   * Tells about the status of the fetch user network api call, when pagination > 1
   *
   * @source redux
   */
  moreFriendsStatus,
  /**
   * check if more friends data is available
   */
  friendsAvailableForRefresh,
  /**
   * Base url need to append with image url to generate full url
   * @source redux
   */
  baseMediaUrl,
  baseMediaThumbUrl,
  /**
   * tell a friend button content to share
   * @source redux
   */
  tellFriendMsg,

  /**
   * Tells about the status of the update member network api call
   *
   * @source redux
   */
  updateMemberNetworkStatus,
  /**
   * If user is logged in, his/her unique identifier id,
   * empty in case of guest user
   *
   * @source redux
   */
  currentUserIdentifier,
  isPrivate: _isPrivate,
  /**
   * redux action to initiate fetch user network api request
   *
   * @source redux
   */
  fetchUserNetwork: _fetchUserNetwork,
  updateUserFollowStatus: _updateUserFollowStatus,
  updateUserRelationStatus: _updateUserRelationStatus,
  /**
   * redux action to reset update member network status
   *
   * @source redux
   */
  resetUpdateMemberNetworkStatus: _resetUpdateMemberNetworkStatus,
  updateFollowerCount: _updateFollowerCount,
  updateFollowingCount: _updateFollowingCount,
  showConfirmPrompt: _showConfirmPrompt,
  showSnack: _showSnack,
  reload: _reload,
}) => {
  const [page, setPage] = useState(1);
  const [friendList, setFriendList] = useState([]);
  const [friendsNotAvailable, setFriendsNotAvailable] = useState(false);
  const [friendsApiStatus, setFriendsApiStatus] = useState(Status.DEFAULT);
  // const [moreFriendsApiStatus, setMoreFriendsApiStatus] = useState(
  //   Status.DEFAULT
  // );
  const [otherFriendsAvailableForRefresh, setOtherFriendsAvailableForRefresh] =
    useState(_isPrivate !== PRIVATE_SK);
  const [isPrivate, setIsPrivate] = useState(_isPrivate === PRIVATE_SK);
  const [searchText, setSearchText] = useState('');

  // call action to initiate api call on pagination.
  useEffect(() => {
    if (!isPrivate || profileType === CURRENT_USER_LK) {
      if (profileType === CURRENT_USER_LK) {
        _fetchUserNetwork(FRIEND_SK, page);
      } else if (otherFriendsAvailableForRefresh) {
        fetchOtherUserFriends(identifier, page);
      }
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  // set friend list state on api success.
  useEffect(() => {
    if (
      profileType === CURRENT_USER_LK &&
      (friendsStatus === Status.SUCCESS ||
        moreFriendsStatus === Status.SUCCESS ||
        updateMemberNetworkStatus === Status.SUCCESS)
    ) {
      setFriendsNotAvailable(friends.length === 0);
      setFriendList(friends);
    }
  }, [friendsStatus, moreFriendsStatus, updateMemberNetworkStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateMemberNetworkStatus === Status.SUCCESS) {
      _resetUpdateMemberNetworkStatus();
    }
  }, [updateMemberNetworkStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch friends for a public user.
  const fetchOtherUserFriends = (userIdentifier, page_ = 1) => {
    if (page_ === 1) {
      setFriendsApiStatus(Status.LOADING);
    } else {
      //setMoreFriendsApiStatus(Status.LOADING);
    }
    api
      .fetchUserNetwork({
        networkStatus: FRIEND_SK,
        page: page_,
        userIdentifier,
      })
      .then(({ userResponse: { userNetworkList } }) => {
        if (page_ === 1) {
          setFriendsNotAvailable(userNetworkList.length === 0);
          setFriendList(userNetworkList);
          setFriendsApiStatus(Status.SUCCESS);
        } else {
          setFriendList((prevState) => [...prevState, ...userNetworkList]);
          //setMoreFriendsApiStatus(Status.SUCCESS);
        }
        setOtherFriendsAvailableForRefresh(userNetworkList.length > 0);
      })
      .catch((error) => {
        if (page_ === 1) {
          setFriendsApiStatus(Status.ERROR);
        } else {
          //setMoreFriendsApiStatus(Status.ERROR);
        }
        if (error.name === SERVER_ERROR_CODES.featurePrivate) {
          setIsPrivate(true);
          setOtherFriendsAvailableForRefresh(false);
        }
      });
  };

  const search = (e) => {
    setSearchText(e.target.value);
  };

  const hasMore =
    profileType === CURRENT_USER_LK
      ? friendsAvailableForRefresh
      : otherFriendsAvailableForRefresh;

  const loader = (
    <div className="spinner">
      <CircularProgress size={22} className="loading_spinner" />
    </div>
  );
  const endMessage = (
    <div className="nomore-data-info">
      {friendsNotAvailable ? (
        profileType === CURRENT_USER_LK ? (
          <span>{translate('userProfile.noFriendsAtCurrentUser')}</span>
        ) : (
          <span>
            {name}
            {translate('userProfile.noFriendsAtFriendProfile')}
          </span>
        )
      ) : isPrivate && profileType !== CURRENT_USER_LK ? (
        <span>{translate('userProfile.privateFriends')}</span>
      ) : (
        <span></span>
      )}
    </div>
  );

  const renderFriends = () => {
    return (
      <InfiniteScroll
        dataLength={friendList.length}
        next={() => setPage((prevState) => prevState + 1)}
        hasMore={hasMore}
        loader={loader}
        endMessage={endMessage}
      >
        {friendList
          .filter((friend) => {
            return (
              friend.name &&
              friend.name.toLowerCase().includes(searchText.toLowerCase())
            );
          })
          .map((friend, key) => (
            <FriendListItem
              key={key}
              item={friend}
              profileType={profileType}
              currentUserIdentifier={currentUserIdentifier}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              updateUserFollowStatus={_updateUserFollowStatus}
              updateUserRelationStatus={_updateUserRelationStatus}
              updateFollowerCount={_updateFollowerCount}
              updateFollowingCount={_updateFollowingCount}
              showConfirmPrompt={_showConfirmPrompt}
              showSnack={_showSnack}
              reload={_reload}
            />
          ))}
      </InfiniteScroll>
    );
  };

  const endAdornment = (
    <InputAdornment position="end">
      <SearchIcon />
    </InputAdornment>
  );

  return (
    <div className="friend-list-container">
      {profileType === CURRENT_USER_LK &&
        !friendsNotAvailable &&
        friendsStatus === Status.SUCCESS && (
          <div>
            <CssTextField
              placeholder="Search"
              size="small"
              endAdornment={endAdornment}
              onChange={_.debounce(search, 1500)}
            />
            {!isNonEmptyString(searchText) && (
              <div className="friends-count-label">
                {`${friendList.length > 1
                  ? translate('userProfile.friends')
                  : translate('userProfile.friend')
                  }`}
                &nbsp;&nbsp;
                <span id="count">{friendList.length}</span>
              </div>
            )}
          </div>
        )}
      {profileType === FRIEND_PROFILE_LK &&
        !friendsNotAvailable &&
        friendsApiStatus === Status.SUCCESS && (
          <div className="other-friends-count-label">
            {name}
            {translate('userProfile.userFriendList').replace('!@#$%', ' ')}
            &nbsp;&nbsp;
            <span id="count">{friendList.length}</span>
          </div>
        )}
      <div>{renderFriends()}</div>
    </div>
  );
};

const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl, tellFriendMsg } = app;
  const {
    friends,
    friendsStatus,
    moreFriendsStatus,
    friendsAvailableForRefresh,
    updateMemberNetworkStatus,
    userIdentifier: currentUserIdentifier,
  } = profile;
  return {
    friends,
    friendsStatus,
    moreFriendsStatus,
    friendsAvailableForRefresh,
    baseMediaUrl,
    baseMediaThumbUrl,
    updateMemberNetworkStatus,
    currentUserIdentifier,
    tellFriendMsg,
  };
};

export default connect(mapStateToProps, {
  fetchUserNetwork,
  updateUserFollowStatus,
  updateUserRelationStatus,
  resetUpdateMemberNetworkStatus,
})(FriendListTab);
