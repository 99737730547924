import React, { useState } from "react";

/* import components provided by library Material-UI */
import {
    Grid,
    Avatar,
    Rating,
    Badge
} from "@mui/material";


/* From Utils */
import { getPeriodicTime } from "../../utils";

/* Language translation */
import { translate } from "../../i18n";

/* Constant */
import {
    BLOGGER_SK,
    LIMITS
} from "../../constants";

/*Import Icons from assets */
import openingIcon from "../../assets/images/icon_opening_text.png";
import closingIcon from "../../assets/images/icon_closing_text.png";
import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';

import ReviewDetails from "../ReviewDetails/ReviewDetails";
import cn from "classnames";
import styles from "./Reviews.module.scss";

const ReviewListItem = ({
    reviewList,
    isBusiness
}) => {

    const [reviewDetailsOpen, setReviewDetailsOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState({});

    const seeReviewDetails = review => {
        setSelectedReview(review);
        setReviewDetailsOpen(true);
    }

    const renderAvatar = ({ userDetails }) => {
        return userDetails?.userRole === BLOGGER_SK ? (
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={
                    <img id={styles["create-post-badge"]} alt="badgeimg" src={bloggerMarkIcon} />
                }
            >
                <Avatar
                    src={
                        userDetails?.profileImg?.isThumGenerated
                            ? userDetails?.profileImg?.url?.thumbUrl
                            : userDetails?.profileImg?.url?.originalUrl
                    }
                    id={styles["create-post-blogger-avatar"]}
                />
            </Badge>
        ) : (
            <Avatar
                src={
                    userDetails?.profileImg?.isThumGenerated
                        ? userDetails?.profileImg?.url?.thumbUrl
                        : userDetails?.profileImg?.url?.originalUrl
                }
                id={styles["create-post-avatar"]}
            />
        );
        // <Avatar
        //     src={userDetails.profileImg?.isThumbGenerated ?
        //         userDetails.profileImg?.url?.thumbUrl
        //         : userDetails.profileImg?.url?.originalUrl}
        //     id={styles["review-profile-avatar"]}
        // />
    };

    return (<div className='review-details'>
        <div className='details'>
            <Grid container spacing={{ xs: 2, md: 2 }}>
                {reviewList.map((item, key) => {
                    return (
                        <Grid
                            key={key}
                            item
                            xs={12}
                            sm={6}
                            md={isBusiness ? 6 : 4}
                        >
                            <div className='details-item'>

                                <div className={cn(styles["preview-text-light"], styles["bubble-light"])} onClick={() => seeReviewDetails(item)}>
                                    <div className={styles['preview-item']}>
                                        <div>
                                            <div className={styles["rating-icons"]}>
                                                <Rating readOnly value={item.rating} precision={0.5} size='small' />
                                            </div>
                                            {item.description &&
                                                <div className={styles["review-description"]}>
                                                    <img alt='img' src={openingIcon} className={styles["left-quotation"]} /><br />
                                                    <span className='text'>
                                                        {item.description?.length > LIMITS.REVIEW_PREVIEW_MAX_LENGTH
                                                            ? <>
                                                                <span>
                                                                    {`${item.description?.slice(0, LIMITS.REVIEW_PREVIEW_MAX_LENGTH)}`}
                                                                </span>
                                                                <span className={styles["more"]} >...{translate('common.more')}</span>
                                                            </>
                                                            : <span>{item.description}</span>
                                                        }
                                                    </span>&nbsp;&nbsp;
                                                    <img alt='img' src={closingIcon} className={styles["right-quotation"]} />
                                                </div>
                                            }
                                            <div>
                                                {item.mediaUrl.length !== 0 &&
                                                    <div className={styles["review-item-image-container"]} >
                                                        {item.mediaUrl.map((media, key) => {
                                                            return <img key={key} alt='img' src={media?.filePath?.originalUrl} className={styles["review-item-image"]} />
                                                        })}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['person-info']}>
                                    {renderAvatar(item)}
                                    <div className={styles['info']}>
                                        <div className={styles['name']}>{item.userDetails.name}</div>
                                        <div className={styles['city']}>{item.userDetails.location}</div>
                                        <div className={styles['time']}>{getPeriodicTime(item.createdAt)}</div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
        <ReviewDetails
            open={reviewDetailsOpen}
            review={selectedReview}
            handleClose={() => setReviewDetailsOpen(false)}
        />
    </div >
    )
};

export default ReviewListItem;