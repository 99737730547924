/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import {
  Card,
  CardContent,
  IconButton
} from "@mui/material";

import {
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon
} from "@mui/icons-material";

import {
  ARTICLE_SK,
  SAVE_SK,
  ASYNC_STORAGE_KEYS,
} from "../../../constants";

import { api, Status } from "../../../api";
import { history } from "../../../store";

import styles from "../../../components/AttractionDetails/AttractionDetails.module.scss";

const ArticleByAttractionItem = ({
  item,
  saveSuccessCallback,
  unsaveSuccessCallback
}) => {

  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  const goToArticle = () => {
    history.push(`/${btoa(item.identifier)}/${ARTICLE_SK}`);
  };

  const handleOnSave = (item) => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);

      if (item.isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: item.identifier,
            entityType: ARTICLE_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse: { favouriteIdentifier } }) => {
            saveSuccessCallback(item.identifier, favouriteIdentifier);
            setApiStatus(Status.SUCCESS);
          })
          .catch(() => {
            setApiStatus(Status.ERROR);
          });
      } else {
        api
          .deleteFavourite({
            favouriteIdentifier: item.favouriteIdentifier,
          })
          .then(() => {
            unsaveSuccessCallback(item.identifier);
            setApiStatus(Status.SUCCESS);
          })
          .catch(() => {
            setApiStatus(Status.ERROR);
          });
      }
    }
  };

  return (
    <Card id={styles["carousel-card"]}>
      <CardContent id={styles["related-article-box"]}>
        <div className={styles["carousel-div"]} id={styles["article-box"]}>
          <IconButton
            id={styles["recommend-save-icon"]}
            onClick={() => handleOnSave(item)}
          >
            {item.isSaved === SAVE_SK ? (
              <BookmarkBorderIcon className={styles["save-icon-style"]} />
            ) : (
              <BookmarkIcon className={styles["save-icon-style"]} />
            )}
          </IconButton>
          <img
            alt="img"
            onClick={goToArticle}
            src={item.coverImg.url.originalUrl}
            id={styles["related-article-box-img"]}
          />
          <div id={styles["article-title"]}>
            <span>{item.title}</span>
          </div>
          <div id={styles["img-protected"]} onClick={goToArticle}></div>
          <div id={styles["article-overlay"]} onClick={goToArticle}>
            <p>{item.shortDescription}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ArticleByAttractionItem;
