import React from "react";
import { connect } from "react-redux";
import { resetLoginState } from "../../../store/actions";
import { translate } from "../../../i18n";
import styles from "../Authentication.module.scss";

const SignupInstruction = (props) => {
  const { toggleSignupMode, resetLoginState } = props;

  const signupBtnclick = () => {
    toggleSignupMode(false);
    resetLoginState();
  };

  return (
    <div>
      <div className={styles["panel-inside"]}>
        <div className={styles["content"]}>
          <div className={styles["panel-title"]}>
            <div>{translate("signinScreen.signupTitle")}</div>
            <div>{translate("signinScreen.signupInfo")}</div>
          </div>
          <button id={styles["sign-up-btn"]} onClick={signupBtnclick}>
            {translate("common.signup")}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { isloginClear } = auth;

  return {
    isloginClear,
  };
};

export default connect(mapStateToProps, {
  resetLoginState,
})(SignupInstruction);
