/* eslint-disable global-require */
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    countryName: 'Afghanistan',
    countryCode: '+93',
    flag: require('../assets/countryFlags/Afghanistan_93.png'),
  },
  {
    countryName: 'Albania',
    countryCode: '+355',
    flag: require('../assets/countryFlags/Albania_355.png'),
  },
  {
    countryName: 'Algeria',
    countryCode: '+213',
    flag: require('../assets/countryFlags/Algeria_213.png'),
  },
  {
    countryName: 'American Samoa',
    countryCode: '+1684',
    flag: require('../assets/countryFlags/American_Samoa_1684.png'),
  },
  {
    countryName: 'Andorra',
    countryCode: '+376',
    flag: require('../assets/countryFlags/Andorra_376.png'),
  },
  {
    countryName: 'Angola',
    countryCode: '+244',
    flag: require('../assets/countryFlags/Angola_244.png'),
  },
  {
    countryName: 'Anguilla',
    countryCode: '+1264',
    flag: require('../assets/countryFlags/Anguilla_1264.png'),
  },
  {
    countryName: 'Antigua And Barbuda',
    countryCode: '+1268',
    flag: require('../assets/countryFlags/Antigua_And_Barbuda_1268.png'),
  },
  {
    countryName: 'Argentina',
    countryCode: '+54',
    flag: require('../assets/countryFlags/Argentina_54.png'),
  },
  {
    countryName: 'Armenia',
    countryCode: '+374',
    flag: require('../assets/countryFlags/Armenia_374.png'),
  },
  {
    countryName: 'Aruba',
    countryCode: '+297',
    flag: require('../assets/countryFlags/Aruba_297.png'),
  },
  {
    countryName: 'Australia',
    countryCode: '+61',
    flag: require('../assets/countryFlags/Australia_61.png'),
  },
  {
    countryName: 'Austria',
    countryCode: '+43',
    flag: require('../assets/countryFlags/Austria_43.png'),
  },
  {
    countryName: 'Azerbaijan',
    countryCode: '+994',
    flag: require('../assets/countryFlags/Azerbaijan_994.png'),
  },
  {
    countryName: 'Bahamas',
    countryCode: '+1242',
    flag: require('../assets/countryFlags/Bahamas_1242.png'),
  },
  {
    countryName: 'Bahrain',
    countryCode: '+973',
    flag: require('../assets/countryFlags/Bahrain_973.png'),
  },
  {
    countryName: 'Bangladesh',
    countryCode: '+880',
    flag: require('../assets/countryFlags/Bangladesh_880.png'),
  },
  {
    countryName: 'Barbados',
    countryCode: '+1246',
    flag: require('../assets/countryFlags/Barbados_1246.png'),
  },
  {
    countryName: 'Belarus',
    countryCode: '+375',
    flag: require('../assets/countryFlags/Belarus_375.png'),
  },
  {
    countryName: 'Belgium',
    countryCode: '+32',
    flag: require('../assets/countryFlags/Belgium_32.png'),
  },
  {
    countryName: 'Belize',
    countryCode: '+501',
    flag: require('../assets/countryFlags/Belize_501.png'),
  },
  {
    countryName: 'Benin',
    countryCode: '+229',
    flag: require('../assets/countryFlags/Benin_229.png'),
  },
  {
    countryName: 'Bermuda',
    countryCode: '+1441',
    flag: require('../assets/countryFlags/Bermuda_1441.png'),
  },
  {
    countryName: 'Bhutan',
    countryCode: '+975',
    flag: require('../assets/countryFlags/Bhutan_975.png'),
  },
  {
    countryName: 'Bolivia',
    countryCode: '+591',
    flag: require('../assets/countryFlags/Bolivia_591.png'),
  },
  {
    countryName: 'Bonaire',
    countryCode: '+5997',
    flag: require('../assets/countryFlags/Bonaire_5997.png'),
  },
  {
    countryName: 'Bosnia And Herzegovina',
    countryCode: '+387',
    flag: require('../assets/countryFlags/Bosnia_And_Herzegovina_387.png'),
  },
  {
    countryName: 'Brazil',
    countryCode: '+55',
    flag: require('../assets/countryFlags/Brazil_55.png'),
  },
  {
    countryName: 'Brunei',
    countryCode: '+673',
    flag: require('../assets/countryFlags/Brunei_673.png'),
  },
  {
    countryName: 'Bulgaria',
    countryCode: '+359',
    flag: require('../assets/countryFlags/Bulgaria_359.png'),
  },
  {
    countryName: 'Burkina Faso',
    countryCode: '+226',
    flag: require('../assets/countryFlags/Burkina_Faso_226.png'),
  },
  {
    countryName: 'Burundi',
    countryCode: '+257',
    flag: require('../assets/countryFlags/Burundi_257.png'),
  },
  {
    countryName: 'Cambodia',
    countryCode: '+855',
    flag: require('../assets/countryFlags/Cambodia_855.png'),
  },
  {
    countryName: 'Cameroon',
    countryCode: '+237',
    flag: require('../assets/countryFlags/Cameroon_237.png'),
  },
  {
    countryName: 'Canada',
    countryCode: '+1',
    flag: require('../assets/countryFlags/Canada_1.png'),
  },
  {
    countryName: 'Cabo Verde',
    countryCode: '+238',
    flag: require('../assets/countryFlags/Cabo_Verde_238.png'),
  },
  {
    countryName: 'Cayman Islands',
    countryCode: '+1345',
    flag: require('../assets/countryFlags/Cayman_Islands_1345.png'),
  },
  {
    countryName: 'Central African Republic',
    countryCode: '+236',
    flag: require('../assets/countryFlags/Central_African_Republic_236.png'),
  },
  {
    countryName: 'Chile',
    countryCode: '+56',
    flag: require('../assets/countryFlags/Chile_56.png'),
  },
  {
    countryName: 'China',
    countryCode: '+86',
    flag: require('../assets/countryFlags/China_86.png'),
  },
  {
    countryName: 'Colombia',
    countryCode: '+57',
    flag: require('../assets/countryFlags/Colombia_57.png'),
  },
  {
    countryName: 'Congo',
    countryCode: '+242',
    flag: require('../assets/countryFlags/Congo_242.png'),
  },
  {
    countryName: 'Cook Islands',
    countryCode: '+682',
    flag: require('../assets/countryFlags/Cook_Islands_682.png'),
  },
  {
    countryName: 'Costa Rica',
    countryCode: '+506',
    flag: require('../assets/countryFlags/Costa_Rica_506.png'),
  },
  {
    countryName: 'Croatia',
    countryCode: '+385',
    flag: require('../assets/countryFlags/Croatia_385.png'),
  },
  {
    countryName: 'Cuba',
    countryCode: '+53',
    flag: require('../assets/countryFlags/Cuba_53.png'),
  },
  {
    countryName: 'Cyprus',
    countryCode: '+357',
    flag: require('../assets/countryFlags/Cyprus_357.png'),
  },
  {
    countryName: 'Czech Republic',
    countryCode: '+420',
    flag: require('../assets/countryFlags/Czech_Republic_420.png'),
  },
  {
    countryName: 'Denmark',
    countryCode: '+45',
    flag: require('../assets/countryFlags/Denmark_45.png'),
  },
  {
    countryName: 'Djibouti',
    countryCode: '+253',
    flag: require('../assets/countryFlags/Djibouti_253.png'),
  },
  {
    countryName: 'Dominica',
    countryCode: '+1767',
    flag: require('../assets/countryFlags/Dominica_1767.png'),
  },
  {
    countryName: 'Dominican Republic',
    countryCode: '+1809',
    flag: require('../assets/countryFlags/Dominican_Republic_1809.png'),
  },
  {
    countryName: 'Ecuador',
    countryCode: '+593',
    flag: require('../assets/countryFlags/Ecuador_593.png'),
  },
  {
    countryName: 'Egypt',
    countryCode: '+20',
    flag: require('../assets/countryFlags/Egypt_20.png'),
  },
  {
    countryName: 'El Salvador',
    countryCode: '+503',
    flag: require('../assets/countryFlags/El_Salvador_503.png'),
  },
  {
    countryName: 'Equatorial Guinea',
    countryCode: '+240',
    flag: require('../assets/countryFlags/Equatorial_Guinea_240.png'),
  },
  {
    countryName: 'Eritrea',
    countryCode: '+291',
    flag: require('../assets/countryFlags/Eritrea_291.png'),
  },
  {
    countryName: 'Estonia',
    countryCode: '+372',
    flag: require('../assets/countryFlags/Estonia_372.png'),
  },
  {
    countryName: 'Ethiopia',
    countryCode: '+251',
    flag: require('../assets/countryFlags/Ethiopia_251.png'),
  },
  {
    countryName: 'Falkland Islands',
    countryCode: '+500',
    flag: require('../assets/countryFlags/Falkland_Islands_500.png'),
  },
  {
    countryName: 'Faroe Islands',
    countryCode: '+298',
    flag: require('../assets/countryFlags/Faroe_Islands_298.png'),
  },
  {
    countryName: 'Fiji',
    countryCode: '+679',
    flag: require('../assets/countryFlags/Fiji_679.png'),
  },
  {
    countryName: 'Finland',
    countryCode: '+358',
    flag: require('../assets/countryFlags/Finland_358.png'),
  },
  {
    countryName: 'France',
    countryCode: '+33',
    flag: require('../assets/countryFlags/France_33.png'),
  },
  {
    countryName: 'French Guiana',
    countryCode: '+594',
    flag: require('../assets/countryFlags/French_Guiana_594.png'),
  },
  {
    countryName: 'French Polynesia',
    countryCode: '+689',
    flag: require('../assets/countryFlags/French_Polynesia_689.png'),
  },
  {
    countryName: 'Gabon',
    countryCode: '+241',
    flag: require('../assets/countryFlags/Gabon_241.png'),
  },
  {
    countryName: 'Gambia',
    countryCode: '+220',
    flag: require('../assets/countryFlags/Gambia_220.png'),
  },
  {
    countryName: 'Georgia',
    countryCode: '+995',
    flag: require('../assets/countryFlags/Georgia_995.png'),
  },
  {
    countryName: 'Germany',
    countryCode: '+49',
    flag: require('../assets/countryFlags/Germany_49.png'),
  },
  {
    countryName: 'Ghana',
    countryCode: '+233',
    flag: require('../assets/countryFlags/Ghana_233.png'),
  },
  {
    countryName: 'Gibraltar',
    countryCode: '+350',
    flag: require('../assets/countryFlags/Gibraltar_350.png'),
  },
  {
    countryName: 'Greece',
    countryCode: '+30',
    flag: require('../assets/countryFlags/Greece_30.png'),
  },
  {
    countryName: 'Greenland',
    countryCode: '+299',
    flag: require('../assets/countryFlags/Greenland_299.png'),
  },
  {
    countryName: 'Grenada',
    countryCode: '+1473',
    flag: require('../assets/countryFlags/Grenada_1473.png'),
  },
  {
    countryName: 'Guam',
    countryCode: '+1671',
    flag: require('../assets/countryFlags/Guam_1671.png'),
  },
  {
    countryName: 'Guatemala',
    countryCode: '+502',
    flag: require('../assets/countryFlags/Guatemala_502.png'),
  },
  {
    countryName: 'Haiti',
    countryCode: '+509',
    flag: require('../assets/countryFlags/Haiti_509.png'),
  },
  {
    countryName: 'Honduras',
    countryCode: '+504',
    flag: require('../assets/countryFlags/Honduras_504.png'),
  },
  {
    countryName: 'Hong Kong',
    countryCode: '+852',
    flag: require('../assets/countryFlags/Hong_Kong_852.png'),
  },
  {
    countryName: 'Hungary',
    countryCode: '+36',
    flag: require('../assets/countryFlags/Hungary_36.png'),
  },
  {
    countryName: 'Iceland',
    countryCode: '+354',
    flag: require('../assets/countryFlags/Iceland_354.png'),
  },
  {
    countryName: 'India',
    countryCode: '+91',
    flag: require('../assets/countryFlags/India_91.png'),
  },
  {
    countryName: 'Indonesia',
    countryCode: '+62',
    flag: require('../assets/countryFlags/Indonesia_62.png'),
  },
  {
    countryName: 'Iran',
    countryCode: '+98',
    flag: require('../assets/countryFlags/Iran_98.png'),
  },
  {
    countryName: 'Iraq',
    countryCode: '+964',
    flag: require('../assets/countryFlags/Iraq_964.png'),
  },
  {
    countryName: 'Ireland',
    countryCode: '+353',
    flag: require('../assets/countryFlags/Ireland_353.png'),
  },
  {
    countryName: 'Israel',
    countryCode: '+972',
    flag: require('../assets/countryFlags/Israel_972.png'),
  },
  {
    countryName: 'Italy',
    countryCode: '+39',
    flag: require('../assets/countryFlags/Italy_39.png'),
  },
  {
    countryName: 'Jamaica',
    countryCode: '+1876',
    flag: require('../assets/countryFlags/Jamaica_1876.png'),
  },
  {
    countryName: 'Japan',
    countryCode: '+81',
    flag: require('../assets/countryFlags/Japan_81.png'),
  },
  {
    countryName: 'Jordan',
    countryCode: '+962',
    flag: require('../assets/countryFlags/Jordan_962.png'),
  },
  {
    countryName: 'Kazakhstan',
    countryCode: '+76',
    flag: require('../assets/countryFlags/Kazakhstan_76.png'),
  },
  {
    countryName: 'Kenya',
    countryCode: '+254',
    flag: require('../assets/countryFlags/Kenya_254.png'),
  },
  {
    countryName: 'Kuwait',
    countryCode: '+965',
    flag: require('../assets/countryFlags/Kuwait_965.png'),
  },
  {
    countryName: 'Kyrgyzstan',
    countryCode: '+996',
    flag: require('../assets/countryFlags/Kyrgyzstan_996.png'),
  },
  {
    countryName: 'Laos',
    countryCode: '+856',
    flag: require('../assets/countryFlags/Laos_856.png'),
  },
  {
    countryName: 'Latvia',
    countryCode: '+371',
    flag: require('../assets/countryFlags/Latvia_371.png'),
  },
  {
    countryName: 'Lebanon',
    countryCode: '+961',
    flag: require('../assets/countryFlags/Lebanon_961.png'),
  },
  {
    countryName: 'Lesotho',
    countryCode: '+266',
    flag: require('../assets/countryFlags/Lesotho_266.png'),
  },
  {
    countryName: 'Liberia',
    countryCode: '+231',
    flag: require('../assets/countryFlags/Liberia_231.png'),
  },
  {
    countryName: 'Libya',
    countryCode: '+218',
    flag: require('../assets/countryFlags/Libya_218.png'),
  },
  {
    countryName: 'Liechtenstein',
    countryCode: '+423',
    flag: require('../assets/countryFlags/Liechtenstein_423.png'),
  },
  {
    countryName: 'Lithuania',
    countryCode: '+370',
    flag: require('../assets/countryFlags/Lithuania_370.png'),
  },
  {
    countryName: 'Luxembourg',
    countryCode: '+352',
    flag: require('../assets/countryFlags/Luxembourg_352.png'),
  },
  {
    countryName: 'Macedonia',
    countryCode: '+389',
    flag: require('../assets/countryFlags/Macedonia_389.png'),
  },
  {
    countryName: 'Madagascar',
    countryCode: '+261',
    flag: require('../assets/countryFlags/Madagascar_261.png'),
  },
  {
    countryName: 'Malawi',
    countryCode: '+265',
    flag: require('../assets/countryFlags/Malawi_265.png'),
  },
  {
    countryName: 'Malaysia',
    countryCode: '+60',
    flag: require('../assets/countryFlags/Malaysia_60.png'),
  },
  {
    countryName: 'Maldives',
    countryCode: '+960',
    flag: require('../assets/countryFlags/Maldives_960.png'),
  },
  {
    countryName: 'Mali',
    countryCode: '+223',
    flag: require('../assets/countryFlags/Mali_223.png'),
  },
  {
    countryName: 'Malta',
    countryCode: '+356',
    flag: require('../assets/countryFlags/Malta_356.png'),
  },
  {
    countryName: 'Martinique',
    countryCode: '+596',
    flag: require('../assets/countryFlags/Martinique_596.png'),
  },
  {
    countryName: 'Mauritania',
    countryCode: '+222',
    flag: require('../assets/countryFlags/Mauritania_222.png'),
  },
  {
    countryName: 'Mauritius',
    countryCode: '+230',
    flag: require('../assets/countryFlags/Mauritius_230.png'),
  },
  {
    countryName: 'Mexico',
    countryCode: '+52',
    flag: require('../assets/countryFlags/Mexico_52.png'),
  },
  {
    countryName: 'Micronesia',
    countryCode: '+691',
    flag: require('../assets/countryFlags/Micronesia_691.png'),
  },
  {
    countryName: 'Moldova',
    countryCode: '+373',
    flag: require('../assets/countryFlags/Moldova_373.png'),
  },
  {
    countryName: 'Monaco',
    countryCode: '+377',
    flag: require('../assets/countryFlags/Monaco_377.png'),
  },
  {
    countryName: 'Mongolia',
    countryCode: '+976',
    flag: require('../assets/countryFlags/Mongolia_976.png'),
  },
  {
    countryName: 'Montenegro',
    countryCode: '+382',
    flag: require('../assets/countryFlags/Montenegro_382.png'),
  },
  {
    countryName: 'Morocco',
    countryCode: '+212',
    flag: require('../assets/countryFlags/Morocco_212.png'),
  },
  {
    countryName: 'Myanmar',
    countryCode: '+95',
    flag: require('../assets/countryFlags/Myanmar_95.png'),
  },
  {
    countryName: 'Namibia',
    countryCode: '+264',
    flag: require('../assets/countryFlags/Namibia_264.png'),
  },
  {
    countryName: 'Nepal',
    countryCode: '+977',
    flag: require('../assets/countryFlags/Nepal_977.png'),
  },
  {
    countryName: 'Netherlands',
    countryCode: '+31',
    flag: require('../assets/countryFlags/Netherlands_31.png'),
  },
  {
    countryName: 'New Caledonia',
    countryCode: '+687',
    flag: require('../assets/countryFlags/New_Caledonia_687.png'),
  },
  {
    countryName: 'New Zealand',
    countryCode: '+64',
    flag: require('../assets/countryFlags/New_Zealand_64.png'),
  },
  {
    countryName: 'Nicaragua',
    countryCode: '+505',
    flag: require('../assets/countryFlags/Nicaragua_505.png'),
  },
  {
    countryName: 'Niger',
    countryCode: '+227',
    flag: require('../assets/countryFlags/Niger_227.png'),
  },
  {
    countryName: 'Nigeria',
    countryCode: '+234',
    flag: require('../assets/countryFlags/Nigeria_234.png'),
  },
  {
    countryName: 'North Korea',
    countryCode: '+850',
    flag: require('../assets/countryFlags/North_Korea_850.png'),
  },
  {
    countryName: 'Northern Mariana Islands',
    countryCode: '+1670',
    flag: require('../assets/countryFlags/Northern_Mariana_Islands_1670.png'),
  },
  {
    countryName: 'Norway',
    countryCode: '+47',
    flag: require('../assets/countryFlags/Norway_47.png'),
  },
  {
    countryName: 'Oman',
    countryCode: '+968',
    flag: require('../assets/countryFlags/Oman_968.png'),
  },
  {
    countryName: 'Pakistan',
    countryCode: '+92',
    flag: require('../assets/countryFlags/Pakistan_92.png'),
  },
  {
    countryName: 'Palau',
    countryCode: '+680',
    flag: require('../assets/countryFlags/Palau_680.png'),
  },
  {
    countryName: 'Panama',
    countryCode: '+507',
    flag: require('../assets/countryFlags/Panama_507.png'),
  },
  {
    countryName: 'Papua New Guinea',
    countryCode: '+675',
    flag: require('../assets/countryFlags/Papua_New_Guinea_675.png'),
  },
  {
    countryName: 'Paraguay',
    countryCode: '+595',
    flag: require('../assets/countryFlags/Paraguay_595.png'),
  },
  {
    countryName: 'Peru',
    countryCode: '+51',
    flag: require('../assets/countryFlags/Peru_51.png'),
  },
  {
    countryName: 'Philippines',
    countryCode: '+63',
    flag: require('../assets/countryFlags/Philippines_63.png'),
  },
  {
    countryName: 'Poland',
    countryCode: '+48',
    flag: require('../assets/countryFlags/Poland_48.png'),
  },
  {
    countryName: 'Portugal',
    countryCode: '+351',
    flag: require('../assets/countryFlags/Portugal_351.png'),
  },
  {
    countryName: 'Puerto Rico',
    countryCode: '+1787',
    flag: require('../assets/countryFlags/Puerto_Rico_1787.png'),
  },
  {
    countryName: 'Qatar',
    countryCode: '+974',
    flag: require('../assets/countryFlags/Qatar_974.png'),
  },
  {
    countryName: 'Reunion',
    countryCode: '+262',
    flag: require('../assets/countryFlags/Reunion_262.png'),
  },
  {
    countryName: 'Romania',
    countryCode: '+40',
    flag: require('../assets/countryFlags/Romania_40.png'),
  },
  {
    countryName: 'Russia',
    countryCode: '+7',
    flag: require('../assets/countryFlags/Russia_7.png'),
  },
  {
    countryName: 'Rwanda',
    countryCode: '+250',
    flag: require('../assets/countryFlags/Rwanda_250.png'),
  },
  {
    countryName: 'Saint Lucia',
    countryCode: '+1758',
    flag: require('../assets/countryFlags/Saint_Lucia_1758.png'),
  },
  {
    countryName: 'Samoa',
    countryCode: '+685',
    flag: require('../assets/countryFlags/Samoa_685.png'),
  },
  {
    countryName: 'Saudi Arabia',
    countryCode: '+966',
    flag: require('../assets/countryFlags/Saudi_Arabia_966.png'),
  },
  {
    countryName: 'Senegal',
    countryCode: '+221',
    flag: require('../assets/countryFlags/Senegal_221.png'),
  },
  {
    countryName: 'Serbia',
    countryCode: '+381',
    flag: require('../assets/countryFlags/Serbia_381.png'),
  },
  {
    countryName: 'Seychelles',
    countryCode: '+248',
    flag: require('../assets/countryFlags/Seychelles_248.png'),
  },
  {
    countryName: 'Sierra Leone',
    countryCode: '+232',
    flag: require('../assets/countryFlags/Sierra_Leone_232.png'),
  },
  {
    countryName: 'Singapore',
    countryCode: '+65',
    flag: require('../assets/countryFlags/Singapore_65.png'),
  },
  {
    countryName: 'Sint Maarten',
    countryCode: '+1721',
    flag: require('../assets/countryFlags/Sint_Maarten_1721.png'),
  },
  {
    countryName: 'Slovakia',
    countryCode: '+421',
    flag: require('../assets/countryFlags/Slovakia_421.png'),
  },
  {
    countryName: 'Slovenia',
    countryCode: '+386',
    flag: require('../assets/countryFlags/Slovenia_386.png'),
  },
  {
    countryName: 'Solomon Islands',
    countryCode: '+677',
    flag: require('../assets/countryFlags/Solomon_Islands_677.png'),
  },
  {
    countryName: 'Somalia',
    countryCode: '+252',
    flag: require('../assets/countryFlags/Somalia_252.png'),
  },
  {
    countryName: 'South Africa',
    countryCode: '+27',
    flag: require('../assets/countryFlags/South_Africa_27.png'),
  },
  {
    countryName: 'South Korea',
    countryCode: '+850',
    flag: require('../assets/countryFlags/South_Korea_850.png'),
  },
  {
    countryName: 'South Sudan',
    countryCode: '+211',
    flag: require('../assets/countryFlags/South_Sudan_211.png'),
  },
  {
    countryName: 'Spain',
    countryCode: '+34',
    flag: require('../assets/countryFlags/Spain_34.png'),
  },
  {
    countryName: 'Sri Lanka',
    countryCode: '+94',
    flag: require('../assets/countryFlags/Sri_Lanka_94.png'),
  },
  {
    countryName: 'Sudan',
    countryCode: '+249',
    flag: require('../assets/countryFlags/Sudan_249.png'),
  },
  {
    countryName: 'Suriname',
    countryCode: '+597',
    flag: require('../assets/countryFlags/Suriname_597.png'),
  },
  {
    countryName: 'Swaziland',
    countryCode: '+268',
    flag: require('../assets/countryFlags/Swaziland_268.png'),
  },
  {
    countryName: 'Sweden',
    countryCode: '+46',
    flag: require('../assets/countryFlags/Sweden_46.png'),
  },
  {
    countryName: 'Switzerland',
    countryCode: '+41',
    flag: require('../assets/countryFlags/Switzerland_41.png'),
  },
  {
    countryName: 'Syria',
    countryCode: '+963',
    flag: require('../assets/countryFlags/Syria_963.png'),
  },
  {
    countryName: 'Taiwan',
    countryCode: '+886',
    flag: require('../assets/countryFlags/Taiwan_886.png'),
  },
  {
    countryName: 'Tajikistan',
    countryCode: '+992',
    flag: require('../assets/countryFlags/Tajikistan_992.png'),
  },
  {
    countryName: 'Tanzania',
    countryCode: '+255',
    flag: require('../assets/countryFlags/Tanzania_255.png'),
  },
  {
    countryName: 'Thailand',
    countryCode: '+66',
    flag: require('../assets/countryFlags/Thailand_66.png'),
  },
  {
    countryName: 'Timor Leste',
    countryCode: '+670',
    flag: require('../assets/countryFlags/Timor_Leste_670.png'),
  },
  {
    countryName: 'Togo',
    countryCode: '+228',
    flag: require('../assets/countryFlags/Togo_228.png'),
  },
  {
    countryName: 'Tonga',
    countryCode: '+676',
    flag: require('../assets/countryFlags/Tonga_676.png'),
  },
  {
    countryName: 'Trinidad And Tobago',
    countryCode: '+1868',
    flag: require('../assets/countryFlags/Trinidad_And_Tobago_1868.png'),
  },
  {
    countryName: 'Tunisia',
    countryCode: '+216',
    flag: require('../assets/countryFlags/Tunisia_216.png'),
  },
  {
    countryName: 'Turkey',
    countryCode: '+90',
    flag: require('../assets/countryFlags/Turkey_90.png'),
  },
  {
    countryName: 'Turkmenistan',
    countryCode: '+993',
    flag: require('../assets/countryFlags/Turkmenistan_993.png'),
  },
  {
    countryName: 'Tuvalu',
    countryCode: '+688',
    flag: require('../assets/countryFlags/Tuvalu_688.png'),
  },
  {
    countryName: 'United Arab Emirates',
    countryCode: '+971',
    flag: require('../assets/countryFlags/United_Arab_Emirates_971.png'),
  },
  {
    countryName: 'Uganda',
    countryCode: '+256',
    flag: require('../assets/countryFlags/Uganda_256.png'),
  },
  {
    countryName: 'United Kingdom',
    countryCode: '+44',
    flag: require('../assets/countryFlags/United_Kingdom_44.png'),
  },
  {
    countryName: 'Ukraine',
    countryCode: '+380',
    flag: require('../assets/countryFlags/Ukraine_380.png'),
  },
  {
    countryName: 'Uruguay',
    countryCode: '+598',
    flag: require('../assets/countryFlags/Uruguay_598.png'),
  },
  {
    countryName: 'US Virgin Islands',
    countryCode: '+1284',
    flag: require('../assets/countryFlags/US_Virgin_Islands_1284.png'),
  },
  {
    countryName: 'United States of America',
    countryCode: '+1',
    flag: require('../assets/countryFlags/United_States_of_America_1.png'),
  },
  {
    countryName: 'Uzbekistan',
    countryCode: '+998',
    flag: require('../assets/countryFlags/Uzbekistan_998.png'),
  },
  {
    countryName: 'Vanuatu',
    countryCode: '+678',
    flag: require('../assets/countryFlags/Vanuatu_678.png'),
  },
  {
    countryName: 'Venezuela',
    countryCode: '+58',
    flag: require('../assets/countryFlags/Venezuela_58.png'),
  },
  {
    countryName: 'Vietnam',
    countryCode: '+84',
    flag: require('../assets/countryFlags/Vietnam_84.png'),
  },
  {
    countryName: 'Yemen',
    countryCode: '+967',
    flag: require('../assets/countryFlags/Yemen_967.png'),
  },
  {
    countryName: 'Zambia',
    countryCode: '+260',
    flag: require('../assets/countryFlags/Zambia_260.png'),
  },
  {
    countryName: 'Zimbabwe',
    countryCode: '+263',
    flag: require('../assets/countryFlags/Zimbabwe_263.png'),
  },
];
