import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton, Tooltip } from '@mui/material';
import { Link } from '@mui/icons-material';
import { translate } from '../../i18n';
import styles from './CopyLinkIcon.module.scss';
const CopyLinkIcon = ({ text, onCopy }) => {
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <IconButton
        className={styles['share-icon-button']}
        aria-label="share-icon"
      >
        <Tooltip title={translate('postListItem.copyLink')} placement="left">
          <Link />
        </Tooltip>
      </IconButton>
    </CopyToClipboard>
  );
};

export default CopyLinkIcon;
