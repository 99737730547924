export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
// Change this value to test different environment
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const VERSION = process.env.REACT_APP_VERSION;
export const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

export const getBuildConfig = () => {
  const commonParameters = {
    host: process.env.REACT_APP_HOST,
    awsXAmzAlgorithm: process.env.REACT_APP_AWS_X_AMZ_ALGORITHM,
  };

  return {
    ...commonParameters,
    awsXAmzCredential: `${ACCESS_KEY}/!@#$%/${process.env.REACT_APP_AWS_REGION}/s3/aws4_request`,
    baseUrl: process.env.REACT_APP_BASE_URL,
    baseAwsUrl: process.env.REACT_APP_BASE_AWS_URL,
    awsSuccessRedirectUrl: process.env.REACT_APP_AWS_SUCCESS_REDIRECT_URL,
    basePath: process.env.REACT_APP_BASE_PATH,
    hashSignKey: process.env.REACT_APP_HASH_SIGN_KEY,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccesskey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    apiTimeOverAllowance: process.env.REACT_APP_API_TIME_OVER_ALLOWANCE,
    openWeatherMapApiUrl: process.env.REACT_APP_OPEN_WEATHER_MAP_API_URL,
    openWeatherMapApiKey: process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY,
    centralBankMMApiUrl: process.env.REACT_APP_CENTRAL_BANK_MM_API_URL,
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    twitterConsumerKey: process.env.REACT_APP_TWITTER_CONSUMER_KEY,
    twitterConsumerSecret: process.env.REACT_APP_TWITTER_CONSUMER_SECRET,
    googleRecaptchaSiteKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
    awsRegion: process.env.REACT_APP_AWS_REGION,
    awsBucket: process.env.REACT_APP_AWS_BUCKET,
    awsSecretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    //Firebase config key
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    appleRedirectUri: process.env.REACT_APP_APPLE_REDIRECT_URI,
  };
};
