/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import _ from "lodash";

import {
  createTheme,
  ThemeProvider,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Zoom,
} from "@mui/material";

import {
  CheckTwoTone as CheckTwoToneIcon,
  FiberManualRecordTwoTone as FiberManualRecordTwoToneIcon,
  Error as ErrorIcon,
  Today as TodayIcon,
  Home as HomeIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  APPLE_SK,
  ASYNC_STORAGE_KEYS,
  FACEBOOK_SK,
  GOOGLE_SK,
  PROFILE_INCOMPLETE_SK,
} from "../../../constants";
import {
  fetchInterestList,
  editProfile,
  resetEditProfileState,
  resetIncompleteUserDetailsState,
} from "../../../store/actions";
import {
  isNonEmptyString,
  loadState,
  removeState,
  saveState,
} from "../../../utils";
import { api, Status } from "../../../api";
import { translate } from "../../../i18n";

import { Snack } from "../../../common";
import Logo from "../../../assets/images/logo.png";

import styles from "../Authentication.module.scss";
import colors from "../../../css/variable.scss";
import cn from "classnames";

const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 388,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: "visible",
        },
        outlined: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            backgroundColor: "#000",
            opacity: "0.7",
            borderRadius: "10px",
            textAlign: "left",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3f51b5",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
          "& .MuiInputBase-input": {
            color: colors.whiteColor,
            "-webkit-text-fill-color": `${colors.whiteColor} !important`,
            zIndex: 1,
          },
          "& .MuiInputAdornment-root": {
            color: colors.whiteColor,
            zIndex: 1,
          },
          "& .MuiSelect-select": {
            textAlign: "left",
          },
          "& .MuiSelect-icon": {
            color: colors.whiteColor,
            zIndex: 1,
            fontSize: "38px",
          },
        },
      },
    },
  },
});

const UserInterest = ({
  /**
   * redux action to initiate editProfile api request
   *
   * @source redux
   */
  editProfile: _editProfile,
  /**
   * Tells about the status of the edit profile api call.
   *
   * @source redux
   */
  editProfileStatus,
  /**
   * Represents the error message from the edit priofile api call
   * @source redux
   */
  editProfileErrorMessage,
  /**
   * Reset state variables in profile reducer, after screen is unmounted
   * @source redux
   */
  resetEditProfileState: _resetEditProfileState,
  /**
   * interest api call.
   * @source redux
   */
  fetchInterestList: _fetchInterestList,
  /**
   * interest api call status
   * @source redux
   */
  initialInterestStatus,
  /**
   * interest api error message
   * @source redux
   */
  initialInterestErrorMessage,
  interestList,
  history: _history,
  userDetails,
  userDetailsStatus,
  resetIncompleteUserDetailsState: _resetIncompleteUserDetailsState,
}) => {
  const genders = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const sessionId = loadState(ASYNC_STORAGE_KEYS.sessionId);
  const userStatus = loadState(ASYNC_STORAGE_KEYS.userStatus);
  const loginType = userDetails?.bio.loginType;
  const userName = userDetails?.bio?.name;

  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [city, setCity] = useState("");
  const [interests, setInterests] = useState([]);
  const [isValidDate, setIsValidDate] = useState(true);
  const [open, setOpen] = useState(false);

  const today = new Date();
  const thisYear = today.getFullYear();
  const thisMonth = today.getMonth();
  const thisDay = today.getUTCDate();
  const validYear = thisYear - 13;

  const isSocialLogin = loginType === FACEBOOK_SK || loginType === GOOGLE_SK;

  useEffect(() => {
    _fetchInterestList();
    api.setSessionId(sessionId);

    return () => {
      resetState();
      const userStatus = localStorage.getItem(ASYNC_STORAGE_KEYS.userStatus);
      if (userStatus === PROFILE_INCOMPLETE_SK) {
        removeState(ASYNC_STORAGE_KEYS.sessionId);
        removeState(ASYNC_STORAGE_KEYS.userIdentifier);
        removeState(ASYNC_STORAGE_KEYS.incompleteUserIdentifier);
        saveState(ASYNC_STORAGE_KEYS.loginType, "");
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialInterestStatus === Status.ERROR) {
      setOpen(true);
    }
  }, [initialInterestStatus]);

  useEffect(() => {
    if (userDetailsStatus === Status.SUCCESS) {
      setName(userName);
    }
  }, [userDetailsStatus, userName]);

  useEffect(() => {
    if (editProfileStatus === Status.SUCCESS) {
      setTimeout(() => {
        _history.push("/");
        window.location.reload();
      }, 1000);
    }
    if (editProfileStatus === Status.ERROR) {
      setOpen(true);
    }
  }, [editProfileStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInterestChange = (interestId) => {
    let isthere = _.find(interests, (interest, k) => {
      return interest === interestId;
    });
    setInterests((prevState) => {
      let _arr = _.clone(prevState);
      if (isthere) {
        _arr = _.remove(_arr, (item) => {
          return item !== interestId;
        });
      } else {
        _arr.push(interestId);
      }
      return _arr;
    });
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const onDateChange = (date) => {
    setBirthday(date);
    setIsValidDate(true);
  };

  const onDateError = (e) => {
    if (e !== "") setIsValidDate(false);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const onSubmit = () => {
    let formatted_date = "";

    if (isValidDate && birthday) {
      const date = moment(birthday, "YYYY/MM/DD");
      formatted_date = date.format("YYYY-MM-DD");
    }

    if (name && interests.length >= 3) {
      _editProfile(
        {
          bio: {
            name,
            gender,
            dob: formatted_date,
            city,
          },
          interestIdentifiers: interests,
        },
        name
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetState = () => {
    _resetEditProfileState();
    _resetIncompleteUserDetailsState();
  };

  if (!isNonEmptyString(sessionId) || userStatus !== PROFILE_INCOMPLETE_SK) {
    return <Redirect to="/" />;
  }

  return (
    <div id={styles.interest}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item lg={7} md={12} xs={12}>
            <div
              className={styles["interest-logo"]}
              onClick={() => _history.push("/")}
            >
              <img alt="logo" src={Logo}></img>
            </div>
            <div className={styles["left-div"]}>
              <div className={styles["interest-sub-title"]}>
                {translate("userInterestScreen.leftTitle")}
              </div>
              {userDetailsStatus === Status.SUCCESS ? (
                <>
                  <Grid container id={styles["interest-grid-container"]}>
                    {interestList.map((item, key) => {
                      let isChecked = _.find(interests, (interestId, k) => {
                        return interestId === item.identifier ? true : false;
                      });
                      return (
                        <ThemeProvider key={key} theme={theme}>
                          <Grid
                            item
                            align="center"
                            id={styles["interest-grid-item"]}
                            xl={3}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={6}
                          >
                            <Zoom in={true}>
                              <div
                                component="span"
                                m={1}
                                className={styles["interest-box"]}
                                onClick={() =>
                                  onInterestChange(item.identifier)
                                }
                              >
                                <div
                                  className={styles["interest-checkbox-root"]}
                                >
                                  {isChecked ? (
                                    <CheckTwoToneIcon
                                      id={styles["interest-checked-icon"]}
                                    />
                                  ) : (
                                    <FiberManualRecordTwoToneIcon
                                      id={styles["interest-check-icon"]}
                                    />
                                  )}
                                </div>

                                <div>
                                  {item.imageUrl !== null ? (
                                    <img
                                      alt="img"
                                      src={item.imageUrl}
                                      id={styles["interest-icon"]}
                                    />
                                  ) : (
                                    <ErrorIcon id={styles["interest-icon"]} />
                                  )}
                                </div>
                                <div className={styles["interest-name"]}>
                                  {item.interestName}
                                </div>
                              </div>
                            </Zoom>
                          </Grid>
                        </ThemeProvider>
                      );
                    })}
                  </Grid>
                  <div className={styles["interest-left-message"]}>
                    {translate("userInterestScreen.leftMessage")} <span>*</span>
                  </div>
                </>
              ) : (
                <CircularProgress id={styles["circular-progress"]} size={20} />
              )}
            </div>
          </Grid>
          <Grid item lg={5} md={12} xs={12} id={styles["interest-right-grid"]}>
            <div style={{ textAlign: "center" }}>
              <div className={styles["interest-right-title"]}>
                {translate("userInterestScreen.rightTitle")}
              </div>
              <div className={styles["interest-right-sub-title"]}>
                {translate("userInterestScreen.rightSubTitle")}
              </div>
              {userDetailsStatus === Status.SUCCESS && (
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    id={styles["interest-grid-item-right"]}
                  >
                    <FormControl
                      variant="outlined"
                      id={styles["interest-inbox"]}
                    >
                      <InputLabel className={styles["interest-inbox-label"]}>
                        {translate("inboxLabel.nameInboxLabel")}
                        {!isSocialLogin && (
                          <span className={styles["required-mark"]}>*</span>
                        )}
                      </InputLabel>
                      <OutlinedInput
                        name="name"
                        type="text"
                        value={name}
                        defaultValue={name}
                        onChange={handleName}
                        endAdornment={
                          <InputAdornment position="end">
                            <AccountCircleIcon />
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 50 }}
                        label={translate("inboxLabel.nameInboxLabel")}
                        disabled={name && isSocialLogin}
                      />
                    </FormControl>
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      id={styles["interest-grid-item-right"]}
                    >
                      <FormControl id={styles["interest-inbox"]}>
                        <KeyboardDatePicker
                          InputProps={{
                            classes: {
                              label: styles["interest-inbox-label"],
                              root: styles["interest-input-base"],
                              input: styles["interest-input-center"],
                              notchedOutline: styles["interest-input-root"],
                              focused: styles["interest-input-focus"],
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: styles["interest-date-label"],
                              focused: styles["interest-inbox-focus"],
                            },
                          }}
                          autoOk
                          openTo="year"
                          format="dd-MM-yyyy"
                          label={translate("inboxLabel.dobInboxLabel")}
                          views={["year", "month", "date"]}
                          value={birthday}
                          variant="inline"
                          onChange={onDateChange}
                          inputVariant="outlined"
                          minDate={"1940-01-01"}
                          initialFocusedDate={`${validYear}/${thisMonth}/${thisDay}`}
                          maxDate={`${validYear}/${thisMonth}/${thisDay}`}
                          keyboardIcon={
                            <TodayIcon id={styles["interst-today-fill"]} />
                          }
                          onError={onDateError}
                        />
                      </FormControl>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    id={styles["interest-grid-item-right"]}
                  >
                    <FormControl
                      variant="outlined"
                      id={styles["interest-inbox"]}
                    >
                      <InputLabel
                        id="gender-label"
                        className={styles["interest-inbox-label"]}
                      >
                        {translate("inboxLabel.genderInboxLabel")}
                      </InputLabel>
                      <Select
                        labelId="gender"
                        id="gender-label"
                        label={translate("inboxLabel.genderInboxLabel")}
                        name="gender"
                        value={gender}
                        onChange={handleGender}
                      >
                        {genders.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    id={styles["interest-grid-item-right"]}
                  >
                    <FormControl
                      id={styles["interest-inbox"]}
                      variant="outlined"
                    >
                      <InputLabel
                        id="city"
                        className={styles["interest-inbox-label"]}
                      >
                        {translate("inboxLabel.cityInboxLabel")}
                      </InputLabel>
                      <OutlinedInput
                        id="city"
                        name="city"
                        type="text"
                        value={city}
                        onChange={handleCity}
                        endAdornment={
                          <InputAdornment position="end">
                            <HomeIcon />
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 30 }}
                        label={translate("inboxLabel.cityInboxLabel")}
                        labelWidth={40}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {!name || interests.length < 3 ? (
                      <button
                        className={cn(styles.solid, styles["btn-disabled"])}
                      >
                        {translate("common.continue")}
                      </button>
                    ) : (
                      <button
                        onClick={onSubmit}
                        type="submit"
                        disabled={
                          editProfileStatus === Status.LOADING ? true : false
                        }
                        className={cn(styles.btn, styles.solid)}
                      >
                        {editProfileStatus === Status.LOADING ? (
                          <CircularProgress
                            size={20}
                            id={styles["circular-progress"]}
                          />
                        ) : (
                          <span> {translate("common.continue")}</span>
                        )}
                      </button>
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
      <Snack
        open={open}
        message={
          editProfileStatus === Status.ERROR
            ? editProfileErrorMessage
            : initialInterestErrorMessage
        }
        type="error"
        onClose={handleClose}
      />
    </div>
  );
};

const mapStateToProps = ({ app, auth, profile }) => {
  const { configStatus } = app;

  const {
    editProfileStatus,
    editProfileErrorMessage,
    userDetails,
    userDetailsStatus,
  } = profile;

  const { initialInterestStatus, initialInterestErrorMessage, interestList } =
    auth;

  return {
    configStatus,
    initialInterestStatus,
    initialInterestErrorMessage,
    interestList,
    editProfileStatus,
    editProfileErrorMessage,
    userDetails,
    userDetailsStatus,
  };
};

export default connect(mapStateToProps, {
  fetchInterestList,
  editProfile,
  resetEditProfileState,
  resetIncompleteUserDetailsState,
})(withRouter(UserInterest));
