import {
  ONLY_ME_SK,
  PRIVATE_SK,
  PUBLIC_SK,
  BLOG_SK,
} from '../constants/stringConstants';
import { translate } from '../i18n';
import PUBLIC_ICON from '../assets/images/icon_public.png';
import PRIVATE_ICON from '../assets/images/icon_private.png';
import ONLY_ME_ICON from '../assets/images/icon_only_me.png';
import BLOG_ICON from '../assets/images/icon_blog.svg';

export function getPrivacyOptions() {
  return [
    { key: PUBLIC_SK, title: translate('common.public'), icon: PUBLIC_ICON },
    { key: PRIVATE_SK, title: translate('common.private'), icon: PRIVATE_ICON },
    { key: ONLY_ME_SK, title: translate('common.onlyme'), icon: ONLY_ME_ICON },
  ];
}

export function getBloggerOptions() {
  return [
    { key: BLOG_SK, title: translate('common.blog'), icon: BLOG_ICON },
    { key: PUBLIC_SK, title: translate('common.public'), icon: PUBLIC_ICON },
    { key: PRIVATE_SK, title: translate('common.private'), icon: PRIVATE_ICON },
    { key: ONLY_ME_SK, title: translate('common.onlyme'), icon: ONLY_ME_ICON },
  ];
}
