import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { api, Status } from '../../../../api';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
  Grid,
  CircularProgress,
  createTheme,
  ThemeProvider,
  InputBase,
} from '@mui/material';
import editIcon from '../../../../assets/images/icon_edit.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formattedDate } from '../../../../utils';
import { ARTICLE_SK, BUSINESS_SK } from '../../../../constants';
import { CreateEditReviewModal } from '../../../../common';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { translate } from '../../../../i18n';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          border: '1px solid #E0E0E0',
          boxSizing: 'border-box',
          boxShadow: '0px 0px 4px rgba(1, 13, 24, 0.1)',
          borderRadius: '6px',
          marginBottom: '2vh',
        },
      },
    },
  },
});

const UserReviewTab = ({ baseMediaUrl, baseMediaThumbUrl, history }) => {
  const [page, setPage] = useState(1);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [reviews, setReviews] = useState([]);
  const [contentAvailableForRefresh, setContentAvailableForRefresh] =
    useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [edittedReview, setEdittedReview] = useState(null);

  useEffect(() => {
    getUserReviews(page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserReviews = (page = 1) => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      api
        .getUserReviews({
          page,
        })
        .then(({ userResponse: { reviewList } }) => {
          setApiStatus(Status.SUCCESS);
          setReviews((prevState) => [...prevState, ...reviewList]);
          if (reviewList.length === 0) {
            setContentAvailableForRefresh(false);
          }
        })
        .catch((error) => {
          setApiStatus(Status.ERROR);
          console.log(error);
        });
    }
  };

  const editReview = (review) => {
    setEdittedReview(review);
    setOpenEdit(true);
  };

  const updateReviewList = (updatedReview) => {
    const { identifier, rating, title, description } = updatedReview;
    let _reviews = _.clone(reviews);
    _reviews.forEach((review) => {
      if (review.identifier === identifier) {
        review.rating = rating;
        review.title = title;
        review.description = description;
      }
    });
    setReviews(_reviews);
  };

  const onEditClose = () => {
    setOpenEdit(false);
  };

  const openEntity = (type, subType, identifier) => {
    let _type = type === BUSINESS_SK ? subType : type;
    history.push(`/${btoa(identifier)}/${_type}`);
  };

  return (
    <div className="user-review-container">
      <InfiniteScroll
        dataLength={reviews.length} //This is important field to render the next data
        next={() => setPage((prevState) => prevState + 1)}
        hasMore={contentAvailableForRefresh}
        loader={
          <div className="spinner">
            <CircularProgress size={22} className="loading_spinner" />
          </div>
        }
        endMessage={
          <div className="nomore-data-info">
            {reviews.length === 0 && apiStatus === Status.SUCCESS ? (
              <span>{translate('businessProfile.noReview')}</span>
            ) : (
              <span></span>
            )}
          </div>
        }
      >
        <ThemeProvider theme={theme}>
          {reviews.map((review, key) => {
            const {
              entity: {
                identifier: entityIdentifier,
                type,
                subType,
                coverImg,
                title: entityTitle,
                city,
              },
              rating,
              createdAt,
              title,
              description,
              mediaUrl,
            } = review;
            return (
              <Accordion key={key}>
                <AccordionSummary>
                  <div className="review-item">
                    <Grid container spacing={1}>
                      <Grid item xs={4} sm={2}>
                        <img
                          className="entity-img"
                          alt="img"
                          src={
                            coverImg.isThumbGenerated
                              ? coverImg.url.thumbUrl
                              : coverImg.url.originalUrl
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            openEntity(type, subType, entityIdentifier);
                          }}
                        />
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        <div className="entity-info-div">
                          <div className="entity-title">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                openEntity(type, subType, entityIdentifier);
                              }}
                            >
                              {entityTitle}
                            </span>
                          </div>
                          {type !== ARTICLE_SK && (
                            <div className="entity-info">{city}</div>
                          )}
                          <Rating
                            value={rating}
                            precision={0.5}
                            readOnly
                            size="small"
                          />
                          <div className="entity-info">
                            {`${translate(
                              'common.reviewedOn'
                            )}: ${formattedDate(createdAt)}`}
                          </div>
                          <img
                            className="edit-icon"
                            alt="edit-icon"
                            src={editIcon}
                            onClick={(event) => {
                              event.stopPropagation();
                              editReview(review);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div id="divider" />
                  <div>
                    <div className="user-review-title">{title}</div>
                    <div className="user-review-desc">
                      <InputBase
                        value={description || ''}
                        multiline
                        readOnly
                        fullWidth
                      />
                    </div>
                    {mediaUrl.length !== 0 && (
                      <div id="review-item-photo-wrap">
                        {mediaUrl.map((item, key) => (
                          <img
                            key={key}
                            id="review-item-photo"
                            alt="img"
                            src={item.filePath.originalUrl}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </ThemeProvider>
      </InfiniteScroll>
      <CreateEditReviewModal
        open={openEdit}
        mode="edit"
        identifier={edittedReview?.identifier}
        rating={edittedReview?.rating}
        title={edittedReview?.title}
        description={edittedReview?.description}
        createdAt={edittedReview?.createdAt}
        updateReviewList={updateReviewList}
        onClose={onEditClose}
      />
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(mapStateToProps, null)(withRouter(UserReviewTab));
