import { Status } from '../../api';
import {
  INITIALIZE_APP_LOADING,
  INITIALIZE_APP_SUCCESS,
  INITIALIZE_APP_ERROR,
  SESSION_EXPIRED,
  LOGOUT,
  CONFIG_LOADING,
  CONFIG_SUCCESS,
  CONFIG_ERROR,
  SHUFFLE_IDS,
  SAVE_FCM_TOKEN_LOADING,
  SAVE_FCM_TOKEN_SUCCESS,
  SAVE_FCM_TOKEN_ERROR,
  NETWORK_CONNECTION,
  ONLINE_LK,
} from '../../constants';

const initialState = {
  /**
   * Tells about whether the api module is
   * configured or not.
   */
  status: Status.DEFAULT,
  errorMessage: '',
  /**
   * If sessionStatus === Status.DEFAULT  => User is not logged in
   * If sessionStatus === Status.SUCCESS  => User is logged in
   * If sessionStatus === Status.ERROR    => session token of logged in user has expired
   */
  sessionStatus: Status.DEFAULT,
  configStatus: Status.DEFAULT,
  // ================================
  baseMediaUrl: '',
  baseMediaThumbUrl: '',
  languages: [],
  articleIds: [],
  attractionIds: [],
  guestUserIdStartingNumber: 10000,
  guestUserId: null,
  newsFeedLimit: 5, // Number of ids to be send in fetchHomePageData api
  tellFriendMsg: '',
  fcmTokenStatus: Status.DEFAULT,
  fcmTokenErrorMsg: "",
  fcmToken: null,
  location: null,
  networkConnection: ONLINE_LK,
};

/**
 * App level reducer, store data that is common to entire app.
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIALIZE_APP_LOADING:
      return {
        ...state,
        status: Status.LOADING,
        errorMessage: '',
      };
    case INITIALIZE_APP_SUCCESS:
      return {
        ...state,
        status: Status.SUCCESS,
        errorMessage: '',
        sessionStatus: payload.sessionStatus,
        location: payload.location,
      };
    case INITIALIZE_APP_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        errorMessage: payload.errorMessage,
      };
    case SAVE_FCM_TOKEN_LOADING:
      return {
        ...state,
        fcmTokenStatus: Status.LOADING,
        fcmTokenErrorMsg: "",
      };
    case SAVE_FCM_TOKEN_SUCCESS:
      return {
        ...state,
        fcmTokenStatus: Status.SUCCESS,
        fcmTokenErrorMsg: "",
      };
    case SAVE_FCM_TOKEN_ERROR:
      return {
        ...state,
        fcmTokenStatus: Status.ERROR,
        fcmTokenErrorMsg: payload.errorMessage
      };
    case SESSION_EXPIRED:
      return {
        ...state,
        sessionStatus: Status.ERROR,
      };
    case CONFIG_LOADING:
      return {
        ...state,
        configStatus: Status.LOADING,
        configErrorMessage: '',
      };
    case CONFIG_SUCCESS:
      return {
        ...state,
        configStatus: Status.SUCCESS,
        configErrorMessage: '',
        tellFriendMsg: payload.tellFriendMsg,
        guestUserIdStartingNumber: payload.guestUserIdStartingNumber,
        fetchNearByTravellerWithInRadiusInMeter:
          payload.fetchNearByTravellerWithInRadiusInMeter,
        baseMediaUrl: payload.baseMediaUrl,
        baseMediaThumbUrl: payload.baseMediaThumbUrl,
        languages: payload.languages,
        articleIds: payload.articleIds,
        attractionIds: payload.attractionIds,
        newsFeedLimit: payload.newsFeedLimit,
      };
    case CONFIG_ERROR:
      return {
        ...state,
        configStatus: Status.ERROR,
        errorMessage: payload.errorMessage,
      };
    case SHUFFLE_IDS:
      return {
        ...state,
        articleIds: payload.articleIds,
        attractionIds: payload.attractionIds,
      };
    case LOGOUT:
      return {
        ...state,
        sessionStatus: Status.DEFAULT,
      };
    case NETWORK_CONNECTION:
      return {
        ...state,
        networkConnection: payload.networkStatus
      }
    default:
      return state;
  }
}