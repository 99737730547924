function onKeyDown(event) {
    if (event.isDefaultPrevented() || !(event.target instanceof HTMLElement)) return alert('Error')
    if (event.key === "Enter") {
        event.target.click()
        event.preventDefault()
    }
}

function preventDefault(event) {
    event.preventDefault()
}

/** Usage: `<div {...buttonRoleProps} />` */
export const buttonRoleProps = {
    draggable: false,
    onDragStart: preventDefault,
    onKeyDown,
    role: 'button',
    tabIndex: 0,
}