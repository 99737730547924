import React, { useEffect, useState } from 'react';
import {
  ARTICLE_SK,
  BUSINESS_SK,
  SAVE_SK,
} from '../../../../../../constants';
import { connect } from 'react-redux';
import {
  saveFavourite,
  deleteFavourite,
} from '../../../../../../store/actions';
import { Status } from '../../../../../../api';
import { translate } from '../../../../../../i18n';
import { isNonEmptyString } from '../../../../../../utils';
import { withRouter } from 'react-router';
import { IconButton } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import styles from './ListItem.module.scss';

const ArticleListItem = ({
  style = styles['popular-articles-item'],
  history,
  type,
  item,
  loggedInUserIdentifier,
  favouriteStatus,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  openSnack: _openSnack,
  showLoginPrompt: _showLoginPrompt,
}) => {
  const [flag, setFlag] = useState(false);
  const [isSaved, setIsSaved] = useState(item.isSaved);

  useEffect(() => {
    setIsSaved(item.isSaved);
    if (flag) {
      const msg =
        item.isSaved === SAVE_SK
          ? translate("homeScreen.unsaveFavouriteSuccessMessage")
          : translate("homeScreen.saveFavouriteSuccessMessage");
      _openSnack(msg);
      setFlag(false);
    }
  }, [item.isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  const _type =
    type === ARTICLE_SK
      ? type
      : item.type === BUSINESS_SK
        ? item.subType
        : item.type;

  const save = (e) => {
    e.stopPropagation();
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (favouriteStatus !== Status.LOADING) {
      setFlag(true);
      if (isSaved === SAVE_SK) {
        _saveFavourite(
          item.identifier,
          _type,
          loggedInUserIdentifier
        );
      } else {
        _deleteFavourite(
          item.favouriteIdentifier,
          _type,
          item.identifier
        );
      }
    }
  };

  const handleClick = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${btoa(item.identifier)}/${_type}`);
  };

  return (
    <>
      <div className={style} onClick={handleClick}>
        <IconButton className={styles.icon} onClick={save}>
          {isSaved === SAVE_SK ? (
            <BookmarkBorderIcon className={styles['save-icon-style']} />
          ) : (
            <BookmarkIcon className={styles['save-icon-style']} />
          )}
        </IconButton>
        <div>
          <img
            alt="img"
            className={styles['article-image']}
            src={item.coverImg?.url?.originalUrl}
          />
          <div id={styles.description}>
            <span> {item.title} </span>
          </div>
          <div id={styles['protected-img']}></div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ home }) => {
  const { favouriteStatus } = home;
  return {
    favouriteStatus
  };
};
export default connect(mapStateToProps, { saveFavourite, deleteFavourite })(
  withRouter(ArticleListItem)
);
