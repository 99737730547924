/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useCallback
} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  CssBaseline,
  CircularProgress,
  Grid,
  Snackbar as SnackBar,
  Alert as MuiAlert,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Status } from "../../../api";
import {
  fetchBannerImages,
  fetchHomePageData,
  deletePost,
  deleteBlog,
  updateUserFollowStatus,
  resetDeletePostStatusToDefault,
  resetReportPostStatusToDefault,
  resetSharePostStatusToDefault,
  updateReaction,
  updateCommentCount,
  resetHomePageDataStatusToDefault,
  config,
  resetFavouriteStatusToDefault,
} from "../../../store/actions";

import {
  AppBar,
  BackToTopIcon,
  PostListItem,
  AlertDialog,
  Spinner,
  Snack
} from "../../../common";

import {
  ShalgoerServices,
  Highlight,
  Article,
  Attraction,
} from "./components";

import RandomSection from "./components/RandomSection/RandomSection";
import {
  ARTICLE_SK,
  ASYNC_STORAGE_KEYS,
  ATTRACTION_SK,
  POST_SK,
  RANDOM_SK,
  SAVE_SK,
} from "../../../constants";
import { translate } from "../../../i18n";

import styles from "./Home.module.scss";
import { initFCM } from "../../../fcm/fcmToken";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/* 
We specify the fontFamily property as 'Roboto, sans-serif'. 
This will use the Roboto font as the default font for our application. 
We can replace this with any other font family.
*/
const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

const Home = ({
  history,
  /**
   * Tells about the status of config api
   * fetch home page data api will be hit,
   * after configStatus === Status.SUCCESS
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   *
   * @source redux
   */
  configStatus,
  /**
   *
   */
  bannerImagesStatus,
  /**
   * Tells about the status of the fetch recent posts api call, when pagination = 1
   *
   * NOTE: Recent Posts api also contains, data for recommendation
   *
   * @source redux
   */
  homePageDataStatus,
  /**
   * Tells about the status of the fetch recent posts api call, when pagination > 1
   *
   * @source redux
   */
  moreHomePageDataStatus,
  articlesStatus,
  businessContractsStatus,
  /**
   * Array which contains banner image data
   *
   * @source redux
   */
  bannerImage,
  /**
     * Array which contain remaining post data
     *
     * @source redux
     */
  posts,
  /**
   * true if post content available posts
   */
  contentAvailableForRefresh,
  sharePostStatus,
  sharedPostIdentifier,
  deletePostStatus,
  reportPostStatus,
  /**
   * Array which conatins recommendation data
   *
   * @source redux
   */
  recommendations,
  /**
   * Array which conatins popular articles data
   *
   * @source redux
   */
  popularArticles,
  /**
   * Array which conatins hotels data
   *
   * @source redux
   */
  hotels,
  /**
   * Array which conatins restaurants data
   *
   * @source redux
   */
  restaurants,
  bloggerList,
  bloggersStatus,

  /**
   * Tells about the status of get user Details api call.
   *
   * @source redux
   */
  userDetailsStatus,
  /**
   * Base url prefixed to filePath to generate
   * complete url for original image/video
   *
   * @source redux
   */
  baseMediaUrl,
  /**
   * Base url pefixed to filePath to generate
   * complete url for thumbnail version of image/video
   *
   * @source redux
   */
  baseMediaThumbUrl,
  /**
   * redux action to initiate recent posts api request
   *
   * @source redux
   */
  fetchHomePageData: _fetchHomePageData,
  /**
   * redux action to initiate banner images api request
   *
   * @source redux
   */
  fetchBannerImages: _fetchBannerImages,
  /**
   * redux action to initiate articles api request
   *
   * @source redux
   */
  //   fetchArticles: _fetchArticles,
  /**
   * redux action to initiate business contracts api request
   *
   * @source redux
   */
  //   fetchBusinessContracts: _fetchBusinessContracts,
  /**
   * redux action to initiate bloggers api request
   *
   * @source redux
   */
  //   fetchBloggers: _fetchBloggers,
  /**
   * Dispatch an action to delete a post
   *
   * @source redux
   */
  deletePost: _deletePost,
  /**
   * Dispatch an action to delete a blog
   *
   * @source redux
   */
  deleteBlog: _deleteBlog,
  /**
   * Dispatch an action to update follow satus
   * for same user for other post list item
   * in list
   *
   * @source redux
   */
  updateUserFollowStatus: _updateUserFollowStatus,
  resetDeletePostStatusToDefault: _resetDeletePostStatusToDefault,
  resetReportPostStatusToDefault: _resetReportPostStatusToDefault,
  resetSharePostStatusToDefault: _resetSharePostStatusToDefault,
  updateReaction: _updateReaction,
  updateCommentCount: _updateCommentCount,
  resetHomePageDataStatusToDefault: _resetHomePageDataStatusToDefault,
  loginStatus,
  config: _config,
  favouriteStatus,
  successMessage,
  resetFavouriteStatusToDefault: _resetFavouriteStatusToDefault,
}) => {
  const classes = useStyles();
  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    if (configStatus === Status.DEFAULT) {
      _config();
    }
  }, [configStatus]);

  useEffect(() => {
    if (favouriteStatus === Status.SUCCESS) {
      setSnackOpen(true);
    }
    _resetFavouriteStatusToDefault();
  }, [favouriteStatus]);

  const showLoginPrompt = () => {
    let data = {
      title: translate("common.loginPromtDialogTitle"),
      description: translate("common.loginPromtDialogMessage"),
      positiveButtonCallback: () => {
        history.push("login");
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    if (sharePostStatus === Status.SUCCESS) {
      setSnackMsg(translate("homeScreen.postShareSuccessMessage"));
      setOpen(true);
      _resetSharePostStatusToDefault();
    }
  }, [sharePostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deletePostStatus === Status.SUCCESS) {
      setSnackMsg(translate("homeScreen.postDeletedSuccessMessage"));
      setOpen(true);
      _resetDeletePostStatusToDefault();
    }
  }, [deletePostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportPostStatus === Status.SUCCESS) {
      setSnackMsg(translate("postListItem.postReportedSuccessMessage"));
      setOpen(true);
      _resetReportPostStatusToDefault();
    }
  }, [reportPostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      configStatus === Status.SUCCESS &&
      homePageDataStatus === Status.DEFAULT &&
      bannerImagesStatus === Status.DEFAULT &&
      articlesStatus === Status.DEFAULT &&
      bloggersStatus === Status.DEFAULT &&
      businessContractsStatus === Status.DEFAULT
    ) {
      handleRefresh();
    }

    if (homePageDataStatus === Status.ERROR) {
      _resetHomePageDataStatusToDefault();
    } // eslint-disable-line react-hooks/exhaustive-deps
  }, [configStatus, userDetailsStatus, bannerImagesStatus, homePageDataStatus, articlesStatus, bloggersStatus, businessContractsStatus, _resetHomePageDataStatusToDefault]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // pagination logic
    if (
      homePageDataStatus === Status.SUCCESS &&
      page !== 1 &&
      moreHomePageDataStatus !== Status.LOADING
    ) {
      _fetchHomePageData(page);
    }
  }, [page]);

  useEffect(() => {
    if (loginStatus === Status.SUCCESS) {
      setPage(1);
      _fetchHomePageData();
    }
  }, [loginStatus]);

  const handleRefresh = useCallback(() => {
    setPage(1);
    _fetchBannerImages();
    _fetchHomePageData();
    // _fetchArticles();
    // _fetchBloggers();
    // _fetchBusinessContracts();
  }, [_fetchBannerImages, _fetchHomePageData]);

  if (
    bannerImagesStatus !== Status.SUCCESS &&
    homePageDataStatus !== Status.SUCCESS
  ) {
    return <Spinner />;
  }

  const renderBanner = () => (
    <div className={styles["banner-container"]}>
      <img
        alt="bannerimg"
        src={bannerImage?.filePath}
        fetchpriority="high"
        className={styles["banner-img"]}
      />
      <ShalgoerServices />
    </div>
  );

  const renderSidebar = () => (
    <div id={styles["sticky-sidebar"]}>
      <div className={styles["sidebar"]}>
        <Highlight
          loggedInUserIdentifier={loggedInUserIdentifier}
          showLoginPrompt={showLoginPrompt}
        />
      </div>
    </div>
  );

  const renderMainContent = () => (
    <div className={styles["main"]}>
      <InfiniteScroll
        dataLength={posts.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={contentAvailableForRefresh}
        loader={
          <div className={styles["spinner"]}>
            <CircularProgress size={22} className="loading_spinner" />
          </div>
        }
        endMessage={
          posts.length === 0 && homePageDataStatus === Status.SUCCESS ? (
            <div className={styles["nomore-data-info"]}>
              <span>{translate("homeScreen.noPostFound")}</span>
            </div>
          ) : (
            <div className={styles["nomore-data-info"]}>
              <CheckCircleOutlineIcon />
              <span>{translate("homeScreen.noMorePostInfo")}</span>
            </div>
          )
        }
      >
        {_.map(posts, (item, key) => {
          return (
            <>
              <Grid
                key={key}
                container
                columns={{ xs: 12, md: 24 }}
                className={styles.mainGridContainer}
              >
                <Grid item xs={1} md={1} />
                <Grid item xs={10} md={20}>
                  {item.type === ARTICLE_SK && (
                    <Article
                      item={item}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      loggedInUserIdentifier={loggedInUserIdentifier}
                      sharePostStatus={sharePostStatus}
                      sharedPostIdentifier={sharedPostIdentifier}
                      showLoginPrompt={showLoginPrompt}
                    />
                  )}
                  {item.type === ATTRACTION_SK && (
                    <Attraction
                      item={item}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      loggedInUserIdentifier={loggedInUserIdentifier}
                      sharePostStatus={sharePostStatus}
                      sharedPostIdentifier={sharedPostIdentifier}
                      showLoginPrompt={showLoginPrompt}
                    />
                  )}
                  {item.type === POST_SK && (
                    <PostListItem
                      item={item}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      deletePost={_deletePost}
                      deleteBlog={_deleteBlog}
                      updateStatusInList={_updateUserFollowStatus}
                      loggedInUserIdentifier={loggedInUserIdentifier}
                      sharePostStatus={sharePostStatus}
                      sharedPostIdentifier={sharedPostIdentifier}
                      showLoginPrompt={showLoginPrompt}
                      containerStyle={classes.pliStyle}
                      updateReaction={_updateReaction}
                      updateCommentCount={_updateCommentCount}
                    />
                  )}
                </Grid>
                <Grid item xs={1} md={3} />
              </Grid>
              <Grid container columns={{ xs: 12, md: 24 }}>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={23}>
                  {item.type === RANDOM_SK && (
                    <RandomSection
                      items={item.list}
                      type={item.entityType}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      loggedInUserIdentifier={loggedInUserIdentifier}
                      showLoginPrompt={showLoginPrompt}
                    />
                  )}
                  {/* <RandomSection
                    items={
                      item.random === ARTICLE_SK
                        ? popularArticles
                        : item.random === ATTRACTION_SK
                          ? recommendations
                          : item.random === HOTEL_SK
                            ? hotels
                            : item.random === RESTAURANT_SK
                              ? restaurants
                              : bloggerList
                    }
                    type={item.random}
                    baseMediaUrl={baseMediaUrl}
                    baseMediaThumbUrl={baseMediaThumbUrl}
                    loggedInUserIdentifier={loggedInUserIdentifier}
                    showLoginPrompt={showLoginPrompt}
                  /> */}
                </Grid>
              </Grid>
            </>
          );
        })}
      </InfiniteScroll>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={styles["home-root-container"]}>
        <CssBaseline />
        <AppBar />
        <div id="back-to-top-anchor" />
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item md={1} />
          <Grid item xs={12} md={10}>
            {renderBanner()}
          </Grid>
          <Grid item md={1} />
        </Grid>
        <div>
          <Grid container rowSpacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={12} md={12} display={{ xs: "none", md: "block" }} />
            <Grid item xs={1} md={1} display={{ xs: "none", md: "block" }} />
            <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
              {renderSidebar()}
            </Grid>
            <Grid item xs={12} md={8}>
              {renderMainContent()}
            </Grid>
          </Grid>
        </div>
        {/* <CreatePostIcon /> */}
        <BackToTopIcon />

        <Snack
          open={snackOpen}
          message={successMessage}
          onClose={handleSnackClose}
        />
        <SnackBar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            className={styles["alertColor"]}
          >
            {snackMsg}
          </Alert>
        </SnackBar>
        <AlertDialog open={alertOpen} data={alertData} />
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  pliStyle: {
    paddingLeft: "10%",
    paddingRight: "10%",
    marginBottom: "3%",
    border: "1px solid #BEBEBE",
    boxShadow: "0px 0px 4px rgba(1, 13, 24, 0.1) !important",
  },
}));

// Home.propTypes = proptypes;

// Home.defaultProps = defaultProps;

const mapStateToProps = ({
  app,
  home,
  articles,
  contracts,
  bloggers,
  profile,
  auth
}) => {
  const { loginStatus } = auth;
  const {
    configStatus,
    baseMediaUrl,
    baseMediaThumbUrl,
    fcmTokenStatus
  } = app;
  const {
    bannerImage,
    posts,
    recommendations,
    bannerImagesStatus,
    homePageDataStatus,
    moreHomePageDataStatus,
    contentAvailableForRefresh,
    sharePostStatus,
    sharedPostIdentifier,
    deletePostStatus,
    reportPostStatus,
    favouriteStatus,
    successMessage
  } = home;
  const { articles: popularArticles, articlesStatus } = articles;
  const { hotels, restaurants, businessContractsStatus } = contracts;
  const { bloggers: bloggerList, bloggersStatus } = bloggers;
  const { userDetails: { bio: { userStatus } = {} } = {}, userDetailsStatus } =
    profile;
  return {
    configStatus,
    baseMediaThumbUrl,
    baseMediaUrl,
    bannerImage,
    posts,
    recommendations,
    bannerImagesStatus,
    homePageDataStatus,
    moreHomePageDataStatus,
    contentAvailableForRefresh,
    sharePostStatus,
    sharedPostIdentifier,
    popularArticles,
    articlesStatus,
    bloggerList,
    bloggersStatus,
    hotels,
    restaurants,
    businessContractsStatus,
    userStatus,
    userDetailsStatus,
    deletePostStatus,
    reportPostStatus,
    loginStatus,
    fcmTokenStatus,
    favouriteStatus,
    successMessage
  };
};

export default connect(mapStateToProps, {
  fetchBannerImages,
  fetchHomePageData,
  //   fetchArticles,
  //   fetchBusinessContracts,
  //   fetchBloggers,
  deletePost,
  deleteBlog,
  updateUserFollowStatus,
  resetDeletePostStatusToDefault,
  resetReportPostStatusToDefault,
  resetSharePostStatusToDefault,
  updateReaction,
  updateCommentCount,
  resetHomePageDataStatusToDefault,
  config,
  resetFavouriteStatusToDefault
})(withRouter(Home));
