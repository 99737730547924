// eslint-disable-next-line import/no-anonymous-default-export
export default {
    cities: [
        {
            name: 'yangon',
            state: 'Yangon',
            lat: 16.871311,
            lon: 96.199379,
            backgroundImage: '/original/media/weather/Yangon.png'
        },
        {
            name: 'mandalay',
            state: 'Mandalay',
            lat: 21.954510,
            lon: 96.093292,
            backgroundImage: '/original/media/weather/Mandalay.png'
        },
        {
            name: 'bago',
            state: 'Bago',
            lat: 17.33521,
            lon: 96.48135,
            backgroundImage: '/original/media/weather/Bago.png'
        },
        {
            name: 'magway',
            state: 'Magway',
            lat: 20.14956,
            lon: 94.932460,
            backgroundImage: '/original/media/weather/Mgway.png'
        },
        {
            name: 'monywa',
            state: 'Sagaing',
            lat: 22.10856,
            lon: 95.13583,
            backgroundImage: '/original/media/weather/Sagaing.png'
        },
        {
            name: 'nayPyiTaw',
            state: 'Nay Pyi Daw',
            lat: 19.745,
            lon: 96.12972,
            backgroundImage: '/original/media/weather/Naypyitaw.png'
        },
        {
            name: 'myitKyina',
            state: 'Kachin',
            lat: 25.38327,
            lon: 97.39637,
            backgroundImage: '/original/media/weather/Kachin.png'
        },
        {
            name: 'mawlamyine',
            state: 'Mon',
            lat: 16.49051,
            lon: 97.62825,
            backgroundImage: '/original/media/weather/Mon.png'
        },
        {
            name: 'hpa-An',
            state: 'Kayin',
            lat: 16.875061,
            lon: 97.632339,
            backgroundImage: '/original/media/weather/Kayin.png'
        },
        {
            name: 'sittwe',
            state: 'Rakhine',
            lat: 20.144444,
            lon: 92.896942,
            backgroundImage: '/original/media/weather/Rrakan.png'
        },
        {
            name: 'loikaw',
            state: 'Kayah',
            lat: 19.674007,
            lon: 97.211360,
            backgroundImage: '/original/media/weather/Kayah.png'
        },
        {
            name: 'taunggyi',
            state: 'Shan',
            lat: 20.788757,
            lon: 97.033714,
            backgroundImage: '/original/media/weather/Shan.png'
        },
        {
            name: 'hakha',
            state: 'Chin',
            lat: 22.642109,
            lon: 93.604053,
            backgroundImage: '/original/media/weather/Chin.png'
        },
        {
            name: 'pathein',
            state: 'Ayeyarwaddy',
            lat: 16.77919,
            lon: 94.73212,
            backgroundImage: '/original/media/weather/Ayarwaddy.png'
        },
        {
            name: 'dawei',
            state: 'Tanintharyi',
            lat: 14.187175,
            lon: 98.209534,
            backgroundImage: '/original/media/weather/Thanintaryi.png'
        },
    ]
}
