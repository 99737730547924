import React, { useEffect, useState } from "react";
import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { ALL_MODE, LIST_MODE } from "../constants";
import {
  ASYNC_STORAGE_KEYS,
  BUSINESS_SK,
  SAVE_SK,
  UNSAVE_SK,
} from "../../../../constants";
import { api, Status } from "../../../../api";
import { IconButton } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import styles from "./BusinessMediaView.module.scss";
import { RatingStar } from "../../../../common";
import { connect } from "react-redux";
import { updateHomePageData } from "../../../../store/actions";
const loggedInUserIdentifier = localStorage.getItem(
  ASYNC_STORAGE_KEYS.userIdentifier
);

const BusinessMediaView = ({
  mode,
  identifier,
  name,
  isSaved,
  favouriteIdentifier,
  url,
  rating,
  openProfile: _openProfile,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
  updateHomePageData: _updateHomePageData,
}) => {
  const [_isSaved, setIsSaved] = useState(isSaved);
  const [_favouriteIdentifier, setFavouriteIdentifier] =
    useState(favouriteIdentifier);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    setIsSaved(isSaved);
    setFavouriteIdentifier(favouriteIdentifier);
  }, [isSaved, favouriteIdentifier]);

  const handleOnSave = () => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      setIsSaved(_isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK);

      if (_isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: identifier,
            entityType: BUSINESS_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse: { favouriteIdentifier } }) => {
            setApiStatus(Status.SUCCESS);
            favSaveSuccessCallback();
            setIsSaved(UNSAVE_SK);
            setFavouriteIdentifier(favouriteIdentifier);
            _updateHomePageData({ favouriteIdentifier, identifier });
          })
          .catch(() => {
            setApiStatus(Status.ERROR);
          });
      } else {
        api
          .deleteFavourite({
            favouriteIdentifier: _favouriteIdentifier,
          })
          .then(() => {
            setApiStatus(Status.SUCCESS);
            favUnSaveSuccessCallback();
            setIsSaved(SAVE_SK);
            setFavouriteIdentifier("");
            _updateHomePageData({ favouriteIdentifier: "", identifier });
          })
          .catch(() => {
            setApiStatus(Status.ERROR);
          });
      }
    }
  };

  if (mode === ALL_MODE) {
    return (
      <Box id={styles["all-business-media-root"]}>
        <Card elevation={5}>
          <CardActionArea onClick={_openProfile}>
            <Box id={styles["business-inner-box"]}>
              <Box
                id={
                  name?.length > 18
                    ? styles["title-box-sm"]
                    : styles["title-box"]
                }
              >
                <Typography id={styles["search-business-title"]}>
                  {name}
                </Typography>
                <RatingStar rating={rating} size="small" />
              </Box>
              <div className={styles["diagonal-box"]} />
              <img
                alt="business-media-img"
                src={url}
                className={styles["business-media-img"]}
              />
            </Box>
          </CardActionArea>
        </Card>
      </Box>
    );
  }

  return (
    <Box id={styles["filtered-result-rootbox"]}>
      {mode === LIST_MODE && (
        // <img
        //   alt='icon'
        //   src={_isSaved === SAVE_SK ? SAVE_ICON : ACTIVE_SAVE_ICON}
        //   id='listing-save-icon'
        //   onClick={handleOnSave}
        // />
        <IconButton id={styles["listing-save-icon"]} onClick={handleOnSave}>
          {_isSaved === SAVE_SK ? (
            <BookmarkBorderIcon className={styles["save-icon-style"]} />
          ) : (
            <BookmarkIcon className={styles["save-icon-style"]} />
          )}
        </IconButton>
      )}
      <CardActionArea onClick={_openProfile}>
        <Box id={styles["business-inner-box-lg"]}>
          <img alt="img" src={url} className={styles["business-media-img"]} />
        </Box>
      </CardActionArea>
    </Box>
  );
};

export default connect(null, {
  updateHomePageData,
})(BusinessMediaView);
