/* eslint-disable no-unused-vars */
import { takeLatest, call, put, select } from "redux-saga/effects";
import { api } from "../../api";
import {
  SHUFFLE_IDS,
  FETCH_BANNER_IMAGES_ERROR,
  FETCH_BANNER_IMAGES_LOADING,
  FETCH_BANNER_IMAGES_REQUEST,
  FETCH_BANNER_IMAGES_SUCCESS,
  FETCH_HOME_PAGE_DATA_REQUEST,
  FETCH_HOME_PAGE_DATA_LOADING,
  FETCH_HOME_PAGE_DATA_SUCCESS,
  FETCH_HOME_PAGE_DATA_ERROR,
  FETCH_MORE_HOME_PAGE_DATA_LOADING,
  FETCH_MORE_HOME_PAGE_DATA_SUCCESS,
  FETCH_MORE_HOME_PAGE_DATA_ERROR,
  UPDATE_USER_FOLLOW_STATUS,
  UPDATE_USER_FOLLOW_STATUS_SUCCESS,
  DELETE_HOME_POST_REQUEST,
  DELETE_HOME_POST_SUCCESS,
  SAVE_FAVOURITE_PLI_REQUEST,
  SAVE_FAVOURITE_PLI_SUCCESS,
  DELETE_FAVOURITE_PLI_SUCCESS,
  DELETE_FAVOURITE_PLI_REQUEST,
  UPDATE_BLOGGER_NETWORK_STATUS_SUCCESS,
  UPDATE_BLOGGER_NETWORK_STATUS,
  UPDATE_SHARE_COUNT,
  UPDATE_SHARE_COUNT_SUCCESS,
  UPDATE_USER_RELATION_STATUS,
  UNFRIEND_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  BLOCKED_SUCCESS,
  FRIEND_SUCCESS,
  FRIEND_REQUEST_DELETE_SUCCESS,
  FRIEND_REQUEST_PENDING_SUCCESS,
  UPDATE_USER_RELATION_STATUS_SUCCESS,
  FRIEND_INVITE_DELETE_SUCCESS,
  DELETE_BLOG_REQUEST,
  UPDATE_REACTION,
  UPDATE_REACTION_SUCCESS,
  UPDATE_COMMENT_COUNT,
  UPDATE_COMMENT_COUNT_SUCCESS,
  UPDATE_VIEW_COUNT,
  UPDATE_VIEW_COUNT_SUCCESS,
  UPDATE_HOME_PAGE_DATA,
  SAVE_FAVOURITE_PLI_LOADING,
  SAVE_FAVOURITE_PLI_ERROR,
  DELETE_FAVOURITE_PLI_LOADING,
  DELETE_FAVOURITE_PLI_ERROR,
} from "../../constants";
import {
  BLOG_SK,
  POST_SK,
  FOLLOWING_SK,
  ARTICLE_SK,
  ATTRACTION_SK,
  HOTEL_SK,
  RESTAURANT_SK,
  BLOGGER_SK,
  UNSAVE_SK,
  SAVE_SK,
  UNFOLLOWED_SK,
  NO_CONNECTION_SK,
  UNFRIEND_SK,
  BLOCKED_SK,
  FRIEND_SK,
  FRIEND_REQUEST_DELETE_SK,
  FRIEND_REQUEST_PENDING_SK,
  FRIEND_INVITE_DELETE_SK,
  RANDOM_SK,
} from "../../constants/stringConstants";
import { shuffle } from "../../utils";

const FIRST_PAGE = 1;

/**
 * worker saga: Calls the get banner images API.
 *
 * @param {Object} action              - action object dispatched by user
 */
function* fetchBannerImages() {
  try {
    yield put({
      type: FETCH_BANNER_IMAGES_LOADING,
    });

    // api call
    const response = yield call({ context: api, fn: api.fetchBannerImages });

    // parse the data from response
    const { userResponse: bannerImages } = response;

    const randomNumber = getRandomInt(bannerImages.length);

    // send parsed data into action payload
    yield put({
      type: FETCH_BANNER_IMAGES_SUCCESS,
      payload: {
        bannerImage: bannerImages[randomNumber],
      },
    });
  } catch (error) {
    yield put({
      type: FETCH_BANNER_IMAGES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}
/**
 * worker saga: Calls the fetch home page data API.
 * if page === 1, then FETCH_HOME_PAGE_DATA_* actions will be dispatched
 * if page > 1,   then FETCH_MORE_HOME_PAGE_DATA_* actions will be dispatched
 *
 * @param {Object} action              - action object dispatched by user
 * @param {number} action.payload.page - pagination in fetch recent posts api
 */
// function* fetchHomePageData({ payload: { page } }) {
//   try {
//     yield put({
//       type:
//         page === FIRST_PAGE
//           ? FETCH_HOME_PAGE_DATA_LOADING
//           : FETCH_MORE_HOME_PAGE_DATA_LOADING,
//     });

//     // eslint-disable-next-line prefer-const
//     let {
//       firstItemId,
//       attractionIds,
//       articleIds,
//       newsFeedLimit,
//       postsIdentifiersMap,
//     } = yield select(getHomePageData);

//     // set first and last item id null for list refresh
//     if (page === 1) {
//       firstItemId = null;
//       attractionIds = shuffle(attractionIds);
//       articleIds = shuffle(articleIds);
//       yield put({
//         type: SHUFFLE_IDS,
//         payload: {
//           articleIds,
//           attractionIds,
//         },
//       });
//     }

//     // api call
//     const response = yield call(
//       { context: api, fn: api.fetchHomePageData },
//       {
//         page,
//         firstItemId,
//         attractionIds: attractionIds.slice(
//           (page - 1) * newsFeedLimit,
//           (page - 1) * newsFeedLimit + newsFeedLimit,
//         ),
//         articleIds: articleIds.slice(
//           (page - 1) * newsFeedLimit,
//           (page - 1) * newsFeedLimit + newsFeedLimit,
//         ),
//       },
//     );

//     // parse the data from response
//     const {
//       userResponse: {
//         guestUserId,
//         recommendations = [],
//         itemList,
//       },
//     } = response;

//     let posts = [...itemList];

//     const newPostsIdentifiersMap = {};
//     if (page !== 1) {
//       posts = posts.filter(post => !(post.identifier in postsIdentifiersMap));
//     }
//     posts.forEach(post => {
//       if (post.type === POST_SK || post.type === BLOG_SK) {
//         newPostsIdentifiersMap[post.identifier] = true;
//       }
//     });

//     const sections = [
//       ARTICLE_SK,
//       ATTRACTION_SK,
//       HOTEL_SK,
//       RESTAURANT_SK,
//       BLOGGER_SK,
//     ];

//     let randoms = [];
//     let i = 0;
//     while (i < posts.length) {
//       let random = getRandomNumber(i);
//       if (random < posts.length) randoms.push(random);
//       i = random;
//     }

//     randoms.map((random, k) => {
//       return posts[random].random = sections[k % 5];
//     });

//     // send parsed data into action payload
//     yield put({
//       type:
//         page === FIRST_PAGE
//           ? FETCH_HOME_PAGE_DATA_SUCCESS
//           : FETCH_MORE_HOME_PAGE_DATA_SUCCESS,
//       payload: {
//         posts,
//         firstItemId: getfirstPostItemId(posts),
//         guestUserId: parseInt(guestUserId, 10) || 0,
//         recommendations:
//           recommendations.length > 0 ? shuffle(recommendations) : [],
//         postsIdentifiersMap: newPostsIdentifiersMap,
//       },
//     });
//   } catch (error) {
//     yield put({
//       type:
//         page === FIRST_PAGE
//           ? FETCH_HOME_PAGE_DATA_ERROR
//           : FETCH_MORE_HOME_PAGE_DATA_ERROR,
//       payload: { errorCode: error.name, errorMessage: error.message },
//     });
//   }
// }

function* fetchNewsFeedData({ payload: { page } }) {
  try {
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_HOME_PAGE_DATA_LOADING
          : FETCH_MORE_HOME_PAGE_DATA_LOADING,
    });

    // eslint-disable-next-line prefer-const
    let { attractionIds, articleIds, newsFeedLimit, postsIdentifiersMap } =
      yield select(getHomePageData);

    // set first and last item id null for list refresh
    if (page === 1) {
      attractionIds = shuffle(attractionIds);
      articleIds = shuffle(articleIds);
      yield put({
        type: SHUFFLE_IDS,
        payload: {
          articleIds,
          attractionIds,
        },
      });
    }

    // api call
    const response = yield call(
      { context: api, fn: api.fetchNewsFeedData },
      {
        page,
        attractionIds: attractionIds.slice(
          (page - 1) * newsFeedLimit,
          (page - 1) * newsFeedLimit + newsFeedLimit
        ),
        articleIds: articleIds.slice(
          (page - 1) * newsFeedLimit,
          (page - 1) * newsFeedLimit + newsFeedLimit
        ),
      }
    );

    // parse the data from response
    const {
      userResponse: { itemList },
    } = response;

    let posts = [...itemList];

    const newPostsIdentifiersMap = {};
    if (page !== 1) {
      posts = posts.filter((post) => !(post.identifier in postsIdentifiersMap));
    }
    posts.forEach((post) => {
      if (post.type === POST_SK || post.type === BLOG_SK) {
        newPostsIdentifiersMap[post.identifier] = true;
      }
    });

    // send parsed data into action payload
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_HOME_PAGE_DATA_SUCCESS
          : FETCH_MORE_HOME_PAGE_DATA_SUCCESS,
      payload: {
        posts,
        postsIdentifiersMap: newPostsIdentifiersMap,
      },
    });
  } catch (error) {
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_HOME_PAGE_DATA_ERROR
          : FETCH_MORE_HOME_PAGE_DATA_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* updateBloggerNetworkStatusInApp({
  payload: { networkType, status, identifier },
}) {
  try {
    const { bloggerList } = yield select(getDataFromStore);
    bloggerList.forEach((blogger) => {
      if (blogger.userIdentifier === identifier) {
        if (networkType === FOLLOWING_SK) {
          blogger.followingStatus = status;
        } else {
          blogger.friendStatus = status;
        }
      }
    });

    yield put({
      type: UPDATE_BLOGGER_NETWORK_STATUS_SUCCESS,
      payload: {
        bloggers: bloggerList,
      },
    });
  } catch (error) {
    console.log("error occurs in blogger status update");
  }
}

/**
 *  update user network status in post list
 * @param payload
 * @return {IterableIterator<*>}
 */
function* updateUserFollowStatusInApp({ payload }) {
  try {
    const { posts, friends } = yield select(getDataFromStore);
    const postList = updateStatus(posts, payload);
    //const newRecentActivities = updateStatus(recentActivities, payload);
    //const newRangeActivities = updateStatus(rangeActivities, payload);
    const friendList = friends.map((friend) => {
      if (friend.identifier === payload.identifier) {
        return {
          ...friend,
          networkStatusWithCurrentUser: {
            ...friend.networkStatusWithCurrentUser,
            followStatus:
              payload.status === UNFOLLOWED_SK
                ? NO_CONNECTION_SK
                : FOLLOWING_SK,
          },
        };
      }
      return friend;
    });
    yield put({
      type: UPDATE_USER_FOLLOW_STATUS_SUCCESS,
      payload: {
        posts: postList,
        friends: friendList,
        //recentActivities: newRecentActivities,
        //rangeActivities: newRangeActivities,
      },
    });
  } catch (error) {
    console.log(error, "error occured  in status update");
  }
}

function* updateUserRelationList({ payload }) {
  try {
    const { friends, requests, invites, userDetails } = yield select(
      getDataFromStore
    );
    let friendList = friends;
    let requestList = requests;
    let invitesList = invites;

    switch (payload.status) {
      case UNFRIEND_SK:
        friendList = friends.filter((friend) => {
          return friend.identifier !== payload.user.identifier;
        });
        yield put({
          type: UNFRIEND_SUCCESS,
          payload: {
            friends: friendList,
          },
        });
        yield put({
          type: UPDATE_USER_FOLLOW_STATUS,
          payload: {
            status: NO_CONNECTION_SK,
            identifier: payload.user.identifier,
          },
        });
        yield put({
          type: GET_USER_DETAILS_SUCCESS,
          payload: {
            ...userDetails,
            bio: {
              ...userDetails.bio,
              totalFriends: friendList.length,

              // totalFollowers:
              //   userDetails.bio.totalFollowers > 0
              //     ? userDetails.bio.totalFollowers - 1
              //     : userDetails.bio.totalFollowers,
            },
          },
        });
        break;

      case BLOCKED_SK:
        friendList = friends.filter((friend) => {
          return friend.identifier !== payload.user.identifier;
        });
        yield put({
          type: BLOCKED_SUCCESS,
          payload: {
            friends: friendList,
          },
        });
        yield put({
          type: GET_USER_DETAILS_SUCCESS,
          payload: {
            ...userDetails,
            bio: {
              ...userDetails.bio,
              totalFriends: friendList.length,
              // totalFollowers:
              //   userDetails.bio.totalFollowers > 0
              //     ? userDetails.bio.totalFollowers - 1
              //     : userDetails.bio.totalFollowers,
            },
          },
        });
        break;

      case FRIEND_SK:
        requestList = requests.filter((request) => {
          return request.identifier !== payload.user.identifier;
        });
        friendList = friends.some(
          (friend) => friend.identifier === payload.user.identifier
        )
          ? friends
          : [
            ...friends,
            {
              ...payload.user,
              networkStatusWithCurrentUser: {
                followStatus: FOLLOWING_SK,
                relationStatus: FRIEND_SK,
              },
            },
          ];
        yield put({
          type: FRIEND_SUCCESS,
          payload: {
            requests: requestList,
            friends: friendList,
          },
        });
        yield put({
          type: UPDATE_USER_FOLLOW_STATUS,
          payload: {
            status: FOLLOWING_SK,
            identifier: payload.user.identifier,
          },
        });
        yield put({
          type: GET_USER_DETAILS_SUCCESS,
          payload: {
            ...userDetails,
            bio: {
              ...userDetails.bio,
              totalFriends: friendList.length,
            },
          },
        });
        break;

      case FRIEND_REQUEST_DELETE_SK:
        requestList = requests.filter((request) => {
          return request.identifier !== payload.user.identifier;
        });
        yield put({
          type: FRIEND_REQUEST_DELETE_SUCCESS,
          payload: {
            requests: requestList,
          },
        });
        break;

      case FRIEND_INVITE_DELETE_SK:
        invitesList = invites.filter((invite) => {
          return invite.identifier !== payload.user.identifier;
        });
        yield put({
          type: FRIEND_INVITE_DELETE_SUCCESS,
          payload: {
            invites: invitesList,
          },
        });
        break;

      default:
        invitesList = invites.some(
          (invite) => invite.identifier === payload.user.identifier
        )
          ? invites
          : [
            ...invites,
            {
              ...payload.user,
              networkStatusWithCurrentUser: {
                followStatus: FOLLOWING_SK,
                relationStatus: FRIEND_REQUEST_PENDING_SK,
              },
            },
          ];
        yield put({
          type: FRIEND_REQUEST_PENDING_SUCCESS,
          payload: {
            invites: invitesList,
          },
        });
        yield put({
          type: UPDATE_USER_FOLLOW_STATUS,
          payload: {
            status: FOLLOWING_SK,
            identifier: payload.user.identifier,
          },
        });
        break;
    }

    yield put({
      type: UPDATE_USER_RELATION_STATUS_SUCCESS,
      payload: {},
    });
  } catch (error) {
    console.log("error occured  in status update");
  }
}

function updateStatus(postList, payload) {
  return postList.map((post) => {
    if (post.type === POST_SK || post.type === BLOG_SK) {
      let userDetails = post.userDetails;
      let parentDetails = post.parentDetails;

      if (post.userDetails.identifier === payload.identifier) {
        userDetails = {
          ...post.userDetails,
          networkStatusWithCurrentUser: {
            ...post.userDetails.networkStatusWithCurrentUser,
            followStatus: payload.status,
          },
        };
      }

      if (
        post.isShared &&
        post.parentDetails.userDetails?.identifier === payload.identifier
      ) {
        parentDetails = {
          ...parentDetails,
          userDetails: {
            ...parentDetails.userDetails,
            networkStatusWithCurrentUser: {
              ...parentDetails.userDetails.networkStatusWithCurrentUser,
              followStatus: payload.status,
            },
          },
        };
      }

      return {
        ...post,
        userDetails,
        parentDetails,
      };
    }

    return post;
  });
}

/**
 * worker saga: delete post API.
 *
 * @param {Object} action                    - action object dispatched by user
 * @param {number} action.payload.identifier - unqiue id of the post which need to be deleted
 */
function* deletePost({ payload: { identifier } }) {
  try {
    // api call
    yield call({ context: api, fn: api.deletePost }, { identifier });
    // Access list from redux
    const { posts } = yield select(getDataFromStore);

    //delete post from list
    let filteredPosts = posts.filter((post) => post.identifier !== identifier);

    // send new posts list
    yield put({
      type: DELETE_HOME_POST_SUCCESS,
      payload: {
        posts: filteredPosts,
        deletedPostIdentifier: identifier,
      },
    });
  } catch (error) {
    console.log("error occured while deleting post");
  }
}

/**
 * worker saga: delete post API.
 *
 * @param {Object} action                    - action object dispatched by user
 * @param {number} action.payload.identifier - unqiue id of the blog which need to be deleted
 */
function* deleteBlog({ payload: { blogIdentifier } }) {
  try {
    // api call
    yield call({ context: api, fn: api.deleteBlog }, { blogIdentifier });
    // Access list from redux
    const { posts } = yield select(getDataFromStore);

    //delete post from list
    let filteredPosts = posts.filter(
      (post) => post.identifier !== blogIdentifier
    );

    // send new posts list
    yield put({
      type: DELETE_HOME_POST_SUCCESS,
      payload: {
        posts: filteredPosts,
        deletedPostIdentifier: blogIdentifier,
      },
    });
  } catch (error) {
    console.log("error occured while deleting blog");
  }
}

function* saveFavourite({
  payload: { entityIdentifier, entityType, userIdentifier },
}) {
  try {
    yield put({ type: SAVE_FAVOURITE_PLI_LOADING })
    // api call
    const response = yield call(
      { context: api, fn: api.saveFavourite },
      { entityIdentifier, entityType, userIdentifier }
    );

    // parse the data from response
    const {
      userResponse: { favouriteIdentifier },
    } = response;

    const { posts } = yield select(getDataFromStore);
    posts.forEach((post) => {
      if (post.identifier === entityIdentifier) {
        post.favouriteIdentifier = favouriteIdentifier;
        post.isSaved = UNSAVE_SK;
      }
      if (
        post.type === RANDOM_SK &&
        (post.entityType === HOTEL_SK || post.entityType === RESTAURANT_SK)
      ) {
        post.list.forEach((item) => {
          if (item.identifier === entityIdentifier) {
            item.favouriteIdentifier = favouriteIdentifier;
            item.isSaved = UNSAVE_SK;
          }
        });
      }
    });

    yield put({
      type: SAVE_FAVOURITE_PLI_SUCCESS,
      payload: {
        posts,
        entityIdentifier,
        favouriteIdentifier,
      },
    });
  } catch (error) {
    yield put({
      type: SAVE_FAVOURITE_PLI_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message }
    });
  }
}

function* deleteFavourite({
  payload: { favouriteIdentifier, entityType, entityIdentifier },
}) {
  yield put({ type: DELETE_FAVOURITE_PLI_LOADING })
  try {
    // api call
    yield call(
      { context: api, fn: api.deleteFavourite },
      { favouriteIdentifier }
    );
    const { posts } = yield select(getDataFromStore);
    posts.forEach((post) => {
      if (post.identifier === entityIdentifier) {
        post.favouriteIdentifier = "";
        post.isSaved = SAVE_SK;
      }
      if (
        post.type === RANDOM_SK &&
        (post.entityType === HOTEL_SK || post.entityType === RESTAURANT_SK)
      ) {
        post.list.forEach((item) => {
          if (item.identifier === entityIdentifier) {
            item.favouriteIdentifier = "";
            item.isSaved = SAVE_SK;
          }
        });
      }
    });
    yield put({
      type: DELETE_FAVOURITE_PLI_SUCCESS,
      payload: {
        posts,
        entityIdentifier,
        favouriteIdentifier,
      },
    });
  } catch (error) {
    yield put({
      type: DELETE_FAVOURITE_PLI_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message }
    })
  }
}

function* incrementShareCountInApp({ payload: { identifier } }) {
  try {
    const { posts } = yield select(getDataFromStore);
    posts.forEach((post) => {
      if (post.identifier === identifier) {
        post.totalShares = post.totalShares + 1;
      }
    });

    yield put({
      type: UPDATE_SHARE_COUNT_SUCCESS,
      payload: {
        posts,
      },
    });
  } catch (error) {
    console.log("error occurs in blogger status update");
  }
}

function* updateReactionInApp({
  payload: { identifier, newReaction, likesCount },
}) {
  try {
    const { posts } = yield select(getDataFromStore);
    let _posts = posts.map((post) => {
      if (post.identifier === identifier) {
        return {
          ...post,
          currentUserReaction: newReaction,
          totalLikes: likesCount,
        };
      }
      return post;
    });

    yield put({
      type: UPDATE_REACTION_SUCCESS,
      payload: {
        posts: _posts,
      },
    });
  } catch (error) {
    console.log("error occurs in reaction update");
  }
}

function* updateCommentCountInApp({ payload: { identifier, count } }) {
  try {
    const { posts } = yield select(getDataFromStore);
    let _posts = posts.map((post) => {
      if (post.identifier === identifier) {
        return {
          ...post,
          totalComments: count,
        };
      }
      return post;
    });

    yield put({
      type: UPDATE_COMMENT_COUNT_SUCCESS,
      payload: {
        posts: _posts,
      },
    });
  } catch (error) {
    console.log("error occurs in comment update");
  }
}

function* updateViewCount({ payload: { identifier, count } }) {
  try {
    const { posts } = yield select(getDataFromStore);

    let _posts = posts.map((post) => {
      if (post.identifier === identifier) {
        return {
          ...post,
          totalViews: count,
        };
      }
      return post;
    });

    yield put({
      type: UPDATE_VIEW_COUNT_SUCCESS,
      payload: {
        posts: _posts,
      },
    });
  } catch (error) {
    console.log("error occurs in update view count");
  }
}

function* updateHomePageData({ payload: { favouriteIdentifier, identifier } }) {
  try {
    const { posts } = yield select(getDataFromStore);
    posts.forEach((post) => {
      if (post.type === RANDOM_SK) {
        post.list.forEach((item) => {
          if (item.identifier === identifier) {
            if (favouriteIdentifier && identifier) {
              item.favouriteIdentifier = favouriteIdentifier;
              item.isSaved = UNSAVE_SK;
            } else {
              item.favouriteIdentifier = "";
              item.isSaved = SAVE_SK;
            }
          }
        });
      } else {
        if (post.identifier === identifier) {
          if (favouriteIdentifier && identifier) {
            post.favouriteIdentifier = favouriteIdentifier;
            post.isSaved = UNSAVE_SK;
          } else {
            post.favouriteIdentifier = "";
            post.isSaved = SAVE_SK;
          }
        }
      }
    });
  }
  catch (error) {
    console.log("error occurs in update home page data");
  }
}

/**
 * Fetch data from redux store
 *
 * NOTE: Don't overfill this for all data acess from redux store, create new
 * accessor if requirement is different or gets exhaustive
 *
 * @return {Object} return user identifier
 */
const getDataFromStore = ({ home, profile, articles, contracts, bloggers }) => {
  const { posts, recommendations } = home;
  //const { recentActivities, rangeActivities } = history;
  const { friends, requests, invites, userDetails } = profile;
  const { articles: popularArticles } = articles;
  const { hotels, restaurants } = contracts;
  const { bloggers: bloggerList } = bloggers;
  return {
    posts,
    recommendations,
    friends,
    requests,
    invites,
    userDetails,
    popularArticles,
    hotels,
    restaurants,
    bloggerList,
    //recentActivities,
    //rangeActivities,
  };
};

// In items array, get last item id whose type="post"
function getfirstPostItemId(items) {
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].type === POST_SK || items[i].type === BLOG_SK) {
      return items[i].itemId;
    }
  }

  return null;
}

/**
 * Fetch data from redux store only for
 * fetchHomePageData generator function
 */
const getHomePageData = ({ app, home }) => {
  const { articleIds, attractionIds, newsFeedLimit } = app;
  const { firstItemId, postsIdentifiersMap, randomNumber } = home;

  return {
    articleIds,
    attractionIds,
    firstItemId,
    postsIdentifiersMap,
    newsFeedLimit,
    randomNumber,
  };
};

function getRandomNumber(min) {
  return getRandomIntRange(min + 2, min + 5);
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
// function getRandomArbitrary(min, max) {
//   return Math.floor(Math.random() * (max - min) + min);
// }

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomIntRange(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_BANNER_IMAGES_REQUEST, fetchBannerImages);
  // yield takeLatest(FETCH_HOME_PAGE_DATA_REQUEST, fetchHomePageData);
  yield takeLatest(FETCH_HOME_PAGE_DATA_REQUEST, fetchNewsFeedData);
  yield takeLatest(UPDATE_USER_FOLLOW_STATUS, updateUserFollowStatusInApp);
  yield takeLatest(UPDATE_USER_RELATION_STATUS, updateUserRelationList);
  yield takeLatest(
    UPDATE_BLOGGER_NETWORK_STATUS,
    updateBloggerNetworkStatusInApp
  );
  yield takeLatest(DELETE_HOME_POST_REQUEST, deletePost);
  yield takeLatest(DELETE_BLOG_REQUEST, deleteBlog);

  yield takeLatest(SAVE_FAVOURITE_PLI_REQUEST, saveFavourite);
  yield takeLatest(DELETE_FAVOURITE_PLI_REQUEST, deleteFavourite);
  yield takeLatest(UPDATE_SHARE_COUNT, incrementShareCountInApp);
  yield takeLatest(UPDATE_REACTION, updateReactionInApp);
  yield takeLatest(UPDATE_COMMENT_COUNT, updateCommentCountInApp);
  yield takeLatest(UPDATE_VIEW_COUNT, updateViewCount);
  yield takeLatest(UPDATE_HOME_PAGE_DATA, updateHomePageData);
}
