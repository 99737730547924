
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import loading from '../../assets/images/loading.gif'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    background: 'white',
  },
  img: {
    width: '50%',
    height: 'auto',
    objectFit: 'cover',
  },
}));
const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={loading}
        alt="loading"
        className={classes.img}
      />
    </div>
  )
}
export default Loading;