import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import appReducer from './app/appReducer';
import homeReducer from './home/homeReducer';
import articlesReducer from './articles/articlesReducer';
import contractsReducer from './contracts/contractsReducer';
import bloggersReducer from './bloggers/bloggersReducer';
import servicesReducer from './services/servicesReducer';
import authReducer from './auth/authReducer';
import profileReducer from './profile/profileReducer';
import attractionReducer from './attraction/attractionReducer';
import businessReducer from './business/businessReducer';
// import postReducer from './addPost/postReducer';

const createHistory= require("history").createBrowserHistory;

export default combineReducers({
    router : connectRouter(createHistory()),
    app: appReducer,
    home: homeReducer,
    articles: articlesReducer,
    contracts: contractsReducer,
    bloggers: bloggersReducer,
    services: servicesReducer,
    auth:authReducer,
    profile:profileReducer,
    attraction:attractionReducer,
    business: businessReducer,
    // post: postReducer,
});