import React from 'react';
import Carousel from "react-multi-carousel";
import { makeStyles } from '@material-ui/core/styles';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import LandmarkByAttractionItem from './LandmarkByAttractionItem';

const useStyles = makeStyles((theme) => ({

  carousel_pad: {
    padding: '10px!important',
    height: '100% !important'
  },
}));
const LandmarkByAttraction = (props) => {
  const {
    landmarks,
    setLanguage,
    fetchDetails,
  } = props;
  const classes = useStyles();
  const responsive = {
    desktop1: {
      breakpoint: { max: 3000, min: 1279 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },

    desktop2: {
      breakpoint: { max: 1279, min: 960 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 960, min: 750 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 750, min: 500 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
  };

  return (
    <Carousel swipeable={true}
      autoPlay={false}
      draggable={true}
      centerMode={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass={classes.carousel_pad}
    >

      {landmarks?.map((item, key) => {
        return (
          <LandmarkByAttractionItem
            item={item}
            key={key}
            setLanguage={setLanguage}
            fetchDetails={fetchDetails}
          />
        )
      })}
    </Carousel>

  )

}
const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};


export default connect(mapStateToProps, {

})(LandmarkByAttraction);




