/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript
} from "@react-google-maps/api";

import {
  CssBaseline,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  IconButton,
  Stack
} from "@mui/material";

import {
  ExpandMore as ExpandMoreIcon,
  People as PeopleIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon,
  LanguageOutlined as LanguageOutlinedIcon,
  PhoneIphoneOutlined as PhoneIphoneOutlinedIcon
} from "@mui/icons-material";

import { BsHourglassSplit, BsClock } from "react-icons/bs";
import { GoMail, GoLocation } from "react-icons/go";
import { AiOutlineFlag, AiOutlinePicture } from "react-icons/ai";
import { MdOutlineStoreMallDirectory, MdDirectionsBus } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoShirtOutline } from "react-icons/io5";

import {
  resetAttractionDetailsState,
  fetchAttractionDetails,
  fetchReviews,
  fetchArticlesByAttraction,
  fetchAllAttractionFiles,
  saveFavourite,
  deleteFavourite,
  //resetSharePostStatusToDefault,
  resetFavouriteStatusToDefault,
  updateViewCount
} from "../../store/actions";
import {
  ATTRACTION_SK,
  BURMESE_LANGUAGE_CODE_LK,
  //DESCENDING_SK,
  ENGLISH_LANGUAGE_CODE_LK,
  IMAGE_SK,
  NEARBY_LK,
  SAVE_SK,
  UNSAVE_SK,
} from "../../constants";
import { isNonEmptyString } from "../../utils";
import { api, Status } from "../../api";
import { getCurrentLocale, translate } from "../../i18n";
import { GOOGLE_API_KEY } from "../../config/buildConfig";
import {
  AppBar,
  AttractionGallery,
  ArticleByAttraction,
  BackToTopIcon,
  BackIcon,
  CreateReviewIcon,
  CopyLinkIcon,
  LandmarkByAttraction,
  RatingStar,
  Space,
  Spinner,
  Snack,
  MediaViewer
} from "../../common";
import { attractionMapMarker } from "../../utils/mapMarkers";
import myFlag from "../../assets/countryFlags/Myanmar_95.png";
import enFlag from "../../assets/countryFlags/United_Kingdom_44.png";
import styles from "./AttractionDetails.module.scss";
import Reviews from "../Reviews/Reviews";

const Attraction = ({
  userIdentifier,
  history,
  resetAttractionDetailsState,
  // recentReviews,
  // reviewStatus,
  // moreReviewStatus,
  // reviewsErrorMessage,
  // moreReviewsErrorMessage,
  // isLoadMoreDisplay,
  fetchAllAttractionFiles,
  attractionFiles,
  //sharePostStatus,
  favouriteStatus,
  favouriteIdentifier,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  //resetSharePostStatusToDefault: _resetSharePostStatusToDefault,
  updateViewCount: _updateViewCount,
  resetFavouriteStatusToDefault: _resetFavouriteStatusToDefault,
}) => {

  //const FIRST_PAGE = 1;

  // Using the "useLoadScript" hook to asynchronously load the Google Maps API with the provided API key.
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_API_KEY, });
  let { id: _attractionIdentifier } = useParams();
  const attractionIdentifier = atob(_attractionIdentifier);
  const c = new URLSearchParams(window.location.search).get("c");

  const [attraction, setAttraction] = useState({});
  const [articles, setArticles] = useState([]);
  const [articlesStatus, setArticlesStatus] = useState(Status.DEFAULT);
  const [language, setLanguage] = useState(getCurrentLocale());
  const [snackSaveOpen, setSnackSaveOpen] = useState(false);
  const [snackUnsaveOpen, setSnackUnsaveOpen] = useState(false);
  const [attractionStatus, setAttractionStatus] = useState(Status.DEFAULT);
  //const [openShareModal, setOpenShareModal] = useState(false);
  //const [shareFlag, setShareFlag] = useState(false);
  //const [shareSnackOpen, setShareSanckOpen] = useState(false);
  const [favSnackOpen, setFavSnackOpen] = useState(false);
  const [favFlag, setFavFlag] = useState(false);
  //const [page, setPage] = useState(FIRST_PAGE);
  const [copySnackOpen, setCopySanckOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [openGallery, setOpenGallery] = useState(false);
  // const [reviewsState, setReviewsState] =
  //   (prevState, newState) => ({ ...prevState, ...newState }),
  //   { apiStatus: Status.DEFAULT, data: [], count: 0 }
  // );
  const [state, setState] = useState({
    activeMarker: null,
    showingInfoWindow: false,
    id: null,
  });
  const [isReviewListUpdated, setIsReviewListUpdated] = useState(false);

  useEffect(() => {

    fetchDetails(attractionIdentifier);

    //increase view count after 5 sec
    setTimeout(() => {
      increaseViewCount();
    }, 5000);

    return () => {
      resetAttractionDetailsState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      attractionStatus === Status.SUCCESS &&
      attraction.visitorsInfo?.travellingDescription
    ) {
      document.getElementById("travelling-desc").innerHTML =
        attraction.visitorsInfo?.travellingDescription;
    }
    if (
      attractionStatus === Status.SUCCESS &&
      attraction.overview?.description
    ) {
      document.getElementById("attr-desc").innerHTML =
        attraction.overview?.description;
    }
  }, [attractionStatus, attraction]);

  // useEffect(() => {
  //   if (sharePostStatus === Status.SUCCESS) {
  //     if (shareFlag) {
  //       setShareSanckOpen(true);
  //       setShareFlag(false);
  //       _resetSharePostStatusToDefault();
  //     }
  //   }
  // }, [sharePostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (favouriteStatus === Status.SUCCESS) {
      let _attraction = {
        ...attraction,
        favouriteIdentifier: favouriteIdentifier,
        isSaved: attraction.isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK,
      };
      setAttraction(_attraction);
      if (favFlag) {
        setFavSnackOpen(true);
        setFavFlag(false);
      }
      _resetFavouriteStatusToDefault();
    }

  }, [favouriteStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const increaseViewCount = () => {
    setAttraction((prevState) => {
      const updatedView = prevState.totalViews + 1;
      _updateViewCount(attractionIdentifier, updatedView);
      return {
        ...prevState,
        totalViews: prevState.totalViews + 1,
      };
    });
  };

  const onMarkerClick = (props, marker, e, key) =>
    setState({
      activeMarker: marker,
      showingInfoWindow: true,
      id: key,
    });

  const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        showingInfoWindow: false,
        activeMarker: null,
        id: null,
      });
    }
  };

  // const handleShareSnackClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setShareSanckOpen(false);
  // };

  const handleFavSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFavSnackOpen(false);
  };

  // const handleOpenShareModal = () => {
  //   setShareFlag(true);
  //   setOpenShareModal(true);
  // };

  // const handleCloseShareModal = () => {
  //   setOpenShareModal(false);
  // };

  const handleCopySnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySanckOpen(false);
  };

  const save = () => {
    if (favouriteStatus !== Status.LOADING) {
      setFavFlag(true);
      if (attraction?.isSaved === SAVE_SK) {
        _saveFavourite(attractionIdentifier, ATTRACTION_SK, userIdentifier);
      } else {
        _deleteFavourite(
          attraction?.favouriteIdentifier,
          ATTRACTION_SK,
          attractionIdentifier
        );
      }
    }
  };

  const updateReviewList = (updated) => {
    setIsReviewListUpdated(updated ? true : false);
  };

  const changeLanguage = () => {
    let _language =
      language === ENGLISH_LANGUAGE_CODE_LK
        ? BURMESE_LANGUAGE_CODE_LK
        : ENGLISH_LANGUAGE_CODE_LK;
    setAttractionStatus(Status.LOADING);
    api
      .fetchAttractionDetails({
        attractionIdentifier,
        language: _language,
        updateViewCount: false,
      })
      .then(({ userResponse: attraction }) => {
        setAttraction((prevState) => {
          return {
            ...attraction,
            landmarks: prevState.landmarks,
          }
        });
        setAttractionStatus(Status.SUCCESS);
      })
      .catch(() => {
        setAttractionStatus(Status.ERROR);
      });

    setLanguage(_language);
    //setExpandReview(false);
  };

  const fetchDetails = (attractionIdentifier) => {
    setAttractionStatus(Status.LOADING);
    api
      .fetchAttractionDetails({
        attractionIdentifier,
        updateViewCount: 1,
      })
      .then(({ userResponse: attraction }) => {
        setAttraction(attraction);
        setAttractionStatus(Status.SUCCESS);
      })
      .catch(() => {
        setAttractionStatus(Status.ERROR);
      });

    /**
     * Calling fetchArticlesByAttraction API for Article You May Like Section
     */
    fetchArticles();
    /**
     * Calling fetchAllAttractionFiles files API for Gallery Section
     */
    fetchAllAttractionFiles(attractionIdentifier);
    /**
     * Calling Review List API for Review Section
     */
    //refreshList(attractionIdentifier);
    //setExpandReview(false);
  };

  const fetchArticles = () => {
    setArticlesStatus(Status.LOADING);
    api
      .fetchArticlesByAttraction({
        attractionIdentifier,
      })
      .then(({ userResponse }) => {
        setArticles(userResponse);
        setArticlesStatus(Status.SUCCESS);
      })
      .catch(() => {
        setArticlesStatus(Status.ERROR);
      });
  };

  // const refreshList = (attractionIdentifier, _page = 1) => {
  //   setReviewsState({ apiStatus: Status.LOADING });
  //   api
  //     .fetchReviews({
  //       type: ATTRACTION_SK,
  //       identifier: attractionIdentifier,
  //       page: _page,
  //       sortOrder: DESCENDING_SK,
  //     })
  //     .then(({ userResponse: { reviewList, totalReviewCount } }) => {
  //       if (_page === 1) {
  //         setReviewsState({
  //           data: reviewList,
  //           count: totalReviewCount,
  //           apiStatus: Status.SUCCESS,
  //         });
  //       } else {
  //         setReviewsState({
  //           data: [...reviewsState.data, ...reviewList],
  //           apiStatus: Status.SUCCESS,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       setReviewsState({ apiStatus: Status.ERROR });
  //     });
  // };

  // useEffect(() => {
  //   if (
  //     page !== 1 &&
  //     attractionStatus === Status.SUCCESS &&
  //     reviewsState.apiStatus !== Status.DEFAULT
  //   ) {
  //     refreshList(attractionIdentifier, page);
  //   }
  // }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateAvgRating = (avgRating) => {
    setAttraction((prevState) => {
      return {
        ...prevState,
        avgRating: avgRating
      }
    })
  };

  const handleArticleSave = (identifier, favouriteIdentifier) => {
    setArticles(prevState => {
      const articles = prevState.map((article) => {
        if (article.identifier === identifier) {
          return {
            ...article,
            isSaved: UNSAVE_SK,
            favouriteIdentifier: favouriteIdentifier,
          };
        }
        return article;
      });
      return articles;
    });
    setSnackSaveOpen(true);
  }

  const handleArticleUnsave = (identifier) => {
    setArticles(prevState => {
      const articles = prevState.map((article) => {
        if (article.identifier === identifier) {
          return {
            ...article,
            isSaved: SAVE_SK,
            favouriteIdentifier: "",
          };
        }
        return article;
      });
      return articles;
    });
    setSnackUnsaveOpen(true);
  }

  const handlePhotoClick = (index) => {
    setSelectedImageIndex(index);
    setOpenGallery(true);
  }

  const closeMediaViewer = () => {
    setOpenGallery(false);
  }

  if (!isNonEmptyString(userIdentifier)) {
    return <Redirect to="/" />;
  }

  const handleSnackSaveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackSaveOpen(false);
  };

  const handleSnackUnsaveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackUnsaveOpen(false);
  };

  if (attractionStatus === Status.LOADING) {
    return <Spinner />;
  }

  var mapOptions = {
    // Disables the map type control, which allows users to switch between different map types (e.g., satellite, terrain).
    mapTypeControl: false,

    // Disables the street view control, which allows users to view 360-degree street-level imagery.
    streetViewControl: false,

    // Configures the map styles to modify the appearance of certain map features.
    styles: [

      // Hides attractions from the map. Attractions are points of interest that are typically interesting or entertaining for users.
      {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
      },

      // Hides business-related points of interest (e.g., stores, restaurants, hotels) from the map.
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const renderHeading = () => (
    <div id={styles["heading"]}>
      <Grid container id={styles["heading-grid-root"]}>
        <Grid item xs={12} md={5}>
          <Grid container direction="column" id={styles["description-grid"]}>
            <Grid item xs={2} sm={3} />
            <Grid item xs={7} sm={6}>
              <div id={styles["description"]}>
                <div id={styles["description-title"]}>{attraction.title}</div>
                <br />
                <div id={styles["short-description"]}>
                  {attraction.overview?.shortDescription}
                </div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} id={styles["bottom-grid-item"]}>
              <Stack direction="column" spacing={2} alignItems="flex-end">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Stack
                    direction="row"
                    columnGap={1}
                    id={styles["views"]}
                    alignItems="flex-end"
                  >
                    <PeopleIcon id={styles["viewicon"]} />
                    <span id={styles["view-label"]}>
                      {`${attraction.totalViews} ${attraction.totalViews > 1
                        ? translate("articleDetails.views")
                        : translate("articleDetails.view")
                        }`}
                    </span>
                  </Stack>
                  <Stack
                    id={styles["views"]}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RatingStar
                      rating={attraction.avgRating}
                      size="small"
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-end"
                  id={styles["header-icon-group"]}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span id={styles["lang-label"]}>
                      {translate("articleDetails.seeTranslation")}
                    </span>
                    <img
                      alt="img"
                      id={styles["flag-icon"]}
                      src={
                        language === BURMESE_LANGUAGE_CODE_LK ? enFlag : myFlag
                      }
                      onClick={changeLanguage}
                    />
                  </Stack>

                  <CopyLinkIcon
                    text={`${attraction.copyLinkUrl}?c=1`}
                    onCopy={() => setCopySanckOpen(true)}
                  />
                  {/* <IconButton className="share-icon-button">
                      <IoMdShareAlt />
                    </IconButton> */}
                  <IconButton
                    onClick={save}
                    className={styles["save-icon-button"]}
                  >
                    {attraction?.isSaved === SAVE_SK ? (
                      <BookmarkBorderIcon
                        className={styles["save-icon-style"]}
                      />
                    ) : (
                      <BookmarkIcon className={styles["save-icon-style"]} />
                    )}
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={styles["cover-img-wrap"]}>
            <img
              id={styles["cover-img"]}
              alt="img"
              src={attraction?.coverImg?.url?.originalUrl}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={styles["attraction-details-root"]}>
      <CssBaseline />
      <AppBar />
      <div id="back-to-top-anchor" />
      <BackIcon isCopy={c} />
      <div id={styles["attraction-details"]}>{renderHeading()}</div>
      <div className={styles["bg-attraction"]}>
        <div className={styles["attraction-info"]}>
          <div id={styles["attraction-overview-container"]}>
            {attraction.overview?.openDay === null &&
              attraction?.overview?.openTime === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <BsClock id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction?.overview?.openDay}
                  <Space />
                  {attraction?.overview?.openTime}
                </div>
              </div>
            )}
            {attraction?.overview?.attractionSights === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <AiOutlineFlag id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction?.overview?.attractionSights}
                </div>
              </div>
            )}
            {attraction?.overview?.email === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <GoMail id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction?.overview?.email}
                </div>
              </div>
            )}
            {attraction.overview?.websiteUrl === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <LanguageOutlinedIcon id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction.overview?.websiteUrl}
                </div>
              </div>
            )}
            {attraction.overview?.recommendedDurations === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <BsHourglassSplit id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {translate("attractionDetails.recommendDuration")}
                </div>
                <Space />
                <div id={styles["info-text"]}>
                  {attraction.overview?.recommendedDurations}
                </div>
              </div>
            )}
            {attraction.overview?.mobileNumber === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div id={styles["overview-icon-container"]}>
                  <PhoneIphoneOutlinedIcon id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction.overview?.mobileNumber}
                </div>
              </div>
            )}
            {attraction.overview?.address === null ? null : (
              <div id={styles["attraction-overview-items"]}>
                <div>
                  <GoLocation id={styles["overview-icon"]} />
                </div>
                <div id={styles["info-text"]}>
                  {attraction.overview?.address}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["attraction-description"]}>
          <div id="attr-desc" className={styles["attr-desc"]} />
          <div className={clsx(styles["slide-pad"],)}>
            {attractionFiles?.length > 0 ?
              <div className={styles["gallery"]}>
                <div className={styles["gallery-title"]}>{translate('attractionDetails.gallery')}</div>
                <AttractionGallery images={attractionFiles} handlePhotoClick={handlePhotoClick} />
              </div>
              : null
            }

            {!attraction?.visitorsInfo?.dressCode &&
              !attraction?.visitorsInfo?.entranceFees &&
              !attraction?.visitorsInfo?.exhibits &&
              !attraction?.visitorsInfo?.visitingHrs &&
              !attraction?.visitorsInfo?.facilities &&
              !attraction?.visitorsInfo?.travellingDescription ? null : (
              <div className={styles["visitor-info"]}>
                <Accordion
                  classes={{ root: styles["collapse-box-shadow"] }}
                >
                  <div id={styles["visitors-info-collapse-bar"]}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div id={styles["visitors-info-bar"]}>
                        {translate("attractionDetails.visitorInformation")}
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails
                    classes={{ root: styles["collapse-details-root"] }}
                  >
                    <div>
                      {attraction?.visitorsInfo?.dressCode && (
                        <div className={styles["visitors-info-item"]}>
                          <div>
                            <IoShirtOutline
                              className={styles["visitors-info-icon"]}
                            />
                          </div>
                          <span>{attraction?.visitorsInfo?.dressCode}</span>
                        </div>
                      )}
                      {attraction?.visitorsInfo?.entranceFees && (
                        <div className={styles["visitors-info-item"]}>
                          <div>
                            <FaRegMoneyBillAlt
                              className={styles["visitors-info-icon"]}
                            />
                          </div>
                          <span>
                            {attraction?.visitorsInfo?.entranceFees}
                          </span>
                        </div>
                      )}
                      {attraction?.visitorsInfo?.exhibits && (
                        <div className={styles["visitors-info-item"]}>
                          <div>
                            <AiOutlinePicture
                              className={styles["visitors-info-icon"]}
                            />
                          </div>
                          <span>{attraction?.visitorsInfo?.exhibits}</span>
                        </div>
                      )}
                      {attraction?.visitorsInfo?.visitingHrs && (
                        <div className={styles["visitors-info-item"]}>
                          <div>
                            <BsClock
                              className={styles["visitors-info-icon"]}
                            />
                          </div>
                          <span>
                            {attraction?.visitorsInfo?.visitingHrs}
                          </span>
                        </div>
                      )}
                      {attraction?.visitorsInfo?.facilities && (
                        <div className={styles["visitors-info-item"]}>
                          <div>
                            <MdOutlineStoreMallDirectory
                              className={styles["visitors-info-icon"]}
                            />
                          </div>
                          <span>
                            {attraction?.visitorsInfo?.facilities}
                          </span>
                        </div>
                      )}
                      <div
                        className={styles["visitors-info-item"]}
                        style={{ alignItems: "flex-start" }}
                      >
                        {attraction?.visitorsInfo
                          ?.travellingDescription && (
                            <div>
                              <MdDirectionsBus
                                className={styles["visitors-info-icon"]}
                              />
                            </div>
                          )}
                        <span id="travelling-desc"></span>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            <br />
            {!attraction?.overview?.history ? null : (
              <div className={styles["visitor-info"]}>
                <Accordion
                  classes={{ root: styles["collapse-box-shadow"] }}
                >
                  <div id={styles["visitors-info-collapse-bar"]}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div id={styles["visitors-info-bar"]}>
                        {translate("attractionDetails.history")}
                      </div>
                    </AccordionSummary>
                  </div>
                  <AccordionDetails
                    classes={{ root: styles["collapse-details-root"] }}
                  >
                    {attraction?.overview.history && (
                      <div className={styles["history"]}>
                        {attraction?.overview?.history}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        </div>
        <div id={styles["review-grid"]}>
          <Reviews
            isReviewListUpdated={isReviewListUpdated}
            type={ATTRACTION_SK}
            identifier={attractionIdentifier}
            entityStatus={attractionStatus}
          />
        </div>
        {articles?.length > 0 ? (
          <div className={styles["related-articles-section"]}>
            <div className={styles["related-article-title"]}>
              {translate("attractionDetails.relatedArticles")}
            </div>
            <div
              className={styles["button"]}
              onClick={() => history.push(`/list/articles?id=${window.btoa(attractionIdentifier)}`)}
            >
              {translate("common.seeAll")}
            </div>
          </div>
        ) : null}

        {articles?.length > 0 ? (
          <ArticleByAttraction
            articles={articles}
            language={language}
            saveSuccessCallback={handleArticleSave}
            unsaveSuccessCallback={handleArticleUnsave}
          />
        ) : null}
        {(attraction?.landmarks?.length > 0 && isLoaded) && (
          <div className={styles["map"]}>
            <div className={styles["top-shadow"]}></div>
            <div className={styles["bottom-shadow"]}></div>
            <div
              className={styles["button"]}
              onClick={() => history.push(`/list/attractions/${NEARBY_LK}`, {
                state: attraction.overview?.state,
              })}
            >
              {translate("common.seeAll")}
            </div>

            <GoogleMap
              zoom={12}
              mapContainerStyle={{
                width: "100%",
                height: "600px",
                borderRadius: "6px",
              }}
              defaultCenter={{
                lat: attraction?.landmarks[0]?.latitude,
                lng: attraction?.landmarks[0]?.longitude,
              }}
              center={{
                lat: attraction?.landmarks[0]?.latitude,
                lng: attraction?.landmarks[0]?.longitude,
              }}
              options={mapOptions}
            >
              {attraction?.landmarks?.map((attraction, key) => (
                <Marker
                  key={key}
                  title={attraction.name}
                  position={{
                    lat: attraction.latitude,
                    lng: attraction.longitude,
                  }}
                  onClick={(props, marker, e) =>
                    onMarkerClick(props, marker, e, key)
                  }
                  icon={{
                    url: attractionMapMarker,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                ></Marker>
              ))}
              {attraction?.landmarks?.map((attraction, key) => {
                if (state.showingInfoWindow && state.id === key) {
                  return (
                    <InfoWindow
                      key={key}
                      onCloseClick={onClose}
                      position={{
                        lat: attraction.latitude,
                        lng: attraction.longitude,
                      }}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -30),
                      }}
                    >
                      <a
                        href={`/${btoa(
                          attraction.identifier
                        )}/${ATTRACTION_SK}`}
                      >
                        <h4>{attraction.name}</h4>
                      </a>
                    </InfoWindow>
                  );
                }
              })}
            </GoogleMap>
            <div
              className={clsx(
                styles["titles"],
                styles["landmark-map-title"]
              )}
            >
              {attraction?.landmarks?.length > 0 ? (
                <span>
                  {translate("attractionDetails.nearbyAttractions")}
                </span>
              ) : null}
            </div>
          </div>
        )}
        {attraction?.landmarks?.length > 0 && (
          <div
            id={styles["landmark-by-attraction"]}
            className="landmark-by-attraction"
          >
            <LandmarkByAttraction
              landmarks={attraction.landmarks}
              setLanguage={setLanguage}
              fetchDetails={fetchDetails}
            />
          </div>
        )}
      </div>
      <CreateReviewIcon
        identifier={attractionIdentifier}
        type={ATTRACTION_SK}
        updateReviewList={updateReviewList}
        title={attraction.title}
        updateAvgRating={updateAvgRating}
      />
      {attractionFiles?.length > 0 && <MediaViewer
        open={openGallery}
        type={IMAGE_SK}
        mediaList={attractionFiles}
        selected={selectedImageIndex}
        close={closeMediaViewer}
        attractionDetails
      />}
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
      {/* <EditShareModal
        open={openShareModal}
        identifier={attractionIdentifier}
        mode="share"
        type={ATTRACTION_SK}
        handleClose={handleCloseShareModal}
      /> */}
      <Snack
        open={favSnackOpen}
        onClose={handleFavSnackClose}
        type="success"
        message={attraction?.isSaved === SAVE_SK
          ? translate("homeScreen.unsaveFavouriteSuccessMessage")
          : translate("homeScreen.saveFavouriteSuccessMessage")}
      />
      <Snack
        open={copySnackOpen}
        onClose={handleCopySnackClose}
        type="success"
        message={translate("postListItem.copyLinkSuccessMessage")}
      />
      <Snack
        open={snackSaveOpen}
        onClose={handleSnackSaveClose}
        message={translate("homeScreen.saveFavouriteSuccessMessage")}
      />
      <Snack
        open={snackUnsaveOpen}
        onClose={handleSnackUnsaveClose}
        message={translate("homeScreen.unsaveFavouriteSuccessMessage")}
      />
    </div>

  );
};

const mapStateToProps = ({ profile, home, attraction }) => {
  const { userIdentifier } = profile;
  const {
    //sharePostStatus, 
    favouriteStatus,
    favouriteIdentifier
  } = home;
  const {
    attractionDetails,
    attractionFiles,
    recentReviews,
    isLoadMoreDisplay,
    reviewsAvailableForRefresh,
    reviewStatus,
    moreReviewStatus,
    reviewsErrorMessage,
    moreReviewsErrorMessage,
    attractionFilesStatus,
    attractionFilesErrorMessage,
    deleteFavSuccessMessage,
    saveFavStatus,
    deleteFavStatus,
  } = attraction;

  return {
    userIdentifier,
    attractionDetails,
    recentReviews,
    isLoadMoreDisplay,
    reviewsAvailableForRefresh,
    reviewStatus,
    reviewsErrorMessage,
    moreReviewsErrorMessage,
    moreReviewStatus,
    attractionFiles,
    attractionFilesStatus,
    attractionFilesErrorMessage,
    favouriteIdentifier,
    deleteFavSuccessMessage,
    saveFavStatus,
    deleteFavStatus,
    //sharePostStatus,
    favouriteStatus,
  };
};

export default connect(mapStateToProps, {
  fetchAttractionDetails,
  resetAttractionDetailsState,
  fetchReviews,
  fetchAllAttractionFiles,
  fetchArticlesByAttraction,
  saveFavourite,
  deleteFavourite,
  //resetSharePostStatusToDefault,
  updateViewCount,
  resetFavouriteStatusToDefault,
})(Attraction);
