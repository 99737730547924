import React, { useEffect, useState } from "react";
import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { translate } from "../../../../i18n";
import {
  ARTICLE_SK,
  ASYNC_STORAGE_KEYS,
  ATTRACTION_SK,
  SAVE_SK,
  UNSAVE_SK,
} from "../../../../constants";
import { ALL_MODE, LIST_MODE } from "../constants";
import { api, Status } from "../../../../api";
import { IconButton } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import styles from "./MediaView.module.scss";
import { RatingStar } from "../../../../common";
import { connect } from "react-redux";
import { updateHomePageData } from '../../../../store/actions';

const loggedInUserIdentifier = localStorage.getItem(
  ASYNC_STORAGE_KEYS.userIdentifier
);

const MediaView = ({
  mode,
  identifier,
  type,
  isSaved,
  favouriteIdentifier,
  url,
  title,
  rating,
  totalViews,
  // totalShares,
  reviewCount,
  openDetails: _openDetails,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
  updateHomePageData: _updateHomePageData,
}) => {
  const pathname = window.location.pathname.substring();
  const [_isSaved, setIsSaved] = useState(isSaved);
  const [_favouriteIdentifier, setFavouriteIdentifier] = useState(favouriteIdentifier);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    setIsSaved(isSaved);
    setFavouriteIdentifier(favouriteIdentifier);
  }, [isSaved, favouriteIdentifier]);

  const handleOnSave = () => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      setIsSaved(_isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK);

      if (_isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: identifier,
            entityType: type,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse: { favouriteIdentifier } }) => {
            setApiStatus(Status.SUCCESS);
            favSaveSuccessCallback();
            setIsSaved(UNSAVE_SK);
            setFavouriteIdentifier(favouriteIdentifier);
            _updateHomePageData({ favouriteIdentifier, identifier });
          })
          .catch((error) => {
            setApiStatus(Status.ERROR);
          });
      } else {
        api
          .deleteFavourite({
            favouriteIdentifier: _favouriteIdentifier,
          })
          .then(() => {
            setApiStatus(Status.SUCCESS);
            favUnSaveSuccessCallback();
            setIsSaved(SAVE_SK);
            setFavouriteIdentifier("");
            _updateHomePageData({ favouriteIdentifier: "", identifier });
          })
          .catch((error) => {
            setApiStatus(Status.ERROR);
          });
      }
    }
  };

  if (mode === ALL_MODE) {
    return (
      <Box id={styles["all-media-root"]}>
        <Card elevation={5}>
          <CardActionArea onClick={_openDetails}>
            <Box id={styles["inner-box"]}>
              <Box id={styles["title-box"]}>
                <Typography id={styles["title"]}>{title}</Typography>
              </Box>
              <img alt="img" src={url} className={styles["media-view-img"]} />
            </Box>
          </CardActionArea>
        </Card>
      </Box>
    );
  }

  return (
    <Box id={styles["filtered-result-rootbox"]}>
      {mode === LIST_MODE && (
        <IconButton id={styles["listing-save-icon"]} onClick={handleOnSave}>
          {_isSaved === SAVE_SK ? (
            <BookmarkBorderIcon className={styles["save-icon-style"]} />
          ) : (
            <BookmarkIcon className={styles["save-icon-style"]} />
          )}
        </IconButton>
      )}
      <CardActionArea onClick={_openDetails}>
        <Box id={styles["inner-box-lg"]}>
          <Box id={styles["title-box"]}>
            <Box id={styles["inner-title-Box"]}>
              <RatingStar rating={rating} size="small" />
              {(type === ARTICLE_SK || type === ATTRACTION_SK) && pathname === "/search" && (
                <Typography id={styles["filtered-result-media-info"]}>
                  {`${totalViews + "+ "}${totalViews > 1 ? translate("common.views") : translate("common.view")}`}
                </Typography>
              )}
              {(type === ARTICLE_SK || type === ATTRACTION_SK) && pathname !== "/search" && (
                <Typography id={styles["filtered-result-media-info"]}>
                  {`${totalViews} ${totalViews > 1 ? translate("common.views") : translate("common.view")}`}
                </Typography>
              )}
            </Box>
          </Box>
          <img alt="img" src={url} className={styles["media-view-img"]} />
        </Box>
      </CardActionArea>
    </Box>
  );
};

export default connect(null, {
  updateHomePageData,
})(MediaView);
