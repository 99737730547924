import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { api, Status } from '../../api';
import { AppBarWithTools, EndMessage, Spinner } from '../../common';
import {
  Box,
  Card,
  CircularProgress,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../i18n';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchListItem } from '../SearchResult/components';
import { LIST_MODE } from '../SearchResult/components/constants';
import { ARTICLE_SK } from '../../constants';
import { RatingButtonGroup } from './components';
import {
  //attractionType, 
  isNonEmptyString
} from '../../utils';
import styles from './AdvancedSearch.module.scss';
import Snack from '../../common/Snack/Snack';
import { isEmpty } from 'lodash';

const ArticleList = ({
  /**
   * Logged in user key
   *
   * @source redux
   */
  loggedInUserIdentifier,
  /**
   * Base url need to append with image url to generate full url
   *
   * @source redux
   */
  baseMediaUrl,
  /**
   * Base url pefixed to filePath to generate
   * complete url for thumbnail version of image
   *
   * @source redux
   */
  baseMediaThumbUrl,
  history,
}) => {
  const tagline = new URLSearchParams(window.location.search).get('tagline');
  const attId = new URLSearchParams(window.location.search).get('id');
  const _attId = atob(attId);
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const [page, setPage] = useState(1);
  const [state, setState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      list: [],
      apiStatus: Status.DEFAULT,
      moreApiStatus: Status.DEFAULT,
      contentAvailableForRefresh: true,
    }
  );
  const [loading, setLoading] = useState(true);
  const isLoggedIn = isNonEmptyString(loggedInUserIdentifier);
  const [snackSaveOpen, setSnackSaveOpen] = useState(false);
  const [snackUnsaveOpen, setSnackUnsaveOpen] = useState(false);

  useEffect(() => {
    if (page !== 1) {
      fetchList(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchList();
    setPage(1);
  }, [rating]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.apiStatus === Status.SUCCESS || state.apiStatus === Status.ERROR) {
      setLoading(false);
    }
  }, [state.apiStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchList = (_page = 1) => {
    const userRequest = {
      page: _page,
    };
    if (rating !== 0) {
      userRequest.rating = rating.toString();
    }
    if (tagline) {
      userRequest.articleTagline = tagline;
    }

    if (attId !== null) {
      userRequest.attractionIdentifier = _attId;
    }
    if (_page === 1) {
      setState({
        apiStatus: Status.LOADING,
      });
    } else {
      setState({
        moreApiStatus: Status.LOADING,
      });
    }
    api[tagline ? 'fetchArticlesByTagline' : 'fetchArticles'](userRequest)
      .then(({ userResponse: articleList }) => {
        if (_page === 1) {
          setState({
            list: articleList,
            contentAvailableForRefresh: !(articleList.length < 10),
            apiStatus: Status.SUCCESS,
          });
        } else {
          setState({
            list: [...state.list, ...articleList],
            contentAvailableForRefresh: !(articleList.length < 10),
            moreApiStatus: Status.SUCCESS,
          });
        }
      })
      .catch(() => {
        if (_page === 1) {
          setState({
            apiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        } else {
          setState({
            moreApiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        }
      });
  };

  const loadMore = () => setPage((prevState) => prevState + 1);

  const handleRatingChange = (rating) => {
    setRating(rating);
  };

  const handleSnackSaveClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackSaveOpen(false);
  };

  const handleSnackUnsaveClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackUnsaveOpen(false);
  };

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Spinner />;
  }

  if (state.apiStatus === Status.ERROR) {
    return <Redirect to="/404" />
  }

  const renderFilterCard = () => (
    <Card className={classes.card}>
      <RatingButtonGroup rating={rating} handleChange={handleRatingChange} />
    </Card>
  );

  const renderList = () => (
    <Grid container direction="column" spacing={2}>
      {state.list.map((item, key) => (
        <Grid item xs key={key}>
          <SearchListItem
            mode={LIST_MODE}
            type={ARTICLE_SK}
            item={item}
            isLoggedIn={isLoggedIn}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            history={history}
            favSaveSuccessCallback={() => setSnackSaveOpen(true)}
            favUnSaveSuccessCallback={() => setSnackUnsaveOpen(true)}
          />
        </Grid>
      ))}
    </Grid>
  );

  const loader = (
    <div className={styles['spinner']}>
      <CircularProgress size={22} className="loading_spinner" />
    </div>
  );

  const endMessage = (
    <>
      {
        !isEmpty(state.list) ? <EndMessage /> : <div className={styles['nomore-data-info']} style={{ paddingTop: '15px' }}>
          {translate('advancedSearch.noArticlesAvailable')}
        </div>
      }
    </>
  );

  const renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          {renderFilterCard()}
        </Grid>
        <Grid item xs={12} md={9} lg={9}>
          <InfiniteScroll
            dataLength={state.list.length} //This is important field to render the next data
            next={
              state.contentAvailableForRefresh &&
              state.apiStatus !== Status.LOADING &&
              state.moreApiStatus !== Status.LOADING &&
              loadMore
            }
            hasMore={state.contentAvailableForRefresh}
            loader={loader}
            endMessage={endMessage}
          >
            <Box>{renderList()}</Box>
          </InfiniteScroll>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CssBaseline />
      <div id="back-to-top-anchor" />
      <AppBarWithTools />
      <Grid container id={styles['list-root']}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography id={styles['listing-title']}>
            {tagline
              ? `${translate('advancedSearch.articlesFor').replace(
                '!@#$%',
                translate(
                  `common.${tagline.replace(/\s/g, '').toLocaleLowerCase()}`
                )
              )} `
              : attId ? translate('attractionDetails.relatedArticles') : translate('homeScreen.popularArticles')}
          </Typography>
          {renderBody()}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Snack
        open={snackSaveOpen}
        onClose={handleSnackSaveClose}
        message={translate('homeScreen.saveFavouriteSuccessMessage')}
      />
      <Snack
        open={snackUnsaveOpen}
        onClose={handleSnackUnsaveClose}
        message={translate('homeScreen.unsaveFavouriteSuccessMessage')}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    paddingTop: '80px',
    background: '#E5E5E5',
  },
  card: {
    padding: '16px',
    position: 'sticky !important',
    top: '80px',
  },
}));

const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { userIdentifier: loggedInUserIdentifier } = profile;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    loggedInUserIdentifier,
  };
};

export default connect(mapStateToProps, null)(withRouter(ArticleList));
