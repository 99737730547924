import { Status } from "../../api";
import {
  FETCH_BANNER_IMAGES_LOADING,
  FETCH_BANNER_IMAGES_SUCCESS,
  FETCH_BANNER_IMAGES_ERROR,
  FETCH_HOME_PAGE_DATA_LOADING,
  FETCH_HOME_PAGE_DATA_SUCCESS,
  FETCH_HOME_PAGE_DATA_ERROR,
  FETCH_MORE_HOME_PAGE_DATA_LOADING,
  FETCH_MORE_HOME_PAGE_DATA_SUCCESS,
  FETCH_MORE_HOME_PAGE_DATA_ERROR,
  SHARE_POST_SUCCESS,
  UPDATE_USER_FOLLOW_STATUS_SUCCESS,
  DELETE_HOME_POST_SUCCESS,
  DELETE_HOME_POST_REQUEST,
  SHARE_POST_REQUEST,
  REPORT_POST_SUCCESS,
  REPORT_POST_REQUEST,
  SAVE_FAVOURITE_PLI_SUCCESS,
  DELETE_FAVOURITE_PLI_SUCCESS,
  UPDATE_SHARE_COUNT_SUCCESS,
  RESET_SHARE_POST_STATUS,
  RESET_DELETE_POST_STATUS,
  RESET_REPORT_POST_STATUS,
  ADD_POST_SUCCESS,
  EDIT_POST,
  RESET_EDIT_POST_STATUS,
  UPDATE_REACTION_SUCCESS,
  UPDATE_COMMENT_COUNT_SUCCESS,
  UPDATE_VIEW_COUNT_SUCCESS,
  RESET_HOME_PAGE_DATA_STATUS,
  RESET_FAVOURITE_STATUS,
  SAVE_FAVOURITE_PLI_LOADING,
  SAVE_FAVOURITE_PLI_ERROR,
  DELETE_FAVOURITE_PLI_LOADING,
  DELETE_FAVOURITE_PLI_ERROR,
  NOTIFICATION_RECEIVED,
  RESET_REMOTE_NOTIFICATION
} from "../../constants";
import { translate } from "../../i18n";

const initialState = {
  isCacheDirty: false,
  bannerImage: {},
  /**
   * Correspond to banner images, loaded at app start
   */
  bannerImagesStatus: Status.DEFAULT,
  bannerImagesErrorMessage: "",
  // posts contain three type of entities => "post", "article", "attraction"
  posts: [],
  /**
   * Sometimes duplicate data comes in posts,
   * so to remove it we are filtering out at our end
   */
  postsIdentifiersMap: {},
  firstItemId: null,
  recommendations: [],

  randomNumber: 0,

  articlesRandomNumber: 0, // Index at which popular articles list will render in Home list
  recommendationsRandomNumber: 0, // Index at which recommendations list will render in Home list
  hotelsRandomNumber: 0, // Index at which hotels list will render in Home list
  restaurantsRandomNumber: 0, // Index at which restaurants list will render in Home list

  contentAvailableForRefresh: true,
  /**
   * Correspond to first set of post, recommendation,
   * loaded at app start
   */
  homePageDataStatus: Status.DEFAULT,
  homePageDataErrorMessage: "",
  /**
   * Correspond to loading more posts(& only post),
   * as user is scrolling the post list
   */
  moreHomePageDataStatus: Status.DEFAULT,
  moreHomePageDataErrorMessage: "",

  sharePostStatus: Status.DEFAULT,
  sharedPostIdentifier: "",
  sharedPost: {},

  deletePostStatus: Status.DEFAULT,
  deletedPostIdentifier: "",

  reportPostStatus: Status.DEFAULT,

  favouriteStatus: Status.DEFAULT,
  entityIdentifier: "",
  favouriteIdentifier: "",
  favouriteErrorMessage: "",

  editPostStatus: Status.DEFAULT,
  editedPost: {},

  remoteNotificationReceived: false,
  newNotiCount: 0,
  successMessage: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BANNER_IMAGES_LOADING:
      return {
        ...state,
        bannerImagesStatus: Status.LOADING,
        bannerImagesErrorMessage: "",
      };
    case FETCH_BANNER_IMAGES_SUCCESS:
      const { bannerImage } = payload;
      return {
        ...state,
        bannerImagesStatus: Status.SUCCESS,
        bannerImagesErrorMessage: "",
        bannerImage: bannerImage,
      };
    case FETCH_BANNER_IMAGES_ERROR:
      return {
        ...state,
        bannerImagesStatus: Status.ERROR,
        bannerImagesErrorMessage: payload.errorMessage,
      };
    case FETCH_HOME_PAGE_DATA_LOADING:
      return {
        ...state,
        homePageDataStatus: Status.LOADING,
        homePageDataErrorMessage: "",
      };
    case FETCH_HOME_PAGE_DATA_SUCCESS: {
      const { posts, postsIdentifiersMap } = payload;

      return {
        ...state,
        homePageDataStatus: Status.SUCCESS,
        homePageDataErrorMessage: "",
        posts,
        postsIdentifiersMap,
        contentAvailableForRefresh: posts.length > 0,
        isCacheDirty: false,
      };
    }
    case FETCH_HOME_PAGE_DATA_ERROR:
      return {
        ...state,
        homePageDataStatus: Status.ERROR,
        homePageDataErrorMessage: payload.errorMessage,
        contentAvailableForRefresh: false,
      };
    case FETCH_MORE_HOME_PAGE_DATA_LOADING:
      return {
        ...state,
        moreHomePageDataStatus: Status.LOADING,
        moreHomePageDataErrorMessage: "",
      };
    case FETCH_MORE_HOME_PAGE_DATA_SUCCESS: {
      const { posts, postsIdentifiersMap } = payload;

      const allPosts = [...state.posts, ...posts];

      return {
        ...state,
        moreHomePageDataStatus: Status.SUCCESS,
        moreHomePageDataErrorMessage: "",
        contentAvailableForRefresh: posts.length > 0,
        posts: allPosts,
        postsIdentifiersMap: {
          ...state.postsIdentifiersMap,
          ...postsIdentifiersMap,
        },
      };
    }
    case FETCH_MORE_HOME_PAGE_DATA_ERROR:
      return {
        ...state,
        moreHomePageDataStatus: Status.ERROR,
        moreHomePageDataErrorMessage: payload.errorMessage,
        contentAvailableForRefresh: false,
      };
    case SHARE_POST_REQUEST:
      return {
        ...state,
        sharePostStatus: Status.LOADING,
      };
    case SHARE_POST_SUCCESS:
      return {
        ...state,
        sharePostStatus: Status.SUCCESS,
        sharedPostIdentifier: payload.sharedPost.parentDetails.identifier,
        posts: [payload.sharedPost, ...state.posts],
        postsIdentifiersMap: {
          ...state.postsIdentifiersMap,
          [payload.sharedPost.identifier]: true,
        },
        sharedPost: payload.sharedPost,
      };
    case UPDATE_USER_FOLLOW_STATUS_SUCCESS: {
      const { posts } = payload;

      return {
        ...state,
        posts,
      };
    }
    case DELETE_HOME_POST_REQUEST: {
      return {
        ...state,
        deletePostStatus: Status.LOADING,
      };
    }
    case DELETE_HOME_POST_SUCCESS: {
      const { posts, deletedPostIdentifier } = payload;
      return {
        ...state,
        deletePostStatus: Status.SUCCESS,
        deletedPostIdentifier,
        posts,
      };
    }
    case REPORT_POST_REQUEST: {
      return {
        ...state,
        reportPostStatus: Status.LOADING,
      };
    }
    case REPORT_POST_SUCCESS: {
      return {
        ...state,
        reportPostStatus: Status.SUCCESS,
      };
    }
    case SAVE_FAVOURITE_PLI_LOADING: {
      return {
        ...state,
        favouriteStatus: Status.LOADING,
        favouriteErrorMessage: "",
      };
    }
    case SAVE_FAVOURITE_PLI_SUCCESS: {
      const { posts, entityIdentifier, favouriteIdentifier } = payload;
      return {
        ...state,
        favouriteStatus: Status.SUCCESS,
        posts,
        entityIdentifier,
        favouriteIdentifier,
        favouriteErrorMessage: "",
        successMessage: translate("homeScreen.saveFavouriteSuccessMessage")
      };
    }
    case SAVE_FAVOURITE_PLI_ERROR: {
      return {
        ...state,
        favouriteStatus: Status.ERROR,
        favouriteErrorMessage: payload.errorMessage,
      };
    }
    case DELETE_FAVOURITE_PLI_LOADING: {
      return {
        ...state,
        favouriteStatus: Status.LOADING,
      };
    }
    case DELETE_FAVOURITE_PLI_SUCCESS: {
      const { posts, recommendations, entityIdentifier, favouriteIdentifier } =
        payload;
      return {
        ...state,
        favouriteStatus: Status.SUCCESS,
        posts,
        recommendations,
        entityIdentifier,
        favouriteIdentifier,
        successMessage: translate("homeScreen.unsaveFavouriteSuccessMessage")
      };
    }
    case DELETE_FAVOURITE_PLI_ERROR: {
      return {
        ...state,
        favouriteStatus: Status.ERROR,
        favouriteErrorMessage: payload.errorMessage,
      }
    }
    case UPDATE_SHARE_COUNT_SUCCESS: {
      return {
        ...state,
        posts: payload.posts,
      };
    }
    case RESET_SHARE_POST_STATUS: {
      return {
        ...state,
        sharePostStatus: Status.DEFAULT,
      };
    }
    case RESET_DELETE_POST_STATUS: {
      return {
        ...state,
        deletePostStatus: Status.DEFAULT,
      };
    }
    case RESET_REPORT_POST_STATUS: {
      return {
        ...state,
        reportPostStatus: Status.DEFAULT,
      };
    }
    case ADD_POST_SUCCESS:
      return {
        ...state,
        posts: [payload.newPost, ...state.posts],
        postsIdentifiersMap: {
          ...state.postsIdentifiersMap,
          [payload.newPost.identifier]: true,
        },
      };
    case EDIT_POST: {
      const { identifier, visibility, description } = payload;
      let posts = state.posts.map((post) => {
        return {
          ...post,
          visibility:
            post.identifier === identifier ? visibility : post.visibility,
          description:
            post.identifier === identifier ? description : post.description,
        };
      });
      return {
        ...state,
        posts,
        editPostStatus: Status.SUCCESS,
        editedPost: payload,
      };
    }
    case RESET_EDIT_POST_STATUS:
      return {
        ...state,
        editPostStatus: Status.DEFAULT,
        editedPost: {},
      };
    case UPDATE_REACTION_SUCCESS: {
      const { posts } = payload;
      return {
        ...state,
        posts,
      };
    }
    case UPDATE_COMMENT_COUNT_SUCCESS: {
      const { posts } = payload;
      return {
        ...state,
        posts,
      };
    }
    case UPDATE_VIEW_COUNT_SUCCESS: {
      const { posts } = payload;
      return {
        ...state,
        posts,
      };
    }
    case RESET_HOME_PAGE_DATA_STATUS: {
      return {
        ...state,
        homePageDataStatus: Status.DEFAULT
      }
    }
    case RESET_FAVOURITE_STATUS: {
      return {
        ...state,
        favouriteStatus: Status.DEFAULT
      }
    }
    case NOTIFICATION_RECEIVED: {
      return {
        ...state,
        remoteNotificationReceived: true,
        newNotiCount: state.newNotiCount + 1,
      }
    }
    case RESET_REMOTE_NOTIFICATION: {
      return {
        ...state,
        remoteNotificationReceived: false,
        newNotiCount: 0,
      }
    }
    default:
      return state;
  }
};
