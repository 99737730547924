import { shape, oneOf } from 'prop-types';
import {
  FOLLOWING_SK,
  FRIEND_SK,
  NO_CONNECTION_SK,
  PENDING_SK,
} from '../../constants/stringConstants';

/**
 * Defines prop-types definition for a user relation with current user
 */
const userRelationType = shape({
  followStatus: oneOf([FOLLOWING_SK, NO_CONNECTION_SK]),
  relationStatus: oneOf([FRIEND_SK, PENDING_SK, NO_CONNECTION_SK]),
});

export default userRelationType;
