import React, { useState } from "react";
import { connect } from "react-redux";
import { resetSignupState } from "../../../store/actions";
import { withRouter } from "react-router";
import { translate } from "../../../i18n";
import Logo from "../../../assets/images/logo.png";
import LogoGreen from "../../../assets/images/smoove-green.png";
import styles from "../Authentication.module.scss";
import cn from "classnames";

const SigninInstruction = (props) => {
  const {
    toggleSignupMode,
    signupMode,
    resetSignupState,
    history: _history,
  } = props;

  const [logoDelayTime, setLogoDelayTime] = useState(false);
  const signinBtnclick = () => {
    toggleSignupMode(true);
    resetSignupState();
  };

  setTimeout(() => {
    setLogoDelayTime(signupMode);
  }, 600);

  return (
    <div>
      <div className={styles.logo} onClick={() => _history.push("/")}>
        {logoDelayTime ? (
          <img alt="logo" src={LogoGreen} />
        ) : (
          <img alt="logo" src={Logo} />
        )}
      </div>
      <div className={styles["panel-inside"]}>
        <div className={cn(styles["panel-center"], styles.content)}>
          <div className={styles["signin-instruction-panel-title"]}>
            {translate("common.welcome")}
          </div>
          <div className={styles["panel-sub-title"]}>
            {translate("signupScreen.alreadyHaveAccount")}
          </div>

          <button
            className={cn(styles.btn, styles.transparent)}
            id={styles["sign-up-btn"]}
            onClick={signinBtnclick}
          >
            {translate("common.login")}
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ auth }) => {
  const { isSignupClear } = auth;

  return {
    isSignupClear,
  };
};

export default connect(mapStateToProps, {
  resetSignupState,
})(withRouter(SigninInstruction));
