import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button as MuiButton, CircularProgress, styled } from '@mui/material';
import { api, Status } from '../../../../api';
import {
  FOLLOWING_SK,
  FRIEND_INVITE_DELETE_SK,
  FRIEND_REQUEST_PENDING_SK,
  NO_CONNECTION_SK,
  UNFOLLOWED_SK,
} from '../../../../constants';
import {
  fetchUserNetwork,
  updateUserRelationStatus,
  updateUserFollowStatus,
} from '../../../../store/actions';
import ItemCard from './components/ItemCard';
import { translate } from '../../../../i18n';

const ButtonOutlined = styled(MuiButton)(({ theme }) => ({
  width: '10vw',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  border: '1px solid #FFB703',
  color: '#FFB703',
  textTransform: 'Capitalize',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  '&:hover': {
    backgroundColor: 'transparent',
    border: '1px solid #FFB703',
  },
}));

const InvitesTab = ({
  /**
   * Array which contain invites.
   *
   * @source redux
   */
  invites,
  /**
   * Tells about the status of the fetch user network api call, when pagination = 1
   *
   * @source redux
   */
  invitesStatus,
  /**
   * Tells about the status of the fetch user network api call, when pagination > 1
   *
   * @source redux
   */
  moreInvitesStatus,
  /**
   * check if more invites data is available to refresh
   */
  invitesAvailableForRefresh,
  /**
   * Base url need to append with image url to generate full url
   * @source redux
   */
  baseMediaUrl,
  baseMediaThumbUrl,
  /**
   * Tells about the status of the update member network api call
   *
   * @source redux
   */
  updateMemberNetworkStatus,
  /**
   * redux action to initiate fetch user network api request
   *
   * @source: redux
   */
  fetchUserNetwork: _fetchUserNetwork,
  updateUserRelationStatus: _updateUserRelationStatus,
  updateUserFollowStatus: _updateUserFollowStatus,
  updateFollowingCount: _updateFollowingCount,
  showSnack: _showSnack,
  reload: _reload,
}) => {
  const [page, setPage] = useState(1);
  const [noInvitesAvailable, setNoInvitesAvailable] = useState(false);

  // call method to initiate api call on pagination.
  useEffect(() => {
    _fetchUserNetwork(FRIEND_REQUEST_PENDING_SK, page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      invitesStatus === Status.SUCCESS ||
      moreInvitesStatus === Status.SUCCESS ||
      updateMemberNetworkStatus === Status.SUCCESS
    ) {
      setNoInvitesAvailable(invites.length === 0);
    }
  }, [invitesStatus, moreInvitesStatus, updateMemberNetworkStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // call api to update request status (confirm or delete)
  const updateRelationship = (networkStatus, user) => {
    api
      .updateMemberNetwork({
        status: networkStatus,
        userIdentifier: user.identifier,
      })
      .then(({ userResponse: { status } }) => {
        _showSnack(status);
        _updateUserRelationStatus(networkStatus, user);
        if (user.networkStatusWithCurrentUser.followStatus === FOLLOWING_SK) {
          _updateFollowingCount(UNFOLLOWED_SK);
          _updateUserFollowStatus(NO_CONNECTION_SK, user.identifier);
        }
      })
      .catch(() => {});
  };

  const loader = (
    <div className="spinner">
      <CircularProgress size={22} className="loading_spinner" />
    </div>
  );

  const endMessage = (
    <div className="nomore-data-info">
      {noInvitesAvailable ? (
        <span>{translate('userProfile.noInvitesFound')}</span>
      ) : (
        <span></span>
      )}
    </div>
  );

  const action = (user) => {
    return (
      <ButtonOutlined
        id="action-button"
        variant="contained"
        size="small"
        onClick={() => updateRelationship(FRIEND_INVITE_DELETE_SK, user)}
      >
        {translate('common.cancel')}
      </ButtonOutlined>
    );
  };

  return (
    <div className="friend-requests-container">
      <InfiniteScroll
        dataLength={invites.length}
        next={() => setPage((prevState) => prevState + 1)}
        hasMore={invitesAvailableForRefresh}
        loader={loader}
        endMessage={endMessage}
      >
        {invites.map((user, key) => (
          <ItemCard
            key={key}
            item={user}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            action={action(user)}
            reload={_reload}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

// mapping states to props of the component.
const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const {
    invites,
    invitesStatus,
    moreInvitesStatus,
    invitesAvailableForRefresh,
    updateMemberNetworkStatus,
  } = profile;
  return {
    invites,
    invitesStatus,
    moreInvitesStatus,
    invitesAvailableForRefresh,
    baseMediaUrl,
    baseMediaThumbUrl,
    updateMemberNetworkStatus,
  };
};

// export the component.
export default connect(mapStateToProps, {
  fetchUserNetwork,
  updateUserRelationStatus,
  updateUserFollowStatus,
})(InvitesTab);
