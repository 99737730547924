/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ATTRACTION_SK } from '../../../../constants';
import { translate } from '../../../../i18n';
import MediaView from '../ListItemMediaView/MediaView';
import { ALL_MODE } from '../constants';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { GoMail } from 'react-icons/go';
import { BsClock } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import styles from './SearchListItem.module.scss';

const AttractionListItem = ({
  mode,
  item,
  isLoggedIn,
  baseMediaUrl,
  baseMediaThumbUrl,
  showLoginPrompt: _showLoginPrompt,
  history,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
  isSearchResult
}) => {
  const {
    identifier,
    coverImg,
    title,
    isSaved,
    favouriteIdentifier,
    avgRating,
    totalReviews,
    shortDescription,
    city,
    state,
    openDays,
    openDurations,
    email,
    webSiteUrl,
    totalViews,
  } = item;

  const coverImgUrl = coverImg?.isThumbGenerated
    ? coverImg?.url?.thumbUrl
    : coverImg?.url?.originalUrl;

  const openDetails = () => {
    if (!isLoggedIn) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${btoa(identifier)}/${ATTRACTION_SK}`);
  };

  if (mode === ALL_MODE) {
    return (
      <MediaView
        mode={mode}
        identifier={identifier}
        type={ATTRACTION_SK}
        isSaved={isSaved}
        favouriteIdentifier={favouriteIdentifier}
        url={coverImgUrl}
        title={title}
        rating={avgRating}
        reviewCount={totalReviews}
        openDetails={openDetails}
        totalViews={totalViews}
      />
    );
  }
  const renderDetails = () => (
    <CardContent id={styles['filtered-result-details']} onClick={openDetails}>
      <Box>
        <Typography id={styles['filtered-result-title']}>{title}</Typography>
        {openDays && (
          <div className={styles["filtered-items"]}>
            <div className={styles["at-icon"]}> <BsClock id={styles['attraction-icon-c']} /></div>
            <Typography id={styles['filtered-result-info']}>
              {`${openDays} ${openDurations ? openDurations : ''}`}
            </Typography>
          </div>
        )}
        <div className={styles["filtered-items"]}>
          <div className={styles["at-icon"]}> <GoLocation id={styles['attraction-icon-c']} /></div>
          <Typography id={styles['filtered-result-info']}>
            {`${city.replace(/^\s+|\s+$/gm, '')}${isSearchResult ? "," : translate(
              'common.comma'
            )} ${state}`}
          </Typography>
        </div>
        {email && (
          <div className={styles["filtered-items"]}>
            <div className={styles["at-icon"]}><GoMail id={styles['attraction-icon-c']} /></div>
            <Typography id={styles['filtered-result-info']}>{email}</Typography>
          </div>
        )}
        {webSiteUrl && (
          <div className={styles["filtered-items"]}>
            <div className={styles["at-icon"]}> <LanguageOutlinedIcon id={styles['attraction-icon-c']} /></div>
            <Typography id={styles['filtered-result-info']}>
              {webSiteUrl}
            </Typography>
          </div>
        )}
      </Box>
      <Box id={styles['description-box']}>
        <Typography id={styles['filtered-result-description']}>
          &#8220; {`${shortDescription}`} &#8221;
          <span className={styles['read-more']}>
            <span className="text-blue">
              ...{translate('common.readMore')}{' '}
            </span>
          </span>
        </Typography>
      </Box>
    </CardContent>
  );

  return (
    <Card id={styles['filtered-result-root']}>
      <CardContent>
        <MediaView
          mode={mode}
          identifier={identifier}
          type={ATTRACTION_SK}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          url={coverImgUrl}
          title={title}
          rating={avgRating}
          reviewCount={totalReviews}
          openDetails={openDetails}
          totalViews={totalViews}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
        />
      </CardContent>
      {renderDetails()}
    </Card>
  );
};

export default AttractionListItem;
