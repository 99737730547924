import { takeLatest, call, put, select } from 'redux-saga/effects';
import { api } from '../../api';
import {
  FETCH_ATTRACTION_DETAILS_REQUEST,
  FETCH_ATTRACTION_DETAILS_LOADING,
  FETCH_ATTRACTION_DETAILS_SUCCESS,
  FETCH_ATTRACTION_DETAILS_ERROR,
  FETCH_REVIEWS_REQUEST,
  FETCH_REVIEWS_LOADING,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_ERROR,
  MORE_FETCH_REVIEWS_LOADING,
  MORE_FETCH_REVIEWS_SUCCESS,
  MORE_FETCH_REVIEWS_ERROR,
  FETCH_ARTICLES_BY_ATTRACTION_LOADING,
  FETCH_ARTICLES_BY_ATTRACTION_SUCCESS,
  FETCH_ARTICLES_BY_ATTRACTION_ERROR,
  FETCH_ARTICLES_BY_ATTRACTION_REQUEST,
  FETCH_All_ATTRACTION_FILES_REQUEST,
  FETCH_All_ATTRACTION_FILES_LOADING,
  FETCH_All_ATTRACTION_FILES_SUCCESS,
  FETCH_All_ATTRACTION_FILES_ERROR,
  SHARE_POST_REQUEST,
  SHARE_POST_LOADING,
  SHARE_POST_SUCCESS,
  SHARE_POST_ERROR,
  SAVE_FAVOURITE_REQUEST,
  SAVE_FAVOURITE_LOADING,
  SAVE_FAVOURITE_SUCCESS,
  SAVE_FAVOURITE_ERROR,
  DELETE_FAVOURITE_REQUEST,
  DELETE_FAVOURITE_LOADING,
  DELETE_FAVOURITE_SUCCESS,
  DELETE_FAVOURITE_ERROR,
  SAVE_SK,
  UNSAVE_SK
} from '../../constants';

const FIRST_PAGE = 1;

/**,
 * worker saga: Calls the fetch attraction List API.
 *
 * @param {Object} action                   - action object dispatched by user
 * @param {string} action.payload.attractionIdentifier    - oid of attraction

 */
function* fetchAttractionDetails({ payload: attractionIdentifier }) {
  try {
    yield put({
      type: FETCH_ATTRACTION_DETAILS_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchAttractionDetails },
      attractionIdentifier ,
    );


    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: FETCH_ATTRACTION_DETAILS_SUCCESS,
      payload: userResponse
    });
  } catch (error) {
    yield put({
      type: FETCH_ATTRACTION_DETAILS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}


/**
 * worker saga: Calls the user reviews api
 *
 * @param {Object} action                   - action object dispatched by user
 * @param {string} action.payload.sortOrder - Sort order can be ascending or descending
 * @param {number} action.payload.page      - Page number of the review to be fetched
 * @param {number} action.payload.fromDate  - start date for range fetch
 * @param {number} action.payload.toDate    - end date for range fetch
 */
function* fetchReviews({ payload: { type, identifier, page, sortOrder, fromDate, toDate } }) {
  try {
    const recentMode = fromDate === '' && toDate === '';
    yield put({
      type:
        page === FIRST_PAGE ? FETCH_REVIEWS_LOADING : MORE_FETCH_REVIEWS_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchReviews },
      { type, identifier, page, sortOrder, fromDate, toDate },
    );
    // parse the data from response
    const {
      userResponse: { reviewList: reviews },
    } = response;
    // send parsed data into action payload
    yield put({
      type:
        page === FIRST_PAGE ? FETCH_REVIEWS_SUCCESS : MORE_FETCH_REVIEWS_SUCCESS,
      payload: {
        reviews,
        recentMode,
      },
    });
  } catch (error) {
    yield put({
      type: page === FIRST_PAGE ? FETCH_REVIEWS_ERROR : MORE_FETCH_REVIEWS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch articles List by attraction API.
 *
 * @param {string} action.payload.attractionIdentifier    - oid of attraction

 */
function* fetchArticlesByAttraction({ payload: attractionIdentifier }) {
  try {
    yield put({
      type: FETCH_ARTICLES_BY_ATTRACTION_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchArticlesByAttraction },
      attractionIdentifier ,
    );


    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: FETCH_ARTICLES_BY_ATTRACTION_SUCCESS,
      payload: userResponse
    });
  } catch (error) {
    yield put({
      type: FETCH_ARTICLES_BY_ATTRACTION_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch all attraction files by attraction API.
 *
 * @param {string} action.payload.attractionIdentifier    - oid of attraction

 */
function* fetchAllAttractionFiles({ payload: attractionIdentifier }) {
  try {
    yield put({
      type: FETCH_All_ATTRACTION_FILES_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchAllAttractionFiles },
      attractionIdentifier ,
    );


    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: FETCH_All_ATTRACTION_FILES_SUCCESS,
      payload: userResponse
    });
  } catch (error) {
    yield put({
      type: FETCH_All_ATTRACTION_FILES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* worker saga: Calls the share attraction by attraction API.
*
* @param {string} action.payload.attractionIdentifier    - oid of attraction

*/
function* shareEntity({ payload: type, visibility, identifier, description }) {
  try {
    yield put({
      type: SHARE_POST_LOADING,
    });
    // api call
    yield call(
      { context: api, fn: api.shareEntity },
      type, visibility, identifier, description ,
    );

    // send parsed data into action payload
    yield put({
      type: SHARE_POST_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: SHARE_POST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

const getDataFromStore = ({ attraction }) => {
  const { attractionDetails } = attraction
  return {
    attractionDetails,

  };
};
/**
* save favourite
*/
function* saveFavourite({ payload: { entityIdentifier, entityType, userIdentifier } }) {

  try {
    yield put({
      type: SAVE_FAVOURITE_LOADING,
    });

    // api call
    const response = yield call(
      { context: api, fn: api.saveFavourite },
      { entityIdentifier, entityType, userIdentifier },
    );
    // parse the data from response
    const {
      userResponse: {
        favouriteIdentifier
      },
    } = response;
    // send parsed data into action payload
    let { attractionDetails } = yield select(getDataFromStore);
    if (attractionDetails.favouriteIdentifier !== favouriteIdentifier) {
      attractionDetails.favouriteIdentifier = favouriteIdentifier
      attractionDetails.isSaved = UNSAVE_SK
    }
    yield put({
      type: SAVE_FAVOURITE_SUCCESS,

      payload: {
        attractionDetails
      },
    });


  } catch (error) {
    yield put({
      type: SAVE_FAVOURITE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* delete favourite
*/
function* deleteFavourite({ payload: { favouriteIdentifier } }) {

  try {
    yield put({
      type: DELETE_FAVOURITE_LOADING,
    });

    // api call
    const response = yield call(
      { context: api, fn: api.deleteFavourite },
      { favouriteIdentifier },
    );
    // parse the data from response
    const {
      userResponse: {
        status
      },
    } = response;
    // send parsed data into action payload
    let { attractionDetails } = yield select(getDataFromStore);
    if (attractionDetails.favouriteIdentifier === favouriteIdentifier) {
      attractionDetails.favouriteIdentifier = favouriteIdentifier
      attractionDetails.isSaved = SAVE_SK
    }
    yield put({
      type: DELETE_FAVOURITE_SUCCESS,
      payload: {
        attractionDetails,
        status
      },
    });


  } catch (error) {
    yield put({
      type: DELETE_FAVOURITE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_ATTRACTION_DETAILS_REQUEST, fetchAttractionDetails);
  yield takeLatest(FETCH_REVIEWS_REQUEST, fetchReviews);
  yield takeLatest(FETCH_ARTICLES_BY_ATTRACTION_REQUEST, fetchArticlesByAttraction);
  yield takeLatest(FETCH_All_ATTRACTION_FILES_REQUEST, fetchAllAttractionFiles);
  yield takeLatest(SHARE_POST_REQUEST, shareEntity);
  yield takeLatest(SAVE_FAVOURITE_REQUEST, saveFavourite);
  yield takeLatest(DELETE_FAVOURITE_REQUEST, deleteFavourite);
}
