import { arrayOf, number, shape, string, oneOf } from 'prop-types';
import { RESTAURANT_SK } from '../../constants/stringConstants';
import userRelationType from './userRelationType';
import imageType from './ImageType';
import landmarkType from './landmarkType';

const restaurantDetailsType = shape({
  avgRating: string,
  businessCategory: oneOf([RESTAURANT_SK]),
  city: string,
  closingTime: string,
  country: string,
  coverImg: imageType,
  cuisines: string,
  description: string,
  district: string,
  email: string,
  foodMenu: arrayOf(string),
  identifier: string,
  landmarks: arrayOf(landmarkType),
  latitude: number,
  longitude: number,
  meals: arrayOf(string),
  name: string,
  mobileNumber: string,
  networkStatusWithCurrentUser: userRelationType,
  openingTime: string,
  profileImg: imageType,
  restoFeatures: arrayOf(string),
  specialDiets: string,
  state: string,
  streetAddress: string,
  tagLine: string,
  webSiteUrl: string,
});

export default restaurantDetailsType;
