import { takeLatest, call, put } from 'redux-saga/effects';
import { api } from '../../api';
import {
  FETCH_CONTRACTS_REQUEST,
  FETCH_CONTRACTS_LOADING,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_ERROR,
  FETCH_MORE_CONTRACTS_LOADING,
  FETCH_MORE_CONTRACTS_SUCCESS,
  FETCH_MORE_CONTRACTS_ERROR,
} from '../../constants';
import { shuffle, } from '../../utils';

const FIRST_PAGE = 1;

/**
 * worker saga: Calls the fetch business contracts List API.
 *
 * @param {Object} action                   - action object dispatched by user
 * @param {number} action.payload.page      - page number to be fetched
 */
function* fetchBusinessContracts({ payload: { page } }) {
  try {
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_CONTRACTS_LOADING
          : FETCH_MORE_CONTRACTS_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchBusinessContracts },
      { page },
    );
    // parse the data from response
    const {
      userResponse: {
        hotel =[],
        restaurant = [],
      },
    } = response;
    // send parsed data into action payload
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_CONTRACTS_SUCCESS
          : FETCH_MORE_CONTRACTS_SUCCESS,
      payload: {
        hotels: hotel.length > 0 ? shuffle(hotel) : [],
        restaurants: restaurant.length > 0 ? shuffle(restaurant) : [],
      },
    });
  } catch (error) {
    yield put({
      type:
        page === FIRST_PAGE ? FETCH_CONTRACTS_ERROR : FETCH_MORE_CONTRACTS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_CONTRACTS_REQUEST, fetchBusinessContracts);
}
