import React from "react";
import { formatRactionCountForDisplay } from "../../../../utils"

const ReactionCount = ({
    likesCount,
    allCount
}) => {
    const formattedCount = formatRactionCountForDisplay(likesCount);
    return (
        <>
            {allCount && "All "}{formattedCount}
        </>
    )
}

export default ReactionCount;