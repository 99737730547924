import React from "react";
import { Button } from "@mui/material";
import { AppBar } from "../../common";
import { translate } from "../../i18n";
import SomethingWrongImg from "../../assets/images/something_went_wrong.png";
import styles from "./SomethingWrong.module.scss";

const SomethingWrong = () => {

    const handleClick = () => {
        window.location.reload();
    };

    return (<>
        <AppBar />
        <div className={styles["container"]}>
            <div className={styles["content"]}>
                <img
                    className={styles["offline-img"]}
                    src={SomethingWrongImg}
                    alt="Connection Lost"
                />
                <div className={styles["text-box"]}>
                    <h2>{translate("common.generalError")}</h2>
                    <p>{translate("common.somethingWrongMsg")}</p>
                    <Button id={styles["btn"]} onClick={handleClick}>{translate("common.tryAgain")}</Button>
                </div>

            </div>

        </div>
    </>);
};

export default SomethingWrong;