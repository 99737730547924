import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { translate } from "../../../../../i18n";
import festivalImg from "../../../../../assets/images/festival.png";
import ecotourismImg from "../../../../../assets/images/ecotourism.png";
import cbtImg from "../../../../../assets/images/cbt.png";
import { isNonEmptyString } from "../../../../../utils";
import {
  ARTICLE_SK,
  CBT_SK,
  ECOTOURISM_SK,
  FESTIVAL_SK,
} from "../../../../../constants";
import { withRouter } from "react-router";
import styles from "./Highlights.module.scss";

const highlights = [
  {
    key: FESTIVAL_SK,
    label: translate("common.festival"),
    image: festivalImg,
  },
  {
    key: ECOTOURISM_SK,
    label: translate("common.ecotourism"),
    image: ecotourismImg,
  },
  {
    key: CBT_SK,
    label: translate("common.communitybasedtourism"),
    image: cbtImg,
  },
];

const Highlights = ({
  loggedInUserIdentifier,
  showLoginPrompt: _showLoginPrompt,
  history,
}) => {
  const classes = useStyles();

  const handleClick = (key) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`/list/${ARTICLE_SK}s?tagline=${key}`);
  };

  return (
    <div className={styles["highlights-container"]}>
      <div>
        <span className={styles["highlights-title"]}>
          {translate("common.highlights")}
        </span>
      </div>
      {highlights.map((item, index) => (
        <div
          className={styles["highlight"]}
          onClick={() => handleClick(item.key)}
          key={item.key}
        >
          <span className={styles["hightlight-label"]}>{item.label}</span>
          <img alt="img" src={item.image} className={classes.img} />
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
    zIndex: -1,
    aspectRatio: "170 / 89",
    borderRadius: "5px",
  },
  highlight: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default withRouter(Highlights);
