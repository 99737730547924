import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import BusinessMediaView from '../ListItemMediaView/BusinessMediaView';
import { translate } from '../../../../i18n';
import { LIMITS } from '../../../../constants';
import { ALL_MODE } from '../constants';
import styles from './SearchListItem.module.scss';
import { RatingStar } from '../../../../common';

const BusinessListItem = ({
  mode,
  type,
  item = {},
  isLoggedIn,
  showLoginPrompt: _showLoginPrompt,
  history,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
  isSearchResult
}) => {
  const LIMIT = LIMITS.POST_DESCRIPTION_MIN_LENGTH;

  const {
    identifier,
    name,
    isSaved,
    favouriteIdentifier,
    profileImg,
    avgRating,
    city,
    state,
    //totalReviews,
    tagLine,
    description,
  } = item;

  const profileImgUrl = profileImg?.isThumbGenerated
    ? profileImg?.url?.thumbUrl
    : profileImg?.url?.originalUrl;

  const openProfile = () => {
    if (!isLoggedIn) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${btoa(identifier)}/${type}`);
  };

  if (mode === ALL_MODE) {
    return (
      <BusinessMediaView
        mode={mode}
        identifier={identifier}
        name={name}
        isSaved={isSaved}
        favouriteIdentifier={favouriteIdentifier}
        url={profileImgUrl}
        rating={avgRating}
        openProfile={openProfile}
      />
    );
  }

  const renderDetails = () => (
    <CardContent id={styles['filtered-result-details']} onClick={openProfile}>
      <Box>
        <Typography id={styles['filtered-result-business-name']}>
          {name}
        </Typography>
        <Typography
          id={styles['filtered-result-business-city']}
          className="text-gray"
        >
          {`${city.replace(/^\s+|\s+$/gm, '')}${isSearchResult ? "," : translate('common.comma')} ${state}`}
        </Typography>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <RatingStar
            rating={avgRating}
            size="small"
          />

          {/* <Typography
            id={styles['filtered-result-business-review']}
            className="text-gray"
          >
            {`${totalReviews} ${
              totalReviews > 1
                ? translate('common.reviews')
                : translate('common.review')
            }`}
          </Typography> */}
        </Stack>
        {tagLine && tagLine !== 'NULL' && (
          <Typography
            id={styles['filtered-result-business-tagline']}
            className="text-bold"
          >
            {tagLine}
          </Typography>
        )}
      </Box>
      <Box id={styles['description-box']}>
        {description && description !== 'NULL' && (
          <Typography id={styles['filtered-result-business-description']}>
            {`${description?.slice(0, LIMIT)}`}
            <span className={styles['read-more']}>
              <span className="text-blue">
                ...{translate('common.readMore')}
              </span>
            </span>
          </Typography>
        )}
      </Box>
    </CardContent>
  );

  return (
    <Card id={styles['filtered-result-root']}>
      <CardContent>
        <BusinessMediaView
          mode={mode}
          identifier={identifier}
          name={name}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          url={profileImgUrl}
          rating={avgRating}
          openProfile={openProfile}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
        />
      </CardContent>
      {renderDetails()}
    </Card>
  );
};

export default BusinessListItem;
