import React from 'react';
import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material';

const Backdrop = ({ open }) => {
  return (
    <MuiBackdrop
      sx={{ color: '#3794EB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" className="loading_spinner" />
    </MuiBackdrop>
  );
};

export default Backdrop;
