import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router'
import { useSelector } from "react-redux";

import SignUp from "../SignupScreen/SignupForm/SignupForm.js";
import SigninInstruction from "../SigninInstruction/SigninInstruction";
import SignupInstruction from "../SignupInstruction/SignupInstruction";
import SignIn from "../SigninScreen/SigninForm/SigninForm.js";
import { Snack } from "../../../common";

import { Status } from "../../../api";
import { translate } from "../../../i18n";
import { isNonEmptyString } from "../../../utils/";
import { ASYNC_STORAGE_KEYS } from "../../../constants";

import styles from "../Authentication.module.scss";
import cn from "classnames";

export const SignupScreen = () => {

  const {
    signupStatus,
    signupErrorMessage,
    loginStatus,
    loginErrorMessage
  } = useSelector((state) => state.auth);

  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);
  const snackMessage = loginStatus === Status.SUCCESS ? translate("common.loginSuccessfully") : signupErrorMessage ? signupErrorMessage : loginErrorMessage;
  const snackType = loginStatus === Status.SUCCESS ? "success" : "error";

  const [signupMode, toggleSignupMode] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (signupStatus === Status.ERROR || loginStatus === Status.ERROR || loginStatus === Status.SUCCESS) {
      setOpen(true);
    }
  }, [signupStatus, loginStatus]);

  const handleClose = () => {
    setOpen(false);
  }

  if (isNonEmptyString(loggedInUserIdentifier) && !open) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <div
        className={
          signupMode
            ? cn(styles.container, styles["sign-up-mode"])
            : styles.container
        }
      >
        <div className={styles["forms-container"]}>
          <div className={styles["signin-signup"]}>
            <SignUp signupMode={signupMode} />
            <SignIn signupMode={signupMode} />
          </div>
        </div>

        <div className={styles["panels-container"]}>
          <div className={cn(styles.panel, styles["left-panel"])}>
            <div>
              <SigninInstruction
                toggleSignupMode={toggleSignupMode}
                signupMode={signupMode}
              />
            </div>
          </div>
          <div
            className={cn(
              styles.panel,
              styles["panel-signup"],
              styles["right-panel"],
              !signupMode && styles["signup-instruction"]
            )}
          >
            <div>
              <SignupInstruction
                toggleSignupMode={toggleSignupMode}
                signupMode={signupMode}
              />
            </div>
          </div>
        </div>
      </div>
      <Snack
        open={open}
        message={snackMessage}
        type={snackType}
        onClose={handleClose}
      />
    </div>
  );
};
