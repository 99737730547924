import { Status } from '../../api';
import {
  FETCH_ARTICLES_LOADING,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_ERROR,
  FETCH_MORE_ARTICLES_LOADING,
  FETCH_MORE_ARTICLES_SUCCESS,
  FETCH_MORE_ARTICLES_ERROR,
} from '../../constants';

const initialState = {
  articles: [],
  contentAvailableForRefresh: true,
  /**
   * Correspond to first set of articles
   */
  articlesStatus: Status.DEFAULT,
  articlesErrorMessage: '',
  /**
   * Correspond to loading more articles
   */
  moreArticlesStatus: Status.DEFAULT,
  moreArticlesErrorMessage: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ARTICLES_LOADING:
      return {
        ...state,
        articlesStatus: Status.LOADING,
        articlesErrorMessage: '',
      };
    case FETCH_ARTICLES_SUCCESS: {
      const { articles } = payload;
      return {
        ...state,
        articlesStatus: Status.SUCCESS,
        articlesErrorMessage: '',
        articles,
        contentAvailableForRefresh: articles.length > 0,
      };
    }
    case FETCH_ARTICLES_ERROR:
      return {
        ...state,
        articlesStatus: Status.ERROR,
        articlesErrorMessage: payload.errorMessage,
      };
    case FETCH_MORE_ARTICLES_LOADING:
      return {
        ...state,
        moreArticlesStatus: Status.LOADING,
        moreArticlesErrorMessage: '',
      };
    case FETCH_MORE_ARTICLES_SUCCESS: {
      const { articles } = payload;
      return {
        ...state,
        moreArticlesStatus: Status.SUCCESS,
        moreArticlesErrorMessage: '',
        contentAvailableForRefresh: articles.length > 0,
        articles: [...state.articles, ...articles],
      };
    }
    case FETCH_MORE_ARTICLES_ERROR:
      return {
        ...state,
        moreArticlesStatus: Status.ERROR,
        moreArticlesErrorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
};
