/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  ALBUM_FILE_DIR_SK,
  BLOGGER_SK,
  CURRENT_USER_LK,
  IMAGE_SK,
  USER_SK,
  VIDEO_SK,
} from '../../constants';
import CardHeader from '@material-ui/core/CardHeader';
import { Badge, makeStyles } from '@material-ui/core';
import {
  Avatar,
  CircularProgress,
  Dialog,
  IconButton,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ImageIcon from '@mui/icons-material/Image';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BLOGGER_MARK from '../../assets/images/icon_blogger_mark.png';
import { api, Status } from '../../api';
import {
  makeCover,
  makeProfile,
} from '../../components/ProfileStack/components/GalleryTab/components/MediaOperationUtils';
import { updatePhoto } from '../../store/actions';
import { connect } from 'react-redux';
import { translate } from '../../i18n';
import CloseIcon from '@mui/icons-material/Close';
import ImageDownlaod from '../../components/ProfileStack/BusinessProfile/ImageDownload';
import styles from './MediaViewer.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BadgeContent from '../BadgeContent/BadgeContent';

const MediaViewer = ({
  open,
  entityType,
  profileType,
  userRole,
  mode,
  mediaList,
  selected,
  profileImgUrl,
  userName,
  close: _close,
  updatePhoto: _updatePhoto,
  deletePhoto: _deletePhoto,
  showSnack: _showSnack,
  restaurantDetial,
  attractionDetails
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const bloggerAvatar = (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <img
          id={styles['create-post-badge']}
          alt="img"
          src={BLOGGER_MARK}
          className={styles.badge}
        />
      }
    >
      <Avatar
        id={styles['create-post-blogger-avatar']}
        alt="avatar"
        src={profileImgUrl}
        className={classes.large}
      />
    </Badge>
  );

  const businessAvatar = (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<BadgeContent subType={entityType} />}
    >
      <Avatar
        id={styles['business-avatar-lg']}
        alt="avatar"
        src={profileImgUrl}
        className={classes.large}
      />
    </Badge>
  );

  const userAvatar = (
    <Avatar
      id={styles['create-post-avatar']}
      alt="avatar"
      src={profileImgUrl}
      className={classes.large}
    />
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const [deleteApiStatus, setDeleteApiStatus] = useState(Status.DEFAULT);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const next = () => {
    if (selectedIndex === mediaList.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const back = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(mediaList.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  useEffect(() => {
    setSelectedIndex(selected);
  }, [selected]);

  const deleteMedia = () => {
    setAnchorEl(null); //close menu
    if (deleteApiStatus !== Status.LOADING) {
      setDeleteApiStatus(Status.LOADING);
      const identifier = mediaList[selectedIndex].identifier;
      api
        .deleteMedia({
          fileIdentifier: [identifier],
          fileDirectory: ALBUM_FILE_DIR_SK,
        })
        .then(() => {
          _deletePhoto(selectedIndex);
          if (selectedIndex >= mediaList.length - 1) {
            setSelectedIndex(0);
          }
          setDeleteApiStatus(Status.SUCCESS);
        })
        .catch((err) => {
          setDeleteApiStatus(Status.ERROR);
        });
    }
  };

  const makeProfilePic = () => {
    setAnchorEl(null); //close menu
    makeProfile(mediaList[selectedIndex], _updatePhoto);
    _showSnack(translate('common.profileUpdatedSuccessMessage'));
  };

  const makeCoverPic = () => {
    setAnchorEl(null); //close menu
    makeCover(mediaList[selectedIndex], _updatePhoto);
    _showSnack(translate('common.coverUpdatedSuccessMessage'));
  };

  return (
    <Dialog
      open={open}
      onClose={_close}
      PaperProps={{
        id: styles['media-viewer-dialog'],
      }}
    >
      <div>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={_close}
        >
          <CloseIcon className={styles['media_viewer_close_btn']} />
        </IconButton>
        {restaurantDetial && (
          <ImageDownlaod
            name={userName}
            className={styles.downloadBtn}
            imageUrl={mediaList[0]?.filePath?.originalUrl}
          />
        )}

        <div className={styles['media-viewer']}>
          {!attractionDetails && (
            <div className={styles.header}>
              <CardHeader
                id={styles['card-header']}
                avatar={
                  userRole === BLOGGER_SK
                    ? bloggerAvatar
                    : entityType === USER_SK
                      ? userAvatar
                      : businessAvatar
                }
                title={
                  <span
                    className={styles['user-name']}
                    id={styles['create-post-profile-name']}
                  >
                    {userName}
                  </span>
                }
                //subheader='time'
                action={
                  mode === 'details' &&
                  profileType === CURRENT_USER_LK && (
                    <MoreHorizIcon onClick={handleMenuClick} />
                  )
                }
              />
            </div>
          )}
          <Menu
            id={styles['long-menu']}
            anchorEl={anchorEl}
            keepMounted
            open={isOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <>
              {mediaList[selectedIndex]?.fileType !== VIDEO_SK && (
                <>
                  <MenuItem onClick={makeProfilePic}>
                    <div className={styles['media-menu']}>
                      <AccountBoxIcon fontSize="small" />
                      <div>{translate('common.makeProfile')}</div>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={makeCoverPic}>
                    <div className={styles['media-menu']}>
                      <ImageIcon fontSize="small" />
                      <div>{translate('common.makeCover')}</div>
                    </div>
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={deleteMedia}>
                <div className={styles['media-menu']}>
                  <DeleteIcon fontSize="small" />
                  <div>{translate('common.delete')}</div>
                </div>
              </MenuItem>
            </>
          </Menu>
          <div className={styles['media-view']}>
            <div className={styles['media-item-container']}>
              {mediaList.map(
                (media, key) =>
                  key === selectedIndex && (<>
                    <div
                      className={styles['media-item']}
                      key={key}
                    >
                      <div>
                        {mediaList.length > 1 ? (
                          <ArrowBackIosIcon
                            onClick={back}
                            id={styles['arrow-icon-left']}
                            className={styles['arrow-icon']}
                          />
                        ) : (
                          <div className={styles['empty-div']} />
                        )}
                      </div>
                      {deleteApiStatus === Status.LOADING && (
                        <CircularProgress
                          id={styles['spinner']}
                          size={22}
                          className="loading_spinner"
                        />
                      )}
                      {media.fileType === IMAGE_SK ? (
                        <img
                          id={styles["media-img"]}
                          alt="img"
                          loading="lazy"
                          className={
                            deleteApiStatus === Status.LOADING ? 'blur' : ''
                          }
                          src={media.filePath.originalUrl}
                        />
                      ) : (
                        <video
                          controls
                          autoPlay
                          src={media.filePath.originalUrl}
                        />
                      )}
                      <div>
                        {mediaList.length > 1 ? (
                          <ArrowForwardIosIcon
                            onClick={next}
                            id={styles['arrow-icon-right']}
                            className={styles['arrow-icon']}
                          />
                        ) : (
                          <div className={styles['empty-div']} />
                        )}
                      </div>

                    </div>
                    {media.caption && (
                      < div className={styles['card-media-caption-lg']}>
                        {media.caption}
                      </div>
                    )}
                  </>)
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog >
  );
};

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default connect(null, { updatePhoto })(MediaViewer);
