import { Status } from '../../api';
import {
  FETCH_BLOGGERS_LOADING,
  FETCH_BLOGGERS_SUCCESS,
  FETCH_BLOGGERS_ERROR,
  FETCH_MORE_BLOGGERS_LOADING,
  FETCH_MORE_BLOGGERS_SUCCESS,
  FETCH_MORE_BLOGGERS_ERROR,
} from '../../constants';

const initialState = {
  bloggers: [],
  contentAvailableForRefresh: true,
  /**
   * Correspond to first set of bloggers
   */
  bloggersStatus: Status.DEFAULT,
  bloggersErrorMessage: '',
  /**
   * Correspond to loading more bloggers
   */
  moreBloggersStatus: Status.DEFAULT,
  moreBloggersErrorMessage: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BLOGGERS_LOADING:
      return {
        ...state,
        bloggersStatus: Status.LOADING,
        bloggersErrorMessage: '',
      };
    case FETCH_BLOGGERS_SUCCESS: {
      const { bloggers } = payload;
      return {
        ...state,
        bloggersStatus: Status.SUCCESS,
        bloggersErrorMessage: '',
        bloggers,
        contentAvailableForRefresh: bloggers.length > 0,
      };
    }
    case FETCH_BLOGGERS_ERROR:
      return {
        ...state,
        bloggersStatus: Status.ERROR,
        bloggersErrorMessage: payload.errorMessage,
      };
    case FETCH_MORE_BLOGGERS_LOADING:
      return {
        ...state,
        moreBloggersStatus: Status.LOADING,
        moreBloggersErrorMessage: '',
      };
    case FETCH_MORE_BLOGGERS_SUCCESS: {
      const { bloggers } = payload;
      return {
        ...state,
        moreBloggersStatus: Status.SUCCESS,
        moreBloggersErrorMessage: '',
        contentAvailableForRefresh: bloggers.length > 0,
        bloggers: [...state.bloggers, ...bloggers],
      };
    }
    case FETCH_MORE_BLOGGERS_ERROR:
      return {
        ...state,
        moreBloggersStatus: Status.ERROR,
        moreBloggersErrorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
};
