import { takeLatest, call, put } from 'redux-saga/effects';
import { api } from '../../api';
import {
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_LOADING,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_ERROR,
  FETCH_MORE_ARTICLES_LOADING,
  FETCH_MORE_ARTICLES_SUCCESS,
  FETCH_MORE_ARTICLES_ERROR,
} from '../../constants';
import {
  SORT_POPULAR_SK,
  ASCENDING_SK,
} from '../../constants';
import { shuffle, } from '../../utils';

const FIRST_PAGE = 1;

/**
 * worker saga: Calls the fetch articles List API.
 *
 * @param {Object} action                   - action object dispatched by user
 * @param {string} action.payload.sortBy    - one of the string "createdAt"|"popular"
 * @param {string} action.payload.sortOrder - ascending or descending
 * @param {number} action.payload.page      - page number to be fetched
 */
function* fetchArticles({ payload: { sortBy = SORT_POPULAR_SK, sortOrder = ASCENDING_SK, page } }) {
  try {
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_ARTICLES_LOADING
          : FETCH_MORE_ARTICLES_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchArticles },
      { sortBy, sortOrder, page },
    );
    // parse the data from response
    const { userResponse: articles } = response;
    // send parsed data into action payload
    yield put({
      type:
        page === FIRST_PAGE
          ? FETCH_ARTICLES_SUCCESS
          : FETCH_MORE_ARTICLES_SUCCESS,
      payload: {
        articles: articles.length > 0 ? shuffle(articles) : [],
      },
    });
  } catch (error) {
    yield put({
      type:
        page === FIRST_PAGE ? FETCH_ARTICLES_ERROR : FETCH_MORE_ARTICLES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_ARTICLES_REQUEST, fetchArticles);
}
