import { shape, string, number, arrayOf } from 'prop-types';

/**
 * Defines prop-types definition for a single interest item
 * which is being displayed on InterestScreen
 */
const interestType =  arrayOf(
    shape({
      id: number,
      userIdentifier: string,
      interestName: string,
    }),
)

export default interestType;
