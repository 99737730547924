/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import {
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  IconButton,
  InputAdornment,
  createTheme,
  ThemeProvider
} from '@mui/material';
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { Snack } from '../../../../common';
import {
  api,
  Status
} from '../../../../api';
import { translate } from '../../../../i18n';

import styles from './ChangePassword.module.scss';
import colors from "../../../../css/variable.scss";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
            backgroundColor: "none !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        },
      },
    },
  },
});

const ChangePassword = ({
  history: _history
}) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useForm();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('success');
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    return () => {
      // componentWillUnmount
      resetState();
    };
  }, []);

  useEffect(() => {
    if (apiStatus === Status.SUCCESS) {
      setSnackOpen(true);
      setSnackMsg(apiSuccessMessage);
      setSnackType('success');
      resetState();
    }
  }, [apiStatus, apiSuccessMessage]);

  const resetState = () => {
    setCurrentPassword('');
    setConfirmNewPassword('');
    setNewPassword('');
    clearErrors('currentpassword');
    clearErrors('newpassword');
    clearErrors('password');
  };

  const onSubmit = () => {
    setApiErrorMessage('');
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      api
        .changePassword({ currentPassword, newPassword, confirmNewPassword })
        .then(({ userResponse }) => {
          setApiStatus(Status.SUCCESS);
          setApiSuccessMessage(userResponse.status);
        })
        .catch((error) => {
          setApiStatus(Status.ERROR);
          setApiErrorMessage(error.message);
        });
    }
  };

  const closeSanck = () => {
    setSnackOpen(false);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={styles['create-password']}>
      <div id={styles['create-password-container']}>
        <form id={styles['form-root']}>
          <ThemeProvider theme={theme}>
            <Grid container spacing={2}>
              {/* Current Passord */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel className={styles["form-item-label"]}>
                    {translate('changePasswordScreen.currentPasswordLabel')}
                    <span> *</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <FormControl className={styles.inbox} variant="outlined">
                  <OutlinedInput
                    styles={{ notchedOutline: styles.input_root }}
                    {...register('currentpassword', {
                      required: true,
                    })}
                    id={styles['outlined-adornment-password']}
                    type={showCurrentPassword ? 'text' : 'password'}
                    value={currentPassword}
                    error={errors.currentpassword?.type === 'required' || apiStatus === Status.ERROR}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error className={styles["error-message"]}>
                    {apiErrorMessage}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {/* New Password */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel className={styles["form-item-label"]}>
                    {translate('changePasswordScreen.changeNewPasswordLabel')}
                    <span> *</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <FormControl className={styles.inbox} variant="outlined">
                  <OutlinedInput
                    styles={{ notchedOutline: styles.input_root }}
                    {...register('newpassword', {
                      required: true,
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\])[A-Za-z\d-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\]{8,}$/,
                      },
                    })}
                    id={styles['outlined-adornment-password']}
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    error={
                      errors.newpassword?.type === 'required' ||
                      errors.newpassword?.type === 'pattern'
                    }
                    onChange={(e) => setNewPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error className={styles["error-message"]}>
                    {errors.password?.type === 'required' ? (
                      <span>
                        {translate('inboxError.passwordErrorRequired')}
                      </span>
                    ) : errors.newpassword?.type === 'pattern' ? (
                      <span> {translate('inboxError.passwordErrorWrong')}</span>
                    ) : null}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {/* Confirm Password */}
              <Grid item xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <InputLabel className={styles["form-item-label"]}>
                    {translate('changePasswordScreen.changeConfirmPasswordLabel')}
                    <span> *</span>
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <FormControl className={styles.inbox} variant="outlined">
                  <OutlinedInput
                    styles={{ notchedOutline: styles.input_root }}
                    {...register('password', {
                      required: true,
                      validate: (value) =>
                        value === watch('newpassword') ||
                        'The passwords do not match',
                    })}
                    id={styles['outlined-adornment-password']}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmNewPassword}
                    error={
                      errors.password?.type === 'required' ||
                      errors.password?.type === 'validate'
                    }
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error className={styles["error-message"]}>
                    {errors.password?.type === 'required' ? (
                      <span>
                        {translate('inboxError.passwordErrorRequired')}
                      </span>
                    ) : errors.password?.type === 'validate' ? (
                      <span> {translate('inboxError.passwordErrorMatch')}</span>
                    ) : null}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>
      </div>
      <div id={styles['password-tab-btn']}>
        {currentPassword === '' ||
          newPassword === '' ||
          confirmNewPassword === '' ? (
          <Button disabled className={styles.btnDisable}>
            {' '}
            {translate('common.submit')}
          </Button>
        ) : (
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className={styles.submitButton}
          >
            {apiStatus === Status.LOADING ? (
              <CircularProgress style={{ color: '#fff' }} size={20} />
            ) : (
              <span> {translate('common.submit')}</span>
            )}
          </Button>
        )}
      </div>
      <Snack
        open={snackOpen}
        message={snackMsg}
        type={snackType}
        onClose={closeSanck}
      />
    </div >
  );
};

export default withRouter(ChangePassword);
