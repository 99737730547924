/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "../../../../i18n";
import { Carousel } from "../../../../common";
import {
  ATTRACTION_SK,
  HOTEL_SK,
  LANDMARK_LK,
  SAVE_SK,
  UNSAVE_SK
} from "../../../../constants";
import { ArticleListItem } from "../../../HomeStack/Home/components/RandomSection/components";
import SnackBar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { isEmpty } from "lodash";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./AboutTab.module.scss";
import { useHistory } from "react-router";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import { useSelector } from "react-redux";
import { Status } from "../../../../api";
import { CircularProgress } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TableCell = withStyles({
  root: {
    borderBottom: "none !important",
    padding: "2% 0 0 !important",
  },
})(MuiTableCell);

const AboutTab = ({
  subType,
  businessDetails,
  baseMediaUrl,
  baseMediaThumbUrl,
  loggedInUserIdentifier,
  apiStatus
}) => {
  const {
    shortDescription,
    description,
    cuisines,
    specialDiets,
    meals,
    restoFeatures,
    roomFeatures,
    hotelAmenities,
    identifier
  } = businessDetails;

  const { favouriteStatus, entityIdentifier, favouriteIdentifier } = useSelector((state) => state.home);
  const features = restoFeatures || roomFeatures || [];
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [landmarks, setLandmarks] = useState(businessDetails.landmarks);
  const history = useHistory();

  useEffect(() => {
    if (apiStatus === Status.SUCCESS) {
      setLandmarks(businessDetails?.landmarks);
    }
  }, [apiStatus]);

  useEffect(() => {
    if (favouriteStatus === Status.SUCCESS) {
      setLandmarks((landmarks) => {
        return landmarks.map((item) => {
          if (item.identifier === entityIdentifier) {
            return {
              ...item,
              isSaved: item?.isSaved === UNSAVE_SK ? SAVE_SK : UNSAVE_SK,
              favouriteIdentifier: favouriteIdentifier
            };
          } else {
            return {
              ...item,
            }
          }
        });
      });
    }
  }, [favouriteStatus, favouriteIdentifier, entityIdentifier]);

  const handleSnackOpen = (msg) => {
    setSnackMsg(msg);
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackMsg("");
    setSnackOpen(false);
  };

  return (
    <div className={styles["about-tab-container"]}>
      {(shortDescription || description) && (
        <div className={styles.description}>
          {shortDescription !== "NULL" && <div id={styles.title}>{shortDescription}</div>}
          {description !== "NULL" && <div id={styles.des}>{description}</div>}
        </div>
      )}

      {(hotelAmenities?.length !== 0 && hotelAmenities !== undefined) ||
        features.length !== 0 ? (
        <div className={styles.features}>
          {subType === HOTEL_SK && (
            <div id={styles.title}>
              {translate("businessProfile.amenities")}
            </div>
          )}
          {(cuisines ||
            specialDiets ||
            !isEmpty(meals)) && (
              <div id={styles.title}>
                {`${translate("businessProfile.cuisines")}, ${translate(
                  "businessProfile.features"
                )}`}
              </div>
            )}
          {subType === HOTEL_SK ? (
            <TableContainer>
              <Table>
                <TableBody>
                  {hotelAmenities?.map((amenity, key) => {
                    return (
                      key <= hotelAmenities.length - 1 &&
                      key % 2 === 0 && (
                        <TableRow key={key}>
                          <TableCell>
                            <div className={styles.item}>
                              <img
                                alt="img"
                                src={hotelAmenities[key].iconUrl}
                                id={styles.icon}
                              />
                              <span id={styles.label}>
                                {hotelAmenities[key].name}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={styles.item}>
                              {hotelAmenities[key + 1] && (
                                <img
                                  alt="img"
                                  src={hotelAmenities[key + 1]?.iconUrl}
                                  id={styles.icon}
                                />
                              )}
                              <span id={styles.label}>
                                {hotelAmenities[key + 1]?.name}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer style={{ overflow: "unset" }}>
              <Table>
                <TableBody>
                  {cuisines && cuisines !== "NULL" && (
                    <TableRow>
                      <TableCell style={{ width: "15%" }}>
                        <div className={styles.item}>
                          <RoomServiceOutlinedIcon id={styles["cuisines-icon"]} />
                          <span id={styles.label}>
                            {translate("businessProfile.cuisines")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span id={styles.text}>{cuisines}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {specialDiets && specialDiets !== "NULL" && (
                    <TableRow>
                      <TableCell style={{ width: "25%" }}>
                        <div className={styles.item}>
                          <RoomServiceOutlinedIcon id={styles["cuisines-icon"]} />
                          <span id={styles.label}>
                            {translate("businessProfile.specialDiets")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span id={styles.text}>{specialDiets}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {meals?.length !== 0 && (
                    <TableRow>
                      <TableCell style={{ width: "15%" }}>
                        <div className={styles.item}>
                          <RoomServiceOutlinedIcon id={styles["cuisines-icon"]} />
                          <span id={styles.label}>
                            {translate("businessProfile.meals")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span id={styles.text}>{meals?.join(", ")}</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {features?.length !== 0 && (
            <>
              <div id={(cuisines ||
                specialDiets ||
                !isEmpty(meals) || !isEmpty(hotelAmenities)) ?
                styles["features-title-padding"] :
                styles["features-title"]}>
                {translate("businessProfile.features")}
              </div>
              <div>
                {features?.map((feature, key) => {
                  return (
                    <div key={key} className={styles["resto-feature"]}>
                      {/* <img alt='icon' src={featuresIcon} id='icon' /> */}
                      <DoneIcon className={styles["feature_done_icon"]} />
                      <span id={styles.feature}>{feature}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles["nomore-data-info"]}>
          {apiStatus === Status.LOADING
            ? <div className={styles.spinner}>
              <CircularProgress size={22} className="loading_spinner" />
            </div>
            : <span>{translate("businessProfile.noAbout")}</span>}
        </div>
      )}
      {landmarks.length !== 0 && (
        <div id={styles["nearby-landmarks"]}>
          <div className={styles["nearby-landmark-topbar"]}>
            <div id={styles.title}>
              {translate("businessProfile.landmarksTitle")}
            </div>
            <div
              className={styles["button"]}
              onClick={() => history.push(`/list/attractions/${LANDMARK_LK}?id=${window.btoa(identifier)}`)}
            >
              {translate("common.seeAll")}
            </div>
          </div>
          <div>
            <Carousel
              items={landmarks}
              count={2}
              md={2}
              sm={1}
              type={ATTRACTION_SK}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              loggedInUserIdentifier={loggedInUserIdentifier}
              component={ArticleListItem}
              style={styles["nearby-landmarks-item"]} // eslint-disable-line react/style-prop-object
              openSnack={handleSnackOpen}
            />
          </div>
        </div>
      )}
      <SnackBar
        id={styles["my-snack"]}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          className={styles["alert-success"]}
        >
          {snackMsg}
        </Alert>
      </SnackBar>
    </div>
  );
};

export default AboutTab;
