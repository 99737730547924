import React from 'react';
import './SmooveIcons.scss';

const SmooveIcons = {
  IcTravelMoneyCalculator: 'ic_travel_money_calculator',
  IcHotelProfile: 'ic_hotel-profile',
  IcEmengencyContact: 'ic_emengency_contact',
  IcResturantProfile: 'ic_resturant-profile',
  IcExchangeRate: 'ic_exchange_rate',
  IcWeather: 'ic-weather',
  IcEmergencyCar: 'ic-emergency-car',
  IcPolice: 'ic-police',
  IcHotel: 'ic-hotel',
  IcResturant: 'ic-resturant',
  IcAttractionMap: 'ic-attraction-map'
};

const SmooveIcon = ({ name }) => <i className={`ico ${name}`}></i>;

export { SmooveIcons, SmooveIcon };
