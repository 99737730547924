import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Divider,
} from '@material-ui/core';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import profileImg from '../../../../../assets/images/logo.png';
import { translate } from '../../../../../i18n';
// import ICON_SHARE from '../../../../../assets/images/icon_share.png';
import { isNonEmptyString } from '../../../../../utils';
import { ARTICLE_SK, LIMITS, SAVE_SK } from '../../../../../constants';
// import EditShareModal from '../../../../../common/EditShareModal/EditShareModal';
import { Status } from '../../../../../api';
import { connect } from 'react-redux';
import { saveFavourite, deleteFavourite } from '../../../../../store/actions';
import SnackBar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { withRouter } from 'react-router';
import { Stack } from '@mui/material';
import { CopyLinkIcon, RatingStar } from '../../../../../common';
import styles from './Article.module.scss';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const propTypes = {
//   item: articleType.isRequired,
//   baseMediaUrl: PropTypes.string,
//   baseMediaThumbUrl: PropTypes.string,
//   loggedInUserIdentifier: PropTypes.string,
//   isShared: PropTypes.bool,
// };

// const defaultProps = {
//   baseMediaUrl: '',
//   baseMediaThumbUrl: '',
//   loggedInUserIdentifier: '',
//   isShared: false,
// };

const Article = ({
  history,
  item,
  baseMediaUrl,
  baseMediaThumbUrl,
  loggedInUserIdentifier,
  isShared,
  //   sharePostStatus,
  //   sharedPostIdentifier,
  favouriteStatus,
  entityIdentifier,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  showLoginPrompt: _showLoginPrompt,
}) => {

  const [flag, setFlag] = useState(false);

  const [snackOpen, setOpen] = useState(false);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [copySnackOpen, setCopySanckOpen] = useState(false);

  const handleCopySnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopySanckOpen(false);
  };

  const LIMIT = isShared
    ? LIMITS.SHARED_POST_DESCRIPTION_MIN_LENGTH
    : LIMITS.POST_DESCRIPTION_MIN_LENGTH;

  const [isSaved, setIsSaved] = useState(item.isSaved);

  const [avgRating, setAvgRating] = useState({
    localValue: item.avgRating,
    backupValue: item.avgRating,
  });
  const [viewsCount, setViewsCount] = useState({
    localValue: item.totalViews,
    backupValue: item.totalViews,
  });
  const [sharesCount, setSharesCount] = useState({
    localValue: item.totalShares,
    backupValue: item.totalShares,
  });
  const [reviewsCount, setReviewsCount] = useState({
    localValue: item.totalReviews,
    backupValue: item.totalReviews,
  });

  useEffect(() => {
    if (avgRating.backupValue !== item.avgRating) {
      setAvgRating({
        localValue: item.avgRating,
        backupValue: item.avgRating,
      });
    }
    if (sharesCount.backupValue !== item.totalShares) {
      setSharesCount({
        localValue: item.totalShares,
        backupValue: item.totalShares,
      });
    }
    if (reviewsCount.backupValue !== item.totalReviews) {
      setReviewsCount({
        localValue: item.totalReviews,
        backupValue: item.totalReviews,
      });
    }
    if (viewsCount.backupValue !== item.totalViews) {
      setViewsCount({
        localValue: item.totalViews,
        backupValue: item.totalViews,
      });
    }
  }, [item.totalShares, item.totalReviews, item.totalViews, item.avgRating]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsSaved(item.isSaved);
    if (flag) {
      setOpen(true);
      setFlag(false);
    }
  }, [item.isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  //const index = getRandomInt(item.mediaUrl.length);
  //   const media = item.mediaUrl[0];

  const coverImg = {
    source: {
      uri: item?.coverImg?.url?.originalUrl,
    },
    thumb: {
      uri: item?.coverImg?.isThumbGenerated
        ? item?.coverImg?.url?.thumbUrl?.concat('?a=1')
        : item?.coverImg?.url?.originalUrl,
    },
    fileType: 'img',
  };

  //   const [openShareModal, setOpenShareModal] = React.useState(false);
  //   const [anchorEl, setAnchorEl] = useState(null);
  //   const open = Boolean(anchorEl);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const handleOpenShareModal = () => {
  //     if (!isNonEmptyString(loggedInUserIdentifier)) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenShareModal(true);
  //   }

  //   const handleCloseShareModal = () => {
  //     setOpenShareModal(false);
  //   }

  const save = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (favouriteStatus !== Status.LOADING) {
      setFlag(true);
      if (isSaved === SAVE_SK) {
        _saveFavourite(item.identifier, ARTICLE_SK, loggedInUserIdentifier);
      } else {
        _deleteFavourite(item.favouriteIdentifier, ARTICLE_SK, item.identifier);
      }
    }
  };

  const handleProfileClick = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
  };

  const handleArticleClick = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${window.btoa(item.identifier)}/article`);
  };
  return (
    <>
      <Card className={isShared ? styles.sharedStyle : styles.root}>
        <CardHeader
          id={styles['mui-card-header']}
          avatar={
            <Avatar
              id={styles['pli-avatar']}
              src={profileImg}
              alt=""
              className={styles.avatar}
              onClick={handleProfileClick}
            />
          }
          title={
            <span
              className={styles['article-header-title']}
              onClick={handleProfileClick}
            >
              {translate('common.articleProfileName')}
            </span>
          }
        />
        <div id={styles['mui-typo']} className={styles.articleTitleContainer}>
          <span
            className={styles['article-title']}
            onClick={handleArticleClick}
          >
            {item.title}
          </span>
          <div onClick={handleArticleClick}>
            <RatingStar
              rating={avgRating.localValue}
              size="small"
              isNewsfeed={true}
            />
          </div>
        </div>
        <div id={styles['protected-img-box']} onClick={handleArticleClick}>
          <CardMedia
            component="img"
            image={coverImg.source.uri}
            alt="cover.img"
            className={styles['article-media']}
            onClick={handleArticleClick}
          />
          <div id={styles['protected-img']} onClick={handleArticleClick}></div>
        </div>
        <CardContent className={styles.content} onClick={handleArticleClick}>
          <div className={styles['pli-description']}>
            <span>
              {`${item.description?.length > LIMIT
                ? item.description.slice(0, LIMIT)
                : item.description
                } `}

              <span className="text-blue">
                ...{translate('common.readMore')}
              </span>
            </span>
          </div>
        </CardContent>
        {isShared ? (
          <></>
        ) : (
          <>
            <Divider />
            <CardActions
              id={styles['article-footer']}
              className={styles.noHorizontalPadding}
            >
              <div className={styles.shareIcon}>
                <Stack direction="row" spacing={1}>
                  <CopyLinkIcon
                    text={`${item.copyLinkUrl}?c=1`}
                    onCopy={() => setCopySanckOpen(true)}
                  />
                  <IconButton
                    className={styles['save-icon-button']}
                    aria-label="save-icon"
                    onClick={save}
                  >
                    {isSaved === SAVE_SK ? (
                      <BookmarkBorderIcon
                        className={styles['mui-save-icon-style']}
                      />
                    ) : (
                      <BookmarkIcon
                        className={styles['mui-save-icon-style-filled']}
                      />
                    )}
                  </IconButton>
                </Stack>
              </div>
              <span id={styles['article-footer-span']} className="text-gray">
                {viewsCount.localValue > 0 && (
                  <span>
                    {`${viewsCount.localValue} ${viewsCount.localValue > 1
                      ? translate('common.views')
                      : translate('common.view')
                      } `}
                  </span>
                )}
              </span>
              {/* <EditShareModal
                open={openShareModal}
                identifier={item.identifier}
                mode='share'
                type={ARTICLE_SK}
                handleClose={handleCloseShareModal}
              /> */}
            </CardActions>
          </>
        )}
      </Card>
      {favouriteStatus === Status.SUCCESS &&
        item.identifier === entityIdentifier && (
          <SnackBar
            id={styles['my-snack']}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackOpen}
            autoHideDuration={2000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              className={styles['alertColor']}
            >
              {isSaved === SAVE_SK
                ? translate('homeScreen.unsaveFavouriteSuccessMessage')
                : translate('homeScreen.saveFavouriteSuccessMessage')}
            </Alert>
          </SnackBar>
        )}
      <SnackBar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={copySnackOpen}
        autoHideDuration={2000}
        onClose={handleCopySnackClose}
      >
        <Alert
          onClose={handleCopySnackClose}
          severity="success"
          className={styles['alertColor']}
        >
          {translate('postListItem.copyLinkSuccessMessage')}
        </Alert>
      </SnackBar>
    </>
  );
};

// Article.propTypes = propTypes;

// Article.defaultProps = defaultProps;

const mapStateToProps = ({ home }) => {
  const { favouriteStatus, entityIdentifier } = home;
  return {
    favouriteStatus,
    entityIdentifier,
  };
};
export default connect(mapStateToProps, { saveFavourite, deleteFavourite })(
  withRouter(Article)
);
