import { translate } from '../i18n';

export function getNetworkStatusOptions() {
  return [
    {
      title: translate('common.unfriend'),
    },
    {
      title: translate('common.unfollow'),
    },
    {
      title: translate('common.block'),
    },
  ];
}
