/**
 * i18n stands for internationalization
 */
import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';
import { isNonEmptyString } from '../utils/primitiveChecks';
import {
  ENGLISH_LANGUAGE_CODE_LK,
  BURMESE_LANGUAGE_CODE_LK,
  BURMESE_SK,
} from '../constants/stringConstants';
import { ASYNC_STORAGE_KEYS } from '../constants';

const en = require('./locales/en');
const my = require('./locales/my');

// Should the app fallback to English if user locale doesn't exists
i18n.fallbacks = true;

// Define the supported translation
i18n.translations = {
  en,
  my,
};

const fallback = { languageTag: 'en', isRTL: false };

let { languageTag } =
  RNLocalize.findBestAvailableLanguage(Object.keys(i18n.translations)) ||
  fallback;

/**
 * If user is logged in, then get his/her
 * preferred language from AsyncStorage
 */
const getSavedPreference = async () => {
  try {
    const language = localStorage.getItem(
      ASYNC_STORAGE_KEYS.userPreferedLangage,
    );
    if (isNonEmptyString(language)) {
      languageTag = language === BURMESE_SK
        ? BURMESE_LANGUAGE_CODE_LK
        : ENGLISH_LANGUAGE_CODE_LK;
    }
  } catch (e) {
  }

  i18n.locale = languageTag;
};

getSavedPreference();
