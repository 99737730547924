import { LIMITS } from '../constants';
import {
  ATTRACTION_SK,
  BUSINESS_SK,
  USER_SK,
  ATTRACTION_PROFILE_LK,
  BUSINESS_PROFILE_LK,
} from '../constants/stringConstants';

/**
 * Return true, if email is valid else false
 *
 * @param {string} email
 */
export function isEmailValid(_email) {
  if (_email === null || _email === undefined) {
    return false;
  }

  const email = _email.trim();
  const regexEmail = /^(?:[\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
  if (!email.match(regexEmail)) {
    return false;
  }
  return true;
}

/**
 * Returns true if phonenumber is valid, else false
 *
 * @param {string} phonetxt - phone number entered by user
 */
export function isPhoneNumberValid(phonetxt) {
  // var phoneno = /^\d{10}$/;    // To restrict for min 10 entries
  // const phoneno = /^.[0-9\\+]{0,20}$/;
  const phoneno = /^\+[1-9]\d{1,14}$/; // https://www.twilio.com/docs/glossary/what-e164

  return phonetxt.match(phoneno);
}

/**
 * Returns true if password is valid, else false
 * current check: Password shouldn't be empty and atleast 8 character
 *
 * @param {string} password - password entered by user
 */
export function isPasswordValid(password) {
  // At least one number, one uppercase letter, one lowercase letter and one special symbol.
  const passwordregix = /((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,21})/;

  return password.match(passwordregix);
}

/**
 * Returns true if name is valid, else false
 * current check: name shouldn't be empty and atleast 4 character
 *
 * @param {string} name - name entered by user
 */
export function isNameValid(_name) {
  if (_name === null || _name === undefined) {
    return false;
  }
  const name = _name?.trim();
  return (
    name.length <= LIMITS.NAME_MAX_LENGTH &&
    name.length >= LIMITS.NAME_MIN_LENGTH
  );
}

/**
 * Returns true if School, University ,Work Location Name is valid, else false
 *
 * current check: Length shouldn't be greater than LIMITS.WORK_AND_EDUCATION_NAME_MAX_LENGTH  i.e 70 char
 * and should not be less than 1.
 *
 * @param {string} name - name entered by user
 */
export function isWorkAndEducationNameValid(name) {
  if (name === null || name === undefined) {
    return false;
  }

  const _name = name.trim();
  return _name.length >= LIMITS.NAME_MIN_LENGTH;
}

/**
 * Returns true if School, University ,Work Location Name is valid, else false
 *
 * current check: Length shouldn't be greater than LIMITS.WORK_AND_EDUCATION_NAME_MAX_LENGTH  i.e 70 char
 * and should not be less than 1.
 *
 * @param {string} item - name entered by user
 */
export function isWorkAndEducationValid(item) {
  if (!isWorkAndEducationNameValid(item.name) || item.from === '') {
    return false;
  }
  return true;
}

/**
 * Validation for image
 *
 * @param {Object} image
 */
export function isValidImage(image) {
  if (!image.type.includes('image')) {
    return false;
  }
  const maxImageSize = LIMITS.IMAGE_MAX_SIZE * 1000000;
  const formats = ['gif', 'jpg', 'jpeg', 'png'];

  return (
    image.size <= maxImageSize &&
    formats.some(format => image.type.includes(format))
  );
}

/**
 * Validation for a video
 *
 * @param {Object} video
 */
function isValidVideo(video) {
  const maxVideoSize = LIMITS.VIDEO_MAX_SIZE * 1000000;
  const maxVideoDuration = LIMITS.VIDEO_MAX_DURATION * 60000; // eslint-disable-line no-unused-vars
  const format = 'video/mp4'; // Currently only 1 format is supported
  return (
    // video.duration <= maxVideoDuration &&
    video.type === format && video.size <= maxVideoSize
  );
}

/**
 * Validation check for the images & videos,
 *
 * mediaList array object looks like this
 * {
 *    height: 360
 *    mime: "video/mp4"
 *    modificationDate: "1596626550000"
 *    path: "file:///data/user/0/com.xyz.abc/cache/react-native-image-crop-picker/videoplayback.mp4"
 *    size: 17006265
 *    width: 640
 * }
 *
 * @param {Object} media  - Object contain image/video details like file path, mime type etc
 * @param {bool} isVideoAllowed - Whether mediaList can contain video or not
 */
export function mediaValidation(media, isVideoAllowed = false) {
  return isValidImage(media) || (isVideoAllowed && isValidVideo(media));
}

/**
 *  get entity type for media api
 * @param type
 * @return {string}
 */
export function getEntityType(type) {
  switch (type) {
    case ATTRACTION_PROFILE_LK:
      return ATTRACTION_SK;
    case BUSINESS_PROFILE_LK:
      return BUSINESS_SK;
    default:
      return USER_SK;
  }
}

/**
 *  To get image extention in video sub url
 * @param url video sub url with video extention
 * @return {*} video thumb url with jpg extention
 */
export function getVideoThumb(url) {
  return `${url.slice(0, url.lastIndexOf('.'))}.jpg`;
}
