import React, { useState } from "react";
import { withRouter } from "react-router";
import { makeStyles, CardContent } from "@material-ui/core";
import {
  ATTRACTION_SK,
  LIMITS,
  PLI_DETAIL_MODE_LK,
  POST_SK,
} from "../../constants";
import { isNonEmptyString } from "../../utils/primitiveChecks";
import styles from "../../components/HomeStack/Home/components/Attraction/Attraction.module.scss";
import { translate } from "./../../i18n";
// const StyledInput = styled(InputBase)({
//   '& .MuiInputBase-root': {
//     padding: '0 !important',
//   },
//   '& .MuiInputBase-input': {
//     cursor: 'pointer !important',
//   },
// });
const DescriptionSection = ({
  identifier,
  mode,
  type = POST_SK,
  isShared = false,
  isContainedMedia = false,
  description,
  loggedIn,
  showLoginPrompt: _showLoginPrompt,
  history,
}) => {
  const classes = useStyles();
  const [readMore, setReadMore] = useState(false);

  const LIMIT =
    type === ATTRACTION_SK
      ? LIMITS.ATTRACTION_DESCRIPTION_MIN_LENGTH
      : isShared && isContainedMedia
        ? LIMITS.SHARED_POST_DESCRIPTION_MIN_LENGTH
        : LIMITS.POST_DESCRIPTION_MIN_LENGTH;

  const onReadMore = () => {
    if (!loggedIn) {
      _showLoginPrompt();
      return;
    }
    if (isShared && description?.length > LIMIT) {
      history.push(`${btoa(identifier)}/${POST_SK}`);
      return;
    }
    setReadMore(!readMore);
  };

  return (
    <CardContent className={isShared ? classes.content2 : classes.content}>
      {isNonEmptyString(description) ? (
        <div className={styles["pli-description"]}>
          {description?.length > LIMIT && mode !== PLI_DETAIL_MODE_LK ? (
            <>
              {/* <StyledInput
                value={
                  readMore
                    ? `${description}`
                    : `${description.slice(0, LIMIT)}...Read More `
                }
                multiline
                readOnly
                aria-label="caption"
                fullWidth
                sx={{
                  cursor: "pointer !important",
                  p: "0 !important",
                }}
              /> */}
              <div>
                <p style={{ whiteSpace: "pre-line" }}>
                  {readMore ? (
                    <>
                      {description}

                      <span className="text-blue" onClick={onReadMore}>
                        &nbsp; {translate("common.seeLess")}
                      </span>
                    </>
                  ) : (
                    <>
                      {description.slice(0, LIMIT)}

                      <span className="text-blue" onClick={onReadMore}>
                        &nbsp;...{translate("common.readMore")}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </>
          ) : (
            // <StyledInput
            //   value={description}
            //   multiline
            //   readOnly
            //   fullWidth
            //   sx={{ p: '0 !important' }}
            // />
            <div>{description}</div>
          )}
        </div>
      ) : (
        <div className={styles["pli-description"]} />
      )}
    </CardContent>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0 !important",
  },
}));
export default withRouter(DescriptionSection);
