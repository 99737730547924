import { Status } from '../../api';
import {
  FETCH_ATTRACTION_DETAILS_LOADING,
  FETCH_ATTRACTION_DETAILS_SUCCESS,
  FETCH_ATTRACTION_DETAILS_ERROR,
  RESET_FETCH_ATTRACTION_DETAILS,
  FETCH_REVIEWS_LOADING,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_ERROR,
  MORE_FETCH_REVIEWS_LOADING,
  MORE_FETCH_REVIEWS_SUCCESS,
  MORE_FETCH_REVIEWS_ERROR,
  FETCH_ARTICLES_BY_ATTRACTION_LOADING,
  FETCH_ARTICLES_BY_ATTRACTION_SUCCESS,
  FETCH_ARTICLES_BY_ATTRACTION_ERROR,
  FETCH_All_ATTRACTION_FILES_LOADING,
  FETCH_All_ATTRACTION_FILES_SUCCESS,
  FETCH_All_ATTRACTION_FILES_ERROR,
  SHARE_POST_LOADING,
  SHARE_POST_SUCCESS,
  SHARE_POST_ERROR,
  SAVE_FAVOURITE_LOADING,
  SAVE_FAVOURITE_SUCCESS,
  SAVE_FAVOURITE_ERROR,
  DELETE_FAVOURITE_LOADING,
  DELETE_FAVOURITE_SUCCESS,
  DELETE_FAVOURITE_ERROR,

} from '../../constants';

const initialState = {
  attractionDetails: {},
  /**
   * Correspond to first set of attraction
   */
  attractionStatus: Status.DEFAULT,
  attractionErrorMessage: '',
  // ==================================
  recentReviews: [],
  rangeReviews: [],
  reviewsStatus: Status.DEFAULT,
  reviewsErrorMessage: '',
  moreReviewsStatus: Status.DEFAULT,
  moreReviewsErrorMessage: '',
  reviewsAvailableForRefresh: false,
  recentReviewsCacheDirty: false,
  isLoadMoreDisplay: false,
  // ==================================
  articles: [],
  articleStatus: Status.DEFAULT,
  articleErrorMessage: '',
  // ==================================
  attractionFiles: [],
  attractionFilesStatus: Status.DEFAULT,
  attractionFilesErrorMessage: '',
  sharePostStatus: Status.DEFAULT,
  sharePostErrorMessage: '',
  saveFavStatus: Status.DEFAULT,
  saveFavErrorMessage: '',
  deleteFavStatus: Status.DEFAULT,
  deleteFavErrorMessage: '',
  deleteFavSuccessMessage: '',
  favouriteIdentifier: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ATTRACTION_DETAILS_LOADING: {
      return {
        ...state,
        attractionStatus: Status.LOADING,
        attractionErrorMessage: '',
      };
    }
    case FETCH_ATTRACTION_DETAILS_SUCCESS: {
      return {
        ...state,
        attractionStatus: Status.SUCCESS,
        attractionErrorMessage: '',
        attractionDetails: payload,
      };
    }
    case FETCH_ATTRACTION_DETAILS_ERROR: {
      return {
        ...state,
        attractionStatus: Status.ERROR,
        attractionErrorMessage: payload.errorMessage,
      };
    }
    case RESET_FETCH_ATTRACTION_DETAILS: {
      return {
        initialState
      };
    }
    case FETCH_REVIEWS_LOADING: {
      return {
        ...state,
        reviewsStatus: Status.LOADING,
        reviewsErrorMessage: '',
      };
    }
    case FETCH_REVIEWS_SUCCESS: {
      const { reviews, recentMode } = payload;
      const newData = {};
      if (recentMode) {
        newData.recentReviews = reviews;
        newData.recentReviewsCacheDirty = false;
      } else {
        newData.rangeReviews = reviews;
      }
      return {
        ...state,
        reviewsStatus: Status.SUCCESS,
        moreReviewsStatus: Status.DEFAULT,
        reviewsErrorMessage: '',
        ...newData,
        reviewsAvailableForRefresh: reviews.length > 0,
        isLoadMoreDisplay: reviews.length === 10
      };
    }
    case FETCH_REVIEWS_ERROR: {
      return {
        ...state,
        reviewsStatus: Status.ERROR,
        moreReviewsStatus: Status.DEFAULT,
        reviewsErrorMessage: payload.errorMessage,
        reviewsAvailableForRefresh: false,
        isLoadMoreDisplay: false
      };
    }
    case MORE_FETCH_REVIEWS_LOADING: {
      return {
        ...state,
        moreReviewsStatus: Status.LOADING,
        moreReviewsErrorMessage: '',
      };
    }
    case MORE_FETCH_REVIEWS_SUCCESS: {
      const { reviews, recentMode } = payload;
      const newData = {};
      if (recentMode) {
        newData.recentReviews = [...state.recentReviews, ...reviews];
      } else {
        newData.rangeReviews = [...state.rangeReviews, ...reviews];
      }
      return {
        ...state,
        reviewStatus: Status.SUCCESS,
        moreReviewsStatus: Status.SUCCESS,
        moreReviewsErrorMessage: '',
        reviewsAvailableForRefresh: reviews.length > 0,
        isLoadMoreDisplay: reviews.length === 10,
        ...newData,
      };
    }
    case MORE_FETCH_REVIEWS_ERROR: {
      return {
        ...state,
        reviewStatus: Status.SUCCESS,
        moreReviewsStatus: Status.ERROR,
        moreReviewsErrorMessage: payload.errorMessage,
        reviewsAvailableForRefresh: false,
        isLoadMoreDisplay: false
      };
    }
    // eslint-disable-next-line no-duplicate-case
    case RESET_FETCH_ATTRACTION_DETAILS: {
      return {
        initialState
      };
    }
    case FETCH_ARTICLES_BY_ATTRACTION_LOADING: {
      return {
        ...state,
        articleStatus: Status.LOADING,
        articleErrorMessage: '',
      };
    }
    case FETCH_ARTICLES_BY_ATTRACTION_SUCCESS: {
      return {
        ...state,
        articleStatus: Status.SUCCESS,
        articleErrorMessage: '',
        articles: payload,
      };
    }
    case FETCH_ARTICLES_BY_ATTRACTION_ERROR: {
      return {
        ...state,
        articleStatus: Status.ERROR,
        articleErrorMessage: payload.errorMessage,
      };
    }
    case FETCH_All_ATTRACTION_FILES_LOADING: {
      return {
        ...state,
        attractionFilesStatus: Status.LOADING,
        attractionFilesErrorMessage: '',
      };
    }
    case FETCH_All_ATTRACTION_FILES_SUCCESS: {
      return {
        ...state,
        attractionFilesStatus: Status.SUCCESS,
        attractionFilesErrorMessage: '',
        attractionFiles: payload,
      };
    }
    case FETCH_All_ATTRACTION_FILES_ERROR: {
      return {
        ...state,
        attractionFilesStatus: Status.ERROR,
        attractionFilesErrorMessage: payload.errorMessage,
      };
    }
    case SHARE_POST_LOADING: {
      return {
        ...state,
        sharePostStatus: Status.LOADING
      };
    }
    case SHARE_POST_SUCCESS: {
      return {
        ...state,
        sharePostStatus: Status.SUCCESS
      };
    }
    case SHARE_POST_ERROR: {
      return {
        ...state,
        sharePostStatus: Status.ERROR,
        sharePostErrorMessage: payload.errorMessage
      };
    }
    case SAVE_FAVOURITE_LOADING: {
      return {
        ...state,
        saveFavStatus: Status.LOADING
      };
    }
    case SAVE_FAVOURITE_SUCCESS: {
      return {
        ...state,
        saveFavStatus: Status.SUCCESS,
        attractionDetails: payload.attractionDetails
      };
    }
    case SAVE_FAVOURITE_ERROR: {
      return {
        ...state,
        saveFavStatus: Status.ERROR,
        saveFavErrorMessage: payload.errorMessage
      };
    }
    case DELETE_FAVOURITE_LOADING: {
      return {
        ...state,
        deleteFavStatus: Status.LOADING
      };
    }
    case DELETE_FAVOURITE_SUCCESS: {
      return {
        ...state,
        attractionDetails: payload.attractionDetails,
        deleteFavStatus: Status.SUCCESS,
        deleFavSuccessMessage: payload.status
      };
    }
    case DELETE_FAVOURITE_ERROR: {
      return {
        ...state,
        deleteFavStatus: Status.ERROR,
        deleteFavErrorMessage: payload.errorMessage
      };
    }
    default:
      return state;
  }
};
