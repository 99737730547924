import React from 'react';
// import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import {
  ARTICLE_SK,
  //PLI_HOME_MODE_LK,
  //PLI_HISTORY_MODE_LK,
  //PLI_DETAIL_MODE_LK,
  //PLI_PROFILE_MODE_LK,
  ATTRACTION_SK,
} from '../../constants/stringConstants';
import { parseMedia } from './MediaOperationUtils';
import {
  //articleType, 
  isNonEmptyString,
  //postType 
} from '../../utils';
import { translate } from '../../i18n';
import HeaderSection from './HeaderSection';
import MediaGrid from '../MediaGrid/MediaGrid';
import PostDescription from '../../common/PostListItem/DescriptionSection';
import {
  Article,
  Attraction,
} from '../../components/HomeStack/Home/components';
import styles from './BodySection.module.scss';
// const propTypes = {
//   item: PropTypes.oneOfType([postType, articleType]).isRequired,
//   loggedInUserIdentifier: PropTypes.string.isRequired,
//   postOwnerIdentifier: PropTypes.string.isRequired,
//   profileScreenIdentifier: PropTypes.string.isRequired,
//   baseMediaUrl: PropTypes.string,
//   baseMediaThumbUrl: PropTypes.string,
//   mode: PropTypes.oneOf([
//     PLI_HOME_MODE_LK,
//     PLI_HISTORY_MODE_LK,
//     PLI_DETAIL_MODE_LK,
//     PLI_PROFILE_MODE_LK,
//   ]).isRequired,
//   detailModeOperations: PropTypes.shape({
//     updateReactionInPreviousScreen: PropTypes.func,
//     updateLikesCountInPreviousScreen: PropTypes.func,
//     updateFollowStatusInPreviousScreen: PropTypes.func,
//     updateShareCountInPreviousScreen: PropTypes.func,
//     updateDescriptionAndVisibilityInPreviousScreen: PropTypes.func,
//   }),
//   changeCommentsCount: PropTypes.func,
//   updateStatusInList: PropTypes.func,
// };

// const defaultProps = {
//   baseMediaUrl: '',
//   baseMediaThumbUrl: '',
//   changeCommentsCount: () => { },
//   updateStatusInList: () => { },
// };

const BodySection = ({
  item,
  /**
   * User id of the logged in user
   */
  loggedInUserIdentifier,
  /**
   * User Id of the post creator
   */
  //postOwnerIdentifier,
  /**
   * If PostListItem is being rendered in Friends/Business profile screen
   * the identifier that points to the profile in it.
   */
  profileScreenIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  /**
   * Mode tells in which screen PostListItem component is used.
   *
   * It could be
   * 1. Home screen
   * 2. History screen
   * 3. Post Detail screen aka CommentListScreen
   * 4. Friends Profile screen
   * 5. Business profile Screen
   */
  mode,
  //detailModeOperations,
  //changeCommentsCount,
  updateStatusInList,
  reloadProfileScreen,
  showLoginPrompt: _showLoginPrompt,
}) => {
  let {
    identifier,
    isShared,
    userDetails,
    parentDetails,
    description,
    mediaUrl = [],
  } = item;

  if (isShared && item.parentDetails !== null) {
    // In case of a share, we will show parent's post/article media, not child's media
    mediaUrl = parentDetails.mediaUrl || [];
  }

  // create media = [{source: {uri: base + filePath}, fileType : 'image/video', caption: string},]
  const media = parseMedia(mediaUrl, baseMediaUrl, baseMediaThumbUrl);

  const renderParentPost = () => {
    if (parentDetails === null) {
      return (
        <Card>
          <span>{translate('homeScreen.originalPostDeletedMessage')}</span>
        </Card>
      );
    }
    let createdAt = '';
    let sharedType = '';
    let userDetails = {};
    let location = '';
    let visibility = '';
    let sharedIdentifier = '';
    // In case of a share, title & description will be shown of parent post/article
    //title = parentDetails.title;
    sharedIdentifier = parentDetails.identifier;
    description = parentDetails.description;
    createdAt = parentDetails.createdAt;
    sharedType = parentDetails.type;
    location = parentDetails.locationAddress;
    visibility = parentDetails.visibility;
    if (parentDetails.userDetails) {
      userDetails = parentDetails.userDetails;
    }
    if (sharedType === ARTICLE_SK) {
      return (
        <Article
          isShared={true}
          item={parentDetails}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          loggedInUserIdentifier={loggedInUserIdentifier}
          showLoginPrompt={_showLoginPrompt}
        />
      );
    }
    if (sharedType === ATTRACTION_SK) {
      return (
        <Attraction
          isShared={true}
          item={parentDetails}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          loggedInUserIdentifier={loggedInUserIdentifier}
          showLoginPrompt={_showLoginPrompt}
        />
      );
    } else {
      return (
        <Card className={styles.parentRoot}>
          <HeaderSection
            mode={mode}
            isShared={true}
            time={createdAt}
            type={sharedType}
            visibility={visibility}
            location={location}
            userDetails={userDetails}
            loggedInUserIdentifier={loggedInUserIdentifier}
            profileScreenIdentifier={profileScreenIdentifier}
            profileImgUrl={
              userDetails.profileImg?.isThumbGenerated
                ? userDetails.profileImg?.url?.thumbUrl
                : userDetails.profileImg?.url?.originalUrl
            }
            updateStatusInList={updateStatusInList}
            showLoginPrompt={_showLoginPrompt}
            reloadProfileScreen={reloadProfileScreen}
          />
          {media.length !== 0 && (
            <MediaGrid
              userDetails={userDetails}
              media={media}
              showLoginPrompt={_showLoginPrompt}
            />
          )}
          <PostDescription
            mode={mode}
            identifier={sharedIdentifier}
            isShared={true}
            isContainedMedia={media.length > 0}
            description={description}
            loggedIn={isNonEmptyString(loggedInUserIdentifier)}
            showLoginPrompt={_showLoginPrompt}
          />
        </Card>
      );
    }
  };

  return (
    <>
      {/* If shared, then child post description will render at top, else at bottom */}
      {isShared && description !== '' && (
        <PostDescription
          mode={mode}
          identifier={identifier}
          description={description}
          loggedIn={isNonEmptyString(loggedInUserIdentifier)}
          showLoginPrompt={_showLoginPrompt}
        />
      )}
      {isShared ? (
        renderParentPost()
      ) : (
        <>
          {media.length !== 0 && (
            <MediaGrid userDetails={userDetails} media={media} />
          )}
          <PostDescription
            mode={mode}
            identifier={identifier}
            description={description}
            loggedIn={isNonEmptyString(loggedInUserIdentifier)}
            showLoginPrompt={_showLoginPrompt}
          />
        </>
      )}
    </>
  );
};

// BodySection.propTypes = propTypes;

// BodySection.defaultProps = defaultProps;

export default BodySection;
