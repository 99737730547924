import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import { translate } from "../../../i18n";
import styles from "../AdvancedSearch.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      //width: 100,
    },
  },
};

const FilterFormControl = ({
  value,
  label,
  menuList,
  handleChange,
  disabled = false,
}) => {
  return (
    <FormControl variant="standard" fullWidth disabled={disabled}>
      <Select
        value={value}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (selected === -1) {
            return (
              <Typography id="listing-filter-placeholder">{label}</Typography>
            );
          }
          return (
            <Typography id={styles["listing-filter-menu-item"]}>
              {selected}
            </Typography>
          );
        }}
      >
        <MenuItem value={-1} id={styles["listing-filter-menu-item"]}>
          <em>{translate("advancedSearch.none")}</em>
        </MenuItem>
        {menuList.map((item, key) => (
          <MenuItem
            key={key}
            value={item}
            id={styles["listing-filter-menu-item"]}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterFormControl;
