import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { translate } from '../../../../i18n';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CURRENT_USER_LK,
  PRIVATE_SK,
  SERVER_ERROR_CODES,
  VIDEO_SK,
} from '../../../../constants';
import { api, Status } from '../../../../api';
import Grid from '@mui/material/Grid';
import videoControlIcon from '../../../../assets/images/icon_video_control.png';
import MediaViewer from '../../../../common/MediaViewer/MediaViewer';
import styles from './VideosGrid.module.scss';

const VideosGrid = ({
  identifier,
  entityType,
  subType,
  profileType,
  baseMediaUrl,
  profileImgUrl,
  userRole,
  businessName,
  isPrivate: _isPrivate,
}) => {
  const [page, setPage] = useState(1);
  const [contentAvailableForRefresh, setContentAvailableForRefresh] = useState(
    _isPrivate !== PRIVATE_SK
  );
  const [videos, setVideos] = useState([]);
  const [videoApiStatus, setVideoApiStatus] = useState(Status.DEFAULT);
  const [mediaViewerState, setMediaViewerState] = useState({
    selected: 0,
    isOpen: false,
  });
  const [isPrivate, setIsPrivate] = useState(_isPrivate === PRIVATE_SK);

  useEffect(() => {
    if (!isPrivate || profileType === CURRENT_USER_LK) {
      getVideos(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const getVideos = (page = 1) => {
    if (videoApiStatus !== Status.LOADING) {
      setVideoApiStatus(Status.LOADING);
      api
        .fetchMedia({
          mediaType: VIDEO_SK,
          entityType: entityType,
          entityIdentifier: identifier,
          page,
        })
        .then(({ userResponse: { video: newVideos } }) => {
          setVideoApiStatus(Status.SUCCESS);
          if (newVideos.length < 10) {
            setContentAvailableForRefresh(false);
          }
          let _videos = getMediaWithLayout(newVideos);
          let allVideos = [...videos, ..._videos];
          setVideos(allVideos);
        })
        .catch((error) => {
          setVideoApiStatus(Status.ERROR);
          if (error.name === SERVER_ERROR_CODES.featurePrivate) {
            setIsPrivate(true);
            setContentAvailableForRefresh(false);
          }
        });
    }
  };

  const viewVideo = (selected) => {
    setMediaViewerState({
      selected,
      isOpen: true,
    });
  };

  const closeMediaViewer = () => {
    setMediaViewerState({
      selected: 0,
      isOpen: false,
    });
  };

  const getMediaWithLayout = (medias) => {
    medias.forEach((media, key) => {
      if ((key + 1) % 3 === 0) {
        media.cols = 12;
      } else {
        media.cols = 6;
      }
    });
    return medias;
  };

  return (
    <div className={styles['videos-grid-container']}>
      <InfiniteScroll
        dataLength={videos.length} //This is important field to render the next data
        next={() => setPage(page + 1)}
        hasMore={contentAvailableForRefresh}
        loader={
          <div className={styles.spinner}>
            <CircularProgress size={22} className="loading_spinner" />
          </div>
        }
        endMessage={
          <div className={styles['nomore-data-info']}>
            {videos.length === 0 && videoApiStatus === Status.SUCCESS ? (
              <span>{translate('businessProfile.noVideo')}</span>
            ) : isPrivate && profileType !== CURRENT_USER_LK ? (
              <span>{translate('userProfile.privateVideos')}</span>
            ) : (
              <span></span>
            )}
          </div>
        }
      >
        <div className={styles['videos-grid']}>
          <Grid container columnSpacing={1}>
            {videos.map((item, key) => (
              <Grid item xs={item.cols} key={key}>
                <div
                  className={styles['video-item']}
                  onClick={() => viewVideo(key)}
                >
                  <video src={item.filePath.originalUrl} />
                  <img alt="img" src={videoControlIcon} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </InfiniteScroll>
      <MediaViewer
        open={mediaViewerState.isOpen}
        type={VIDEO_SK}
        entityType={subType}
        mediaList={videos}
        selected={mediaViewerState.selected}
        baseMediaUrl={baseMediaUrl}
        profileImgUrl={profileImgUrl}
        userRole={userRole}
        userName={businessName}
        close={closeMediaViewer}
      />
    </div>
  );
};

export default VideosGrid;
