// ============================================================
// ======================= App level ==========================
// ============================================================
export const INITIALIZE_APP_REQUEST = "INITIALIZE_APP_REQUEST";
export const INITIALIZE_APP_LOADING = "INITIALIZE_APP_LOADING";
export const INITIALIZE_APP_SUCCESS = "INITIALIZE_APP_SUCCESS";
export const INITIALIZE_APP_ERROR = "INITIALIZE_APP_ERROR";
export const CONFIG_REQUEST = "CONFIG_REQUEST";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const CONFIG_SUCCESS = "CONFIG_SUCCESS";
export const CONFIG_ERROR = "CONFIG_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const SHUFFLE_IDS = "SHUFFLE_IDS";
export const SAVE_FCM_TOKEN_REQUEST = "SAVE_FCM_TOKEN_REQUEST";
export const SAVE_FCM_TOKEN_LOADING = "SAVE_FCM_TOKEN_REQUEST_LOADING";
export const SAVE_FCM_TOKEN_SUCCESS = "SAVE_FCM_TOKEN_REQUEST_SUCCESS";
export const SAVE_FCM_TOKEN_ERROR = "SAVE_FCM_TOKEN_ERROR";
export const NETWORK_CONNECTION = "NETWORK_CONNECTION";
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
export const RESET_REMOTE_NOTIFICATION = 'RESET_REMOTE_NOTIFICATION';
// ============================================================
// ===================== Home =================================
// ============================================================
export const FETCH_BANNER_IMAGES_REQUEST = "FETCH_BANNER_IMAGES_REQUEST";
export const FETCH_BANNER_IMAGES_LOADING = "FETCH_BANNER_IMAGES_LOADING";
export const FETCH_BANNER_IMAGES_SUCCESS = "FETCH_BANNER_IMAGES_SUCCESS";
export const FETCH_BANNER_IMAGES_ERROR = "FETCH_BANNER_IMAGES_ERROR";
export const FETCH_HOME_PAGE_DATA_REQUEST = "FETCH_HOME_PAGE_DATA_REQUEST";
export const FETCH_HOME_PAGE_DATA_LOADING = "FETCH_HOME_PAGE_DATA_LOADING";
export const FETCH_HOME_PAGE_DATA_SUCCESS = "FETCH_HOME_PAGE_DATA_SUCCESS";
export const FETCH_HOME_PAGE_DATA_ERROR = "FETCH_HOME_PAGE_DATA_ERROR";
export const FETCH_MORE_HOME_PAGE_DATA_LOADING =
  "FETCH_MORE_HOME_PAGE_DATA_LOADING";
export const FETCH_MORE_HOME_PAGE_DATA_SUCCESS =
  "FETCH_MORE_HOME_PAGE_DATA_SUCCESS";
export const FETCH_MORE_HOME_PAGE_DATA_ERROR =
  "FETCH_MORE_HOME_PAGE_DATA_ERROR";
export const RESET_HOME_SCREEN_DATA = "RESET_HOME_SCREEN_DATA";
export const SHARE_POST_REQUEST = "SHARE_POST_REQUEST";
export const SHARE_POST_SUCCESS = "SHARE_POST_SUCCESS";
export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_HOME_POST_REQUEST = "DELETE_HOME_POST_REQUEST";
export const DELETE_HOME_POST_SUCCESS = "DELETE_HOME_POST_SUCCESS";
export const REPORT_POST_REQUEST = "REPORT_POST_REQUEST";
export const REPORT_POST_SUCCESS = "REPORT_POST_SUCCESS";
export const UPDATE_USER_FOLLOW_STATUS = "UPDATE_USER_FOLLOW_STATUS";
export const UPDATE_USER_FOLLOW_STATUS_SUCCESS =
  "UPDATE_USER_FOLLOW_STATUS_SUCCESS";
export const UPDATE_BLOGGER_NETWORK_STATUS = "UPDATE_BLOGGER_NETWORK_STATUS";
export const UPDATE_BLOGGER_NETWORK_STATUS_SUCCESS =
  "UPDATE_BLOGGER_NETWORK_STATUS_SUCCESS";
export const SAVE_FAVOURITE_PLI_REQUEST = "SAVE_FAVOURITE_PLI_REQUEST";
export const SAVE_FAVOURITE_PLI_LOADING = "SAVE_FAVOURITE_PLI_LOADING";
export const SAVE_FAVOURITE_PLI_SUCCESS = "SAVE_FAVOURITE_PLI_SUCCESS";
export const SAVE_FAVOURITE_PLI_ERROR = "SAVE_FAVOURITE_PLI_ERROR";

export const DELETE_FAVOURITE_PLI_REQUEST = "DELETE_FAVOURITE_PLI_REQUEST";
export const DELETE_FAVOURITE_PLI_LOADING = "DELETE_FAVOURITE_PLI_LOADING";
export const DELETE_FAVOURITE_PLI_SUCCESS = "DELETE_FAVOURITE_PLI_SUCCESS";
export const DELETE_FAVOURITE_PLI_ERROR = "DELETE_FAVOURITE_PLI_ERROR";


export const UPDATE_SHARE_COUNT = "UPDATE_SHARE_COUNT";
export const UPDATE_SHARE_COUNT_SUCCESS = "UPDATE_SHARE_COUNT_SUCCESS";
export const RESET_SHARE_POST_STATUS = "RESET_SHARE_POST_STATUS";
export const RESET_DELETE_POST_STATUS = "RESET_DELETE_POST_STATUS";
export const RESET_REPORT_POST_STATUS = "RESET_REPORT_POST_STATUS";
export const UPDATE_VIEW_COUNT = "UPDATE_VIEW_COUNT";
export const UPDATE_VIEW_COUNT_SUCCESS = "UPDATE_VIEW_COUNT_SUCCESS";
export const RESET_HOME_PAGE_DATA_STATUS = "RESET_HOME_PAGE_DATA_STATUS";
export const RESET_FAVOURITE_STATUS = "RESET_FAVOURITE_STATUS";
export const UPDATE_HOME_PAGE_DATA = "UPDATE_HOME_PAGE_DATA";
// ============================================================
// ================= ArticleList ==============================
// ============================================================
export const FETCH_ARTICLES_REQUEST = "FETCH_ARTICLES_REQUEST";
export const FETCH_ARTICLES_LOADING = "FETCH_ARTICLES_LOADING";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_ERROR = "FETCH_ARTICLES_ERROR";
export const FETCH_MORE_ARTICLES_LOADING = "FETCH_MORE_ARTICLES_LOADING";
export const FETCH_MORE_ARTICLES_SUCCESS = "FETCH_MORE_ARTICLES_SUCCESS";
export const FETCH_MORE_ARTICLES_ERROR = "FETCH_MORE_ARTICLES_ERROR";
// ============================================================
// ================= BusinessContractList =====================
// ============================================================
export const FETCH_CONTRACTS_REQUEST = "FETCH_CONTRACTS_REQUEST";
export const FETCH_CONTRACTS_LOADING = "FETCH_CONTRACTS_LOADING";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const FETCH_CONTRACTS_ERROR = "FETCH_CONTRACTS_ERROR";
export const FETCH_MORE_CONTRACTS_LOADING = "FETCH_MORE_CONTRACTS_LOADING";
export const FETCH_MORE_CONTRACTS_SUCCESS = "FETCH_MORE_CONTRACTS_SUCCESS";
export const FETCH_MORE_CONTRACTS_ERROR = "FETCH_MORE_ACONTRACTS_ERROR";
// ============================================================
// ================= BloggerList ==============================
// ============================================================
export const FETCH_BLOGGERS_REQUEST = "FETCH_BLOGGERS_REQUEST";
export const FETCH_BLOGGERS_LOADING = "FETCH_BLOGGERS_LOADING";
export const FETCH_BLOGGERS_SUCCESS = "FETCH_BLOGGERS_SUCCESS";
export const FETCH_BLOGGERS_ERROR = "FETCH_BLOGGERS_ERROR";
export const FETCH_MORE_BLOGGERS_LOADING = "FETCH_MORE_BLOGGERS_LOADING";
export const FETCH_MORE_BLOGGERS_SUCCESS = "FETCH_MORE_BLOGGERS_SUCCESS";
export const FETCH_MORE_BLOGGERS_ERROR = "FETCH_MORE_BLOGGERS_ERROR";
// ============================================================
// ================= ShalgoerServices =========================
// ============================================================
export const FETCH_WEATHER_REQUEST = "FETCH_WEATHER_REQUEST";
export const FETCH_WEATHER_LOADING = "FETCH_WEATHER_LOADING";
export const FETCH_WEATHER_SUCCESS = "FETCH_WEATHER_SUCCESS";
export const FETCH_WEATHER_ERROR = "FETCH_WEATHER_ERROR";
export const FETCH_EXCHANGE_RATE_REQUEST = "FETCH_EXCHANGE_RATE_REQUEST";
export const FETCH_EXCHANGE_RATE_LOADING = "FETCH_EXCHANGE_RATE_LOADING";
export const FETCH_EXCHANGE_RATE_SUCCESS = "FETCH_EXCHANGE_RATE_SUCCESS";
export const FETCH_EXCHANGE_RATE_ERROR = "FETCH_EXCHANGE_RATE_ERROR";
export const FETCH_HIGHWAY_CONTACT_REQUEST = "FETCH_HIGHWAY_CONTACT_REQUEST";
export const FETCH_HIGHWAY_CONTACT_LOADING = "FETCH_HIGHWAY_CONTACT_LOADING";
export const FETCH_HIGHWAY_CONTACT_SUCCESS = "FETCH_HIGHWAY_CONTACT_SUCCESS";
export const FETCH_HIGHWAY_CONTACT_ERROR = "FETCH_HIGHWAY_CONTACT_ERROR";
export const FETCH_SOURCE_REQUEST = "FETCH_SOURCE_REQUEST";
export const FETCH_SOURCE_LOADING = "FETCH_SOURCE_LOADING";
export const FETCH_SOURCE_SUCCESS = "FETCH_SOURCE_SUCCESS";
export const FETCH_SOURCE_ERROR = "FETCH_SOURCE_ERROR";
export const FETCH_DESTINATION_REQUEST = "FETCH_DESTINATION_REQUEST";
export const FETCH_DESTINATION_LOADING = "FETCH_DESTINATION_LOADING";
export const FETCH_DESTINATION_SUCCESS = "FETCH_DESTINATION_SUCCESS";
export const FETCH_DESTINATION_ERROR = "FETCH_DESTINATION_ERROR";
export const FETCH_ROUTE_REQUEST = "FETCH_ROUTE_REQUEST";
export const FETCH_ROUTE_LOADING = "FETCH_ROUTE_LOADING";
export const FETCH_ROUTE_SUCCESS = "FETCH_ROUTE_SUCCESS";
export const FETCH_ROUTE_ERROR = "FETCH_ROUTE_ERROR";
export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_LOADING = "FETCH_SERVICES_LOADING";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_ERROR = "FETCH_SERVICES_ERROR";
export const FETCH_CALCULATIONS_REQUEST = "FETCH_CALCULATIONS_REQUEST";
export const FETCH_CALCULATIONS_LOADING = "FETCH_CALCULATIONS_LOADING";
export const FETCH_CALCULATIONS_SUCCESS = "FETCH_CALCULATIONS_SUCCESS";
export const FETCH_CALCULATIONS_ERROR = "FETCH_CALCULATIONS_ERROR";
export const RESET_DATA = "RESET_DATA";
// ============================================================
// ================= Trip budget screenshot ===================
// ============================================================
export const SCREENSHOT_LOADING = "SCREENSHOT_LOADING";
export const SCREENSHOT_SUCCESS = "SCREENSHOT_SUCCESS";
// ============================================================
// ================= SignUp ===================================
// ============================================================
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const RESET_SIGNUP_STATE = "RESET_SIGNUP_STATE";
// ============================================================
// =================== OtpVerification ========================
// ============================================================
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_LOADING = "RESEND_OTP_LOADING";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";
export const RESET_OTP_VERIFICATION_STATE = "RESET_OTP_VERIFICATION_STATE";
// ============================================================
// ================== ProfileScreen ===========================
// ============================================================
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_LOADING = "GET_USER_DETAILS_LOADING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const FETCH_FAVOURITES_REQUEST = "FETCH_FAVOURITES_REQUEST";
export const FETCH_FAVOURITES_LOADING = "FETCH_FAVOURITES_LOADING";
export const FETCH_FAVOURITES_SUCCESS = "FETCH_FAVOURITES_SUCCESS";
export const FETCH_FAVOURITES_ERROR = "FETCH_FAVOURITES_ERROR";
export const UPDATE_FAVOURITES = "UPDATE_FAVOURITES";
export const UPDATE_FAVOURITES_SUCCESS = "UPDATE_FAVOURITES_SUCCESS";
// ============================================================
// ======================= LoginScreen ========================
// ============================================================
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";
// ============================================================
// ================== ForgetPasswordScreen ====================
// ============================================================
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_LOADING = "FORGET_PASSWORD_LOADING";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
export const RESET_FORGET_PASSWORD_STATE = "RESET_FORGET_PASSWORD_STATE";
export const SAVE_OTP_RECEIVE_TYPE = "SAVE_OTP_RECEIVE_TYPE";
// ============================================================
// ================== ResetPasswordScreen =====================
// ============================================================
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD_STATE = "RESET_CHANGE_PASSWORD_STATE";
export const SAVE_OTP = "SAVE_OTP";
// ==============================================================
// =================Initialize Interest  ========================
// ==============================================================
export const INITIAlIZE_INTEREST_REQUEST = "INITIAlIZE_INTEREST_REQUEST";
export const INITIAlIZE_INTEREST_LOADING = "INITIAlIZE_INTEREST_LOADING";
export const INITIAlIZE_INTEREST_SUCCESS = "INITIAlIZE_INTEREST_SUCCESS";
export const INITIAlIZE_INTEREST_ERROR = "INITIAlIZE_INTEREST_ERROR";
// ==============================================================
// ================ edit profile  ===============================
// ==============================================================
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_LOADING = "EDIT_PROFILE_LOADING";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const RESET_EDIT_PROFILE_STATE = "RESET_EDIT_PROFILE_STATE";
export const RESET_INCOMPLETE_USER_DETAILS_STATE = "RESET_INCOMPLETE_USER_DETAILS_STATE";
// ============================================================
// ================= AttractionDetailsScreen ====================
// ==============================================================
export const FETCH_ATTRACTION_DETAILS_REQUEST =
  "FETCH_ATTRACTION_DETAILS_REQUEST";
export const FETCH_ATTRACTION_DETAILS_LOADING =
  "FETCH_ATTRACTION_DETAILS_LOADING";
export const FETCH_ATTRACTION_DETAILS_SUCCESS =
  "FETCH_ATTRACTION_DETAILS_SUCCESS";
export const FETCH_ATTRACTION_DETAILS_ERROR = "FETCH_ATTRACTION_DETAILS_ERROR";
export const RESET_FETCH_ATTRACTION_DETAILS = "RESET_FETCH_ATTRACTION_DETAILS";
// ============================================================
// ================= Fetch User Review ========================
// ============================================================
export const FETCH_REVIEWS_REQUEST = "FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_LOADING = "FETCH_REVIEWS_LOADING";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_ERROR = "FETCH_REVIEWS_ERROR";
export const MORE_FETCH_REVIEWS_LOADING = "FETCH_USER_REVIEWS_LOADING";
export const MORE_FETCH_REVIEWS_SUCCESS = "FETCH_USER_REVIEWS_SUCCESS";
export const MORE_FETCH_REVIEWS_ERROR = "FETCH_USER_REVIEWS_ERROR";
// ============================================================
// ================= Fetch Articles By Attraction =============
// ============================================================
export const FETCH_ARTICLES_BY_ATTRACTION_REQUEST =
  "FETCH_ARTICLES_BY_ATTRACTION_REQUEST";
export const FETCH_ARTICLES_BY_ATTRACTION_LOADING =
  "FETCH_ARTICLES_BY_ATTRACTION_LOADING";
export const FETCH_ARTICLES_BY_ATTRACTION_SUCCESS =
  "FETCH_ARTICLES_BY_ATTRACTION_SUCCESS";
export const FETCH_ARTICLES_BY_ATTRACTION_ERROR =
  "FETCH_ARTICLES_BY_ATTRACTION_ERROR";
// ============================================================
// ================= Fetch All Attraction Files ===============
// ============================================================
export const FETCH_All_ATTRACTION_FILES_REQUEST =
  "FETCH_All_ATTRACTION_FILES_REQUEST";
export const FETCH_All_ATTRACTION_FILES_LOADING =
  "FETCH_All_ATTRACTION_FILES_LOADING";
export const FETCH_All_ATTRACTION_FILES_SUCCESS =
  "FETCH_All_ATTRACTION_FILES_SUCCESS";
export const FETCH_All_ATTRACTION_FILES_ERROR =
  "FETCH_All_ATTRACTION_FILES_ERROR";
// ============================================================
// ================= Share Entity =============================
// ============================================================
export const SHARE_POST_LOADING = "SHARE_POST_LOADING";
export const SHARE_POST_ERROR = "SHARE_POST_ERROR";
// ============================================================
// ================= Save Favourite ===========================
// ============================================================
export const SAVE_FAVOURITE_REQUEST = "SAVE_FAVOURITE_REQUEST";
export const SAVE_FAVOURITE_SUCCESS = "SAVE_FAVOURITE_SUCCESS";
export const SAVE_FAVOURITE_LOADING = "SAVE_FAVOURITE_LOADING";
export const SAVE_FAVOURITE_ERROR = "SAVE_FAVOURITE_ERROR";
// ============================================================
// ================= Delete Favourite =========================
// ============================================================
export const DELETE_FAVOURITE_REQUEST = "DELETE_FAVOURITE_REQUEST";
export const DELETE_FAVOURITE_SUCCESS = "DELETE_FAVOURITE_SUCCESS";
export const DELETE_FAVOURITE_LOADING = "DELETE_FAVOURITE_LOADING";
export const DELETE_FAVOURITE_ERROR = "DELETE_FAVOURITE_ERROR";
// ============================================================
// ================= Business Details =========================
// ============================================================
export const FETCH_BUSINESS_DETAILS_REQUEST = "FETCH_BUSINESS_DETAILS_REQUEST";
export const FETCH_BUSINESS_DETAILS_LOADING = "FETCH_BUSINESS_DETAILS_LOADING";
export const FETCH_BUSINESS_DETAILS_SUCCESS = "FETCH_BUSINESS_DETAILS_SUCCESS";
export const FETCH_BUSINESS_DETAILS_ERROR = "FETCH_BUSINESS_DETAILS_ERROR";
// ============================================================
// ================= User Profile =============================
// ============================================================
export const UPLOAD_PROFILE_IMAGE_REQUEST = "UPLOAD_PROFILE_IMAGE_REQUEST";
export const UPLOAD_PROFILE_IMAGE_ERROR = "UPLOAD_PROFILE_IMAGE_ERROR";
export const UPLOAD_PROFILE_IMAGE_SUCCESS = "UPLOAD_PROFILE_IMAGE_SUCCESS";
export const UPLOAD_PROFILE_IMAGE_LOADING = "UPLOAD_PROFILE_IMAGE_LOADING";
export const UPLOAD_COVER_IMAGE_REQUEST = "UPLOAD_COVER_IMAGE_REQUEST";
export const UPLOAD_COVER_IMAGE_ERROR = "UPLOAD_COVER_IMAGE_ERROR";
export const UPLOAD_COVER_IMAGE_SUCCESS = "UPLOAD_COVER_IMAGE_SUCCESS";
export const UPLOAD_COVER_IMAGE_LOADING = "UPLOAD_COVER_IMAGE_LOADING";
export const RESET_UPLOAD_PROFILE_IMAGE = "RESET_UPLOAD_PROFILE_IMAGE";
// ============================================================
// ================= User Profile =============================
// ============================================================
export const UPDATE_PHOTO = "UPDATE_PHOTO";
export const FETCH_USER_NETWORK_REQUEST = "FETCH_USER_NETWORK_REQUEST";
export const FETCH_USER_FRIENDS_LOADING = "FETCH_USER_FRIENDS_LOADING";
export const FETCH_USER_FRIENDS_SUCCESS = "FETCH_USER_FRIENDS_SUCCESS";
export const FETCH_USER_FRIENDS_ERROR = "FETCH_USER_FRIENDS_ERROR";
export const FETCH_MORE_USER_FRIENDS_LOADING =
  "FETCH_MORE_USER_FRIENDS_LOADING";
export const FETCH_MORE_USER_FRIENDS_SUCCESS =
  "FETCH_MORE_USER_FRIENDS_SUCCESS";
export const FETCH_MORE_USER_FRIENDS_ERROR = "FETCH_MORE_USER_FRIENDS_ERROR";
export const FETCH_USER_REQUESTS_LOADING = "FETCH_USER_REQUESTS_LOADING";
export const FETCH_USER_REQUESTS_SUCCESS = "FETCH_USER_REQUESTS_SUCCESS";
export const FETCH_USER_REQUESTS_ERROR = "FETCH_USER_REQUESTS_ERROR";
export const FETCH_MORE_USER_REQUESTS_LOADING =
  "FETCH_MORE_USER_REQUESTS_LOADING";
export const FETCH_MORE_USER_REQUESTS_SUCCESS =
  "FETCH_MORE_USER_REQUESTS_SUCCESS";
export const FETCH_MORE_USER_REQUESTS_ERROR = "FETCH_MORE_USER_REQUESTS_ERROR";
export const FETCH_USER_INVITES_LOADING = "FETCH_USER_INVITES_LOADING";
export const FETCH_USER_INVITES_SUCCESS = "FETCH_USER_INVITES_SUCCESS";
export const FETCH_USER_INVITES_ERROR = "FETCH_USER_INVITES_ERROR";
export const FETCH_MORE_USER_INVITES_LOADING =
  "FETCH_MORE_USER_INVITES_LOADING";
export const FETCH_MORE_USER_INVITES_SUCCESS =
  "FETCH_MORE_USER_INVITES_SUCCESS";
export const FETCH_MORE_USER_INVITES_ERROR = "FETCH_MORE_USER_INVITES_ERROR";
export const UPDATE_MEMBER_NETWORK_REQUEST = "UPDATE_MEMBER_NETWORK_REQUEST";
export const BLOCKED_LOADING = "BLOCKED_LOADING";
export const BLOCKED_SUCCESS = "BLOCKED_SUCCESS";
export const BLOCKED_ERROR = "BLOCKED_ERROR";
export const UNBLOCKED_LOADING = "UNBLOCKED_LOADING";
export const UNBLOCKED_SUCCESS = "UNBLOCKED_SUCCESS";
export const UNBLOCKED_ERROR = "UNBLOCKED_ERROR";
export const FOLLOWING_LOADING = "FOLLOWING_LOADING";
export const FOLLOWING_SUCCESS = "FOLLOWING_SUCCESS";
export const FOLLOWING_ERROR = "FOLLOWING_ERROR";
export const UNFOLLOWED_LOADING = "UNFOLLOWED_LOADING";
export const UNFOLLOWED_SUCCESS = "UNFOLLOWED_SUCCESS";
export const UNFOLLOWED_ERROR = "UNFOLLOWED_ERROR";
export const UNFRIEND_LOADING = "UNFRIEND_LOADING";
export const UNFRIEND_SUCCESS = "UNFRIEND_SUCCESS";
export const UNFRIEND_ERROR = "UNFRIEND_ERROR";
export const FRIEND_LOADING = "FRIEND_LOADING";
export const FRIEND_SUCCESS = "FRIEND_SUCCESS";
export const FRIEND_ERROR = "FRIEND_ERROR";
export const FRIEND_REQUEST_DELETE_LOADING = "FRIEND_REQUEST_DELETE_LOADING";
export const FRIEND_REQUEST_DELETE_SUCCESS = "FRIEND_REQUEST_DELETE_SUCCESS";
export const FRIEND_REQUEST_DELETE_ERROR = "FRIEND_REQUEST_DELETE_ERROR";
export const FRIEND_REQUEST_PENDING_LOADING = "FRIEND_REQUEST_PENDING_LOADING";
export const FRIEND_REQUEST_PENDING_SUCCESS = "FRIEND_REQUEST_PENDING_SUCCESS";
export const FRIEND_REQUEST_PENDING_ERROR = "FRIEND_REQUEST_PENDING_ERROR";
export const RESET_UPDATE_MEMBER_NETWORK_STATUS =
  "RESET_UPDATE_MEMBER_NETWORK_STATUS";
export const UPDATE_USER_RELATION_STATUS = "UPDATE_USER_RELATION_STATUS";
export const UPDATE_USER_RELATION_STATUS_SUCCESS =
  "UPDATE_USER_RELATION_STATUS_SUCCESS";
export const FRIEND_INVITE_DELETE_LOADING = "FRIEND_INVITE_DELETE_LOADING";
export const FRIEND_INVITE_DELETE_SUCCESS = "FRIEND_INVITE_DELETE_SUCCESS";
export const FRIEND_INVITE_DELETE_ERROR = "FRIEND_INVITE_DELETE_ERROR";
// ============================================================
// ================== AddPostScreen ===========================
// ============================================================
export const ADD_POST_REQUEST = "ADD_POST_REQUEST";
export const ADD_POST_LOADING = "ADD_POST_LOADING";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_ERROR = "ADD_POST_ERROR";
export const RESET_ADD_POST = "RESET_ADD_POST";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
// ============================================================
// ================== Edit Post ===============================
// ============================================================
export const EDIT_POST = "EDIT_POST";
export const RESET_EDIT_POST_STATUS = "RESET_EDIT_POST_STATUS";
// ============================================================
// ================== Add Blog ================================
// ============================================================
export const ADD_BLOG_REQUEST = "ADD_BLOG_REQUEST";
// ============================================================
export const UPDATE_REACTION = "UPDATE_REACTION";
export const UPDATE_REACTION_SUCCESS = "UPDATE_REACTION_SUCCESS";
export const UPDATE_COMMENT_COUNT = "UPDATE_COMMENT_COUNT";
export const UPDATE_COMMENT_COUNT_SUCCESS = "UPDATE_COMMENT_COUNT_SUCCESS";
