import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ARTICLE_SK } from '../../../../constants';
import { translate } from '../../../../i18n';
import MediaView from '../ListItemMediaView/MediaView';
import { ALL_MODE } from '../constants';
import styles from './SearchListItem.module.scss';
const ArticleListItem = ({
  mode,
  item,
  isLoggedIn,
  baseMediaUrl,
  baseMediaThumbUrl,
  showLoginPrompt: _showLoginPrompt,
  history,
  favSaveSuccessCallback,
  favUnSaveSuccessCallback,
}) => {
  const {
    identifier,
    coverImg,
    title,
    shortDescription,
    isSaved,
    favouriteIdentifier,
    avgRating,
    totalViews,
    totalShares,
  } = item;

  const coverImgUrl = coverImg.isThumbGenerated
    ? coverImg.url.thumbUrl
    : coverImg.url.originalUrl;

  const openDetails = () => {
    if (!isLoggedIn) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${btoa(identifier)}/${ARTICLE_SK}`);
  };

  if (mode === ALL_MODE) {
    return (
      <MediaView
        mode={mode}
        identifier={identifier}
        type={ARTICLE_SK}
        isSaved={isSaved}
        favouriteIdentifier={favouriteIdentifier}
        url={coverImgUrl}
        title={title}
        rating={avgRating}
        openDetails={openDetails}
      />
    );
  }

  const renderDetails = () => (
    <CardContent id={styles['filtered-result-details']} onClick={openDetails}>
      <Box>
        <Typography id={styles['filtered-result-title']}>
          {/* {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
            ? title
            : mode === LIST_MODE && title} */}
          {title}
        </Typography>
      </Box>
      <Box id={styles['description-box']}>
        <Typography id={styles['filtered-result-description']}>
          &#8220; {`${shortDescription}`} &#8221;
          <span className={styles['read-more']}>
            <span className="text-blue">...{translate('common.readMore')}</span>
          </span>
        </Typography>
      </Box>
    </CardContent>
  );

  return (
    <Card id={styles['filtered-result-root']}>
      <CardContent>
        <MediaView
          mode={mode}
          identifier={identifier}
          type={ARTICLE_SK}
          isSaved={isSaved}
          favouriteIdentifier={favouriteIdentifier}
          url={coverImgUrl}
          title={title}
          rating={avgRating}
          totalViews={totalViews}
          totalShares={totalShares}
          openDetails={openDetails}
          favSaveSuccessCallback={favSaveSuccessCallback}
          favUnSaveSuccessCallback={favUnSaveSuccessCallback}
        />
      </CardContent>
      {renderDetails()}
    </Card>
  );
};

export default ArticleListItem;
