/**
 * use this file to define validation constants
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  PASSWORD_MAX_LENGTH: 21,
  PASSWORD_MIN_LENGTH: 8,
  EMAIL_MAX_LENGTH: 254,
  PRIMARY_PHONE_MAX_LENGTH: 15,
  PHONE_MAX_LENGTH: 50,
  OTP_MAX_LENGTH: 6,
  SPLASH_SCREEN_WAIT_TIME: 3000, // in milliseconds
  MYANMAR_COUNTRY_CODE_INDEX: 122,
  POST_MAX_LENGTH: 60000,
  SLIDER_MIN_VALUE: 5,
  SLIDER_MAX_VALUE: 25,
  SLIDER_STEP: 5,
  REVIEW_TITLE_MAX_LENGTH: 90,
  REVIEW_DESCRIPTION_MAX_LENGTH: 8000,
  COMMENT_MAX_LENGTH: 8000,
  STATUS_MAX_LENGTH: 155,
  NAME_MIN_LENGTH: 1,
  NAME_MAX_LENGTH: 50,
  CITY_MAX_LENGTH: 30,
  WORK_NAME_MAX_LENGTH: 254,
  EDUCATION_NAME_MAX_LENGTH: 70,
  IMAGE_MAX_SIZE: 10, // In MB
  VIDEO_MAX_SIZE: 150, // In MB
  VIDEO_MAX_DURATION: 5, // In Minutes
  ALBUM_NAME: 25,
  ALBUM_DESC: 50,
  ALBUM_FILE_LIMIT: 100,
  POST_FILE_LIMIT: 10,
  REVIEW_FILE_LIMIT: 5,
  BUSINESS_NAME_MAX_LENGTH: 100,
  BUSINESS_COUNTRY_NAME_MAX_LENGTH: 50,
  BUSINESS_REGION_MAX_LENGTH: 30,
  BUSINESS_STREET_ADDRESS_MAX_LENGTH: 254,
  BUSINESS_ADDITIONAL_ADDRESS_MAX_ELNGTH: 254,
  BUSINESS_ZIP_CODE_MAX_LENGTH: 10,
  AUTO_SUGGESTION_API_TIME_DELAY: 1000, // in milli seconds
  REVIEW_NUM_OF_LINES: 3,
  IMAGE_COMPRESS_QUALITY: 0.5,
  VIDEO_COMPRESS_QUALITY: 'MediumQuality', // MediumQuality, LowQuality, HighestQuality, Passthrough, 640x480, 960x540, 1280x720,
  POLICY_EXPIRY_TIME: 900, // in seconds
  IGNORE_VIDEO_COMPRESS_IF_LESS_THEN: 600, // in pixel
  POST_DESCRIPTION_MIN_LENGTH: 255,
  SHARED_POST_DESCRIPTION_MIN_LENGTH: 155,
  ATTRACTION_DESCRIPTION_MIN_LENGTH: 100,
  ARTICLE_DETAILS_DESCRIPTION_MAX_LENGTH: 255 * 3,
  REVIEW_PREVIEW_MAX_LENGTH: 75,
  PAGE_SIZE: 12,
  REASON_MAX_LENGTH: 2000,
  SAVED_POST_DESCRIPTION_MAX_LENGTH: 120,
};
