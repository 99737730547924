/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import cn from "classnames";

import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Tabs,
  Tab,
  CircularProgress,
  IconButton,
  createTheme,
  ThemeProvider
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { translate, getCurrentLocale } from "../../../../i18n";
import { Status } from "../../../../api";
import {
  extractNumbersFromString,
  getE164Format,
  saveState
} from "../../../../utils";
import {
  BURMESE_LANGUAGE_CODE_LK,
  EMAIL_LK,
  PHONE_NUMBER_LK,
  ASYNC_STORAGE_KEYS,
  MYANMAR_COUNTRY_CODE,
  NEPAL_COUNTRY_CODE
} from "../../../../constants";
import CountryPhoneCodes from "../../../../config/countryPhoneCode";
import { signup, resetSignupState } from "../../../../store/actions";

import SocialLogin from "../../SocialLogin/SocialLogin";
import Recaptcha from "../../../../common/Recaptcha/Recaptcha";

import styles from "../../Authentication.module.scss";
import colors from "../../../../css/variable.scss";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(10px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grayColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
            backgroundColor: "none !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.MuiFormHelperText-root': {
            marginLeft: "0px",
            marginRight: "0px",
          }
        },
      },
    },
  },
});

const propTypes = {
  _signupStatus: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  signup: PropTypes.func.isRequired,
  resetSignupState: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  signupErrorMessage: PropTypes.string,
};

const defaultProps = {
  signupErrorMessage: "",
};

const SignUp = ({
  /**
   * Tells about the status of the signup api call,
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   *
   * @source: redux
   */
  _signupStatus,
  signup: _signup,
  userIdentifier,
  otpExpiryTimeInSecs,
  /***
   * History from router
   */
  history: _history,
  /**
   * saveOtpType email or phone no
   */
  resetSignupState: _resetSignupState,
  isSignupClear,
  signupMode,
}) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue
  } = useForm();

  const language = getCurrentLocale();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [userInputPhoneNumber, setUserInputPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [tab, setTab] = useState(0);
  const [emailToogle, isEmailToogle] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(MYANMAR_COUNTRY_CODE);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    return resetState();
  }, []);

  useEffect(() => {
    /* to populate the autofilled data */
    setValue("signupEmail", email);
    setValue("password", password);
  }, [setValue, email, password]);

  useEffect(() => {
    const formattedNumber = getE164Format(selectedCountryCode, userInputPhoneNumber);
    if (formattedNumber) {
      setPhoneNumber(formattedNumber);
    }
  }, [selectedCountryCode, userInputPhoneNumber]);

  useEffect(() => {
    if (signupMode) {
      clearErrors("phone");
      clearErrors("signupEmail");
      clearErrors("password");
      setEmail("");
      setUserInputPhoneNumber("");
      setPassword("");
      setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
    }
  }, [signupMode]);

  useEffect(() => {
    if (isSignupClear === null) {
      resetState();
    }
  }, [isSignupClear]);

  useEffect(() => {

    if (_signupStatus === Status.SUCCESS) {
      /* Save OTP Expiry Time in Minutes/Seconds to localStorage */
      saveState(ASYNC_STORAGE_KEYS.timerMinutes, 4);
      saveState(ASYNC_STORAGE_KEYS.timerSeconds, 55);
      /* Redirect to OTP screen */
      goToOtpScreen();
      /* Reset the signup status to the default value when transitioning to a OTP screen */
      _resetSignupState();
    }

    if (_signupStatus === Status.DEFAULT) {
      resetState();
    }
  }, [_signupStatus]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSingUpEmail = (e) => {
    setEmail(e.target.value.trim());
  }

  const SelectedCountryCodes = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const handlePhone = (e) => {
    const inputValue = e.target.value;
    /* Remove non-numeric characters */
    const numericValue = extractNumbersFromString(inputValue);
    setUserInputPhoneNumber(numericValue);
  };

  const handleSignUpPassword = (e) => {
    setPassword(e.target.value.trim());
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onEmailTabClick = (e) => {
    e.preventDefault();
    isEmailToogle(false);
    resetState();
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
  };

  const onMobileTabClick = (e) => {
    e.preventDefault();
    isEmailToogle(true);
    resetState();
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
  };

  const onSubmit = () => {
    if (!recaptchaToken) {
      return;
    }
    _signup(email, phoneNumber, password);
  };

  const resetState = () => {
    _resetSignupState();
    setPassword("");
    setUserInputPhoneNumber("");
    setEmail("");
    clearErrors("signupEmail");
    clearErrors("phone");
    clearErrors("password");
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
  };

  const handleFormKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSubmit(onSubmit);
    }
  };

  const goToOtpScreen = () => {
    /* Redirects to the OTP screen with the necessary data (userIdentifier, otpExpiryTimeInSecs, and otpType, loginType) */
    _history.push({
      pathname: "/otp",
      state: {
        userIdentifier: userIdentifier,
        otpExpiryTimeInSecs: otpExpiryTimeInSecs,
        otpType: email ? email : phoneNumber,
        loginType: email ? EMAIL_LK : PHONE_NUMBER_LK,
      },
    });
  }

  const goToTermsPolicy = () => {
    _history.push("/terms-policy");
  };

  return (
    <ThemeProvider theme={theme}>
      <form
        className={styles["sign-in-form"]}
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={handleFormKeyPress}
        autoComplete="off"
      >
        <span className={styles["signin-signup-form-title"]}>
          {translate("signupScreen.title")}
        </span>

        <SocialLogin />

        <span className={styles["email-info-text"]}>
          {translate("signupScreen.emailInfoText")}
        </span>

        <div id={styles["signup-screen-group"]}>
          <Tabs
            classes={{ indicator: styles["tab-indicator"] }}
            id={styles["tab-root"]}
            value={tab}
            onChange={handleTabChange}
            indicatorColor="none"
            textColor="primary"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              onClick={onEmailTabClick}
              label={<div>{translate("signupScreen.emailTab")}</div>}
              classes={{
                root: styles["tab-label"],
                selected: styles["selected-tab"],
              }}
            />
            <Tab
              onClick={onMobileTabClick}
              label={<div>{translate("signupScreen.mobileTab")}</div>}
              classes={{
                root: styles["tab-label"],
                selected: styles["selected-tab"],
              }}
            />
          </Tabs>

          {!emailToogle ? (
            <FormControl id={styles["inbox"]} variant="outlined" error={errors.singupEmail}>
              <InputLabel
                className={styles["inbox-label"]}
                id={errors.signupEmail && styles["inbox-label-error"]}
              >
                {translate("inboxLabel.emailInboxLabel")}
              </InputLabel>
              <OutlinedInput
                {...register("signupEmail", {
                  required: true,
                  pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  },
                })}
                type="text"
                id="signupEmail"
                name="signupEmail"
                value={email}
                onChange={handleSingUpEmail}
                error={
                  errors.signupEmail?.type === "pattern" ||
                  errors.signupEmail?.type === "required"
                }
                labelWidth={40}
              />
              <FormHelperText error>
                {errors.signupEmail?.type === "required" ? (
                  <span>{translate("inboxError.emailErrorRequired")}</span>
                ) : errors.signupEmail?.type === "pattern" ? (
                  <span>{translate("inboxError.emailErrorWrong")}</span>
                ) : null}
              </FormHelperText>
            </FormControl>
          ) : (
            <div id={styles["phone-number-box"]}>
              <FormControl variant="outlined" id={styles["phone-inbox-select"]}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCountryCode}
                  onChange={SelectedCountryCodes}
                  disabled={true}
                >
                  {CountryPhoneCodes &&
                    CountryPhoneCodes.map((item, key) => {
                      return (
                        <MenuItem
                          key={key}
                          id="activities-name"
                          value={item.countryCode}
                        >
                          <img
                            alt="img"
                            src={item.flag}
                            className={selectedCountryCode !== NEPAL_COUNTRY_CODE ? styles["flag-img"] : styles["flag-img-nepal"]}
                          ></img>
                          {item.countryCode}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl id={styles["phone-inbox"]} variant="outlined">
                <InputLabel
                  className={styles["inbox-label"]}
                  id={errors?.phone && styles["inbox-label-error"]}
                >
                  {translate("common.phone")}
                </InputLabel>
                <OutlinedInput
                  {...register("phone", {
                    required: true,
                    pattern: {
                      value: '[0-9]*'
                    }
                  })}
                  error={
                    errors.phone?.type === "pattern" ||
                    errors.phone?.type === "required"
                  }
                  type="text"
                  id="phone"
                  value={userInputPhoneNumber}
                  onChange={handlePhone}
                  name="phone"
                  autoComplete="new-phone"
                  labelWidth={45}
                />
                <FormHelperText error>
                  {errors.phone?.type === "required" ? (
                    <span>{translate("inboxError.mobileErrorRequired")}</span>
                  ) : errors.phone?.type === "maxLength" ? (
                    <span>{translate("inboxError.mobileErrorWrong")}</span>
                  ) : null}
                </FormHelperText>
              </FormControl>
            </div>
          )}

          <FormControl id={styles["inbox"]} variant="outlined" error={errors.password}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              className={styles["inbox-label"]}
              id={errors?.password && styles["inbox-label-error"]}
            >
              {translate("inboxLabel.passwordInboxLabel")}
            </InputLabel>
            <OutlinedInput
              {...register("password", {
                required: true,
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\])[A-Za-z\d-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\]{8,}$/,
                },
              })}
              id="password"
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              value={password}
              error={
                errors.password?.type === "required" ||
                errors.password?.type === "pattern"
              }
              onChange={handleSignUpPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            {errors.password && (
              <FormHelperText error>
                {errors.password?.type === "required" ? (
                  <span> {translate("inboxError.passwordErrorRequired")}</span>
                ) : errors.password?.type === "pattern" ? (
                  <span>{translate("inboxError.passwordErrorWrong")}</span>
                ) : null}
              </FormHelperText>
            )}
            <FormControl id={styles["recaptcha"]}>
              <Recaptcha setRecaptchaToken={setRecaptchaToken} />
            </FormControl>
            <div id={styles["signup-terms-info"]}>
              <span>{translate("signupScreen.checkboxLabel")}</span> &nbsp;
              <span id={styles["signup-terms-info-bold"]} onClick={() => goToTermsPolicy()}>
                {translate("signupScreen.checkboxLabelB")}
              </span>
              {language === BURMESE_LANGUAGE_CODE_LK && <span> {translate("signupScreen.checkboxLabelAgree")} </span>}
            </div>
          </FormControl>
        </div>

        {(email === "" && phoneNumber === "") || password === "" || !recaptchaToken ? (
          <button disabled className={cn(styles.solid, styles["btn-disabled"])}>
            {translate("common.signup")}
          </button>
        ) : (
          <button
            type="submit"
            disabled={_signupStatus === Status.LOADING ? true : false}
            className={cn(styles.btn, styles.solid)}
          >
            {_signupStatus === Status.LOADING ? (
              <CircularProgress size={20} id={styles["circular-progress"]} />
            ) : (
              translate("common.signup")
            )}
          </button>
        )}
      </form>
    </ThemeProvider >
  );
};

SignUp.propTypes = propTypes;

SignUp.defaultProps = defaultProps;

const mapStateToProps = ({ auth }) => {
  const {
    signupStatus,
    signupErrorMessage,
    userIdentifier,
    otpExpiryTimeInSecs,
    isSignupClear,
  } = auth;
  return {
    _signupStatus: signupStatus,
    signupErrorMessage,
    userIdentifier,
    otpExpiryTimeInSecs,
    isSignupClear,
  };
};

export default connect(mapStateToProps, {
  signup,
  resetSignupState,
})(withRouter(SignUp));
