import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router";
import PropTypes from "prop-types";

import {
  createTheme,
  ThemeProvider,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  resetPassword,
  resetChangePasswordState,
} from "../../../store/actions";
import { Status } from "../../../api";
import { ASYNC_STORAGE_KEYS } from "../../../constants";
import { isNonEmptyString } from "../../../utils";
import { translate } from "../../../i18n";

import { OneLineBreak, Snack } from "../../../common";
import PasswordImg from "../../../assets/images/Password.png";
import LogoImg from "../../../assets/images/logo.png";

import styles from "../Authentication.module.scss";
import colors from "../../../css/variable.scss";
import cn from "classnames";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(10px, -8px) scale(0.75)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grayColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
            backgroundColor: "none !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.MuiFormHelperText-root': {
            marginLeft: "0px",
            marginRight: "0px",
          }
        },
      },
    },
  },
});

const propTypes = {
  status: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  resetPasswordStatus: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  resendOtpStatus: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  resetChangePasswordState: PropTypes.func.isRequired,
  otpExpiryTimeInSecs: PropTypes.number,
  resetPassword: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      otpMode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  resetPasswordErrorMessage: PropTypes.string,
  resendOtpErrorMessage: PropTypes.string,
};

const defaultProps = {
  resetPasswordErrorMessage: "",
  resendOtpErrorMessage: "",
  otpExpiryTimeInSecs: 0,
};

const ChangePassword = ({
  /**
   * Tells about the status of the reset password api call,
   *
   * if resetPasswordStatus === Status.DEFAULT => api hasn't been hit yet
   * if resetPasswordStatus === Status.LOADING => api is currently being executed
   * if resetPasswordStatus === Status.SUCCESS => success response from api
   * if resetPasswordStatus === Status.ERROR   => error response from api
   *
   * @source: redux
   */
  resetPasswordStatus,
  /**
   * Contains the success message from server, when resetPasswordStatus === Status.SUCCESS
   *
   * @source: redux
   */
  resetPasswordSuccessMessage,
  /**
   * Contains the error message from server, when resetPasswordStatus === Status.ERROR
   *
   * @source: redux
   */
  resetPasswordErrorMessage,
  /**
   * redux action to make reset password api request
   *
   * @source: redux
   */
  resetPassword: _resetPassword,
  /**
   * redux action to make resend otp api request
   *
   * @source: redux
   */
  resendOtp: _resendOtp,
  /**
   * When user hit back button, from ResetPasswordScreen,
   * reset the state associated with it, in redux
   *
   * @source: redux
   */
  resetChangePasswordState: _resetChangePasswordState,

  history: _history,
}) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
    setValue
  } = useForm();

  let location = useLocation();
  const password = useRef({});
  password.current = watch("newPassword", "");
  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [snackopen, setSnackOpen] = useState(false);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /* To populate the autofilled data */
    setValue("newPssword", newPassword);
    setValue("confirmPassword", confirmPassword);
  }, [setValue, newPassword, confirmPassword]);

  useEffect(() => {
    if (resetPasswordStatus === Status.SUCCESS) {
      setSnackOpen(true);
      setTimeout(() => {
        _history.replace("/login");
      }, 2000);
    }
    if (resetPasswordStatus === Status.ERROR) {
      setSnackOpen(true);
    }
  }, [resetPasswordStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (data) => {
    _resetPassword(
      location.state.otp,
      newPassword,
      confirmPassword,
      location.state.userIdentifier
    );
  };

  const resetState = () => {
    _resetChangePasswordState();
    setConfirmPassword("");
    setNewPassword("");
    clearErrors("newPassword");
    clearErrors("confirmPassword");
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  if (isNonEmptyString(loggedInUserIdentifier)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <div className={styles.container}>
        <div className={styles["forms-container"]}>
          <div className={styles["signin-signup"]}>
            <ThemeProvider theme={theme}>
              <form
                action="#"
                className={styles["sign-in-form"]}
                onSubmit={(e) => e.preventDefault()}
              >
                <span className={styles["signin-signup-form-title"]}>
                  {translate("changePasswordScreen.title")}
                </span>
                <span className={styles["change-pass-sub-title"]}>
                  {translate("changePasswordScreen.subTitle")}
                </span>
                <FormControl id={styles["change-pass-inbox"]} variant="outlined">
                  <InputLabel
                    className={styles["inbox-label"]}
                    id={errors.newPassword && styles["inbox-label-error"]}
                    htmlFor="outlined-adornment-password"
                  >
                    {translate("inboxLabel.newPasswordLabel")}
                  </InputLabel>
                  <OutlinedInput
                    {...register("newPassword", {
                      required: true,
                      pattern: {
                        // eslint-disable-next-line no-useless-escape
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\])[A-Za-z\d-’/`~!#*$@_%+=.,^&(){}[\]|;:'"”<>?\\]{8,}$/,
                      },
                    })}
                    id="newPassword"
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    error={
                      errors.newPassword?.type === "required" ||
                      errors.newPassword?.type === "pattern"
                    }
                    onChange={handleNewPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={150}
                  />
                  <FormHelperText error>
                    {errors.newPassword?.type === "required" ? (
                      <span>{translate("inboxError.passwordErrorRequired")}</span>
                    ) : errors.newPassword?.type === "pattern" ? (
                      <span> {translate("inboxError.passwordErrorWrong")}</span>
                    ) : null}
                  </FormHelperText>
                </FormControl>
                <FormControl id={styles["change-pass-inbox"]} variant="outlined">
                  <InputLabel
                    className={styles["inbox-label"]}
                    id={errors.confirmPassword && styles["inbox-label-error"]}
                    htmlFor="outlined-adornment-password"
                  >
                    {translate("inboxLabel.confirmPasswordLabel")}
                  </InputLabel>
                  <OutlinedInput
                    {...register("confirmPassword", {
                      required: true,
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match",
                    })}
                    id="confirmPassword"
                    name="conformPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    error={
                      errors.confirmPassword?.type === "required" ||
                      errors.confirmPassword?.type === "pattern" ||
                      errors.confirmPassword?.type === "validate"
                    }
                    onChange={handleConfirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={150}
                  />
                  <FormHelperText error>
                    {errors.confirmPassword?.type === "required" ? (
                      <span>{translate("inboxError.passwordErrorRequired")}</span>
                    ) : errors.confirmPassword?.type === "pattern" ? (
                      <span> {translate("inboxError.passwordErrorWrong")}</span>
                    ) : errors.confirmPassword?.type === "validate" ? (
                      <span> {translate("inboxError.passwordErrorMatch")}</span>
                    ) : null}
                  </FormHelperText>
                </FormControl>
                <OneLineBreak />
                {!newPassword || !confirmPassword ? (
                  <button className={cn(styles.solid, styles["btn-disabled"])}>
                    {translate("common.submit")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      resetPasswordStatus === Status.LOADING ? true : false
                    }
                    className={cn(styles.btn, styles.solid)}
                  >
                    {resetPasswordStatus === Status.LOADING ? (
                      <CircularProgress id={styles["circular-progress"]} size={20} />
                    ) : (
                      <span> {translate("common.submit")}</span>
                    )}
                  </button>
                )}
              </form>
            </ThemeProvider>
          </div>
        </div>
        <div className={styles["panels-container"]}>
          <div className={cn(styles.panel, styles["left-panel"])}>
            <div>
              <div className={styles.logo} onClick={() => _history.push("/")}>
                <img src={LogoImg} alt="LOGO"></img>
              </div>
              <div className={styles["panel-inside"]}>
                <div className={cn(styles["panel-center"], styles.content)}>
                  <img
                    alt="img"
                    src={PasswordImg}
                    className={styles["img-lock-first"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snack
          open={snackopen}
          message={resetPasswordStatus === Status.SUCCESS ? resetPasswordSuccessMessage : resetPasswordErrorMessage}
          type={resetPasswordStatus === Status.SUCCESS ? "success" : "error"}
          onClose={handleSnackClose}
        />
      </div>
    </div>
  );
};

ChangePassword.propTypes = propTypes;

ChangePassword.defaultProps = defaultProps;

const mapStateToProps = ({ auth }) => {
  const {
    resetPasswordStatus,
    resetPasswordSuccessMessage,
    resetPasswordErrorMessage,
    otp,
  } = auth;

  return {
    resetPasswordStatus,
    resetPasswordSuccessMessage,
    resetPasswordErrorMessage,
    otp,
  };
};

export default connect(mapStateToProps, {
  resetPassword,
  resetChangePasswordState,
})(ChangePassword);
