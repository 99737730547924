import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Divider,
} from '@material-ui/core';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { translate } from '../../../../../i18n';
import { saveFavourite, deleteFavourite } from '../../../../../store/actions';
import { isNonEmptyString } from '../../../../../utils';
import { ATTRACTION_SK, LIMITS, SAVE_SK } from '../../../../../constants';
import { connect } from 'react-redux';
import { Status } from '../../../../../api';
import SnackBar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import EditShareModal from '../../../../../common/EditShareModal/EditShareModal';
import { withRouter } from 'react-router';
import { Stack } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { CopyLinkIcon, RatingStar } from '../../../../../common';
import styles from './Attraction.module.scss';
import cn from 'classnames';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const COPY_ACTION = 'copy';
// const SAVE_ACTION = 'save';

// const propTypes = {
//   item: articleType.isRequired,
//   baseMediaUrl: PropTypes.string,
//   baseMediaThumbUrl: PropTypes.string,
//   loggedInUserIdentifier: PropTypes.string,
//   isShared: PropTypes.bool,
// };

// const defaultProps = {
//   baseMediaUrl: '',
//   baseMediaThumbUrl: '',
//   loggedInUserIdentifier: '',
//   isShared: false,
// };

const Attraction = ({
  history,
  item,
  baseMediaUrl,
  baseMediaThumbUrl,
  loggedInUserIdentifier,
  isShared,
  //   sharePostStatus,
  //   sharedPostIdentifier,
  favouriteStatus,
  entityIdentifier,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  showLoginPrompt: _showLoginPrompt,
}) => {
  //   const classes = useStyles();
  const [flag, setFlag] = useState(false);
  const [isSaved, setIsSaved] = useState(item.isSaved);
  const [snackOpen, setOpen] = useState(false);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [copySnackOpen, setCopySanckOpen] = useState(false);

  const handleCopySnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopySanckOpen(false);
  };

  const [avgRating, setAvgRating] = useState({
    localValue: item.avgRating,
    backupValue: item.avgRating,
  });
  const [viewsCount, setViewsCount] = useState({
    localValue: item.totalViews,
    backupValue: item.totalViews,
  });
  const [sharesCount, setSharesCount] = useState({
    localValue: item.totalShares,
    backupValue: item.totalShares,
  });
  const [reviewsCount, setReviewsCount] = useState({
    localValue: item.totalReviews,
    backupValue: item.totalReviews,
  });

  useEffect(() => {
    if (avgRating.backupValue !== item.avgRating) {
      setAvgRating({
        localValue: item.avgRating,
        backupValue: item.avgRating,
      });
    }
    if (sharesCount.backupValue !== item.totalShares) {
      setSharesCount({
        localValue: item.totalShares,
        backupValue: item.totalShares,
      });
    }
    if (reviewsCount.backupValue !== item.totalReviews) {
      setReviewsCount({
        localValue: item.totalReviews,
        backupValue: item.totalReviews,
      });
    }
    if (viewsCount.backupValue !== item.totalViews) {
      setViewsCount({
        localValue: item.totalViews,
        backupValue: item.totalViews,
      });
    }
  }, [item.totalShares, item.totalReviews, item.totalViews, item.avgRating]); // eslint-disable-line react-hooks/exhaustive-deps

  const coverImg = {
    source: {
      uri: item.coverImg.url.originalUrl,
    },
    thumb: {
      uri: item.coverImg.isThumbGenerated
        ? item.coverImg.url.thumbUrl?.concat('?a=1')
        : item.coverImg.url.originalUrl,
    },
    fileType: 'img',
  };

  //   const OPTIONS = [
  //     {
  //       key: COPY_ACTION,
  //       title: translate('postListItem.copyLink'),
  //       icon: COPY_ICON,
  //     },
  //     {
  //       key: SAVE_ACTION,
  //       title: isSaved === SAVE_SK ? translate('common.save') : translate('common.unsave'),
  //       icon: isSaved === SAVE_SK ? SAVE_ICON : SAVE_FILLED_ICON,
  //     },
  //   ];
  //   let dropDownOptions = OPTIONS;
  //   if (!isNonEmptyString(loggedInUserIdentifier)) {
  //     dropDownOptions = dropDownOptions.slice(0, dropDownOptions.length - 1);
  //   }
  //   const ITEM_HEIGHT = 48;

  //   const [openShareModal, setOpenShareModal] = React.useState(false);
  //   const [anchorEl, setAnchorEl] = useState(null);
  //   const open = Boolean(anchorEl);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const handleOpenShareModal = () => {
  //     if (!isNonEmptyString(loggedInUserIdentifier)) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenShareModal(true);
  //   }

  //   const handleCloseShareModal = () => {
  //     setOpenShareModal(false);
  //   }

  //   const handleDropdown = option => {
  //     switch (option.key) {
  //       case COPY_ACTION:
  //         copyToClipboard();
  //         break;
  //       case SAVE_ACTION:
  //         save();
  //         break;
  //       default:
  //       // No Action
  //     }
  //   };

  //   const copyToClipboard = () => {
  //     let origin = window.location.href;
  //     navigator.clipboard.writeText(`${origin.slice(0, origin.length - 1)}${item.url}?c=1`);
  //     setCopySanckOpen(true);
  //   }

  const save = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (favouriteStatus !== Status.LOADING) {
      setFlag(true);
      if (isSaved === SAVE_SK) {
        _saveFavourite(item.identifier, ATTRACTION_SK, loggedInUserIdentifier);
      } else {
        _deleteFavourite(
          item.favouriteIdentifier,
          ATTRACTION_SK,
          item.identifier
        );
      }
    }
  };

  useEffect(() => {
    setIsSaved(item.isSaved);
    if (flag) {
      setOpen(true);
      setFlag(false);
    }
  }, [item.isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAttractionClick = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${btoa(item.identifier)}/attraction`);
  };

  return (
    <>
      <Card className={isShared ? styles.sharedStyle : styles.root}>
        <CardHeader
          id={styles['mui-card-header']}
          titleTypographyProps={{ id: styles['attr-typo'] }}
          className={styles.header}
          //   action={
          //     isShared
          //       ? <></>
          //       : <>
          //         <IconButton aria-label="settings" onClick={handleClick} className={styles.dropdownStyle}>
          //           <MoreVertIcon id='more-vert-icon' />
          //         </IconButton>
          //         <Menu
          //           id="long-menu"
          //           anchorEl={anchorEl}
          //           keepMounted
          //           open={open}
          //           onClose={handleClose}
          //           PaperProps={{
          //             style: {
          //               maxHeight: ITEM_HEIGHT * 4.5,
          //               width: '20ch',
          //             },
          //           }}
          //           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          //           transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          //         >
          //           {dropDownOptions.map((option) => (
          //             <MenuItem key={option.key} onClick={handleClose}>
          //               {option.key === COPY_ACTION ?
          //                 <CopyToClipboard
          //                   text={`${window.location.href.slice(0, origin.length)}/${btoa(item.url.split('/')[1])}/${ATTRACTION_SK}?c=1`}
          //                   onCopy={() => setCopySanckOpen(true)}
          //                 >
          //                   <div className={styles.menuItemStyle}>
          //                     <div><img alt='img' src={option.icon} className={styles.menuIconStyle} /></div>
          //                     <div className='pli-dropdown-menu'>{option.title}</div>
          //                   </div>
          //                 </CopyToClipboard>
          //                 : <div className={styles.menuItemStyle} onClick={() => handleDropdown(option)}>
          //                   <div><img alt='img' src={option.icon} className={option.key === SAVE_ACTION ? styles.menuIconSm : styles.menuIconStyle} /></div>
          //                   <div className='pli-dropdown-menu'>{option.title}</div>
          //                 </div>
          //               }
          //             </MenuItem>
          //           ))}
          //         </Menu>
          //       </>
          //   }
          title={
            <span
              className={styles['article-header-title']}
              onClick={handleAttractionClick}
            >
              {item.title}
            </span>
          }
          subheader={
            <div className={styles['attraction-header-subheader']}>
              <span
                id={styles['location']}
                className={cn(styles.city, 'text-gray')}
                onClick={handleAttractionClick}
              >
                {item.city}
              </span>
              <div onClick={handleAttractionClick}>
                <RatingStar
                  rating={avgRating.localValue}
                  size="small"
                  isNewsfeed={true}
                />
              </div>
            </div>
          }
        />
        <div id={styles['protected-img-box']} onClick={handleAttractionClick}>
          <CardMedia
            component="img"
            image={coverImg.source.uri}
            alt="cover.img"
            className={styles['article-media']}
            onClick={handleAttractionClick}
          />
          <div
            id={styles['protected-img']}
            onClick={handleAttractionClick}
          ></div>
        </div>

        <CardContent className={styles.content} onClick={handleAttractionClick}>
          <div className={styles['pli-description']}>
            <span>
              {`${item.description?.length >
                LIMITS.ATTRACTION_DESCRIPTION_MIN_LENGTH
                ? item.description.slice(
                  0,
                  LIMITS.ATTRACTION_DESCRIPTION_MIN_LENGTH
                )
                : item.description
                } `}

              <span className="text-blue">
                ...{translate('common.readMore')}
              </span>
            </span>
          </div>
        </CardContent>
        {isShared ? (
          <></>
        ) : (
          <>
            <Divider />
            <CardActions
              id={styles['article-footer']}
              className={styles.noHorizontalPadding}
            >
              <div className={styles.shareIcon}>
                <Stack direction="row" spacing={1}>
                  <CopyLinkIcon
                    text={`${item.copyLinkUrl}?c=1`}
                    onCopy={() => setCopySanckOpen(true)}
                  />
                  <IconButton
                    className={styles['save-icon-button']}
                    onClick={save}
                  >
                    {isSaved === SAVE_SK ? (
                      <BookmarkBorderIcon
                        className={styles['mui-save-icon-style']}
                      />
                    ) : (
                      <BookmarkIcon
                        className={styles['mui-save-icon-style-filled']}
                      />
                    )}
                  </IconButton>
                </Stack>
              </div>
              {/* <span>
                {sharesCount.localValue > 0 && (
                  <span>
                    {`${sharesCount.localValue} ${sharesCount.localValue > 1
                      ? translate('common.shares')
                      : translate('common.nshare')
                      } `}
                  </span>
                )}
              </span> */}
              <span id={styles['article-footer-span']} className="text-gray">
                {viewsCount.localValue > 0 && (
                  <span>
                    {`${viewsCount.localValue} ${viewsCount.localValue > 1
                      ? translate('common.views')
                      : translate('common.view')
                      } `}
                  </span>
                )}
              </span>
              {/* <span>
                {reviewsCount.localValue > 0 && (
                  <span>
                    {`${reviewsCount.localValue} ${reviewsCount.localValue > 1
                      ? translate('common.reviews')
                      : translate('common.review')
                      } `}
                  </span>
                )}
              </span> */}
              {/* <EditShareModal
                open={openShareModal}
                identifier={item.identifier}
                mode='share'
                type={ATTRACTION_SK}
                handleClose={handleCloseShareModal}
              /> */}
            </CardActions>
          </>
        )}
      </Card>
      {favouriteStatus === Status.SUCCESS &&
        item.identifier === entityIdentifier && (
          <SnackBar
            id={styles['my-snack']}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackOpen}
            autoHideDuration={2000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              className={styles['alertColor']}
            >
              {isSaved === SAVE_SK
                ? translate('homeScreen.unsaveFavouriteSuccessMessage')
                : translate('homeScreen.saveFavouriteSuccessMessage')}
            </Alert>
          </SnackBar>
        )}
      <SnackBar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={copySnackOpen}
        autoHideDuration={2000}
        onClose={handleCopySnackClose}
      >
        <Alert
          onClose={handleCopySnackClose}
          severity="success"
          className={styles['alertColor']}
        >
          {translate('postListItem.copyLinkSuccessMessage')}
        </Alert>
      </SnackBar>
    </>
  );
};

// Attraction.propTypes = propTypes;

// Attraction.defaultProps = defaultProps;

const mapStateToProps = ({ home }) => {
  const { favouriteStatus, entityIdentifier } = home;
  return {
    favouriteStatus,
    entityIdentifier,
  };
};
export default connect(mapStateToProps, { saveFavourite, deleteFavourite })(
  withRouter(Attraction)
);
