import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TransgenderIcon from '@mui/icons-material/Transgender';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { translate } from '../../../../../../i18n';
import { Stack, Grid } from '@mui/material';
import { formattedDate } from '../../../../../../utils';
import styles from './ProfileDetials.module.scss';
import cn from 'classnames';

const ProfileDetail = ({ userDetails }) => {
  const Info = ({ icon, text }) => {
    return (
      <div className={styles.new_profile_info}>
        <div className={styles.new_profile_info_icon}>{icon} </div>
        <div className={styles.new_profile_info_text}>{text}</div>
      </div>
    );
  };
  return (
    <div>
      {userDetails?.bio?.mobileNumber && (
        <Info
          icon={<StayCurrentPortraitIcon />}
          text={
            <a
              href={`tel:${userDetails?.bio?.mobileNumber}`}
              className={styles.new_profile_info_link}
            >
              {userDetails?.bio?.mobileNumber}
            </a>
          }
        />
      )}
      {userDetails?.bio?.email && (
        <Info
          icon={<MailOutlineIcon />}
          text={
            <a
              href={`mailto:${userDetails?.bio?.email}`}
              className={styles.new_profile_info_link}
            >
              {userDetails?.bio?.email}
            </a>
          }
        />
      )}
      {userDetails?.bio?.gender && (
        <Info
          icon={<TransgenderIcon />}
          text={translate(`common.${userDetails?.bio?.gender}`)}
        />
      )}
      {userDetails?.bio?.dob && (
        <Info
          icon={<CakeOutlinedIcon />}
          text={formattedDate(userDetails?.bio?.dob) || 'NA'}
        />
      )}
      {userDetails?.bio?.homeTown && (
        <Info
          icon={<HomeWorkOutlinedIcon />}
          text={userDetails?.bio?.homeTown || 'NA'}
        />
      )}

      {userDetails?.interests !== [] && (
        <div>
          <div className={cn(styles.paper, styles.interests)}>
            <h1 className={styles.new_profile_info_interests_title}>
              {translate('userProfile.interests')}
            </h1>
            <div className={styles.interests_list}>
              <Grid container spacing={2}>
                {userDetails.interests.map((_, key) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <div className={styles.new_user_profile_item}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <img
                            className={styles.icon}
                            alt="interest-icon"
                            src={userDetails.interests[key]?.imageUrl}
                          />
                          <div>{userDetails.interests[key]?.interestName}</div>
                        </Stack>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProfileDetail;

// key < userDetails.interests.length &&
// key % 2 === 0 && (
//   <div key={key}>
//     <Stack direction="row" spacing={1}>
//       <div className="new_user_profile_item">
//         <Stack direction="row" spacing={1} alignItems="center">
//           <img
//             className="icon"
//             alt="interest-icon"
//             src={`${baseMediaUrl}${userDetails.interests[key]?.imageUrl}`}
//           />
//           <div>
//             {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
//               ? userDetails.interests[key]?.interestName
//               : userDetails.interests[key]?.interestNameMy}
//           </div>
//         </Stack>
//       </div>
//       {key + 1 < userDetails.interests.length && (
//         <div className="new_user_profile_item">
//           <Stack
//             direction="row"
//             spacing={1}
//             alignItems="center"
//           >
//             <img
//               className="icon"
//               alt="interest-icon"
//               src={`${baseMediaUrl}${
//                 userDetails.interests[key + 1]?.imageUrl
//               }`}
//             />
//             <div>
//               {getCurrentLocale() === ENGLISH_LANGUAGE_CODE_LK
//                 ? userDetails.interests[key + 1]?.interestName
//                 : userDetails.interests[key + 1]
//                     ?.interestNameMy}
//             </div>
//           </Stack>
//         </div>
//       )}
//     </Stack>
//     {key !== userDetails.interests.length - 2 && <br />}
//   </div>
