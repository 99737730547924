import {
    shape,
    string,
    bool,
    number,
    arrayOf,
    oneOfType,
    oneOf,
  } from 'prop-types';
  import {
    PUBLIC_SK,
    PRIVATE_SK,
    ONLY_ME_SK,
    NO_CONNECTION_SK,
    PENDING_SK,
    FOLLOWING_SK,
    FRIEND_SK,
    BLOCKED_SK,
    NONE_SK,
    LOVE_SK,
    INTERESTED_SK,
    WANDER_TO_GO_SK,
  } from '../../constants/stringConstants';
  
  /**
   * Defines prop-types definition for a single Post item
   * which is being displayed on HomeScreen
   */
  const postType = shape({
    createdAt: string,
    currentUserReaction: oneOf([
      NONE_SK,
      LOVE_SK,
      INTERESTED_SK,
      WANDER_TO_GO_SK,
    ]),
    description: string,
    identifier: string,
    isShared: bool,
    itemId: number,
    latitude: oneOfType([number]),
    locationAddress: oneOfType([string]),
    longitude: oneOfType([number]),
    mediaUrl: arrayOf(
      shape({
        filePath: string,
        fileType: string,
        isThumbGenerated: number,
      }),
    ),
    totalComments: number,
    totalLikes: number,
    totalShares: number,
    type: string,
    userDetails: shape({
      identifier: string,
      location: string,
      name: string,
      networkStatusWithCurrentUser: shape({
        followStatus: oneOf([NO_CONNECTION_SK, FOLLOWING_SK]),
        relationStatus: oneOf([
          NO_CONNECTION_SK,
          PENDING_SK,
          FRIEND_SK,
          BLOCKED_SK,
        ]),
      }),
      profileImg: shape({
        isThumbGenerated: number,
        url: string,
      }),
    }),
    parentDetails: oneOfType([
      shape({
        type: string,
        identifier: string,
        locationAddress: string,
        description: string,
        latitude: number,
        longitude: number,
        createdAt: string,
        mediaUrl: arrayOf(
          shape({
            filePath: string,
            fileType: string,
          }),
        ),
        userDetails: shape({
          identifier: string,
          location: string,
          name: string,
          networkStatusWithCurrentUser: shape({
            followStatus: oneOf([NO_CONNECTION_SK, FOLLOWING_SK]),
            relationStatus: oneOf([
              NO_CONNECTION_SK,
              PENDING_SK,
              FRIEND_SK,
              BLOCKED_SK,
            ]),
          }),
          profileImg: shape({
            url: string,
            isThumbGenerated: number,
          }),
        }),
      }),
      shape({
        type: string,
        identifier: string,
        title: string,
        description: string,
        created_at: string,
        mediaUrl: arrayOf(
          shape({
            filePath: string,
            fileType: string,
          }),
        ),
      }),
    ]),
    visibility: oneOf([PUBLIC_SK, PRIVATE_SK, ONLY_ME_SK]),
  });
  
  export default postType;
  