/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
// import InputBase from '@material-ui/core/InputBase';
// import Avatar from '@material-ui/core/Avatar';
// import Modal from '@material-ui/core/Modal';
import {
  POST_SK,
  NONE_SK,
  LOVE_SK,
  INTERESTED_SK,
  WANDER_TO_GO_SK,
  PUBLIC_SK,
  PRIVATE_SK,
  ONLY_ME_SK,
  PLI_HOME_MODE_LK,
  PLI_HISTORY_MODE_LK,
  PLI_DETAIL_MODE_LK,
  PLI_PROFILE_MODE_LK,
  //   BLOGGER_SK,
  BLOG_SK,
  SAVE_SK,
  UNSAVE_SK,
  RANDOM_SK,
  HOTEL_SK,
  RESTAURANT_SK,
} from "../../../constants/stringConstants";
import { translate } from "../../../i18n";

import ReactionPopUp from "../../../common/PostListItem/ReactionPopUp";

import { withRouter } from "react-router";
import SnackBar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CopyLinkIcon from "../../../common/CopyLinkIcon/CopyLinkIcon";
import styles from "../../../common/PostListItem/FooterSection.module.scss";
import { IconButton } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { isNonEmptyString } from "../../../utils";
import { api, Status } from "../../../api";
import {
  saveFavourite,
  deleteFavourite,
  updateHomePageData,
  resetFavouriteStatusToDefault,
} from "../../../store/actions";
import { Modal } from "@material-ui/core";
import ReactionList from "../../../common/PostListItem/Reaction/ReactionList";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const propTypes = {
  loggedIn: PropTypes.bool,
  isShared: PropTypes.bool,
  identifier: PropTypes.string.isRequired,
  postOwnerIdentifier: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([
    PLI_HOME_MODE_LK,
    PLI_HISTORY_MODE_LK,
    PLI_DETAIL_MODE_LK,
    PLI_PROFILE_MODE_LK,
  ]).isRequired,
  url: PropTypes.string.isRequired,
  likesCount: PropTypes.number,
  sharesCount: PropTypes.number,
  commentsCount: PropTypes.number,
  reaction: PropTypes.oneOf([NONE_SK, LOVE_SK, INTERESTED_SK, WANDER_TO_GO_SK]),
  reactionLoading: PropTypes.bool,
  baseMediaUrl: PropTypes.string,
  updateReaction: PropTypes.func,
  incrementShareCount: PropTypes.func,
  changeCommentsCount: PropTypes.func,
  childPostVisibility: PropTypes.oneOf([
    PUBLIC_SK,
    PRIVATE_SK,
    ONLY_ME_SK,
    BLOG_SK,
  ]).isRequired,
  parentPostVisibility: PropTypes.oneOf([
    PUBLIC_SK,
    PRIVATE_SK,
    ONLY_ME_SK,
    BLOG_SK,
  ]),
  historyCommentLogic: PropTypes.func,
};

const defaultProps = {
  loggedIn: false,
  isShared: false,
  reactionLoading: false,
  likesCount: 0,
  sharesCount: 0,
  commentsCount: 0,
  reaction: NONE_SK,
  baseMediaUrl: "",
  updateReaction: () => { },
  incrementShareCount: () => { },
  historyCommentLogic: () => { },
  changeCommentsCount: () => { },
  parentPostVisibility: PUBLIC_SK,
  profileImg: "",
};

const FooterSection = ({
  /**
   * Mode tells in which screen PostListItem component is used.
   *
   * It could be
   * 1. Home screen
   * 2. History screen
   * 3. Post Detail screen aka CommentListScreen
   * 4. Friends Profile screen
   * 5. Business profile Screen
   */
  mode,
  isShared,
  sharedType,
  childPostVisibility,
  /**
   * If there is a parent post, parentPostVisibility will point to
   * parent post visibility
   */
  parentPostVisibility,
  loggedIn,
  identifier,
  loggedInUserIdentifier,
  /**
   * User id of the post creator
   */
  postOwnerIdentifier,
  /**
   * Web url of the post
   */
  url,
  likesCount,
  sharesCount,
  commentsCount,
  reaction,
  reactionLoading,
  incrementShareCount,
  updateReaction,
  changeCommentsCount,
  historyCommentLogic,
  profileImg,
  userRole,
  parentPostIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  history,
  item,
  showLoginPrompt: _showLoginPrompt,
  reloadProfileScreen: _reloadProfileScreen,
  favouriteStatus,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  entityIdentifier,
  favouriteIdentifier,
  setPost,
  updateHomePageData: _updateHomePageData,
  resetFavouriteStatusToDefault: _resetFavouriteStatusToDefault,
}) => {
  const classes = useStyles();
  const [openReactionList, setOpenReactionList] = useState(false);
  //   const [openShareModal, setOpenShareModal] = useState(false);
  //   const [openComment, setOpenComment] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isSaved, setIsSaved] = useState(item.isSaved);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const save = (item) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (favouriteStatus !== Status.LOADING) {
      if (isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: item.identifier,
            entityType: POST_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse: { favouriteIdentifier } }) => {
            item.favouriteIdentifier = favouriteIdentifier;
            item.isSaved = UNSAVE_SK;
            setPost(item);
            setIsSaved(item.isSaved);
            setIsOpen(true);
            // _resetFavouriteStatusToDefault();
            _updateHomePageData(favouriteIdentifier, item.identifier);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let favouriteIdentifier = item?.favouriteIdentifier;
        api["deleteFavourite"]({ favouriteIdentifier })
          .then(({ userResponse }) => {
            item.favouriteIdentifier = "";
            item.isSaved = SAVE_SK;
            setPost(item);
            setIsSaved(item.isSaved);
            setIsOpen(true);
            // _resetFavouriteStatusToDefault();
            _updateHomePageData({ identifier: item.identifier });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };

  //   const profileImgUrl = `${profileImg.isThumbGenerated
  //     ? baseMediaThumbUrl
  //     : baseMediaUrl
  //     }${profileImg.url}`;

  //   const handleOpenShareModal = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenShareModal(true);
  //   }

  const handleOpenReactionList = () => {
    if (!loggedIn) {
      _showLoginPrompt();
      return;
    }
    setOpenReactionList(true);
  };

  const handleCloseReactionList = () => {
    setOpenReactionList(false);
  };

  //   const handleCloseShareModal = () => {
  //     setOpenShareModal(false);
  //   }

  //   const openProfile = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //     }
  //     if (mode === PLI_PROFILE_MODE_LK) {
  //       if (postOwnerIdentifier !== loggedInUserIdentifier) {
  //         history.replace(`/user?id=${btoa(loggedInUserIdentifier)}`);
  //         _reloadProfileScreen(loggedInUserIdentifier);
  //       }
  //     }
  //     else {
  //       history.push(`/user?id=${btoa(loggedInUserIdentifier)}`);
  //     }

  //   }

  //   const openCommentList = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenComment(true);
  //   }

  //   const renderAvatar = () => {
  //     if (!loggedIn) {
  //       return (
  //         <Avatar
  //           id='pli-avatar'
  //           src={guestProfileImg}
  //           className={classes.avatar}
  //           onClick={openProfile}
  //         />
  //       );
  //     }
  //     else if (userRole !== BLOGGER_SK) {
  //       return (
  //         <Avatar
  //           id='pli-avatar'
  //           src={profileImgUrl}
  //           className={classes.avatar}
  //           onClick={openProfile}
  //         />
  //       );
  //     }
  //     else {
  //       return (
  //         <Badge
  //           overlap="circular"
  //           anchorOrigin={{
  //             vertical: 'bottom',
  //             horizontal: 'right'
  //           }}
  //           badgeContent={<img
  //             id='pli-badge'
  //             alt='badgeimg'
  //             src={bloggerMarkIcon}
  //             className={classes.badge}
  //           />}
  //         >
  //           <Avatar
  //             id='pli-avatar'
  //             src={profileImgUrl}
  //             className={classes.bloggerAvatar}
  //             onClick={openProfile}
  //           />
  //         </Badge>
  //       )
  //     }
  //   };

  //   const renderShareIcon = () => {
  //     let visibility = isShared ? parentPostVisibility : childPostVisibility;
  //     if (visibility !== PUBLIC_SK && visibility !== BLOG_SK) {
  //       return null;
  //     }
  //     return (
  //       <img
  //         id='pli-share-icon'
  //         alt='img'
  //         src={ICON_SHARE}
  //         className={classes.actionIconSm}
  //       />
  //     );
  //   }

  return (
    <>
      {/* <CommentList
        open={openComment}
        handleClose={() => setOpenComment(false)}
        postIdentifier={identifier}
        postOwnerIdentifier={postOwnerIdentifier}
        updateTotalCommentsInPostUI={changeCommentsCount}
      /> */}
      <Modal
        open={openReactionList}
        onClose={handleCloseReactionList}
        className={classes.reactionListModal}
      >
        <>
          <ReactionList
            detail
            entityIdentifier={identifier}
            entityType={POST_SK}
            baseMediaUrl={baseMediaUrl}
          />
        </>
      </Modal>
      {/* <EditShareModal
        open={openShareModal}
        mode='share'
        identifier={isShared ? parentPostIdentifier : identifier}
        type={isShared ? sharedType : POST_SK}
        isShared={isShared}
        incrementNumberOfShare={incrementShareCount}
        handleClose={handleCloseShareModal}
      /> */}
      <Divider className={styles.divider} />
      <CardActions disableSpacing className={styles.root}>
        <div className={styles.iconSection}>
          <div className={styles.reactionSection}>
            <ReactionPopUp
              classes={classes}
              loggedIn={loggedIn}
              currentSelection={reaction}
              setSelectedReaction={updateReaction}
              showLoginPrompt={_showLoginPrompt}
              item={item}
            />
            {/* {renderShareIcon()} */}
            <CopyLinkIcon
              text={`${url}?c=1`}
              onCopy={() => setSnackOpen(true)}
            />
            <IconButton
              id={styles["recommend-save-icon"]}
              onClick={() => save(item)}
            >
              {isSaved === SAVE_SK ? (
                <BookmarkBorderIcon className={styles["save-icon-style"]} />
              ) : (
                <BookmarkIcon className={styles["save-icon-style"]} />
              )}
            </IconButton>
          </div>
          <div
            id="reaction-info"
            className={styles.reactionInfo}
            onClick={() => handleOpenReactionList()}
          >
            <span>
              {likesCount > 0 && (
                <span>
                  {`${likesCount} ${likesCount > 1
                    ? translate("common.reacts")
                    : translate("common.react")
                    } `}
                </span>
              )}
            </span>
            {/* <span>
            {commentsCount > 0 && (
              <span onClick={openCommentList}>
                {`${commentsCount} ${
                  commentsCount > 1
                    ? translate("common.comments")
                    : translate("common.ncomment")
                } `}
              </span>
            )}
          </span> */}
            {/* <span>
            {sharesCount > 0 && (
              <span>
                {`${sharesCount} ${
                  sharesCount > 1
                    ? translate("common.shares")
                    : translate("common.nshare")
                } `}
              </span>
            )}
          </span> */}
          </div>
        </div>
      </CardActions>
      {/* <Divider light />
      <div className={classes.commentSection}>
        <div>
          {renderAvatar()}
        </div>
        <div className='comment-box'
          onClick={openCommentList}
        >
          <InputBase
            value=''
            id='comment-input'
            placeholder={translate('common.addComment')}
            inputProps={{ 'aria-label': 'naked' }}
          />
        </div>
      </div> */}

      <SnackBar
        id={styles["my-snack"]}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className={styles["alertColor"]}
        >
          {isSaved === SAVE_SK
            ? translate("homeScreen.unsaveFavouriteSuccessMessage")
            : translate("homeScreen.saveFavouriteSuccessMessage")}
        </Alert>
      </SnackBar>

      <SnackBar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          className={styles.alertColor}
        >
          {translate("postListItem.copyLinkSuccessMessage")}
        </Alert>
      </SnackBar>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    width: "26px",
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    cursor: "pointer",
  },
  bloggerAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

FooterSection.propTypes = propTypes;

FooterSection.defaultProps = defaultProps;

const mapStateToProps = ({ app, profile, home }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { favouriteStatus, entityIdentifier, favouriteIdentifier } = home;

  const { userDetails: { bio: { profileImg, userRole } = {} } = {} } = profile;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    profileImg,
    userRole,
    favouriteStatus,
    entityIdentifier,
    favouriteIdentifier,
  };
};

export default connect(mapStateToProps, {
  saveFavourite,
  deleteFavourite,
  updateHomePageData,
  resetFavouriteStatusToDefault
})(
  withRouter(FooterSection)
);
