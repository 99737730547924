import Button from '@mui/material/Button';
import React from 'react';
import styles from './UserProfileInfo.module.scss';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import { useState } from 'react';
import ProfileDetail from './components/ProfileDetials/ProfileDetails';
import { translate } from '../../../../i18n';
import Banner from '../UserProfileBanner/Banner';
import SavedTab from '../SavedTab/SavedTab';
import { withRouter } from 'react-router';

const UserProfileInfo = ({
  history,
  userDetails,
  profileImgUrl,
  baseMediaUrl,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const goToEditPofile = () => {
    history.push('/edit-profile');
  };

  return (
    <div id={styles["profile-info-root"]} >
      {/* user info */}
      <Banner userDetails={userDetails} profileImgUrl={profileImgUrl} />
      <div className={styles.new_user_edit_div}>
        <Button
          variant="contained"
          className={styles.new_user_eidt_btn}
          onClick={goToEditPofile}
        >
          {translate('userProfile.editProfile')}
        </Button>
      </div>
      {/* user info */}

      {/* tab */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          lg={6}
          md={8}
          sm={12}
          xs={12}
          className={styles.new_user_tab_grid}
        >
          <div className="">
            <div>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs"
                  variant="fullWidth"
                  id={styles['profile-tabs-wappper']}
                >
                  <Tab
                    id={value === 0 && styles.indicator}
                    label={translate('common.profile')}
                    className={styles.profile_tab}
                  />
                  <Tab
                    id={value === 1 && styles.indicator}
                    label={translate('userProfile.saved')}
                    className={styles.profile_tab}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ProfileDetail
                  userDetails={userDetails}
                  baseMediaUrl={baseMediaUrl}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SavedTab />
              </TabPanel>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* tab */}
    </div>
  );
};

export default withRouter(UserProfileInfo);
