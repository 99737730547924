/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import { publicIpv4 } from 'public-ip';
import moment from "moment";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Tabs,
  Tab,
  CircularProgress,
  IconButton,
  createTheme,
  ThemeProvider
} from "@mui/material";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import { translate } from "../../../../i18n";
import { Status } from "../../../../api";
import {
  ASYNC_STORAGE_KEYS,
  LOCAL_IP_ADDRESS,
  MYANMAR_COUNTRY_CODE,
  NEPAL_COUNTRY_CODE,
  PROFILE_INCOMPLETE_SK
} from "../../../../constants";
import { resetLoginState, login } from "../../../../store/actions";
import { extractNumbersFromString, getE164Format } from "../../../../utils";

import SocialLogin from "../../SocialLogin/SocialLogin";
import CountryPhoneCodes from "../../../../config/countryPhoneCode";
import Recaptcha from "../../../../common/Recaptcha/Recaptcha";

import styles from "../../Authentication.module.scss";
import colors from "../../../../css/variable.scss";
import cn from "classnames";


const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(10px, -8px) scale(0.75)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grayColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
            backgroundColor: "none !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.MuiFormHelperText-root': {
            marginLeft: "0px",
            marginRight: "0px",
          }
        },
      },
    },
  },
});

const propTypes = {
  status: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  errorMessage: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  resetLoginState: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    reset: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

const defaultProps = {};

const SignIn = ({
  /**
   * Tells about the status of the login api call,
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   *
   * @source: redux
   */
  status,
  /**
   * Contains the error message from server, when status === Status.ERROR
   *
   * @source: redux
   */
  errorMessage: _message,
  /**
   * redux action to initiate login api request
   *
   * @source: redux
   */
  login: _login,
  /**
   * When user hit back button, from LoginScreen,
   * reset the state associated with login logic in redux
   *
   * @source: redux
   */
  resetLoginState: _resetLoginState,
  history: _history,
  saveSnackMessage: _saveSnackMessage,
  signupMode,
  userDetailsStatus,
}) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [userInputPhoneNumber, setUserInputPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [tab, setTab] = useState(0);
  const [emailToogle, isEmailToogle] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(MYANMAR_COUNTRY_CODE);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loginAttempt, setLoginAttempt] = useState(parseInt(localStorage.getItem(ASYNC_STORAGE_KEYS.loginAttempt)) || 0);
  const [waitingMinutes, setWaitingMinutes] = useState(false);
  const [holdMinutes, setHoldMinutes] = useState("");
  const [holdSeconds, setHoldSeconds] = useState("");
  const [ipAddress, setIpAddress] = useState('');

  const currentTime = moment().format("HH:mm:ss");
  const loginExpiryTime = moment().add(1, "minutes").format("HH:mm:ss");

  useEffect(() => {
    getIpaddress();
    return () => {
      // componentWillUnmount
      resetState();
    };
  }, []);

  useEffect(() => {
    /* To populate the autofilled data */
    setValue("email", email);
    setValue("password", password);
  }, [setValue, email, password]);

  useEffect(() => {
    const formattedNumber = getE164Format(selectedCountryCode, userInputPhoneNumber);
    if (formattedNumber) {
      setPhoneNumber(formattedNumber);
    }
  }, [selectedCountryCode, userInputPhoneNumber]);

  useEffect(() => {
    if (!signupMode) {
      _resetLoginState();
      setEmail("");
      setUserInputPhoneNumber("");
      setPassword("");
      clearErrors("email");
      clearErrors("phone");
      clearErrors("password");
      setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
    }
  }, [signupMode]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (isLoginClear) {
  //     resetState()
  //   }
  // }, [isLoginClear]);

  useEffect(() => {
    if (status === Status.SUCCESS) {

      const userStatus = localStorage.getItem(ASYNC_STORAGE_KEYS.userStatus);
      if (userStatus === PROFILE_INCOMPLETE_SK) {
        _history.push("/interest");
      } else if (userDetailsStatus === Status.SUCCESS) {
        _history.push("/");
      }

      const expiry = localStorage.getItem(ASYNC_STORAGE_KEYS.loginExpiryTime);

      if (expiry !== null) {
        localStorage.removeItem(ASYNC_STORAGE_KEYS.loginExpiryTime);
        localStorage.removeItem(ASYNC_STORAGE_KEYS.loginAttempt);
      }
    }

    if (status === Status.ERROR) {
      setLoginAttempt(parseInt(loginAttempt) + 1);

      if (
        currentTime > localStorage.getItem(ASYNC_STORAGE_KEYS.loginExpiryTime)
      ) {
        setLoginAttempt(1);
      }
    }
  }, [status, userDetailsStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    localStorage.setItem(
      ASYNC_STORAGE_KEYS.loginAttempt,
      loginAttempt.toString()
    );

    if (loginAttempt === 1) {
      localStorage.setItem(ASYNC_STORAGE_KEYS.loginExpiryTime, loginExpiryTime);
    }

    if (
      loginAttempt === 5 &&
      localStorage.getItem(ASYNC_STORAGE_KEYS.holdTime)
    ) {
      setWaitingMinutes(true);
      getCountDown();
    }

    if (
      loginAttempt === 5 &&
      currentTime < localStorage.getItem(ASYNC_STORAGE_KEYS.loginExpiryTime)
    ) {
      getCountDown();
    }
  }, [loginAttempt]);

  const countDown = (i, callback) => {
    const timer = setInterval(function () {
      let minutes = parseInt(i / 60, 10);
      let seconds = parseInt(i % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setWaitingMinutes(true);

      setHoldMinutes(minutes);
      setHoldSeconds(seconds);

      if (i-- > 0) {
        localStorage.setItem(ASYNC_STORAGE_KEYS.holdTime, i);
      } else {
        localStorage.removeItem(ASYNC_STORAGE_KEYS.holdTime);
        clearInterval(timer);
        callback();
      }
    }, 1000);
  };

  const getCountDown = () => {
    var countDownTime = localStorage.getItem(ASYNC_STORAGE_KEYS.holdTime) || 5 * 60;
    countDown(countDownTime, () => {
      setWaitingMinutes(false);
      localStorage.removeItem(ASYNC_STORAGE_KEYS.loginExpiryTime);
      localStorage.removeItem(ASYNC_STORAGE_KEYS.loginAttempt);
      setLoginAttempt(0);
    });
  };

  const getIpaddress = async () => {
    try {
      const ip = await publicIpv4();
      setIpAddress(ip);
    } catch (error) {
      // Handle the error, and provide a default IP address if needed
      setIpAddress(LOCAL_IP_ADDRESS);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  const SelectedCountryCodes = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const handlePhone = (e) => {
    const inputValue = e.target.value;
    /* Remove non-numeric characters */
    const numericValue = extractNumbersFromString(inputValue);
    setUserInputPhoneNumber(numericValue);
  };

  const handlePasswrod = (e) => {
    setPassword(e.target.value.trim())
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onEmailTabClick = (e) => {
    _resetLoginState();
    isEmailToogle(false);
    e.preventDefault();
    resetState();
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
  };

  const onMobileTabClick = (e) => {
    _resetLoginState();
    isEmailToogle(true);
    e.preventDefault();
    resetState();
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
  };

  const resetState = () => {
    _resetLoginState();
    setPassword("");
    setUserInputPhoneNumber("");
    setEmail("");
    setSelectedCountryCode(MYANMAR_COUNTRY_CODE);
    clearErrors("email");
    clearErrors("phone");
    clearErrors("password");
  };

  const onSubmit = () => {

    if (!recaptchaToken) {
      return;
    };

    if (!password) {
      return;
    };

    _login(email, phoneNumber, password, ipAddress);
  };

  const handleFormKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(e); // Call the onSubmit function directly
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goToForgotPassword = () => {
    _history.push("/forgotpassword");
  };

  return (
    <ThemeProvider theme={theme}>
      <form
        action="/otp"
        className={styles["sign-up-form"]}
        onKeyPress={handleFormKeyPress}
        onSubmit={handleSubmit(onSubmit)}
      >
        <span className={styles["signin-signup-form-title"]}>
          {translate("signinScreen.title")}
        </span>

        <SocialLogin />
        <span className={styles["email-info-text"]}>
          {translate("signinScreen.emailInfoText")}
        </span>
        <div id={styles["signup-screen-group"]}>
          <Tabs
            classes={{ indicator: styles["tab-indicator"] }}
            id={styles["tab-root"]}
            value={tab}
            onChange={handleTabChange}
            indicatorColor="none"
            textColor="primary"
            aria-label="scrollable auto tabs"
          >
            <Tab
              disabled={waitingMinutes}
              onClick={onEmailTabClick}
              label={<div>{translate("signinScreen.emailTab")}</div>}
              classes={{
                root: styles["tab-label"],
                selected: styles["selected-tab"],
              }}
            />
            <Tab
              disabled={waitingMinutes}
              onClick={onMobileTabClick}
              label={<div>{translate("signinScreen.mobileTab")}</div>}
              classes={{
                root: styles["tab-label"],
                selected: styles["selected-tab"],
              }}
            />
          </Tabs>
          {!emailToogle ? (
            <FormControl variant="outlined" id={styles["inbox"]}>
              <InputLabel
                className={styles["inbox-label"]}
                id={errors.email && styles["inbox-label-error"]}
              >
                {translate("inboxLabel.emailInboxLabel")}
              </InputLabel>
              <OutlinedInput
                {...register("email", {
                  required: true,
                  pattern: {
                    /* eslint-disable-next-line no-useless-escape */
                    value:
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  },
                })}
                error={
                  errors.email?.type === "pattern" ||
                  errors.email?.type === "required"
                }
                id="email"
                value={email}
                type="text"
                onChange={handleEmail}
                name="email"
                labelWidth={40}
                disabled={waitingMinutes}
              />

              <FormHelperText error>
                {errors.email?.type === "required" ? (
                  <span>{translate("inboxError.emailErrorRequired")}</span>
                ) : errors.email?.type === "pattern" ? (
                  <span>{translate("inboxError.emailErrorWrong")}</span>
                ) : null}
              </FormHelperText>
            </FormControl>
          ) : (
            <div id={styles["phone-number-box"]}>
              <FormControl
                variant="outlined"
                id={styles["phone-inbox-select"]}
                disabled={waitingMinutes}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCountryCode}
                  onChange={SelectedCountryCodes}
                  disabled={true}
                >
                  {CountryPhoneCodes &&
                    CountryPhoneCodes.map((item, key) => {
                      return (
                        <MenuItem
                          key={key}
                          id="activities-name"
                          value={item.countryCode}
                        >
                          <img
                            alt="img"
                            src={item.flag}
                            className={selectedCountryCode !== NEPAL_COUNTRY_CODE ? styles["flag-img"] : styles["flag-img-nepal"]}
                          ></img>{" "}
                          {item.countryCode}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl
                id={styles["inbox"]}
                variant="outlined"
                disabled={waitingMinutes}
              >
                <InputLabel
                  className={styles["inbox-label"]}
                  id={errors.phone && styles["inbox-label-error"]}
                >
                  {translate("common.phone")}
                </InputLabel>
                <OutlinedInput
                  {...register("phone", {
                    required: true,
                  })}
                  error={
                    errors.phone?.type === "pattern" ||
                    errors.phone?.type === "required"
                  }
                  id="phone"
                  value={userInputPhoneNumber}
                  type="text"
                  onChange={handlePhone}
                  name="phone"
                  labelWidth={45}
                />
                <FormHelperText error>
                  {errors.phone?.type === "required" ? (
                    <span>{translate("inboxError.mobileErrorRequired")}</span>
                  ) : errors.phone?.type === "pattern" ? (
                    <span>{translate("inboxError.mobileErrorWrong")}</span>
                  ) : null}
                </FormHelperText>
              </FormControl>
            </div>
          )}

          <FormControl
            id={styles["inbox-password"]}
            variant="outlined"
            disabled={waitingMinutes}
          >
            <InputLabel
              className={styles["inbox-label"]}
              id={errors.password && styles["inbox-label-error"]}
            >
              {translate("inboxLabel.passwordInboxLabel")}
            </InputLabel>
            <OutlinedInput
              {...register("password", {
                required: true,
              })}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              error={errors.password?.type === "required"}
              onChange={handlePasswrod}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText error>
              {errors.password?.type === "required" && <span> {translate("inboxError.passwordErrorRequired")} </span>}
            </FormHelperText>
          </FormControl>

          <button
            className={styles["forgot-password"]}
            onClick={goToForgotPassword}
            disabled={waitingMinutes}
          >
            {translate("signinScreen.forgotPasswordText")}
          </button>

          <FormControl id={styles["recaptcha"]}>
            <Recaptcha setRecaptchaToken={setRecaptchaToken} />
          </FormControl>
        </div>

        {(email === "" && userInputPhoneNumber === "") ||
          password === "" ||
          !recaptchaToken ||
          waitingMinutes ? (
          <button disabled className={cn(styles.solid, styles["btn-disabled"])}>
            {translate("common.login")}
          </button>
        ) : (
          <button
            type="submit"
            id={styles["submit"]}
            disabled={status === Status.LOADING ? true : false}
            value={translate("common.login")}
            className={cn(styles.btn, styles.solid)}
          >
            {status === Status.LOADING ? (
              <CircularProgress size={20} id={styles["circular-progress"]} />
            ) : (
              translate("common.login")
            )}
          </button>
        )}
        {waitingMinutes && (
          <div className={styles["timer"]}>
            {translate("common.timeOutMsg")}
            <span className={styles["hold-time"]}>
              {holdMinutes}:{holdSeconds}
            </span>
          </div>
        )}
      </form>
    </ThemeProvider>
  );
};

SignIn.propTypes = propTypes;

SignIn.defaultProps = defaultProps;

const mapStateToProps = ({ auth, profile }) => {
  const {
    loginStatus: status,
    loginErrorMessage: errorMessage,
    isLoginClear,
  } = auth;
  const { userDetailsStatus } = profile;
  return {
    status,
    errorMessage,
    isLoginClear,
    userDetailsStatus,
  };
};

export default connect(mapStateToProps, {
  login,
  resetLoginState,
})(withRouter(SignIn));
