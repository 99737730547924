import React, { useEffect, useReducer, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { translate } from '../../i18n';
import { CssBaseline, Grid, Typography, styled, Box } from '@mui/material';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import {
  AlertDialog,
  AppBar,
  BackIcon,
  BackToTopIcon,
  //   CreatePostIcon,
  Spinner,
} from '../../common';
import { api, Status } from '../../api';
import {
  ARTICLE_SK,
  ASYNC_STORAGE_KEYS,
  ATTRACTION_SK,
  BUSINESS_SK,
  HOTEL_SK,
  POST_SK,
  RESTAURANT_SK,
  USER_SK,
} from '../../constants';
import AllResultTab from './AllResultTab';
import FilterResultTab from './FilterResultTab';
import { isNonEmptyString } from '../../utils';
import styles from './SearchResult.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: '15px' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Tabs = styled((props) => <MuiTabs {...props} />)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});

const Tab = styled((props) => <MuiTab {...props} />)(({ theme }) => ({
  border: '1px solid #C4C4C4',
  boxSizing: 'border-box',
  borderRadius: '6px',
  marginRight: '10px',
}));

const SearchResult = ({ history }) => {
  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );
  const isLoggedIn = isNonEmptyString(loggedInUserIdentifier);
  const keyword = new URLSearchParams(window.location.search).get('q');
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      attractions: [],
      articles: [],
      hotels: [],
      restaurants: [],
      users: [],
      posts: [],
      apiStatus: Status.DEFAULT,
    }
  );
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const tabs = [
    {
      label: translate('common.all'),
      type: 'All',
    },
    {
      label: translate('common.attractions'),
      type: ATTRACTION_SK,
    },
    {
      label: translate('common.articles'),
      type: ARTICLE_SK,
    },
    {
      label: translate('common.hotels'),
      type: HOTEL_SK,
    },
    {
      label: translate('common.restaurants'),
      type: RESTAURANT_SK,
    },
    // {
    //   label: translate('common.users'),
    //   type: USER_SK,
    // },
    // {
    //   label: translate('common.posts'),
    //   type: POST_SK,
    // },
  ];

  useEffect(() => {
    setSelectedTab(0);
    setState({
      apiStatus: Status.LOADING,
    });
    api
      .fetchSearchResult({
        keyword,
      })
      .then(({ userResponse: { searchList = {} } }) => {
        const {
          attractions: attractionList = [],
          hotels: hotelList = [],
          restaurants: restaurantList = [],
          articles: articleList = [],
          posts: postList = [],
          users: userList = [],
        } = searchList;
        setState({
          attractions: attractionList.slice(0, 4).map((item) => ({
            ...item,
            type: ATTRACTION_SK,
          })),
          hotels: hotelList.map((item) => ({ ...item, type: BUSINESS_SK })),
          restaurants: restaurantList.map((item) => ({
            ...item,
            type: BUSINESS_SK,
          })),
          articles: articleList
            .slice(0, 4)
            .map((item) => ({ ...item, type: ARTICLE_SK })),
          posts: postList.map((item) => ({ ...item, type: POST_SK })),
          users: userList
            .slice(0, 4)
            .map((item) => ({ ...item, type: USER_SK })),
          apiStatus: Status.SUCCESS,
        });
      })
      .catch(() => {
        setState({
          apiStatus: Status.ERROR,
        });
      });
  }, [keyword]);

  const isDataAvailable = () =>
    state.attractions.length ||
    state.hotels.length ||
    state.restaurants.length ||
    state.articles.length ||
    state.posts.length ||
    state.users.length;

  if (
    state.apiStatus === Status.LOADING
  ) {
    return <Spinner />;
  }

  // if ((state.apiStatus === Status.SUCCESS && !isDataAvailable())) {
  //   return <NoResult show={true} />;
  // }

  if (keyword === null || keyword === "") {
    return <Redirect to="/404" />
  }

  const showLoginPrompt = (
    description = translate('common.loginPromtDialogMessage'),
    title = translate('common.loginPromtDialogTitle')
  ) => {
    let data = {
      title,
      description,
      positiveButtonCallback: () => {
        history.push('register-login');
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSeeMoreClicked = (type) => {
    let _selectedTab = 0;
    switch (type) {
      case ATTRACTION_SK:
        _selectedTab = 1;
        break;
      case ARTICLE_SK:
        _selectedTab = 2;
        break;
      case HOTEL_SK:
        _selectedTab = 3;
        break;
      case RESTAURANT_SK:
        _selectedTab = 4;
        break;
      // case USER_SK:
      //   _selectedTab = 5;
      //   break;
      // case POST_SK:
      //   _selectedTab = 6;
      //   break;
      default:
        break;
    }
    setSelectedTab(_selectedTab);
  };

  const renderTitle = () => {
    return (
      <Typography id={styles['search-result-title']}>
        {translate('searchResultScreen.searchResultFor')}
      </Typography>
    );
  };

  const ResponsiveTab = ({ children }) => {
    return (
      <>
        <Tabs
          id={styles['search-result-tab-desktop']}
          value={selectedTab}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          {children}
        </Tabs>
        <Tabs
          id={styles['search-result-tab-mobile']}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="on"
          onChange={handleTabChange}
        >
          {children}
        </Tabs>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <>
        <ResponsiveTab>
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              label={tab.label}
              sx={
                key === tabs.length - 1 && {
                  marginRight: 0,
                }
              }
            />
          ))}
        </ResponsiveTab>
        {tabs.map((tab, key) => (
          <TabPanel key={key} value={selectedTab} index={key}>
            {key === 0 ? (
              <AllResultTab
                state={state}
                isLoggedIn={isLoggedIn}
                handleSeeMoreClicked={handleSeeMoreClicked}
                showLoginPrompt={showLoginPrompt}
                isNoResult={(state.apiStatus === Status.SUCCESS && !isDataAvailable())}
              />
            ) : (
              <FilterResultTab
                type={tab.type}
                isLoggedIn={isLoggedIn}
                showLoginPrompt={showLoginPrompt}
                isSearchResult={true}
              />
            )}
          </TabPanel>
        ))}
      </>
    );
  };

  return (
    <>
      <CssBaseline />
      <div id="back-to-top-anchor" />
      <AppBar mode="search" />
      <BackIcon />
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
      <Grid container id={styles['search-result-root']}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {renderTitle()}
          {renderTabs()}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <AlertDialog open={alertOpen} data={alertData} />
    </>
  );
};

export default withRouter(SearchResult);
