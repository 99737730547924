import React from 'react';
import { Dialog, DialogTitle, Button } from '@material-ui/core';
import { translate } from '../../i18n';
import { Button as MuiButton, Stack } from '@mui/material';
import styles from './AlertDialog.module.scss';

const AlertDialog = ({ data, open }) => {
  const {
    title,
    description,
    positiveButtonTitle = translate('common.yes'),
    negativeButtonTitle = translate('common.no'),
    positiveButtonCallback,
    negativeButtonCallback,
    hideNegativeButton = false,
    isSpecial = false,
    handleClose,
    titleColor = '',
  } = data;
  let _description = description?.split('!@#$%');
  return (
    <Dialog
      id={styles['login-alert']}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={isSpecial ? handleClose : negativeButtonCallback}
    >
      <DialogTitle
        id={styles['simple-dialog-title']}
        style={{ color: titleColor }}
      >
        {title}
      </DialogTitle>
      <div className={styles.paper}>
        <div className={styles['alert-dialog-description']}>
          {_description?.map((des, key) => (
            <div key={key}>{des}</div>
          ))}
        </div>
        <div className={styles.footer}>
          <Stack
            direction="row"
            spacing={{
              xs: 1,
              sm: 2,
            }}
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              onClick={positiveButtonCallback}
              className={styles.buttonStyle}
            >
              {positiveButtonTitle}
            </Button>
            {!hideNegativeButton && (
              <MuiButton
                variant="contained"
                size="small"
                onClick={negativeButtonCallback}
                id={styles.buttonOutlined}
              >
                {negativeButtonTitle}
              </MuiButton>
            )}
          </Stack>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialog;
