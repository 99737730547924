import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    Avatar,
    CircularProgress,
    Divider,
    IconButton,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { api, Status } from '../../../api';
import { getPeriodicTime } from '../../../utils';
import styles from './NotificationList.module.scss';
import { isEmpty } from 'lodash';

import { translate } from '../../../i18n';
import EndMessage from '../../EndMessage/EndMessage';

const NotificationList = ({
    history
}) => {

    const [page, setPage] = useState(1);
    const [notiList, setNotiList] = useState([]);
    const [contentAvailableForRefresh, setContentAvailableForRefresh] = useState(true);
    const [deleteStatus, setDeleteStatus] = useState(Status.DEFAULT);
    const [deletedId, setDeletedId] = useState('');

    const deleteNoti = (identifier) => {
        if (deleteStatus === Status.LOADING && deletedId === identifier) {
            return;
        }
        setDeletedId(identifier);
        setDeleteStatus(Status.LOADING);
        api
            .deleteNotification({ identifier })
            .then(() => {
                setDeleteStatus(Status.SUCCESS);
                setDeletedId('');
                setNotiList(prevState => {
                    return prevState.filter(item => {
                        return item.identifier !== identifier
                    })
                })
            })
            .catch(() => {
                setDeletedId('');
                setDeleteStatus(Status.ERROR);
            });
    };

    useEffect(() => {
        api
            .fetchNotificationList({ page })
            .then(({ userResponse: { notificationList } }) => {
                setNotiList(prevState => {
                    return [
                        ...prevState,
                        ...notificationList,
                    ];
                });
                setContentAvailableForRefresh(notificationList.length !== 0);
            })
            .catch(() => { });
    }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

    const openDetails = (item) => {
        history.push(`/${window.btoa(item?.postIdentifier)}/${item?.type}`);
    }

    const endMessage = (
        <>
            {
                !isEmpty(notiList) ? <EndMessage /> : <div className={styles['nomore-data-info']}>
                    {translate('homeScreen.noNotification')}
                </div>
            }
        </>
    );

    return (
        <div>
            <InfiniteScroll
                dataLength={notiList.length} //This is important field to render the next data
                next={() => setPage(page + 1)}
                hasMore={contentAvailableForRefresh}
                loader={<div className={styles['spinner']}><CircularProgress size={22} id={styles["spinner-style"]} /></div>}
                scrollableTarget='noti-list-root'
                endMessage={endMessage}
            >
                {notiList.map((item, key) => {
                    return (
                        <div key={key} className={styles['notification-list']}>
                            <div className={styles["noti-list-items"]}>
                                <div className={styles["noti-list-item-text-box"]} onClick={() => openDetails(item)}>
                                    <Avatar
                                        alt="Profile"
                                        src={`${item?.userDetail?.profileImg?.url?.originalUrl}`}
                                        sx={{ cursor: 'pointer' }}
                                        id={styles["noti-avatar"]}
                                    />

                                    <div className={styles['notificaion-text']}>
                                        <span><strong>{item?.userDetail?.name}</strong>{item?.msg}</span>
                                        <div className={styles["date-time"]}>{getPeriodicTime(item.createdAt)}</div>
                                    </div>
                                </div>
                                <IconButton aria-label="delete" id={styles['notification-delete']} onClick={() => deleteNoti(item.identifier)}>
                                    {deleteStatus === Status.LOADING && deletedId === item.identifier
                                        ? <CircularProgress size={20} color='error' />
                                        : <DeleteIcon className={styles["delete-icon"]} />
                                    }
                                </IconButton>
                            </div>
                            <Divider />
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
}

export default NotificationList;