import React, { useState, useEffect } from 'react';
import styles from './Banner.module.scss';
import { Avatar, IconButton, CircularProgress } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { connect } from 'react-redux';
import {
  uploadProfileImage,
  resetUploadProfileImage,
} from '../../../../store/actions';
import { isValidImage } from '../../../../utils/validations';
import { ERROR_SK, SUCCESS_SK } from '../../../../constants';
import { translate } from '../../../../i18n';
import { Snack } from '../../../../common';
import { Status } from '../../../../api';

const Banner = ({
  userDetails,
  profileImgUrl,
  uploadErrorMessage,
  uploadProfileStatus,
  uploadProfileImage: _uploadProfileImage,
  resetUploadProfileImage: _resetUploadProfileImage,
  mode,
}) => {
  let uploadInput = null;
  const [isProfileUploading, setIsProfileUploading] = useState(false);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });

  const showSnack = (message, type = SUCCESS_SK) => {
    setSnackData({
      open: true,
      type: type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const isValidMedia = (media) => {
    if (!isValidImage(media)) {
      showSnack(translate('userProfile.invalidPhoto'), ERROR_SK);
      return false;
    }
    return true;
  };

  const uploadProfile = () => {
    const image = uploadInput.files[0];
    if (image && isValidMedia(image)) {
      _uploadProfileImage(image);
    }
  };

  useEffect(() => {
    handleUploadStatus(uploadProfileStatus, setIsProfileUploading);
  }, [uploadProfileStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadStatus = (status, setLoading) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case Status.LOADING:
        setLoading(true);
        break;
      case Status.SUCCESS:
        showSnack(translate('userProfile.uploadSuccessMessage'));
        setLoading(false);
        _resetUploadProfileImage();
        break;
      case Status.ERROR:
        showSnack(uploadErrorMessage, ERROR_SK);
        setLoading(false);
        break;
    }
  };

  return (
    <>
      <div className={styles.new_user_avatar_container}>
        <div className={styles.new_user_avatar_wapper}>
          <Avatar
            alt="avater"
            src={!isProfileUploading && profileImgUrl}
            className={styles.new_user_avatar}
          >
            {isProfileUploading && (
              <CircularProgress size={22} className="loading_spinner" />
            )}
          </Avatar>

          <div>
            <IconButton
              aria-label="upload picture"
              component="label"
              className={styles.new_user_camera_icon_div}
            >
              <input
                type="file"
                accept="image/*"
                id="upload-profile-input"
                name="upload-profile-input"
                ref={(ref) => {
                  uploadInput = ref;
                }}
                onChange={uploadProfile}
                hidden
              />

              <CameraAltIcon className={styles.new_user_camera_icon} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={styles.new_user_info_div}>
        <div className={styles.new_user_name}>{userDetails?.bio?.name}</div>
        <div className={styles.new_user_address}>{userDetails?.bio?.city}</div>
      </div>
      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={handleSnackClose}
      />
    </>
  );
};

const mapStateToProps = ({ profile }) => {
  const { uploadProfileStatus, uploadErrorMessage } = profile;

  return {
    uploadProfileStatus,
    uploadErrorMessage,
  };
};

export default connect(mapStateToProps, {
  uploadProfileImage,
  resetUploadProfileImage,
})(Banner);
