import React, { useEffect, useState } from "react";
import { BUSINESS_SK, SAVE_SK } from "../../../../../../constants";
import { connect } from "react-redux";
import {
  saveFavourite,
  deleteFavourite,
} from "../../../../../../store/actions";
import { translate } from "../../../../../../i18n";
import { Status } from "../../../../../../api";
import { isNonEmptyString } from "../../../../../../utils";
import { withRouter } from "react-router";
import { IconButton } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import styles from "./ListItem.module.scss";
import { RatingStar } from "../../../../../../common";
const BusinessListItem = ({
  history,
  type,
  item,
  loggedInUserIdentifier,
  favouriteStatus,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  openSnack: _openSnack,
  showLoginPrompt: _showLoginPrompt,
}) => {
  const [flag, setFlag] = useState(false);
  const [isSaved, setIsSaved] = useState(item.isSaved);

  useEffect(() => {
    setIsSaved(item.isSaved);
    if (flag) {
      const msg =
        item.isSaved === SAVE_SK
          ? translate("homeScreen.unsaveFavouriteSuccessMessage")
          : translate("homeScreen.saveFavouriteSuccessMessage");
      _openSnack(msg);
      setFlag(false);
    }
  }, [item.isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (favouriteStatus !== Status.LOADING) {
      setFlag(true);
      if (isSaved === SAVE_SK) {
        _saveFavourite(item.identifier, BUSINESS_SK, loggedInUserIdentifier);
      } else {
        _deleteFavourite(
          item.favouriteIdentifier,
          BUSINESS_SK,
          item.identifier
        );
      }
    }
  };

  const handleClick = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`${btoa(item.identifier)}/${type}`);
  };

  return (
    <div className={styles["popular-articles-item"]}>
      <IconButton className={styles["icon"]} onClick={save}>
        {isSaved === SAVE_SK ? (
          <BookmarkBorderIcon className={styles["save-icon-style"]} />
        ) : (
          <BookmarkIcon className={styles["save-icon-style"]} />
        )}
      </IconButton>
      <div>
        <img
          alt="img"
          className={styles["hotels-image"]}
          src={
            item.isThumbGenerated
              ? item.profileImg.url.thumbUrl
              : item.profileImg.url.originalUrl
          }
        />
        <div id={styles["protected-img-business"]} onClick={handleClick}></div>
      </div>
      <div className={styles["hotels-description"]} onClick={handleClick}>
        <div className={styles["name"]}>
          <span> {item.name} </span>
        </div>
        <div className={styles["mui-rating"]}>
          <RatingStar
            rating={item.avgRating}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ home }) => {
  const { favouriteStatus } = home;
  return {
    favouriteStatus,
  };
};
export default connect(mapStateToProps, { saveFavourite, deleteFavourite })(
  withRouter(BusinessListItem)
);
