/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams, withRouter, } from 'react-router';
import {
  AppBar,
  BackIcon,
  BackToTopIcon,
  Spinner,
} from '../../common';
import {
  CssBaseline,
  Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {
  api,
  Status,
} from '../../api';
import { isNonEmptyString } from '../../utils';
import { ASYNC_STORAGE_KEYS, PLI_DETAIL_MODE_LK } from '../../constants';
import { connect } from 'react-redux';
import {
  updateReaction,
  updateCommentCount,
  resetEditPostStatus,
} from '../../store/actions';
import PostDetailItem from "./PostDetailItem/PostDetailItem";

const PostDetails = ({
  baseMediaUrl,
  baseMediaThumbUrl,
  editPostStatus,
  editedPost,
  updateReaction,
  updateCommentCount,
  resetEditPostStatus,
  history,
}) => {
  const classes = useStyles();
  const { id: _postIdentifier } = useParams();
  const postIdentifier = window.atob(_postIdentifier);
  const c = new URLSearchParams(window.location.search).get('c');
  const [post, setPost] = useState({});
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);

  useEffect(() => {
    fetchPostDetails();
  }, [postIdentifier]);

  useEffect(() => {
    if (editPostStatus === Status.SUCCESS) {
      resetEditPostStatus();
      const { visibility, description } = editedPost;
      setPost(prevState => {
        return {
          ...prevState,
          visibility,
          description,
        };
      });
    }
  }, [editPostStatus]);

  const fetchPostDetails = () => {
    setApiStatus(Status.LOADING);
    api
      .fetchPostDetails({ postIdentifier, })
      .then(({ userResponse: post }) => {
        setPost(post);
        setApiStatus(Status.SUCCESS);
      })
      .catch(error => {
        setApiStatus(Status.ERROR);
      });
  }

  if (!isNonEmptyString(loggedInUserIdentifier)) {
    return <Redirect to='/' />
  }

  if (apiStatus !== Status.SUCCESS) {
    return <Spinner />;
  }

  return (
    <>
      <div id='back-to-top-anchor' />
      <CssBaseline />
      <AppBar />
      <BackIcon isCopy={c} />
      <div className='post-details-container'>
        <Grid container >
          <Grid item xs={3} />
          <Grid item xs={6}>
            <PostDetailItem
              item={post}
              mode={PLI_DETAIL_MODE_LK}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              deletePost={() => { }}
              updateStatusInList={() => { }}
              loggedInUserIdentifier={loggedInUserIdentifier}
              sharePostStatus={''}
              sharedPostIdentifier={''}
              showLoginPrompt={() => { }}
              containerStyle={classes.pliStyle}
              updateReaction={updateReaction}
              updateCommentCount={updateCommentCount}
              setPost={setPost}
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </div>
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  pliStyle: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: '90px'
  },
}));

const mapStateToProps = ({ app, home, }) => {
  const {
    baseMediaUrl,
    baseMediaThumbUrl,
  } = app;
  const {
    editPostStatus,
    editedPost,
  } = home;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    editPostStatus,
    editedPost,
  }
};

export default connect(mapStateToProps, {
  updateReaction,
  updateCommentCount,
  resetEditPostStatus,
})(withRouter(PostDetails));