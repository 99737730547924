import { arrayOf, number, shape, string, oneOf } from 'prop-types';
import { HOTEL_SK } from '../../constants/stringConstants';
import userRelationType from './userRelationType';
import imageType from './ImageType';
import landmarkType from './landmarkType';

const hotelDetailsType = shape({
  avgRating: string,
  businessCategory: oneOf([HOTEL_SK]),
  checkInTime: string,
  checkOutTime: string,
  city: string,
  country: string,
  coverImg: imageType,
  description: string,
  district: string,
  email: string,
  hotelAmenities: arrayOf(
    shape({
      name: string,
      iconUrl: string,
    }),
  ),
  identifier: string,
  interiorAlbumFiles: arrayOf(imageType),
  landmarks: arrayOf(landmarkType),
  latitude: number,
  longitude: number,
  mobileNumber: string,
  name: string,
  networkStatusWithCurrentUser: userRelationType,
  profileImg: imageType,
  roomFeatures: arrayOf(string),
  state: string,
  streetAddress: string,
  tagLine: string,
  webSiteUrl: string,
});

export default hotelDetailsType;
