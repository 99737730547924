/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Divider,
  CardActions,
  Alert as MuiAlert,
  Snackbar,
  IconButton,
} from '@mui/material';

import {
  PLI_HOME_MODE_LK,
  PLI_PROFILE_MODE_LK,
  POST_SK, SAVE_SK,
  // PUBLIC_SK,
  // PRIVATE_SK,
  // ONLY_ME_SK,
  // PLI_HOME_MODE_LK,
  // PLI_HISTORY_MODE_LK,
  // PLI_DETAIL_MODE_LK,
  // PLI_PROFILE_MODE_LK,
  // BLOGGER_SK,
  // BLOG_SK,
} from '../../constants/stringConstants';
import { translate } from '../../i18n';

import ReactionList from './Reaction/ReactionList';
import ReactionPopUp from './Reaction/ReactionPopUp';
import CopyLinkIcon from '../CopyLinkIcon/CopyLinkIcon';
// import EditShareModal from '../EditShareModal/EditShareModal';
// import CommentList from '../../components/HomeStack/CommentList/CommentList';

// import guestProfileImg from '../../assets/images/guest_profile.png';
// import ICON_SHARE from '../../assets/images/icon_share.png';
// import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';

import { buttonRoleProps } from '../../utils/buttonRoleProp';
import ReactionCount from './Reaction/ReactionCount/ReactionCount';
import styles from './FooterSection.module.scss';
import { isNonEmptyString } from '../../utils';

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Status, api } from '../../api';
import { saveFavourite, deleteFavourite } from "../../store/actions";
import { ASYNC_STORAGE_KEYS } from '../../constants';
import SnackBar from "@mui/material/Snackbar";
import { Modal, makeStyles } from '@material-ui/core';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FooterSection = ({
  /**
   * Mode tells in which screen PostListItem component is used.
   *
   * It could be
   * 1. Home screen
   * 2. History screen
   * 3. Post Detail screen aka CommentListScreen
   * 4. Friends Profile screen
   * 5. Business profile Screen
   */
  mode,
  // isShared,
  // sharedType,
  // childPostVisibility,
  /**
   * If there is a parent post, parentPostVisibility will point to
   * parent post visibility
   */
  //parentPostVisibility,
  loggedIn,
  identifier,
  loggedInUserIdentifier,
  /**
   * User id of the post creator
   */
  //postOwnerIdentifier,
  /**
   * Web url of the post
   */
  url,
  likesCount,
  // sharesCount,
  // commentsCount,
  reaction,
  // reactionLoading,
  // incrementShareCount,
  updateReaction,
  // changeCommentsCount,
  // historyCommentLogic,
  // profileImg,
  // userRole,
  // parentPostIdentifier,
  // history,
  showLoginPrompt: _showLoginPrompt,
  reloadProfileScreen: _reloadProfileScreen,
  item,
  favouriteStatus,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  entityIdentifier,
  setPosts,
  baseMediaUrl,
  savePostInBusiness
}) => {
  const classes = useStyles();
  const [openReactionList, setOpenReactionList] = useState(false);
  //const [openShareModal, setOpenShareModal] = useState(false);
  //const [openComment, setOpenComment] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  // const [isSaved, setIsSaved] = useState(item.isSaved);
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   setIsSaved(item.isSaved);
  //   setIsOpen(true);
  // }, [item.isSaved]);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  //   const profileImgUrl = `${profileImg.isThumbGenerated
  //     ? baseMediaThumbUrl
  //     : baseMediaUrl
  //     }${profileImg.url}`;

  //   const handleOpenShareModal = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenShareModal(true);
  //   }

  const handleOpenReactionList = (event) => {
    if (!loggedIn) {
      _showLoginPrompt();
      return;
    }
    setOpenReactionList(!openReactionList);
  };

  const handleCloseReactionList = () => {
    setOpenReactionList(false);
  }

  //   const handleCloseShareModal = () => {
  //     setOpenShareModal(false);
  //   }

  //   const openProfile = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //     }
  //     if (mode === PLI_PROFILE_MODE_LK) {
  //       if (postOwnerIdentifier !== loggedInUserIdentifier) {
  //         history.replace(`/user?id=${btoa(loggedInUserIdentifier)}`);
  //         _reloadProfileScreen(loggedInUserIdentifier);
  //       }
  //     }
  //     else {
  //       history.push(`/user?id=${btoa(loggedInUserIdentifier)}`);
  //     }

  //   }

  //   const openCommentList = () => {
  //     if (!loggedIn) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     setOpenComment(true);
  //   }

  //   const renderAvatar = () => {
  //     if (!loggedIn) {
  //       return (
  //         <Avatar
  //           id='pli-avatar'
  //           src={guestProfileImg}
  //           className={classes.avatar}
  //           onClick={openProfile}
  //         />
  //       );
  //     }
  //     else if (userRole !== BLOGGER_SK) {
  //       return (
  //         <Avatar
  //           id='pli-avatar'
  //           src={profileImgUrl}
  //           className={classes.avatar}
  //           onClick={openProfile}
  //         />
  //       );
  //     }
  //     else {
  //       return (
  //         <Badge
  //           overlap="circular"
  //           anchorOrigin={{
  //             vertical: 'bottom',
  //             horizontal: 'right'
  //           }}
  //           badgeContent={<img
  //             id='pli-badge'
  //             alt='badgeimg'
  //             src={bloggerMarkIcon}
  //             className={classes.badge}
  //           />}
  //         >
  //           <Avatar
  //             id='pli-avatar'
  //             src={profileImgUrl}
  //             className={classes.bloggerAvatar}
  //             onClick={openProfile}
  //           />
  //         </Badge>
  //       )
  //     }
  //   };

  //   const renderShareIcon = () => {
  //     let visibility = isShared ? parentPostVisibility : childPostVisibility;
  //     if (visibility !== PUBLIC_SK && visibility !== BLOG_SK) {
  //       return null;
  //     }
  //     return (
  //       <img
  //         id='pli-share-icon'
  //         alt='img'
  //         src={ICON_SHARE}
  //         className={classes.actionIconSm}
  //       />
  //     );
  //   }

  const save = (item) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }

    if (mode === PLI_HOME_MODE_LK) {
      if (favouriteStatus !== Status.LOADING) {
        if (item.isSaved === SAVE_SK) {
          _saveFavourite(item.identifier, POST_SK, loggedInUserIdentifier);
        } else {
          _deleteFavourite(item.favouriteIdentifier, POST_SK, item.identifier);
        }
      }
    } else if (mode === PLI_PROFILE_MODE_LK) {
      if (favouriteStatus !== Status.LOADING) {
        if (item.isSaved === SAVE_SK) {
          savePostInBusiness({ identifier: item.identifier });
        } else {
          savePostInBusiness({ identifier: item.identifier, favouriteIdentifier: item.favouriteIdentifier });
        }
      }
    }
  };

  return (
    <>
      {/* <CommentList
        open={openComment}
        handleClose={() => setOpenComment(false)}
        postIdentifier={identifier}
        postOwnerIdentifier={postOwnerIdentifier}
        updateTotalCommentsInPostUI={changeCommentsCount}
      /> */}
      {/* <Dialog
        open={openReactionList}
        onClose={handleCloseReactionList}
        className={styles.reactionListModal}
      > */}
      {/* <ReactionList
        open={openReactionList}
        entityIdentifier={identifier}
        entityType={POST_SK}
        closeReactionList={handleCloseReactionList}
      /> */}

      <Modal
        open={openReactionList}
        onClose={handleCloseReactionList}
        className={classes.reactionListModal}
      >
        <>
          <ReactionList
            entityIdentifier={identifier}
            entityType={POST_SK}
            baseMediaUrl={baseMediaUrl}
          />
        </>
      </Modal>

      {/* <EditShareModal
        open={openShareModal}
        mode='share'
        identifier={isShared ? parentPostIdentifier : identifier}
        type={isShared ? sharedType : POST_SK}
        isShared={isShared}
        incrementNumberOfShare={incrementShareCount}
        handleClose={handleCloseShareModal}
      /> */}
      <Divider className={styles.divider} />
      <CardActions disableSpacing className={styles.root}>
        <div className={styles["footer-icon-group"]}>
          <ReactionPopUp
            loggedIn={loggedIn}
            currentSelection={reaction}
            setSelectedReaction={updateReaction}
            showLoginPrompt={_showLoginPrompt}
          />
          {/* {renderShareIcon()} */}
          <CopyLinkIcon text={`${url}?c=1`} onCopy={() => setSnackOpen(true)} />
          <IconButton
            id={styles["recommend-save-icon"]}
            onClick={() => save(item)}
          >
            {item.isSaved === SAVE_SK ? (
              <BookmarkBorderIcon className={styles["save-icon-style"]} />
            ) : (
              <BookmarkIcon className={styles["save-icon-style"]} />
            )}
          </IconButton>
        </div>
        <ReactionCount likes={likesCount} />
        <div
          {...buttonRoleProps}
          id='reaction-info'
          aria-label='Total Reaction Count'
          onClick={handleOpenReactionList}
        >
          <span className={styles["text-footer"]}>
            {likesCount > 0 && (
              <>
                <ReactionCount likesCount={likesCount} />
                {` ${likesCount > 1
                  ? translate('common.reacts')
                  : translate('common.react')
                  } `}
              </>
            )}
          </span>
          {/* <span>
            {commentsCount > 0 && (
              <span onClick={openCommentList}>
                {`${commentsCount} ${commentsCount > 1
                  ? translate('common.comments')
                  : translate('common.ncomment')
                  } `}
              </span>
            )}
          </span> */}
          {/* <span>
            {sharesCount > 0 && (
              <span>
                {`${sharesCount} ${sharesCount > 1
                  ? translate('common.shares')
                  : translate('common.nshare')
                  } `}
              </span>
            )}
          </span> */}
        </div>
      </CardActions>
      {/* <Divider light />
      <div className={classes.commentSection}>
        <div>
          {renderAvatar()}
        </div>
        <div className='comment-box'
          onClick={openCommentList}
        >
          <InputBase
            value=''
            id='comment-input'
            placeholder={translate('common.addComment')}
            inputProps={{ 'aria-label': 'naked' }}
          />
        </div>
      </div> */}
      {favouriteStatus === Status.SUCCESS &&
        item.identifier === entityIdentifier && (
          <SnackBar
            id={styles["my-snack"]}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={isOpen}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              className={styles["alertColor"]}
            >
              {item.isSaved === SAVE_SK
                ? translate("homeScreen.unsaveFavouriteSuccessMessage")
                : translate("homeScreen.saveFavouriteSuccessMessage")}
            </Alert>
          </SnackBar>
        )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          className={styles.alertColor}
        >
          {translate('postListItem.copyLinkSuccessMessage')}
        </Alert>
      </Snackbar>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    width: "26px",
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    cursor: "pointer",
  },
  bloggerAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const mapStateToProps = ({ app, profile, home }) => {

  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { favouriteStatus, entityIdentifier, favouriteIdentifier } = home;
  const { userDetails: { bio: { profileImg, userRole } = {} } = {} } = profile;

  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    profileImg,
    userRole,
    favouriteStatus,
    entityIdentifier,
    favouriteIdentifier,
  };
};

export default connect(mapStateToProps, { saveFavourite, deleteFavourite })(withRouter(FooterSection));
