import React, { useState, useEffect, useRef } from "react";

import {
    Popper,
    Zoom
} from "@mui/material";

import {
    WANDER_TO_GO_SK,
    INTERESTED_SK,
    LOVE_SK,
    NONE_SK,
} from "../../../constants/stringConstants";

import WONDER_TO_GO_DEFAULT from "../../../assets/images/icon_travel.png";
import WONDER_TO_GO from "../../../assets/images/icon_travel_active.png";
import INTERESTED from "../../../assets/images/icon_interested_active.png";
import LOVE from "../../../assets/images/icon_heart_active.png";

import styles from "./ReactionPopUp.module.scss";
import { buttonRoleProps } from "../../../utils/buttonRoleProp";

const ReactionPopUp = ({
    currentSelection,
    loggedIn,
    setSelectedReaction,
    showLoginPrompt,
}) => {

    const reacts = [
        {
            id: WANDER_TO_GO_SK,
            img: WONDER_TO_GO
        },
        {
            id: INTERESTED_SK,
            img: INTERESTED
        },
        {
            id: LOVE_SK,
            img: LOVE
        },
    ];

    const containerRef = useRef(null);

    const [selected, setSelected] = useState(currentSelection);
    const [anchorEl, setAnchorEl] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        /* Clear the timeout when the component unmounts */
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const loginAlert = () => {
        showLoginPrompt();
    };

    const reaction = (selectedReaction) => {
        if (!loggedIn) {
            loginAlert();
            return;
        }
        setSelectedReaction(selectedReaction);
        setSelected(selectedReaction);
        setAnchorEl(null);
    };

    const removeReact = (event) => {
        if (!loggedIn) {
            loginAlert();
            return;
        }
        if (event.key !== "Tab") {
            const selectedReaction = selected !== NONE_SK ? NONE_SK : WANDER_TO_GO_SK;
            setSelectedReaction(selectedReaction);
            setSelected(selectedReaction);
            setAnchorEl(null);
        }
    };

    const getReactionIcon = (selected) => {
        switch (selected) {
            case WANDER_TO_GO_SK:
                return WONDER_TO_GO;
            case INTERESTED_SK:
                return INTERESTED;
            case LOVE_SK:
                return LOVE;
            default:
                return WONDER_TO_GO_DEFAULT;
        }
    };

    const handleOpen = (event) => {
        if (loggedIn) {
            /* Cancel the timeout when mouse hover */
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
            }
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        /* Set a timeout to close Popper after 500 ms */
        const id = setTimeout(() => {
            setAnchorEl(null);
        }, 500);
        setTimeoutId(id);
    };

    const open = Boolean(anchorEl);
    const id = open ? "spring-popper" : undefined;

    return (
        <div
            className={styles.reactIconBox}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            ref={containerRef}
        >
            <div
                {...buttonRoleProps}
                className={styles.reactContainer}
                aria-label="Raction"
                onClick={removeReact}
            >
                <img
                    className={styles.reactIcon}
                    alt="img"
                    src={getReactionIcon(selected)}
                    aria-describedby={id}
                />
            </div>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="top-start"
            >
                {() => (
                    <Zoom in={open} container={containerRef.current}>
                        <div className={styles.reactionPopup} >
                            {reacts.map((react, key) => {
                                return (
                                    <div
                                        key={key}
                                        id={react.id}
                                        className={styles.reactionIconContainer}
                                        onClick={() => reaction(react.id)}
                                    >
                                        <img
                                            alt="img"
                                            src={react.img}
                                            className={
                                                react.id === LOVE_SK
                                                    ? styles.reactionIconSm
                                                    : styles.reactionIcon
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Zoom>
                )}
            </Popper>
        </div>
    );
};

export default ReactionPopUp;
