import React, { useEffect, useState, cloneElement } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  AppBar as MaterialAppBar,
  Autocomplete,
  Badge,
  Box,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Switch,
  Collapse,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  useScrollTrigger,
  Avatar,
  ThemeProvider,
  createTheme,
  Popover
} from "@mui/material";

import {
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountBox as AccountBoxIcon,
  Close as CloseIcon,
  InventoryOutlined as InventoryOutlinedIcon,
  MailOutlined as MailOutlinedIcon,
  Logout as LogoutIcon,
  NotificationsNone as NotificationsNoneIcon
} from '@mui/icons-material';

import { changeLocale, translate } from '../../i18n';
import { api, Status } from '../../api';
import { isNonEmptyString, loadState } from '../../utils';
import {
  getUserDetails,
  logout,
  editProfile,
  resetRemoteNotification
} from '../../store/actions';
import {
  ASYNC_STORAGE_KEYS,
  BLOGGER_SK,
  BURMESE_LANGUAGE_CODE_LK,
  BURMESE_SK,
  CURRENT_USER_LK,
  ENGLISH_LANGUAGE_CODE_LK,
  ENGLISH_SK,
  PLI_PROFILE_MODE_LK,
} from '../../constants';

import AlertDialog from '../AlertDialog/AlertDialog';
import NotificationList from './Notification/NotificationList';
import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';
import logo from '../../assets/images/logo.png';
import styles from './AppBar.module.scss';
import colors from "../../css/variable.scss";

const DesktopSection = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('700')]: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const MobileSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('700')]: {
    display: 'none',
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: 'inherit',
    '& fieldset': {
      border: 0,
    },
  },
  [theme.breakpoints.up('992')]: {
    '& .Mui-focused': {
      width: '35vw',
    },
  },
}));

const commonSwitchStyles = {
  switchBase: {
    color: colors.whiteColor,
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: colors.whiteColor,
    },
  },
};

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        ...commonSwitchStyles,
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: colors.whiteColor,
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            backgroundColor: colors.whiteColor
          }
        }
      },
    },
  },
});

const mobileTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        ...commonSwitchStyles,
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: colors.grayColor,
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            backgroundColor: colors.grayColor,
          }
        }
      },
    },
  },
});

const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 30,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      backgroundColor: colors.primaryColor,
      color: colors.whiteColor,
      transition: trigger ? '0.3s' : '0.5s',
      boxShadow: 'none',
    },
  });
};

const LanguageSwitch = ({ mode = 'desktop', changeLanguage }) => {

  const preferredLanguage = loadState(ASYNC_STORAGE_KEYS.userPreferedLangage);

  const [checked, toggleChecked] = useState(preferredLanguage === BURMESE_SK);

  const handleChange = (event) => {
    toggleChecked(event.target.checked);
    changeLanguage(event.target.checked ? BURMESE_SK : ENGLISH_SK);
  };

  return (
    <div className={styles.switchContainer}>
      <span className={styles.switchLabel}>
        {mode === 'mobile'
          ? translate('common.en')
          : translate('common.english')}
      </span>
      <ThemeProvider theme={mode !== 'mobile' ? theme : mobileTheme}>
        <Switch
          inputProps={{ 'aria-label': 'controlled' }}
          checked={checked}
          onChange={handleChange}
        />
      </ThemeProvider>
      <span className={styles.switchLabel}>
        {mode === 'mobile'
          ? translate('common.my')
          : translate('common.burmese')}
      </span>
    </div>
  );
};

const AppBar = ({
  mode,
  profileType,
  history,
  userDetails,
  getUserDetails: _getUserDetails,
  logout: _logout,
  reload: _reload,
  editProfile: _editProfile,
  logoutStatus,
  newNotiCount,
  resetRemoteNotification: _resetRemoteNotification
}) => {

  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);
  const keyword = new URLSearchParams(window.location.search).get('q');

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState(keyword);
  const [suggestions, setSuggestions] = useState([]);
  const [isLanChanged, setIsLanChanged] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputKeyword, setInputKeyword] = useState("");
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const ENTER_KEY = 'Enter';

  const profileImgUrl = userDetails?.bio?.profileImg?.url
    ? userDetails?.profileImg?.isThumbGenerated
      ? userDetails?.bio?.profileImg?.url?.thumbUrl
      : userDetails?.bio?.profileImg?.url?.originalUrl
    : '';

  useEffect(() => {
    if (keyword) {
      setOpen(false);
    }
  }, [keyword]);

  useEffect(() => {
    if (logoutStatus === Status.SUCCESS) {
      history.replace('/');
      window.location.reload();
    }
  }, [logoutStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isNonEmptyString(searchText)) {
      doSearch();
      setOpen(false)
    }
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  // UseEffect to clear the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  useEffect(() => {
    if (isLanChanged) {
      window.location.reload();
    }
  }, [isLanChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newValue) => {
    if (/^\s+$/.test(newValue)) {
      return;
    }
    setSearchText(newValue.trim());
  }

  const handleInputChange = (event, newValue) => {

    setInputKeyword(newValue);

    if (/^\s+$/.test(newValue)) {
      return;
    }

    if (!isNonEmptyString(newValue)) {
      setSearchText(newValue.trim());
    }

    if (newValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    // Clear the previous timer
    if (timerId) {
      clearTimeout(timerId);
    }

    /**
     * The auto-suggestion API call after a delay of 300ms once the user stops typing. 
     * If the user continues typing before the 300ms delay, the timer resets, and the new delay starts again 
     *  */
    const newTimerId = setTimeout(() => {
      showAutoSuggestion(newValue);
    }, 300);

    setTimerId(newTimerId);
  };

  const showAutoSuggestion = (value) => {
    if (value.length < 3) {
      setSuggestions([]);
      return;
    }
    api
      .getAutoSuggestion({ keyword: value })
      .then(({ userResponse: { suggestionList } }) => {
        setSuggestions(suggestionList);
      })
      .catch(() => { });
  };

  const doSearch = () => {
    const encodedKeyword = encodeURIComponent(searchText);
    history[mode === "search" ? 'replace' : 'push'](`/search?q=${encodedKeyword}`);
  };

  /**
   * Function to open AutoSuggestion List
   */
  const handleOpen = () => {
    if (inputKeyword.length > 0) {
      setOpen(true);
    }
  };

  /**
   * Function to close AutoSuggestion List
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Function to close Search box in Mobile Section
   */
  const handleClick = () => {
    setOpenDialog(!openDialog);
  };

  const changeLanguage = (language) => {
    changeLocale(
      language === BURMESE_SK
        ? BURMESE_LANGUAGE_CODE_LK
        : ENGLISH_LANGUAGE_CODE_LK
    );
    localStorage.setItem(ASYNC_STORAGE_KEYS.userPreferedLangage, language);
    // Check if the loggedInUserIdentifier is a non-empty string
    if (isNonEmptyString(loggedInUserIdentifier)) {
      // Call the editProfile API to update user profile settings
      api.editProfile(
        {
          userDetailsObjectsToBeUpdate: {
            settings: {
              language, // Update the language setting
            },
          }
        },
      ).then(() => {
        // If the API call is successful, set the flag indicating that the language has been changed
        setIsLanChanged(true);
      }).catch((error) => { });
    } else {
      window.location.reload();
    }
  };

  const openProfile = () => {
    if (mode === PLI_PROFILE_MODE_LK && profileType !== CURRENT_USER_LK) {
      history.replace(`/user?id=${btoa(loggedInUserIdentifier)}`);
      _reload(loggedInUserIdentifier);
    } else {
      history.push(`/user?id=${btoa(loggedInUserIdentifier)}`);
    }
  };

  const openNotification = (event) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }
    setNotificationAnchorEl(event.currentTarget);
    _resetRemoteNotification();
  };

  const closeNotification = () => {
    setNotificationAnchorEl(null);
  };

  const goToAuth = () => {
    history.push('login');
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleBack = (event) => {
    if (
      event.key === ENTER_KEY &&
      !isNonEmptyString(event.target.value) &&
      mode === 'search'
    ) {
      history.goBack();
    }
  };

  const goToPrivacyPolicy = () => {
    history.push('/terms-policy');
  };

  const goToInbox = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }
    history.push('/inbox');
  };

  const handleLogout = () => {
    setAnchorEl(null);
    _logout();
  };

  const showLoginPrompt = () => {
    let data = {
      title: translate('common.loginPromtDialogTitle'),
      description: translate('common.loginPromtDialogMessage'),
      positiveButtonCallback: () => {
        history.push('register-login');
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const openNotiList = Boolean(notificationAnchorEl);
  const id = openNotiList ? 'simple-popover' : undefined;

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={goToPrivacyPolicy}
        className={styles.popover_text_default}
      >
        <ListItemIcon>
          <InventoryOutlinedIcon className={styles.menuIcon} fontSize="small" />
        </ListItemIcon>
        {translate('privacyPolicy.title')}
      </MenuItem>
      <MenuItem onClick={goToInbox} className={styles.popover_text_default}>
        <ListItemIcon>
          <MailOutlinedIcon className={styles.menuIcon} fontSize="small" />
        </ListItemIcon>
        {translate('common.inbox')}
      </MenuItem>
      {isNonEmptyString(loggedInUserIdentifier) && (
        <MenuItem
          onClick={handleLogout}
          className={styles.popover_text_default}
        >
          <ListItemIcon>
            <LogoutIcon className={styles.menuIcon} fontSize="small" />
          </ListItemIcon>
          {translate('common.logout')}
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'long-menu';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={styles.popover_text_default}>
        <LanguageSwitch
          mode="mobile"
          changeLanguage={changeLanguage}
        />
      </MenuItem>
      <MenuItem
        onClick={
          isNonEmptyString(loggedInUserIdentifier) ? openProfile : goToAuth
        }
        className={styles.popover_text_default}
      >
        <ListItemIcon>
          <AccountBoxIcon className={styles.menuIcon} fontSize="small" />
        </ListItemIcon>
        {translate('common.account')}
      </MenuItem>
      <MenuItem
        onClick={goToPrivacyPolicy}
        className={styles.popover_text_default}
      >
        <ListItemIcon>
          <InventoryOutlinedIcon className={styles.menuIcon} fontSize="small" />
        </ListItemIcon>
        {translate('privacyPolicy.title')}
      </MenuItem>
      <MenuItem onClick={goToInbox} className={styles.popover_text_default}>
        <ListItemIcon>
          <MailOutlinedIcon className={styles.menuIcon} fontSize="small" />
        </ListItemIcon>
        {translate('common.inbox')}
      </MenuItem>
      {isNonEmptyString(loggedInUserIdentifier) && (
        <MenuItem
          onClick={handleLogout}
          className={styles.popover_text_default}
        >
          <ListItemIcon>
            <LogoutIcon className={styles.menuIcon} fontSize="small" />
          </ListItemIcon>
          {translate('common.logout')}
        </MenuItem>
      )}
    </Menu>
  );

  const renderLogo = () => (
    <div className={styles['logo-container']}>
      <img alt="logo" src={logo} onClick={() => history.push('/')} />
    </div>
  );

  const renderDesktopSection = () => (
    <>
      <Box className={styles.searchBox}>
        <Autocomplete
          id={"smoove-search"}
          value={searchText}
          blurOnSelect="touch"
          aria-label='search'
          filterSelectedOptions={true}
          open={open}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          onInputChange={handleInputChange}
          onKeyDown={handleBack}
          freeSolo
          disableClearable
          options={suggestions.map((option) => option.term)}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <CssTextField
              {...params}
              size="small"
              aria-label="search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 0 }}>
                    <SearchIcon className={styles.searchIcon} />
                  </InputAdornment>
                ),
              }}
              className={`${styles.inputBaseStyles} ${inputKeyword && styles.searchInput}`}
            />
          )}
        />
      </Box>
      <LanguageSwitch changeLanguage={changeLanguage} />
      {!isNonEmptyString(loggedInUserIdentifier) ? (
        <div className={styles["guest-account"]}>
          <AccountBoxIcon className={styles.accountIcon} onClick={goToAuth} />
          <span className={styles.accountLabel} onClick={goToAuth}>
            {translate('common.account')}
          </span>
        </div>
      ) : (
        <span>
          {userDetails?.bio.userRole === BLOGGER_SK ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <img
                  id="profile-badge"
                  alt="badgeimg"
                  src={bloggerMarkIcon}
                  className={styles.badge}
                />
              }
            >
              <Avatar
                src={profileImgUrl}
                className={styles.bloggerAvatar}
                onClick={openProfile}
                alt="Profile"
                aria-label={userDetails.bio.name}
              />
            </Badge>
          ) : (
            <Avatar
              aria-label={userDetails.bio.name}
              src={profileImgUrl}
              className={styles.post_avatar}
              onClick={openProfile}
              alt="Profile"
            ></Avatar>
          )}
        </span>
      )}
      <IconButton
        edge="start"
        aria-haspopup="true"
        color="inherit"
        aria-describedby='simple-popover'
        variant="contained"
        onClick={openNotification}
        id={styles["app-bar-icon-btn"]}
      >
        <Badge
          badgeContent={newNotiCount}
          color="error"
        >
          <NotificationsNoneIcon className={styles.notiIcon} />
        </Badge>
      </IconButton>
      <IconButton
        edge="start"
        aria-label="Menu"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        id={styles["app-bar-icon-btn"]}
      >
        <MenuIcon />
      </IconButton>
    </>
  );

  const renderMobileSection = () => (
    <>
      <Collapse in={openDialog} timeout="auto" unmountOnExit>
        <Autocomplete
          id="mobile-search"
          value={searchText}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleBack}
          freeSolo
          disableClearable
          options={suggestions.map((option) => option.term)}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <div id={styles['css-text-box']}>
              <CssTextField
                {...params}
                size="small"
                aria-label="search"
                placeholder="Search..."
                InputProps={{
                  ...params.InputProps,
                }}
                className={styles.inputBaseStyles}
              />
              <CloseIcon onClick={handleClick} />
            </div>
          )}
        />
      </Collapse>

      <IconButton
        position="start"
        xs={{ marginRight: 0 }}
        id={styles["app-bar-icon-btn"]}
        onClick={handleClick}
      >
        <SearchIcon className={styles.searchIcon} />
      </IconButton>
      <IconButton
        edge="start"
        aria-haspopup="true"
        color="inherit"
        aria-describedby='simple-popover'
        variant="contained"
        onClick={openNotification}
        id={styles["app-bar-icon-btn"]}
      >
        <Badge
          badgeContent={newNotiCount}
          color="error"
        >
          <NotificationsNoneIcon className={styles.notiIcon} />
        </Badge>
      </IconButton>
      <IconButton
        edge="start"
        aria-label="Menu"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="inherit"
        id={styles["app-bar-icon-btn"]}
      >
        <MenuIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <ElevationScroll>
        <MaterialAppBar id={styles["app-bar-container"]}>
          <Grid container className={styles.homeWappper}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Toolbar className={styles.toolbar}>
                {renderLogo()}
                <DesktopSection id='section-desktop'>
                  {renderDesktopSection()}
                </DesktopSection>
                <MobileSection id='section-mobile'>
                  {renderMobileSection()}
                </MobileSection>
              </Toolbar>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {renderMobileMenu}
          {renderMenu}
        </MaterialAppBar>
      </ElevationScroll>
      <Popover
        id='simple-popover'
        open={openNotiList}
        anchorEl={notificationAnchorEl}
        onClose={closeNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          id: 'noti-list-root'
        }}
      >
        <div className={styles['notification_box']}>
          <NotificationList history={history} />
        </div>
      </Popover>
      <AlertDialog open={alertOpen} data={alertData} />
    </>
  );
};

const mapStateToProps = ({ profile, auth, home }) => {
  const {
    userIdentifier,
    userDetails,
    userDetailsStatus,
    editProfileStatus
  } = profile;
  const {
    remoteNotificationReceived,
    newNotiCount,
  } = home;
  const { logoutStatus } = auth;

  return {
    userIdentifier,
    userDetails,
    userDetailsStatus,
    logoutStatus,
    editProfileStatus,
    remoteNotificationReceived,
    newNotiCount
  };
};

export default connect(mapStateToProps, {
  getUserDetails,
  logout,
  editProfile,
  resetRemoteNotification
})(withRouter(AppBar));
