/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import styles from '../../../components/AttractionDetails/AttractionDetails.module.scss';
import { withRouter } from 'react-router';
import { ATTRACTION_SK, ENGLISH_LANGUAGE_CODE_LK } from '../../../constants';
import RatingStar from '../../RatingStar/ratingstar';
import { getCurrentLocale } from '../../../i18n';

const useStyles = makeStyles((theme) => ({
  star: {
    paddingTop: '8px',
  },
  des_card: {
    position: 'relative',
  },
  shortDescription: {
    paddingBottom: '13%',
  },
  description_text: {
    fontSize: '18px',
    color: 'black',
    paddingBottom: '5%',
  },
  img: {
    width: '100%',
    height: 170,
    borderRadius: 5,
    objectFit: 'cover',
    position: 'relative',
    pointerEvent: 'none !important',
  },
  img_grid: {
    margin: '10% 0% 2% 2%',
    alignItems: 'center',
    position: 'relative',
  },
  protected_img: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  info: {
    margin: '10% 4% 2% 4%',
  },
  carousel_card: {
    cursor: 'pointer',
    borderRadius: 10,
    padding: '0px 0% 0px 0%',
  },
  card_content: {
    padding: '0px 4px 16px 4px !important',
  },
  carousel_div: {
    borderRadius: 10,
  },

  '@media screen and (max-width: 1279px)': {
    __expression__: 'screen and (max-width: 1279px)',
    img: {
      height: '171px !important',
    },
  },

  '@media screen and (max-width: 959px)': {
    __expression__: 'screen and (max-width: 959px)',
    img: {
      height: '211px !important',
    },
  },

  '@media screen and (max-width: 830px)': {
    __expression__: 'screen and (max-width: 830px)',
    img: {
      height: '171px !important',
    },
  },
  '@media screen and (max-width: 742px)': {
    __expression__: 'screen and (max-width: 742px)',
    img: {
      height: '191px !important',
    },
  },
  '@media screen and (max-width: 590px)': {
    __expression__: 'screen and (max-width: 590px)',
    img: {
      height: '151px !important',
    },
  },

  '@media screen and (max-width: 500px)': {
    __expression__: 'screen and (max-width: 500px)',
    img: {
      height: '210px !important',
    },
  },
  '@media screen and (max-width: 400px)': {
    __expression__: 'screen and (max-width: 400px)',
    img: {
      height: '131px !important',
    },
  },
  '@media screen and (max-width: 320px)': {
    __expression__: 'screen and (max-width: 320px)',
    img: {
      height: '101px !important',
    },
  },
}));
const LandmarkByAttractionItem = (props) => {
  const { item, history, fetchDetails, setLanguage } = props;
  const classes = useStyles();
  const goToAttraction = (e) => {
    history.replace(`/${btoa(item.identifier)}/${ATTRACTION_SK}`);
    setLanguage(getCurrentLocale());
    fetchDetails(item.identifier);
  };

  return (
    <Card className={classes.carousel_card} onClick={goToAttraction}>
      <CardContent className={classes.card_content}>
        <div className={classes.carousel_div}>
          <Grid container>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <div className={classes.img_grid}>
                <img
                  alt="img"
                  src={
                    item?.coverImg?.isThumbGenerated
                      ? item?.coverImg?.url?.thumbUrl
                      : item?.coverImg?.url?.originalUrl
                  }
                  className={classes.img}
                />
                <div className={classes.protected_img}></div>
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={6} xs={12}>
              <div className={classes.info}>
                <div id={styles['landmark-item-title']}>{item.name}</div>
                <div id={styles['landmark-item-desc']}>
                  {item.shortDescription}
                </div>
                <div className={classes.star}>
                  <RatingStar
                    rating={item.avgRating}
                    size="small"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};
const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(LandmarkByAttractionItem));
