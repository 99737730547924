import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import styles from "../AccountControlTab.module.scss";
import { translate } from '../../../../../i18n';
import { Status, api } from '../../../../../api';
import { useSelector } from 'react-redux';
import Snack from '../../../../../common/Snack/Snack';
import { DEACTIVATE_LK } from '../../../../../constants';
import { history } from '../../../../../store';

const DeleteDeactivateConfirmationModal = ({
    open,
    verifiedState,
    reason,
    accountControlType,
    handleClose,
    userName,
    deactivatedTime
}) => {
    const { userDetails } = useSelector((state) => state.profile);

    const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
    const [snackOpen, setSnackOpen] = useState(false);
    const [apiSuccessMsg, setApiSuccessMsg] = useState("");

    useEffect(() => {
        if (apiStatus === Status.SUCCESS) {
            handleClose();
            localStorage.clear();
            history.push("/");
            window.location.reload();
        }
    }, [apiStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleConfirm = () => {
        setApiStatus(Status.LOADING);
        api.userAccountControl({
            accountControlType,
            userIdentifier: verifiedState?.userIdentifier,
            userControl: verifiedState?.userControl,
            reason,
            deactivatePeriod: deactivatedTime
        }).then(({ userResponse }) => {
            setSnackOpen(true);
            setApiSuccessMsg(userResponse);
            setApiStatus(Status.SUCCESS);
        }).catch(() => {
            setApiStatus(Status.ERROR);
        });
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirmation-dialog-title"
            >
                <IconButton
                    aria-label="close"
                    className={styles["close-button"]}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle className={styles["confirmation-dialog-title"]}>
                    {accountControlType === DEACTIVATE_LK
                        ? translate("editProfile.accountDeactivateConfirmationTitle").replace(/%s/g, userDetails.bio.name)
                        : translate("editProfile.accountDeleteConfirmationTitle").replace(/%s/g, userDetails.bio.name)}
                </DialogTitle>
                <DialogContent className={styles["confirmation-dialog-content"]} >
                    {accountControlType === DEACTIVATE_LK
                        ? translate("editProfile.accountDeactivateConfirmationMsg")
                        : <p dangerouslySetInnerHTML={{ __html: translate("editProfile.accountDeleteConfirmationMsg").replace(/%s/g, userName) }} />}
                </DialogContent>
                <DialogActions
                    id={styles["confirmation-dialog-btn-container"]}
                    onClick={() => handleConfirm()}
                >
                    {apiStatus === Status.LOADING
                        ? <CircularProgress className={styles["loading-spinner-modal"]} size={20} />
                        : accountControlType === DEACTIVATE_LK
                            ? translate("editProfile.deactivateAccount")
                            : translate("editProfile.deleteAccount")}
                </DialogActions>
            </Dialog>
            <Snack open={snackOpen} message={apiSuccessMsg} />
        </div>
    );
}


export default DeleteDeactivateConfirmationModal;