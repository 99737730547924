import React from "react";
import styles from "./EndMessage.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { translate } from "../../i18n";

const EndMessage = () => {
    return <>
        <div className={styles["end-message-info"]}>
            <CheckCircleOutlineIcon />
            <div className={styles["end-message"]}>{translate('common.endMessage')}</div>
        </div>
    </>
}

export default EndMessage;