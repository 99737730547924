/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { ASYNC_STORAGE_KEYS } from '../../constants';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import MenuItem from '@material-ui/core/MenuItem';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
    getUserDetails,
    shareEntity
} from '../../store/actions'


const useStyles = makeStyles((theme) => ({

    post_avatar: {
        width: "36px !important",
        height: "36px !important",
        border: "1px solid #979DA0 !important"
    },
    input_base: {
        minHeight: '0px!important',
        height: '2.5em'
    },
    privacy_select: {
        display: ' flex',
        alignItems: ' center',
        paddingTop: '10%'
    },
    select_focus: {
        backgroundColor: 'white!important'
    },
    input_root: {
        border: '1px solid white ',
        borderRadius: 10


    },
}));

const SharePost = (props) => {
    const classes = useStyles()
    const {
        type,
        identifier,
        onClose,
        open,
        getUserDetails,
        baseMediaThumbUrl,
        baseMediaUrl,
        userDetails,
    } = props;
    const [privacy, setPrivacy] = useState('public')
    const handleClose = () => {
        onClose(true);
    };

    useEffect(() => {
        getUserDetails(localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier))
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    const onShareNow = () => {

        shareEntity(type, privacy, identifier, null)
    }
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <CardHeader

                            avatar={
                                <Avatar aria-label={userDetails.bio.name} src={userDetails?.profileImg?.isThumbGenerated
                                    ? userDetails?.bio?.profileImg?.url?.thumbUrl
                                    : userDetails?.bio?.profileImg?.url?.originalUrl
                                } className={classes.post_avatar}>
                                </Avatar>
                            }
                            title={userDetails?.bio?.name}
                            subheader={<span>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.input_root,
                                            input: classes.inputCenter,

                                        }
                                    }}
                                    name="privacy"
                                    selectInputProps={{
                                        classes: {
                                            root: classes.input_base,
                                        }
                                    }}
                                    SelectProps={{
                                        classes: { select: classes.input_base }

                                    }}
                                    value={privacy}
                                    onChange={(e) => setPrivacy(e.target.value)}
                                    variant="outlined"
                                >
                                    <MenuItem key="public" value="public">
                                        <span className={classes.privacy_select}> <PublicIcon />Public</span>
                                    </MenuItem>
                                    <MenuItem key="private" value="private">
                                        <span className={classes.privacy_select}> <LockIcon /> Private</span>
                                    </MenuItem>
                                    <MenuItem key="onlyMe" value="onlyMe">
                                        <span className={classes.privacy_select}><SupervisorAccountIcon /> OnlyMe</span>
                                    </MenuItem>
                                </TextField>

                            </span>}
                        />



                    </DialogContentText>
                    <DialogActions>
                        <button onClick={onShareNow}>Share</button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </div>
    );

}

const mapStateToProps = ({ app, profile }) => {
    const { baseMediaUrl, baseMediaThumbUrl } = app;
    const { userDetails } = profile

    return {

        baseMediaUrl,
        baseMediaThumbUrl,
        userDetails

    };
};


export default connect(mapStateToProps, {
    getUserDetails,
    shareEntity
})(SharePost);


