// import React, { useEffect, useReducer, useState } from "react";
// import PropTypes from "prop-types";
// import InfiniteScroll from "react-infinite-scroll-component";

// import {
//     Dialog,
//     IconButton,
//     Tabs,
//     Tab,
//     Avatar,
//     CircularProgress,
//     Typography,
//     createTheme,
//     ThemeProvider,
//     useMediaQuery
// } from "@mui/material";

// import { api, Status } from "../../../api";
// import { OneLineBreak, TwoLineBreak } from "../../../common";

// import {
//     INTERESTED_SK,
//     LOVE_SK,
//     WANDER_TO_GO_SK,
// } from "../../../constants";

// import { translate } from "../../../i18n";
// import { Close as CloseIcon } from "@mui/icons-material";
// import ReactionCount from "./ReactionCount/ReactionCount";
// import ICON_INTERESTED_ACTIVE from "../../../assets/images/icon_interested_active.png";
// import ICON_HEART_ACTIVE from "../../../assets/images/icon_heart_active.png";
// import ICON_TRAVEL_ACTIVE from "../../../assets/images/icon_travel_active.png";

// import styles from "./ReactionList.module.scss";
// import colors from "../../../css/variable.scss";


// const theme = createTheme({
//     components: {
//         MuiTab: {
//             styleOverrides: {
//                 root: {
//                     minHeight: 0,
//                     minWidth: "120px",
//                     flexDirection: "row",
//                     fontSize: "16px",
//                     textTransform: "capitalize",
//                     "&.Mui-selected": {
//                         color: colors.blackColor
//                     },
//                 },

//             },
//         },
//         MuiTabs: {
//             styleOverrides: {
//                 indicator: {
//                     backgroundColor: colors.primaryColor
//                 }
//             }
//         }
//     },
// });

// const TabPanel = (props) => {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && <div>{children}</div>}
//         </div>
//     );
// };

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         "aria-controls": `simple-tabpanel-${index}`,
//     };
// }

// const Item = ({ userDetails }) => {

//     const userAvatar = (
//         <Avatar
//             alt="avatar"
//             src={`${userDetails?.profileImg?.url?.thumbUrl}`}
//         />
//     );

//     return (
//         <div className={styles["user-container"]}>
//             <div className={styles["user-avatar"]}>
//                 {userAvatar}
//             </div>

//             <div className={styles["user-name"]}>{userDetails?.name}</div>
//         </div>
//     );
// };

// const ReactionList = ({
//     entityIdentifier = "",
//     entityType = "",
//     baseMediaUrl,
//     detail,
//     closeReactionList,
//     open
// }) => {

//     // Check if the screen width is smaller than or equal to 600px.
//     const scrollable = useMediaQuery('(max-width: 600px)');

//     const [currentTab, setCurrentTab] = useState(0);
//     const [page, setPage] = useState(1);
//     const [state, setState] = useReducer(
//         (prevState, newState) => ({ ...prevState, ...newState }),
//         {
//             reactions: [],
//             reactionCount: {},
//             //   identifiersHashMap: {}, // Remove duplicates logic from api response
//             apiStatus: Status.DEFAULT,
//             moreApiStatus: Status.DEFAULT,
//             contentAvailableForRefresh: true,
//         }
//     );

//     useEffect(() => {
//         open && fetchReactions(currentTab);
//     }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

//     useEffect(() => {
//         // pagination logic
//         if (state.moreApiStatus !== Status.LOADING && page > 1) {
//             fetchReactions(currentTab, page);
//         }
//     }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

//     function fetchReactions(currentTab, _page = 1) {
//         if (_page === 1) {
//             setState({
//                 apiStatus: Status.LOADING,
//             });
//         } else {
//             setState({
//                 moreApiStatus: Status.LOADING,
//             });
//         }
//         api
//             .getReactions({
//                 page: _page,
//                 reactionType:
//                     currentTab === 0
//                         ? "all"
//                         : currentTab === 1
//                             ? INTERESTED_SK
//                             : currentTab === 2
//                                 ? LOVE_SK
//                                 : WANDER_TO_GO_SK,
//                 entityType,
//                 entityIdentifier,
//             })
//             .then(({ userResponse: { reactionCount = {}, reactions = [] } }) => {
//                 const newList = reactions;
//                 if (_page === 1) {
//                     setState({
//                         reactionCount,
//                         reactions: newList,
//                         contentAvailableForRefresh: newList.length !== 0,
//                         apiStatus: Status.SUCCESS,
//                     });
//                 } else {
//                     setState({
//                         reactions: [...state.reactions, ...newList],
//                         contentAvailableForRefresh: newList.length !== 0,
//                         moreApiStatus: Status.SUCCESS,
//                     });
//                 }
//             })
//             .catch(() => {
//                 if (_page === 1) {
//                     setState({
//                         apiStatus: Status.ERROR,
//                         contentAvailableForRefresh: false,
//                     });
//                 } else {
//                     setState({
//                         moreApiStatus: Status.ERROR,
//                         contentAvailableForRefresh: false,
//                     });
//                 }
//             });
//     }

//     const handleTabChange = (event, newValue) => {
//         setCurrentTab(newValue);
//         setPage(1);
//         fetchReactions(newValue);
//     };

//     const { interested = 0, love = 0, wanderToGo = 0 } = state.reactionCount;
//     let allCount = interested + love + wanderToGo;
//     return (
//         <Dialog
//             open={open}
//             onClose={closeReactionList}
//         >
//             <div
//                 className={
//                     detail
//                         ? styles["detail-reaction-list-container"]
//                         : styles["reaction-list-container"]
//                 }
//             >
//                 <ThemeProvider theme={theme}>
//                     <center>
//                         <div className={styles["reaction-list"]}>
//                             <div className={styles["reaction-header"]}>
//                                 <h2 className={styles["title"]}>{translate("postListItem.reactionListTitle")}</h2>
//                                 <IconButton
//                                     aria-label="close"
//                                     id={styles.closeButton}
//                                     onClick={closeReactionList}
//                                 >
//                                     <CloseIcon className={styles['media_viewer_close_btn']} />
//                                 </IconButton>
//                             </div>

//                             <div className={styles["list-tabs"]}>
//                                 <Tabs
//                                     id={styles.tabs}
//                                     value={currentTab}
//                                     onChange={handleTabChange}
//                                     variant={scrollable ? "scrollable" : "fullWidth"}
//                                     scrollButtons={false}
//                                     aria-label="reaction list tab bar"
//                                     selectionFollowsFocus
//                                 >
//                                     <Tab
//                                         label={<ReactionCount likesCount={allCount} allCount={true} />}
//                                         {...a11yProps(0)}
//                                     />
//                                     <Tab
//                                         label={<ReactionCount likesCount={interested} />}
//                                         icon={
//                                             <img
//                                                 alt="img"
//                                                 src={ICON_INTERESTED_ACTIVE}
//                                                 className={styles["icon-img"]}
//                                             />
//                                         }
//                                         {...a11yProps(1)}
//                                     />
//                                     <Tab
//                                         label={<ReactionCount likesCount={love} />}
//                                         icon={
//                                             <img
//                                                 alt="img"
//                                                 src={ICON_HEART_ACTIVE}
//                                                 className={styles["icon-img"]}
//                                             />
//                                         }
//                                         {...a11yProps(2)}

//                                     />
//                                     <Tab
//                                         label={<ReactionCount likesCount={wanderToGo} />}
//                                         icon={
//                                             <img
//                                                 alt="img"
//                                                 src={ICON_TRAVEL_ACTIVE}
//                                                 className={styles["icon-img"]}
//                                             />
//                                         }
//                                         {...a11yProps(3)}

//                                     />
//                                 </Tabs>
//                                 {state.apiStatus === Status.LOADING ? (
//                                     <div className={styles["loading-container"]}>
//                                         <CircularProgress size={20} id={styles["loading"]} />
//                                     </div>
//                                 ) : (<>
//                                     <TabPanel
//                                         value={currentTab}
//                                         index={0}
//                                         className={styles.itemContainer}
//                                         id="reaction-all-data"
//                                     >
//                                         <InfiniteScroll
//                                             dataLength={state.reactions.length} //This is important field to render the next data
//                                             next={() => setPage(page + 1)}
//                                             hasMore={state.contentAvailableForRefresh}
//                                             scrollableTarget="reaction-all-data"
//                                             className={styles.infiniteScrollStyle}
//                                             loader={
//                                                 <>
//                                                     <Typography align="center">
//                                                         <CircularProgress size={22} />
//                                                     </Typography>
//                                                 </>
//                                             }
//                                             endMessage=""
//                                         >
//                                             {state.reactions.map((reaction, key) => {
//                                                 return (
//                                                     <Item
//                                                         key={key}
//                                                         userDetails={reaction.userDetails}
//                                                         baseMediaUrl={baseMediaUrl}
//                                                     />
//                                                 );
//                                             })}
//                                         </InfiniteScroll>
//                                     </TabPanel>
//                                     <TabPanel
//                                         value={currentTab}
//                                         index={1}
//                                         className={styles.itemContainer}
//                                         id="reaction-interested-data"
//                                     >
//                                         <InfiniteScroll
//                                             dataLength={state.reactions.length} //This is important field to render the next data
//                                             next={() => setPage(page + 1)}
//                                             hasMore={state.contentAvailableForRefresh}
//                                             scrollableTarget="reaction-interested-data"
//                                             className={styles.infiniteScrollStyle}
//                                             loader={
//                                                 <>
//                                                     <Typography align="center">
//                                                         <TwoLineBreak />
//                                                         <CircularProgress size={22} />
//                                                     </Typography>
//                                                     <OneLineBreak />
//                                                 </>
//                                             }
//                                             endMessage=""
//                                         >
//                                             {state.reactions.map((reaction, key) => {
//                                                 return (
//                                                     <Item
//                                                         key={key}
//                                                         userDetails={reaction.userDetails}
//                                                         baseMediaUrl={baseMediaUrl}
//                                                     />
//                                                 );
//                                             })}
//                                         </InfiniteScroll>
//                                     </TabPanel>
//                                     <TabPanel
//                                         value={currentTab}
//                                         index={2}
//                                         className={styles.itemContainer}
//                                         id="reaction-love-data"
//                                     >
//                                         <InfiniteScroll
//                                             dataLength={state.reactions.length} //This is important field to render the next data
//                                             next={() => setPage(page + 1)}
//                                             hasMore={state.contentAvailableForRefresh}
//                                             scrollableTarget="reaction-love-data"
//                                             className={styles.infiniteScrollStyle}
//                                             loader={
//                                                 <>
//                                                     <Typography align="center">
//                                                         <TwoLineBreak />
//                                                         <CircularProgress size={22} />
//                                                     </Typography>
//                                                     <OneLineBreak />
//                                                 </>
//                                             }
//                                             endMessage=""
//                                         >
//                                             {state.reactions.map((reaction, key) => {
//                                                 return (
//                                                     <Item
//                                                         key={key}
//                                                         userDetails={reaction.userDetails}
//                                                         baseMediaUrl={baseMediaUrl}
//                                                     />
//                                                 );
//                                             })}
//                                         </InfiniteScroll>
//                                     </TabPanel>
//                                     <TabPanel
//                                         value={currentTab}
//                                         index={3}
//                                         className={styles.itemContainer}
//                                         id="reaction-wanter-data"
//                                     >
//                                         <InfiniteScroll
//                                             dataLength={state.reactions.length} //This is important field to render the next data
//                                             next={() => setPage(page + 1)}
//                                             hasMore={state.contentAvailableForRefresh}
//                                             scrollableTarget="reaction-wanter-data"
//                                             className={styles.infiniteScrollStyle}
//                                             loader={
//                                                 <>
//                                                     <Typography align="center">
//                                                         <TwoLineBreak />
//                                                         <CircularProgress size={22} />
//                                                     </Typography>
//                                                     <OneLineBreak />
//                                                 </>
//                                             }
//                                             endMessage=""
//                                         >
//                                             {state.reactions.map((reaction, key) => {
//                                                 return (
//                                                     <Item
//                                                         key={key}
//                                                         userDetails={reaction.userDetails}
//                                                         baseMediaUrl={baseMediaUrl}
//                                                     />
//                                                 );
//                                             })}
//                                         </InfiniteScroll>
//                                     </TabPanel>
//                                 </>)}
//                             </div>
//                         </div>
//                     </center>
//                 </ThemeProvider>
//             </div>
//         </Dialog >
//     );
// };

// export default ReactionList;

import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Avatar } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import ICON_INTERESTED_ACTIVE from "../../../assets/images/icon_interested_active.png";
import ICON_HEART_ACTIVE from "../../../assets/images/icon_heart_active.png";
import ICON_TRAVEL_ACTIVE from "../../../assets/images/icon_travel_active.png";
import { api, Status } from "../../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import { OneLineBreak, TwoLineBreak } from "../../../common";
import { Typography } from "@material-ui/core";
import {
    INTERESTED_SK,
    LOVE_SK,
    WANDER_TO_GO_SK,
    BLOGGER_SK,
} from "../../../constants";
import BLOGGER_MARK from "../../../assets/images/icon_blogger_mark.png";
import { Badge, withStyles } from "@material-ui/core";
import { translate } from "../../../i18n";
import styles from "./ReactionList.module.scss";

// eslint-disable-next-line no-unused-vars
const StyledAvatar = withStyles((theme) => ({
    root: {
        border: `2px solid #FFB703`,
    },
}))(Avatar);

const StyledBloggerAvatar = withStyles((theme) => ({
    root: {
        border: `2px solid #FA3F58`,
    },
}))(Avatar);
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Item = ({ userDetails, baseMediaUrl }) => {
    const classes = useStyles();

    const bloggerAvatar = (
        <Badge
            overlap="circular"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            badgeContent={
                <img alt="img" src={BLOGGER_MARK} className={classes.badge} />
            }
        >
            <StyledBloggerAvatar>
                <Avatar
                    alt="avatar"
                    src={`${userDetails?.profileImg?.url?.thumbUrl}`}
                    className={classes.large}
                />
            </StyledBloggerAvatar>
        </Badge>
    );

    const userAvatar = (
        <Avatar
            alt="avatar"
            src={`${userDetails?.profileImg?.url?.thumbUrl}`}
            className={classes.large}
        />
    );

    return (
        <div className={classes.itemStyle}>
            <div style={{ cursor: "pointer" }}>
                {userDetails.userRole === BLOGGER_SK ? bloggerAvatar : userAvatar}
            </div>

            <div className={styles.name}>{userDetails?.name}</div>
        </div>
    );
};

const ReactionList = ({
    entityIdentifier = "",
    entityType = "",
    baseMediaUrl,
    detail,
}) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = React.useState(0);
    const [page, setPage] = useState(1);
    const [state, setState] = useReducer(
        (prevState, newState) => ({ ...prevState, ...newState }),
        {
            reactions: [],
            reactionCount: {},
            //   identifiersHashMap: {}, // Remove duplicates logic from api response
            apiStatus: Status.DEFAULT,
            moreApiStatus: Status.DEFAULT,
            contentAvailableForRefresh: true,
        }
    );

    useEffect(() => {
        fetchReactions(currentTab);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // pagination logic
        if (state.moreApiStatus !== Status.LOADING && page > 1) {
            fetchReactions(currentTab, page);
        }
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchReactions(currentTab, _page = 1) {
        if (_page === 1) {
            setState({
                apiStatus: Status.LOADING,
            });
        } else {
            setState({
                moreApiStatus: Status.LOADING,
            });
        }
        api
            .getReactions({
                page: _page,
                reactionType:
                    currentTab === 0
                        ? "all"
                        : currentTab === 1
                            ? INTERESTED_SK
                            : currentTab === 2
                                ? LOVE_SK
                                : WANDER_TO_GO_SK,
                entityType,
                entityIdentifier,
            })
            .then(({ userResponse: { reactionCount = {}, reactions = [] } }) => {
                const newList = reactions;
                if (_page === 1) {
                    setState({
                        reactionCount,
                        reactions: newList,
                        contentAvailableForRefresh: newList.length !== 0,
                        apiStatus: Status.SUCCESS,
                    });
                } else {
                    setState({
                        reactions: [...state.reactions, ...newList],
                        contentAvailableForRefresh: newList.length !== 0,
                        moreApiStatus: Status.SUCCESS,
                    });
                }
            })
            .catch(() => {
                if (_page === 1) {
                    setState({
                        apiStatus: Status.ERROR,
                        contentAvailableForRefresh: false,
                    });
                } else {
                    setState({
                        moreApiStatus: Status.ERROR,
                        contentAvailableForRefresh: false,
                    });
                }
            });
    }

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setPage(1);
        fetchReactions(newValue);
    };

    const { interested = 0, love = 0, wanderToGo = 0 } = state.reactionCount;
    let allCount = interested + love + wanderToGo;
    return (
        <div
            className={
                detail
                    ? styles["detail-reaction-list-container"]
                    : styles["reaction-list-container"]
            }
        >
            <center>
                <div className={styles["reaction-list"]}>
                    <span className={styles.title}>
                        {translate("postListItem.reactionListTitle")}
                    </span>
                    <Tabs
                        id={styles.tabs}
                        variant="fullWidth"
                        className={classes.tabs}
                        value={currentTab}
                        onChange={handleTabChange}
                    >
                        <Tab
                            label={`All ${allCount}`}
                            {...a11yProps(0)}
                            className={classes.tab}
                        />
                        <Tab
                            label={interested}
                            icon={
                                <img
                                    alt="img"
                                    src={ICON_INTERESTED_ACTIVE}
                                    className={classes.tabIcon}
                                />
                            }
                            {...a11yProps(1)}
                            className={classes.tab}
                        />
                        <Tab
                            label={love}
                            icon={
                                <img
                                    alt="img"
                                    src={ICON_HEART_ACTIVE}
                                    className={classes.tabIcon}
                                />
                            }
                            {...a11yProps(2)}
                            className={classes.tab}
                        />
                        <Tab
                            label={wanderToGo}
                            icon={
                                <img
                                    alt="img"
                                    src={ICON_TRAVEL_ACTIVE}
                                    className={classes.tabIcon}
                                />
                            }
                            {...a11yProps(3)}
                            className={classes.tab}
                        />
                    </Tabs>

                    {state.apiStatus === Status.LOADING ? (
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress size={20} />
                        </div>
                    ) : (
                        <div className={styles["list-tabs"]}>
                            <TabPanel
                                value={currentTab}
                                index={0}
                                className={classes.itemContainer}
                                id="reaction-all-data"
                            >
                                <InfiniteScroll
                                    dataLength={state.reactions.length} //This is important field to render the next data
                                    next={() => setPage(page + 1)}
                                    hasMore={state.contentAvailableForRefresh}
                                    scrollableTarget="reaction-all-data"
                                    className={classes.infiniteScrollStyle}
                                    loader={
                                        <>
                                            <Typography align="center">
                                                <TwoLineBreak />
                                                <CircularProgress size={22} />
                                            </Typography>
                                            <OneLineBreak />
                                        </>
                                    }
                                    endMessage=""
                                >
                                    {state.reactions.map((reaction, key) => {
                                        return (
                                            <Item
                                                key={key}
                                                userDetails={reaction.userDetails}
                                                baseMediaUrl={baseMediaUrl}
                                            />
                                        );
                                    })}
                                </InfiniteScroll>
                            </TabPanel>
                            <TabPanel
                                value={currentTab}
                                index={1}
                                className={classes.itemContainer}
                                id="reaction-interested-data"
                            >
                                <InfiniteScroll
                                    dataLength={state.reactions.length} //This is important field to render the next data
                                    next={() => setPage(page + 1)}
                                    hasMore={state.contentAvailableForRefresh}
                                    scrollableTarget="reaction-interested-data"
                                    className={classes.infiniteScrollStyle}
                                    loader={
                                        <>
                                            <Typography align="center">
                                                <TwoLineBreak />
                                                <CircularProgress size={22} />
                                            </Typography>
                                            <OneLineBreak />
                                        </>
                                    }
                                    endMessage=""
                                >
                                    {state.reactions.map((reaction, key) => {
                                        return (
                                            <Item
                                                key={key}
                                                userDetails={reaction.userDetails}
                                                baseMediaUrl={baseMediaUrl}
                                            />
                                        );
                                    })}
                                </InfiniteScroll>
                            </TabPanel>
                            <TabPanel
                                value={currentTab}
                                index={2}
                                className={classes.itemContainer}
                                id="reaction-love-data"
                            >
                                <InfiniteScroll
                                    dataLength={state.reactions.length} //This is important field to render the next data
                                    next={() => setPage(page + 1)}
                                    hasMore={state.contentAvailableForRefresh}
                                    scrollableTarget="reaction-love-data"
                                    className={classes.infiniteScrollStyle}
                                    loader={
                                        <>
                                            <Typography align="center">
                                                <TwoLineBreak />
                                                <CircularProgress size={22} />
                                            </Typography>
                                            <OneLineBreak />
                                        </>
                                    }
                                    endMessage=""
                                >
                                    {state.reactions.map((reaction, key) => {
                                        return (
                                            <Item
                                                key={key}
                                                userDetails={reaction.userDetails}
                                                baseMediaUrl={baseMediaUrl}
                                            />
                                        );
                                    })}
                                </InfiniteScroll>
                            </TabPanel>
                            <TabPanel
                                value={currentTab}
                                index={3}
                                className={classes.itemContainer}
                                id="reaction-wanter-data"
                            >
                                <InfiniteScroll
                                    dataLength={state.reactions.length} //This is important field to render the next data
                                    next={() => setPage(page + 1)}
                                    hasMore={state.contentAvailableForRefresh}
                                    scrollableTarget="reaction-wanter-data"
                                    className={classes.infiniteScrollStyle}
                                    loader={
                                        <>
                                            <Typography align="center">
                                                <TwoLineBreak />
                                                <CircularProgress size={22} />
                                            </Typography>
                                            <OneLineBreak />
                                        </>
                                    }
                                    endMessage=""
                                >
                                    {state.reactions.map((reaction, key) => {
                                        return (
                                            <Item
                                                key={key}
                                                userDetails={reaction.userDetails}
                                                baseMediaUrl={baseMediaUrl}
                                            />
                                        );
                                    })}
                                </InfiniteScroll>
                            </TabPanel>
                        </div>
                    )}
                </div>
            </center>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    badge: {
        width: "15px",
        height: "15px",
    },
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    tabs: {
        minHeight: 0,
    },
    tab: {
        minWidth: 0,
        minHeight: 0,
        padding: "6px",
        margin: "0 10px",
        textTransform: "capitalize",
        opacity: 1,
    },
    tabIcon: {
        width: "19px",
        height: "18px",
    },
    tabIconSm: {
        width: "15px",
        height: "15px",
    },
    itemContainer: {
        padding: "10px 0",
        overflow: "auto",
        // height: "10%",
    },
    itemStyle: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        width: "30px",
        height: "30px",
    },
    infiniteScrollStyle: {
        height: "100% !important",
        // overflow: "hidden !important",
    },
    "@media (max-width: 399px)": {
        tab: {
            margin: "0 !important",
        },
    },
}));

export default ReactionList;

