import React, { useEffect, useState } from 'react';
import {
  ALBUM_FILE_DIR_SK,
  CURRENT_USER_LK,
  ERROR_SK,
  LIMITS,
  SUCCESS_SK,
  VIDEO_SK,
} from '../../../../constants';
import { IconButton, MenuItem, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AlertDialog, Snack } from '../../../../common';
import { api, Status } from '../../../../api';
import { translate } from '../../../../i18n';
import albumListIcon from '../../../../assets/images/icon_albums.png';
import addPhotoIcon from '../../../../assets/images/icon_add_photo.png';
import editAlbumIcon from '../../../../assets/images/icon_edit.png';
import deleteAlbumIcon from '../../../../assets/images/icon_delete.png';
import EditAlbumModal from './EditAlbumModal';
import { getPeriodicTime, isNonEmptyString, isObject } from '../../../../utils';
import styles from './AlbumDetails.module.scss';

const AlbumDetails = ({
  mode = 'default',
  fileCount,
  identifier,
  profileType,
  albumName,
  description,
  createdAt,
  loggedInUserIdentifier,
  updateAlbumDetails,
  setNewItemLoading: _setNewItemLoading,
  albumDetailsModeOperations,
}) => {
  let addPhotoRef = null;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadApiStatus, setUploadApiStatus] = useState(Status.DEFAULT);
  const [filesCount, setFilesCount] = useState(0);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });

  useEffect(() => {
    if (
      filesCount === uploadedMedia.length &&
      uploadApiStatus === Status.SUCCESS
    ) {
      _setNewItemLoading(false, uploadedMedia);
      setUploadedMedia([]);
      setFilesCount(0);
      setUploadApiStatus(Status.DEFAULT);
    }
  }, [uploadedMedia, uploadApiStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setAnchorEl(null);
    setIsEdit(true);
  };

  const closeEditModal = () => {
    setIsEdit(false);
  };

  const handleDeleteClick = () => {
    let data = {
      description: `${translate('userProfile.deleteAlbumMessage').replace(
        '!@#$%',
        `“${albumName}”`
      )}
      ? !@#$%
      ${translate('userProfile.deleteAlbumMessage2')}`,
      positiveButtonCallback: () => {
        deleteAlbumAPI();
        setAlertOpen(false);
        albumDetailsModeOperations?.goToAlbumList();
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
    setAnchorEl(null);
  };

  const deleteAlbumAPI = () => {
    albumDetailsModeOperations?.setDeleteStatus(identifier);
    api
      .deleteAlbum({
        albumIdentifier: identifier,
      })
      .then((response) => {
        const {
          userResponse: { status }, // eslint-disable-line no-unused-vars
        } = response;
        //Toast.showToast(status);

        // remove album form redux list
        albumDetailsModeOperations?.deleteAlbum(identifier);
      });
  };

  const showSnack = ({ message, type }) => {
    setSnackData({
      open: true,
      type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleFileChange = () => {
    let files = addPhotoRef.files;
    setFilesCount(files.length);
    _setNewItemLoading(true, [...Array(files.length).keys()]);
    getPostPolicyAndUploadMedia(files);
  };

  async function getPostPolicyAndUploadMedia(mediaList) {
    setUploadApiStatus(Status.LOADING);
    let postPolicy = await getPostPolicy();
    if (!isObject(postPolicy)) {
      return;
    }
    for (let i = 0; i < mediaList.length; i++) {
      uploadFile(mediaList[i], postPolicy);
    }
  }

  async function getPostPolicy() {
    try {
      const { userResponse } = await api.getPostPolicy({
        entityType: ALBUM_FILE_DIR_SK,
        entityIdentifier: identifier,
      });
      return {
        imagePolicy: userResponse.image,
        videoPolicy: userResponse.video,
        policyCreatedAt: userResponse.policyCreatedAt,
      };
    } catch (error) {
      setUploadApiStatus(Status.ERROR);
      showSnack({
        type: ERROR_SK,
        message: translate('createPost.fileUploadFailedMessage'),
      });
      return null;
    }
  }

  function uploadFile(item, postPolicy) {
    const { imagePolicy, videoPolicy, policyCreatedAt } = postPolicy;

    return api
      .uploadMedia({
        file: item,
        policy: item.type.includes(VIDEO_SK)
          ? videoPolicy.base64policy
          : imagePolicy.base64policy,
        signature: item.type.includes(VIDEO_SK)
          ? videoPolicy.signature
          : imagePolicy.signature,
        policyCreatedAt,
        loggedInUserIdentifier,
        entityIdentifier: identifier,
        fileDirectory: ALBUM_FILE_DIR_SK,
        localFileIdentifier: item.size.toString(),
      })
      .then((response) => {
        const { identifier, fileType, path } = response.userResponse;
        const newItem = {
          identifier,
          fileType,
          filePath: path,
          isThumbGenerated: 0,
        };
        setUploadApiStatus(Status.SUCCESS);
        setUploadedMedia((prevState) => [...prevState, newItem]);
        return null;
      })
      .catch((e) => {
        setUploadApiStatus(Status.SUCCESS);
        setUploadedMedia((prevState) => [...prevState, null]);
        showSnack({
          type: ERROR_SK,
          message: translate('createPost.invalidMedia'),
        });
        console.log(e);
      });
  }

  return (
    <div>
      <AlertDialog open={alertOpen} data={alertData} />
      <EditAlbumModal
        open={isEdit}
        close={closeEditModal}
        identifier={identifier}
        name={albumName}
        description={description}
        loggedInUserIdentifier={loggedInUserIdentifier}
        updateAlbumDetails={updateAlbumDetails}
      />
      {profileType === CURRENT_USER_LK && mode === 'details' && (
        <div id={styles['album-details']}>
          <div className={styles['details-ablum-top-section']}>
            <div className={styles['left-side']}>
              <div id={styles['album-name']}>{albumName}</div>
              <div id={styles.description}>{description}</div>
              <div id={styles['updated-date']}>
                {isNonEmptyString(createdAt) &&
                  `${translate('userProfile.createdAt').replace(
                    '!@#$%',
                    getPeriodicTime(createdAt)
                  )}`}
              </div>
            </div>
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
                style={{
                  marginTop: '-12px',
                  marginRight: '-12px',
                }}
              >
                <MoreVertIcon id="more-vert-icon" />
              </IconButton>
              <Menu
                id={styles['long-menu']}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
                // have to check
                // PaperProps={{
                //   style: {},
                // }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleEditClick}>
                  <div className={styles['menu-item']}>
                    <img alt="iconimg" src={editAlbumIcon} />
                    {translate('common.edit')}
                  </div>
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                  <div className={styles['menu-item']}>
                    <img alt="iconimg" src={deleteAlbumIcon} />
                    {translate('common.delete')}
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className={styles['details-album-container']}>
            <div id={styles.venus}>
              <img
                alt="img"
                src={albumListIcon}
                onClick={albumDetailsModeOperations?.goToAlbumList}
              />
            </div>
            {fileCount < LIMITS.ALBUM_FILE_LIMIT && (
              <label htmlFor="add-photo-input">
                <input
                  multiple
                  type="file"
                  accept={['image/*', 'video/*']}
                  id={styles['add-photo-input']}
                  name="add-photo-input"
                  ref={(ref) => {
                    addPhotoRef = ref;
                  }}
                  onChange={handleFileChange}
                  disabled={uploadApiStatus === Status.LOADING}
                  hidden
                />
                <div id={styles.mars}>
                  <img alt="add-img" src={addPhotoIcon} />
                  &nbsp;
                  {translate('userProfile.addPhotosVideos')}
                </div>
              </label>
            )}
          </div>
        </div>
      )}
      {profileType !== CURRENT_USER_LK && mode === 'details' && (
        <div className={styles['details-album-container']}>
          <div className={styles['details-album-name']}>
            <span id={styles.label}>
              {`${translate('businessProfile.albumName')}: `}
            </span>
            <span id={styles.name}>{albumName}</span>
          </div>
          <img
            alt="img"
            className={styles.grid_icon}
            src={albumListIcon}
            onClick={albumDetailsModeOperations?.goToAlbumList}
          />
        </div>
      )}
      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={handleSnackClose}
      />
    </div>
  );
};

export default AlbumDetails;
