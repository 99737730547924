import React, { useState, useEffect } from "react";
import { renderToString } from 'react-dom/server';
import { Redirect, useLocation } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";

import { CircularProgress } from "@mui/material";

import { Status, api } from "../../../api";
import { Snack, Space } from "../../../common";
import { ASYNC_STORAGE_KEYS } from "../../../constants";
import { translate } from "../../../i18n";
import { EMAIL_LK, OTP_REQUEST_FORGOT_PASSWORD } from "../../../constants/stringConstants";
import {
  verifyOtp,
  resendOtp,
  resetOtpVerificationState,
} from "../../../store/actions";
import {
  isNonEmptyString,
  loadState,
  removeState,
  saveState
} from "../../../utils";

import OtpImg from "../../../assets/authentication/otp.png";
import LogoImg from "../../../assets/images/logo.png";

import styles from "../Authentication.module.scss";
import cn from "classnames";

const propTypes = {
  verifyOtpStatus: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  resendOtpStatus: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  resetOtpVerificationState: PropTypes.func.isRequired,
  verifyOtp: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      otpMode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  verifyOtpErrorMessage: PropTypes.string,
  resendOtpErrorMessage: PropTypes.string,
};

const defaultProps = {
  verifyOtpErrorMessage: "",
  resendOtpErrorMessage: "",
};

const ResetPassword = ({
  /**
   * Tells about the status of the resend otp api call
   *
   * @source: redux
   */
  resendOtpStatus,
  /**
   * Contains the error message from server, when resendOtpStatus === Status.ERROR
   *
   * @source: redux
   */
  resendOtpErrorMessage,
  /**
   * redux action to make verify otp api request
   *
   * @source: redux
   */
  verifyOtp: _verifyOtp,
  /**
   * redux action to make resend otp api request
   *
   * @source: redux
   */
  resendOtp: _resendOtp,
  /**
   * When user hit back button, from OtpVerificationScreen,
   * reset the state associated with it, in redux
   *
   * @source: redux
   */
  resetOtpVerificationState: _resetOtpVerificationState,

  history: _history,
}) => {

  const loggedInUserIdentifier = loadState(ASYNC_STORAGE_KEYS.userIdentifier);
  const location = useLocation();

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otp, setOTP] = useState("");
  const [otpType, setOtpType] = useState(null);
  const [loginType, setLoginType] = useState("");
  const [userIdentifier, setUserIdentifier] = useState(null);
  const [openVerifyOtpSnack, setOpenVerifyOtpSnack] = useState(false);
  const [verifyOtpStatus, setVerifyOtpStatus] = useState(Status.DEFAULT);
  const [verifyOtpApiErrorMessage, setVerifyOtpApiErrorMessage] = useState("");
  const [openResendOtpSnack, setOpenResendOtpSnack] = useState(false);
  const [resendApiErrorMessage, setResendApiErrorMessage] = useState("");

  useEffect(() => {
    return () => {
      resetState();
      removeState(ASYNC_STORAGE_KEYS.timerMinutes);
      removeState(ASYNC_STORAGE_KEYS.timerSeconds);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.state) {
      setUserIdentifier(location.state.userIdentifier);
      const storedMinutes = parseInt(loadState(ASYNC_STORAGE_KEYS.timerMinutes)) || 0;
      const storedSeconds = parseInt(loadState(ASYNC_STORAGE_KEYS.timerSeconds)) || 0;

      setMinutes(storedMinutes);
      setSeconds(storedSeconds);

      setOtpType(location.state.otpType);
      setLoginType(location.state.loginType);
    } else {
      redirectToForgotPassword();
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (otp === "") {
      setVerifyOtpStatus(Status.DEFAULT);
    }
  }, [otp]);

  useEffect(() => {
    if (verifyOtpStatus === Status.SUCCESS) {
      redirectToChangePassword();
    }
  }, [verifyOtpStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resendOtpStatus === Status.SUCCESS) {
      setOpenResendOtpSnack(true);
      let otp = location.state.otpExpiryTimeInSecs;
      setMinutes(otp / 60);
      setSeconds(0);
      /* Clear the text input, for new otp */
      setOTP("");
    }
    /* Update localStorage when the timer is reset */
    saveState(ASYNC_STORAGE_KEYS.timerMinutes, otp / 60);
    saveState(ASYNC_STORAGE_KEYS.timerSeconds, 0);

    if (resendOtpStatus === Status.ERROR) {
      setOpenResendOtpSnack(true);
      setResendApiErrorMessage(resendOtpErrorMessage);
    }

  }, [resendOtpStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer);
          resetState();
        } else {
          setMinutes(minutes - 1);
          setSeconds(54);
        }
      }
    }, 1000);

    /* Update localStorage with the current timer state */
    saveState(ASYNC_STORAGE_KEYS.timerMinutes, minutes);
    saveState(ASYNC_STORAGE_KEYS.timerSeconds, seconds);

    return () => {
      clearInterval(timer);
    };
  }, [minutes, seconds]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenResendOtpSnack(false);
    setOpenVerifyOtpSnack(false);
  };

  const onSubmit = () => {
    setVerifyOtpStatus(Status.LOADING);
    api
      .verifyOtp({ otp, userIdentifier, otpType: OTP_REQUEST_FORGOT_PASSWORD })
      .then(() => {
        setVerifyOtpStatus(Status.SUCCESS);
        setOpenVerifyOtpSnack(true);
      })
      .catch((error) => {
        setVerifyOtpStatus(Status.ERROR);
        setOpenVerifyOtpSnack(true);
        setVerifyOtpApiErrorMessage(error.message);
      });
  };

  const handlResendOtp = () => {
    _resendOtp(OTP_REQUEST_FORGOT_PASSWORD, userIdentifier);
  };

  const resetState = () => {
    _resetOtpVerificationState();
    setOTP(null);
    setVerifyOtpStatus(Status.DEFAULT);
    setVerifyOtpApiErrorMessage("");
  };

  const redirectToChangePassword = () => {
    _history.replace({
      pathname: "/changepassword",
      state: {
        otp: otp,
        userIdentifier: userIdentifier,
      },
    });
  };

  const redirectToForgotPassword = () => {
    _history.replace("/forgotpassword");
  }

  const isDisabled = minutes === 0 && seconds === 0 && verifyOtpStatus === Status.DEFAULT;

  const timerJSX = <span className={styles["otp_text"]}>
    {minutes < 10 ? `0${minutes}` : minutes}:
    {seconds < 10 ? `0${seconds}` : seconds}
  </span>
  /* Noted: renderToString renders a React tree to an HTML string. */
  // Convert the JSX element to a string
  const timer = renderToString(timerJSX);

  if (isNonEmptyString(loggedInUserIdentifier)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <div className={styles.container}>
        <div className={styles["forms-container"]}>
          <div className={styles["signin-signup"]}>
            <div className={cn(styles["sign-in-form"], styles["otp-div"])}>
              <span className={styles["signin-signup-form-title"]}>
                {translate("otpScreen.mainTitle")}
              </span>
              <br></br>
              <span className={styles["otp-sub-title"]}>
                {
                  loginType === EMAIL_LK ?
                    translate("otpScreen.titleEmail").replace(/%s/g, otpType) :
                    translate("otpScreen.titlePhone").replace(/%s/g, otpType)
                }
              </span>
              <OtpInput
                value={otp}
                isDisabled={isDisabled}
                onChange={onOTPChange}
                numInputs={6}
                inputStyle={
                  verifyOtpStatus === Status.ERROR && otp !== ""
                    ? styles["otp-input-error"]
                    : styles["otp-input-box"]
                }
                focusStyle={styles["otp-focus-style"]}
                separator={
                  <span>
                    <Space />
                  </span>
                }
                shouldAutoFocus={false}
                isInputNum={true}
                containerStyle={styles["otp-container"]}
              />
              {isDisabled ? (
                <div className={styles["otp-exp-div"]}>
                  <span className={styles["expired"]}>
                    {translate("otpScreen.otpExpired")}
                  </span>
                </div>
              ) : (
                <div className={styles["otp-exp-div"]}>
                  <span className={styles["otp_info_text"]}
                    dangerouslySetInnerHTML={{
                      __html: translate("otpScreen.otpMessage").replace(/%s/g, timer)
                    }}
                  />
                </div>
              )}
              {otp?.length !== 6 ? (
                <button
                  className={cn(styles.solid, styles["btn-disabled"])}
                  disabled
                >
                  {translate("common.submit")}
                </button>
              ) : (
                <button
                  className={cn(styles.solid, styles.btn)}
                  disabled={verifyOtpStatus === Status.length ? true : false}
                  onClick={onSubmit}
                >
                  {verifyOtpStatus === Status.LOADING ? (
                    <div>
                      <CircularProgress id={styles["circular-progress"]} size={20} />
                    </div>
                  ) : (
                    <span>{translate("common.submit")} </span>
                  )}
                </button>
              )}
              <div className={styles["resend_otp"]}>
                {translate("otpScreen.resendMessage")} <Space />
                <span>
                  {resendOtpStatus === Status.LOADING ? (
                    <CircularProgress size={20} id={styles["circular-progress"]} />
                  ) : minutes === 0 && seconds === 0 ? (
                    <span
                      onClick={handlResendOtp}
                      className={styles["resend_text"]}
                    >
                      {translate("otpScreen.resend")}
                    </span>
                  ) : (
                    <span className={styles["disabled_resend"]}>
                      {translate("otpScreen.resend")}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles["panels-container"]}>
          <div className={cn(styles.panel, styles["left-panel"])}>
            <div>
              <div className={styles.logo} onClick={() => _history.push("/")}>
                <img src={LogoImg} alt="LOGO"></img>
              </div>
              <div className={styles["panel-inside"]}>
                <div className={cn(styles["panel-center"], styles.content)}>
                  <img alt="img" src={OtpImg} className={styles["img-lock"]} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Snack
          open={openResendOtpSnack}
          message={resendOtpStatus === Status.SUCCESS ? translate("otpScreen.otpSendSuccess") : resendApiErrorMessage}
          type={resendOtpStatus === Status.SUCCESS ? "success" : "error"}
          onClose={handleSnackClose}
        />
        <Snack
          open={openVerifyOtpSnack}
          message={verifyOtpApiErrorMessage}
          type={"error"}
          onClose={handleSnackClose}
        />
      </div>
    </div >
  );
};

ResetPassword.propTypes = propTypes;

ResetPassword.defaultProps = defaultProps;

const mapStateToProps = ({ auth }) => {
  const {
    resendOtpStatus,
    resendOtpErrorMessage,
    otp_type,
  } = auth;

  return {
    resendOtpStatus,
    resendOtpErrorMessage,
    otp_type,
  };
};

export default connect(mapStateToProps, {
  verifyOtp,
  resendOtp,
  resetOtpVerificationState,
})(ResetPassword);
