/**
 * return true if @a is object else false
 */
export const isObject = a => {
  return !!a && a.constructor === Object;
};

/**
 * return true is a is empty or non empty valid string
 */
export const isString = a => {
  return typeof a === 'string';
};

/**
 * return true if a is non empty valid string
 */
export const isNonEmptyString = a => {
  return !!(isString(a) && a !== '');
};

/**
* return true is a is base64 string
*/
export const isBase64String = a => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(a);
};
