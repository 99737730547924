import { shape, string, oneOf } from 'prop-types';
import {
  HOTEL_SK,
  RESTAURANT_SK,
  BUSINESS_SK,
  ATTRACTION_SK,
  RELIGIOUS_SK,
  NON_RELIGIOUS_SK,
} from '../../constants/stringConstants';
import imageType from './ImageType';

/**
 * Defines prop-types definition for a single
 * landmark, that comes in business profile
 */
const landmarkType = shape({
  coverImg: imageType,
  identifier: string,
  name: string,
  subType: oneOf([RELIGIOUS_SK, NON_RELIGIOUS_SK, HOTEL_SK, RESTAURANT_SK]),
  type: oneOf([ATTRACTION_SK, BUSINESS_SK]),
});

export default landmarkType;
