import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core';
import {
  Dialog,
  IconButton,
  CircularProgress,
  TextField,
  styled,
} from '@mui/material';
import { isNonEmptyString } from '../../../../utils';
import { translate } from '../../../../i18n';
import { api, Status } from '../../../../api';
import { Button, ButtonOutlined } from '../../../../common';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFB703',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFB703',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#FFB703',
    },
  },
});

const EditAlbumModal = ({
  open,
  identifier,
  name,
  description,
  loggedInUserIdentifier,
  close: _close,
  updateAlbumDetails,
}) => {
  const classes = useStyles();
  const [albumName, setAlbumName] = useState(name);
  const [albumDescription, setAlbumDescription] = useState(description);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    setAlbumName(name);
    setAlbumDescription(description);
  }, [name, description]);

  const save = () => {
    setApiStatus(Status.LOADING);
    api
      .editAlbum({
        albumName,
        albumDescription,
        identifier,
      })
      .then((response) => {
        setApiStatus(Status.SUCCESS);
        updateAlbumDetails(identifier, albumName, albumDescription);
        _close();
      })
      .catch(() => {
        setApiStatus(Status.ERROR);
      });
  };

  const onClose = () => {
    if (apiStatus !== Status.LOADING) {
      _close();
      resetState();
    }
  };

  const resetState = () => {
    setAlbumName(name);
    setAlbumDescription(description);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="edit-album-dialog">
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <HighlightOffIcon fontSize="large" />
        </IconButton>
        {apiStatus === Status.LOADING && (
          <div id="loading-circle">
            <CircularProgress className="loading_spinner" />
          </div>
        )}
        <div className="edit-album-container">
          <div className="edit-album-title">
            {translate('userProfile.editAlbum')}
          </div>
          <br />
          <div className="edit-album-form">
            <CssTextField
              fullWidth
              size="small"
              label={`${translate('userProfile.name')}*`}
              value={albumName}
              onChange={(e) => {
                setAlbumName(e.target.value);
              }}
            />
            <br />
            <br />
            <CssTextField
              fullWidth
              size="small"
              label={translate('userProfile.description')}
              value={albumDescription}
              onChange={(e) => {
                setAlbumDescription(e.target.value);
              }}
            />
            <br />
            <div className="footer">
              {isNonEmptyString(albumName) && apiStatus !== Status.LOADING ? (
                <Button
                  id="button"
                  variant="contained"
                  size="small"
                  onClick={save}
                  style={{ textTransform: 'uppercase' }}
                >
                  {translate('userProfile.done')}
                </Button>
              ) : (
                <ButtonOutlined
                  id="button"
                  variant="contained"
                  size="small"
                  style={{ textTransform: 'uppercase' }}
                >
                  {translate('userProfile.done')}
                </ButtonOutlined>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute !important',
    right: '3%',
    top: 0,
    color: '#000 !important',
  },
}));

export default EditAlbumModal;
