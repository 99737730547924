import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../i18n';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { Tabs } from '@material-ui/core';
import PhotosGrid from './PhotosGrid';
import VideosGrid from './VideosGrid';
import AlbumsTab from './AlbumsTab';
// import '../../../../css/business.css';
import styles from './GalleryTab.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GalleryTab = ({
  identifier,
  entityType,
  subType,
  profileType,
  loggedInUserIdentifier,
  profileImgUrl,
  userRole,
  businessName,
  baseMediaUrl,
  baseMediaThumbUrl,
  isSliderClicked = false,
  setIsSliderClicked = () => {},
  sliderAlbumIdentifier = '',
  isPrivate,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isSliderClicked) {
      setSelectedTab(0);
    }
  }, [isSliderClicked]);

  return (
    <div className={styles['gallery-tab-container']}>
      <TabContext value={selectedTab}>
        <Tabs
          id={styles['gallery-tabs']}
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          variant="fullWidth"
          centered
        >
          <Tab
            id={selectedTab === 0 && styles.indicator}
            label={translate('common.albums')}
            value={0}
            className={styles.tabHeader}
          />
          <Tab
            id={selectedTab === 1 && styles.indicator}
            label={translate('common.photos')}
            value={1}
            className={styles.tabHeader}
          />
          <Tab
            id={selectedTab === 2 && styles.indicator}
            label={translate('common.videos')}
            value={2}
            className={styles.tabHeader}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <AlbumsTab
            identifier={identifier}
            entityType={entityType}
            subType={subType}
            profileType={profileType}
            loggedInUserIdentifier={loggedInUserIdentifier}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            profileImgUrl={profileImgUrl}
            userRole={userRole}
            businessName={businessName}
            isSliderClicked={isSliderClicked}
            setIsSliderClicked={setIsSliderClicked}
            sliderAlbumIdentifier={sliderAlbumIdentifier}
            isPrivate={isPrivate}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <PhotosGrid
            identifier={identifier}
            entityType={entityType}
            subType={subType}
            profileType={profileType}
            profileImgUrl={profileImgUrl}
            userRole={userRole}
            businessName={businessName}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            isPrivate={isPrivate}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <VideosGrid
            identifier={identifier}
            entityType={entityType}
            subType={subType}
            profileType={profileType}
            profileImgUrl={profileImgUrl}
            userRole={userRole}
            businessName={businessName}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            isPrivate={isPrivate}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default GalleryTab;
