import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import {
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  createTheme,
  ThemeProvider
} from "@mui/material";

import { forgetPassword, resetForgetPasswordState } from "../../../store/actions";

import { translate } from "../../../i18n";
import { Status } from "../../../api";
import {
  extractNumbersFromString,
  getE164Format,
  isNonEmptyString,
  saveState
} from "../../../utils";
import {
  ASYNC_STORAGE_KEYS,
  EMAIL_LK,
  NEPAL_COUNTRY_CODE,
  PHONE_NUMBER_LK
} from "../../../constants";
import CountryPhoneCodes from "../../../config/countryPhoneCode";

import Recaptcha from "../../../common/Recaptcha/Recaptcha";
import { OneLineBreak, Snack } from "../../../common";

import PasswordImg from "../../../assets/images/Password.png";
import LogoImg from "../../../assets/images/logo.png";

import styles from "../Authentication.module.scss";
import colors from "../../../css/variable.scss";
import cn from "classnames";


const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(10px, -8px) scale(0.75)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.grayColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primaryColor,
            backgroundColor: "none !important",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.MuiFormHelperText-root': {
            marginLeft: "0px",
            marginRight: "0px",
          }
        },
      },
    },
  },
});

const propTypes = {
  status: PropTypes.oneOf([
    Status.DEFAULT,
    Status.LOADING,
    Status.SUCCESS,
    Status.ERROR,
  ]).isRequired,
  errorMessage: PropTypes.string.isRequired,
  forgetPassword: PropTypes.func.isRequired,
  resetForgetPasswordState: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      emailMode: PropTypes.bool,
      emailValue: PropTypes.string,
      phone: PropTypes.string,
      selectedCountryCode: PropTypes.string,
      screenTitle: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const defaultProps = {};

/**
 * FLOW: User first makes a password reset request,
 * either by entering his/her email/mobile_number
 * and then the screen is directed to ResetPasswordScreen,
 * where user enter otp & new password.
 */
const ForgotPassword = ({
  /**
   * Tells about the status of the forget password api call,
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   *
   * @source: redux
   */
  status,
  /**
   * Contains the error message from server, when status === Status.ERROR
   *
   * @source: redux
   */
  errorMessage: _message,

  /**
   * redux action to initiate forget password api request
   *
   * @source: redux
   */
  forgetPassword: _forgetPassword,
  /**
   * When user hit back button, from ForgetPasswordScreen,
   * reset the state associated with forget password logic in redux
   *
   * @source: redux
   */
  resetForgetPasswordState: _resetForgetPasswordState,
  history: _history,
  userIdentifier,
  otpExpiryTimeInSecs,
}) => {

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm();

  const loggedInUserIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);

  const [email, setEmail] = useState("");
  const [userInputPhoneNumber, setUserInputPhoneNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [value, setValue] = useState(0);
  const [emailToogle, isEmailToogle] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(CountryPhoneCodes[122].countryCode);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formattedNumber = getE164Format(selectedCountryCode, userInputPhoneNumber);
    if (formattedNumber) {
      setPhoneNumber(formattedNumber);
    }
  }, [selectedCountryCode, userInputPhoneNumber]);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      /* Save OTP Expiry Time in Minutes/Seconds to localStorage */
      saveState(ASYNC_STORAGE_KEYS.timerMinutes, 4);
      saveState(ASYNC_STORAGE_KEYS.timerSeconds, 55);
      /* Redirect to ResetPassword screen */
      redirectToResetPassword();
    }

    if (status === Status.ERROR) {
      setApiErrorMessage(_message);
      setOpen(true);
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value.trim());
  };

  const SelectedCountryCodes = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const handlePhone = (e) => {
    const inputValue = e.target.value;
    /* Remove non-numeric characters */
    const numericValue = extractNumbersFromString(inputValue);
    setUserInputPhoneNumber(numericValue);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const onEmailTabClick = (e) => {
    e.preventDefault();
    setApiErrorMessage("");
    isEmailToogle(false);
    resetState();
  };

  const onMobileTabClick = (e) => {
    e.preventDefault();
    setApiErrorMessage("");
    isEmailToogle(true);
    resetState();
  };

  const onSubmit = async (data) => {

    if (!recaptchaToken) {
      return;
    }

    _forgetPassword(email, phoneNumber);
  };

  const resetState = () => {
    _resetForgetPasswordState();
    setUserInputPhoneNumber("");
    setEmail("");
    clearErrors("newEmail");
    clearErrors("phone");
  };

  const redirectToResetPassword = () => {
    _history.push({
      pathname: "/resetpassword",
      state: {
        userIdentifier: userIdentifier,
        otpExpiryTimeInSecs: otpExpiryTimeInSecs,
        otpType: email ? email : phoneNumber,
        loginType: email ? EMAIL_LK : PHONE_NUMBER_LK
      },
    });
  }

  const handleLogoClick = () => {
    _history.push("/");
  }

  if (isNonEmptyString(loggedInUserIdentifier)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className={styles.container}>
          <div className={styles["forms-container"]}>
            <div
              className={styles["signin-signup"]}
              id={styles["forgot-password-signup"]}
            >
              <form
                action="#"
                className={styles["sign-in-form"]}
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
              >
                <div className={styles["forgot-pass-titel-box"]}>
                  <span className={styles["signin-signup-form-title"]}>
                    {translate("forgotPasswordScreen.title")}
                  </span>
                  <p className={styles["forgot-password-sub-title"]}>
                    {translate("forgotPasswordScreen.subTitle")}
                  </p>
                </div>

                <OneLineBreak />
                <div id={styles["signup-screen-group"]}>

                  <Tabs
                    classes={{ indicator: styles["tab-indicator"] }}
                    id={styles["tab-root"]}
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="none"
                    textColor="primary"
                    aria-label="scrollable auto tabs"
                  >
                    <Tab
                      onClick={onEmailTabClick}
                      label={<div>{translate("signinScreen.emailTab")}</div>}
                      classes={{
                        root: styles["tab-label"],
                        selected: styles["selected-tab"],
                      }}
                    />
                    <Tab
                      onClick={onMobileTabClick}
                      label={<div>{translate("signinScreen.mobileTab")}</div>}
                      classes={{
                        root: styles["tab-label"],
                        selected: styles["selected-tab"],
                      }}
                    />
                  </Tabs>

                  {!emailToogle ? (
                    <FormControl id={styles["inbox"]} variant="outlined">
                      <InputLabel
                        className={styles["inbox-label"]}
                        id={errors.newEmail && styles["inbox-label-error"]}
                      >
                        {translate("inboxLabel.emailInboxLabel")}
                      </InputLabel>
                      <OutlinedInput
                        {...register("newEmail", {
                          required: true,
                          pattern: {
                            // eslint-disable-next-line no-useless-escape
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          },
                        })}
                        type="text"
                        id="newEmail"
                        name="newEmail"
                        value={email}
                        onChange={handleEmail}
                        error={
                          errors.newEmail?.type === "pattern" ||
                          errors.newEmail?.type === "required"
                        }
                        labelWidth={40}
                      />
                      <FormHelperText error>
                        {errors.newEmail?.type === "required" ? (
                          <span>
                            {translate("inboxError.emailErrorRequired")}
                          </span>
                        ) : errors.newEmail?.type === "pattern" ? (
                          <span>{translate("inboxError.emailErrorWrong")}</span>
                        ) : null}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    <div id={styles["phone-number-box"]}>
                      <FormControl
                        variant="outlined"
                        id={styles["phone-number-box-select"]}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCountryCode}
                          onChange={SelectedCountryCodes}
                          disabled={true}
                        >
                          {CountryPhoneCodes &&
                            CountryPhoneCodes.map((item, key) => {
                              return (
                                <MenuItem
                                  key={key}
                                  id="activities-name"
                                  value={item.countryCode}
                                >
                                  <img
                                    alt="img"
                                    src={item.flag}
                                    className={selectedCountryCode !== NEPAL_COUNTRY_CODE ? styles["flag-img"] : styles["flag-img-nepal"]}
                                  ></img>
                                  {item.countryCode}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>

                      <FormControl id={styles["inbox"]} variant="outlined">
                        <InputLabel
                          className={styles["inbox-label"]}
                          id={errors.phone && styles["inbox-label-error"]}
                        >
                          {translate("common.phone")}
                        </InputLabel>
                        <OutlinedInput
                          {...register("phone", {
                            required: true,
                          })}
                          type="text"
                          id="phone"
                          name="phone"
                          value={userInputPhoneNumber}
                          onChange={handlePhone}
                          error={errors.phone?.type === "required"}
                          labelWidth={45}
                        />
                        <FormHelperText error>
                          {errors.phone?.type === "required" && <span>{translate("inboxError.mobileErrorRequired")}</span>}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  )}
                  <FormControl id={styles["recaptcha"]}>
                    <Recaptcha setRecaptchaToken={setRecaptchaToken} />
                  </FormControl>
                </div>

                <OneLineBreak />

                {(email === "" && userInputPhoneNumber === "") || !recaptchaToken ? (
                  <button className={cn(styles.solid, styles["btn-disabled"])}>
                    {translate("common.submit")}
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    disabled={status === Status.LOADING ? true : false}
                    className={cn(styles.btn, styles.solid)}
                  >
                    {status === Status.LOADING ? (
                      <CircularProgress id={styles["circular-progress"]} size={20} />
                    ) : (
                      <span>{translate("common.submit")}</span>
                    )}
                  </button>
                )}
              </form>
            </div>
          </div>

          <div className={styles["panels-container"]}>
            <div className={cn(styles.panel, styles["left-panel"])}>
              <div>
                <div className={styles.logo} onClick={handleLogoClick}>
                  <img alt="img" src={LogoImg}></img>
                </div>
                <div className={styles["panel-inside"]}>
                  <div className={cn(styles["panel-center"], styles.content)}>
                    <img
                      alt="img"
                      src={PasswordImg}
                      className={styles["img-lock-first"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      <Snack
        open={open}
        message={apiErrorMessage}
        type="error"
        onClose={handleClose}
      />
    </div>
  );
};

ForgotPassword.propTypes = propTypes;

ForgotPassword.defaultProps = defaultProps;

const mapStateToProps = ({ auth }) => {
  const {
    forgetPasswordStatus: status,
    forgetPasswordErrorMessage: errorMessage,
    userIdentifier,
    otpExpiryTimeInSecs,
  } = auth;
  return {
    status,
    errorMessage,
    userIdentifier,
    otpExpiryTimeInSecs,
  };
};

export default connect(mapStateToProps, {
  forgetPassword,
  resetForgetPasswordState,
})(withRouter(ForgotPassword));
