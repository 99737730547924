/* eslint-disable no-unused-vars */
import { CircularProgress, CssBaseline, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@mui/material';
import {
  AppBar,
  Snack,
  Spinner,
  BackIcon
} from '../../../../common';
import {
  CURRENT_USER_LK,
  ERROR_SK,
  PLI_PROFILE_MODE_LK,
  SUCCESS_SK,
} from '../../../../constants';
import {
  uploadProfileImage,
  uploadCoverImage,
  resetEditProfileState,
} from '../../../../store/actions';
import { Banner } from '..';
import ProfileTab from './ProfileTab';
import { Status } from '../../../../api';
import { translate } from '../../../../i18n';
import ChangePassword from './ChangePassword';
import styles from './User.module.scss';
import cn from 'classnames';
import AccountControlTab from './AccountControlTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const EditProfile = ({
  userDetails,
  editProfileStatus,
  editProfileErrorMessage,
  uploadProfileImage: _uploadProfileImage,
  uploadCoverImage: _uploadCoverImage,
  resetEditProfileState: _resetEditProfileState,
  history,
}) => {
  const {
    profileImg = {},
    coverImg = {},
    name = '',
    city = '',
    totalFollowers = 0,
    totalFollowing = 0,
    // loginType,
  } = userDetails.bio;

  const profileImgUrl = profileImg?.isThumbGenerated
    ? profileImg?.url?.thumbUrl
    : profileImg?.url?.originalUrl;

  const coverImgUrl = coverImg?.isThumbGenerated
    ? coverImg?.url?.thumbUrl
    : coverImg?.url?.originalUrl;

  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackType, setSnackType] = useState('success');
  const [isLanChanged, setIsLanChanged] = useState(false);

  const loginType = localStorage.getItem('loginType');

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    _resetEditProfileState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editProfileStatus === Status.LOADING) {
      setUpdating(true);
    } else if (editProfileStatus === Status.SUCCESS) {
      _resetEditProfileState();
      setUpdating(false);
      setSnackType(SUCCESS_SK);
      showSanck(translate('editProfile.successMessage'));
      checkLanguage();
    } else if (editProfileStatus === Status.ERROR) {
      _resetEditProfileState();
      setUpdating(false);
      setSnackType(ERROR_SK);
      showSanck(editProfileErrorMessage);
    }
  }, [editProfileStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   return () => {
  //     _resetEditProfileState();
  //   }
  // }, []);

  const checkLanguage = () => {
    if (isLanChanged) {
      window.location.reload();
    }
  };

  const toggleIsLanguageChanged = () => {
    setIsLanChanged(!isLanChanged);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const showSanck = (message) => {
    setSnackMsg(message);
    setSnackOpen(true);
  };

  const closeSanck = () => {
    setSnackOpen(false);
  };

  const back = () => {
    history.goBack();
  };

  if (loading) {
    return <Spinner />;
  }

  const ResponsiveTabs = ({ children }) => {
    return (
      <>
        <Tabs
          id={styles['edit-profile-tabs']}
          orientation="vertical"
          variant="fullWidth"
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        // selectionFollowsFocus={true}
        >
          {children}
        </Tabs>
        <Tabs
          id={styles['edit-profile-tabs-mobile']}
          orientation="horizontal"
          variant="fullWidth"
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        // selectionFollowsFocus={true}
        >
          {children}
        </Tabs>
      </>
    );
  };

  return (
    <div
      className={
        updating
          ? cn(styles.updating, styles['user-profile-container'])
          : styles['user-profile-container']
      }
    >
      <CssBaseline />
      <AppBar mode={PLI_PROFILE_MODE_LK} profileType={CURRENT_USER_LK} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10} id={styles["banner-container"]}>
          <Banner
            profileType={CURRENT_USER_LK}
            userDetails={userDetails}
            profileImgUrl={profileImgUrl}
            coverImgUrl={coverImgUrl}
            name={name}
            city={city}
            totalFollower={totalFollowers}
            totalFollowings={totalFollowing}
            mode="edit"
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <BackIcon />
      <div className={styles['edit-profile-container']}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          lg={10}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid xs={1} md={1}></Grid>
          <Grid xs={10} md={10}>
            <Box id={styles['tabs-box']}>
              <ResponsiveTabs>
                <Tab label={translate('common.profile')} {...a11yProps(0)} />
                {!loginType && (
                  <Tab
                    label={translate('common.changePassword')}
                    {...a11yProps(1)}
                  />
                )}
                <Tab label={translate('editProfile.accountOwnershipAndControl')} {...a11yProps(2)} />
              </ResponsiveTabs>
              <TabPanel value={selectedTab} index={0} id={styles["tab-panel"]}>
                <ProfileTab />
              </TabPanel>
              {!loginType && (
                <TabPanel value={selectedTab} index={1} id={styles["tab-panel"]}>
                  <ChangePassword />
                </TabPanel>
              )}

              <TabPanel value={selectedTab} index={loginType ? 1 : 2} id={styles["tab-panel"]}>
                <AccountControlTab />
              </TabPanel>
              {/* <TabPanel value={selectedTab} index={2}>
                <SettingsTab toggleIsLanguageChanged={toggleIsLanguageChanged} />
              </TabPanel> */}
            </Box>
          </Grid>

          <Grid xs={1} md={1}></Grid>
        </Grid>
      </div>
      {updating && (
        <div className={styles['updating-form']}>
          <CircularProgress className="loading_spinner" />
        </div>
      )}
      <Snack
        open={snackOpen}
        message={snackMsg}
        type={snackType}
        onClose={closeSanck}
      />
    </div>
  );
};

const mapStateToProps = ({ app, profile, auth }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const {
    userDetails,
    userIdentifier,
    editProfileStatus,
    editProfileErrorMessage,
  } = profile;

  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    userDetails,
    userIdentifier,
    editProfileStatus,
    editProfileErrorMessage,
  };
};

export default connect(mapStateToProps, {
  uploadProfileImage,
  uploadCoverImage,
  resetEditProfileState,
})(withRouter(EditProfile));
