import React, { useState } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Dialog,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { SmooveIcon, SmooveIcons } from '../../../../../../common';
import { translate } from '../../../../../../i18n';
import { fetchHighwayContacts } from '../../../../../../store/actions';
import { Status } from '../../../../../../api';

import statesAndDivisions from '../../../../../../config/statesAndDivisions';

import styles from './HighwayContacts.module.scss';

const ContactListItem = ({ contacts }) => {
  return (
    <div className={styles['contact-wrap']}>
      {contacts?.length === 0 && (
        <div className={styles['contact-style']}>No Contact</div>
      )}

      {_.map(contacts, (contact, key) => {
        return (
          <div key={key} className={styles['contact-style']}>
            <div className={styles['contact-details-container']}>
              <div className={styles['icon-side']}>
                <HomeIcon className={styles['small-icon']} />
              </div>
              <div className={styles['contact-details']}>
                <span>{contact.title}</span>
              </div>
            </div>
            <div className={styles['contact-details-container']}>
              <div className={styles['icon-side']}>
                <LocalPhoneIcon className={styles['small-icon']} />
              </div>
              <div className={styles['contact-details']}>
                <span>{contact.mobileNumber}</span>
              </div>
            </div>

            {contact.description !== null ? (
              <div className={styles['contact-details-container']}>
                <div className={styles['icon-side']}>
                  <InsertDriveFileIcon className={styles['small-icon']} />
                </div>
                <div className={styles['contact-details']}>
                  <span id={styles['help']}>{contact.description}</span>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const ContactList = ({ data }) => {
  return (
    <React.Fragment>
      <Grid
        id={styles['police-list']}
        item lg={6} md={6}
      >
        <div className={styles['list-title']}>
          <div className={styles['icon']}>
            <div className={styles["smoove-ic-container"]}>
              <SmooveIcon name={SmooveIcons.IcPolice} />
            </div>
            <span className={styles['police-label']}>
              {translate('shalgoerServices.police')}
            </span>
          </div>
        </div>
        <div className="left">
          {data !== null || data !== undefined ? (
            <ContactListItem contacts={data.police} />
          ) : null}
        </div>
      </Grid>
      <Grid
        id={styles['emergency-list']}
        item lg={6} md={6}
      >
        <div className={styles['list-title']}>
          <div className={styles['icon']}>
            <div className={styles["smoove-ic-container"]}>
              <SmooveIcon name={SmooveIcons.IcEmergencyCar} />
            </div>
            <span className={styles['police-label']}>
              {translate('shalgoerServices.emergency')}
            </span>
          </div>
        </div>
        <div className="right">
          {data !== null || data !== undefined ? (
            <ContactListItem contacts={data.emergency} />
          ) : null}
        </div>
      </Grid>
    </React.Fragment>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className={styles['tab-panel']}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const HighwayContacts = ({
  fetchHighwayContacts: _fetchHighwayContacts,
  highwayContactsStatus,
  highwayContactsData,
}) => {
  const { cities } = statesAndDivisions;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [city, setCity] = useState('');
  const [filteredData, setFilteredData] = useState({
    emergency: [],
    police: [],
  });
  const [flag, setFlag] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleOpen = () => {
    setOpen(true);

    if (highwayContactsStatus === Status.DEFAULT) {
      _fetchHighwayContacts();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setValue(0);
    resetState();
    setFlag(true);
  };

  const resetState = () => {
    setCity('');
  };

  const handleStateChange = (event) => {
    const city = event.target.value;
    setCity(city);
    if (city !== '') {
      setFlag(false);
      const { emergency, police } = highwayContactsData;

      const filterEmergency = emergency?.filter((item) => {
        return item?.state?.toLowerCase() === city.state.toLowerCase();
      });

      const filterPolice = police?.filter((item) => {
        return item?.state?.toLowerCase() === city.state.toLowerCase();
      });

      setFilteredData({
        emergency: filterEmergency,
        police: filterPolice,
      });
    } else {
      setFlag(true);
    }
  };
  const listData = {
    emergency: highwayContactsData?.emergency,
    police: highwayContactsData?.police,
  };
  return (
    <div className="for-shalgoers-item-wrapper">
      <Tooltip title="Emergency Contacts" placement="left">
        <div role="button" className="ic-service-container" onClick={handleOpen}>
          <SmooveIcon name={`${SmooveIcons.IcEmengencyContact} ic-service`} />
        </div>
      </Tooltip>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <div className={styles['contacts-dialog']}>
          <IconButton
            aria-label="close"
            className={styles['close-button']}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={styles['contacts-content-container']}>
            <div className={styles['contacts-header']}>
              {translate('shalgoerServices.highwayContactsPhoneNo')}
            </div>
            <div className={styles['tabs']}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                aria-label="full width tabs example"
                classes={{
                  indicator: styles['indicator'],
                }}
              >
                <Tab
                  id="tab-1"
                  label={translate('shalgoerServices.stateOrRegion')}
                  {...a11yProps(0)}
                />
                <Tab
                  id="tab-2"
                  label={translate('shalgoerServices.highway')}
                  {...a11yProps(1)}
                />
              </Tabs>

              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {highwayContactsData ? (
                  <>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <div className={styles['contacts-state']}>
                        <div className={styles['contacts-selectState']}>
                          {translate('shalgoerServices.selectStateDivison')}
                        </div>
                        <div className={styles['contacts-selectBox']}>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            displayEmpty
                            value={city}
                            onChange={handleStateChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={{
                              classes: { paper: styles['menu-style'] },
                            }}
                          >
                            <MenuItem value="">
                              <span>
                                {translate('shalgoerServices.allStateDivision')}
                              </span>
                            </MenuItem>
                            {_.map(cities, (city, key) => {
                              return (
                                <MenuItem value={city} key={key}>
                                  <span>
                                    {translate(
                                      `statesAndDivisions.${city.state
                                        ?.replaceAll(' ', '')
                                        .toLowerCase()}`
                                    )}
                                  </span>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>

                      <Grid
                        container
                        spacing={2}
                        className={styles['contact-grid']}
                      >
                        {flag ? (
                          <ContactList data={listData} />
                        ) : (
                          <ContactList data={filteredData} />
                        )}
                      </Grid>
                    </TabPanel>
                  </>
                ) : (
                  <div className={styles['spinner']}>
                    <CircularProgress className="loading_spinner" />
                  </div>
                )}
                {highwayContactsData ? (
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Grid
                      container
                      spacing={2}
                      className={styles['contact-grid']}
                    >
                      <ContactList data={highwayContactsData?.highway} />
                    </Grid>
                  </TabPanel>
                ) : (
                  <div className={styles['spinner']}>
                    <CircularProgress className="loading_spinner" />
                  </div>
                )}
              </SwipeableViews>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ services }) => {
  const { highwayContactsStatus, highwayContactsData } = services;
  return {
    highwayContactsStatus,
    highwayContactsData,
  };
};

export default connect(mapStateToProps, { fetchHighwayContacts })(HighwayContacts);
