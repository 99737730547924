import "moment/locale/my";
import "moment/locale/en-in";
import Request from "./Request";
import {
  formatYmd,
  getFileExtension,
  isNonEmptyString,
  serverSupportedFormatted,
} from "../utils";
import { getContentMD5 } from "./helpers/headerUtils";
import { OTP_REQUEST_REG } from "../constants/stringConstants";
import { LIMITS } from "../constants";

const { v4: uuidv4 } = require("uuid");
const moment = require("moment-timezone");

class Api {
  constructor() {
    this.request = new Request();
  }

  /**
   * @param {Object} options                - contain extra configuration for request object
   * @param {string} deviceId               - device id
   * @param {string} deviceName             - device name
   * @param {string} sessionId              - session id if user is logged in
   * @param {function} sessionExpiredAction - A redux action which will be dispatch if session is expired
   * @param {function} networkConnectionAction - A redux action which will be dispatch if the request was made but no response was received
   */
  init({ deviceId, deviceName, sessionId, sessionExpiredAction, networkConnectionAction }) {
    this.request.init({
      deviceId,
      deviceName,
      sessionId,
      sessionExpiredAction,
      networkConnectionAction
    });
  }
  /**
   * After a successful login, save session id into
   * request object, which will be send in Headers
   */
  setSessionId(sessionId) {
    this.request.setSessionId(sessionId);
  }

  // ================================ APIs =================================

  config() {
    return this.request.get("/v10/config", null, null);
  }

  fetchBannerImages() {
    const currentDate = formatYmd(new Date());
    const userRequest = {
      currentDate: currentDate,
    };

    return this.request.get(
      "/v10/fetchBannerFile",
      {
        userRequest,
      },
      null
    );
  }

  // fetchHomePageData({ page, attractionIds, articleIds, firstItemId }) {
  //   const userRequest = {
  //     articleIds,
  //     attractionIds,
  //     pagination: {
  //       page,
  //     },
  //     filter: {
  //       firstItemId,
  //     },
  //   };

  //   return this.request.get(
  //     "/v10/fetchHomePageData",
  //     {
  //       userRequest,
  //     },
  //     null
  //   );
  // }

  fetchNewsFeedData({ page, attractionIds, articleIds }) {
    const userRequest = {
      articleIds,
      attractionIds,
      pagination: {
        page,
      },
    };

    return this.request.get("/v10/fetchNewsFeedData", { userRequest }, null);
  }

  fetchArticlesByTagline({ page, articleTagline, rating }) {
    const userRequest = {
      //filter:{},
      articleTagline,
      pagination: {
        page,
      },
    };
    if (rating) {
      userRequest.filter = {
        rating,
      };
    }
    return this.request.get(
      "/v10/fetchArticlesListByTagline",
      { userRequest },
      null
    );
  }

  fetchArticles({ page, sortBy, sortOrder, attractionIdentifier, rating }) {
    const userRequest = {
      sort: {},
      filter: {},
      pagination: {
        page,
      },
    };
    if (sortBy && sortOrder) {
      userRequest.sort = {
        sortBy,
        sortOrder,
      };
    }
    if (attractionIdentifier) {
      userRequest.attractionIdentifier = attractionIdentifier;
    }
    if (rating) {
      userRequest.filter = {
        rating,
      };
    }
    return this.request.get("/v10/fetchArticlesList", { userRequest }, null);
  }

  fetchBusinessContracts({ page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get(
      "/v10/fetchBusinessContractList",
      { userRequest },
      null
    );
  }

  fetchBloggers({ page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchBloggerList", { userRequest }, null);
  }

  updateReaction({ type, reaction, identifier }) {
    const userRequest = {
      reaction,
      entityType: type,
      entityIdentifier: identifier,
    };
    return this.request.post("/v10/updateReaction", null, { userRequest });
  }

  updateMemberNetwork({ status, userIdentifier, entityType = "user" }) {
    const userRequest = {
      networkStatus: status,
      entityIdentifier: userIdentifier,
      entityType,
    };
    return this.request.post("/v10/updateNetwork", null, {
      userRequest,
    });
  }

  getReactions({
    entityIdentifier,
    reactionType,
    entityType,
    page,
    firstItemId,
  }) {
    const userRequest = {
      entityIdentifier,
      reactionType,
      entityType,
      firstItemId,
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/listReactions", { userRequest }, null);
  }

  shareEntity({ type, visibility, identifier, description }) {
    const userRequest = {
      entityType: type,
      entityIdentifier: identifier,
      postVisibilityStatus: visibility,
    };
    if (description !== null && description !== "") {
      userRequest.postDescription = description;
    }
    return this.request.post("/v10/shareEntity", null, {
      userRequest,
    });
  }

  //   deletePost({ identifier }) {
  //     const userRequest = {
  //       postIdentifier: identifier,
  //     };
  //     return this.request.delete('/v10/deletePost', null, { userRequest });
  //   }

  //   deleteBlog({ blogIdentifier }) {
  //     const userRequest = {
  //       blogIdentifier,
  //     };
  //     return this.request.delete('/v10/deleteBlog', null, { userRequest });
  //   }

  report({ identifier, description = "" }) {
    const userRequest = {
      postIdentifier: identifier,
    };
    if (description !== "") {
      userRequest.description = description;
    }
    return this.request.post("/v10/post/report", null, { userRequest });
  }

  saveFavourite({ entityIdentifier, entityType, userIdentifier }) {
    const userRequest = {
      entityIdentifier,
      entityType,
      userIdentifier,
    };
    return this.request.post("/v10/saveFavourite", null, { userRequest });
  }

  deleteFavourite({ favouriteIdentifier }) {
    const userRequest = {
      favouriteIdentifier,
    };
    return this.request.delete("/v10/deleteFavourite", null, { userRequest });
  }

  article({ articleIdentifier, language, updateViewCount }) {
    const userRequest = {
      articleIdentifier,
      updateViewCount: updateViewCount ? "1" : "0",
    };
    return this.request.get("/v10/article", { userRequest }, null, language);
  }

  readMore({ readEntity, identifier }) {
    const userRequest = {
      readEntity,
      identifier,
    };
    return this.request.post("/v10/readMore", null, { userRequest });
  }

  fetchRecommendedAttractions({ identifier, language }) {
    const userRequest = {
      articleIdentifier: identifier,
    };
    return this.request.get(
      "/v10/fetchRecommendedAttractions",
      { userRequest },
      null,
      language
    );
  }

  fetchReviews({ type, identifier, page, sortOrder }) {
    const userRequest = {
      entityType: type,
      entityIdentifier: identifier,
      pagination: {
        page,
      },
      sort: {
        sortOrder,
      },
    };
    return this.request.get("/v10/fetchEntityReviews", { userRequest }, null);
  }

  fetchBusinessReviews({ identifier, page }) {
    const userRequest = {
      reviewType: "all",
      businessIdentifier: identifier,
      pagination: {
        page,
      },
    };
    return this.request.get(
      "/business/fetchBusinessReviews",
      { userRequest },
      null
    );
  }

  fetchBusinessByArticle({ articleIdentifier }) {
    const userRequest = {
      articleIdentifier,
    };
    return this.request.get(
      "/v10/fetchBusinessesByArticle",
      { userRequest },
      null
    );
  }

  addReview({
    type,
    entityIdentifier,
    title,
    description,
    rating,
    identifier,
  }) {
    const userRequest = {
      rating,
      entityType: type,
      entityIdentifier,
    };
    if (title) {
      userRequest.reviewTitle = title;
    }
    if (description) {
      userRequest.reviewDescription = description;
    }
    if (isNonEmptyString(identifier)) {
      userRequest.identifier = identifier;
    }
    return this.request.post("/v10/addReview", null, { userRequest });
  }

  updateReview({ reviewIdentifier, title, description, rating }) {
    const userRequest = {
      reviewIdentifier,
      rating,
    };
    if (title) {
      userRequest.reviewTitle = title;
    }
    if (description) {
      userRequest.reviewDescription = description;
    }
    return this.request.post("/v10/updateReview", null, { userRequest });
  }

  getPostPolicy({
    entityType,
    entityIdentifier,
    policyExpiryInSecs = LIMITS.POLICY_EXPIRY_TIME,
  }) {
    const userRequest = {
      entityType,
      entityIdentifier,
      policyExpiryInSecs,
      timeZone: moment.tz.guess(),
    };

    return this.request.post("/v10/getPostPolicy", null, {
      userRequest,
    });
  }

  uploadMedia({
    file,
    policy,
    signature,
    policyCreatedAt,
    loggedInUserIdentifier,
    entityIdentifier,
    fileDirectory,
    localFileIdentifier,
    caption,
  }) {
    const fileExtension = getFileExtension(file.name).toLowerCase();
    const date = serverSupportedFormatted(new Date()).split("-").join("");
    let { duration } = file;
    if (duration) {
      duration = Math.floor(duration / 1000);
    }

    const data = new FormData();
    data.append("acl", "public-read");
    data.append(
      "success_action_redirect",
      this.request.configuration.awsSuccessRedirectUrl
    );
    data.append("policy", policy);
    data.append(
      "X-amz-credential",
      this.request.configuration.awsXAmzCredential.replace("!@#$%", date)
    );
    data.append("X-amz-algorithm", this.request.configuration.awsXAmzAlgorithm);
    data.append("X-amz-date", policyCreatedAt);
    data.append("X-amz-expires", LIMITS.POLICY_EXPIRY_TIME);
    data.append("X-amz-signature", signature);
    data.append("Content-Type", file.type);
    data.append(
      "key",
      isNonEmptyString(entityIdentifier)
        ? `original/user_data/${loggedInUserIdentifier}/${fileDirectory}/${entityIdentifier}/${uuidv4()}.${fileExtension}`
        : `original/user_data/${loggedInUserIdentifier}/${fileDirectory}/${uuidv4()}.${fileExtension}`
    );
    data.append("file", file);

    return this.request.post("", null, data, true, {
      localFileIdentifier,
      isportrait: "0",
      videoDuration: duration,
      caption,
    });
  }

  getBusinessDetails({ businessIdentifier }) {
    const userRequest = {
      businessIdentifier,
    };
    return this.request.get(
      "/v10/business",
      {
        userRequest,
      },
      null
    );
  }

  fetchBusinessPosts({ identifier, page = 1 }) {
    const userRequest = {
      businessIdentifier: identifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchBusinessPosts", { userRequest }, null);
  }

  fetchNearbyAttractions({ identifier }) {
    const userRequest = {
      businessIdentifier: identifier,
      filter: {
        radius: 30000,
      },
    };
    return this.request.get(
      "/v10/fetchNearByAttractions",
      { userRequest },
      null
    );
  }

  fetchMedia({ mediaType, entityType, entityIdentifier, page }) {
    const userRequest = {
      mediaType,
      entityType,
      entityIdentifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchMedia", { userRequest }, null);
  }

  fetchAlbumFiles({ albumIdentifier, page }) {
    const userRequest = {
      albumIdentifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/getAlbumFiles", { userRequest }, null);
  }

  fetchMemberPosts({ identifier, page = 1 }) {
    const userRequest = {
      userIdentifier: identifier,
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchMemberPosts", { userRequest }, null);
  }

  fetchActivityList({ identifier, page = 1 }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchActivityList", { userRequest }, null);
  }

  createAlbum({ albumName, albumDescription, tempToken }) {
    const userRequest = {
      albumName,
    };
    if (tempToken?.length > 0) {
      userRequest.identifier = tempToken;
    }
    if (albumDescription?.length > 0) {
      userRequest.albumDescription = albumDescription;
    }

    return this.request.post("/v10/createAlbum", null, {
      userRequest,
    });
  }

  deleteAlbum({ albumIdentifier }) {
    const userRequest = {
      albumIdentifier,
    };
    return this.request.delete("/v10/deleteAlbum", null, { userRequest });
  }

  editAlbum({ albumName, albumDescription, identifier }) {
    const userRequest = {
      identifier,
      albumName,
    };
    if (albumDescription?.length > 0) {
      userRequest.albumDescription = albumDescription;
    }

    return this.request.post("/v10/editAlbum", null, {
      userRequest,
    });
  }

  deleteMedia({ fileIdentifier, fileDirectory }) {
    const userRequest = {
      fileIdentifier,
      fileDirectory,
    };

    return this.request.delete("/v10/deleteFile", null, { userRequest });
  }

  updateProfilePic({ fileDirectory, fileIdentifier }) {
    const userRequest = {
      fileDirectory,
      fileIdentifier,
    };
    return this.request.post("/v10/updateProfilePicture", null, {
      userRequest,
    });
  }

  fetchFavourites({ entityType, page }) {
    const userRequest = {
      entityType: entityType === "all" ? "" : entityType,
      pagination: {
        page,
      },
    };

    return this.request.get("/v10/fetchFavourites", { userRequest }, null);
  }

  getUserReviews({ page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/fetchReviewHistory", { userRequest }, null);
  }

  fetchUserNetwork({ networkStatus, page, userIdentifier = null }) {
    const userRequest = {
      networkStatus,
      pagination: {
        page,
      },
    };
    if (userIdentifier) {
      userRequest.userIdentifier = userIdentifier;
    }
    return this.request.get(
      "/v10/fetchUserNetwork",
      {
        userRequest,
      },
      null
    );
  }

  fetchPostDetails({ postIdentifier }) {
    const userRequest = {
      postIdentifier,
    };
    return this.request.get("/v10/post", { userRequest }, null);
  }

  //   addPost({
  //     postVisibilityStatus,
  //     postDescription,
  //     latitude,
  //     longitude,
  //     identifier,
  //   }) {
  //     const userRequest = {
  //       postVisibilityStatus,
  //     };

  //     if (isNonEmptyString(identifier)) {
  //       userRequest.identifier = identifier;
  //     }

  //     if (latitude !== 0 && longitude !== 0) {
  //       userRequest.latitude = latitude;
  //       userRequest.longitude = longitude;
  //     }

  //     if (isNonEmptyString(postDescription)) {
  //       userRequest.postDescription = postDescription;
  //     }

  //     return this.request.post('/v10/post', null, { userRequest });
  //   }

  //   addBlog({
  //     blogDescription,
  //     latitude,
  //     longitude,
  //     identifier,
  //   }) {
  //     const userRequest = {
  //       blogDescription,
  //       identifier,
  //     };

  //     if (latitude !== 0 && longitude !== 0) {
  //       userRequest.latitude = latitude;
  //       userRequest.longitude = longitude;
  //     }

  //     return this.request.post('/v10/blog', null, { userRequest });
  //   }

  //   editPost({ identifier, description, visibility }) {
  //     const userRequest = {
  //       postIdentifier: identifier,
  //       postDescription: description,
  //       postVisibilityStatus: visibility,
  //     };
  //     return this.request.put('/v10/post', null, { userRequest });
  //   }

  //   editBlog({ blogIdentifier, blogDescription }) {
  //     const userRequest = {
  //       blogIdentifier,
  //       blogDescription,
  //     };
  //     return this.request.put('/v10/blog', null, { userRequest });
  //   }

  fetchSearchResult({ keyword, type, page }) {
    const userRequest = {
      keyword,
    };
    if (page) {
      userRequest.pagination = {
        page,
      };
    }
    if (isNonEmptyString(type)) {
      userRequest.entityType = type;
    }
    return this.request.get("/v10/fetchSearchResult", { userRequest }, null);
  }

  fetchStateList() {
    return this.request.get("/v10/state", null, null);
  }

  fetchCityList({ stateName }) {
    const userRequest = {
      stateName,
    };
    return this.request.get("/v10/city", { userRequest }, null);
  }

  fetchAllAttractionList({ page, state, city, rating }) {
    const userRequest = {
      filter: {},
      pagination: {
        page,
      },
    };
    if (state) {
      userRequest.filter.state = state;
    }
    if (city) {
      userRequest.filter.city = city;
    }
    if (rating) {
      userRequest.filter.rating = rating;
    }
    return this.request.get(
      "/v10/fetchAllAttractionList",
      { userRequest },
      null
    );
  }

  fetchRecommendationList({ page, state, city, rating }) {
    const userRequest = {
      filter: {},
      pagination: {
        page,
      },
    };
    if (state) {
      userRequest.filter.state = state;
    }
    if (city) {
      userRequest.filter.city = city;
    }
    if (rating) {
      userRequest.filter.rating = rating;
    }
    return this.request.get(
      "/v10/fetchRecommendationList",
      { userRequest },
      null
    );
  }

  fetchAttractionsByBusiness({ page, state, city, rating, businessIdentifier }) {
    const userRequest = {
      filter: {},
      businessIdentifier,
      pagination: {
        page,
      },
    };
    if (state) {
      userRequest.filter.state = state;
    }
    if (city) {
      userRequest.filter.city = city;
    }
    if (rating) {
      userRequest.filter.rating = rating;
    }
    return this.request.get(
      "/v10/fetchAttractionsByBusiness",
      { userRequest },
      null
    );
  }

  fetchAllHotelList({ page, state, city, rating }) {
    const userRequest = {
      filter: {},
      pagination: {
        page,
      },
    };
    if (state) {
      userRequest.filter.state = state;
    }
    if (city) {
      userRequest.filter.city = city;
    }
    if (rating) {
      userRequest.filter.rating = rating;
    }
    return this.request.get("/v10/fetchAllHotelList", { userRequest }, null);
  }

  fetchAllRestaurantList({ page, state, city, rating }) {
    const userRequest = {
      filter: {},
      pagination: {
        page,
      },
    };
    if (state) {
      userRequest.filter.state = state;
    }
    if (city) {
      userRequest.filter.city = city;
    }
    if (rating) {
      userRequest.filter.rating = rating;
    }
    return this.request.get(
      "/v10/fetchAllRestaurantList",
      { userRequest },
      null
    );
  }

  addBusinessSuggestion({
    businessName,
    businessCategory,
    country,
    destination,
    streetAddress,
    additionalAddress,
    zipCode,
  }) {
    const userRequest = {
      businessName,
      businessCategory,
      country,
      destination,
      streetAddress,
    };
    if (isNonEmptyString(additionalAddress)) {
      userRequest.additionalAddress = additionalAddress;
    }
    if (isNonEmptyString(zipCode)) {
      userRequest.zipCode = zipCode;
    }
    return this.request.post("/v10/addBusinessSuggestion", null, {
      userRequest,
    });
  }

  getAutoSuggestion({ keyword }) {
    const userRequest = {
      keyword,
    };
    return this.request.get("/v10/autoSuggestion", { userRequest }, null);
  }

  fetchComments({ postIdentifier, lastItemId, page }) {
    const userRequest = {
      postIdentifier,
      pagination: {
        page,
      },
    };
    if (page !== 1) {
      userRequest.filter = {
        lastItemId,
      };
    }
    return this.request.get("/v10/fetchRecentComments", { userRequest }, null);
  }

  addComment({ identifier, text }) {
    const userRequest = {
      comment: text,
      postIdentifier: identifier,
    };
    return this.request.post("/v10/addComment", null, { userRequest });
  }

  deleteComment({ identifier }) {
    const userRequest = {
      commentIdentifier: identifier,
    };
    return this.request.delete("/v10/deleteComment", null, { userRequest });
  }

  addReply({ identifier, text }) {
    const userRequest = {
      reply: text,
      commentIdentifier: identifier,
    };
    return this.request.post("/v10/addReply", null, { userRequest });
  }

  saveFCMToken({ fcmToken }) {
    const userRequest = { fcmToken };

    return this.request.post("/v10/saveFcmToken", null, {
      userRequest,
    });
  }

  socialLogin({
    loginType,
    token,
    secretToken,
    userRole,
    ipAddress,
    fcmToken,
  }) {
    const userRequest = { userRole, ipAddress, fcmToken };

    userRequest.loginType = loginType;

    userRequest.token = token;

    if (secretToken) {
      userRequest.secretToken = secretToken;
    }
    return this.request.post("/v10/socialLogin", null, {
      userRequest,
    });
  }

  fetchWeather({ location, units, exclude }) {
    return this.request.getWeather(location, units, exclude);
  }

  fetchExchangeRate() {
    return this.request.get("/v10/fetchExchangeRate", null, null);
  }

  fetchHighwayContacts() {
    return this.request.get("/v10/fetchHighwayContactLists", null, null);
  }

  getPrivacyPolicy() {
    return this.request.get("/v10/getPrivacyPolicy", null, null);
  }

  getInbox({ page }) {
    const userRequest = {
      pagination: {
        page,
      },
    };
    return this.request.get("/v10/inbox", { userRequest }, null);
  }

  fetchSources() {
    return this.request.get("/v10/fetchTMSources", null, null);
  }

  fetchDestinations({ source }) {
    const userRequest = {
      source,
    };

    return this.request.get("/v10/fetchTMDestinations", { userRequest }, null);
  }

  fetchRoutes({ sourceId, destinationId }) {
    const userRequest = {
      sourceId,
      destinationId,
    };

    return this.request.get("/v10/fetchTMRoutes", { userRequest }, null);
  }

  fetchServices({ routeIdentifier }) {
    const userRequest = {
      routeIdentifier,
    };
    return this.request.get("/v10/fetchTMServices", { userRequest }, null);
  }

  fetchCalculations({
    routeIdentifier,
    transportationId,
    accommodationId,
    mealPlanId,
    activityIds,
    person,
    day,
  }) {
    const userRequest = {
      routeIdentifier,
      transportationId,
      accommodationId,
      mealPlanId,
      activityIds,
      person,
      day,
    };
    return this.request.get("/v10/fetchTMCalculations", { userRequest }, null);
  }
  // ============================================================
  // ================= Authentication ========================
  // ============================================================

  signup({ email, phoneNumber, password, userRole }) {
    const userRequest = {
      userRole,
      password: getContentMD5(password),
    };

    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }
    return this.request.post("/v10/registration", null, { userRequest });
  }

  login({ email, phoneNumber, password, userRole, ipAddress, fcmToken }) {
    const userRequest = { userRole, ipAddress, fcmToken };
    userRequest.password = getContentMD5(password);
    userRequest.ipAddress = ipAddress;

    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }

    return this.request.post("/v10/login", null, {
      userRequest,
    });
  }

  forgetPassword({ email, phoneNumber }) {
    const userRequest = { userRole: "member" };

    if (email) {
      userRequest.email = email;
    } else {
      // in api, the key is 'mobileNumber'
      userRequest.mobileNumber = phoneNumber;
    }

    return this.request.post("/v10/forgotPassword", null, {
      userRequest,
    });
  }

  verifyOtp({
    otp,
    userIdentifier,
    otpType = OTP_REQUEST_REG,
    ipAddress,
    fcmToken,
  }) {
    const userRequest = {
      otp,
      otpType,
      userIdentifier,
      ipAddress,
      fcmToken,
    };

    return this.request.post("/v10/verifyOtp", null, {
      userRequest,
    });
  }

  resetPassword({ otp, password, confirmPassword, userIdentifier }) {
    const userRequest = { otp, userIdentifier };

    userRequest.password = getContentMD5(password);
    userRequest.confirmPassword = getContentMD5(confirmPassword);

    return this.request.put("/v10/resetPassword", null, {
      userRequest,
    });
  }

  changePassword({ currentPassword, newPassword, confirmNewPassword }) {
    const userRequest = {
      currentPassword: getContentMD5(currentPassword),
      newPassword: getContentMD5(newPassword),
      confirmNewPassword: getContentMD5(confirmNewPassword),
    };

    return this.request.put("/v10/changePassword", null, { userRequest });
  }

  resendOtp({ otpType, userIdentifier }) {
    return this.request.post("/v10/resendOtp", null, {
      userRequest: {
        otpType,
        userIdentifier,
      },
    });
  }

  fetchInterestList() {
    return this.request.get("/v10/fetchInterestList", null, null);
  }
  editProfile({ userDetailsObjectsToBeUpdate }) {
    const userRequest = {
      ...userDetailsObjectsToBeUpdate,
    };
    return this.request.put("/v10/editProfile", null, { userRequest });
  }

  logout() {
    return this.request.get("/v10/logout", null, null);
  }
  getUserDetails({ userIdentifier, fcmToken = null }) {
    const userRequest = {
      userIdentifier,
    };

    if (fcmToken != null) {
      userRequest.fcmToken = fcmToken;
    }

    return this.request.get(
      "/v10/getUserDetails",
      {
        userRequest,
      },
      null
    );
  }
  // ============================================================
  // ================= Attraction Details ========================
  // ============================================================

  fetchAttractionDetails({ attractionIdentifier, language, updateViewCount }) {
    const userRequest = {
      attractionIdentifier,
      updateViewCount: updateViewCount ? "1" : "0",
    };
    return this.request.get(
      "/v10/attraction",
      {
        userRequest,
      },
      null,
      language
    );
  }
  // ============================================================
  // ================= Fetch Article By Attraction ========================
  // ============================================================
  fetchArticlesByAttraction({ attractionIdentifier, language }) {
    const userRequest = {
      attractionIdentifier,
    };
    return this.request.get(
      "/v10/fetchArticlesByAttraction",
      { userRequest },
      null,
      language
    );
  }
  // ============================================================
  // ================= Fetch Article By Attraction ========================
  // ============================================================
  fetchAllAttractionFiles({ attractionIdentifier }) {
    const userRequest = {
      attractionIdentifier,
    };
    return this.request.get(
      "/v10/fetchAllAttractionFilesbyId",
      { userRequest },
      null
    );
  }
  // ============================================================
  // ================= Fetch Explore Album Photo By Business ========================
  // ============================================================
  fetchExploreAlbumPhotoByBusiness({
    businessIdentifier,
    businessCategory,
    page,
  }) {
    const userRequest = {
      businessIdentifier,
      businessCategory,
      pagination: {
        page,
      },
    };
    return this.request.get(
      "/v10/fetchExploreAlbumPhotoByBusiness",
      { userRequest },
      null
    );
  }

  // ============================================================
  // ================= Account Verification =====================
  // ============================================================
  verifyAccount({
    accountControlType,
    loginType,
    email,
    mobileNumber,
    password,
    reason,
    token
  }) {
    const userRequest = {
      accountControlType,
      loginType,
    };
    if (email) {
      userRequest.email = email;
    }
    if (mobileNumber) {
      userRequest.mobileNumber = mobileNumber;
    }
    if (reason) {
      userRequest.reason = reason;
    }
    if (token) {
      userRequest.token = token;
    }
    if (password) {
      userRequest.password = getContentMD5(password);
    }
    return this.request.post("/v10/verifyAccount", null, { userRequest });
  }

  // ============================================================
  // ================= Account Delete or Deactivate =====================
  // ============================================================
  userAccountControl({
    accountControlType,
    userIdentifier,
    userControl,
    reason,
    deactivatePeriod,
  }) {
    const userRequest = {
      accountControlType,
      userIdentifier,
      userControl,
      reason,
      deactivatePeriod
    };
    return this.request.delete("/v10/userAccountControl", null, { userRequest });
  }

  fetchNotificationList({ page }) {
    const userRequest = {
      pagination: {
        page,
      }
    };
    return this.request.get('/v10/fetchNotificationList', { userRequest }, null);
  }

  deleteNotification({ identifier }) {
    const userRequest = {
      notificationIdentifier: [identifier],
    };
    return this.request.delete('/v10/deleteNotification', null, { userRequest });
  }
}

const api = new Api();

export default api;
