import React, { useEffect, useReducer, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import {
  AppBar,
  BackIcon,
  BackToTopIcon,
  Spinner
} from '../../common';
import { translate } from '../../i18n';
import {
  api,
  Status
} from '../../api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchListItem } from '../SearchResult/components';
import {
  ATTRACTION_SK,
  LANDMARK_LK,
  NEARBY_LK,
  RECOMMENDATIONS_LK
} from '../../constants';
import { connect } from 'react-redux';
import { LIST_MODE } from '../SearchResult/components/constants';
import { FilterCard } from './components';
import { isNonEmptyString } from '../../utils';
import styles from './AdvancedSearch.module.scss';
import Snack from '../../common/Snack/Snack';
import { isEmpty } from 'lodash';
import { EndMessage } from '../../common';

const AttractionList = ({
  /**
   * Logged in user key
   *
   * @source redux
   */
  loggedInUserIdentifier,
  /**
   * Base url need to append with image url to generate full url
   *
   * @source redux
   */
  baseMediaUrl,
  /**
   * Base url pefixed to filePath to generate
   * complete url for thumbnail version of image
   *
   * @source redux
   */
  baseMediaThumbUrl,
  history,
}) => {

  const pathname = history.location.pathname;
  // Extract the last part of the pathname.
  const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
  const _state = history.location.state?.state;

  const businessIdentifier = new URLSearchParams(window.location.search).get('id');

  const [state, setState] = useState(_state ? _state : -1);
  const [city, setCity] = useState(-1);
  const [rating, setRating] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [attractionState, setAttractionState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      list: [],
      apiStatus: Status.DEFAULT,
      moreApiStatus: Status.DEFAULT,
      contentAvailableForRefresh: true,
    }
  );
  const isLoggedIn = isNonEmptyString(loggedInUserIdentifier);
  const [snackSaveOpen, setSnackSaveOpen] = useState(false);
  const [snackUnsaveOpen, setSnackUnsaveOpen] = useState(false);

  useEffect(() => {
    if (page !== 1) {
      fetchList(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchList();
    setPage(1);
  }, [state, city, rating]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (attractionState.apiStatus === Status.SUCCESS) {
      setLoading(false);
    }
  }, [attractionState.apiStatus]);

  const loadMore = () => setPage((prevState) => prevState + 1);

  const fetchList = (_page = 1) => {
    const userRequest = {
      page: _page,
    };
    if (state !== -1) {
      userRequest.state = state;
    }
    if (city !== -1) {
      userRequest.city = city;
    }
    if (rating !== 0) {
      userRequest.rating = rating.toString();
    }
    if (businessIdentifier) {
      userRequest.businessIdentifier = window.atob(businessIdentifier);
    }
    if (_page === 1) {
      setAttractionState({
        apiStatus: Status.LOADING,
      });
    } else {
      setAttractionState({
        moreApiStatus: Status.LOADING,
      });
    }
    api[lastSegment === RECOMMENDATIONS_LK ? "fetchRecommendationList" : lastSegment === LANDMARK_LK ? "fetchAttractionsByBusiness" : "fetchAllAttractionList"](userRequest)
      .then(({ userResponse: attractionList }) => {
        if (_page === 1) {
          setAttractionState({
            list: attractionList,
            contentAvailableForRefresh: !(attractionList.length < 10),
            apiStatus: Status.SUCCESS,
          });
        } else {
          setAttractionState({
            list: [...attractionState.list, ...attractionList],
            contentAvailableForRefresh: !(attractionList.length < 10),
            moreApiStatus: Status.SUCCESS,
          });
        }
      })
      .catch(() => {
        if (_page === 1) {
          setAttractionState({
            apiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        } else {
          setAttractionState({
            moreApiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        }
      });
  };

  const handleStateChange = (value) => {
    setState(value);
  };

  const handleCityChange = (value) => {
    setCity(value);
  };

  const handleRatingChange = (rating) => {
    setRating(rating);
  };

  const handleSnackSaveClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackSaveOpen(false);
  };

  const handleSnackUnsaveClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackUnsaveOpen(false);
  };

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <Spinner />;
  }

  const renderList = () => (
    <Grid container direction="column" spacing={2}>
      {attractionState.list.map((item, key) => (
        <Grid item xs key={key}>
          <SearchListItem
            mode={LIST_MODE}
            type={ATTRACTION_SK}
            item={item}
            isLoggedIn={isLoggedIn}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            history={history}
            favSaveSuccessCallback={() => setSnackSaveOpen(true)}
            favUnSaveSuccessCallback={() => setSnackUnsaveOpen(true)}
          />
        </Grid>
      ))}
    </Grid>
  );

  const loader = (
    <div className={styles['spinner']}>
      <CircularProgress size={22} className="loading_spinner" />
    </div>
  );

  const endMessage = (
    <>
      {
        !isEmpty(attractionState.list) ? <EndMessage /> : <div className={styles['nomore-data-info']}>
          {lastSegment === RECOMMENDATIONS_LK ? translate('advancedSearch.noRecommendedPlacesAvailable') : lastSegment === LANDMARK_LK ? translate('advancedSearch.noNearbyPopularLandmarkAvailable') : translate('advancedSearch.noNearbyAttractionsAvailable')}
        </div>
      }
    </>
  );

  const renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <FilterCard
            state={state}
            city={city}
            rating={rating}
            handleStateChange={handleStateChange}
            handleCityChange={handleCityChange}
            handleRatingChange={handleRatingChange}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <InfiniteScroll
            dataLength={attractionState.list.length} //This is important field to render the next data
            next={
              attractionState.contentAvailableForRefresh &&
              attractionState.apiStatus !== Status.LOADING &&
              attractionState.moreApiStatus !== Status.LOADING &&
              loadMore
            }
            hasMore={attractionState.contentAvailableForRefresh}
            loader={loader}
            endMessage={endMessage}
          >
            <Box>{renderList()}</Box>
          </InfiniteScroll>
        </Grid>
      </Grid>
    );
  };

  const getListingTitle = () => {
    if (lastSegment === RECOMMENDATIONS_LK) {
      return translate('homeScreen.recommendations');
    } else if (lastSegment === NEARBY_LK) {
      return translate('articleDetails.nearbyAttractionsTitle');
    } else {
      return translate("businessProfile.landmarksTitle")
    }
  }

  return (
    <>
      <CssBaseline />
      <div id="back-to-top-anchor" />
      <AppBar />
      <BackIcon />
      <BackToTopIcon />
      <Grid container id={styles['list-root']}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography id={styles['listing-title']}>
            {getListingTitle()}
          </Typography>
          {renderBody()}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Snack
        open={snackSaveOpen}
        onClose={handleSnackSaveClose}
        message={translate('homeScreen.saveFavouriteSuccessMessage')}
      />
      <Snack
        open={snackUnsaveOpen}
        onClose={handleSnackUnsaveClose}
        message={translate('homeScreen.unsaveFavouriteSuccessMessage')}
      />
    </>
  );
};

const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { userIdentifier: loggedInUserIdentifier } = profile;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    loggedInUserIdentifier,
  };
};

export default connect(mapStateToProps, null)(withRouter(AttractionList));
