import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { styled } from '@mui/material/styles';
import {
  Tooltip,
  Dialog,
  IconButton,
  Paper,
  Grid
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { translate } from "../../../../../../i18n";
import { fetchExchangeRate } from "../../../../../../store/actions";
import { api, Status } from "../../../../../../api";

import {
  Spinner,
  SmooveIcon,
  SmooveIcons
} from "../../../../../../common";
import { getExchangeRateFormattedDate } from "../../../../../../utils";

import usFlag from "../../../../../../assets/countryFlags/United_States_of_America_1.png";
import sgFlag from "../../../../../../assets/countryFlags/Singapore_65.png";
import jpFlag from "../../../../../../assets/images/japan.png";
import hkFlag from "../../../../../../assets/countryFlags/Hong_Kong_852.png";
import thaiFlag from "../../../../../../assets/countryFlags/Thailand_66.png";
import indiaFlag from "../../../../../../assets/images/india.png";
import chinaFlag from "../../../../../../assets/countryFlags/China_86.png";
import malaysiaFlag from "../../../../../../assets/countryFlags/Malaysia_60.png";
import russiaFlag from "../../../../../../assets/images/russia.png";
import ukFlag from "../../../../../../assets/countryFlags/United_Kingdom_44.png";
import styles from "./ExchangeRate.module.scss";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: "#000",
  border: "1px solid #e0e0e0",
  height: "50px",
  boxShadow: "none !important",
}));

const ExchangeRate = () => {
  const [open, setOpen] = useState(false);
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [date, setDate] = useState("");

  const rates = [
    {
      countryCode: "USD",
      flag: usFlag,
      unit: 1,
      rate: exchangeRateData?.USD
    },
    {
      countryCode: "GBP",
      flag: ukFlag,
      unit: 1,
      rate: exchangeRateData?.GBP
    },
    {
      countryCode: "RUB",
      flag: russiaFlag,
      unit: 1,
      rate: exchangeRateData?.RUB
    },
    {
      countryCode: "CNY",
      flag: chinaFlag,
      unit: 1,
      rate: exchangeRateData?.CNY
    },
    {
      countryCode: "THB",
      flag: thaiFlag,
      unit: 1,
      rate: exchangeRateData?.THB
    },
    {
      countryCode: "SGD",
      flag: sgFlag,
      unit: 1,
      rate: exchangeRateData?.SGD
    },
    {
      countryCode: "JPY",
      flag: jpFlag,
      unit: 100,
      rate: exchangeRateData?.JPY
    },
    {
      countryCode: "HKD",
      flag: hkFlag,
      unit: 1,
      rate: exchangeRateData?.HKD
    },
    {
      countryCode: "MYR",
      flag: malaysiaFlag,
      unit: 1,
      rate: exchangeRateData?.MYR
    },
    {
      countryCode: "IDR",
      flag: indiaFlag,
      unit: 1,
      rate: exchangeRateData?.IDR
    }
  ]

  useEffect(() => {
    if (open) {
      setApiStatus(Status.LOADING);
      api
        .fetchExchangeRate()
        .then(({ userResponse }) => {
          setExchangeRateData(userResponse.rates);
          setDate(getExchangeRateFormattedDate(userResponse.timestamp));
          setApiStatus(Status.SUCCESS);
        })
        .catch((error) => {
          setApiStatus(Status.ERROR);
        });
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles["exchange-rate"]}>
      <Tooltip aria-label="Exchange Rate" title="Exchange Rate" placement="left">
        <div role="button" className="ic-service-container" onClick={handleOpen}>
          <SmooveIcon name={`${SmooveIcons.IcExchangeRate} ic-service`} />
        </div>
      </Tooltip>

      <Dialog
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <div className={styles["exchange-rate-dialog"]}>
          <IconButton
            aria-label="close"
            className={styles["close-button"]}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <div className={styles["exchange-rate-content"]}>
            <div>
              <span className={styles["exchange-rate-title"]}>
                <div className={styles["exchange-rate-table"]}>
                  {translate("shalgoerServices.exchangeRateTitle")}
                </div>
              </span>
            </div>
            {apiStatus === Status.LOADING ? (
              <Spinner />
            ) : apiStatus === Status.ERROR ? (
              <div className={styles["exchange-rate-unavailable-container"]}>
                <div className={styles["exchange-rate-unavailable"]}>
                  {translate("shalgoerServices.exchangeRateUnavailable")}
                </div>
                <a
                  href="https://forex.cbm.gov.mm/index.php/fxrate"
                  target="blank"
                  className={styles["myanmar-entral-bank"]}
                >
                  <strong>{translate("shalgoerServices.bankName")}.</strong>
                </a>
              </div>
            ) : (
              <div className={styles["exchange-rate-table-container"]}>
                <div className={styles["exchange-rate-table"]}>
                  <div className={styles["exchange-rate-reference-title"]}>
                    {translate(
                      "shalgoerServices.exchangeRateReferenceDate"
                    ).replace(/%s/g, date)}
                  </div>
                  <Grid container spacing={1} >
                    <Grid item xs={12}>
                      <Item>
                        <div className={styles['exchange-rate-header']}>{translate("common.country")}</div>
                        <div className={styles['exchange-rate-header']}>{translate("common.unit")}</div>
                        <div className={styles['exchange-rate-header']}>{translate("common.rate")}</div>
                      </Item>
                    </Grid>
                    {rates.map((item, index) => {
                      return < Grid item xs={12} key={index} >
                        <Item>
                          <div className={styles["exchange-rate-item"]}>
                            <div className={styles["exchange-rate-country"]}>
                              <img
                                alt="img"
                                src={item.flag}
                                className={styles["flag"]}
                              />
                              <div className={styles['country-code']}>{item.countryCode}</div>
                            </div>
                          </div>
                          <div className={styles["exchange-rate-item"]}>{item.unit}</div>
                          <div className={styles["exchange-rate-item"]}>
                            {item.rate} {translate("common.mmk")}
                          </div>
                        </Item>
                      </Grid>
                    })}
                  </Grid>
                  <div className={styles["exchange-rate-reference-bank"]}>
                    {translate("shalgoerServices.exchangeRateReferenceTitle")}
                  </div>
                  <div className={styles["exchange-rate-reference-subtitle"]}>
                    {translate("shalgoerServices.exchangeRateReferenceSubTitle")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog >
    </div >
  );
};

const mapStateToProps = ({ services }) => {
  const { exchangeRateStatus, exchangeRateData } = services;
  return {
    exchangeRateStatus,
    exchangeRateData,
  };
};

export default connect(mapStateToProps, { fetchExchangeRate })(ExchangeRate);
