import React from 'react';
import Carousel from "react-multi-carousel";
import '../../../../node_modules/react-multi-carousel/lib/styles.css'
import styles from "../../../components/AttractionDetails/AttractionDetails.module.scss"

const AttractionGallery = (props) => {
    const {
        images,
        handlePhotoClick
    } = props;

    const responsive = {
        desktop1: {
            breakpoint: { max: 3000, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
    };

    return (
        <div className="attraction-gallery">
            < Carousel
                swipeable={true}
                autoPlay={false}
                draggable={true}
                centerMode={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                containerClass="attraction-gallery"
                dotListClass="custom-dot-list-style"
            >
                {images?.map((item, key) => {
                    return (
                        <div className='img_div' key={key}>
                            <img
                                alt='img'
                                src={item?.filePath?.originalUrl}
                                className={styles["gallery-img"]}
                                onClick={() => handlePhotoClick(key)}
                            />
                        </div>
                    )
                })}
            </Carousel >
        </div>
    );
};

export default AttractionGallery;





