import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import {
  sharePost,
  sharePostSucess,
  updateShareCount,
  editPost,
} from '../../store/actions';
import {
  getBloggerOptions,
  getPrivacyOptions,
} from '../../config/privacyOptions';
import { makeStyles, IconButton, Avatar, MenuItem } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  //ARTICLE_SK,
  //ATTRACTION_SK,
  BLOGGER_SK,
  BLOG_SK,
  // POST_SK,
  SUCCESS_SK,
} from '../../constants';
import { api, Status } from '../../api';
import { translate } from '../../i18n';
import {
  Badge,
  CardHeader,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  Stack,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import bloggerMarkIcon from '../../assets/images/icon_blogger_mark.png';
import cancelIcon from '../../assets/images/icon_remove_media.png';
import { Button } from '../Button/Button';

const EDIT_MODE = 'edit';
const SHARE_MODE = 'share';

// const propTypes = {
//   name: PropTypes.string,
//   baseMediaUrl: PropTypes.string,
//   baseMediaThumbUrl: PropTypes.string,
//   profileImg: PropTypes.shape({
//     isThumbGenerated: PropTypes.number,
//     url: PropTypes.string,
//     localPath: PropTypes.bool,
//   }),
//   mode: PropTypes.oneOf([EDIT_MODE, SHARE_MODE]).isRequired,
//   type: PropTypes.oneOf([POST_SK, ARTICLE_SK, BLOG_SK, ATTRACTION_SK])
//     .isRequired,
//   identifier: PropTypes.string.isRequired,
//   incrementNumberOfShare: PropTypes.func,
//   description: PropTypes.string,
//   visibility: PropTypes.string,
//   updateDescriptionAndVisibility: PropTypes.func,
//   isPostOnlyContainsDescription: PropTypes.bool,
//   sharePostSucess: PropTypes.func.isRequired,
// };

// const defaultProps = {
//   name: '',
//   baseMediaUrl: '',
//   baseMediaThumbUrl: '',
//   incrementNumberOfShare: () => { },
// };

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#F2F2EE',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
        },
      },
    },
  },
});

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0 !important',

    '& fieldset': {
      border: 'none', //'1px dashed #979DA0',
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      border: 'none', //'1px dashed #979DA0',

      borderRadius: '8px',
    },
    '&.Mui-focused fieldset': {
      border: 'none', //'1px dashed #979DA0',

      borderRadius: '8px',
    },
  },
});

const EditShareModal = ({
  open,
  /**
   * Logged in user name
   *
   * @source redux
   */
  name,
  /**
   * Base url prefix for @profileImageUrl
   *
   * @source redux
   */
  baseMediaUrl,
  baseMediaThumbUrl,
  /**
   * Logged in user profile image url
   *
   * @source redux
   */
  profileImg,
  userRole,
  mode,
  type,
  identifier,
  isShared = false,
  description: postDescription = '',
  visibility: postVisibility,
  isPostOnlyContainsDescription,
  incrementNumberOfShare: _incrementNumberOfShare,
  updateDescriptionAndVisibility,
  /**
   * After success response from share api, this action dispatch the response
   * to be added on post list on HomeScreen, to make shared post visible
   *
   * @source redux
   */
  sharePost: _sharePost,
  sharePostSucess: _sharePostSucess,
  handleClose: _handleClose,
  updateShareCount: _updateShareCount,
  editPost: _editPost,
  showSnack,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const privacyOptions = useMemo(() =>
    type === BLOG_SK && !isShared
      ? getBloggerOptions().slice(0, 1)
      : getPrivacyOptions()
  );

  const getDefaultVisibilityObject = (postVisibility) =>
    privacyOptions.find((option) => option.key === postVisibility) ||
    privacyOptions[0];

  /**
   * @apiStatus Tells about the status of the share entity api call
   *
   * if status === Status.DEFAULT => api hasn't been hit yet
   * if status === Status.LOADING => api is currently being executed
   * if status === Status.SUCCESS => success response from api
   * if status === Status.ERROR   => error response from api
   */
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [visibility, setVisibility] = useState(
    mode === EDIT_MODE
      ? getDefaultVisibilityObject(postVisibility).key
      : privacyOptions[0].key
  );
  const [description, setDescription] = useState(
    mode === EDIT_MODE ? postDescription : ''
  );

  useEffect(() => {
    if (mode === EDIT_MODE) {
      setDescription(postDescription);
      setVisibility(postVisibility);
    }
  }, [postDescription, postVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrivacyChange = (event) => {
    setVisibility(event.target.value);
  };

  const handleInputChange = (event) => {
    setDescription(event.target.value);
  };

  const handleShare = () => {
    if (apiStatus !== Status.LOADING) {
      setApiStatus(Status.LOADING);
      _sharePost();
      api
        .shareEntity({
          type,
          visibility,
          identifier,
          description: description?.trim(),
        })
        .then(({ userResponse }) => {
          setApiStatus(Status.SUCCESS);
          _updateShareCount(identifier);
          _sharePostSucess(userResponse);
          _incrementNumberOfShare();
          _handleClose();
        })
        .catch(() => {
          // Error toast is handle by api object
          setApiStatus(Status.ERROR);
        });
    }
  };

  const handleEditPost = () => {
    if (type === BLOG_SK) {
      editBlog();
    } else {
      setApiStatus(Status.LOADING);
      api
        .editPost({
          visibility,
          identifier,
          description: description?.trim(),
        })
        .then(() => {
          setApiStatus(Status.SUCCESS);
          showSnack({
            type: SUCCESS_SK,
            message: translate('homeScreen.postEditSuccessMessage'),
          });
          _editPost(identifier, visibility, description);
          _handleClose();
        })
        .catch(() => {
          // Error toast is handle by api object
          setApiStatus(Status.ERROR);
        });
    }
  };

  const editBlog = () => {
    setApiStatus(Status.LOADING);
    api
      .editBlog({
        blogIdentifier: identifier,
        blogDescription: description?.trim(),
      })
      .then(() => {
        setApiStatus(Status.SUCCESS);
        showSnack({
          type: SUCCESS_SK,
          message: translate('homeScreen.postEditSuccessMessage'),
        });
        _editPost(identifier, postVisibility, description);
        _handleClose();
      })
      .catch(() => {
        // Error toast is handle by api object
        setApiStatus(Status.ERROR);
      });
  };

  const renderHeader = () => {
    const avatar =
      userRole === BLOGGER_SK ? (
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <img id="create-post-badge" alt="badgeimg" src={bloggerMarkIcon} />
          }
        >
          <Avatar
            src={profileImg?.url?.originalUrl}
            id="create-post-blogger-avatar"
          />
        </Badge>
      ) : (
        <Avatar src={profileImg?.url?.originalUrl} id="create-post-avatar" />
      );
    const title = <Typography id="create-post-profile-name">{name}</Typography>;
    const subheader = (
      <ThemeProvider theme={theme}>
        <FormControl>
          <Select
            disabled={type === BLOG_SK && !isShared}
            value={visibility}
            size="small"
            IconComponent={
              type !== BLOG_SK || isShared
                ? ExpandMoreIcon
                : () => {
                  return null;
                }
            }
            className={classes.selectStyle}
            onChange={handlePrivacyChange}
          >
            {privacyOptions.map((option) => {
              return (
                <MenuItem key={option.key} value={option.key}>
                  <Stack
                    direction="row"
                    spacing={1}
                    id="privacy-wrapper"
                    alignItems="center"
                  >
                    <img
                      alt="img"
                      className={
                        option.key === BLOG_SK && !isShared
                          ? 'blog-icon'
                          : 'privacy-icon'
                      }
                      src={option.icon}
                    />
                    <div className="privacy-title">{option.title}</div>
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ThemeProvider>
    );
    const action = (
      <IconButton className={classes.closeButtonStyle} onClick={_handleClose}>
        <img id="icon-remove-media" alt="icon" src={cancelIcon} />
      </IconButton>
    );
    return (
      <>
        <CardHeader
          avatar={avatar}
          title={title}
          subheader={subheader}
          action={action}
          className={classes.cardHeaderStyle}
        />
      </>
    );
  };

  const renderContent = () => {
    return (
      <CssTextField
        id="create-post-text-input"
        value={description}
        fullWidth
        multiline
        minRows={4}
        autoFocus
        placeholder={translate('editShareModal.placeholderMessage')}
        onChange={handleInputChange}
      />
    );
  };

  const renderFooter = () => {
    return (
      <Button
        id="edit-post-action-button"
        variant="contained"
        size="small"
        onClick={mode === EDIT_MODE ? handleEditPost : handleShare}
      >
        {mode === SHARE_MODE
          ? translate('editShareModal.shareButton')
          : translate('common.save')}
      </Button>
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      scroll="paper"
      PaperProps={{
        id: 'edit-post-dialog',
      }}
      onClose={_handleClose}
    >
      <DialogTitle>{renderHeader()}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>{renderFooter()}</DialogActions>
      {apiStatus === Status.LOADING && (
        <div id="loading-circle">
          <CircularProgress className="loading_spinner" />
        </div>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButtonStyle: {
    position: 'absolute !important',
    right: 0,
    top: 0,
    color: '#010D18 !important',
    zIndex: 1000,
  },
  selectStyle: {
    width: '122px',
    height: '25px',
    marginTop: '3px',
  },
  cardHeaderStyle: {
    padding: '0 !important',
  },
}));

// EditShareModal.propTypes = propTypes;

// EditShareModal.defaultProps = defaultProps;

const mapStateToProps = ({ profile, app }) => {
  const { baseMediaThumbUrl, baseMediaUrl } = app;
  const {
    userDetails: {
      bio: { name, profileImg, userRole },
    },
  } = profile;
  return {
    name,
    baseMediaUrl,
    baseMediaThumbUrl,
    profileImg,
    userRole,
  };
};

export default connect(mapStateToProps, {
  sharePost,
  sharePostSucess,
  updateShareCount,
  editPost,
})(EditShareModal);
