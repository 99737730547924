// Fisher-Yates Shuffle Modern Algorithm to suffle the array
export function shuffle(array) {
    const arrayCopy = [...array];
  
    let ctr = arrayCopy.length;
    let temp;
    let index;
  
    while (ctr > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * ctr);
      // Decrease ctr by 1
      ctr -= 1;
      // And swap the last element with it
      temp = arrayCopy[ctr];
      arrayCopy[ctr] = arrayCopy[index];
      arrayCopy[index] = temp;
    }
    return arrayCopy;
  }
  
  // sort media list to take all images at start
  export function sortMediaList(mediaList) {
    const images = [];
    const videos = [];
    mediaList.forEach(item => {
      if (item.mime === 'video/mp4') {
        videos.push(item);
      } else {
        images.push(item);
      }
    });
    return images.concat(videos);
  }
  
  // genereate media uri list
  export function generateMediaUriList(mediaList) {
    const uriList =[];
    mediaList?.forEach(item => {
      uriList.push(item.source.uri);
    });
    return uriList;
  }