import React, { useState } from "react";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import {
  WANDER_TO_GO_SK,
  INTERESTED_SK,
  LOVE_SK,
  NONE_SK,
} from "../../constants";
import WONDER_TO_GO from "../../assets/images/icon_travel_active.png";
import INTERESTED from "../../assets/images/icon_interested_active.png";
import LOVE from "../../assets/images/icon_heart_active.png";
import styles from "./ReactPopUp.module.scss";

const images = [
  { id: WANDER_TO_GO_SK, img: WONDER_TO_GO },
  { id: INTERESTED_SK, img: INTERESTED },
  { id: LOVE_SK, img: LOVE },
];

const ReactionPopUp = (props) => {
  const {
    currentSelection,
    loggedIn,
    setSelectedReaction,
    showLoginPrompt,
  } = props;

  const [selected, setSelected] = useState(currentSelection);
  const [popUpEnabled, setPopUpEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const loginAlert = () => {
    showLoginPrompt();
  };

  const react = (selectedReaction) => {
    if (!loggedIn) {
      loginAlert();
      return;
    }
    setSelectedReaction(selectedReaction);
    setSelected(selectedReaction);
    setAnchorEl(null);
  };

  const removeReact = () => {
    if (!loggedIn) {
      loginAlert();
      return;
    }
    const selectedReaction = selected !== NONE_SK ? NONE_SK : WANDER_TO_GO_SK;
    setSelectedReaction(selectedReaction);
    setSelected(selectedReaction);
    setAnchorEl(null);
  };

  const getReactionIcon = (selected) => {
    switch (selected) {
      case WANDER_TO_GO_SK:
        return WONDER_TO_GO;
      case INTERESTED_SK:
        return INTERESTED;
      case LOVE_SK:
        return LOVE;
      default:
        return WONDER_TO_GO;
    }
  };

  const handleOpen = (event) => {
    if (loggedIn) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "spring-popper" : undefined;

  return (
    <div className={styles.reactIconBox} onMouseLeave={handleClose}>
      <img
        className={styles.reactIcon}
        alt="img"
        src={getReactionIcon(selected)}
        aria-describedby={id}
        onMouseEnter={handleOpen}
        onClick={removeReact}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div className={styles.reactionPopup}>
              {images.map((image, key) => {
                return (
                  <div
                    key={key}
                    id={image.id}
                    className={styles.reactionIconContainer}
                    onClick={() => react(image.id)}
                  >
                    <img
                      alt="img"
                      src={image.img}
                      className={
                        image.id === LOVE_SK
                          ? styles.reactionIconSm
                          : styles.reactionIcon
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default ReactionPopUp;
