import api from '../../../../../api/api';
import {
  COVER_FILE_DIR_SK,
  PROFILE_FILE_DIR_SK,
} from '../../../../../constants/stringConstants';

/**
 * make cover photo
 * @param option image object after parshed media
 * @param _updatePhoto action to update in user profile reduer
 */
export function makeCover(option, _updatePhoto) {
  api
    .updateProfilePic({
      fileDirectory: COVER_FILE_DIR_SK,
      fileIdentifier: option.identifier,
    })
    .then(response => {
      let statusMsg = response.userResponse.status;
      _updatePhoto(COVER_FILE_DIR_SK, option, statusMsg);
    })
    .catch(() => {
      // Toast message already shown in common handling
    });
}

/**
 * make profile photo
 * @param option image object after parshed media
 * @param _updatePhoto action to update in user profile reduer
 */
export function makeProfile(option, _updatePhoto) {
  api
    .updateProfilePic({
      fileDirectory: PROFILE_FILE_DIR_SK,
      fileIdentifier: option.identifier,
    })
    .then(response => {
      let statusMsg = response.userResponse.status;
      _updatePhoto(PROFILE_FILE_DIR_SK, option, statusMsg);
    })
    .catch(() => {
      // Toast message already shown in common handling
    });
}
