/* eslint-disable no-unused-vars */
import { IMAGE_SK, VIDEO_SK } from "../../constants";
import { getVideoThumb } from "../../utils/validations";

/**
 *  parse server list to add base and thumb url in image object
 * @param mediaList
 * @param baseMediaUrl
 * @param baseMediaThumbUrl
 * @return {*}
 */
export function parseMedia(mediaList, baseMediaUrl, baseMediaThumbUrl) {
  let _baseMediaUrl = baseMediaUrl;

  return mediaList.map(itemValue => {
    const extraProps = {};

    if (itemValue?.fileType === VIDEO_SK) {
      extraProps.dimensions = {
        width: '100vw',//DIMENS.common.WINDOW_WIDTH,
        height: '100vh',//DIMENS.common.WINDOW_HEIGHT,
      };
    }

    // if local image present than do not add base url
    if (itemValue.localPath) {
      _baseMediaUrl = '';
    }

    return {
      source: {
        // uri: itemValue?.isThumbGenerated
        //   ? itemValue?.filePath?.thumbUrl?.concat('?a=1')
        //   : itemValue?.filePath?.originalUrl,
        uri: itemValue?.filePath?.originalUrl
      },
      thumb: {
        uri: getThumbLink(itemValue),
      },
      fileType: itemValue?.fileType || IMAGE_SK,
      videoDuration: itemValue?.videoDuration,
      identifier: itemValue?.identifier,
      filePath: itemValue?.filePath,
      isThumbGenerated: itemValue?.isThumbGenerated,
      isPortrait: itemValue?.isPortrait,
      caption: itemValue?.caption,
      ...extraProps,
    };
  });
}

// get correct thumb url for image or video
const getThumbLink = itemValue => {
  // if video local thumb preset than use it instead of filepath which has mp4 link
  if (itemValue.videoThumbnail) return itemValue.videoThumbnail;

  if (itemValue?.fileType === VIDEO_SK) {
    return getVideoThumb(itemValue?.filePath?.originalUrl);
  }

  return itemValue?.filePath?.originalUrl;
};
