import React from 'react';
import { withRouter } from 'react-router';
import styles from './BackIcon.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const BackIcon = ({ history, isCopy }) => {

  /* 
  if "isCopy" is true, then back function will navigate to Homepage
  if "isCopy" is false, then back function will navigate to previous page 
  */
  const back = () => {
    if (isCopy) {
      history.push('/');
    }
    else {
      history.goBack();
    }
  }

  return (
    <div className={styles.root}>
      <KeyboardBackspaceIcon
        id={styles['common-back-icon']}
        onClick={back}
      />
    </div>
  );
};

export default withRouter(BackIcon);
