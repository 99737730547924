import { MYANMAR_COUNTRY_CODE } from "../constants";

export function getFormattedPrice(x) {
  return String(x).replace(/(.)(?=(\d{3})+$)/g, '$1,');
}

export function extractNumbersFromString(value) {
  return value.replace(/[^0-9]/g, '');
}

// This function takes a phone number and formats it according to the specified rules
export function getMMPhoneNumberFormat(phoneNumber) {
  if (phoneNumber.startsWith('09')) {
    // If the phone number starts with '09', replace it with '+959'
    return phoneNumber.replace('09', '+959');
  }
  else if (phoneNumber.startsWith("959")) {
    // If the phone number starts with '959', replace it with '+959'
    return phoneNumber.replace('959', '+959');
  }
  else if (phoneNumber.startsWith('9')) {
    if (phoneNumber.length === 9) {
      // If the phone number starts with '9' and has a length of 9, replace '9' with '+9599'
      return phoneNumber.replace('9', '+9599');
    } else {
      // If the phone number starts with '9' but has a length other than 9, replace '9' with '+959'
      return phoneNumber.replace('9', '+959');
    }
  }
  else if (!phoneNumber.startsWith('+959')) {
    // If the phone number doesn't start with '+959', prepend '+959' to it
    return '+959' + phoneNumber;
  }

  // If none of the conditions above are met, return the original phone number
  return phoneNumber;
}

export function getE164Format(countryCode, phoneNumber) {
  if (countryCode === MYANMAR_COUNTRY_CODE) {
    return getMMPhoneNumberFormat(phoneNumber);
  } else {
    return countryCode + phoneNumber;
  }
}

export function formatRactionCountForDisplay(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1) + 'K';
  } else {
    return num;
  }
}