const ASYNC_STORAGE_KEYS = {
  /**
   * session assigned after a successful login
   */
  sessionId: 'sessionId',
  /**
   * Unique id that distinguishes user
   */
  userIdentifier: 'userIdentifier',
  loginType: 'loginType',
  userPreferedLangage: 'userPreferedLangage',
  userName: 'userName',
  userStatus: 'userStatus',
  incompleteUserIdentifier: 'incompleteUserIdentifier',
  loginAttempt: 'loginAttempt',
  loginExpiryTime: 'loginExpiryTime',
  holdTime: 'holdTime',
  timerMinutes: 'timerMinutes',
  timerSeconds: 'timerSeconds',
  postList: "detialPostList",
};

export default ASYNC_STORAGE_KEYS;
