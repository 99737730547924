import React from 'react';
import { IconButton, CircularProgress } from '@material-ui/core';
import { ACCESS_KEY, getBuildConfig } from '../../../config/buildConfig';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import styles from './ImageDownload.module.scss';

const { GetObjectCommand, S3Client } = require('@aws-sdk/client-s3');

const ImageDownlaod = ({ imageUrl, name, className }) => {
  const buildConfig = getBuildConfig();
  const AccessKey = ACCESS_KEY;
  const [loading, setIsLoading] = useState(false);
  const getObject = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const client = new S3Client({
          region: buildConfig.awsRegion,
          credentials: {
            accessKeyId: AccessKey,
            secretAccessKey: buildConfig.awsSecretAccessKey,
          },
        });

        const getObjectCommand = new GetObjectCommand({
          Bucket: buildConfig.awsBucket,
          Key: imageUrl.split('.com/')[1],
        });

        const response = await client.send(getObjectCommand);
        resolve(response);
      } catch (err) {
        // Handle the error or throw
        return reject(err);
      }
    });
  };

  const download = () => {
    setIsLoading(true);
    getObject()
      .then((response) => {
        const reader = response.Body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      // Update image
      .then((url) => {
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = url;
        a.download = `${name}_menu.png`;
        a.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };
  return (
    <>
      {loading ? (
        <CircularProgress className={className} />
      ) : (
        <Tooltip title="Download">
          <IconButton
            className={className}
            onClick={() => download()}
            disabled={loading}
          >
            <DownloadIcon
              fontSize="small"
              className={styles['media_viewer_close_btn']}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ImageDownlaod;
