import {
  INITIALIZE_APP_REQUEST,
  SESSION_EXPIRED,
  SAVE_FCM_TOKEN_REQUEST,
  NETWORK_CONNECTION,
  CONFIG_REQUEST,
  NOTIFICATION_RECEIVED,
  RESET_REMOTE_NOTIFICATION,
} from '../../constants';

export const initializeApp = () => ({
  type: INITIALIZE_APP_REQUEST,
});

export const config = () => ({
  type: CONFIG_REQUEST,
});

export const sessionExpired = {
  type: SESSION_EXPIRED,
};

export const notificationReceived = {
  type: NOTIFICATION_RECEIVED,
};

export const resetRemoteNotification = () => ({
  type: RESET_REMOTE_NOTIFICATION,
});

export const networkConnection = (networkStatus) => ({
  type: NETWORK_CONNECTION,
  payload: {
    networkStatus
  }
});
/**
 * Action dispatch to logout user, and enter guest mode.
 */
export const saveFcmToken = fcmToken => ({
  type: SAVE_FCM_TOKEN_REQUEST,
  payload: {
    fcmToken,
  },
});