import { shape, string, number, oneOf } from 'prop-types';
import {
  ATTRACTION_SK,
  RELIGIOUS_SK,
  NON_RELIGIOUS_SK,
} from '../../constants/stringConstants';

/**
 * Defines prop-types definition for a single attraction item
 * which is being displayed on HomeScreen
 */
const attractionType = shape({
  avgRating: number,
  category: oneOf([RELIGIOUS_SK, NON_RELIGIOUS_SK]),
  city: string,
  coverImg: shape({
    isThumbGenerated: number,
    url: string,
  }),
  url: string,
  description: string,
  identifier: string,
  title: string,
  totalReviews: number,
  type: oneOf([ATTRACTION_SK]),
});

export default attractionType;
