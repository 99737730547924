import React from 'react';
import image from "../../assets/images/404.png";
import styles from "./404.module.scss";
import { useHistory } from "react-router";
import { translate } from "../../i18n";
const PageNotFound = () => {
    const history = useHistory();
    const goToHome = () => {
        history.push("/");
    }
    return (
        <div className={styles["container"]}>
            <div className={styles["wrapper"]}>
                <img src={image} className={styles["img"]} alt='404' />
                <div className={styles["wrapper-group"]}>
                    <div className={styles["title"]}>
                        {translate("common.pageNotFound")}
                    </div>
                    <div className={styles["button"]} onClick={() => goToHome()}>
                        {translate("common.backToHome")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;