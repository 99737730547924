/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams, withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
  AppBar,
  BackIcon,
  BackToTopIcon,
  // CreatePostIcon,
  CreateReviewIcon,
  MediaViewer,
  RatingStar,
  SmooveIcon,
  SmooveIcons,
  Spinner,
} from '../../../common';

import {
  ALBUM_SK,
  ASYNC_STORAGE_KEYS,
  BUSINESS_PROFILE_LK,
  BUSINESS_SK,
  FOLLOWING_SK,
  HOTEL_SK,
  IMAGE_SK,
  NO_CONNECTION_SK,
  ON_SK,
  RESTAURANT_SK,
  SAVE_SK,
  SLIDER_PHOTO_SK,
  UNFOLLOWED_SK,
  UNSAVE_SK,
} from '../../../constants';

import {
  getFormattedPrice,
  isBusinessOpened,
  isNonEmptyString,
  getFormattedTime,
  hotelMapMarker,
  restaurantMapMarker
} from '../../../utils';

// https://www.npmjs.com/package/google-maps-react
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript
} from "@react-google-maps/api";

import {
  CssBaseline,
  Tabs,
  Tab,
  Grid,
  Avatar,
  Badge,
  Snackbar as SnackBar,
  Alert as MuiAlert,
  IconButton,
  useMediaQuery,
  tabsClasses,
  CircularProgress
} from '@mui/material';

import { TabContext } from '@mui/lab';

import {
  AccessTime,
  MailOutline,
  PhoneIphone,
  LocationOn,
  Language as WebsiteIcon,
  MenuBook as MenuBookIcon,
  Payments as PaymentsIcon,
  East as EastIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon
} from '@mui/icons-material';

import { makeStyles } from '@material-ui/core';
import MuiImageSlider from 'mui-image-slider';

import {
  GalleryTab,
  AboutTab,
  PostTab,
  RoomAndMenuTab,
  ReviewTab
} from '../components';

import OPEN_ICON from '../../../assets/images/icon_restaurant_open.png';
import CLOSE_ICON from '../../../assets/images/icon_restaurant_close.png';

import { updateUserFollowStatus } from '../../../store/actions';
import { api, Status } from '../../../api';
import { translate } from '../../../i18n';
import { GOOGLE_API_KEY } from '../../../config/buildConfig';
import _, { isEmpty } from 'lodash';
import moment from 'moment';

import styles from './BusinessProfile.module.scss';
import cn from 'classnames';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const MyTab = ({
  businessDetails,
  sliderState,
  google,
  children,
  handleSliderClicked,
  subType,
  isLoaded
}) => {
  const classes = useStyles();
  const {
    openingTime,
    openingTime2,
    closingTime,
    closingTime2,
    email,
    streetAddress,
    mobileNumber,
    webSiteUrl,
    latitude,
    longitude,
    name,
    checkInTime,
    checkOutTime,
    maxRange,
    minRange,
  } = businessDetails;
  const _openingTime = getFormattedTime(openingTime);
  const _closingTime = getFormattedTime(closingTime);
  const _openingTime2 = getFormattedTime(openingTime2);
  const _closingTime2 = getFormattedTime(closingTime2);
  const _checkInTime = getFormattedTime(checkInTime);
  const _checkOutTime = getFormattedTime(checkOutTime);
  const _minRange = getFormattedPrice(minRange);
  const _maxRange = getFormattedPrice(maxRange);

  const [state, setState] = useState({
    activeMarker: null,
    showingInfoWindow: false,
  });
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (isLoaded) {
      setMapCenter({
        lat: latitude,
        lng: longitude,
      });
    };
  }, [isLoaded, latitude, longitude]);

  const onMarkerClick = (marker) => {
    setState({
      activeMarker: marker,
      showingInfoWindow: true,
    })
  };

  const onClose = () => {
    if (state.showingInfoWindow) {
      setState((prevState) => ({
        ...prevState,
        showingInfoWindow: false,
      }));
    }
  };

  const viewMoreAlbums = () => {
    handleSliderClicked();
  };

  var mapOptions = {
    // Disables the map type control, which allows users to switch between different map types (e.g., satellite, terrain).
    mapTypeControl: false,

    // Disables the street view control, which allows users to view 360-degree street-level imagery.
    streetViewControl: false,

    // Configures the map styles to modify the appearance of certain map features.
    styles: [

      // Hides attractions from the map. Attractions are points of interest that are typically interesting or entertaining for users.
      {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }],
      },

      // Hides business-related points of interest (e.g., stores, restaurants, hotels) from the map.
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  return (
    <div className={styles['my-tab-container']}>
      <Grid container columnSpacing={{ xs: 2, md: 4 }}>
        <Grid item xs={12} md={4}>
          <div className={styles.left}>
            <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={12}>
                <div className={styles['reach-us']}>
                  <div className={styles.label}>
                    {translate('common.reachUs')}
                  </div>
                  {((openingTime && openingTime !== closingTime) ||
                    checkInTime) && (
                      <div className={styles.item}>
                        {(openingTime !== closingTime || checkInTime) && (
                          <AccessTime />
                        )}
                        <span>
                          {checkInTime
                            ? `${translate(
                              'businessProfile.checkInTime'
                            )} : ${_checkInTime}`
                            : openingTime !== closingTime &&
                            `${_openingTime} ${translate(
                              'businessProfile.to'
                            )} ${_closingTime}`}
                        </span>
                      </div>
                    )}
                  {((openingTime2 && openingTime2 !== closingTime2) ||
                    checkOutTime) && (
                      <div className={styles.item}>
                        {(openingTime2 !== closingTime2 || checkOutTime) && (
                          <AccessTime />
                        )}
                        <span>
                          {checkOutTime
                            ? `${translate(
                              'businessProfile.checkOutTime'
                            )} : ${_checkOutTime}`
                            : openingTime2 !== closingTime2 &&
                            `${_openingTime2} ${translate(
                              'businessProfile.to'
                            )} ${_closingTime2}`}
                        </span>
                      </div>
                    )}

                  {minRange > 0 && _.isNumber(minRange) && (
                    <div className={styles.item}>
                      <PaymentsIcon />
                      <span>
                        {translate('businessProfile.minRange')} : {_minRange} {translate("common.mmk")}
                      </span>
                    </div>
                  )}

                  {maxRange > 0 && _.isNumber(minRange) && (
                    <div className={styles.item}>
                      <PaymentsIcon />
                      <span>
                        {translate('businessProfile.maxRange')} : {_maxRange} {translate("common.mmk")}
                      </span>
                    </div>
                  )}

                  {email && email !== 'NULL' && (
                    <div className={styles.item}>
                      <MailOutline />
                      <span>{email}</span>
                    </div>
                  )}
                  {streetAddress && (
                    <div className={styles.item}>
                      <LocationOn />
                      <span>{streetAddress}</span>
                    </div>
                  )}
                  {mobileNumber && (
                    <div className={styles.item}>
                      <PhoneIphone />
                      <span>{mobileNumber}</span>
                    </div>
                  )}
                  {webSiteUrl && webSiteUrl !== 'NULL' && (
                    <div className={styles.item}>
                      <WebsiteIcon />
                      <span>{webSiteUrl}</span>
                    </div>
                  )}
                </div>
              </Grid>
              {isLoaded ? (
                <Grid item xs={12} sm={6} md={12}>
                  <div className={styles.map}>
                    <GoogleMap
                      zoom={12}
                      mapContainerStyle={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "6px",
                      }}
                      defaultCenter={{
                        lat: latitude,
                        lng: longitude,
                      }}
                      center={mapCenter}
                      options={mapOptions}
                    >
                      {(latitude && longitude) && (
                        <Marker
                          position={{ lat: latitude, lng: longitude }}
                          onClick={(marker) =>
                            onMarkerClick(marker)
                          }
                          name={name}
                          icon={{
                            url:
                              subType === HOTEL_SK
                                ? hotelMapMarker
                                : restaurantMapMarker,
                            scaledSize: new window.google.maps.Size(50, 50),
                          }}
                        >
                          {state.showingInfoWindow && <InfoWindow
                            position={{ lat: latitude, lng: longitude }}
                            marker={state.activeMarker}
                            visible={state.showingInfoWindow}
                            onCloseClick={onClose}
                            disableAutoPan={true}
                            options={{
                              pixelOffset: new window.google.maps.Size(0, 0),
                            }}
                          >
                            <div>
                              <h4>{name}</h4>
                            </div>
                          </InfoWindow>}
                        </Marker>
                      )}

                    </GoogleMap>
                  </div>
                </Grid>) : <></>}
              <Grid item xs={false} sm={false} md={12}>
                {sliderState.apiStatus === Status.SUCCESS &&
                  sliderState.photos.length !== 0 && (
                    <div className={styles['slider-container']}>
                      <MuiImageSlider
                        autoPlay
                        arrows={false}
                        images={sliderState.photos}
                        classes={classes}
                      />
                      <div
                        className={styles['view-more']}
                        onClick={viewMoreAlbums}
                      >
                        <span id={styles.label}>
                          {translate('common.viewMore')}
                        </span>
                        <div>
                          <EastIcon />
                        </div>
                      </div>
                    </div>
                  )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className={styles.right}>{children}</div>
        </Grid>
      </Grid>
    </div>
  );
};

const BusinessProfile = ({
  history,
  google,
  baseMediaUrl,
  baseMediaThumbUrl,
  updateUserFollowStatus: _updateUserFollowStatus,
}) => {

  // Using the "useLoadScript" hook to asynchronously load the Google Maps API with the provided API key.
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );
  let { id: businessId, type: _type } = useParams();
  const businessIdentifier = atob(businessId);
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [businessDetails, setBusinessDetails] = useState({});
  const [followStatus, setFollowStatus] = useState(NO_CONNECTION_SK);
  const [opened, setOpened] = useState(false);
  const [secondOpened, setSecondOpened] = useState(false);
  const [saveApiStatus, setSaveApiStatus] = useState(Status.DEFAULT);
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackMsg, setSnackMsg] = useState('');
  const [snackOpen, setSanckOpen] = useState(false);
  const [isFollowing, setIsFollowing] = useState(true);
  const [followApiStatus, setFollowApiStatus] = useState(Status.DEFAULT);
  const [isReviewUpdated, setIsReviewUpdated] = useState(false);
  const [sliderState, setSliderState] = useState({
    albumIdentifier: '',
    photos: [],
    apiStatus: Status.DEFAULT,
  });
  const [isSliderClicked, setIsSliderClicked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // Check if the screen width is smaller than or equal to 780px.
  const isTablet = useMediaQuery('(max-width: 780px)');

  // Set the variant of the Tabs component based on the screen size.
  // If the screen is smaller than or equal to 780px, set the variant to 'scrollable'.
  // Otherwise, set it to 'fullWidth'.
  const variant = isTablet ? 'scrollable' : 'fullWidth';

  useEffect(() => {
    getBusinessDetail(businessIdentifier);
    getSliderPhotos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      Object.keys(businessDetails).length > 0 &&
      'networkStatusWithCurrentUser' in businessDetails
    ) {
      setFollowStatus(
        businessDetails.networkStatusWithCurrentUser.followStatus
      );
    }
    if (Object.keys(businessDetails).length > 0) {
      const _openingTime = businessDetails.openingTime;
      const _closingTime = businessDetails.closingTime;
      const _openingTime2 = businessDetails.openingTime2;
      const _closingTime2 = businessDetails.closingTime2;
      const _opened = isBusinessOpened(_openingTime, _closingTime);
      setOpened(_opened);

      if (_openingTime2 < moment().format('HH:mm:ss')) {
        if (!isEmpty(_openingTime2) && !isEmpty(_closingTime2)) {
          if (_openingTime2 !== _closingTime2) {
            setSecondOpened(isBusinessOpened(_openingTime2, _closingTime2));
          }
        }
      }
    }
  }, [businessDetails]);

  const reload = (newIdentifier) => {
    setSelectedTab(0);
    getBusinessDetail(newIdentifier);
  };

  const getBusinessDetail = (businessIdentifier) => {
    setApiStatus(Status.LOADING);
    api
      .getBusinessDetails({
        businessIdentifier,
      })
      .then(({ userResponse: { businessDetails } }) => {
        setBusinessDetails({
          ...businessDetails,
          foodMenu: businessDetails.foodMenu?.map((item) => {
            return {
              ...item,
              fileType: IMAGE_SK,
            };
          }),
        });
        setApiStatus(Status.SUCCESS);
      })
      .catch(() => {
        // Error toast is handle by api object
        setApiStatus(Status.ERROR);
      });
  };

  const getSliderPhotos = () => {
    if (sliderState.apiStatus !== Status.LOADING) {
      setSliderState({ apiStatus: Status.LOADING });
      api
        .fetchMedia({
          mediaType: ALBUM_SK,
          entityType: BUSINESS_SK,
          entityIdentifier: businessIdentifier,
          page: 1,
        })
        .then(({ userResponse: { album: albums } }) => {
          let sliderAlbumId = '';
          albums.forEach((album) => {
            if (album.albumName === SLIDER_PHOTO_SK) {
              sliderAlbumId = album.identifier;
            }
          });
          if (isNonEmptyString(sliderAlbumId)) {
            api
              .fetchAlbumFiles({
                albumIdentifier: sliderAlbumId,
                page: 1,
              })
              .then(({ userResponse: { files: sliderPhotos } }) => {
                let photos = sliderPhotos.map((photo) => {
                  return photo.filePath.originalUrl;
                });
                setSliderState({
                  albumIdentifier: sliderAlbumId,
                  photos,
                  apiStatus: Status.SUCCESS,
                });
              })
              .catch(() => {
                setSliderState({ apiStatus: Status.ERROR });
              });
          }
        })
        .catch(() => {
          setSliderState({ apiStatus: Status.ERROR });
        });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const save = () => {
    setSaveApiStatus(Status.LOADING);
    if (saveApiStatus !== Status.LOADING) {
      if (businessDetails.isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: businessIdentifier,
            entityType: BUSINESS_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse }) => {
            businessDetails.favouriteIdentifier =
              userResponse.favouriteIdentifier;
            businessDetails.isSaved = UNSAVE_SK;
            setSaveApiStatus(Status.SUCCESS);
            setSnackMsg(translate('homeScreen.saveFavouriteSuccessMessage'));
            setSanckOpen(true);
          })
          .catch(() => {
            setSaveApiStatus(Status.ERROR);
          });
      } else {
        api
          .deleteFavourite({
            favouriteIdentifier: businessDetails.favouriteIdentifier,
            entityType: BUSINESS_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse }) => {
            businessDetails.favouriteIdentifier = '';
            businessDetails.isSaved = SAVE_SK;
            setSaveApiStatus(Status.SUCCESS);
            setSnackMsg(translate('homeScreen.unsaveFavouriteSuccessMessage'));
            setSanckOpen(true);
          })
          .catch(() => {
            setSaveApiStatus(Status.ERROR);
          });
      }
    }
  };

  const updateRelationship = async (status) => {
    setFollowApiStatus(Status.LOADING);
    api
      .updateMemberNetwork({
        status,
        userIdentifier: businessIdentifier,
        entityType: BUSINESS_SK,
      })
      .then(({ userResponse: { status: responseStatus } }) => {
        businessDetails.totalFollowers =
          status === FOLLOWING_SK
            ? businessDetails.totalFollowers + 1
            : businessDetails.totalFollowers - 1;
        businessDetails.networkStatusWithCurrentUser.followStatus = status;
        setBusinessDetails({
          ...businessDetails,
        });
        _updateUserFollowStatus(status, businessIdentifier);
        setFollowApiStatus(Status.SUCCESS);
      })
      .catch(() => {
        setFollowApiStatus(Status.ERROR);
      });
  };

  let clickable = true; // This is to avoid double click
  const toggleFollowStatus = () => {
    if (clickable) {
      clickable = false;
      updateRelationship(
        followStatus === FOLLOWING_SK ? UNFOLLOWED_SK : FOLLOWING_SK
      );
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackMsg('');
    setSanckOpen(false);
  };

  const handleSharePost = () => {
    setSnackMsg(translate('homeScreen.postShareSuccessMessage'));
    setSanckOpen(true);
  };

  const updateReviewList = (isHasNew) => {
    setIsReviewUpdated(isHasNew ? true : false);
  };

  const handleSliderClicked = () => {
    setSelectedTab(2);
    setIsSliderClicked(true);
  };

  const closeMediaViewer = () => {
    setShowMenu(false);
  };

  const updateAvgRating = (avgRating) => {
    setBusinessDetails((prevState) => {
      return {
        ...prevState,
        avgRating: avgRating
      }
    });
  };

  if (!isNonEmptyString(localStorage.getItem(ASYNC_STORAGE_KEYS.sessionId))) {
    return <Redirect to="/" />;
  }

  if (apiStatus === Status.LOADING) {
    return <Spinner />;
  }

  if (apiStatus === Status.ERROR) {
    return <Redirect to="/404" />
  }

  let switchLabel;
  if (_type === HOTEL_SK) {
    switchLabel = translate('businessProfile.room');
  } else if (_type === RESTAURANT_SK) {
    switchLabel = translate('businessProfile.menu');
  }
  return (
    <div>
      <CssBaseline />
      <AppBar />
      <div id="back-to-top-anchor" />
      <div className={styles['business-profile-container']}>
        <BackIcon />
        <div className={styles.banner}>
          <div className={styles.shading} />
          <img alt="img" src={businessDetails.coverImg?.url?.originalUrl} />
        </div>
        <div className={styles.content}>
          <Grid container>
            <Grid item xs={1} md={1} />
            <Grid item xs={10} md={10}>
              <div className={styles.profile}>
                <Grid
                  container
                  direction={{
                    xs: 'column',
                    md: 'row',
                  }}
                >
                  <Grid item md={12}>
                    <div
                      className={
                        _type === HOTEL_SK
                          ? styles.left
                          : cn(styles.left, styles['res-left'])
                      }
                    >
                      <div className={styles['profile-img']}>
                        {businessDetails.profileImg && (
                          <Badge
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            badgeContent={
                              <div className={styles["smoove-ic-container"]}>
                                <SmooveIcon name={`${_type === HOTEL_SK ? SmooveIcons.IcHotel : SmooveIcons.IcResturant} profile-badge`} />
                              </div>
                            }
                          >
                            <Avatar
                              id={styles['profile-avatar']}
                              alt="avatar"
                              variant="square"
                              src={businessDetails.profileImg?.url?.originalUrl}
                            />
                          </Badge>
                        )}
                      </div>
                      <div className={styles.info}>
                        <div id={styles.name}>{businessDetails.name}</div>
                        <div id={styles.city}>{`${businessDetails.city
                          }${translate('common.comma')} ${businessDetails.state
                          }`}</div>
                        <div>
                          <RatingStar
                            rating={businessDetails.avgRating}
                            size="small"
                          />
                        </div>
                        {/* <div id='follower'>
                          {`${businessDetails.totalFollowers}
                                ${businessDetails.totalFollowers > 1
                              ? translate('businessProfile.followers')
                              : translate('businessProfile.follower')}`
                          }
                        </div> */}
                        {businessDetails.profileImg &&
                          _type === RESTAURANT_SK && (
                            <div className={styles['restaurant-status']}>
                              <div className={styles.wrap}>
                                <div className={styles.label}>
                                  {opened || secondOpened
                                    ? translate('businessProfile.openStatus')
                                    : translate('businessProfile.closeStatus')}
                                </div>
                                <img
                                  alt="img"
                                  src={
                                    opened || secondOpened
                                      ? OPEN_ICON
                                      : CLOSE_ICON
                                  }
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item >
                    <div className={styles.right}>
                      {_type === RESTAURANT_SK &&
                        businessDetails.foodMenu?.length !== 0 && (
                          <div className={styles.export}>
                            <IconButton
                              className={styles['save-icon-button']}
                              onClick={() => setShowMenu(true)}
                            >
                              <MenuBookIcon
                                className={styles['mui-save-icon-style']}
                              />
                            </IconButton>
                            {/* <img
                              alt="export-img"
                              src={EXPORT_ICON}
                              onClick={() => setShowMenu(true)}
                            /> */}
                          </div>
                        )}
                      <div className={styles.save}>
                        <IconButton
                          className={styles['save-icon-button']}
                          onClick={save}
                        >
                          {businessDetails.isSaved === SAVE_SK ? (
                            <BookmarkBorderIcon
                              className={styles['mui-save-icon-style']}
                            />
                          ) : (
                            <BookmarkIcon
                              className={styles['mui-save-icon-style-filled']}
                            />
                          )}
                        </IconButton>
                      </div>
                      {/* <div className='follow'>
                        <div className='follow-button follow-button-lg'
                          onClick={toggleFollowStatus}
                          onMouseEnter={() => setIsFollowing(false)}
                          onMouseLeave={() => setIsFollowing(true)}
                        >
                          {followApiStatus === Status.LOADING
                            ? <Spinner size={20} />
                            : <span>
                              {followStatus === FOLLOWING_SK
                                ? <span>
                                  {isFollowing
                                    ? <span className='following-label'>
                                      <img alt='img' src={followingIcon} />
                                      {translate('common.following')}
                                    </span>
                                    : translate('common.unfollow')
                                  }
                                </span>
                                : translate('common.follow')}
                            </span>}
                        </div>
                      </div> */}
                      <div className={styles.follow}>
                        <div className={cn(styles['follow-button'], followStatus === FOLLOWING_SK && styles['following-button'])}
                          onClick={toggleFollowStatus}
                          onMouseEnter={() => setIsFollowing(false)}
                          onMouseLeave={() => setIsFollowing(true)}
                        >
                          {followApiStatus === Status.LOADING
                            ? <CircularProgress id={cn(followStatus !== FOLLOWING_SK && styles["follow-spinner"], followStatus === FOLLOWING_SK && styles['unfollow-spinner'])} size={20} />
                            : <span>
                              {followStatus === FOLLOWING_SK
                                ? isFollowing ? translate('common.following') :
                                  translate('common.unfollow')
                                : translate('common.follow')}
                            </span>}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={styles['main-content']}>
                <TabContext value={selectedTab.toString()}>
                  <Tabs
                    id={styles.tabs}
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    variant={variant}
                    scrollButtons={true}
                    TabIndicatorProps={{ style: { height: 3 } }}
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                        display: "flex"
                      },
                    }}
                  >
                    <Tab label={translate('businessProfile.post')} value={0} />
                    <Tab label={translate('businessProfile.about')} value={1} />
                    <Tab
                      label={translate('businessProfile.gallery')}
                      value={2}
                    />

                    <Tab label={switchLabel} value={3} />
                    {businessDetails?.reviewShow === ON_SK && <Tab label={translate('businessProfile.reviews')} value={"4"} />}
                  </Tabs>
                  <TabPanel value={selectedTab} index={0}>
                    <MyTab
                      businessDetails={businessDetails}
                      sliderState={sliderState}
                      subType={_type}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      google={google}
                      handleSliderClicked={handleSliderClicked}
                      isLoaded={isLoaded}
                    >
                      <PostTab
                        identifier={businessIdentifier}
                        profileType={BUSINESS_PROFILE_LK}
                        subType={_type}
                        loggedInUserIdentifier={loggedInUserIdentifier}
                        baseMediaUrl={baseMediaUrl}
                        baseMediaThumbUrl={baseMediaThumbUrl}
                        handleSharePost={handleSharePost}
                        reload={reload}
                        detailApiStatus={apiStatus}
                      />
                    </MyTab>
                  </TabPanel>
                  <TabPanel value={selectedTab} index={1}>
                    <MyTab
                      businessDetails={businessDetails}
                      subType={_type}
                      sliderState={sliderState}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      google={google}
                      handleSliderClicked={handleSliderClicked}
                      isLoaded={isLoaded}
                    >
                      <AboutTab
                        identifier={businessIdentifier}
                        subType={_type}
                        businessDetails={businessDetails}
                        loggedInUserIdentifier={loggedInUserIdentifier}
                        baseMediaUrl={baseMediaUrl}
                        baseMediaThumbUrl={baseMediaThumbUrl}
                        apiStatus={apiStatus}
                      />
                    </MyTab>
                  </TabPanel>
                  <TabPanel value={selectedTab} index={2}>
                    <MyTab
                      businessDetails={businessDetails}
                      subType={_type}
                      sliderState={sliderState}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      google={google}
                      handleSliderClicked={handleSliderClicked}
                      isLoaded={isLoaded}
                    >
                      <GalleryTab
                        identifier={businessIdentifier}
                        entityType={BUSINESS_SK}
                        subType={_type}
                        profileType={BUSINESS_PROFILE_LK}
                        loggedInUserIdentifier={loggedInUserIdentifier}
                        profileImgUrl={
                          businessDetails.profileImg?.isThumbGenerated
                            ? businessDetails.profileImg?.url?.thumbUrl
                            : businessDetails.profileImg?.url?.originalUrl
                        }
                        businessName={businessDetails.name}
                        baseMediaUrl={baseMediaUrl}
                        baseMediaThumbUrl={baseMediaThumbUrl}
                        isSliderClicked={isSliderClicked}
                        setIsSliderClicked={setIsSliderClicked}
                        sliderAlbumIdentifier={sliderState.albumIdentifier}
                      />
                    </MyTab>
                  </TabPanel>
                  <TabPanel value={selectedTab} index={3}>
                    <MyTab
                      businessDetails={businessDetails}
                      sliderState={sliderState}
                      baseMediaUrl={baseMediaUrl}
                      subType={_type}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      google={google}
                      handleSliderClicked={handleSliderClicked}
                      isLoaded={isLoaded}
                    >
                      <RoomAndMenuTab
                        identifier={businessIdentifier}
                        entityType={BUSINESS_SK}
                        subType={_type}
                        profileType={BUSINESS_PROFILE_LK}
                        loggedInUserIdentifier={loggedInUserIdentifier}
                        profileImgUrl={
                          businessDetails.profileImg?.isThumbGenerated
                            ? businessDetails.profileImg?.url?.thumbUrl
                            : businessDetails.profileImg?.url?.originalUrl
                        }
                        businessName={businessDetails.name}
                        baseMediaUrl={baseMediaUrl}
                        baseMediaThumbUrl={baseMediaThumbUrl}
                        isSliderClicked={isSliderClicked}
                        setIsSliderClicked={setIsSliderClicked}
                        sliderAlbumIdentifier={sliderState.albumIdentifier}
                      />
                    </MyTab>
                  </TabPanel>
                  {businessDetails?.reviewShow === ON_SK && <TabPanel value={parseInt(selectedTab)} index={4}>
                    <MyTab
                      businessDetails={businessDetails}
                      sliderState={sliderState}
                      baseMediaUrl={baseMediaUrl}
                      baseMediaThumbUrl={baseMediaThumbUrl}
                      google={google}
                      handleSliderClicked={handleSliderClicked}
                    >
                      <ReviewTab
                        identifier={businessIdentifier}
                        type={BUSINESS_SK}
                        loggedInUserIdentifier={loggedInUserIdentifier}
                        baseMediaUrl={baseMediaUrl}
                        baseMediaThumbUrl={baseMediaThumbUrl}
                        isReviewUpdated={isReviewUpdated}
                      />
                    </MyTab>
                  </TabPanel>}
                </TabContext>
              </div>
            </Grid>
            <Grid item xs={1} md={1} />
          </Grid>
        </div>
      </div>
      <CreateReviewIcon
        identifier={businessIdentifier}
        type={BUSINESS_SK}
        updateReviewList={updateReviewList}
        title={businessDetails.name}
        updateAvgRating={updateAvgRating}
      />
      {/* create post */}
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
      <SnackBar
        id={styles['my-snack']}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={snackOpen}
        onClose={handleSnackClose}
      >
        <Alert
          severity="success"
          className={styles['alert-success']}
          onClose={handleSnackClose}
        >
          {snackMsg}
        </Alert>
      </SnackBar>
      {_type === RESTAURANT_SK && apiStatus === Status.SUCCESS && (
        <MediaViewer
          open={showMenu}
          type={IMAGE_SK}
          entityType={_type}
          mediaList={businessDetails?.foodMenu}
          selected={0}
          profileImgUrl={
            businessDetails.profileImg?.isThumbGenerated
              ? businessDetails.profileImg?.url?.thumbUrl
              : businessDetails.profileImg?.url?.originalUrl
          }
          userName={businessDetails.name}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          close={closeMediaViewer}
          restaurantDetial
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  img: {
    height: '200px',
    border: '1px solid #CED2D9',
    borderRadius: '6px',
    objectFit: 'cover',
  },
}));

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(mapStateToProps, { updateUserFollowStatus })(withRouter(BusinessProfile));
