import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getCurrentLocale, translate } from "../../../../../../i18n";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import DirectionsBikeOutlinedIcon from "@mui/icons-material/DirectionsBikeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import NightShelterOutlinedIcon from "@mui/icons-material/NightShelterOutlined";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchSources } from "../../../../../../store/actions";
import { fetchDestinations } from "../../../../../../store/actions";
import { fetchRoutes } from "../../../../../../store/actions";
import { fetchServices } from "../../../../../../store/actions";
import { fetchCalculations } from "../../../../../../store/actions";
import { resetData } from "../../../../../../store/actions";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Status from "../../../../../../api/Status";
import html2canvas from "html2canvas";
import { Tooltip } from "@mui/material";
import { ENGLISH_LANGUAGE_CODE_LK } from "../../../../../../constants";
import styles from "./TravelMoneyCalculator.module.scss";
import { CircularProgress } from "@material-ui/core";
import { SmooveIcon, SmooveIcons } from "../../../../../../common";

const TravelMoneyCalculator = ({
  sources,
  destinations,
  routes,
  accommodationLists,
  activitiesLists,
  mealPlansLists,
  transportationsLists,
  calculations,
  calculationsStatus,
  fetchSources: _fetchSources,
  fetchDestinations: _fetchDestinations,
  fetchRoutes: _fetchRoutes,
  fetchServices: _fetchServices,
  fetchCalculations: _fetchCalculations,
  resetData: _resetData,
}) => {
  const [source, setSource] = React.useState("");
  const [selectedSource, setSelectedSource] = React.useState({});

  const [destination, setDestination] = React.useState("");
  const [selectedDestination, setSelectedDestination] = React.useState({});

  const [route, setRoute] = React.useState("");
  const [selectedRoute, setSelectedRoutes] = React.useState({});

  const [transporation, setTransporation] = React.useState({});
  const [selectedTransportId, setSelectedTransportId] = React.useState("");

  const [selectedAccommodationDetail, setSelectedAccommodationDetail] =
    React.useState({});
  const [selectedMealPlans, setSelectedMealPlans] = React.useState({});
  const [selectedActivities, setSelectedActivities] = React.useState([]);
  const [numberOfPerson, setNumberOfPerson] = React.useState("");
  const [numberOfDay, setNumberOfDay] = React.useState("");
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [resetHandler, setResetHandler] = useState(false);
  const language = getCurrentLocale();
  const steps = getSteps();

  useEffect(() => {
    if (open) {
      _fetchSources();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRoute("");
    setSelectedRoutes({});
    setDestination("");
    setSelectedDestination({});
    setTransporation({});
    setSelectedTransportId("");

    setSelectedAccommodationDetail({});

    setSelectedActivities([]);

    setSelectedMealPlans("");

    setNumberOfPerson("");
    setNumberOfDay("");

    _resetData();

    setResetHandler(false);
    setActiveStep(0);
  }, [selectedSource]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calculationsStatus === Status.SUCCESS) {
      const targetDiv = document.getElementById("calculator-content-left");
      if (targetDiv.style.display !== "none") {
        targetDiv.style.display = "none";
      }

      const targetBtnLeftReset = document.getElementById("btn-left-reset");

      if (targetBtnLeftReset.style.display !== "none") {
        targetBtnLeftReset.style.display = "none";
      }

      const targetMessage = document.getElementById("message");

      if (targetMessage.style.display === "none") {
        targetMessage.style.display = "block";
      }

      const targetPlanBtnRightReset = document.getElementById(
        "plan-btn-right-reset"
      );
      if (targetPlanBtnRightReset.style.display === "none") {
        targetPlanBtnRightReset.style.display = "block";
      }

      const targetPlanTitle = document.getElementById("plan-title");
      if (targetPlanTitle.style.display !== "none") {
        targetPlanTitle.style.display = "none";
      }
      const targetTitle = document.getElementById("title");
      if (targetTitle.style.display === "none") {
        targetTitle.style.display = "block";
      }

      const targetFromTo = document.getElementById("fromTo");

      if (targetFromTo.style.display !== "none") {
        targetFromTo.style.display = "none";
      }

      const targetAlignCenter = document?.getElementById("align-center");
      targetAlignCenter.style.textAlign = "center";

      const planBoxItems = document.getElementById("content-right");
      planBoxItems.style.backgroundColor = "#F2F2EE";

      document.getElementById("plan-box-items-labels").style.alignItems =
        "center";
    }
  }, [calculationsStatus]);

  const saveImage = () => {
    html2canvas(document.getElementById("html2-canvas")).then(
      (canvas) => {
        var a = document.createElement("a");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        a.download = "shalgo-trip-budget.jpg";
        a.click();
      }
    );
  };

  const reset = () => {
    setSource("");
    setSelectedSource({});

    setDestination("");
    setSelectedDestination({});

    setRoute("");
    setSelectedRoutes({});

    setTransporation({});
    setSelectedTransportId("");

    setSelectedAccommodationDetail({});

    setSelectedActivities([]);

    setSelectedMealPlans("");

    setNumberOfPerson("");
    setNumberOfDay("");

    _resetData();

    setResetHandler(false);
    setActiveStep(0);

    const targetDiv = document.getElementById("calculator-content-left");
    if (targetDiv.style.display === "none") {
      targetDiv.style.display = "block";
    }

    const targetBtnLeftReset = document.getElementById("btn-left-reset");

    if (targetBtnLeftReset.style.display === "none") {
      targetBtnLeftReset.style.display = "block";
    }

    const targetMessage = document.getElementById("message");
    if (targetMessage.style.display !== "none") {
      targetMessage.style.display = "none";
    }

    const targetPlanBtnRightReset = document.getElementById(
      "plan-btn-right-reset"
    );
    if (targetPlanBtnRightReset.style.display !== "none") {
      targetPlanBtnRightReset.style.display = "none";
    }

    const targetPlanTitle = document.getElementById("plan-title");
    if (targetPlanTitle.style.display === "none") {
      targetPlanTitle.style.display = "block";
    }

    const targetTitle = document.getElementById("title");
    if (targetTitle.style.display !== "none") {
      targetTitle.style.display = "none";
    }

    const planBoxItems = document.getElementById("content-right");
    planBoxItems.style.backgroundColor = "#FFFFFF";
  };

  useEffect(() => {
    if (resetHandler) {
      reset();
    }
  }, [resetHandler]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    selectedSource?.id &&
      selectedDestination?.id &&
      _fetchRoutes(
        selectedSource?.id.toString(),
        selectedDestination?.id.toString()
      );
    // setDisableBtn(true);
    setRoute("");
    setSelectedRoutes({});

    setTransporation({});
    setSelectedTransportId("");

    setSelectedAccommodationDetail({});

    setSelectedActivities([]);

    setSelectedMealPlans("");

    setNumberOfPerson("");
    setNumberOfDay("");

    setResetHandler(false);
    setActiveStep(0);
  }, [selectedDestination, selectedSource]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTransporation({});
    setSelectedTransportId("");

    setSelectedAccommodationDetail({});

    setSelectedActivities([]);

    setSelectedMealPlans("");
  }, [selectedRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (step === 0) {
      setActiveStep(0);
    }
    if (activeStep === 2) {
      if (step === 0) {
        setActiveStep(0);
      } else if (step === 1) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    }
  };

  const checkedBtn = () => {
    if (_.isEmpty(selectedRoute) && activeStep === 0) {
      return true;
    }

    if (
      activeStep === 1 &&
      (selectedTransportId?.length === 0 ||
        selectedMealPlans?.length === 0 ||
        selectedAccommodationDetail?.length === 0)
    ) {
      return true;
    }

    if (
      activeStep === 2 &&
      (numberOfDay?.length === 0 || numberOfPerson?.length === 0)
    ) {
      return true;
    }
  };

  const calculate = () => {
    let activityIds = [];
    // eslint-disable-next-line array-callback-return
    selectedActivities.map((item) => {
      activityIds.push(item?.id);
    });
    _fetchCalculations(
      selectedRoute.uuid,
      transporation?.id.toString(),
      selectedAccommodationDetail?.id.toString(),
      selectedMealPlans?.id.toString(),
      activityIds,
      numberOfPerson,
      numberOfDay
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const Journey = () => {
    useEffect(() => {
      if (resetHandler) {
        reset();
      }
    }, [resetHandler]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSource = (event) => {
      setSource(event.target.value);
      const sourceResult = sources.filter((e) => {
        return e.id === event.target.value;
      });
      setSelectedSource(sourceResult[0]);
      _fetchDestinations(sourceResult[0].name);
    };

    const handleDestination = (event) => {
      setDestination(event.target.value);
      const destinationsResult = destinations.filter((e) => {
        return e.id === event.target.value;
      });

      setSelectedDestination(destinationsResult[0]);
    };

    const handleRoute = (event) => {
      setRoute(event.target.value);

      const routesResult = routes.filter((e) => {
        return e.id === event.target.value;
      });

      setSelectedRoutes(routesResult[0]);

      _fetchServices(routesResult[0].uuid);
    };

    return (
      <div className={styles["box-container"]}>
        <Grid
          container
          spacing={6}
          direction="row"
          className={styles["stepper-row"]}
        >
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{`${translate("shalgoerServices.source")} (${translate(
                "common.from"
              )})`}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div>
              <FormControl className={styles["form-control-lg"]}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select1"
                  value={source}
                  onChange={handleSource}
                >
                  {sources.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {language === ENGLISH_LANGUAGE_CODE_LK
                          ? item.name
                          : item.nameMy}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
          direction="row"
          className={styles["stepper-row"]}
        >
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{`${translate("shalgoerServices.destination")} (${translate(
                "common.to"
              )})`}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl className={styles["form-control-lg"]}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select2"
                value={destination}
                onChange={handleDestination}
                disabled={destinations.length === 0}
              >
                {destinations.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {language === ENGLISH_LANGUAGE_CODE_LK
                        ? item.name
                        : item.nameMy}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
          direction="row"
          className={styles["stepper-row"]}
        >
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{translate("shalgoerServices.route")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl className={styles["form-control-lg"]}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={route}
                onChange={handleRoute}
                disabled={routes.length === 0}
              >
                {routes.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {language === ENGLISH_LANGUAGE_CODE_LK
                        ? item.name
                        : item.nameMy}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Services = () => {
    const handleAccommoChange = (event) => {
      const accommoResult = accommodationLists.filter((e) => {
        return e.id.toString() === event.target.value;
      });

      setSelectedAccommodationDetail(accommoResult[0]);
    };

    const handleMealPlans = (event) => {
      const mealPlansResult = mealPlansLists.filter((e) => {
        return e.id.toString() === event.target.value;
      });
      setSelectedMealPlans(mealPlansResult[0]);
    };

    const handleTransportationChange = (event) => {
      setSelectedTransportId(event.target.value);
      var transportationResult = transportationsLists.filter(function (e) {
        return e.id === event.target.value;
      });
      setTransporation(transportationResult[0]);
    };

    const handleActivitiesChange = (e, item) => {
      if (isChecked(e.target.value)) {
        setSelectedActivities((prevState) => {
          return prevState.filter((x) => x.id !== item.id);
        });
      } else {
        setSelectedActivities((prevState) => {
          return [...prevState, item];
        });
      }
    };

    const isChecked = (id) => {
      const index = _.findIndex(selectedActivities, (item) => {
        return item.id.toString() === id.toString();
      });
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    };

    const StyledRadio = withStyles({
      root: styles["styled-radio"],
      checked: styles["styled-radio-checked"],
    })((props) => <Radio color="default" {...props} />);

    return (
      <div className={styles["box-container"]}>
        {/* Transportation */}
        <Grid container spacing={6} direction="row">
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{translate("shalgoerServices.transportation")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl className={styles["form-control-lg"]}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTransportId}
                onChange={handleTransportationChange}
                disabled={transportationsLists.length === 0}
              >
                {transportationsLists &&
                  transportationsLists.map((item, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={item.id}
                        id={styles["activities-name"]}
                      >
                        {language === ENGLISH_LANGUAGE_CODE_LK
                          ? item.name
                          : item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Accommodation */}

        <Grid container spacing={4} direction="row">
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{translate("shalgoerServices.accommodation")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedAccommodationDetail.id}
                id={styles["radio-group"]}
                onChange={handleAccommoChange}
                disabled={accommodationLists.length === 0}
              >
                {accommodationLists &&
                  accommodationLists.map((item, key) => {
                    return (
                      <FormControlLabel
                        key={key}
                        value={item.id}
                        control={<StyledRadio />}
                        label={
                          language === ENGLISH_LANGUAGE_CODE_LK
                            ? item.name
                            : item.name
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* Meal Plans */}

        <Grid container spacing={4} direction="row">
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{translate("shalgoerServices.mealPlans")}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedMealPlans?.id}
                id={styles["radio-group"]}
                onChange={handleMealPlans}
              >
                {mealPlansLists &&
                  mealPlansLists.map((item, key) => {
                    return (
                      <FormControlLabel
                        key={key}
                        value={item.id}
                        control={<StyledRadio />}
                        label={
                          language === ENGLISH_LANGUAGE_CODE_LK
                            ? item.name
                            : item.name
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* Activities */}

        {activitiesLists[0] ? (
          <Grid container spacing={6} direction="row">
            <Grid item xs={12} md={5}>
              <div className={styles["stepper-label"]}>
                <span>{translate("shalgoerServices.activities")}</span>
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container derection="row" spacing={1}>
                {activitiesLists &&
                  activitiesLists.map((item, key) => {
                    return (
                      <div className={styles["activities-icon"]} key={key}>
                        <FormControlLabel
                          id={styles["activities-checkbox"]}
                          value={item.id}
                          checked={isChecked(item.id)}
                          control={
                            <Checkbox
                              icon={
                                <CircleUnchecked
                                  id={styles["unchecked-icon"]}
                                />
                              }
                              checkedIcon={
                                <CircleCheckedFilled
                                  id={styles["checked-icon"]}
                                />
                              }
                            />
                          }
                          onChange={(e) => handleActivitiesChange(e, item)}
                        />
                        <div className={styles["bottom-box"]}>
                          <div className={styles["activities-img-container"]}>
                            <img
                              src={item.filePath}
                              className={styles["activities-img"]}
                              alt="activities"
                            />
                          </div>
                          <div className={styles["activities-name"]}>
                            {language === ENGLISH_LANGUAGE_CODE_LK
                              ? item.name
                              : item.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </div>
    );
  };

  const Details = () => {
    const handleNumberOfPerson = (event) => {
      setNumberOfPerson(event.target.value);
    };

    const handleNumberOfDay = (event) => {
      setNumberOfDay(event.target.value);
    };

    return (
      <div className={styles["box-container"]}>
        <Grid
          container
          spacing={6}
          direction="row"
          className={styles["stepper-row"]}
        >
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{`${translate("shalgoerServices.numberOfPerson")}`}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl className={styles["form-control-lg"]} required>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={numberOfPerson}
                onChange={handleNumberOfPerson}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={6}
          direction="row"
          className={styles["stepper-row"]}
        >
          <Grid item xs={12} md={5}>
            <div className={styles["stepper-label"]}>
              <span>{`${translate("shalgoerServices.numberOfDay")}`}</span>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl className={styles["form-control-lg"]} required>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={numberOfDay}
                onChange={handleNumberOfDay}
              >
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  };

  const YourPlan = () => {
    var numberFormat = new Intl.NumberFormat();
    const transportationCost = numberFormat.format(
      language === ENGLISH_LANGUAGE_CODE_LK
        ? transporation?.cost
        : transporation?.cost
    );
    const accommodationCost = numberFormat.format(
      language === ENGLISH_LANGUAGE_CODE_LK
        ? selectedAccommodationDetail?.cost
        : selectedAccommodationDetail?.cost
    );
    const mealPlansCost = numberFormat.format(
      language === ENGLISH_LANGUAGE_CODE_LK
        ? selectedMealPlans?.cost
        : selectedMealPlans?.cost
    );

    return (
      <div className="box-container" id="box-container">
        <div
          className={styles["title"]}
          id="title"
          style={{ display: "none" }}
        >
          {translate("shalgoerServices.travelMoneyCalculatorTitle")}
        </div>
        {/* Source */}
        <div className={styles["from-to"]} id="fromTo">
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            alignItems="center"
            spacing={1}
          >
            <div>
              {source && (
                <div className={styles["source"]}>
                  <div>
                    <HomeOutlinedIcon id={styles["plan-icon"]} />
                  </div>
                  <div className={styles["plan-label"]}>
                    {` ${translate("common.from")}: ${selectedSource.name} (${selectedSource.nameMy
                      })`}
                  </div>
                </div>
              )}
            </div>
            <div>
              {destination && (
                <span className={styles["source"]}>
                  {` ${translate("common.to")}: `}
                  {` ${selectedDestination.name}`} (
                  {` ${selectedDestination.nameMy}`})
                </span>
              )}
            </div>
          </Stack>
        </div>
        {/* Route */}
        <div>
          {selectedRoute.id && (
            <Stack direction="column" spacing={1}>
              <div className={styles["align-center"]} id="align-center">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {selectedRoute.name}
                <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ({selectedRoute.nameMy}){" "}
              </div>
            </Stack>
          )}
        </div>

        {/* Transporation */}
        <div id={styles["plan-box-items"]}>
          <div
            className={styles["plan-box-items-labels"]}
            id="plan-box-items-labels"
          >
            {transporation.id && (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <div id={styles["tm-boxs-left"]}>
                  <div className={styles["plan-label-align-right"]}>
                    <div className={styles["plan-icon-label"]}>
                      <div>
                        <DirectionsCarFilledOutlinedIcon
                          id={styles["plan-icon"]}
                        />
                      </div>
                      <div className={styles["plan-label"]}>
                        {translate("shalgoerServices.transportation")}
                      </div>
                    </div>
                  </div>
                </div>
                <div id={styles["tm-boxs-right"]}>
                  <div className={styles["plan-label-align-right"]}>
                    {language === ENGLISH_LANGUAGE_CODE_LK
                      ? transporation.name
                      : transporation.name}
                  </div>
                  <div className={styles["plans-label-align-left"]}>
                    {transportationCost} {translate("shalgoerServices.ks")}
                  </div>
                </div>
              </Stack>
            )}

            {/* Accommodation */}
            {selectedAccommodationDetail?.id && (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <div id={styles["tm-boxs-left"]}>
                  <div className={styles["plan-label-align-right"]}>
                    <div className={styles["plan-icon-label"]}>
                      <div>
                        <NightShelterOutlinedIcon id={styles["plan-icon"]} />
                      </div>
                      <div className={styles["plan-label"]}>
                        {translate("shalgoerServices.accommodation")}
                      </div>
                    </div>
                  </div>
                </div>
                <div id={styles["tm-boxs-right"]}>
                  <div className={styles["plan-label-align-right"]}>
                    {language === ENGLISH_LANGUAGE_CODE_LK
                      ? selectedAccommodationDetail.name
                      : selectedAccommodationDetail?.name}
                  </div>
                  <div className={styles["plans-label-align-left"]}>
                    {accommodationCost} {translate("shalgoerServices.ks")}
                  </div>
                </div>
              </Stack>
            )}

            {/* Meal Plans */}
            {selectedMealPlans?.id && (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <div id={styles["tm-boxs-left"]}>
                  <div className={styles["plan-label-align-right"]}>
                    <div className={styles["plan-icon-label"]}>
                      <div>
                        <RoomServiceOutlinedIcon id={styles["plan-icon"]} />
                      </div>
                      <div className={styles["plan-label"]}>
                        {translate("shalgoerServices.mealPlans")}
                      </div>
                    </div>
                  </div>
                </div>
                <div id={styles["tm-boxs-right"]}>
                  <div className={styles["plan-label-align-right"]}>
                    {language === ENGLISH_LANGUAGE_CODE_LK
                      ? selectedMealPlans.name
                      : selectedMealPlans.name}
                  </div>
                  <div className={styles["plans-label-align-left"]}>
                    {mealPlansCost} {translate("shalgoerServices.ks")}
                  </div>
                </div>
              </Stack>
            )}

            {/* Activities */}
            {selectedActivities
              ? selectedActivities.map((item, key) => {
                return (
                  <Stack
                    direction={{ xs: "column", sm: "row", md: "row" }}
                    spacing={{ xs: 1, sm: 3, md: 4 }}
                    key={key}
                  >
                    <div id={styles["tm-boxs-left"]}>
                      <div className={styles["plan-label-align-right"]}>
                        {key === 0 && (
                          <div className={styles["plan-icon-label"]}>
                            <div>
                              <DirectionsBikeOutlinedIcon
                                id={styles["plan-icon"]}
                              />
                            </div>
                            <div className={styles["plan-label"]}>
                              {translate("shalgoerServices.activities")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id={styles["tm-boxs-right"]}>
                      <div className={styles["plan-label-align-right"]}>
                        {language === ENGLISH_LANGUAGE_CODE_LK
                          ? item.name
                          : item.name}
                      </div>
                      <div className={styles["plans-label-align-left"]}>
                        {language === ENGLISH_LANGUAGE_CODE_LK
                          ? numberFormat.format(item?.cost)
                          : numberFormat.format(item?.cost)}{" "}
                        {translate("shalgoerServices.ks")}
                      </div>
                    </div>
                  </Stack>
                );
              })
              : []}

            {/* number of person */}
            {numberOfPerson && (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <div id={styles["tm-boxs-left"]}>
                  <div className={styles["plan-label-align-right"]}>
                    <div className={styles["plan-icon-label"]}>
                      <div>
                        <GroupsOutlinedIcon id={styles["plan-icon"]} />
                      </div>
                      <div className={styles["plan-label"]}>
                        {`${translate("shalgoerServices.numberOfPerson")}`}
                      </div>
                    </div>
                  </div>
                </div>
                {numberOfPerson > 1 ? (
                  <div id={styles["tm-boxs-right"]}>
                    <div className={styles["plan-label-align-right"]}>
                      {numberOfPerson} {translate("shalgoerServices.persons")}
                    </div>
                    <div className={styles["plan-label-align-right"]}></div>
                  </div>
                ) : (
                  <div id={styles["tm-boxs-right"]}>
                    <div className={styles["plan-label-align-right"]}>
                      {numberOfPerson} {translate("shalgoerServices.person")}
                    </div>
                    <div className={styles["plan-label-align-right"]}></div>
                  </div>
                )}
              </Stack>
            )}

            {/* numper of day */}
            {numberOfDay && (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <div id={styles["tm-boxs-left"]}>
                  <div className={styles["plan-label-align-right"]}>
                    <div className={styles["plan-icon-label"]}>
                      <div>
                        <DateRangeOutlinedIcon id={styles["plan-icon"]} />
                      </div>
                      <div className={styles["plan-label"]}>{`${translate(
                        "shalgoerServices.numberOfDay"
                      )}`}</div>
                    </div>
                  </div>
                </div>
                {numberOfDay > 1 ? (
                  <div id={styles["tm-boxs-right"]}>
                    <div className={styles["plan-label-align-right"]}>
                      {numberOfDay} {translate("shalgoerServices.days")}{" "}
                    </div>
                    <div className={styles["plan-label-align-right"]}></div>
                  </div>
                ) : (
                  <div id={styles["tm-boxs-right"]}>
                    <div className={styles["plan-label-align-right"]}>
                      {numberOfDay} {translate("shalgoerServices.day")}{" "}
                    </div>
                    <div className={styles["plan-label-align-right"]}></div>
                  </div>
                )}
              </Stack>
            )}
          </div>
        </div>

        {calculations && (
          <div className={styles["total-cost-box"]}>
            <span>
              <span className={styles["total-cost-text"]}>
                {translate("shalgoerServices.tripBudget")}{" "}
              </span>
              <span className={styles["total-cost-number"]}>
                {numberFormat.format(calculations.totalCost)}
              </span>
              <span className={styles["total-cost-text"]}>
                {translate("shalgoerServices.ks")}
              </span>
            </span>
          </div>
        )}
      </div>
    );
  };

  function getSteps() {
    let step1 = translate("shalgoerServices.journey");
    let step2 = translate("shalgoerServices.services");
    let step3 = translate("shalgoerServices.details");

    return [step1, step2, step3];
  }

  function getStepContent(step, props) {
    switch (step) {
      case 0:
        return <Journey props={props} />;
      case 1:
        return <Services props={props} />;
      case 2:
        return <Details props={props} />;
      default:
        return <></>;
    }
  }

  return (
    <div id={styles["tm-calculator"]}>
      <Tooltip title="TMCalculator" placement="left">
        <div role="button" className="ic-service-container" onClick={handleOpen}>
          <SmooveIcon name={`${SmooveIcons.IcTravelMoneyCalculator} ic-service`} />
        </div>
      </Tooltip>
      <div id="tm">
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          aria-labelledby="customized-dialog-title"
          open={open}
          onClose={handleClose}
          id="dialog"
        >
          <div className={styles["calculator-dialog"]}>
            <IconButton
              aria-label="close"
              className={styles["close-button"]}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <div className={styles["calculator-content"]}>
              <div
                className={styles["content-left"]}
                id="calculator-content-left"
              >
                <div className={styles["calculator-title"]}>
                  {translate("shalgoerServices.travelMoneyCalculatorTitle")}
                </div>
                <div>
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    id={styles["stepper"]}
                  >
                    {steps.map((label, index) => (
                      <Step
                        key={label}
                        onClick={handleStep(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <StepLabel>
                          <div className={styles["labels"]}>{label}</div>
                        </StepLabel>
                        <StepContent>
                          <Typography>
                            {getStepContent(index, {
                              resetHandler,
                              setResetHandler,
                            })}
                          </Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>

                <div className={styles["button-group"]}>
                  <div className={styles["btn-left"]}>
                    <div id="btn-left-reset">
                      {activeStep === 0 ? (
                        <Button
                          id="disabled-btn"
                          disabled={activeStep === 0}
                          variant="contained"
                          className={styles["button"]}
                        >
                          {translate("common.back")}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleBack}
                          className={styles["button"]}
                        >
                          {translate("common.back")}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className={styles["btn-right"]}>
                    {calculations ? (
                      <div>
                        <Button onClick={reset} className={styles["button"]}>
                          {translate("common.reset")}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {activeStep === steps.length - 1 ? (
                          <Button
                            className={styles["button"]}
                            variant="contained"
                            disabled={checkedBtn()}
                            loading={calculationsStatus === Status.LOADING}
                            onClick={calculate}
                          >
                            {calculationsStatus === Status.LOADING ? (
                              <CircularProgress color="#fff" size={20} />
                            ) : (
                              translate("common.calculate")
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            id="disabled-btn"
                            color="primary"
                            onClick={handleNext}
                            className={styles["button"]}
                            disabled={checkedBtn()}
                          >
                            {translate("common.next")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles["content-right"]} id="content-right">
                <div id="html2-canvas">
                  <div className={styles["calculator-title"]} id="plan-title">
                    {translate("shalgoerServices.yourPlanTitle")}
                  </div>

                  <div
                    className={styles["shalgo-trip-budget"]}
                    id="shalgo-trip-budget"
                  >

                    <YourPlan />
                  </div>
                  <div className={styles["plan-btn-right"]}>
                    <div id="message" style={{ display: "none" }}>
                      <p>
                        <span className={styles["message-left"]}>
                          {translate("shalgoerServices.notice")}
                        </span>
                        <span
                          onClick={saveImage}
                          className={styles["save-to-gallery"]}
                          data-html2canvas-ignore="true"
                        >
                          {translate("shalgoerServices.saveToGallery")}
                        </span>
                      </p>
                    </div>
                    <div id="plan-btn-right-reset" style={{ display: "none" }} data-html2canvas-ignore="true">
                      <Button
                        onClick={reset}
                        className={
                          language === ENGLISH_LANGUAGE_CODE_LK
                            ? styles["button"]
                            : styles["button-my"]
                        }
                      >
                        {translate("common.reset")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = ({ services, app }) => {
  const { baseMediaUrl } = app;
  const {
    sources,
    destinations,
    routes,
    accommodationLists,
    activitiesLists,
    mealPlansLists,
    transportationsLists,
    calculations,
    calculationsStatus,
  } = services;
  return {
    sources,
    destinations,
    routes,
    accommodationLists,
    activitiesLists,
    mealPlansLists,
    transportationsLists,
    calculations,
    baseMediaUrl,
    calculationsStatus,
  };
};

export default connect(mapStateToProps, {
  fetchSources,
  fetchDestinations,
  fetchRoutes,
  fetchServices,
  fetchCalculations,
  resetData,
})(TravelMoneyCalculator);
