import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../i18n';
import {
  Tabs,
  Tab,
} from '@mui/material';
import {
  TabContext,
} from '@mui/lab';
import FriendListTab from './FriendListTab';
import RequestsTab from './RequestsTab';
import InvitesTab from './InvitesTab';
import { AlertDialog, Snack } from '../../../../common';
import { CURRENT_USER_LK } from '../../../../constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FriendsTab = ({
  profileType,
  identifier,
  name,
  isPrivate,
  updateFollowerCount: _updateFollowerCount,
  updateFollowingCount: _updateFollowingCount,
  updateUserFollowStatus: _updateUserFollowStatus,
  reload: _reload,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');

  const showConfirmPrompt = (params) => {
    let data = {
      description: params.description,
      positiveButtonCallback: () => {
        params.positiveButtonCallback();
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      }
    }
    setAlertData(data);
    setAlertOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const showSanck = (message) => {
    setSnackMsg(message);
    setSnackOpen(true);
  }

  const closeSanck = () => {
    setSnackOpen(false);
  }

  const renderMyFriends = () => {
    return (
      <TabContext value={selectedTab}>
        <Tabs
          id='friends-tabs'
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          variant='fullWidth'
          centered
        >
          <Tab label={translate('userProfile.myFriends')} value={0} />
          <Tab label={translate('userProfile.requests')} value={1} />
          <Tab label={translate('userProfile.invites')} value={2} />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <FriendListTab
            profileType={profileType}
            identifier={identifier}
            name={name}
            isPrivate={isPrivate}
            updateFollowerCount={_updateFollowerCount}
            updateFollowingCount={_updateFollowingCount}
            showConfirmPrompt={showConfirmPrompt}
            showSnack={showSanck}
            reload={_reload}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <RequestsTab
            showConfirmPrompt={showConfirmPrompt}
            showSnack={showSanck}
            updateFollowingCount={_updateFollowingCount}
            reload={_reload}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <InvitesTab
            showSnack={showSanck}
            updateFollowingCount={_updateFollowingCount}
            reload={_reload}
          />
        </TabPanel>
      </TabContext>
    );
  };

  const renderFriendList = () => {
    return (
      <FriendListTab
        profileType={profileType}
        identifier={identifier}
        name={name}
        showConfirmPrompt={showConfirmPrompt}
        showSnack={showSanck}
        reload={_reload}
      />
    );
  };

  return (
    <div className='friends-tab-container'>
      {profileType === CURRENT_USER_LK
        ? renderMyFriends()
        : renderFriendList()
      }
      <Snack
        open={snackOpen}
        message={snackMsg}
        onClose={closeSanck}
      />
      <AlertDialog
        open={alertOpen}
        data={alertData}
      />
    </div>
  );
};

export default FriendsTab;