/**
 * Return file extension from file path
 *
 * NOTE: file extension doesn't contain '.'
 *
 * @param {string} fileName - Name of file, whose extension need to be extracted
 */
export const getFileExtension = fileName => {
  try {
    return fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
  } catch (error) {
    console.log(error);
    return null;
  }
};
