import React, { useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import { ASYNC_STORAGE_KEYS, BUSINESS_SK, VIDEO_SK } from '../../constants';
import { AlertDialog, MediaViewer } from '..';
import { isNonEmptyString } from '../../utils';
import { connect } from 'react-redux';
import { translate } from '../../i18n';
import { withRouter } from 'react-router';
import styles from './BusinessMediaGrid.module.scss';
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const NormalItem = ({ media, viewPhoto }) => {
  return (
    <ImageList
      id={styles['business-media']}
      variant="quilted"
      cols={media.length}
    >
      {media.map((item, key) => (
        <ImageListItem
          id={styles['normal-item']}
          key={key}
          cols={1}
          rows={1}
          onClick={() => viewPhoto(key)}
        >
          <img
            {...srcset(item.source.uri, 121, 1, 1)}
            alt="bimg"
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const ThreeGridItem = ({ media, viewPhoto }) => {
  return (
    <ImageList id={styles['business-media-lg']} variant="quilted" cols={2}>
      {media.map((item, key) => (
        <ImageListItem
          id={key === 0 ? styles['normal-item'] : styles['three-grid-item']}
          key={key}
          cols={1}
          rows={key === 0 ? 2 : 1}
          onClick={() => viewPhoto(key)}
        >
          <img
            {...srcset(item.source.uri, 121, 1, 1)}
            alt="bimg"
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const FourGridItem = ({ media, viewPhoto }) => {
  return (
    <ImageList id={styles['business-media-lg']} variant="quilted" cols={3}>
      {media.map((item, key) => (
        <ImageListItem
          id={
            key === 0 ? styles['multi-grid-first'] : styles['multi-grid-normal']
          }
          key={key}
          cols={key === 0 ? 3 : 1}
          onClick={() => viewPhoto(key)}
        >
          <img
            {...srcset(item.source.uri, 121, 1, 1)}
            alt="bimg"
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const MultiGridItem = ({ media, viewPhoto }) => {
  return (
    <ImageList id={styles['business-media-lg']} variant="quilted" cols={4}>
      {media.slice(0, 5).map((item, key) => (
        <ImageListItem
          id={
            key === 0 ? styles['multi-grid-first'] : styles['multi-grid-normal']
          }
          key={key}
          cols={key === 0 ? 4 : 1}
          onClick={() => viewPhoto(key)}
        >
          <img
            {...srcset(item.source.uri, 121, 1, 1)}
            alt="bimg"
            loading="lazy"
          />
          {key === 4 && media.length > 5 && (
            <div
              id={styles['more-media-icon']}
              onClick={(e) => {
                e.stopPropagation();
                viewPhoto(5);
              }}
            >
              {`+${media.length - 5}`}
            </div>
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const BusinessMediaGrid = ({
  media,
  type,
  userDetails,
  baseMediaUrl,
  baseMediaThumbUrl,
  history,
}) => {
  const [controls, setControls] = useState(false);
  const [mediaViewerState, setMediaViewerState] = useState({
    selected: 0,
    isOpen: false,
  });
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const profileImgUrl = userDetails?.profileImg.isThumbGenerated
    ? userDetails?.profileImg?.url?.thumbUrl
    : userDetails?.profileImg?.url?.originalUrl;

  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );

  const viewPhoto = (selected) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }
    setMediaViewerState({
      selected,
      isOpen: true,
    });
  };

  const showLoginPrompt = () => {
    let data = {
      title: translate('common.loginPromtDialogTitle'),
      description: translate('common.loginPromtDialogMessage'),
      positiveButtonCallback: () => {
        history.push('register-login');
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const closeMediaViewer = () => {
    setMediaViewerState({
      selected: 0,
      isOpen: false,
    });
  };

  if (media[0].fileType === VIDEO_SK) {
    return (
      <div id={styles['business-media']}>
        <video
          src={media[0].source.uri}
          controls={controls}
          onMouseEnter={() => setControls(true)}
          onMouseLeave={() => setControls(false)}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    );
  }

  const renderGrid = () => {
    switch (media.length) {
      case 1:
        return <NormalItem media={media} viewPhoto={viewPhoto} />;
      case 2:
        return <NormalItem media={media} viewPhoto={viewPhoto} />;
      case 3:
        return <ThreeGridItem media={media} viewPhoto={viewPhoto} />;
      case 4:
        return <FourGridItem media={media} viewPhoto={viewPhoto} />;
      default:
        return <MultiGridItem media={media} viewPhoto={viewPhoto} />;
    }
  };

  return (
    <>
      {renderGrid()}
      <MediaViewer
        open={mediaViewerState.isOpen}
        entityType={type}
        mediaList={media}
        selected={mediaViewerState.selected}
        profileImgUrl={profileImgUrl}
        userRole={BUSINESS_SK}
        userName={userDetails?.name}
        baseMediaUrl={baseMediaUrl}
        baseMediaThumbUrl={baseMediaThumbUrl}
        close={closeMediaViewer}
      />
      <AlertDialog open={alertOpen} data={alertData} />
    </>
  );
};

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaThumbUrl,
    baseMediaUrl,
  };
};

export default connect(mapStateToProps, null)(withRouter(BusinessMediaGrid));
