import { Status, } from '../../api';
import {
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_LOADING,
  EDIT_PROFILE_SUCCESS,
  RESET_EDIT_PROFILE_STATE,
  LOGIN_SUCCESS,
  VERIFY_OTP_SUCCESS,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  PROFILE_FILE_DIR_SK,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_LOADING,
  UPLOAD_PROFILE_IMAGE_ERROR,
  RESET_UPLOAD_PROFILE_IMAGE,
  COVER_FILE_DIR_SK,
  UPLOAD_COVER_IMAGE_LOADING,
  UPLOAD_COVER_IMAGE_SUCCESS,
  UPLOAD_COVER_IMAGE_ERROR,
  UPDATE_PHOTO,
  UNFRIEND_SUCCESS,
  UPDATE_USER_FOLLOW_STATUS_SUCCESS,
  BLOCKED_SUCCESS,
  FRIEND_SUCCESS,
  FRIEND_REQUEST_DELETE_SUCCESS,
  FRIEND_REQUEST_PENDING_SUCCESS,
  RESET_UPDATE_MEMBER_NETWORK_STATUS,
  FETCH_USER_FRIENDS_LOADING,
  FETCH_USER_FRIENDS_SUCCESS,
  FETCH_USER_FRIENDS_ERROR,
  FETCH_MORE_USER_FRIENDS_LOADING,
  FETCH_MORE_USER_FRIENDS_SUCCESS,
  FETCH_MORE_USER_FRIENDS_ERROR,
  FETCH_USER_REQUESTS_LOADING,
  FETCH_USER_REQUESTS_SUCCESS,
  FETCH_USER_REQUESTS_ERROR,
  FETCH_MORE_USER_REQUESTS_LOADING,
  FETCH_MORE_USER_REQUESTS_SUCCESS,
  FETCH_MORE_USER_REQUESTS_ERROR,
  FETCH_USER_INVITES_LOADING,
  FETCH_USER_INVITES_SUCCESS,
  FETCH_USER_INVITES_ERROR,
  FETCH_MORE_USER_INVITES_LOADING,
  FETCH_MORE_USER_INVITES_SUCCESS,
  FETCH_MORE_USER_INVITES_ERROR,
  FRIEND_INVITE_DELETE_SUCCESS,
  RESET_INCOMPLETE_USER_DETAILS_STATE,
  FETCH_FAVOURITES_LOADING,
  FETCH_FAVOURITES_SUCCESS,
  FETCH_FAVOURITES_ERROR,
  UPDATE_FAVOURITES_SUCCESS
} from '../../constants';

/**
 * NOTE: If you have to check whether user is logged in or not,
 * there is a "sessionStatus" variable in appReducer, use that
 */
const initialState = {
  userDetailsStatus: Status.DEFAULT,
  userDetailsErrorMessage: '',
  userIdentifier: null,
  userStatus: '',
  userDetails: {
    bio: {},
    interests: [],
    placesVisited: [],
    settings: {},
    workAndEducation: {},
  },
  editProfileStatus: Status.DEFAULT,
  editProfileErrorMessage: '',
  uploadProfileStatus: Status.DEFAULT,
  uploadErrorMessage: '',
  uploadCoverStatus: Status.DEFAULT,
  statusMsg: '',
  friends: [],
  friendsStatus: Status.DEFAULT,
  friendsErrorMessage: '',
  moreFriendsStatus: Status.DEFAULT,
  moreFriendsErrorMessage: '',
  friendsAvailableForRefresh: true,
  requests: [],
  requestsStatus: Status.DEFAULT,
  requestsErrorMessage: '',
  moreRequestsStatus: Status.DEFAULT,
  moreRequestsErrorMessage: '',
  requestsAvailableForRefresh: true,
  invites: [],
  invitesStatus: Status.DEFAULT,
  invitesErrorMessage: '',
  moreInvitesStatus: Status.DEFAULT,
  moreInvitesErrorMessage: '',
  invitesAvailableForRefresh: true,
  updateMemberNetworkStatus: Status.DEFAULT,
  updateMemberNetworkErrorMessage: '',
  updateMemberNetworkSuccessMessage: '',
  fetchFavouriteStatus: Status.DEFAULT,
  favourites: [],
  fetchFavouriteErrorMessage: '',
  hasMore: false
};

/**
 * Profile reducer, store user details
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:

      return {

        ...state,
        userIdentifier: payload.userIdentifier,
        userStatus: payload.userStatus,
      };

    case VERIFY_OTP_SUCCESS:

      return {

        ...state,
        userIdentifier: payload.userIdentifier,
        userStatus: payload.userStatus,
      };
    case GET_USER_DETAILS_REQUEST:

      return {

        ...state,
        userIdentifier: payload.userIdentifier,
        editProfileStatus: Status.DEFAULT,
      };
    case GET_USER_DETAILS_LOADING:
      return {
        ...state,
        userDetailsStatus: Status.LOADING,
        userDetailsErrorMessage: '',
      };
    case GET_USER_DETAILS_SUCCESS: {
      const {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      } = payload;
      return {
        ...state,
        userDetailsStatus: Status.SUCCESS,
        userDetailsErrorMessage: '',
        userDetails: {
          bio,
          interests,
          placesVisited,
          settings,
          workAndEducation,
        },
      };
    }

    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsStatus: Status.ERROR,
        userDetailsErrorMessage: payload.errorMessage,
      };
    case EDIT_PROFILE_LOADING:
      return {
        ...state,
        editProfileStatus: Status.LOADING,
        editProfileErrorMessage: '',
      };
    case EDIT_PROFILE_SUCCESS: {
      const {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      } = payload;
      return {
        ...state,
        editProfileStatus: Status.SUCCESS,
        editProfileErrorMessage: '',
        userDetails: {
          bio,
          interests,
          placesVisited,
          settings,
          workAndEducation,
        },
        userStatus: bio.userStatus,
      };
    }
    case EDIT_PROFILE_ERROR:
      return {
        ...state,
        editProfileStatus: Status.ERROR,
        editProfileErrorMessage: payload.errorMessage,
      };
    case RESET_EDIT_PROFILE_STATE:
      return {
        ...state,
        editProfileStatus: Status.DEFAULT,
        editProfileErrorMessage: '',
      };
    case UPLOAD_PROFILE_IMAGE_LOADING: {
      return {
        ...state,
        uploadProfileStatus: Status.LOADING,
        uploadErrorMessage: '',
      };
    }
    case UPLOAD_PROFILE_IMAGE_SUCCESS: {
      const { path, identifier, localPath } = payload;

      const image = {
        identifier,
        localPath,
        isThumbGenerated: 0,
        filePath: path,
      };

      return {
        ...state,
        uploadProfileStatus: Status.SUCCESS,
        userDetails: updatePhotoInBio(state, image, PROFILE_FILE_DIR_SK),
      };
    }
    case UPLOAD_PROFILE_IMAGE_ERROR: {
      return {
        ...state,
        uploadProfileStatus: Status.ERROR,
        uploadErrorMessage: payload.errorMessage,
      };
    }
    case RESET_UPLOAD_PROFILE_IMAGE: {
      return {
        ...state,
        uploadProfileStatus: Status.DEFAULT,
        uploadErrorMessage: '',
      };
    }
    case UPLOAD_COVER_IMAGE_LOADING: {
      return {
        ...state,
        uploadCoverStatus: Status.LOADING,
        uploadErrorMessage: '',
      };
    }
    case UPLOAD_COVER_IMAGE_SUCCESS: {
      const { path, identifier, localPath } = payload;

      const image = {
        identifier,
        localPath,
        isThumbGenerated: 0,
        filePath: path,
      };

      return {
        ...state,
        uploadCoverStatus: Status.SUCCESS,
        userDetails: updatePhotoInBio(state, image, COVER_FILE_DIR_SK),
      };
    }
    case UPLOAD_COVER_IMAGE_ERROR: {
      return {
        ...state,
        uploadCoverStatus: Status.ERROR,
        uploadErrorMessage: payload.errorMessage,
      };
    }
    case UPDATE_PHOTO: {
      // set profile or cover photo from album
      const { statusMsg, fileDirectory, image } = payload;
      return {
        ...state,
        userDetails: updatePhotoInBio(state, image, fileDirectory),
        statusMsg,
      };
    }
    case FETCH_USER_FRIENDS_LOADING:
      return {
        ...state,
        friendsStatus: Status.LOADING,
        friendsErrorMessage: '',
        friends: [],
        friendsAvailableForRefresh: true,
      };
    case FETCH_USER_FRIENDS_SUCCESS:
      return {
        ...state,
        friendsStatus: Status.SUCCESS,
        friendsErrorMessage: '',
        friends: payload.userNetworkList,
        friendsAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_USER_FRIENDS_ERROR:
      return {
        ...state,
        friendsStatus: Status.ERROR,
        friendsErrorMessage: payload.errorMessage,
      };
    case FETCH_MORE_USER_FRIENDS_LOADING:
      return {
        ...state,
        moreFriendsStatus: Status.LOADING,
        moreFriendsErrorMessage: '',
      };
    case FETCH_MORE_USER_FRIENDS_SUCCESS:
      return {
        ...state,
        moreFriendsStatus: Status.SUCCESS,
        moreFriendsErrorMessage: '',
        friends: [...state.friends, ...payload.userNetworkList],
        friendsAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_MORE_USER_FRIENDS_ERROR:
      return {
        ...state,
        moreFriendsStatus: Status.ERROR,
        moreFriendsErrorMessage: payload.errorMessage,
      };
    case FETCH_USER_REQUESTS_LOADING:
      return {
        ...state,
        requestsStatus: Status.LOADING,
        requestsErrorMessage: '',
      };
    case FETCH_USER_REQUESTS_SUCCESS:
      return {
        ...state,
        requestsStatus: Status.SUCCESS,
        requestsErrorMessage: '',
        requests: payload.userNetworkList,
        requestsAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_USER_REQUESTS_ERROR:
      return {
        ...state,
        requestsStatus: Status.ERROR,
        requestsErrorMessage: payload.errorMessage,
      };
    case FETCH_MORE_USER_REQUESTS_LOADING:
      return {
        ...state,
        moreRequestsStatus: Status.LOADING,
        moreRequestsErrorMessage: '',
      };
    case FETCH_MORE_USER_REQUESTS_SUCCESS:
      return {
        ...state,
        moreRequestsStatus: Status.SUCCESS,
        moreRequestsErrorMessage: '',
        requests: [...state.requests, ...payload.userNetworkList],
        requestsAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_MORE_USER_REQUESTS_ERROR:
      return {
        ...state,
        moreRequestsStatus: Status.ERROR,
        moreRequestsErrorMessage: payload.errorMessage,
      };
    case FETCH_USER_INVITES_LOADING:
      return {
        ...state,
        invitesStatus: Status.LOADING,
        invitesErrorMessage: '',
      };
    case FETCH_USER_INVITES_SUCCESS:
      return {
        ...state,
        invitesStatus: Status.SUCCESS,
        invitesErrorMessage: '',
        invites: payload.userNetworkList,
        invitesAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_USER_INVITES_ERROR:
      return {
        ...state,
        invitesStatus: Status.ERROR,
        invitesErrorMessage: payload.errorMessage,
      };
    case FETCH_MORE_USER_INVITES_LOADING:
      return {
        ...state,
        moreInvitesStatus: Status.LOADING,
        moreInvitesErrorMessage: '',
      };
    case FETCH_MORE_USER_INVITES_SUCCESS:
      return {
        ...state,
        moreInvitesStatus: Status.SUCCESS,
        moreInvitesErrorMessage: '',
        invites: [...state.invites, ...payload.userNetworkList],
        invitesAvailableForRefresh: payload.userNetworkList.length > 0,
      };
    case FETCH_MORE_USER_INVITES_ERROR:
      return {
        ...state,
        moreInvitesStatus: Status.ERROR,
        moreInvitesErrorMessage: payload.errorMessage,
      };
    case UNFRIEND_SUCCESS:
      return {
        ...state,
        friends: payload.friends,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case UPDATE_USER_FOLLOW_STATUS_SUCCESS:
      return {
        ...state,
        friends: payload.friends,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case BLOCKED_SUCCESS:
      return {
        ...state,
        friends: payload.friends,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case FRIEND_SUCCESS:
      return {
        ...state,
        friends: payload.friends,
        requests: payload.requests,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case FRIEND_REQUEST_DELETE_SUCCESS:
      return {
        ...state,
        requests: payload.requests,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case FRIEND_REQUEST_PENDING_SUCCESS:
      return {
        ...state,
        invites: payload.invites,
        updateMemberNetworkStatus: Status.SUCCESS,
      };
    case FRIEND_INVITE_DELETE_SUCCESS:
      return {
        ...state,
        invites: payload.invites,
        updateMemberNetworkStatus: Status.SUCCESS,
      }
    case RESET_UPDATE_MEMBER_NETWORK_STATUS:
      return {
        ...state,
        updateMemberNetworkStatus: Status.DEFAULT,
        updateMemberNetworkErrorMessage: '',
      };
    case RESET_INCOMPLETE_USER_DETAILS_STATE:
      return {
        ...state,
        userDetails: {
          bio: {},
          interests: [],
          placesVisited: [],
          settings: {},
          workAndEducation: {},
        },
      }
    case FETCH_FAVOURITES_LOADING:
      return {
        ...state,
        fetchFavouriteStatus: Status.LOADING,
        fetchFavouriteErrorMessage: '',
      }
    case FETCH_FAVOURITES_SUCCESS:
      return {
        ...state,
        fetchFavouriteStatus: Status.SUCCESS,
        favourites: payload.favourites,
        hasMore: payload.hasMore
      }
    case FETCH_FAVOURITES_ERROR:
      return {
        ...state,
        fetchFavouriteStatus: Status.ERROR,
        fetchFavouriteErrorMessage: payload.errorMessage
      }
    case UPDATE_FAVOURITES_SUCCESS:
      return {
        ...state,
        favourites: payload.favourites
      }
    default:
      return state;
  };

}

const updatePhotoInBio = (state, image, fileDirectory) => {
  if (fileDirectory === COVER_FILE_DIR_SK) {
    return {
      ...state.userDetails,
      bio: {
        ...state.userDetails.bio,
        coverImg: {
          identifier: image.identifier,
          isThumbGenerated: image.isThumbGenerated,
          url: image.filePath,
          localPath: image.localPath,
        },
      },
    };
  }

  return {
    ...state.userDetails,
    bio: {
      ...state.userDetails.bio,
      profileImg: {
        identifier: image.identifier,
        isThumbGenerated: image.isThumbGenerated,
        url: {
          thumbUrl: image.filePath,
          originalUrl: image.filePath,
        },
        localPath: image.localPath,
      },
    },
  };
};
