/**
 * Define all type of string constants, which are used in api
 *
 * Suffix _SK : Means Server Key => these keys are send in api request or used in api response
 * Suffix _LK : Means Local Key => Keys used at app level to distinguish something
 */
// ============================================================
// ======================= Reaction Types =====================
// ============================================================
export const INTERESTED_SK = 'interested';
export const WANDER_TO_GO_SK = 'wanderToGo';
export const LOVE_SK = 'love';
export const NONE_SK = 'none';
// ============================================================
// ======================= RECOMMENDATION Types ===============
// ============================================================
export const RELIGIOUS_SK = 'religious';
export const NON_RELIGIOUS_SK = 'nonReligious';
export const HOTEL_SK = 'hotel';
export const RESTAURANT_SK = 'restaurant';
// ============================================================
// ======================= ENTITY  Types =====================
// ============================================================
export const POST_SK = 'post';
export const BLOG_SK = 'blog';
export const ARTICLE_SK = 'article';
export const COMMENT_SK = 'comment';
export const REPLY_SK = 'reply';
export const RANDOM_SK = 'random';
// ============================================================
// ====================== Post Visibility =====================
// ============================================================
export const PUBLIC_SK = 'public';
export const PRIVATE_SK = 'private';
export const ONLY_ME_SK = 'onlyMe';
// ============================================================
// ================= User Relation with others ================
// ============================================================
export const FOLLOWING_SK = 'following';
export const UNFOLLOWED_SK = 'unFollowed';
export const NO_CONNECTION_SK = 'noConnection';
export const PENDING_SK = 'pending';
export const FRIEND_REQUEST_PENDING_SK = 'friendRequestPending';
export const FRIEND_ACCEPT_PENDING_SK = 'friendAcceptPending';
export const FRIEND_SK = 'friend';
export const UNFRIEND_SK = 'unFriend';
export const BLOCKED_SK = 'blocked';
export const UNBLOCKED_SK = 'unBlocked';
export const FRIEND_REQUEST_DELETE_SK = 'friendRequestDelete';
export const FRIEND_INVITE_DELETE_SK = 'friendInviteDelete';
export const LOADING_SK = 'LOADING';
export const SUCCESS_SK = 'SUCCESS';
export const ERROR_SK = 'ERROR';
// ============================================================
// ====================== Verify OTP  request type ============
// ============================================================
export const OTP_REQUEST_REG = 'registration';
export const OTP_REQUEST_FORGOT_PASSWORD = 'passwordReset';
// ============================================================
// ====================== SORT TYPE ===========================
// ============================================================
export const SORT_DATE_SK = 'publishedAt';
export const SORT_POPULAR_SK = 'popular';
export const SORT_ALPHABETIC_SK = 'alphabetic';
// ============================================================
// ===================== SORT ORDER ===========================
// ============================================================
export const ASCENDING_SK = 'asc';
export const DESCENDING_SK = 'desc';
// ============================================================
// ===================== FILE TYPE/Media type ===========================
// ============================================================
export const IMAGE_SK = 'image';
export const VIDEO_SK = 'video';
export const ALBUM_SK = 'album';
// ============================================================
// ================= Gender options ===========================
// ============================================================
export const MALE_SK = 'male';
export const FEMALE_SK = 'female';
export const OTHER_SK = 'other';
// ============================================================
// ================= User Status ==============================
// ============================================================
export const ACTIVE_SK = 'active';
export const PROFILE_INCOMPLETE_SK = 'profileIncomplete';
// ============================================================
// ================= Login Types ==============================
// ============================================================
export const EMAIL_SK = 'email';
export const PHONE_SK = 'mobileNumber';
// ============================================================
// ================= Input Types ==============================
// ============================================================
export const NAME_LK = 'name';
export const EMAIL_LK = 'email';
export const PHONE_NUMBER_LK = 'phoneNumber';
export const DOB_LK = 'dateOfBirth';
export const HOME_TOWN_LK = 'homeTown';
export const GENDER_LK = 'gender';
export const CURRENT_CITY_LK = 'currentCity';
export const MEMBER_STATUS_LK = 'memberStatus';
// ============================================================
// ============== FILE UPLOAD DIRECTORY =======================
// ============================================================
export const POST_FILE_DIR_SK = 'post';
export const PROFILE_FILE_DIR_SK = 'profilePhoto';
export const COVER_FILE_DIR_SK = 'coverPhoto';
export const ALBUM_FILE_DIR_SK = 'album';
export const REVIEW_FILE_DIR_SK = 'review';
// ============================================================
// ================= Languages ================================
// ============================================================
export const ENGLISH_SK = 'english';
export const BURMESE_SK = 'burmese';
export const ENGLISH_LANGUAGE_CODE_LK = 'en';
export const BURMESE_LANGUAGE_CODE_LK = 'my';
// ============================================================
// ================= Profile entity type ========================
// ============================================================
export const AMM_ADMIN_SK = 'AMM'; // AMM admin
export const USER_SK = 'user';
export const BUSINESS_SK = 'business';
export const ATTRACTION_SK = 'attraction';
// ============================================================
// =========== Profile Screen local constants ========================
// ============================================================
export const BUSINESS_PROFILE_LK = 'BUSINESS_PROFILE';
export const ATTRACTION_PROFILE_LK = 'ATTRACTION_PROFILE';
export const FRIEND_PROFILE_LK = 'FRIEND_PROFILE';
export const CURRENT_USER_LK = 'CURRENT_USER';
// ============================================================
// ============ Activity Type (History,PN) =======================
// ============================================================
export const CREATE_POST_ACT_SK = 'CREATEPOST';
export const SHARE_POST_ACT_SK = 'SHAREPOST';
export const COMMENT_TO_POST_ACT_SK = 'COMMENTONPOST';
export const REPLY_ON_COMMENT_ACT_SK = 'REPLYONCOMMENT';
export const REACTION_POST_ACT_SK = 'REACTONPOST';
export const REACTION_ON_COMMENT_ACT_SK = 'REACTONCOMMENT';
export const REACTION_ON_REPLY_ACT_SK = 'REACTONREPLY';
export const SHARE_ARTICLE_ACT_SK = 'SHAREARTICLE';
export const SHARE_ATTRACTION_ACT_SK = 'SHAREATTRACTION';

export const FRIEND_REQUEST_SENT_ACT_SK = 'FRIENDREQUESTSENT';
export const FRIEND_REQUEST_ACCEPT_ACT_SK = 'FRIENDREQUESTACCEPTED';
export const CREATE_ALBUM_ACT_SK = 'CREATEALBUM';

// ============================================================
// ============ type (PN) =======================
// ============================================================

export const PN_USER_SK = 'USER';
export const PN_BUSINESS_SK = 'BUSINESS';
export const PN_POST_SK = 'POST';

// ============================================================
// ================= Permission errors ========================
// ============================================================
export const E_PERMISSION_MISSING = 'E_PERMISSION_MISSING';
export const E_PICKER_CANCELLED = 'E_PICKER_CANCELLED';
export const E_NO_IMAGE_DATA_FOUND = 'E_NO_IMAGE_DATA_FOUND';
export const E_ERROR_WHILE_CLEANING_FILES = 'E_ERROR_WHILE_CLEANING_FILES';
export const UNAVAILABLE_SK = 'UNAVAILABLE';
export const UNAUTHORIZED_SK = 'UNAUTHORIZED';
export const CANCELLED_SK = 'CANCELLED';
export const TIMEOUT_SK = 'TIMEOUT';
// ============================================================
// ================= PostListItem Mode ========================
// ============================================================
export const PLI_HOME_MODE_LK = 'home';
export const PLI_HISTORY_MODE_LK = 'history';
export const PLI_DETAIL_MODE_LK = 'detail';
export const PLI_PROFILE_MODE_LK = 'profile'; // Friends profile or Business Profile
export const PUBLIC_PROFILE_SK = 'public_profile';
export const USER_GENDER_SK = 'user_gender';
export const USER_HOMETOWN_SK = 'user_hometown';
export const FACEBOOK_SK = 'facebook';
export const GOOGLE_SK = 'google';
export const APPLE_SK = 'apple';
export const TWITTER_SK = 'twitter';
export const WHEN_IN_USE_SK = 'whenInUse';
export const IOS_SK = 'ios';
export const SAVE_SK = 'save';
export const UNSAVE_SK = 'unsave';
// ============================================================
// ================= Highway Contact Category =================
// ============================================================
export const EMERGENCY_SK = 'Emergency';
export const POLICE_SK = 'Police';
// ============================================================
// ================= Blogger ==================================
// ============================================================
export const BLOGGER_SK = 'blogger';
// ============================================================
// ================= Business Profile =========================
// ============================================================
export const SLIDER_PHOTO_SK = 'Slider Photos';
// ============================================================
// ================= Highlights ===============================
// ============================================================
export const FESTIVAL_SK = 'Festival';
export const ECOTOURISM_SK = 'Ecotourism';
export const CBT_SK = 'Community Based Tourism';
// ============================================================
// ================= Account Control and Ownership ============
// ============================================================
export const DEACTIVATE_LK = 'deactivated';
export const DELETE_LK = 'deleted';
// ============================================================
// ============== Attractions Listing Pages ===================
// ============================================================
export const RECOMMENDATIONS_LK = 'recommendations';
export const NEARBY_LK = 'nearby';
export const LANDMARK_LK = 'landmark';
// ============================================================
// ================= Myanmar Country Code =====================
// ============================================================
export const MYANMAR_COUNTRY_CODE = '+95';
export const NEPAL_COUNTRY_CODE = '+977';
// ============================================================
// ================== Deactivate Duration =====================
// ============================================================
export const WEEK_DURATION = 'week';
export const FORTNIGHT_DURATION = 'fortnight';
export const MONTH_DURATION = 'month';
// ============================================================
// ===================== Local IP Address =====================
// ============================================================
export const LOCAL_IP_ADDRESS = '127.0.0.1';
// ============================================================
// ===================== Network Connection ===================
// ============================================================
export const ONLINE_LK = 'online';
export const OFFLINE_LK = 'offline';
// ============================================================
// ================= Review Visibility ========================
// ============================================================
export const ON_SK = 'on';
export const OFF_SK = 'off';