import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SUCCESS_SK } from '../../constants';
import styles from './Snack.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};

const defaultProps = {
  type: SUCCESS_SK,
  message: '',
  onClose: () => { },
};

const Snack = ({
  open,
  message,
  type,
  onClose,
}) => {
  const checkType = type.toLocaleLowerCase();
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={onClose}
      >
        <Alert
          severity={checkType}
          className={checkType === 'success' ? styles['alert-success'] : styles['alert-error']}
          onClose={onClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );

}

Snack.propTypes = propTypes;
Snack.defaultProps = defaultProps;

export default Snack;





