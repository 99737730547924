/* eslint-disable no-unused-vars */
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api, Status } from '../../../../api';
import { PostListItem, Snack } from '../../../../common';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  BUSINESS_PROFILE_LK,
  BUSINESS_SK,
  CURRENT_USER_LK,
  PLI_PROFILE_MODE_LK,
  POST_SK,
  SAVE_SK,
  SUCCESS_SK,
  UNSAVE_SK,
} from '../../../../constants';
import { translate } from '../../../../i18n';
import { makeStyles } from '@material-ui/core/styles';
import {
  deletePost,
  deleteBlog,
  resetDeletePostStatusToDefault,
  resetEditPostStatus,
  resetSharePostStatusToDefault,
  resetReportPostStatusToDefault,
  updateReaction,
  updateCommentCount,
  updateHomePageData,
} from '../../../../store/actions';
import { connect } from 'react-redux';
import styles from './PostTab.module.scss';

const PostTab = ({
  identifier,
  /**
   * PostsTab can render either for friends profile screen
   * or business profile screen
   */
  profileType,
  /**
   * If PostsTab is opened for business profile screen,
   * subtype tells whether hotel or restaurant
   */
  subType = '',
  loggedInUserIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  deletePostStatus,
  userDetails: loggedInUserDetails,
  homePosts,
  editPostStatus,
  editedPost,
  deletedPostIdentifier,
  sharePostStatus,
  sharedPostIdentifier,
  sharedPost,
  reportPostStatus,
  deletePost,
  deleteBlog,
  resetDeletePostStatusToDefault,
  resetEditPostStatus,
  resetSharePostStatusToDefault,
  resetReportPostStatusToDefault,
  reload: reloadProfileScreen,
  updateReaction,
  updateCommentCount,
  detailApiStatus,
  updateHomePageData: _updateHomePageData,
}) => {
  const classes = useStyles();
  const [contentAvailableForRefresh, setContentAvailableForRefresh] =
    useState(true);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });

  useEffect(() => {
    if (sharePostStatus === Status.SUCCESS) {
      if (profileType === CURRENT_USER_LK) {
        addSharedPostInList();
      }
      showSnack({
        type: SUCCESS_SK,
        message: translate('homeScreen.postShareSuccessMessage'),
      });
      resetSharePostStatusToDefault();
    }
    if (reportPostStatus === Status.SUCCESS) {
      showSnack({
        type: SUCCESS_SK,
        message: translate('postListItem.postReportedSuccessMessage'),
      });
      resetReportPostStatusToDefault();
    }
  }, [sharePostStatus, reportPostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editPostStatus === Status.SUCCESS && profileType === CURRENT_USER_LK) {
      resetEditPostStatus();
      editPostInList();
    }
  }, [editPostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      deletePostStatus === Status.SUCCESS &&
      profileType === CURRENT_USER_LK
    ) {
      deletePostInList();
    }
  }, [deletePostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (profileType === CURRENT_USER_LK) {
      const _profileImg = loggedInUserDetails.bio.profileImg;
      let _posts = [];
      posts.forEach((post) => {
        _posts.push({
          ...post,
          userDetails: {
            ...post.userDetails,
            profileImg: {
              isThumbGenerated: _profileImg.isThumbGenerated,
              url: _profileImg.url,
            },
          },
        });
      });
      _posts.length !== 0 && setPosts(_posts);
    }
  }, [loggedInUserDetails.bio.profileImg]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    detailApiStatus === Status.SUCCESS && refreshList();
  }, [detailApiStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    page !== 1 && refreshList()
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshList = () => {
    api[
      profileType === BUSINESS_PROFILE_LK
        ? 'fetchBusinessPosts'
        : 'fetchMemberPosts'
    ]({
      identifier,
      page,
    })
      .then(({ userResponse }) => {
        handlePosts(userResponse);
      })
      .catch((error) => {
        setContentAvailableForRefresh(false);
      });
  };

  const handlePosts = (userResponse) => {
    const { postList, businessDetails, userDetails } = userResponse; // eslint-disable-line no-unused-vars
    const list = postList.map((item) => {
      const extraKeys = {};
      if (profileType === BUSINESS_PROFILE_LK) {
        extraKeys.type = POST_SK;
        extraKeys.userDetails = {
          identifier,
          type: BUSINESS_SK,
          ...businessDetails,
        };
      }
      return { ...item, ...extraKeys };
    });
    if (page === 1) {
      setPosts(list);
    } else {
      setPosts((prevState) => [...prevState, ...list]);
    }
    if (list.length === 0) {
      setContentAvailableForRefresh(false);
    }
  };

  const addNewPostInList = () => {
    const newPost = homePosts[0];
    setPosts((prevState) => {
      return [newPost, ...prevState];
    });
  };

  const deletePostInList = () => {
    setPosts((prevState) => {
      return prevState.filter((el) => el.identifier !== deletedPostIdentifier);
    });
    resetDeletePostStatusToDefault();
    showSnack({
      type: SUCCESS_SK,
      message: translate('homeScreen.postDeletedSuccessMessage'),
    });
  };

  const editPostInList = () => {
    const { identifier, visibility, description } = editedPost;
    setPosts((prevState) => {
      return prevState.map((post) => {
        return {
          ...post,
          visibility:
            post.identifier === identifier ? visibility : post.visibility,
          description:
            post.identifier === identifier ? description : post.description,
        };
      });
    });
  };

  const addSharedPostInList = () => {
    setPosts((prevState) => {
      return [sharedPost, ...prevState].map((post) => {
        return {
          ...post,
          totalShares:
            post.identifier === sharedPostIdentifier
              ? post.totalShares + 1
              : post.totalShares,
        };
      });
    });
  };

  const showSnack = ({ message, type }) => {
    setSnackData({
      open: true,
      type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const savePostInBusiness = ({ identifier, favouriteIdentifier }) => {
    if (favouriteIdentifier) {
      api.deleteFavourite({ favouriteIdentifier })
        .then(() => {
          setPosts((prevState) => {
            const posts = prevState.map((post) => {
              if (post.identifier === identifier) {
                return {
                  ...post,
                  favouriteIdentifier: "",
                  isSaved: SAVE_SK
                };
              }
              return post;
            })
            return posts;
          });
          showSnack({
            type: SUCCESS_SK,
            message: translate("homeScreen.unsaveFavouriteSuccessMessage"),
          });
          /* update home page post list item */
          _updateHomePageData({ identifier });
        }).catch((error) => {
          console.log(error);
        })
    } else {
      api.saveFavourite({
        entityIdentifier: identifier,
        entityType: POST_SK,
        userIdentifier: loggedInUserIdentifier
      })
        .then(({ userResponse: { favouriteIdentifier } }) => {
          setPosts((prevState) => {
            const posts = prevState.map((post) => {
              if (post.identifier === identifier) {
                return {
                  ...post,
                  favouriteIdentifier: favouriteIdentifier,
                  isSaved: UNSAVE_SK
                };
              }
              return post;
            })
            return posts;
          });
          showSnack({
            type: SUCCESS_SK,
            message: translate("homeScreen.saveFavouriteSuccessMessage"),
          });
          /* update home page post list item */
          _updateHomePageData({ favouriteIdentifier, identifier });
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  return (
    <>
      <div className={styles['posts-container']}>
        <InfiniteScroll
          dataLength={posts.length} //This is important field to render the next data
          next={() => setPage(page + 1)}
          hasMore={contentAvailableForRefresh}
          loader={
            <div className={styles.spinner}>
              <CircularProgress size={22} className="loading_spinner" />
            </div>
          }
          endMessage={
            posts.length === 0 && !contentAvailableForRefresh ? (
              <div className={styles['nomore-data-info']}>
                <span>{translate('homeScreen.noPostFound')}</span>
              </div>
            ) : (
              <div className={styles['nomore-data-info']}>
                <CheckCircleOutlineIcon />
                <span>{translate('homeScreen.noMorePostInfo')}</span>
              </div>
            )
          }
        >
          {posts.map((item, key) => {
            return (
              <PostListItem
                key={key}
                item={item}
                mode={PLI_PROFILE_MODE_LK}
                baseMediaUrl={baseMediaUrl}
                baseMediaThumbUrl={baseMediaThumbUrl}
                deletePost={deletePost}
                deleteBlog={deleteBlog}
                loggedInUserIdentifier={loggedInUserIdentifier}
                profileScreenIdentifier={identifier}
                reloadProfileScreen={reloadProfileScreen}
                containerStyle={classes.pliStyle}
                updateReaction={updateReaction}
                updateCommentCount={updateCommentCount}
                savePostInBusiness={savePostInBusiness}
                setPosts={setPosts}
              />
            );
          })}
        </InfiniteScroll>
      </div>
      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={handleSnackClose}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pliStyle: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginBottom: '3%',
    border: "1px solid #BEBEBE",
    boxShadow: "0px 0px 4px rgba(1, 13, 24, 0.1) !important"
  },

  "@media screen and (max-width: 900px)": {
    pliStyle: {
      marginTop: "10px",
    },
  },
}));

const mapStateToProps = ({ home, profile }) => {
  const {
    deletePostStatus,
    deletedPostIdentifier,
    posts: homePosts,
    editPostStatus,
    editedPost,
    sharePostStatus,
    sharedPostIdentifier,
    sharedPost,
    reportPostStatus,
    successMessage,
  } = home;
  const { userDetails } = profile;

  return {
    deletePostStatus,
    userDetails,
    homePosts,
    deletedPostIdentifier,
    editPostStatus,
    editedPost,
    sharePostStatus,
    sharedPostIdentifier,
    sharedPost,
    reportPostStatus,
    successMessage,
  };
};

export default connect(mapStateToProps, {
  deletePost,
  deleteBlog,
  resetDeletePostStatusToDefault,
  resetEditPostStatus,
  resetSharePostStatusToDefault,
  resetReportPostStatusToDefault,
  updateReaction,
  updateCommentCount,
  updateHomePageData,
})(PostTab);
