import { shape, string, number, arrayOf } from 'prop-types';

/**
 * Defines prop-types definition for a single article item
 * which is being displayed on HomeScreen
 */
const articleType = shape({
  createdAt: string,
  publishedAt: string,
  description: string,
  identifier: string,
  itemId: number,
  mediaUrl: arrayOf(
    shape({
      filePath: string,
      fileType: string,
      isThumbGenerated: number,
    }),
  ),
  title: string,
  totalReviews: number,
  totalShares: number,
  totalViews: number,
  type: string,
});

export default articleType;
