import React, { useEffect } from "react";
import {
  AppBar,
  BackToTopIcon,
  // CreatePostIcon
} from "../../common";
import Grid from "@mui/material/Grid";
import styles from "./Teams&Policy.module.scss";
import { api } from "../../api";
import { translate } from "../../i18n";

const PrivacyPolicy = () => {
  useEffect(() => {
    api
      .getPrivacyPolicy()
      .then(({ userResponse }) => {
        // setDescription(userResponse.description);

        document.getElementById("policy-rule").innerHTML =
          userResponse.description;
      })
      .catch(() => {});
  }, []);

  return (
    <div>
      <AppBar />
      <div id="back-to-top-anchor" />
      <div className={styles["policy-container"]}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className={styles["policy-title"]}>
              {translate("privacyPolicy.title")}
            </div>

            <div id="policy-rule" className={styles["policy-rule"]}></div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
    </div>
  );
};

export default PrivacyPolicy;
