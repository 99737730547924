/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { translate } from "../../i18n";
import {
  ARTICLE_SK,
  ATTRACTION_SK,
  BLOG_SK,
  HOTEL_SK,
  POST_SK,
  RESTAURANT_SK,
  USER_SK,
} from "../../constants";
import { BusinessSuggestion, NoResult, SearchListItem } from "./components";
import { Button } from "../../common";
import { withRouter } from "react-router";
import styles from "./SearchResult.module.scss";

const MODE = "ALL";

const AllResultTab = ({
  state,
  isLoggedIn,
  /**
   * Base url need to append with image url to generate full url
   *
   * @source redux
   */
  baseMediaUrl,
  /**
   * Base url pefixed to filePath to generate
   * complete url for thumbnail version of image
   *
   * @source redux
   */
  baseMediaThumbUrl,
  handleSeeMoreClicked,
  showLoginPrompt,
  history,
  isNoResult
}) => {
  const keyword = new URLSearchParams(window.location.search).get("q");

  const renderHeader = (title) => (
    <Typography id={styles["search-list-item-title"]}>{title}</Typography>
  );

  const renderFooter = (type) => (
    <Box id={styles["seemore-box"]}>
      <Button
        id={styles["all-search-result-button"]}
        variant="contained"
        size="small"
        onClick={() => handleSeeMoreClicked(type)}
      >
        {translate("common.seeMore")}
      </Button>
    </Box>
  );

  const renderList = (list, title, type) => (
    <Box id={styles["list-box"]}>
      {renderHeader(title)}
      <Grid container spacing={2} marginBottom={2}>
        {list.map((item, key) => (
          <Grid
            item
            key={key}
            xs={type === POST_SK || type === BLOG_SK ? 12 : 6}
            sm={type === POST_SK || type === BLOG_SK ? 12 : 4}
            md={type === POST_SK || type === BLOG_SK ? 12 : 3}
          >
            <SearchListItem
              mode={MODE}
              type={type}
              item={item}
              isLoggedIn={isLoggedIn}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              showLoginPrompt={showLoginPrompt}
              history={history}
            />
          </Grid>
        ))}
      </Grid>
      {renderFooter(type)}
      <Divider />
    </Box>
  );

  const renderBusinessList = (list, title, type) => (
    <Box className={styles["list-box"]}>
      {renderHeader(title)}
      <Grid container spacing={2} marginBottom={2}>
        {list.map((item, key) => (
          <Grid item key={key} xs={6} sm={4} md={3} lg={2}>
            <SearchListItem
              mode={MODE}
              type={type}
              item={item}
              isLoggedIn={isLoggedIn}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              showLoginPrompt={showLoginPrompt}
              history={history}
            />
          </Grid>
        ))}
      </Grid>
      {renderFooter(type)}
      <Divider />
    </Box>
  );

  return (
    <Box id={styles["all-result-tab-root-filter"]} >
      {isNoResult && <NoResult show={true} />}
      {state.attractions.length > 0 &&
        renderList(
          state.attractions,
          `${translate("searchResultScreen.attractionsRelatedWith").replace(
            "!@#$%",
            `‘${keyword}’`
          )}`,
          ATTRACTION_SK
        )}
      {state.articles.length > 0 &&
        renderList(
          state.articles,
          `${translate("searchResultScreen.articlesRelatedWith").replace(
            "!@#$%",
            `‘${keyword}’`
          )}`,
          ARTICLE_SK
        )}
      {state.restaurants.length > 0 &&
        renderBusinessList(
          state.restaurants,
          `${translate("searchResultScreen.restaurantsThatMatchBy").replace(
            "!@#$%",
            `‘${keyword}’`
          )}`,
          RESTAURANT_SK
        )}
      {/* {state.users.length > 0 &&
        renderList(
          state.users,
          `${translate(
            'searchResultScreen.usersThatMatchBy',
          ).replace('!@#$%', `‘${keyword}’`)}`,
          USER_SK,
        )} */}
      {state.hotels.length > 0 &&
        renderBusinessList(
          state.hotels,
          `${translate("searchResultScreen.hotelsThatMatchBy").replace(
            "!@#$%",
            `‘${keyword}’`
          )}`,
          HOTEL_SK
        )}
      {/* {state.posts.length > 0 &&
        renderList(
          state.posts,
          `${translate(
            'searchResultScreen.postsThatMatchBy',
          ).replace('!@#$%', `‘${keyword}’`)}`,
          POST_SK,
        )} */}
      {!isNoResult && <BusinessSuggestion flag={true} />}
    </Box >
  );
};

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(mapStateToProps, null)(withRouter(AllResultTab));
