import React from "react";
import { Button, ButtonGroup, Typography, Rating, Stack } from "@mui/material";
import { translate } from "../../../i18n";
import styles from "../AdvancedSearch.module.scss";

const ratingButtons = [0, 2, 3, 4, 5];

const RatingButtonGroup = ({ rating, handleChange }) => {
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography id={styles["listing-filter-title"]}>
          {translate("common.rating")}
        </Typography>
        <Rating readOnly size="small" value={rating} />
      </Stack>
      <ButtonGroup fullWidth size="small" id={styles["rating-button-group"]}>
        {ratingButtons.map((value, key) => (
          <Button
            key={key}
            id={rating === value ? styles["button-active"] : styles["button"]}
            onClick={() => {
              handleChange(value);
            }}
          >
            {value === 0 ? translate("common.all") : value}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

export default RatingButtonGroup;
