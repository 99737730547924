import React, { useEffect, } from 'react';
import 'react-slideshow-image/dist/styles.css';
import {
  USER_SK,
  BUSINESS_SK,
} from '../../constants';
import UserMediaGrid from './UserMediaGrid';
import BusinessMediaGrid from './BusinessMediaGrid';

const MediaGrid = ({
  userDetails,
  media,
}) => {
  const type = userDetails.type === BUSINESS_SK ? userDetails.subType : USER_SK;

  useEffect(() => {
    playPauseVideo();
  }, []);

  function playPauseVideo() {
    let videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      // We can only control playback without insteraction if video is mute
      video.muted = true;
      // Play is a promise so we need to check we have it
      //let playPromise = video.play();
      //if (playPromise !== undefined) {
      //playPromise.then((_) => {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (
              entry.intersectionRatio !== 1 &&
              !video.paused
            ) {
              video.pause();
            }
          });
        },
        { threshold: 0.2 }
      );
      observer.observe(video);
      //});
      //}
    });
  }

  return (
    <>
      {type === USER_SK
        ? <UserMediaGrid
          userDetails={userDetails}
          media={media}
        />
        : <BusinessMediaGrid
          media={media}
          type={type}
          userDetails={userDetails}
        />
      }
    </>
  )
}

export default MediaGrid;