import { Status } from '../../api';
import {
    FETCH_WEATHER_LOADING,
    FETCH_WEATHER_SUCCESS,
    FETCH_WEATHER_ERROR,
    FETCH_EXCHANGE_RATE_LOADING,
    FETCH_EXCHANGE_RATE_SUCCESS,
    FETCH_EXCHANGE_RATE_ERROR,
    FETCH_SOURCE_LOADING,
    FETCH_SOURCE_SUCCESS,
    FETCH_SOURCE_ERROR,
    FETCH_DESTINATION_LOADING,
    FETCH_DESTINATION_SUCCESS,
    FETCH_DESTINATION_ERROR,
    FETCH_ROUTE_LOADING,
    FETCH_ROUTE_SUCCESS,
    FETCH_ROUTE_ERROR,
    FETCH_SERVICES_LOADING,
    FETCH_SERVICES_SUCCESS,
    FETCH_SERVICES_ERROR,
    FETCH_CALCULATIONS_LOADING,
    FETCH_CALCULATIONS_SUCCESS,
    FETCH_CALCULATIONS_ERROR,

    RESET_DATA,
    FETCH_HIGHWAY_CONTACT_LOADING,
    FETCH_HIGHWAY_CONTACT_ERROR,
    FETCH_HIGHWAY_CONTACT_SUCCESS

} from '../../constants';

const initialState = {
    // =========================================================
    // ==================== Weather ============================
    // =========================================================
    weatherStatus: Status.DEFAULT,
    weatherErrorMessage: '',
    weatherData: null,
    // =========================================================
    // ==================== Exchange Rate ======================
    // =========================================================
    exchangeRateStatus: Status.DEFAULT,
    exchangeRateErrorMessage: '',
    exchangeRateData: null,
    // =========================================================
    // ==================== Highway Contacts ======================
    // =========================================================
    highwayContactsStatus: Status.DEFAULT,
    highwayContactsErrorMessage: '',
    highwayContactsData: null,
    // =========================================================
    // ==================== Travel Money Calculator ============
    // =========================================================
    sourceStatus: Status.DEFAULT,
    sourceErrorMessage: '',
    sources: [],
    destinationStatus: Status.DEFAULT,
    destinationErrorMessage: '',
    destinations: [],
    routeStatus: Status.DEFAULT,
    routeErrorMessage: '',
    routes: [],
    accommodationLists: [],
    activitiesLists: [],
    mealPlansLists: [],
    transportationsLists: [],
    calculations: '',
    calculationsStatus: Status.DEFAULT,

}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_WEATHER_LOADING:
            return {
                ...state,
                weatherStatus: Status.LOADING,
                weatherErrorMessage: '',
            };
        case FETCH_WEATHER_ERROR:
            return {
                ...state,
                weatherStatus: Status.ERROR,
                weatherErrorMessage: payload.errorMessage,
            }
        case FETCH_WEATHER_SUCCESS:
            const { weatherData, } = payload;
            return {
                ...state,
                weatherStatus: Status.SUCCESS,
                weatherErrorMessage: '',
                weatherData: weatherData,
            }
        case FETCH_EXCHANGE_RATE_LOADING:
            return {
                ...state,
                exchangeRateStatus: Status.LOADING,
                exchangeRateErrorMessage: '',
            };
        case FETCH_EXCHANGE_RATE_ERROR:
            return {
                ...state,
                exchangeRateStatus: Status.ERROR,
                exchangeRateErrorMessage: payload.errorMessage,
            }
        case FETCH_EXCHANGE_RATE_SUCCESS:
            const { exchangeRateData, } = payload;
            return {
                ...state,
                exchangeRateStatus: Status.SUCCESS,
                exchangeRateErrorMessage: '',
                exchangeRateData: exchangeRateData,
            }
        case FETCH_HIGHWAY_CONTACT_LOADING:
            return {
                ...state,
                highwayContactsStatus: Status.LOADING,
                highwayContactsErrorMessage: '',
            };
        case FETCH_HIGHWAY_CONTACT_ERROR:
            return {
                ...state,
                highwayContactsStatus: Status.ERROR,
                highwayContactsErrorMessage: payload.errorMessage,
            };
        case FETCH_HIGHWAY_CONTACT_SUCCESS:
            const { highwayContactsData, } = payload;
            return {
                ...state,
                highwayContactsStatus: Status.SUCCESS,
                highwayContactsErrorMessage: '',
                highwayContactsData: highwayContactsData,
            };
        case FETCH_SOURCE_LOADING:
            return {
                ...state,
                sourceStatus: Status.LOADING,
                sourceErrorMessage: '',
            };
        case FETCH_SOURCE_ERROR:
            return {
                ...state,
                sourceStatus: Status.ERROR,
                sourceErrorMessage: payload.errorMessage,
            }
        case FETCH_SOURCE_SUCCESS:
            const { sources, } = payload;

            return {
                ...state,
                sourceStatus: Status.SUCCESS,
                sourceErrorMessage: '',
                sources: sources,
            }
        case FETCH_DESTINATION_LOADING:
            return {
                ...state,
                destinationStatus: Status.LOADING,
                destinationErrorMessage: '',
            };
        case FETCH_DESTINATION_ERROR:
            return {
                ...state,
                destinationStatus: Status.ERROR,
                destinationErrorMessage: payload.errorMessage,
            }
        case FETCH_DESTINATION_SUCCESS:
            const { destinations, } = payload;
            return {
                ...state,
                destinationStatus: Status.SUCCESS,
                destinationErrorMessage: '',
                destinations: destinations,
            }
        case FETCH_ROUTE_LOADING:
            return {
                ...state,
                routeStatus: Status.LOADING,
                routeErrorMessage: '',
            };
        case FETCH_ROUTE_ERROR:
            return {
                ...state,
                routeStatus: Status.ERROR,
                routeErrorMessage: payload.errorMessage,
            }
        case FETCH_ROUTE_SUCCESS:
            const { routes, } = payload;
            return {
                ...state,
                routeStatus: Status.SUCCESS,
                routeErrorMessage: '',
                routes: routes,
            }
        case FETCH_CALCULATIONS_LOADING:
            return {
                ...state,
                calculationsStatus: Status.LOADING,
                calculationsErrorMessage: '',
            };
        case FETCH_CALCULATIONS_ERROR:
            return {
                ...state,
                calculationsStatus: Status.ERROR,
                calculationsErrorMessage: payload.errorMessage,
            }
        case FETCH_CALCULATIONS_SUCCESS:
            const { calculations } = payload;
            return {
                ...state,
                calculationsStatus: Status.SUCCESS,
                calculationsErrorMessage: '',
                calculations: calculations,
            }
        case FETCH_SERVICES_LOADING:
            return {
                ...state,
                servicesStatus: Status.LOADING,
                servicesErrorMessage: '',
            };
        case FETCH_SERVICES_ERROR:
            return {
                ...state,
                servicesStatus: Status.ERROR,
                servicesErrorMessage: payload.errorMessage,
            }
        case FETCH_SERVICES_SUCCESS:
            const { services } = payload;
            return {
                ...state,
                servicesStatus: Status.SUCCESS,
                servicesErrorMessage: '',
                accommodationLists: services.accommodations,
                activitiesLists: services.activities,
                mealPlansLists: services.mealPlans,
                transportationsLists: services.transportations,
            }
        case RESET_DATA:
            return {
                ...state,
                destinations: [],
                routes: [],
                accommodationLists: [],
                activitiesLists: [],
                mealPlansLists: [],
                transportationsLists: [],
                calculations: '',
                calculationsStatus: Status.DEFAULT,
            };
        default:
            return state;
    }
}
