import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { USER_SK } from '../../../../../../constants';
import { isNonEmptyString } from '../../../../../../utils';
import { updateBloggerNetworkStatus } from '../../../../../../store/actions';
import { withRouter } from 'react-router';

const BloggerListItem = ({
  item,
  baseMediaUrl,
  baseMediaThumbUrl,
  loggedInUserIdentifier,
  updateBloggerNetworkStatus: _updateBloggerNetworkStatus,
  showLoginPrompt: _showLoginPrompt,
  history,
}) => {

  const [followStatus, setFollowStatus] = useState({
    localValue: item.followingStatus, // Value which gets change as user interact with PostListItem
    backupValue: item.followingStatus, // Value use to check for latest value from redux
  });
  const [friendStatus, setFriendStatus] = useState({
    localValue: item.friendStatus, // Value which gets change as user interact with PostListItem
    backupValue: item.friendStatus, // Value use to check for latest value from redux
  });

  useEffect(() => {
    const newFollowStatusValue = item.followingStatus;
    if (followStatus.backupValue !== newFollowStatusValue) {
      setFollowStatus({
        localValue: newFollowStatusValue,
        backupValue: newFollowStatusValue,
      });
    }
    const newFriendStatusValue = item.friendStatus;
    if (friendStatus.backupValue !== newFriendStatusValue) {
      setFriendStatus({
        localValue: newFriendStatusValue,
        backupValue: newFriendStatusValue,
      });
    }
  }, [item.followingStatus, item.friendStatus,]); // eslint-disable-line react-hooks/exhaustive-deps

  const openProfile = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    history.push(`/${USER_SK}?id=${btoa(item.userIdentifier)}`);
  }

  return (
    <div className='blogger-item' onClick={openProfile}>
      <img
        alt='img'
        className='blogger-image'
        src={item.profileImg.isThumbGenerated ? item.profileImg.url.thumbUrl : item.profileImg.url.originalUrl}
      />
      <div className='blogger-description'>
        <div className='name'>
          <span> {item.userName} </span>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateBloggerNetworkStatus, })(withRouter(BloggerListItem));