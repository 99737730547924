import { fork } from 'redux-saga/effects';
import appSagas from './app/appSagas';
import homeSagas from './home/homeSagas';
import articlesSagas from './articles/articlesSagas';
import contractsSagas from './contracts/contractsSagas';
import bloggersSagas from './bloggers/bloggersSagas';
import servicesSagas from './services/servicesSagas';
import authSagas from './auth/authSagas';
import profileSagas from './profile/profileSagas'
import attractionSagas from './attraction/attractionSagas';
import businessSagas from './business/businessSagas';
// import postSagas from './addPost/postSagas';

/**
 * rootSaga
 */
 export default function* root() {
    yield fork(appSagas);
    yield fork(homeSagas);
    yield fork(articlesSagas);
    yield fork(contractsSagas);
    yield fork(bloggersSagas);
    yield fork(servicesSagas);
    yield fork(authSagas);
    yield fork(profileSagas);
    yield fork(attractionSagas);
    yield fork(businessSagas);
    // yield fork(postSagas);
 }