import React, { useEffect, useState } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { FilterFormControl, RatingButtonGroup } from ".";
import { translate } from "../../../i18n";
import { api } from "../../../api";
import styles from "../AdvancedSearch.module.scss";

const FilterCard = ({
  state,
  city,
  rating,
  handleStateChange: _handleStateChange,
  handleCityChange: _handleCityChange,
  handleRatingChange: _handleRatingChange,
}) => {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    getStateList();
    if (state) {
      refreshCityList(state)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getStateList = () => {
    api
      .fetchStateList()
      .then(({ userResponse }) => {
        setStateList(userResponse);
      })
      .catch(() => { });
  };

  const getCityList = (state) => {
    api
      .fetchCityList({ stateName: state })
      .then(({ userResponse }) => {
        setCityList(userResponse);
      })
      .catch(() => { });
  };

  const handleStateChange = (e) => {
    let value = e.target.value;
    _handleStateChange(value);
    refreshCityList(value);
  };

  const refreshCityList = (value) => {
    _handleCityChange(-1);
    setCityList([]);
    if (value !== -1) {
      getCityList(value);
    }
  };

  return (
    <Card id={styles["listing-filter-card"]}>
      <Grid container spacing={1} direction="column">
        <Grid item xs>
          <Typography id={styles["listing-filter-title"]}>
            {translate("advancedSearch.filterByLocations")}
          </Typography>
        </Grid>
        <Grid item xs>
          <FilterFormControl
            value={state}
            label={translate("advancedSearch.selectState")}
            menuList={stateList}
            handleChange={handleStateChange}
          />
        </Grid>
        <Grid item xs>
          <FilterFormControl
            disabled={cityList.length === 0}
            value={city}
            label={translate("advancedSearch.selectCity")}
            menuList={cityList}
            handleChange={(e) => _handleCityChange(e.target.value)}
          />
        </Grid>
        <Grid item xs />
        <Grid item xs>
          <RatingButtonGroup
            rating={rating}
            handleChange={_handleRatingChange}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default FilterCard;
