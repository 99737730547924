import React from 'react';
import Grid from '@mui/material/Grid';
import Weather from './Weather/Weather';
import ExchangeRate from './ExchangeRate/ExchangeRate';
import TravelMoneyCalculator from './TravelMoneyCalculator/TravelMoneyCalculator';
import HighwayContacts from './HighwayContacts/HighwayContacts';
import styles from '../../Home.module.scss';
const ShalgoerServices = () => {
  return (
    <div className={styles['for-shalgoers-container']}>
      <Grid
        container
        direction={{
          xs: 'row',
          sm: 'column',
        }}
        rowSpacing={2}
        columnSpacing={2}
        alignItems="center"
      >
        <Grid item xs={3} sm={12}>
          <Weather />
        </Grid>
        <Grid item xs={3} sm={12}>
          <ExchangeRate />
        </Grid>
        <Grid item xs={3} sm={12}>
          <HighwayContacts />
        </Grid>
        <Grid item xs={3} sm={12}>
          <TravelMoneyCalculator />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShalgoerServices;
