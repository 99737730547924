import React from 'react';

const Tab =() =>{
    return <>&nbsp;&nbsp;</>
}
const OneLineBreak =()=>{
    return <br/>
}
const TwoLineBreak = () => {
    return <><br/><br/></>
}
const ThreeLineBreak = () =>{
    return <><br/><br/><br/></>
}

const Space = () =>{
    return <>&nbsp;</>
}

export { Tab, OneLineBreak, TwoLineBreak, ThreeLineBreak, Space }