import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import store from './store';
import Router from './router';
import './css/main.scss';

ReactDOM.render(
  <StoreProvider store={store}>
    <Router />
  </StoreProvider>,
  document.getElementById('root')
);
