import React from 'react';
import { CssBaseline } from '@mui/material';
import {
  AppBar,
  BackIcon,
  BackToTopIcon,
//   CreatePostIcon,
} from '..';

const Tools = () => {
  return (
    <>
      <div id='back-to-top-anchor' />
      <CssBaseline />
      <AppBar />
      <BackIcon />
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
    </>
  );
}

export default Tools;