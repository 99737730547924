import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { api, Status } from '../../api';
import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { translate } from '../../i18n';
import { BusinessSuggestion, NoResult, SearchListItem } from './components';
import { HOTEL_SK, RESTAURANT_SK, USER_SK } from '../../constants';
import { withRouter } from 'react-router';
import styles from './SearchResult.module.scss';
const FilterResultTab = ({
  type,
  isLoggedIn,
  /**
   * Base url need to append with image url to generate full url
   *
   * @source redux
   */
  baseMediaUrl,
  /**
   * Base url pefixed to filePath to generate
   * complete url for thumbnail version of image
   *
   * @source redux
   */
  baseMediaThumbUrl,
  showLoginPrompt,
  history,
  isSearchResult
}) => {
  const keyword = new URLSearchParams(window.location.search).get('q');

  const [state, setState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    {
      list: [],
      identifiersHashMap: {},
      apiStatus: Status.DEFAULT,
      moreApiStatus: Status.DEFAULT,
      contentAvailableForRefresh: true,
    }
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    refreshList(page);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMore = () => setPage((prevState) => prevState + 1);

  const refreshList = (_page = 1) => {
    if (_page === 1) {
      setState({
        apiStatus: Status.LOADING,
      });
    } else {
      setState({
        moreApiStatus: Status.LOADING,
      });
    }
    api
      .fetchSearchResult({
        keyword,
        type,
        page: _page,
      })
      .then(({ userResponse: { searchList } }) => {
        const newIdentifiersHashMap = { ...state.identifiersHashMap };
        const newList = searchList
          .filter((item) => {
            if (item.identifier in newIdentifiersHashMap) {
              return false;
            }
            newIdentifiersHashMap[item.identifier] = 1;
            return true;
          })
          .map((item) => ({ ...item, type }));
        if (_page === 1) {
          setState({
            list: newList,
            identifiersHashMap: newIdentifiersHashMap,
            contentAvailableForRefresh: !(newList.length < 10),
            apiStatus: Status.SUCCESS,
          });
        } else {
          setState({
            list: [...state.list, ...newList],
            identifiersHashMap: newIdentifiersHashMap,
            contentAvailableForRefresh: !(newList.length < 10),
            moreApiStatus: Status.SUCCESS,
          });
        }
      })
      .catch(() => {
        if (_page === 1) {
          setState({
            apiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        } else {
          setState({
            moreApiStatus: Status.ERROR,
            contentAvailableForRefresh: false,
          });
        }
      });
  };

  const loader = (
    <div className={styles['spinner']} style={{ paddingTop: '15px' }}>
      <CircularProgress size={22} className="loading_spinner" />
    </div>
  );

  const endMessage = (
    <div>
      <div className={state.apiStatus === Status.SUCCESS && state.list.length === 0 ? styles['nomore-data-info'] : styles['data-info']}>
        {state.apiStatus === Status.SUCCESS && state.list.length === 0 ? (
          (type === HOTEL_SK || type === RESTAURANT_SK) ? <NoResult /> : <NoResult showTitleA={true} />
        ) : (
          <>
            <CheckCircleOutlineIcon />
            <span className={styles['nomore-post-info']}>
              {translate('homeScreen.noMorePostInfo')}
            </span>
          </>
        )}
      </div>
      {(type === HOTEL_SK || type === RESTAURANT_SK) && (
        <>
          {!(state.apiStatus === Status.SUCCESS && state.list.length === 0) && <Divider />}
          <div className={(state.apiStatus === Status.SUCCESS && state.list.length === 0) ? styles['business-suggestion'] : styles['business-suggestion-data']}>
            {state.apiStatus === Status.SUCCESS && state.list.length === 0
              ? <BusinessSuggestion showTitle={true} />
              : <BusinessSuggestion showTitle={false} />
            }
          </div>
        </>
      )}
    </div>
  );

  const renderList = () => (
    <Grid container spacing={2}>
      {state.list.map((item, key) => (
        <Grid
          item
          key={key}
          xs={type === USER_SK ? 6 : 12}
          sm={type === USER_SK ? 4 : 12}
          md={type === USER_SK ? 3 : 12}
        >
          <SearchListItem
            type={type}
            item={item}
            isLoggedIn={isLoggedIn}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaThumbUrl}
            showLoginPrompt={showLoginPrompt}
            history={history}
            isSearchResult={isSearchResult}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <InfiniteScroll
      dataLength={state.list.length} //This is important field to render the next data
      next={
        state.contentAvailableForRefresh &&
        state.apiStatus !== Status.LOADING &&
        state.moreApiStatus !== Status.LOADING &&
        loadMore
      }
      hasMore={state.contentAvailableForRefresh}
      loader={loader}
      endMessage={endMessage}
    >
      <Box>{renderList()}</Box>
    </InfiniteScroll>
  );
};

const mapStateToProps = ({ app }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
  };
};

export default connect(mapStateToProps, null)(withRouter(FilterResultTab));
