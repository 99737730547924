import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './Spinner.module.scss';

const Spinner = ({ size = 40 }) => {
  return (
    <div className={styles['details-page-spinner']}>
      <CircularProgress size={size} className={styles.icon} />
    </div>
  );
};

export default Spinner;
