import { 
  FETCH_ATTRACTION_DETAILS_REQUEST,
  RESET_FETCH_ATTRACTION_DETAILS,
  FETCH_REVIEWS_REQUEST,
  FETCH_ARTICLES_BY_ATTRACTION_REQUEST,
  FETCH_All_ATTRACTION_FILES_REQUEST,
  SHARE_POST_REQUEST
 } from '../../constants';

export const resetAttractionDetailsState = () => ({
  type: RESET_FETCH_ATTRACTION_DETAILS,
});

 export const fetchAttractionDetails = (attractionIdentifier) => ({
    type: FETCH_ATTRACTION_DETAILS_REQUEST,
    payload: {
      attractionIdentifier,
    },
  });
  
  export const fetchReviews = ( type, identifier, page , sortOrder,
    fromDate = '',
    toDate = '',) => ({
    type: FETCH_REVIEWS_REQUEST,
    payload: {
      type, 
      identifier, 
      page, 
      sortOrder,
      fromDate,
      toDate
    },
  });

  export const fetchArticlesByAttraction = (attractionIdentifier) => ({
    type : FETCH_ARTICLES_BY_ATTRACTION_REQUEST,
    payload:{
      attractionIdentifier
    }
  });
  export const fetchAllAttractionFiles = (attractionIdentifier) => ({
    type : FETCH_All_ATTRACTION_FILES_REQUEST,
    payload:{
      attractionIdentifier
    }
  })
  /**
 * Dispatch an action containing data related to new shared post
 * which will be stored in post list of home reducer
 *
 * @param {object} sharedPost  - Data pertaining to shared post
 */
export const shareEntity = (type, visibility, identifier, description) => ({
  type: SHARE_POST_REQUEST,
  payload: {
    type, 
    visibility, 
    identifier, 
    description
  },
});



 