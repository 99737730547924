import React, { useEffect, useState } from "react";
// import TwitterLogin from 'react-twitter-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import facebook from "../../../assets/authentication/facebook.png";
import google from "../../../assets/authentication/google.png";
import {
  APPLE_SK,
  EMAIL_SK,
  FACEBOOK_SK,
  // FACEBOOK_SK,
  GOOGLE_SK,
  LOCAL_IP_ADDRESS,
} from "../../../constants";
import { getBuildConfig } from "../../../config/buildConfig";
import { socialLogin } from "../../../store/actions";
import { connect } from "react-redux";
import { gapi } from "gapi-script";
import styles from "../Authentication.module.scss";
import { publicIpv4 } from "public-ip";
import AppleSignInAuth from "react-apple-signin-auth";

const FACEBOOK_APP_ID = getBuildConfig().facebookAppId;
const GOOGLE_CLIENT_ID = getBuildConfig().googleClientId;
const APPLE_CLIENT_ID = getBuildConfig().appleClientId;
const APPLE_REDIRECT_URI = getBuildConfig().appleRedirectUri;
// const CONSUMER_KEY = getBuildConfig().twitterConsumerKey;
// const CONSUMER_SECRET = getBuildConfig().twitterConsumerSecret;
const SocialLogin = ({
  /**
   * redux action to initiate social login api request
   *
   * @source: redux
   */
  socialLogin: _socialLogin,
}) => {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    getIpaddress();
  }, []);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const getIpaddress = async () => {
    try {
      const ip = await publicIpv4();
      setIpAddress(ip);
    } catch (error) {
      // Handle the error, and provide a default IP address if needed
      console.error("Error fetching IP address:", error);
      setIpAddress(LOCAL_IP_ADDRESS);
    }
  };

  const facebookLoginResponse = ({ accessToken }) => {
    accessToken && _socialLogin(FACEBOOK_SK, accessToken, ipAddress);
  };

  const googleLoginResponse = ({ tokenId }) => {
    tokenId && _socialLogin(GOOGLE_SK, tokenId, ipAddress);
  };

  const googleLoginFailure = (error) => {
    console.log(error);
  };

  return (
    <ul className={styles["social-ul"]}>
      {/* <li className={classes.social_li}> */}
      {/* <TwitterLogin
          consumerKey={CONSUMER_KEY}
          consumerSecret={CONSUMER_SECRET}
          authCallback={twitterLoginResponse}
          className={classes.twitterWrap}
        > */}
      {/* <img
            alt='img'
            src={twitter}
            className={classes.twitter}
          /> */}
      {/* </TwitterLogin> */}
      {/* </li> */}

      <li className={styles["social-li"]}>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields={EMAIL_SK}
          callback={facebookLoginResponse}
          render={(renderProps) => (
            <img
              alt="fb-icon"
              src={facebook}
              className={styles["facebook-icon"]}
              onClick={renderProps.onClick}
            />
          )}
        />
      </li>
      <li className={styles["social-li"]}>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          onSuccess={googleLoginResponse}
          onFailure={googleLoginFailure}
          autoLoad={false}
          render={(renderProps) => (
            <img
              alt="google-icon"
              src={google}
              className={styles["google"]}
              onClick={renderProps.onClick}
            />
          )}
        />
      </li>
      <li className={styles["social-li"]}>
        {APPLE_CLIENT_ID && APPLE_REDIRECT_URI && (
          <>
            <AppleSignInAuth
              authOptions={{
                clientId: APPLE_CLIENT_ID,
                scope: "email name",
                redirectURI: APPLE_REDIRECT_URI,
                state: "state",
                nonce: "nonce",
                usePopup: true,
                response_mode: "query",
              }}
              onSuccess={(response) => {
                _socialLogin(
                  APPLE_SK,
                  response?.authorization?.id_token,
                  ipAddress
                );
              }}
              className={styles["apple_btn"]}
              onError={(error) => {
                console.error(error);
              }}
              uiType="light"
              buttonExtraChildren=""
              // skipScript={false}
            ></AppleSignInAuth>
          </>
        )}
      </li>
    </ul>
  );
};

export default connect(null, {
  socialLogin,
})(SocialLogin);
