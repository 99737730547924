import React from 'react';
import { withRouter } from 'react-router';
import {
  ThemeProvider,
  createTheme,
  Card,
  CardHeader,
  Avatar,
  Badge,
} from '@mui/material';
import {
  BLOGGER_SK,
} from '../../../../../constants';
import bloggerMarkIcon from '../../../../../assets/images/icon_blogger_mark.png';

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
        }
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: 'center',
        },
        title: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '18px',
          color: '#010D18',
          lineHeight: 1,
        },
      },
    },
  }
});

const ItemCard = ({
  item,
  baseMediaUrl,
  baseMediaThumbUrl,
  action,
  reload: _reload,
  history,
}) => {
  const profileImgUrl = item.profileImg.isThumbGenerated
    ? item.profileImg.url.thumbUrl
    : item.profileImg.url.originalUrl;

  const openProfile = () => {
    history.replace(`/user?id=${btoa(item.identifier)}`);
    _reload(item.identifier);
  }

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardHeader
          avatar={
            item.userRole === BLOGGER_SK
              ? <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                badgeContent={<img
                  id='frilist-profile-badge'
                  alt='badgeimg'
                  src={bloggerMarkIcon}
                />}
              >
                <Avatar
                  id='item-card-blogger-avatar'
                  src={profileImgUrl}
                  onClick={openProfile}
                />
              </Badge>
              : <Avatar
                id='item-card-avatar'
                src={profileImgUrl}
                onClick={openProfile}
              />
          }
          action={action}
          title={
            <span
              id='friend-name'
              style={{ cursor: 'pointer' }}
              onClick={openProfile}
            >
              {item.name}
            </span>
          }
        />
      </Card>
    </ThemeProvider>
  );
};

export default withRouter(ItemCard);