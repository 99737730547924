import React from "react";
import styles from './Unsave.module.scss';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { IconButton } from "@mui/material";
import { deleteFavourite } from "../../store/actions";
import { useDispatch } from "react-redux";

const Unsave = ({
    item,
    successCallback: _successCallback
}) => {
    const dispatch = useDispatch();

    const unsave = (unsaveItem) => {
        const identifier = unsaveItem?.businessIdentifier ?? unsaveItem?.articleIdentifier ?? unsaveItem?.attractionIdentifier ?? unsaveItem?.postIdentifier;
        _successCallback(unsaveItem);
        dispatch(deleteFavourite(
            unsaveItem?.favouriteUuid,
            unsaveItem?.entityType,
            identifier)
        );
    }

    return (
        <IconButton className={styles["icon-button"]} onClick={() => unsave(item)}>
            <BookmarkIcon className={styles["unsave-icon-style"]} />
        </IconButton>
    )
}

export default Unsave;