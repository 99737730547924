import {
  EDIT_PROFILE_REQUEST,
  RESET_EDIT_PROFILE_STATE,
  GET_USER_DETAILS_REQUEST,
  UPLOAD_PROFILE_IMAGE_REQUEST,
  UPLOAD_COVER_IMAGE_REQUEST,
  PROFILE_FILE_DIR_SK,
  UPDATE_PHOTO,
  FETCH_USER_NETWORK_REQUEST,
  RESET_UPDATE_MEMBER_NETWORK_STATUS,
  RESET_UPLOAD_PROFILE_IMAGE,
  RESET_INCOMPLETE_USER_DETAILS_STATE,
  FETCH_FAVOURITES_REQUEST,
  UPDATE_FAVOURITES
} from '../../constants';

/**
 * Initiate user details api request.
 *
 * @param {string} userIdentifier  - unquie id of the logged in user
 */
export const getUserDetails = userIdentifier => ({
  type: GET_USER_DETAILS_REQUEST,
  payload: {
    userIdentifier,
  },
});

export const editProfile = (userDetailsObjectsToBeUpdate, userName) => ({
  type: EDIT_PROFILE_REQUEST,
  payload: {
    userDetailsObjectsToBeUpdate,
    userName
  },
});

/**
* It Rests the editProfileStatus back to Status.DEFAULT
*/
export const resetEditProfileState = () => ({
  type: RESET_EDIT_PROFILE_STATE,
});

/**
* Resets the incomplete user details state to its default value.
*/
export const resetIncompleteUserDetailsState = () => ({
  type: RESET_INCOMPLETE_USER_DETAILS_STATE,
})

/**
 *  Upload profile image action
 * @param image
 * @return {{type: string, payload: {image: *}}}
 */
export const uploadProfileImage = image => ({
  type: UPLOAD_PROFILE_IMAGE_REQUEST,
  payload: { image },
});

export const resetUploadProfileImage = () => ({
  type: RESET_UPLOAD_PROFILE_IMAGE,
});

/**
 *  Upload cover image action
 * @param image
 * @return {{type: string, payload: {image: *}}}
 */
export const uploadCoverImage = image => ({
  type: UPLOAD_COVER_IMAGE_REQUEST,
  payload: { image },
});

export const updatePhoto = (fileDirectory = PROFILE_FILE_DIR_SK, image, statusMsg) => ({
  type: UPDATE_PHOTO,
  payload: {
    fileDirectory,
    image,
    statusMsg,
  },
});

/**
 * Initiate fetch user network api request.
 *
 * @param {string} networkStatus  - network status e.g. friend, blocked, friendRequestPending, following etc.
 * @param {number} page           - page number of the user network list to be fetched
 */
export const fetchUserNetwork = (networkStatus, page = 1) => ({
  type: FETCH_USER_NETWORK_REQUEST,
  payload: {
    networkStatus,
    page,
  },
});
/**
 * dispatch reset_update_member_network_status action.
 */
export const resetUpdateMemberNetworkStatus = () => ({
  type: RESET_UPDATE_MEMBER_NETWORK_STATUS,
});

export const fetchFavourites = (filter, page) => ({
  type: FETCH_FAVOURITES_REQUEST,
  payload: {
    filter,
    page
  },
});

export const updateFavourites = (unsavedItem) => ({
  type: UPDATE_FAVOURITES,
  payload: {
    unsavedItem
  }
})