import React, { useEffect, useState } from "react";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import {
  ALBUM_SK,
  CURRENT_USER_LK,
  PRIVATE_SK,
  SERVER_ERROR_CODES,
  SLIDER_PHOTO_SK,
  VIDEO_SK,
} from "../../../../constants";
import { api, Status } from "../../../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { translate } from "../../../../i18n";
import PhotosGrid from "./PhotosGrid";
import CreateAlbumModal from "./CreateAlbumModal";
import createAlbumIcon from "../../../../assets/images/icon_create_album.png";
import defaultVedioThumb from "../../../../assets/images/default_video_thumb.jpg";
import styles from "./AlbumsTab.module.scss";
import cn from "classnames";
const CreateAlbumPalette = ({ loggedInUserIdentifier, updateAlbumList }) => {
  const [isOpen, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={styles["album-item"]} onClick={openModal}>
        <div id={styles["shade-1"]} className={styles.shade} />
        <div id={styles["shade-2"]} className={styles.shade} />
        <div id={styles["shade-3"]} className={styles.shade} />
        <div className={styles.album}>
          <div id={styles["create-icon"]}>
            <img alt="create-img" src={createAlbumIcon} />
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div id={styles["create-album-label"]}>
          {translate("userProfile.createAlbum")}
        </div>
      </div>
      <CreateAlbumModal
        open={isOpen}
        close={closeModal}
        loggedInUserIdentifier={loggedInUserIdentifier}
        updateAlbumList={updateAlbumList}
      />
    </div>
  );
};

const AlbumsTab = ({
  identifier,
  entityType,
  subType,
  profileType,
  loggedInUserIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  profileImgUrl,
  userRole,
  businessName,
  isSliderClicked,
  setIsSliderClicked,
  sliderAlbumIdentifier,
  isPrivate: _isPrivate,
}) => {
  const [page, setPage] = useState(1);
  const [contentAvailableForRefresh, setContentAvailableForRefresh] = useState(
    _isPrivate !== PRIVATE_SK
  );
  const [albums, setAlbums] = useState([]);
  const [albumApiStatus, setAlbumApiStatus] = useState(Status.DEFAULT);
  const [detailsMode, toggleDetailsMode] = useState({
    mode: "default",
    identifier: "",
    albumName: "",
  });
  const [isPrivate, setIsPrivate] = useState(_isPrivate === PRIVATE_SK);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPrivate || profileType === CURRENT_USER_LK) {
      getAlbums(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSliderClicked) {
      toggleDetailsMode({
        mode: "sliderDetails",
        identifier: sliderAlbumIdentifier,
        albumName: SLIDER_PHOTO_SK,
      });
    }
  }, [isSliderClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAlbums = (page = 1) => {
    if (albumApiStatus !== Status.LOADING) {
      setAlbumApiStatus(Status.LOADING);
      api
        .fetchMedia({
          mediaType: ALBUM_SK,
          entityType: entityType,
          entityIdentifier: identifier,
          page,
        })
        .then(({ userResponse: { album: newAlbums } }) => {
          setAlbumApiStatus(Status.SUCCESS);
          if (newAlbums.length < 10) {
            setContentAvailableForRefresh(false);
          }

          //show blank albums if user is owner
          let _newAlbums = [];
          if (profileType !== CURRENT_USER_LK) {
            newAlbums.forEach((album) => {
              if (album.fileCount !== 0) {
                _newAlbums.push(album);
              }
            });
          }
          let allAlbums =
            profileType === CURRENT_USER_LK
              ? [...albums, ...newAlbums]
              : [...albums, ..._newAlbums];

          setAlbums(allAlbums);
        })
        .catch((error) => {
          setAlbumApiStatus(Status.ERROR);
          if (error.name === SERVER_ERROR_CODES.featurePrivate) {
            setIsPrivate(true);
            setContentAvailableForRefresh(false);
          }
        });
    }
  };

  const updateAlbumList = (newAlbum) => {
    // let _albums = _.clone(albums);
    // _albums.unshift(newAlbum);
    // setAlbums(_albums);
    setAlbums((prevState) => {
      return [newAlbum, ...prevState];
    });
  };

  const deleteAlbum = (albumIdentifier) => {
    let _albums = _.clone(albums);
    _albums.forEach((album, key) => {
      if (album.identifier === albumIdentifier) {
        _albums.splice(key, 1);
      }
    });
    setAlbums(_albums);
  };

  const setDeleteStatus = (albumIdentifier) => {
    let _albums = _.clone(albums);
    _albums.forEach((album, key) => {
      if (album.identifier === albumIdentifier) {
        album.isDeleting = true;
      }
    });
    setAlbums(_albums);
  };

  const handleAlbumClick = ({ identifier, albumName }) => {
    toggleDetailsMode({
      mode: "details",
      identifier,
      albumName,
    });
  };

  const updateAlbumName = (identifier, name) => {
    let _albums = _.clone(albums);
    _albums.forEach((album) => {
      if (album.identifier === identifier) {
        album.albumName = name;
      }
    });
    setAlbums(_albums);
  };

  const updateAlbumCount = (identifier, fileType, isDelete) => {
    let _albums = _.clone(albums);
    _albums.forEach((album) => {
      if (album.identifier === identifier) {
        if (isDelete) {
          fileType === VIDEO_SK
            ? (album.videoCount -= 1)
            : (album.imageCount -= 1);
        } else {
          fileType === VIDEO_SK
            ? (album.videoCount += 1)
            : (album.imageCount += 1);
        }
      }
    });
    setAlbums(_albums);
  };

  const updateAlbumFile = (identifier, newFile) => {
    setAlbums((prevState) => {
      return prevState.map((el) => {
        return {
          ...el,
          fileType:
            el.identifier === identifier ? newFile.fileType : el.fileType,
          filePath:
            el.identifier === identifier ? newFile.filePath : el.filePath,
        };
      });
    });
  };

  const goToAlbumList = () => {
    resetState();
  };

  const resetState = () => {
    toggleDetailsMode({
      mode: "default",
      identifier: "",
      albumName: "",
    });
    setIsSliderClicked(false);
  };

  const generatePreviewImage = (album) => {
    let filePath = album.isThumbGenerated
      ? album.filePath.thumbUrl
      : album.filePath.originalUrl;

    if (album.fileType === VIDEO_SK) {
      if (album.isThumbGenerated) {
        filePath = album.filePath.thumbUrl.replace(".mp4", ".jpg");
      } else {
        filePath = defaultVedioThumb;
      }
    }

    return filePath;
  };

  return (
    <>
      {detailsMode.mode === "default" ? (
        <div className={styles["albums-tab-container"]}>
          <InfiniteScroll
            dataLength={albums.length} //This is important field to render the next data
            next={() => setPage(page + 1)}
            hasMore={contentAvailableForRefresh}
            loader={
              <div className={styles.spinner}>
                <CircularProgress size={22} className="loading_spinner" />
              </div>
            }
            endMessage={
              <div className={styles["nomore-data-info"]}>
                {albums.length === 0 && albumApiStatus === Status.SUCCESS ? (
                  <span>{translate("businessProfile.noAlbum")}</span>
                ) : isPrivate && profileType !== CURRENT_USER_LK ? (
                  <span>{translate("userProfile.privateAlbums")}</span>
                ) : (
                  <span></span>
                )}
              </div>
            }
          >
            <div className={styles["albums-tab"]}>
              <Grid
                container
                columnSpacing={{
                  xs: 4,
                  sm: 8,
                }}
                rowSpacing={4}
              >
                {profileType === CURRENT_USER_LK && (
                  <Grid item xs={6}>
                    <CreateAlbumPalette
                      loggedInUserIdentifier={loggedInUserIdentifier}
                      updateAlbumList={updateAlbumList}
                    />
                  </Grid>
                )}
                {albums.map((album, key) => (
                  <Grid item xs={6} key={key}>
                    <div
                      className={styles["album-item"]}
                      onClick={() => handleAlbumClick(album)}
                    >
                      <div
                        id={styles["shade-1"]}
                        className={styles["shade"]}
                      ></div>
                      <div
                        id={styles["shade-2"]}
                        className={styles["shade"]}
                      ></div>
                      <div
                        id={styles["shade-3"]}
                        className={styles["shade"]}
                      ></div>
                      <div className={styles.album}>
                        {album.filePath && !album.isDeleting ? (
                          <img alt="img" src={generatePreviewImage(album)} />
                        ) : (
                          <div id={styles["loading-palette"]}>
                            <CircularProgress
                              size={22}
                              className="loading_spinner"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        album.isDeleting
                          ? cn(styles.info, styles.blur)
                          : styles.info
                      }
                    >
                      <h2 id={styles.name}>{album?.albumName}</h2>
                      <div id={styles.count}>
                        {album.imageCount !== 0 &&
                          `${album.imageCount} ${
                            album.imageCount > 1
                              ? translate("common.photos")
                              : translate("common.photo")
                          } `}
                        {album.videoCount !== 0 &&
                          `${album.videoCount} ${
                            album.videoCount > 1
                              ? translate("common.videos")
                              : translate("common.video")
                          } `}
                        {album.imageCount === 0 &&
                          album.videoCount === 0 &&
                          `${album.imageCount} ${translate("common.photo")}`}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <PhotosGrid
          mode={detailsMode.mode}
          entityType={entityType}
          subType={subType}
          profileType={profileType}
          identifier={detailsMode.identifier}
          loggedInUserIdentifier={loggedInUserIdentifier}
          baseMediaUrl={baseMediaUrl}
          baseMediaThumbUrl={baseMediaThumbUrl}
          profileImgUrl={profileImgUrl}
          userRole={userRole}
          businessName={businessName}
          albumName={detailsMode.albumName}
          albumDetailsModeOperations={{
            goToAlbumList,
            deleteAlbum,
            updateAlbumName,
            updateAlbumCount,
            setDeleteStatus,
            updateAlbumFile,
          }}
        />
      )}
    </>
  );
};

export default AlbumsTab;
