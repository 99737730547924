import { shape, string, number } from 'prop-types';

/**
 * Defines prop-types definition for a single article item
 * which is being displayed on HomeScreen
 */
const imageType = shape({
  url: string,
  isThumbGenerated: number,
});

export default imageType;
