import { Status } from '../../api';
import {
  FETCH_BUSINESS_DETAILS_LOADING,
  FETCH_BUSINESS_DETAILS_SUCCESS,
  FETCH_BUSINESS_DETAILS_ERROR,
} from '../../constants';

const initialState = {
  businessDetailsStatus: Status.DEFAULT,
  businessDetailsErrorMessage: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BUSINESS_DETAILS_LOADING:
      return {
        ...state,
        businessDetailsStatus: Status.LOADING,
        businessDetailsErrorMessage: '',
      };
    case FETCH_BUSINESS_DETAILS_SUCCESS: {
      return {
        ...state,
        businessDetailsStatus: Status.SUCCESS,
        bloggersErrorMessage: '',
      };
    }
    case FETCH_BUSINESS_DETAILS_ERROR:
      return {
        ...state,
        businessDetailsStatus: Status.ERROR,
        businessDetailsErrorMessage: payload.errorMessage,
      };
    default:
      return state;
  }
};
