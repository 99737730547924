import axios from 'axios';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { ACCESS_KEY, getBuildConfig } from '../config/buildConfig';
import { SERVER_ERROR_CODES } from '../constants';
import { getDate, isNonEmptyString, } from '../utils';
import Logger from './helpers/apiLogger';
import { getContentMD5, getAuthorization } from './helpers/headerUtils';
import { parseServerErrorResponse } from './helpers/handleError';
import { getCurrentLocale, } from '../i18n';
// import { networkConnectionAction } from '../store';
/**
 * Provide a layer over the axios library, to
 * configure request parameters before making a HTTP call.
 *
 * NOTE: This class shouln't be used directly,
 * use api object from api.js
 */
class Request {
  constructor() {
    const buildConfig = getBuildConfig();
    // The reason why we are not simply destructuring getBuildConfig
    // is to have clear picture what keys are present in configuration
    this.configuration = {
      host: buildConfig.host,
      baseUrl: buildConfig.baseUrl,
      baseAwsUrl: buildConfig.baseAwsUrl,
      basePath: buildConfig.basePath,
      hashSignKey: buildConfig.hashSignKey,
      accessKeyId: buildConfig.accessKeyId,
      secretAccesskey: buildConfig.secretAccesskey,
      apiTimeOverAllowance: buildConfig.apiTimeOverAllowance,
      awsSuccessRedirectUrl: buildConfig.awsSuccessRedirectUrl,
      awsXAmzCredential: buildConfig.awsXAmzCredential,
      awsXAmzAlgorithm: buildConfig.awsXAmzAlgorithm,
      weatherApiUrl: buildConfig.openWeatherMapApiUrl,
      weatherApiKey: buildConfig.openWeatherMapApiKey,
      centralBankMMApiUrl: buildConfig.centralBankMMApiUrl,
    };
  }

  /**
   * Initilization at app start up
   */
  init({
    deviceId,
    deviceName,
    sessionId = '',
    sessionExpiredAction = () => { },
    networkConnectionAction = () => { }
  }) {
    this.configuration.deviceId = deviceId;
    this.configuration.deviceName = deviceName;
    this.configuration.sessionId = sessionId;
    this.sessionExpiredAction = sessionExpiredAction;
    this.networkConnectionAction = networkConnectionAction;
  }

  post(path, params, data, isMultipart = false, headers) {
    return this.send({
      path,
      method: 'POST',
      params,
      data,
      isMultipart,
      headers,
    });
  }

  put(path, params, data) {
    return this.send({ path, method: 'PUT', params, data });
  }

  get(path, params, data, language = null) {
    return this.send({ path, method: 'GET', params, data, language });
  }

  delete(path, params, data) {
    return this.send({ path, method: 'DELETE', params, data });
  }

  send({
    /**
     * `path` is the api endpoint that will be appended
     * with base_url + root_path, to form complete url
     */
    path,
    /**
     * `method` is the HTTP request method to indicate
     * the desired action to be performed for a given resource
     *
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
     */
    method,
    /**
     * `params` are the URL GET parameters to be appended with the url.
     * Must be a plain object or a URLSearchParams object
     */
    params = {},
    /**
     * `data` is the data to be sent as the request body
     * Only applicable for request methods 'PUT', 'POST', and 'PATCH'
     * When no `transformRequest` is set, must be of one of the following types:
     * - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
     * - Browser only: FormData, File, Blob
     * - Node only: Stream, Buffer
     */
    data,
    /**
     *  true for multipart request
     */
    isMultipart = false,
    /**
     * Extra headers to be merged
     */
    headers: extraHeaders = {},
    language,
  }) {
    const url = isMultipart
      ? this.configuration.baseAwsUrl
      : `${this.configuration.baseUrl}${this.configuration.basePath
      }${path}${'?lang='}${language || getCurrentLocale()}`;

    // Do not modify data if it is multipart request
    if (!isMultipart) {
      if (method === 'GET') {
        // eslint-disable-next-line no-param-reassign
        params = {
          ...params,
          deviceId: this.configuration.deviceId,
          deviceName: this.configuration.deviceName,
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        data = {
          ...data,
          deviceId: this.configuration.deviceId,
          deviceName: this.configuration.deviceName,
        };
      }
    }

    const headers = isMultipart
      ? this.getMultipartHeaders(extraHeaders, method)
      : this.getHeaders(method, method === 'GET' ? params : data);

    const base64String = Base64.stringify(Utf8.parse(JSON.stringify(params)));
    const _params = method === 'GET' ? { data: base64String } : null;

    return new Promise((resolve, reject) => {
      Logger.describeRequest({ url, method, headers, params, data });
      axios({
        url,
        method,
        timeout: this.configuration.apiTimeOverAllowance,
        headers,
        params: _params,
        data,
      })
        .then(response => {
          Logger.describeSuccessResponse(response);
          resolve(response.data);
        })
        .catch(error => {
          Logger.describeErrorResponse(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const parsedError = parseServerErrorResponse(error);
            // eslint-disable-next-line eqeqeq
            if (parsedError.name === SERVER_ERROR_CODES.sessionExpired) {
              this.handleSessionExpire();
            }
            reject(parsedError);
          } else if (error.request) {
            /**
             * The request was made but no response was received
             * `error.request` is an instance of XMLHttpRequest in the browser
             */
            const msg = error?.message;
            if (isNonEmptyString(msg)) {
              this.handleNetworkConnection();
            }
          } else {
            // Something happened in setting up the request that triggered an Error
            // error.config is there
          }
          // handleThisError(3);
          reject(error);
        });
    });
  }

  /**
   * AWS multipart upload requires ddifferent set of headers
   *
   * @param {object} extraHeaders - extra keys to be sent with headers
   * @param method
   */
  getMultipartHeaders(extraHeaders, method) {
    // all file upload request will have same data
    const data = {
      deviceId: this.configuration.deviceId,
      deviceName: this.configuration.deviceName,
      accessKey: ACCESS_KEY,
    };

    const { iso8601Date, rfc2822Date } = getDate();
    const contentMd5 = getContentMD5(data);
    const contentType = 'multipart/form-data';
    const content = Base64.stringify(Utf8.parse(JSON.stringify(data)));

    return {
      'Content-MD5': contentMd5,
      'platform-origin': 'portal',
      content,
      'Auth-Date': rfc2822Date,
      'AMM-AUTHORIZATION': getAuthorization({
        method,
        contentMd5,
        contentType,
        date: iso8601Date,
        hashSignKey: this.configuration.hashSignKey,
        accessKeyId: this.configuration.accessKeyId,
        secretAccesskey: this.configuration.secretAccesskey,
      }),
      sessionToken: this.configuration.sessionId,
      ...extraHeaders,
    };
  }

  getHeaders(method, data) {
    //const date = serverSupportedFormatted(new Date()).split('-').join('');
    //const credential = this.configuration.awsXAmzCredential.replace('!@#$%', date);

    const { iso8601Date, rfc2822Date } = getDate();
    const contentMd5 = getContentMD5(data);
    const contentType = 'application/json';
    const dynamicKeys = {};
    if (this.configuration.sessionId) {
      dynamicKeys.sessionToken = this.configuration.sessionId;
    }
    return {
      'Content-Type': contentType,
      'Content-MD5': contentMd5,
      'platform-origin': 'portal',
      'Auth-Date': rfc2822Date,
      ...dynamicKeys,
      Authorization: getAuthorization({
        method,
        contentMd5,
        contentType,
        date: iso8601Date,
        hashSignKey: this.configuration.hashSignKey,
        accessKeyId: this.configuration.accessKeyId,
        secretAccesskey: this.configuration.secretAccesskey,
      }),
    };
  }

  getWeather(location, units, exclude) {
    const url = this.configuration.weatherApiUrl;
    const params = {
      lat: location.latitude,
      lon: location.longitude,
      units,
      exclude,
      appid: this.configuration.weatherApiKey
    }
    return this.sendShalgoerServiceRequest(url, params);

  }

  getExchangeRate() {
    const url = this.configuration.centralBankMMApiUrl;
    this.sendShalgoerServiceRequest(url)
  }

  sendShalgoerServiceRequest(url, params = {}) {
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
    }
    return new Promise((resolve, reject) => {
      axios({
        url,
        method,
        timeout: this.configuration.apiTimeOverAllowance,
        headers,
        params,
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

  }

  setSessionId(sessionId) {
    this.configuration.sessionId = sessionId;
  }

  handleSessionExpire() {
    /**
     * Dispatch a redux action, notifying session has expired.
     *
     * This function was attached to request object at the
     * time of "INITIALIZE_APP_REQUEST" process.
     */
    this.sessionExpiredAction();
  }

  /**
   * Trigger a Redux action to indicate that the network connection has gone offline
   */
  handleNetworkConnection() {
    this.networkConnectionAction();
  }
}

export default Request;
