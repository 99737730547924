import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { translate } from '../../../../i18n';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CURRENT_USER_LK,
  IMAGE_SK,
  PRIVATE_SK,
  SERVER_ERROR_CODES,
  VIDEO_SK,
} from '../../../../constants';
import { api, Status } from '../../../../api';
import MediaViewer from '../../../../common/MediaViewer/MediaViewer';
import { Snack, Spinner } from '../../../../common';
import { connect } from 'react-redux';
import defaultVedioThumb from '../../../../assets/images/default_video_thumb.jpg';
import AlbumDetails from '../GalleryTab/AlbumDetails';
import styles from './RoomAndMenuTab.module.scss';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const RoomAndMenuTab = ({
  identifier,
  entityType,
  subType,
  profileType,
  profileImgUrl,
  userRole,
  businessName,
  loggedInUserIdentifier,
  baseMediaUrl,
  baseMediaThumbUrl,
  mode = 'default',
  albumName = '',
  statusMsg,
  isPrivate: _isPrivate,
  albumDetailsModeOperations,
}) => {
  const [page, setPage] = useState(1);
  const [contentAvailableForRefresh, setContentAvailableForRefresh] = useState(
    _isPrivate !== PRIVATE_SK
  );
  const [photos, setPhotos] = useState([]);
  const [photoApiStatus, setPhotoApiStatus] = useState(Status.DEFAULT);
  const [albumDetails, setAlbumDetails] = useState({
    name: '',
    description: '',
    createdAt: '',
  });
  const [mediaViewerState, setMediaViewerState] = useState({
    selected: 0,
    isOpen: false,
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const [isPrivate, setIsPrivate] = useState(_isPrivate === PRIVATE_SK);

  useEffect(() => {
    if (!isPrivate || profileType === CURRENT_USER_LK) {
      getPhotos(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const getPhotos = (page = 1) => {
    if (photoApiStatus !== Status.LOADING) {
      setPhotoApiStatus(Status.LOADING);
      api
        .fetchExploreAlbumPhotoByBusiness({
          businessIdentifier: identifier,
          businessCategory: subType,
          page,
        })
        .then(({ userResponse }) => {
          setPhotoApiStatus(Status.SUCCESS);
          let _photos = getMediaWithLayout(userResponse);
          let allPhotos = [...photos, ..._photos];
          setPhotos(allPhotos);
          if (userResponse.length < 10) {
            setContentAvailableForRefresh(false);
          }
        })
        .catch((error) => {
          console.log('error', error);
          setPhotoApiStatus(Status.ERROR);
          if (error.name === SERVER_ERROR_CODES.featurePrivate) {
            setIsPrivate(true);
            setContentAvailableForRefresh(false);
          }
        });
    }
  };

  const setNewItemLoading = (isLoading, newItems = []) => {
    let _photos = _.clone(photos);
    if (isLoading) {
      newItems.forEach((__) => {
        _photos.unshift({ isLoading: true });
      });
    } else {
      newItems.forEach((__) => {
        _photos.shift();
      });
      newItems.forEach((item) => {
        if (item !== null) {
          _photos.unshift(item);
          albumDetailsModeOperations.updateAlbumCount(
            identifier,
            item.fileType,
            false
          );
        }
      });
    }
    let newphotos = getMediaWithLayout(_photos);
    setPhotos(newphotos);
  };

  const updateAlbumDetails = (identifier, name, description) => {
    setAlbumDetails((prevState) => {
      return {
        ...prevState,
        name,
        description,
      };
    });
    albumDetailsModeOperations.updateAlbumName(identifier, name);
  };

  const deletePhoto = (index) => {
    // delete media from index
    let _photos = _.clone(photos);
    let fileType = _photos[index].fileType;
    _photos.splice(index, 1);
    let newphotos = getMediaWithLayout(_photos);
    setPhotos(newphotos);
    albumDetailsModeOperations.updateAlbumCount(identifier, fileType, true);
    albumDetailsModeOperations.updateAlbumFile(identifier, _photos[0]);

    if (photos.length - 1 === 0) {
      deleteAlbumAPI();
    }
  };

  const deleteAlbumAPI = () => {
    api
      .deleteAlbum({
        albumIdentifier: identifier,
      })
      .then((response) => {
        const {
          userResponse: { status }, // eslint-disable-line no-unused-vars
        } = response;
        //Toast.showToast(status);

        // remove album form redux list
        albumDetailsModeOperations?.deleteAlbum(identifier);
        closeMediaViewer();
        albumDetailsModeOperations?.goToAlbumList();
      });
  };

  const viewPhoto = (selected) => {
    setMediaViewerState({
      selected,
      isOpen: true,
    });
  };

  const closeMediaViewer = () => {
    setMediaViewerState({
      selected: 0,
      isOpen: false,
    });
  };

  const showSnack = (msg) => {
    setSnackMsg(msg);
    setSnackOpen(true);
  };

  const getMediaWithLayout = (medias) => {
    let _medias = [];
    let chunks = sliceIntoChunks(medias, 10);
    chunks.forEach((chunk) => {
      let handler = 4;
      chunk.forEach((media, key) => {
        if (key === handler) {
          media.cols = 2;
          handler = handler + 4;
        } else {
          media.cols = 1;
        }
        _medias.push(media);
      });
    });
    return chunks.flat();
  };

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const renderImage = (item) => {
    if (item.fileType === VIDEO_SK) {
      if (item.isThumbGenerated) {
        return (
          <img
            {...srcset(
              `${item.filePath.thumbUrl.replace('.mp4', '.jpg')}`,
              50,
              item.cols,
              1
            )}
            alt="img"
            loading="lazy"
          />
        );
      } else {
        return <img alt="img" src={defaultVedioThumb} loading="lazy" />;
      }
    }

    return (
      <img
        {...srcset(
          item.isThumbGenerated
            ? item?.filePath?.thumbUrl
            : item?.filePath?.originalUrl,
          50,
          item.cols,
          1
        )}
        alt="img"
        loading="lazy"
      />
    );
  };
  return (
    <div className={styles['photos-grid-container']}>
      <AlbumDetails
        fileCount={photos.length}
        identifier={identifier}
        mode={mode}
        profileType={profileType}
        albumName={albumDetails.name}
        description={albumDetails.description}
        createdAt={albumDetails.createdAt}
        loggedInUserIdentifier={loggedInUserIdentifier}
        updateAlbumDetails={updateAlbumDetails}
        setNewItemLoading={setNewItemLoading}
        albumDetailsModeOperations={albumDetailsModeOperations}
      />
      {photoApiStatus === Status.LOADING && mode === 'sliderDetails' ? (
        <Spinner size={22} />
      ) : (
        <InfiniteScroll
          dataLength={photos.length} //This is important field to render the next data
          next={() => {
            photoApiStatus !== Status.LOADING && //hot-fix
              setPage((prevState) => prevState + 1);
          }}
          hasMore={contentAvailableForRefresh}
          loader={
            <div className={styles.spinner}>
              <CircularProgress size={22} className="loading_spinner" />
            </div>
          }
          endMessage={
            <div className={styles['nomore-data-info']}>
              {photos.length === 0 && photoApiStatus === Status.SUCCESS ? (
                <span>{translate('businessProfile.noPhoto')}</span>
              ) : isPrivate && profileType !== CURRENT_USER_LK ? (
                <span>{translate('userProfile.privatePhotos')}</span>
              ) : (
                <span></span>
              )}
            </div>
          }
        >
          <ImageList
            id={styles['profile-image-list']}
            variant="quilted"
            cols={3}
          >
            {photos.map((item, key) => (
              <ImageListItem
                id={styles['profile-image-list-item']}
                key={key}
                cols={item.cols}
                rows={1}
                onClick={() => viewPhoto(key)}
              >
                {item.isLoading ? (
                  <div id={styles['loading-palette']}>
                    <CircularProgress size={22} className="loading_spinner" />
                  </div>
                ) : (
                  renderImage(item)
                )}
              </ImageListItem>
            ))}
          </ImageList>
        </InfiniteScroll>
      )}
      <MediaViewer
        open={mediaViewerState.isOpen}
        type={IMAGE_SK}
        entityType={subType}
        profileType={profileType}
        mode={mode}
        mediaList={photos}
        selected={mediaViewerState.selected}
        profileImgUrl={profileImgUrl}
        userRole={userRole}
        userName={businessName}
        baseMediaUrl={baseMediaUrl}
        baseMediaThumbUrl={baseMediaThumbUrl}
        close={closeMediaViewer}
        deletePhoto={deletePhoto}
        showSnack={showSnack}
      />
      <Snack open={snackOpen} message={snackMsg} onClose={handleSnackClose} />
    </div>
  );
};

const mapStateToProps = ({ profile }) => {
  const { statusMsg } = profile;

  return {
    statusMsg,
  };
};

export default connect(mapStateToProps, null)(RoomAndMenuTab);
