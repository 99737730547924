import React from 'react';

import {
    RadioGroup,
    useRadioGroup,
    Radio,
    FormControlLabel,
    Button
} from '@mui/material';

import { translate } from '../../../../../i18n';

import {
    DELETE_LK,
    DEACTIVATE_LK
} from '../../../../../constants';

import styles from '../AccountControlTab.module.scss';

const AccountOwnershipAndControl = ({
    onActionChange,
    handleNextAction
}) => {
    const ControlLabel = (props) => {
        const radioGroup = useRadioGroup();
        if (radioGroup) {
            onActionChange(radioGroup.value);
        }
        return <FormControlLabel {...props} />;
    }

    return (
        <>
            <RadioGroup name="use-radio-group" defaultValue={DEACTIVATE_LK}>
                <div className={styles["radio-item-container"]}>
                    <div className={styles["radio-item"]}>
                        <div className={styles["radio-title"]}>{translate('editProfile.deactivateAccount')}</div>
                        <ControlLabel
                            value={DEACTIVATE_LK}
                            label={translate('editProfile.deactivateMsg')}
                            control={<Radio />}
                            labelPlacement="start"
                        />
                    </div>
                    <div className={styles["radio-item"]}>
                        <div className={styles["radio-title"]}>{translate('editProfile.deleteAccount')}</div>
                        <ControlLabel
                            value={DELETE_LK}
                            label={translate('editProfile.deleteMsg')}
                            control={<Radio />}
                            labelPlacement="start"
                        />
                    </div>
                </div>
            </RadioGroup>
            <div className={styles["main-content-btn-container"]}>
                <Button
                    onClick={() => handleNextAction()}
                    id={styles["account-control-btn"]}
                >
                    {translate("common.continue")}
                </Button>
            </div>
        </>
    );
};

export default AccountOwnershipAndControl;