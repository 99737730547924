import { takeLatest, call, put, select } from 'redux-saga/effects';
import { api } from '../../api';
import {
  LOGIN_REQUEST,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  ASYNC_STORAGE_KEYS,
  SIGNUP_REQUEST,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESEND_OTP_REQUEST,
  RESEND_OTP_LOADING,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  GET_USER_DETAILS_REQUEST,
  RESET_HOME_SCREEN_DATA,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_LOADING,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INITIAlIZE_INTEREST_LOADING,
  INITIAlIZE_INTEREST_SUCCESS,
  INITIAlIZE_INTEREST_ERROR,
  INITIAlIZE_INTEREST_REQUEST,
  LOGOUT,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  PROFILE_INCOMPLETE_SK,
  SOCIAL_LOGIN_REQUEST,
  RESET_EDIT_PROFILE_STATE
} from '../../constants';

/**
 * Fetch data from redux store
 *
 * NOTE: Don't overfill this for all data acess from redux store, create new
 * accessor if requirement is different or gets exhaustive
 *
 * @return {Object} return user identifier
 */
const getDataFromStore = ({ auth, app, profile }) => {
  const { userIdentifier } = profile;
  const { fcmToken } = app;
  return {
    userIdentifier,
    fcmToken,
  };
};

/**
 * worker saga: Calls the login API with given credentials,
 * on sucess, receives user identifier & session id
 *
 * TODO: Send correct ip address in api body
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - email of the user
 * @param {string} action.payload.phoneNumber - phone number of the user
 * @param {string} action.payload.password    - password for the account
 * @param {string} action.payload.userRole    - by default `memeber`
 */
function* login({ payload: { email, phoneNumber, password, userRole, ipAddress } }) {
  try {
    yield put({ type: LOGIN_LOADING });

    // Dispatch action to hit getUserDetails api
    yield put({
      type: RESET_EDIT_PROFILE_STATE,
    });

    const { fcmToken } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.login },
      {
        email,
        phoneNumber,
        password,
        userRole,
        ipAddress,
        fcmToken,
      },
    );
    // parse the data from response
    const {
      userResponse: {
        about,
        userRole: _userRole,
        userName,
        sessionId,
        profileImageUrl,
        userIdentifier,
        userStatus,
      },
    } = response;
    api.setSessionId(sessionId);

    // send parsed data into action payload
    yield put({
      type: LOGIN_SUCCESS,//change
      payload: {
        about,
        userName,
        profileImageUrl,
        userIdentifier,
        userRole: _userRole,
        userStatus,
        sessionId,
      },
    });

    // Save sessionId & userIdentifier & username in Async storage
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userStatus, userStatus);
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.sessionId, sessionId);
    if (userStatus !== PROFILE_INCOMPLETE_SK) {
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.userIdentifier, userIdentifier);
    }
    else {
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.incompleteUserIdentifier, userIdentifier);
    }

    // Reset home screen, to fetch fresh list
    yield put({
      type: RESET_HOME_SCREEN_DATA,
    });
    // Dispatch action to hit getUserDetails api
    yield put({
      type: GET_USER_DETAILS_REQUEST,
      payload: { userIdentifier },
    });
  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* socialLogin({
  payload: { loginType, token, ipAddress, secretToken, userRole },
}) {
  try {
    yield put({ type: LOGIN_LOADING });

    const { fcmToken } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.socialLogin },
      {
        loginType,
        token,
        secretToken,
        userRole,
        ipAddress,
        fcmToken,
      },
    );
    // parse the data from response
    const {
      userResponse: {
        about,
        userRole: _userRole,
        userName,
        sessionId,
        profileImageUrl,
        userIdentifier,
        userStatus,
      },
    } = response;
    api.setSessionId(sessionId);
    // send parsed data into action payload
    yield put({

      type: LOGIN_SUCCESS,
      payload: {
        about,
        userName,
        profileImageUrl,
        userIdentifier,
        userRole: _userRole,
        userStatus,
        sessionId,
      },
    });

    // Save sessionId & userIdentifier & username in Async storage
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userStatus, userStatus);
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.sessionId, sessionId);
    if (userStatus !== PROFILE_INCOMPLETE_SK) {
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.userIdentifier, userIdentifier);
    } else {
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.incompleteUserIdentifier, userIdentifier);
    }
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.loginType, loginType);

    // Reset home screen, to fetch fresh list
    yield put({
      type: RESET_HOME_SCREEN_DATA,
    });
    // // Dispatch action to hit getUserDetails api
    yield put({
      type: GET_USER_DETAILS_REQUEST,
      payload: { userIdentifier },
    });
  } catch (error) {
    yield put({
      type: LOGIN_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the forget password API.
 *
 * @param {Object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - account email whose password forgotten
 * @param {string} action.payload.phoneNumber - account phone number whose password forgotten
 */
function* forgetPassword({ payload: { email, phoneNumber } }) {
  try {
    yield put({ type: FORGET_PASSWORD_LOADING });
    // make an api call
    const response = yield call(
      { context: api, fn: api.forgetPassword },
      { email, phoneNumber },
    );
    // parse the data from response
    const {
      userResponse: { userIdentifier, otpExpiryTimeInSecs },
    } = response;
    // send parsed data into action payload
    yield put({
      type: FORGET_PASSWORD_SUCCESS,
      payload: {
        userIdentifier,
        otpExpiryTimeInSecs,
      },
    });
  } catch (error) {
    yield put({
      type: FORGET_PASSWORD_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the reset password API.
 *
 * @param {Object} action                         - action object dispatched by user
 * @param {string} action.payload.otp             - otp sent to the email/phone number
 * @param {string} action.payload.password        - new password for the account
 * @param {string} action.payload.confirmPassword - confirm password for the account
 */
function* resetPassword({ payload: { otp, password, confirmPassword, userIdentifier } }) {
  try {
    yield put({ type: RESET_PASSWORD_LOADING });

    const response = yield call(
      { context: api, fn: api.resetPassword },
      { otp, password, confirmPassword, userIdentifier },
    );

    const {
      userResponse: { status },
    } = response;

    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: {
        successMessage: status
      },
    });
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the signup API with given credentials,
 * on sucess, receives user identifier & otp expiry time
 *
 * @param {object} action                     - action object dispatched by user
 * @param {string} action.payload.email       - email of the user
 * @param {string} action.payload.phoneNumber - phone number of the user
 * @param {string} action.payload.password    - password for the account
 * @param {string} action.payload.userRole    - by default `memeber`
 */
function* signup({ payload: { email, phoneNumber, password, userRole } }) {
  try {
    yield put({ type: SIGNUP_LOADING });
    // api call
    const response = yield call(
      { context: api, fn: api.signup },
      { email, phoneNumber, password, userRole },
    );
    // parse the data from response
    const {
      userResponse: { userIdentifier, otpExpiryTimeInSecs },
    } = response;
    // send parsed data into action payload
    yield put({
      type: SIGNUP_SUCCESS,
      payload: {
        userIdentifier,
        otpExpiryTimeInSecs: parseInt(otpExpiryTimeInSecs, 10),
      },
    });
  } catch (error) {
    yield put({
      type: SIGNUP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the verify otp API, with given otp.
 * on success, session id is stored in "app" reducer, and rest of the
 * data is stored in "profile" reducer
 *
 * TODO: Send ip address in api body
 *
 * @param {Object} action             - action object dispatched by user
 * @param {string} action.payload.otp - otp entered by the user
 */
function* verifyOtp({ payload: { otp, userIdentifier, ipAddress } }) {
  try {
    yield put({ type: VERIFY_OTP_LOADING });
    // get some data from redux store
    const { fcmToken } = yield select(getDataFromStore);
    // api call
    const response = yield call(
      { context: api, fn: api.verifyOtp },
      {
        otp,
        userIdentifier,
        ipAddress,
        fcmToken
      },
    );
    // parse the data from response
    const {
      userResponse: {
        about,
        userRole,
        userName,
        sessionId,
        profileImageUrl,
        userIdentifier: _userIdentifier,
        userStatus,
      },
    } = response;
    api.setSessionId(sessionId);

    // send parsed data into action payload
    yield put({
      type: VERIFY_OTP_SUCCESS,
      payload: {
        about,
        userRole,
        userName,
        profileImageUrl,
        userIdentifier: _userIdentifier,
        userStatus,
        sessionId,
      },
    });

    // Save sessionId & userIdentifier in Async storage
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.sessionId, sessionId);
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userStatus, userStatus);
    //yield localStorage.setItem(ASYNC_STORAGE_KEYS.userIdentifier, userIdentifier);

    // Reset home screen, to fetch fresh list
    yield put({
      type: RESET_HOME_SCREEN_DATA,
    });
    // Dispatch action to hit getUserDetails api
    yield put({
      type: GET_USER_DETAILS_REQUEST,
      payload: { userIdentifier },
    });
  } catch (error) {
    yield put({
      type: VERIFY_OTP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* worker saga: Calls the resend otp API.
*
* @param {Object} action                 - action object dispatched by user
* @param {string} action.payload.otpType - whether to request otp for registeration or password reset
*/
function* resendOtp({ payload: { otpType, userIdentifier } }) {
  try {
    yield put({ type: RESEND_OTP_LOADING });

    // make an api call
    const response = yield call(
      { context: api, fn: api.resendOtp },
      { otpType, userIdentifier },
    );
    // parse the data from response
    const {
      userResponse: { userIdentifier: _userIdentifier },
    } = response;
    // send parsed data into action payload
    yield put({
      type: RESEND_OTP_SUCCESS,
      payload: {
        userIdentifier: _userIdentifier,
      },
    });
  } catch (error) {
    yield put({
      type: RESEND_OTP_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/***
 * User interest List
 */
function* fetchInterestList() {
  try {
    yield put({ type: INITIAlIZE_INTEREST_LOADING });
    // api call
    const response = yield call({ context: api, fn: api.fetchInterestList });
    // parse the data from response
    const {
      userResponse
    } = response;
    // send parsed data into action payload
    yield put({
      type: INITIAlIZE_INTEREST_SUCCESS,
      payload: userResponse
    });
  } catch (error) {
    yield put({
      type: INITIAlIZE_INTEREST_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
* worker saga: Remove session id from Async storage
*/
function* logout({ payload: { isSessionExpired } }) {
  try {
    if (!isSessionExpired) {
      try {
        yield put({ type: LOGOUT_LOADING });
        // api call
        yield call({ context: api, fn: api.logout });

        yield put({
          type: LOGOUT_SUCCESS,
        });
      } catch (error) {
        yield put({
          type: LOGOUT_ERROR,
          payload: { errorCode: error.name, errorMessage: error.message },
        });
      }
    }
    // clear sessionId & userIdentifier in Async storage
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.sessionId, '');
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userIdentifier, '');
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userName, '');
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userStatus, '');
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userPreferedLangage, '');
    yield localStorage.setItem(ASYNC_STORAGE_KEYS.loginType, '');

    api.setSessionId('');
    //Toast.showToast(translate('common.logoutSuccessfulMessage'));

    // This reset home screen should be after, all cache has been cleared
    yield put({
      type: RESET_HOME_SCREEN_DATA,
    });
  } catch (error) {
    // log error
    console.log(error);
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(SOCIAL_LOGIN_REQUEST, socialLogin);
  yield takeLatest(RESEND_OTP_REQUEST, resendOtp);
  yield takeLatest(SIGNUP_REQUEST, signup);
  yield takeLatest(VERIFY_OTP_REQUEST, verifyOtp);
  yield takeLatest(FORGET_PASSWORD_REQUEST, forgetPassword);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(INITIAlIZE_INTEREST_REQUEST, fetchInterestList);
  yield takeLatest(LOGOUT, logout);
}