import React, { useEffect, useState } from 'react';
import {
  AppBar,
  BackToTopIcon,
} from '../../common';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { api } from '../../api';
import { getPeriodicTime } from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  Accordion,
  ListItemAvatar,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Box, Divider, Toolbar } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { getCurrentLocale, translate } from '../../i18n';
import styles from './Inbox.module.scss';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Inbox = () => {
  const language = getCurrentLocale();
  const [page, setPage] = useState(1);
  const [inboxList, setInboxList] = useState([]);
  const [contentAvailableForRefresh, setContentAvailableForRefresh] =
    useState(true);
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    api
      .getInbox({ page })
      .then(({ userResponse: { inboxList } }) => {
        setInboxList((prevState) => {
          return [...prevState, ...inboxList];
        });
        renderHtml(inboxList);
        setContentAvailableForRefresh(inboxList.length !== 0);
      })
      .catch(() => { });
  }, [page]);

  const renderHtml = (inboxList) => {
    inboxList.map((item, key) => {
      return (document.getElementById(`body-${key}`).innerHTML = item.body);
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchFilter = (_searchText) => {
    setSearchText(_searchText);
  };

  return (
    <div>
      <AppBar />
      <div id="back-to-top-anchor" />
      <div className={styles['inbox-container']}>
        <>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{ flexGrow: 1 }}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    noWrap
                    className={styles['inbox-title']}
                    sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                  >
                    {translate('common.inbox')}
                  </Typography>
                  <Search>
                    <SearchIconWrapper onClick={() => searchFilter('')}>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      value={searchText}
                      placeholder={`${translate('common.search')}...`}
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e) =>
                        searchFilter(e.target.value.toLowerCase())
                      }
                    />
                  </Search>
                </Toolbar>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <br />

          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={10} sm={10} md={10}>
              <InfiniteScroll
                dataLength={inboxList.length} //This is important field to render the next data
                next={() => setPage(page + 1)}
                hasMore={contentAvailableForRefresh}
                loader={<div className={styles['spinner']}></div>}
                endMessage={<div></div>}
              >
                {inboxList
                  .filter((message) => {
                    return message.title.toLowerCase().includes(searchText);
                  })
                  .map((item, key) => {
                    return (
                      <Accordion
                        key={key}
                        expanded={expanded === key}
                        onChange={handleChange(key)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id={styles['panel1bh-header']}
                        >
                          <ListItemAvatar>
                            <Avatar className={styles['mail_box']}>
                              <MailOutlineIcon />
                            </Avatar>
                          </ListItemAvatar>

                          <Typography variant="body1">
                            <b>{item.title}</b>
                            <div className={styles['inbox-date']}>
                              {getPeriodicTime(item.createdAt, language)}
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <Divider variant="middle" />

                        <AccordionDetails>
                          <Typography variant="body2">
                            <div
                              className={styles['inbox-listbody']}
                              dangerouslySetInnerHTML={{ __html: item.body }}
                            ></div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </>
      </div>
      <BackToTopIcon />
    </div>
  );
};

export default Inbox;
