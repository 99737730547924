/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import { Badge } from '@material-ui/core';
import {
  BLOG_SK,
  POST_SK,
  ARTICLE_SK,
  PUBLIC_SK,
  PRIVATE_SK,
  ONLY_ME_SK,
  FOLLOWING_SK,
  UNFOLLOWED_SK,
  NO_CONNECTION_SK,
  PLI_HOME_MODE_LK,
  PLI_HISTORY_MODE_LK,
  PLI_DETAIL_MODE_LK,
  PLI_PROFILE_MODE_LK,
  BUSINESS_SK,
  ATTRACTION_SK,
  HOTEL_SK,
  RELIGIOUS_SK,
  USER_SK,
  RESTAURANT_SK,
  NON_RELIGIOUS_SK,
  SUCCESS_SK,
  BLOGGER_SK,
} from '../../constants';
import { translate } from '../../i18n';
import { getPeriodicTime, isNonEmptyString } from '../../utils';
import { api, Status } from '../../api';
// import AlertDialog from '../AlertDialog/AlertDialog';
import {
  reportPost,
  reportPostSuccess,
  resetEditPostStatus,
} from '../../store/actions';

// import BLOG_ICON from '../../assets/images/icon_blog.svg';
// import FRIEND_ICON from '../../assets/images/icon_friend.png';
// import UNFOLLOW_ICON from '../../assets/images/icon_unfollow.png';
// import COPY_ICON from '../../assets/images/icon_copylink.png';
// import REPORT_ICON from '../../assets/images/icon_report.png';
// import EDIT_ICON from '../../assets/images/icon_edit.png';
// import DELETE_ICON from '../../assets/images/icon_delete.png';
// import PUBLIC_ICON from '../../assets/images/icon_public.png';
// import PRIVATE_ICON from '../../assets/images/icon_private.png';
// import ONLY_ME_ICON from '../../assets/images/icon_only_me.png';

import BLOGGER_MARK from '../../assets/images/icon_blogger_mark.png';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import styles from './HeaderSection.module.scss';
import cn from 'classnames';
// import SnackBar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
import { withRouter } from 'react-router';
import BadgeContent from '../BadgeContent/BadgeContent';
// import { Room as LocationIcon } from '@mui/icons-material';
// import { Stack } from '@mui/material';
// import EditShareModal from '../EditShareModal/EditShareModal';
// import { Snack } from '..';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const COPY_ACTION = 'copy';
// const UNFOLLOW_ACTION = 'unfollow';
// const REPORT_ACTION = 'report';
// const EDIT_ACTION = 'edit';
// const DELETE_ACTION = 'delete';

// const getVisibilityIcon = visibility => {
//   switch (visibility) {
//     case BLOG_SK:
//       return BLOG_ICON;
//     case PRIVATE_SK:
//       return PRIVATE_ICON;
//     case ONLY_ME_SK:
//       return ONLY_ME_ICON;
//     default:
//       return PUBLIC_ICON;
//   }
// };

const propTypes = {
  mode: PropTypes.oneOf([
    PLI_HOME_MODE_LK,
    PLI_HISTORY_MODE_LK,
    PLI_DETAIL_MODE_LK,
    PLI_PROFILE_MODE_LK,
  ]),
  isShared: PropTypes.bool,
  type: PropTypes.oneOf([ARTICLE_SK, POST_SK, BLOG_SK]).isRequired,
  visibility: PropTypes.oneOf([PUBLIC_SK, PRIVATE_SK, ONLY_ME_SK, BLOG_SK]),
  identifier: PropTypes.string,
  time: PropTypes.string.isRequired,
  followStatus: PropTypes.oneOf([NO_CONNECTION_SK, FOLLOWING_SK, '']),
  userDetails: PropTypes.shape({
    name: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    type: PropTypes.oneOf([USER_SK, BUSINESS_SK, ATTRACTION_SK]),
    subType: PropTypes.oneOf([
      HOTEL_SK,
      RESTAURANT_SK,
      RELIGIOUS_SK,
      NON_RELIGIOUS_SK,
    ]),
  }).isRequired,
  changeFollowStatus: PropTypes.func,
  locationAddress: PropTypes.string,
  url: PropTypes.string,
  profileImgUrl: PropTypes.string,
  loggedInUserIdentifier: PropTypes.string,
  profileScreenIdentifier: PropTypes.string.isRequired,
  showRightControls: PropTypes.bool,
  deletePost: PropTypes.func,
  deleteBlog: PropTypes.func,
  description: PropTypes.string,
  updateDescriptionAndVisibility: PropTypes.func,
  isPostOnlyContainsDescription: PropTypes.bool,
  updateStatusInList: PropTypes.func,
};

const defaultProps = {
  mode: PLI_HOME_MODE_LK,
  isShared: false,
  locationAddress: '',
  profileImgUrl: '',
  identifier: '',
  description: '',
  url: '',
  visibility: PUBLIC_SK,
  //followStatus: '',
  loggedInUserIdentifier: '',
  showRightControls: true,
  deletePost: () => { },
  deleteBlog: () => { },
  updateStatusInList: () => { },
  //changeFollowStatus: () => { },
  updateDescriptionAndVisibility: () => { },
  isPostOnlyContainsDescription: false,
};

const BloggerAvatar = withStyles((theme) => ({
  root: {
    border: `2px solid #FA3F58`,
  },
}))(Avatar);

const HeaderSection = ({
  history,
  mode,
  isShared,
  /**
   * POST_SK or ARTICLE type
   */
  type,
  /**
   * POST_SK visibility
   */
  visibility,
  /**
   * post identifier
   */
  identifier,
  /**
   * Time of the created post/article
   */
  time,
  /**
   * Location, if user has enabled it
   */
  locationAddress,
  /**
   * post description
   */
  description,
  userDetails,
  /**
   * profile image url
   */
  profileImgUrl,
  //   /**
  //    * Web url of the post
  //    */
  //   url,
  /**
   * If user is logged in, his unique identifier id
   */
  loggedInUserIdentifier,
  /**
   * If PostListItem is being rendered in Friends/Business profile screen
   * the identifier that points to the profile in it.
   */
  profileScreenIdentifier,
  /**
   * Whether to show right side controls or not
   *
   * default value is true
   */
  showRightControls,
  /**
   * Dispatch an action to delete this post
   */
  deletePost,
  /**
   * Dispatch an action to delete this blog
   */
  deleteBlog,
  /**
   * Tells about whether the current logged in user is following the post owner
   *
   * If followStatus === "following" => logged in user follows the post owner
   */
  //followStatus,
  /**
   * Function to follow or unfollow post owner
   */
  //changeFollowStatus,
  updateStatusInList,
  /**
   * When Edit dialog appears, update visibility & description back in PostListItem
   */
  updateDescriptionAndVisibility,
  /**
   * In case of edit post, alolow description to be saved empty
   * only if post contain media or shared post
   */
  isPostOnlyContainsDescription,
  editPostStatus,
  reportPost: _reportPost,
  reportPostSuccess: _reportPostSuccess,
  resetEditPostStatus: _resetEditPostStatus,
  showLoginPrompt: _showLoginPrompt,
  reloadProfileScreen: _reloadProfileScreen,
}) => {
  const classes = useStyles();
  const [followStatus, setFollowStatus] = useState({
    localValue: userDetails?.networkStatusWithCurrentUser?.followStatus, // Value which gets change as user interact with PostListItem
    backupValue: userDetails?.networkStatusWithCurrentUser?.followStatus, // Value use to check for latest value from redux
  });

  useEffect(() => {
    if (editPostStatus === Status.SUCCESS) {
      _resetEditPostStatus();
    }
  }, [editPostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  //   const [snackOpen, setSnackOpen] = useState(false);
  //   const handleSnackClose = (event, reason) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }

  //     setSnackOpen(false);
  //   };

  //   const [anchorEl, setAnchorEl] = useState(null);
  //   const open = Boolean(anchorEl);

  //   const [alertData, setAlertData] = useState({});
  //   const [alertOpen, setAlertOpen] = useState(false);
  //   const [openShareModal, setOpenShareModal] = useState(false);
  //   const [snackData, setSnackData] = useState({
  //     open: false,
  //     type: SUCCESS_SK,
  //     message: '',
  //   });

  //   const handleCloseShareModal = () => {
  //     setOpenShareModal(false);
  //   }

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   // Dropdown options when type == 'post`
  //   const POST_OPTIONS = [
  //     {
  //       key: UNFOLLOW_ACTION,
  //       title: translate('common.unfollow'),
  //       icon: UNFOLLOW_ICON,
  //     },
  //     {
  //       key: COPY_ACTION,
  //       title: translate('postListItem.copyLink'),
  //       icon: COPY_ICON,
  //     },
  //     {
  //       key: REPORT_ACTION,
  //       title: translate('common.report'),
  //       icon: REPORT_ICON,
  //     }
  //   ];

  //   const ITEM_HEIGHT = 48;

  const avatarProps = { title: translate('common.brandName').substring(0, 3) };
  //if (type !== POST_SK) {
  //avatarProps.containerStyle = styles.articleAvatarBackground(theme);
  //} else {
  avatarProps.source = { uri: profileImgUrl };
  //}

  //   let dropDownOptions = POST_OPTIONS;
  //   if (isNonEmptyString(loggedInUserIdentifier)) {
  //     if (
  //       mode === PLI_PROFILE_MODE_LK ||
  //       userDetails.identifier ===
  //       loggedInUserIdentifier /* If post belonged to logged in user */ ||
  //       followStatus.localValue !==
  //       FOLLOWING_SK /* logged in user doesn't follow post owner */
  //     ) {
  //       // don't show unfollow options in drodpwn
  //       dropDownOptions = POST_OPTIONS.slice(1);
  //     }

  //     if (
  //       userDetails.identifier === loggedInUserIdentifier &&
  //       isNonEmptyString(userDetails.identifier)
  //     ) {
  //       dropDownOptions = dropDownOptions.slice(0, dropDownOptions.length - 1); // remove report option
  //       dropDownOptions.push({
  //         key: EDIT_ACTION,
  //         title: translate('common.edit'),
  //         icon: EDIT_ICON,
  //       });
  //       dropDownOptions.push({
  //         key: DELETE_ACTION,
  //         title: translate('common.delete'),
  //         icon: DELETE_ICON,
  //       });
  //     }
  //   } else {
  //     dropDownOptions = [
  //       {
  //         key: COPY_ACTION,
  //         title: translate('postListItem.copyLink'),
  //         icon: COPY_ICON,
  //       },
  //     ];
  //   }

  //   const copyToClipboard = () => {
  //     let url = window.location.href;
  //     navigator.clipboard.writeText(`${origin.slice(0, origin.length - 1)}${url}?c=1`);
  //     setSnackOpen(true);
  //   };

  //   const reportPost = async () => {
  //     if (!isNonEmptyString(loggedInUserIdentifier)) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     _reportPost();
  //     api
  //       .report({ identifier })
  //       .then(() => {
  //         _reportPostSuccess();

  //       }
  //       )
  //       .catch(() => { });
  //   };

  //   const handleDeletePost = () => {
  //     if (loggedInUserIdentifier !== userDetails.identifier) {
  //       // Check just to be double sure, only owener can delete his/her post
  //       return;
  //     }
  //     let data = {
  //       title: translate('postListItem.deletePostTitle'),
  //       description: translate('postListItem.deletePostMessage'),
  //       positiveButtonCallback: () => {
  //         if (type === BLOG_SK) {
  //           deleteBlog(identifier);
  //         }
  //         else {
  //           deletePost(identifier);
  //         }
  //         setAlertOpen(false);
  //       },
  //       negativeButtonCallback: () => {
  //         setAlertOpen(false);
  //       }
  //     };
  //     setAlertOpen(true);
  //     setAlertData(data);
  //   };

  //   const handleEditPost = () => {
  //     setOpenShareModal(true);
  //   };

  //   const handleDropdown = option => {
  //     switch (option.key) {
  //       case COPY_ACTION:
  //         copyToClipboard();
  //         break;
  //       case UNFOLLOW_ACTION:
  //         changeFollowStatus(UNFOLLOWED_SK);
  //         break;
  //       case REPORT_ACTION:
  //         reportPost();
  //         break;
  //       case EDIT_ACTION:
  //         handleEditPost();
  //         break;
  //       case DELETE_ACTION:
  //         handleDeletePost();
  //         break;
  //       default:
  //       // No Action
  //     }
  //   };

  const openProfile = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      _showLoginPrompt();
      return;
    }
    if (mode !== PLI_PROFILE_MODE_LK) {
      if (userDetails.type === USER_SK) {
        history.push(`/user?id=${btoa(userDetails.identifier)}`);
      } else {
        history.push(`/${btoa(userDetails.identifier)}/${userDetails.subType}`);
      }
    } else {
      const profileScreenIdentifier = new URLSearchParams(
        window.location.search
      ).get('id');
      if (
        isShared &&
        userDetails.identifier !== atob(profileScreenIdentifier)
      ) {
        if (userDetails.type === USER_SK) {
          history.replace(`/user?id=${btoa(userDetails.identifier)}`);
        } else {
          history.replace(
            `/${btoa(userDetails.identifier)}/${userDetails.subType}`
          );
        }
        _reloadProfileScreen(userDetails.identifier);
      }
    }
  };

  //   const changeFollowStatus = status => {
  //     if (!isNonEmptyString(loggedInUserIdentifier)) {
  //       _showLoginPrompt();
  //       return;
  //     }
  //     api
  //       .updateMemberNetwork({
  //         status,
  //         userIdentifier: userDetails.identifier,
  //         entityType: userDetails.type,
  //       })
  //       .then(({ userResponse: { status: message } }) => {
  //         //Toast.showToast(message);
  //         setFollowStatus(prevState => ({
  //           ...prevState,
  //           localValue: status,
  //         }));
  //         updateStatusInList(status, userDetails.identifier);
  //       })
  //       .catch(() => { });
  //   };

  const userType =
    userDetails.type === BUSINESS_SK
      ? userDetails.subType
      : userDetails.userRole === BLOGGER_SK
        ? BLOGGER_SK
        : USER_SK;

  const avatar =
    userType === USER_SK ? (
      <Avatar
        id={styles['pli-avatar']}
        alt="shalgoer"
        src={profileImgUrl}
        className={styles.avatar}
        onClick={openProfile}
      />
    ) : userType === BLOGGER_SK ? (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <img id={styles['pli-badge']} alt="" src={BLOGGER_MARK} />
        }
      >
        <BloggerAvatar id={styles['blogger-avatar']}>
          <Avatar
            id={styles['pli-avatar']}
            alt="shalgoer"
            src={profileImgUrl}
            className={styles.avatar}
            onClick={openProfile}
          />
        </BloggerAvatar>
      </Badge>
    ) : (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={styles["badge"]}
        badgeContent={<BadgeContent subType={userType} />}
      >
        <Avatar id={styles['business-avatar']}>
          <Avatar
            id={styles['pli-avatar']}
            alt="shalgoer"
            src={profileImgUrl}
            className={styles.avatar}
            onClick={openProfile}
          />
        </Avatar>
      </Badge>
    );

  //   useEffect(() => {
  //     const {
  //       networkStatusWithCurrentUser: {
  //         followStatus: newFollowStatusValue,
  //       } = {},
  //     } = userDetails;
  //     if (followStatus.backupValue !== newFollowStatusValue) {
  //       setFollowStatus({
  //         localValue: newFollowStatusValue,
  //         backupValue: newFollowStatusValue,
  //       });
  //     }
  //   }, [userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  //   const showSnack = ({ message, type }) => {
  //     setSnackData({
  //       open: true,
  //       type,
  //       message,
  //     });
  //   }

  //   const closeSnack = () => {
  //     setSnackData(prevState => {
  //       return {
  //         ...prevState,
  //         open: false,
  //       };
  //     });
  //   }

  return (
    <>
      <CardHeader
        id={styles['mui-card-header']}
        avatar={avatar}
        // action={
        //   <div className={classes.actionContainer}>
        //     {!isShared && (
        //       <>
        //         {loggedInUserIdentifier !== userDetails.identifier && mode !== PLI_PROFILE_MODE_LK && userDetails.type !== BUSINESS_SK ?
        //           followStatus.localValue === FOLLOWING_SK ?
        //             <div className={classes.followingStatus}>
        //               <img alt='img' src={FRIEND_ICON} className='following-icon' />
        //               <span className='following-label'>{translate('common.following')}</span>
        //             </div> :
        //             <Button
        //               id='follow-btn'
        //               size='small'
        //               variant='contained'
        //               className={classes.followBtn}
        //               onClick={() => changeFollowStatus(FOLLOWING_SK)}
        //             >
        //               {translate('common.follow')}
        //             </Button> : null

        //         }
        //         <IconButton
        //           aria-label="more"
        //           aria-controls="long-menu"
        //           aria-haspopup="true"
        //           onClick={handleClick}
        //           className={classes.dropdownStyle}
        //         >
        //           <MoreVertIcon id='more-vert-icon' />
        //         </IconButton>
        //         <Menu
        //           id="long-menu"
        //           anchorEl={anchorEl}
        //           keepMounted
        //           open={open}
        //           onClose={handleClose}
        //           PaperProps={{
        //             style: {
        //               maxHeight: ITEM_HEIGHT * 4.5,
        //             },
        //           }}
        //           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        //           transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        //         >
        //           {dropDownOptions.map((option, key) => (
        //             <MenuItem key={option.key} onClick={handleClose}>
        //               {option.key === COPY_ACTION ?
        //                 <CopyToClipboard
        //                   text={`${window.location.href.slice(0, origin.length)}/${btoa(url.split('/')[1])}/${POST_SK}?c=1`}
        //                   onCopy={() => setSnackOpen(true)}
        //                 >
        //                   <div className={classes.menuItemStyle}>
        //                     <div><img alt='img' src={option.icon} className={classes.menuIconStyle} /></div>
        //                     <div className='pli-dropdown-menu'>{option.title}</div>
        //                   </div>
        //                 </CopyToClipboard>
        //                 : <div className={classes.menuItemStyle} onClick={() => handleDropdown(option)}>
        //                   <div><img alt='img' src={option.icon} className={classes.menuIconStyle} /></div>
        //                   <div className='pli-dropdown-menu'>{option.title}</div>
        //                 </div>
        //               }
        //             </MenuItem>
        //           ))}
        //         </Menu>
        //       </>
        //     )}
        //   </div>
        // }
        title={
          isNonEmptyString(userDetails.name) && (
            <span
              className={styles['pli-header-details']}
              onClick={openProfile}
            >
              {userDetails.name}
            </span>
          )
        }
        subheader={
          <div>
            {/* {locationAddress &&
              <Stack
                direction='row'
                alignItems='center'
                sx={{ marginLeft: '-4px' }}
              >
                <LocationIcon
                  fontSize='small'
                  color='error'
                />
                <span className='pli-header-date'>
                  {locationAddress}
                </span>
              </Stack>
            } */}
            <div className={styles.belowInfo}>
              <div className={cn(styles['pli-header-date'], 'text-gray')}>
                {getPeriodicTime(time)}
              </div>
              {/* <div className={classes.privacyBox}>
                {(type === POST_SK || type === BLOG_SK) && (
                  <img alt='' src={getVisibilityIcon(visibility)}
                    className={type === BLOG_SK
                      ? classes.blogIcon
                      : classes.privacyIcon}
                  />
                )}
              </div> */}
            </div>
          </div>
        }
        className={classes.root}
      />
      {/* <EditShareModal
        open={openShareModal}
        mode='edit'
        type={type}
        identifier={identifier}
        description={isNonEmptyString(description) ? description : ''}
        visibility={visibility}
        isPostOnlyContainsDescription={isPostOnlyContainsDescription}
        updateDescriptionAndVisibility={updateDescriptionAndVisibility}
        handleClose={handleCloseShareModal}
        showSnack={showSnack}
      /> */}
      {/* <AlertDialog open={alertOpen} data={alertData} />
      <SnackBar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackOpen} autoHideDuration={2000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {translate('postListItem.copyLinkSuccessMessage')}
        </Alert>
      </SnackBar>
      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={closeSnack}
      /> */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  followBtn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  followingStatus: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

HeaderSection.propTypes = propTypes;

HeaderSection.defaultProps = defaultProps;

const mapStateToProps = ({ home }) => {
  const { editPostStatus } = home;
  return {
    editPostStatus,
  };
};
export default connect(mapStateToProps, {
  reportPost,
  reportPostSuccess,
  resetEditPostStatus,
})(withRouter(HeaderSection));
