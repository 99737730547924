/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { CssBaseline } from '@material-ui/core';
import { AppBar, BackToTopIcon } from '../../../common';
import {
  ASYNC_STORAGE_KEYS,
  CURRENT_USER_LK,
  FRIEND_PROFILE_LK,
  PLI_PROFILE_MODE_LK,
  SERVER_ERROR_CODES,
} from '../../../constants';
import { updateUserFollowStatus, sessionExpired } from '../../../store/actions';
import { api, Status } from '../../../api';
import UserProfileInfo from '../components/UserProfileInfo/UserProfileInfo';

const UserProfile = ({
  history,
  location,
  baseMediaUrl,
  userDetails: currentUserDetails,
  baseMediaThumbUrl,
  updateUserFollowStatus: _updateUserFollowStatus,
}) => {

  const dispatch = useDispatch();
  const identifier = atob(new URLSearchParams(location.search).get('id'));
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT);
  const [isBlocked, setIsBlocked] = useState(false);

  const [userDetails, setUserDetails] = useState({
    bio: {},
    interests: [],
    settings: {},
    workAndEducation: {},
    placesVisited: [],
  });

  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const reload = () => { };

  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );

  const profileType =
    loggedInUserIdentifier === identifier ? CURRENT_USER_LK : FRIEND_PROFILE_LK;

  const profileImage = currentUserDetails?.bio?.profileImg;

  const profileImgUrl = profileImage?.isThumbGenerated
    ? profileImage?.url?.thumbUrl
    : profileImage?.url?.originalUrl;

  useEffect(() => {
    getUserDetails(identifier);
  }, [identifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserDetails = (userIdentifier) => {
    setApiStatus(Status.LOADING);
    api
      .getUserDetails({
        userIdentifier,
      })
      .then(({ userResponse }) => {
        let _interests = userResponse.interests.filter((interest) => {
          return interest.isSelected;
        });
        let _userDetails = {
          ...userResponse,
          interests: _interests,
        };
        setUserDetails(_userDetails);
        setApiStatus(Status.SUCCESS);
      })
      .catch((error) => {
        setApiStatus(Status.ERROR);
        if (error.name === SERVER_ERROR_CODES.blockedUser) {
          setIsBlocked(true);
        }
        if (error.name === SERVER_ERROR_CODES.invalidSession) {
          // if server error is invalid session, application will show SessionExpire AlertDialog to user
          dispatch(sessionExpired);
        }
      });
  };

  return (
    <div>
      <CssBaseline />
      <AppBar
        mode={PLI_PROFILE_MODE_LK}
        profileType={profileType}
        reload={reload}
      />
      <div id="back-to-top-anchor" />
      <UserProfileInfo
        userDetails={userDetails}
        baseMediaUrl={baseMediaUrl}
        profileImgUrl={profileImgUrl}
      />
      <BackToTopIcon />
    </div>
  );
};

const mapStateToProps = ({ app, profile }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { userDetails, userIdentifier } = profile;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    userDetails,
    userIdentifier,
  };
};

export default connect(mapStateToProps, {
  updateUserFollowStatus,
})(withRouter(UserProfile));
