import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import { api } from '../../api';
import {
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_LOADING,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  ASYNC_STORAGE_KEYS,
  PROFILE_FILE_DIR_SK,
  UPLOAD_PROFILE_IMAGE_LOADING,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_ERROR,
  UPLOAD_PROFILE_IMAGE_REQUEST,
  UPLOAD_COVER_IMAGE_REQUEST,
  UPLOAD_COVER_IMAGE_LOADING,
  UPLOAD_COVER_IMAGE_SUCCESS,
  UPLOAD_COVER_IMAGE_ERROR,
  COVER_FILE_DIR_SK,
  FETCH_USER_NETWORK_REQUEST,
  LOADING_SK,
  SUCCESS_SK,
  ERROR_SK,
  FRIEND_SK,
  FETCH_USER_FRIENDS_LOADING,
  FETCH_MORE_USER_FRIENDS_LOADING,
  FRIEND_ACCEPT_PENDING_SK,
  FETCH_USER_REQUESTS_LOADING,
  FETCH_MORE_USER_REQUESTS_LOADING,
  FETCH_USER_INVITES_LOADING,
  FETCH_MORE_USER_INVITES_LOADING,
  FETCH_MORE_USER_FRIENDS_ERROR,
  FETCH_USER_REQUESTS_ERROR,
  FETCH_MORE_USER_REQUESTS_ERROR,
  FETCH_MORE_USER_INVITES_ERROR,
  FETCH_MORE_USER_FRIENDS_SUCCESS,
  FETCH_USER_REQUESTS_SUCCESS,
  FETCH_MORE_USER_REQUESTS_SUCCESS,
  FETCH_MORE_USER_INVITES_SUCCESS,
  FETCH_USER_FRIENDS_ERROR,
  FETCH_USER_INVITES_ERROR,
  FETCH_USER_INVITES_SUCCESS,
  FETCH_USER_FRIENDS_SUCCESS,
  ENGLISH_SK,
  BURMESE_SK,
  BURMESE_LANGUAGE_CODE_LK,
  ENGLISH_LANGUAGE_CODE_LK,
  FETCH_FAVOURITES_REQUEST,
  FETCH_FAVOURITES_LOADING,
  FETCH_FAVOURITES_SUCCESS,
  FETCH_FAVOURITES_ERROR,
  LIMITS,
  UPDATE_FAVOURITES,
  UPDATE_FAVOURITES_SUCCESS,
  ACTIVE_SK
} from '../../constants';
import { changeLocale } from '../../i18n';
import { isNonEmptyString } from '../../utils';

const FIRST_PAGE = 1;

/**
 * worker saga: Calls the get user details API.
 */
function* getUserDetails({ payload: { userIdentifier } }) {
  const getDataFromStore = ({ profile, app }) => {
    const { userIdentifier: currentUserIdentifier } = profile;
    const { fcmToken } = app;
    return {
      currentUserIdentifier,
      fcmToken,
    };
  };
  try {
    yield put({
      type: GET_USER_DETAILS_LOADING,
    });

    let { fcmToken, currentUserIdentifier } = yield select(getDataFromStore);
    if (currentUserIdentifier !== userIdentifier) {
      fcmToken = null;
    }

    // api call
    const response = yield call(
      { context: api, fn: api.getUserDetails },
      { userIdentifier, fcmToken },
    );
    // parse the data from response
    const {
      userResponse: {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      },
    } = response;
    // send parsed data into action payload
    yield put({
      type: GET_USER_DETAILS_SUCCESS,
      payload: {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      },
    });

    const { userStatus } = bio;
    let { language } = settings || {};
    language = language === null ? ENGLISH_SK : language;

    /**
     * We will assume that if the user's status is not active, 
     * the user is a guest, and therefore, the app's language is the guest's language.
     *  
     * If the user's status is active, 
     * the app's language is determined by the user's settings.
     */
    if (userStatus === ACTIVE_SK) {
      changeLocale(
        language === BURMESE_SK
          ? BURMESE_LANGUAGE_CODE_LK
          : ENGLISH_LANGUAGE_CODE_LK,
      );
      yield localStorage.setItem(
        ASYNC_STORAGE_KEYS.userPreferedLangage,
        language,
      );
    }
  } catch (error) {
    yield put({
      type: GET_USER_DETAILS_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the fetch favourites API.
 */
function* fetchFavourites({ payload: { filter, page } }) {
  const getDataFromStore = ({ profile }) => {
    const { favourites } = profile;
    return {
      favourites
    };
  };
  try {
    yield put({
      type: FETCH_FAVOURITES_LOADING,
    });

    let { favourites } = yield select(getDataFromStore);

    // api call
    const response = yield call(
      { context: api, fn: api.fetchFavourites },
      { entityType: filter, page },
    );

    const { userResponse: _favourites } = response;

    // send parsed data into action payload
    yield put({
      type: FETCH_FAVOURITES_SUCCESS,
      payload: {
        favourites: page === 1 ? _favourites : [...favourites, ..._favourites],
        hasMore: _favourites.length >= LIMITS.PAGE_SIZE
      },
    });

  } catch (error) {
    yield put({
      type: FETCH_FAVOURITES_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

function* updateFavourites({ payload: { unsavedItem } }) {
  const getDataFromStore = ({ profile }) => {
    const { favourites } = profile;
    return {
      favourites
    };
  };
  try {

    const { favourites } = yield select(getDataFromStore);

    let _favourites = favourites.filter((item) => item !== unsavedItem);

    yield put({
      type: UPDATE_FAVOURITES_SUCCESS,
      payload: {
        favourites: _favourites
      }
    });

  } catch (error) {
    console.log("error occurs in update favourites");
  }

}

/**
 * worker saga: Calls upload profile image API.
 */
function* uploadProfileImage({
  payload: { image: file, fileDirectory = PROFILE_FILE_DIR_SK },
}) {
  try {
    yield put({
      type: UPLOAD_PROFILE_IMAGE_LOADING,
    });
    // get post policy
    const {
      userResponse: { image, policyCreatedAt },
    } = yield call(
      { context: api, fn: api.getPostPolicy },
      { entityType: fileDirectory },
    );
    // Get data from redux
    const { loggedInUserIdentifier } = yield select(getLoggedInUserIdentifier);
    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      {
        file,
        fileDirectory,
        loggedInUserIdentifier,
        policyCreatedAt,
        policy: image.base64policy,
        signature: image.signature,
        localFileIdentifier: file.size.toString(),
      },
    );
    // parse the data from response
    const { userResponse } = response;

    // send parsed data into action payload
    yield put({
      type: UPLOAD_PROFILE_IMAGE_SUCCESS,
      payload: {
        path: userResponse.path,
        identifier: userResponse.identifier,
        localPath: false,
      },
    });
  } catch (error) {
    yield put({
      type: UPLOAD_PROFILE_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls upload Cover image API.
 */
function* uploadCoverImage({
  payload: { image: file, fileDirectory = COVER_FILE_DIR_SK },
}) {
  try {
    yield put({
      type: UPLOAD_COVER_IMAGE_LOADING,
    });
    // get post policy
    const {
      userResponse: { image, policyCreatedAt },
    } = yield call(
      { context: api, fn: api.getPostPolicy },
      { entityType: fileDirectory },
    );
    // Get data from redux
    const { loggedInUserIdentifier } = yield select(getLoggedInUserIdentifier);
    // api call
    const response = yield call(
      { context: api, fn: api.uploadMedia },
      {
        file,
        fileDirectory,
        loggedInUserIdentifier,
        policyCreatedAt,
        policy: image.base64policy,
        signature: image.signature,
        localFileIdentifier: file.size.toString(),
      },
    );
    // parse the data from response
    const { userResponse } = response;

    // send parsed data into action payload
    yield put({
      type: UPLOAD_COVER_IMAGE_SUCCESS,
      payload: {
        path: userResponse.path,
        identifier: userResponse.identifier,
        localPath: false,
      },
    });
  } catch (error) {
    yield put({
      type: UPLOAD_COVER_IMAGE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

// fetch user network action.
const fetchUserNetworkAction = (networkStatus, status, page) => {
  switch (status) {
    case LOADING_SK:
      switch (networkStatus) {
        case FRIEND_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_FRIENDS_LOADING
            : FETCH_MORE_USER_FRIENDS_LOADING;
        case FRIEND_ACCEPT_PENDING_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_REQUESTS_LOADING
            : FETCH_MORE_USER_REQUESTS_LOADING;
        default:
          return page === FIRST_PAGE
            ? FETCH_USER_INVITES_LOADING
            : FETCH_MORE_USER_INVITES_LOADING;
      }
    case SUCCESS_SK:
      switch (networkStatus) {
        case FRIEND_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_FRIENDS_SUCCESS
            : FETCH_MORE_USER_FRIENDS_SUCCESS;
        case FRIEND_ACCEPT_PENDING_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_REQUESTS_SUCCESS
            : FETCH_MORE_USER_REQUESTS_SUCCESS;
        default:
          return page === FIRST_PAGE
            ? FETCH_USER_INVITES_SUCCESS
            : FETCH_MORE_USER_INVITES_SUCCESS;
      }
    default:
      switch (networkStatus) {
        case FRIEND_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_FRIENDS_ERROR
            : FETCH_MORE_USER_FRIENDS_ERROR;
        case FRIEND_ACCEPT_PENDING_SK:
          return page === FIRST_PAGE
            ? FETCH_USER_REQUESTS_ERROR
            : FETCH_MORE_USER_REQUESTS_ERROR;
        default:
          return page === FIRST_PAGE
            ? FETCH_USER_INVITES_ERROR
            : FETCH_MORE_USER_INVITES_ERROR;
      }
  }
};

/**
 * worker saga: Calls fetch user network list API.
 */
export function* fetchUserNetwork({ payload: { networkStatus, page } }) {
  try {
    yield put({
      type: fetchUserNetworkAction(networkStatus, LOADING_SK, page),
    });
    // api call
    const response = yield call(
      { context: api, fn: api.fetchUserNetwork },
      { networkStatus, page },
    );
    // parse the data from response
    const {
      userResponse: { userNetworkList },
    } = response;
    // send parsed data into action payload
    yield put({
      type: fetchUserNetworkAction(networkStatus, SUCCESS_SK, page),
      payload: { userNetworkList },
    });
  } catch (error) {
    yield put({
      type: fetchUserNetworkAction(networkStatus, ERROR_SK, page),
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * worker saga: Calls the Put edit profile API.
 */
function* editProfile({ payload: { userDetailsObjectsToBeUpdate, userName } }) {
  const getDataFromStore = ({ profile, auth }) => {
    const { userIdentifier } = profile;
    const { sessionId } = auth;
    return {
      userIdentifier,
      sessionId,
    };
  };
  try {
    yield put({
      type: EDIT_PROFILE_LOADING,
    });
    // api call
    const response = yield call(
      { context: api, fn: api.editProfile },
      { userDetailsObjectsToBeUpdate },
    );

    if (userDetailsObjectsToBeUpdate?.settings?.language) {
      yield localStorage.setItem(
        ASYNC_STORAGE_KEYS.userPreferedLangage,
        userDetailsObjectsToBeUpdate?.settings?.language,
      );
    }

    // parse the data from response
    const {
      userResponse: {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      },
    } = response;

    yield localStorage.setItem(ASYNC_STORAGE_KEYS.userStatus, bio.userStatus);
    const _userIdentifier = localStorage.getItem(ASYNC_STORAGE_KEYS.userIdentifier);
    if (!isNonEmptyString(_userIdentifier)) {
      const { userIdentifier } = yield select(getDataFromStore);
      yield localStorage.setItem(ASYNC_STORAGE_KEYS.userIdentifier, userIdentifier);
    }

    // send parsed data into action payload
    yield put({
      type: EDIT_PROFILE_SUCCESS,
      payload: {
        bio,
        interests,
        placesVisited,
        settings,
        workAndEducation,
      },
    });

  } catch (error) {
    yield put({
      type: EDIT_PROFILE_ERROR,
      payload: { errorCode: error.name, errorMessage: error.message },
    });
  }
}

/**
 * Fetch logged in user identifier from redux store
 */
const getLoggedInUserIdentifier = ({ profile }) => {
  const { userIdentifier } = profile;

  return {
    loggedInUserIdentifier: userIdentifier,
  };
};

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(EDIT_PROFILE_REQUEST, editProfile);
  yield takeLatest(GET_USER_DETAILS_REQUEST, getUserDetails);
  yield takeLatest(UPLOAD_PROFILE_IMAGE_REQUEST, uploadProfileImage);
  yield takeLatest(UPLOAD_COVER_IMAGE_REQUEST, uploadCoverImage);
  yield takeEvery(FETCH_USER_NETWORK_REQUEST, fetchUserNetwork);
  yield takeEvery(FETCH_FAVOURITES_REQUEST, fetchFavourites);
  yield takeEvery(UPDATE_FAVOURITES, updateFavourites);
}