/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../store";
import { initializeApp, logout } from "../store/actions";
import { Status } from "../api";
import { AlertDialog, Loading } from "../common";
import {
  Home,
  Otp,
  ChangePassword,
  SigninScreen,
  SignupScreen,
  ForgotPassword,
  ResetPassword,
  UserInterest,
  ArticleDetails,
  AttractionDetails,
  BusinessProfile,
  UserProfile,
  PostDetails,
  EditProfile,
  SearchResult,
  AttractionList,
  ArticleList,
  HotelList,
  RestaurantList,
  PrivacyPolicy,
  Inbox,
  PageNotFound,
} from "../components";
import { translate } from "../i18n";
import { initFCM } from "../fcm/fcmToken";
import { clearBrowserConsole } from "../utils";
import RouteHandler from "../components/RouteHandler/RouteHandler";
import SomethingWrong from "../components/SomethingWrong/SomethingWrong";
import { OFFLINE_LK } from "../constants";

const Router = ({
  initializeApp: _initializeApp,
  /**
   * If sessionStatus === Status.DEFAULT  => User is not logged in
   * If sessionStatus === Status.SUCCESS  => User is logged in
   * If sessionStatus === Status.ERROR    => Current session has expired
   *
   * @source redux
   */
  sessionStatus,
  /**
   * If networkConnection === online  => The request was made, response was received
   * If networkConnection === offline  => he request was made but no response was received
   *
   * @source redux
   */
  networkConnection,
  /**
   * Dispatch logout action
   *
   * @source reudx
   */
  logout: _logout,
  status: initializeAppStatus,
  fcmTokenStatus,
  userDetailsStatus,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handlePreloader();
    _initializeApp();
    // clearBrowserConsole();
  }, []);

  useEffect(() => {
    if (
      fcmTokenStatus === Status.DEFAULT &&
      userDetailsStatus === Status.SUCCESS
    ) {
      initFCM();
    }
  }, [fcmTokenStatus, userDetailsStatus]);

  useEffect(() => {
    if (sessionStatus === Status.ERROR) {
      showAlertDialog();
    }
  }, [sessionStatus]);

  const showAlertDialog = () => {
    let data = {
      title: translate("errors.sessionExpiredTitle"),
      description: translate("errors.sessionExpiredMessage"),
      positiveButtonCallback: () => {
        _logout(true);
        reload();
      },
      hideNegativeButton: true,
      titleColor: "red",
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const reload = () => {
    history.replace("/");
    window.location.reload();
  };

  const handlePreloader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {initializeAppStatus === Status.Loading ? (
        <Loading />
      ) : initializeAppStatus === Status.SUCCESS ? (
        <ConnectedRouter history={history}>
          <RouteHandler>
            {networkConnection === OFFLINE_LK ? (
              <SomethingWrong />
            ) : (
              <Switch>
                <Route exact path="/otp" component={Otp} />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route
                  exact
                  path="/changepassword"
                  component={ChangePassword}
                />
                <Route exact path="/register-login" component={SigninScreen} />
                <Route exact path="/login" component={SignupScreen} />
                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route exact path="/interest" component={UserInterest} />
                <Route
                  exact
                  path="/:id/attraction"
                  component={AttractionDetails}
                />
                <Route exact path="/:id/article" component={ArticleDetails} />
                <Route exact path="/:id/post" component={PostDetails} />
                <Route exact path="/search" component={SearchResult} />
                <Route
                  exact
                  path="/list/attractions/:type"
                  component={AttractionList}
                />
                <Route exact path="/list/articles" component={ArticleList} />
                <Route exact path="/list/hotels" component={HotelList} />
                <Route
                  exact
                  path="/list/restaurants"
                  component={RestaurantList}
                />
                <Route exact path="/:id/:type" component={BusinessProfile} />
                <Route exact path="/user" component={UserProfile} />
                <Route exact path="/edit-profile" component={EditProfile} />
                <Route exact path="/terms-policy" component={PrivacyPolicy} />
                <Route exact path="/inbox" component={Inbox} />
                <Route exact path="/" component={Home} />
                <Route exact path="/404" component={PageNotFound} />
                <Route path="*">
                  <Redirect to="/404" />
                </Route>
              </Switch>
            )}
          </RouteHandler>
        </ConnectedRouter>
      ) : (
        <Loading />
      )}
      <AlertDialog open={alertOpen} data={alertData} />
    </>
  );
};

const mapStateToProps = ({ app, profile }) => {
  const { status, sessionStatus, networkConnection, fcmTokenStatus } = app;
  const { userStatus, userDetailsStatus } = profile;
  return {
    status,
    sessionStatus,
    userStatus,
    networkConnection,
    fcmTokenStatus,
    userDetailsStatus,
  };
};

export default connect(mapStateToProps, { initializeApp, logout })(Router);
