import React, { forwardRef, useEffect, useState } from 'react';
import { AlertDialog, Backdrop, Button, Snack } from '../../../common';
import {
  Box,
  Card,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Stack,
  styled,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputBase,
  createTheme,
  ThemeProvider,
  Rating,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { translate } from '../../../i18n';
import {
  HighlightOff,
  List,
  Flag,
  AccountBalance,
  LocationOn,
  Map,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { api } from '../../../api';
import {
  ASYNC_STORAGE_KEYS,
  ERROR_SK,
  HOTEL_SK,
  RESTAURANT_SK,
  SUCCESS_SK,
} from '../../../constants';
import { isNonEmptyString } from '../../../utils';
import thanksImg from '../../../assets/images/thanks_for_suggestion_img.png';
import { withRouter } from 'react-router';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import styles from '../SearchResult.module.scss';
const options = [
  { key: RESTAURANT_SK, name: translate('common.restaurant') },
  { key: HOTEL_SK, name: translate('common.hotel') },
];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F2EE',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
  },
});

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:after': {
    border: '1px solid #010D18',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: 18,
    '& fieldset': {
      border: '1px solid #010D18',
    },
    '&:hover fieldset': {
      border: '1px solid #010D18',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #010D18',
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    border: '1px solid #010D18',
    padding: '8px 26px 8px 12px',
    fontSize: 18,
  },
}));

const Label = ({ label, IconComponent, ...props }) => {
  return (
    <Box sx={{ paddingLeft: '20px' }}>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconComponent {...props} />
        <Typography id={styles['business-suggestion-label']}>
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};

const SelectBox = ({ value, handleChange }) => (
  <ThemeProvider theme={theme}>
    <FormControl fullWidth>
      <Select
        value={value}
        onChange={handleChange}
        IconComponent={KeyboardArrowDown}
        input={<BootstrapInput />}
      >
        {options.map((option, key) => (
          <MenuItem key={key} value={option.key}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </ThemeProvider>
);

const BusinessSuggestion = ({
  showTitle,
  showTitleA,
  flag,
  history
}) => {
  const loggedInUserIdentifier = localStorage.getItem(
    ASYNC_STORAGE_KEYS.userIdentifier
  );
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState(options[0].key);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [additionalAddress, setAdditionalAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openThanks, setOpenThanks] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      showSnack({
        type: ERROR_SK,
        message: error.toString().split(':')[1],
      });
    }
  }, [error]);

  useEffect(() => {
    checkDisabled();
  }, [name, country, city, address]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoading(true);
    api
      .addBusinessSuggestion({
        businessName: name,
        businessCategory: category,
        country,
        destination: city,
        streetAddress: address,
        additionalAddress,
        zipCode,
      })
      .then(() => {
        setLoading(false);
        setOpenThanks(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const checkDisabled = () => {
    if (
      isNonEmptyString(name) &&
      isNonEmptyString(country) &&
      isNonEmptyString(city) &&
      isNonEmptyString(address)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const resetState = () => {
    setName('');
    setCategory(options[0].key);
    setCountry('');
    setCity('');
    setAddress('');
    setAdditionalAddress('');
    setZipCode('');
  };

  const handleClose = () => {
    setOpen(false);
    resetState();
  };

  const closeThanks = () => {
    setOpenThanks(false);
    handleClose();
  };

  const openDialog = () => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }
    setOpen(true);
  };

  const showLoginPrompt = () => {
    let data = {
      title: translate('common.loginPromtDialogTitle'),
      description: translate('common.loginPromtDialogMessage'),
      positiveButtonCallback: () => {
        history.push('register-login');
        setAlertOpen(false);
      },
      negativeButtonCallback: () => {
        setAlertOpen(false);
      },
    };
    setAlertData(data);
    setAlertOpen(true);
  };

  const showSnack = ({ message, type }) => {
    setSnackData({
      open: true,
      type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const renderBody = () => {
    return (
      <div id={styles['search-result-screen-body']}>
        <Card id={styles['business-suggestion-card']}>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Box id={styles['business-suggestion-body-box']}>
                <Typography id={styles['business-suggestion-sub-title']}>
                  {translate('searchResultScreen.generalInformation')}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} />
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={`${translate(
                        'searchResultScreen.officialPlaceName'
                      )} *`}
                      IconComponent={() => (
                        <AssignmentIcon
                          id={styles['business-suggestion-icon']}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={translate('searchResultScreen.category')}
                      IconComponent={() => (
                        <List id={styles['business-suggestion-icon']} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <SelectBox
                      value={category}
                      handleChange={(e) => setCategory(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
                <Typography id={styles['business-suggestion-sub-title']}>
                  {translate('searchResultScreen.location')}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} />
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={`${translate('searchResultScreen.country')} *`}
                      IconComponent={() => (
                        <Flag id={styles['business-suggestion-icon']} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={`${translate(
                        'searchResultScreen.cityTownState'
                      )} *`}
                      IconComponent={() => (
                        <AccountBalance
                          id={styles['business-suggestion-icon']}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={`${translate(
                        'searchResultScreen.streetAddress'
                      )} *`}
                      IconComponent={LocationOn}
                      id={styles['business-suggestion-icon']}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={translate(
                        'searchResultScreen.additionalAddressInfo'
                      )}
                      IconComponent={() => (
                        <Map id={styles['business-suggestion-icon']} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={additionalAddress}
                      onChange={(e) => setAdditionalAddress(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sm={5}>
                    <Label
                      label={translate('searchResultScreen.zipCodePostalCode')}
                      IconComponent={() => (
                        <PaymentIcon id={styles['business-suggestion-icon']} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={7}>
                    <CssTextField
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12} />
                  <Grid item xs={12} textAlign="center">
                    <Button
                      variant="contained"
                      id={styles['business-suggestion-submit-button']}
                      onClick={() => !disabled && submit()}
                      disabled={disabled}
                    >
                      {translate('searchResultScreen.suggest')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Card>
      </div>
    );
  };

  const renderMainDialogContent = () => (
    <>
      <IconButton
        id={styles['business-suggestion-close-button']}
        onClick={handleClose}
      >
        <HighlightOff />
      </IconButton>
      <Box id={styles['business-suggestion-title']}>
        {translate('searchResultScreen.addBusinessButton')}
      </Box>
      {renderBody()}
      <Backdrop open={loading} />
    </>
  );

  const renderThanksDialogContent = () => (
    <>
      <DialogContent>
        <Grid container spacing={1} direction="column" textAlign="center">
          <Grid item xs>
            <img
              alt="thanks"
              src={thanksImg}
              className={styles['business-suggestion-thanks-img']}
            />
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <div className={styles.ratingDiv}>
              <Rating readOnly value={5} />
            </div>
            <Typography id={styles['business-suggestion-thanks-msg']}>
              {translate('searchResultScreen.thanksForSuggestion')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions id={styles['business-suggestion-dialog-actions']}>
        <Typography
          id={styles['business-suggestion-ok-button']}
          onClick={closeThanks}
        >
          {translate('common.ok')}
        </Typography>
      </DialogActions>
    </>
  );

  return (
    <>
      <Typography id={!flag ? styles['no-result-business-title'] : styles['no-result-business-title-flag']}>
        {(showTitle || showTitleA) && translate('searchResultScreen.addBusinessTitle')}
      </Typography>
      {!showTitleA && <Button
        id={flag ? styles['all-search-result-button-flag'] : styles['all-search-result-button']}
        variant="contained"
        size="small"
        onClick={openDialog}
      >
        {translate('searchResultScreen.addBusinessButton')}
      </Button>}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{ className: styles['business-suggestion-root'] }}
      >
        {renderMainDialogContent()}
      </Dialog>
      <Dialog open={openThanks}>{renderThanksDialogContent()}</Dialog>
      <AlertDialog open={alertOpen} data={alertData} />
      <Snack
        open={snackData.open}
        type={snackData.type}
        message={snackData.message}
        onClose={handleSnackClose}
      />
    </>
  );
};

export default withRouter(BusinessSuggestion);
