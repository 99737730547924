import { shape, string, number } from 'prop-types';

/**
 * Defines prop-types definition for a single Recommendation item
 * which is being displayed on HomeScreen
 */
const recommendationType = shape({
  identifier: string.isRequired,
  /**
   * Name is the entity name, this recommendation points to
   */
  name: string.isRequired,
  /**
   * Title can be any arbitray label set by backend to show on UI
   */
  title: string,
  rating: string,
  type: string,
  subType: string,
  coverImg: shape({
    url: string.isRequired,
    isThumbGenerated: number,
  }),
});

export default recommendationType;
