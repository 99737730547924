import { getCurrentLocale, translate } from "../i18n";
import "moment/locale/my";
import "moment/locale/en-in";
import { BURMESE_LANGUAGE_CODE_LK } from "../constants/stringConstants";

const moment = require("moment");

/**
 *
 * @param {*} date is a 'Date' object
 * @returns date in format 'yyyy-mm-dd'
 */
export function formatYmd(date) {
  return date.toISOString().slice(0, 10);
}

/**
 * @returns object containing ISO 8601 and RFC2822 formatted date
 */
export function getDate() {
  const currentDate = new Date(Date.now());
  let iso8601Date = currentDate.toISOString();

  iso8601Date = `${iso8601Date.split(".")[0]}+00:00`;

  const rfc2822Date = currentDate.toUTCString().replace("GMT", "+0000");

  return {
    iso8601Date,
    rfc2822Date,
  };
}

/**
 * Use this method to show date in application
 *
 * @param {String} date         - ISO 8601 or RFC 2822 format
 * @param {bool} compactMonth - Show "Dec" instead of showing full month name
 * @param {bool} isUTC - if incoming date in UTC and we want to show in local time
 *
 * @return {string} formatted  date in format "DD MMM, YYYY"
 */
export function formattedDate(date, compactMonth = false, isUTC = false) {
  const myDate = isUTC ? moment.utc(date).local() : moment(date);

  if (myDate.isValid()) {
    return myDate
      .locale(getCurrentLocale())
      .format(
        `DD ${compactMonth && getCurrentLocale() !== BURMESE_LANGUAGE_CODE_LK
          ? "MMM"
          : "MMMM"
        }, YYYY`
      );
  }

  return "";
}

/**
 * If you want to send date to server than use this method, server only read in this format
 * @param date :  ISO 8601 or RFC 2822 format
 * @return {string} formatted  date in format "YYYY-MM-DD"
 */
export function serverSupportedFormatted(date) {
  const myDate = moment(date);

  if (myDate.isValid()) {
    return myDate.format("YYYY-MM-DD");
  }

  return "";
}

/**
 * Convert string to date object one only in cal date will not show blank if yo use this
 * @param date :  ISO 8601 or RFC 2822 formatted string
 * @return {Date} date object
 */
export function stringToDate(date) {
  // return if already date object
  if (date instanceof Date) {
    return date;
  }

  const myDate = moment(date);

  if (myDate.isValid()) {
    return myDate.toDate();
  }
  return new Date();
}

/**
 * @Param date : ISO 8601 or RFC 2822 format
 *
 * @return {string} :getPeriodicTime time for posts like 1 min ago, 1 month ago etc
 */

export function getPeriodicTime(date) {
  // current date time
  const now = moment();

  // post created date time converted  UTC to locale timezone
  const myDate = moment.utc(date).local();

  if (now.diff(myDate, 'minutes', true) < 1) {
    // if post is 15 min or less than show recent                    : recent
    return translate('common.recent');
  }
  if (now.diff(myDate, 'days', true) < 1) {
    // today post but not recent, time diff is less than 1 day      : X minute ago or y hours ago
    return myDate.locale(getCurrentLocale()).from(now);
  }
  if (now.diff(myDate, 'days', true) < 2) {
    // yesterday                                                    : yesterday at HH:mm
    return `${translate('timeAndDate.yesertdayAt')} ${myDate
      .locale(getCurrentLocale())
      .format('HH:mm')}`;
  }
  if (now.diff(myDate, 'weeks', true) < 1) {
    // more than yesterday day, with in week                        : X day ago at HH:mm
    return (
      myDate.locale(getCurrentLocale()).from(now) +
      myDate.locale(getCurrentLocale()).format(' \\s HH:mm')
        // .replace('s', translate('common.at'))
        .replace(
          's',
          getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? '' : translate('common.at'),
        )
    );
  }
  if (now.year() === myDate.year()) {
    // posted date is in current                                    : dd mmm at HH:mm
    return (
      myDate
        .locale(getCurrentLocale())
        .format(
          `DD ${getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? 'MMMM' : 'MMM'
          } \\s HH:mm`,
        )
        // .replace('s', translate('common.at'));
        .replace(
          's',
          getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? '' : translate('common.at'),
        )
    );
  }
  // posted date is not in current                                : dd mmm, YYYY at HH:mm
  return myDate
    .locale(getCurrentLocale())
    .format(
      `DD ${getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? 'MMMM' : 'MMM'
      }, YYYY \\s HH:mm`,
    )
    .replace(
      's',
      getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? '' : translate('common.at'),
    );
}

/**
 * @param date :  ISO 8601 or RFC 2822 formatted string
 * @return {string} year in as "MMM YYYY"
 */
export function getYearFromDate(date) {
  return moment(date)
    .locale(getCurrentLocale())
    .format(
      `${getCurrentLocale() === BURMESE_LANGUAGE_CODE_LK ? "MMMM" : "MMM"} YYYY`
    );
}

/**
 *
 * @return {Date} max date for Date of birth
 */
export function maxDOB() {
  return moment().subtract(18, "years").toDate();
}

export function getOneWeekPreviousDate() {
  const date = new Date();

  date.setDate(date.getDate() - 6);

  return date;
}

/**
 *
 * @param time in HH:mm:ss utc time    ex 01:00:33
 * @return {string} local time in hh:mmA   06:30AM
 */
export function getFormattedTime(time) {
  return moment.utc(time, "HH:mm:ss").format("hh:mm A");
}

/**
 *
 * @param fromTime
 * @param toTime
 * @return {boolean}
 */
export function isBusinessOpened(fromTime, toTime) {
  if (
    (fromTime === "00:00:00" || !fromTime) &&
    (toTime === "00:00:00" || !toTime)
  ) {
    return false;
  }

  const format = "HH:mm:ss";

  const time = moment().utc().local().format(format);
  const current = moment.utc(time, format);
  const openTime = moment.utc(fromTime, format); // UTC time from server
  const closeTime = moment.utc(toTime, format);

  const value = current.isBetween(openTime, closeTime);

  return closeTime.isBefore(openTime) ? !value : value;
}

/**
 * @param {*} date is a 'Date' object
 * @returns day name
 */
export function getDayName(date) {
  // var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var days = [
    translate("days.sunday"),
    translate("days.monday"),
    translate("days.tuesday"),
    translate("days.wednesday"),
    translate("days.thursday"),
    translate("days.friday"),
    translate("days.saturday"),
  ];
  return days[date.getDay()];
}

/**
 *
 */
export function getWeatherFormattedDate(date) {
  const fdate = formattedDate(date);
  const dayName = getDayName(date);
  return `${dayName} ${fdate} `;
}

export function getExchangeRateFormattedDate(date) {
  return moment
    .unix(date)
    .locale(getCurrentLocale())
    .format("DD MMMM, YYYY");
}
