import { takeLatest, put, call, select, } from 'redux-saga/effects';
import {

    ASYNC_STORAGE_KEYS,
    //--------------------
    INITIALIZE_APP_REQUEST,
    INITIALIZE_APP_LOADING,
    INITIALIZE_APP_SUCCESS,
    INITIALIZE_APP_ERROR,
    CONFIG_REQUEST,
    CONFIG_LOADING,
    CONFIG_SUCCESS,
    CONFIG_ERROR,
    GET_USER_DETAILS_REQUEST,
    PROFILE_INCOMPLETE_SK,
    SAVE_FCM_TOKEN_REQUEST,
    SAVE_FCM_TOKEN_LOADING,
    SAVE_FCM_TOKEN_SUCCESS,
    SAVE_FCM_TOKEN_ERROR
} from '../../constants';
import { sessionExpiredAction, networkConnectionAction } from '..';
import { api, Status } from '../../api';
import { checkPermissionAndGetLocation, } from '../../utils';
import { getUniqueDeviceId, browserNameDetect } from '../../utils';

// worker saga: Initialize necessary things before app starts
function* initializeApp() {
    try {
        yield put({ type: INITIALIZE_APP_LOADING });
        const { deviceId, deviceName } = getDeviceInfo();
        let sessionId = yield localStorage.getItem(ASYNC_STORAGE_KEYS.sessionId);
        if (sessionId === null || sessionId === undefined) {
            sessionId = '';
        }
        api.init({
            deviceId,
            deviceName,
            sessionId,
            sessionExpiredAction,
            networkConnectionAction
        });
        let location = yield checkPermissionAndGetLocation(false);
        yield put({
            type: INITIALIZE_APP_SUCCESS,
            payload: {
                sessionStatus: sessionId !== '' ? Status.SUCCESS : Status.DEFAULT,
                location,
            },
        });
        // yield put({
        //     type: CONFIG_REQUEST,
        // });
        if (sessionId) {
            const userStatus = yield localStorage.getItem(ASYNC_STORAGE_KEYS.userStatus);
            const userIdentifier =
                userStatus !== PROFILE_INCOMPLETE_SK
                    ? yield localStorage.getItem(
                        ASYNC_STORAGE_KEYS.userIdentifier,
                    )
                    : yield localStorage.getItem(
                        ASYNC_STORAGE_KEYS.incompleteUserIdentifier,
                    );
            // Dispatch action to hit get user details API
            yield put({
                type: GET_USER_DETAILS_REQUEST,
                payload: {
                    userIdentifier,
                },
            });
        }

    } catch (error) {
        yield put({
            type: INITIALIZE_APP_ERROR,
            payload: { errorMessage: error.message },
        });
    }
}

function* config() {
    try {
        yield put({ type: CONFIG_LOADING });
        // api call
        const response = yield call({ context: api, fn: api.config });
        // parse the data from response
        const {
            userResponse: {
                languages,
                attractionIds,
                articleIds,
                awsS3BaseUrl,
                newsFeedLimit,
                awsS3BaseUrlThumb,
                guestUserIdStartingNumber,
                fetchNearByTravellerWithInRadiusInMeter,
                tellFriendMsg,
            },
        } = response;
        // send parsed data into action payload
        yield put({
            type: CONFIG_SUCCESS,
            payload: {
                languages,
                articleIds,
                attractionIds,
                tellFriendMsg,
                baseMediaUrl: awsS3BaseUrl,
                baseMediaThumbUrl: awsS3BaseUrlThumb,
                newsFeedLimit: parseInt(newsFeedLimit, 10),
                guestUserIdStartingNumber: parseInt(guestUserIdStartingNumber, 10),
                fetchNearByTravellerWithInRadiusInMeter: parseInt(
                    fetchNearByTravellerWithInRadiusInMeter,
                    10,
                ),
            },
        });
    } catch (error) {
        yield put({
            type: CONFIG_ERROR,
            payload: { errorCode: error.name, errorMessage: error.message },
        });
    }
}

function* saveFCMToken({ payload: { fcmToken } }) {
    try {

        yield put({ type: SAVE_FCM_TOKEN_LOADING });

        const { sessionStatus } = yield select(getDataFromStore);
        const userStatus = localStorage.getItem(ASYNC_STORAGE_KEYS.userStatus);
        const loggedIn = sessionStatus === Status.SUCCESS && userStatus !== PROFILE_INCOMPLETE_SK;

        // call only if user is in loggedIn state
        if (loggedIn) {
            // api call
            yield call(
                { context: api, fn: api.saveFCMToken },
                { fcmToken }
            );
        }

        yield put({
            type: SAVE_FCM_TOKEN_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: SAVE_FCM_TOKEN_ERROR,
            payload: { errorMessage: error.message },
        });
    }
}

function getDeviceInfo() {

    const deviceId = getUniqueDeviceId();
    const deviceName = browserNameDetect();

    return {
        deviceId,
        deviceName,
    };
}

const getDataFromStore = ({ app }) => {
    const { sessionStatus } = app;
    return {
        sessionStatus,
    };
}
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
    yield takeLatest(INITIALIZE_APP_REQUEST, initializeApp);
    yield takeLatest(CONFIG_REQUEST, config);
    yield takeLatest(SAVE_FCM_TOKEN_REQUEST, saveFCMToken);
}