import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AccountOwnershipAndControl from './component/AccountOwnershipAndControl';
import DeactivateAccount from './component/DeactivateAccount';
import DeleteAccount from './component/DeleteAccount';
import {
    DEACTIVATE_LK,
    DELETE_LK
} from '../../../../constants';
import styles from "./AccountControlTab.module.scss";
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';

const AccountControlTab = () => {

    const { userDetails } = useSelector((state) => state.profile);
    const loginType = localStorage.getItem("loginType");

    const [activeStep, setActiveStep] = useState(0);
    const [selectedAction, setSelectedAction] = useState('');

    // Performs the appropriate action based on the selected value and currnt step
    const handleNextAction = () => {
        if (selectedAction === DEACTIVATE_LK) {
            setActiveStep(1);//Move to step 1 directly
        } else if (selectedAction === DELETE_LK) {
            setActiveStep(2); // Move to step 2 directly
        }
    };

    const handleSelectedActionChange = (value) => {
        setSelectedAction(value);
    };

    // Wrap the UserName and LoginType (Google, Facebook, Apple) in <strong> tags to apply bold style
    const userName = <strong>{userDetails.bio.name}</strong>;
    const _loginType = <strong style={{ textTransform: "capitalize" }}>{loginType}</strong>

    /* Noted: renderToString renders a React tree to an HTML string. */
    // Convert the UserName and LoginType JSX element to a string
    const userNameString = renderToString(userName);
    const loginTypeString = renderToString(_loginType);


    const steps = [
        {
            step: <AccountOwnershipAndControl
                onActionChange={handleSelectedActionChange}
                handleNextAction={handleNextAction}
            />
        },
        {
            step: <DeactivateAccount
                selectedAction={selectedAction}
                userName={userNameString}
                loginTypeString={loginTypeString}
                loginType={loginType}
            />
        },
        {
            step: <DeleteAccount
                selectedAction={selectedAction}
                userName={userNameString}
                loginTypeString={loginTypeString}
                loginType={loginType}
            />
        },
    ];

    return (
        <>
            <div className="account-control-container">
                <Box id={styles["account-control-box"]} sx={{ flexGrow: 1 }}>
                    <Box sx={{ width: '100%' }}>
                        {steps[activeStep].step}
                    </Box>
                </Box>
            </div>
        </>
    );
}

export default AccountControlTab;