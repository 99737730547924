import React from "react";
import { SmooveIcon, SmooveIcons } from "../SmooveIcons/SmooveIcons";
import { HOTEL_SK } from "../../constants";
import styles from "./BadgeContent.module.scss";

const BadgeContent = ({ subType }) => {
    return (
        <div className={styles["pli-badge-icon-container"]}>
            <SmooveIcon name={subType === HOTEL_SK ? SmooveIcons.IcHotel : SmooveIcons.IcResturant} />
        </div>
    )
}

export default BadgeContent;