import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { AiOutlineGoogle } from "react-icons/ai";
import FacebookLoginRender from "react-facebook-login/dist/facebook-login-render-props";
import { ImFacebook } from "react-icons/im";

import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    ThemeProvider,
    createTheme,
    RadioGroup,
    Radio,
    FormControlLabel,
    IconButton,
    InputAdornment,
    OutlinedInput
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import DeleteDeactivateConfirmationModal from "./DeleteDeactivateConfirmationModal";
import { Snack } from "../../../../../common";
import { getBuildConfig } from "../../../../../config/buildConfig";
import countryPhoneCodes from "../../../../../config/countryPhoneCode";

import { Status, api } from "../../../../../api";
import { translate } from "../../../../../i18n";
import {
    EMAIL_LK,
    EMAIL_SK,
    FACEBOOK_SK,
    GOOGLE_SK,
    PHONE_SK,
    WEEK_DURATION,
    FORTNIGHT_DURATION,
    MONTH_DURATION
} from "../../../../../constants";

import styles from "../AccountControlTab.module.scss";
import colors from "../../../../../css/variable.scss";

const FACEBOOK_APP_ID = getBuildConfig().facebookAppId;
const GOOGLE_CLIENT_ID = getBuildConfig().googleClientId;

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primaryColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primaryColor,
                        backgroundColor: "none !important",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "& .MuiTypography-root": {
                        fontSize: "16px",
                        color: "#000",
                    },
                },
            },
        },
    },
});

const DeactivateAccount = ({
    selectedAction,
    userName,
    /**
    * lognType: Represents the login type retrieved from the local storage.
    * To determine the current login type.
    */
    loginType,
    /**
    * loginTypeString: Represents the login type as a HTML string 
    * with the first letter capitalized.
    * To display the login type.
    */
    loginTypeString
}) => {
    const { userDetails } = useSelector((state) => state.profile);

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [verifyApiStatus, setVerifyApiStatus] = useState(Status.DEFAULT);
    const [isPromptStep, setIsPromptStep] = useState(true);
    const [isVerificationStep, setIsVerificationStep] = useState(false);
    const [isAccountDeactivationStep, setIsAccountDeactivationStep] = useState(false);
    const [verifiedState, setVerifiedState] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [openDeleteDeactivateConfirmationModal, setOpenDeleteDeactivateConfirmationModal] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [deactivatedTime, setDeactivatedTime] = useState(WEEK_DURATION);

    const countryCode = countryPhoneCodes[122].countryCode;
    const countryFlag = countryPhoneCodes[122].flag;

    // radio item for deactivate duration
    const radioItems = [
        {
            value: WEEK_DURATION,
            label: translate('editProfile.weekDuration'),
        },
        {
            value: FORTNIGHT_DURATION,
            label: translate('editProfile.fortnightDuration'),
        },
        {
            value: MONTH_DURATION,
            label: translate('editProfile.monthDuration'),
        },
    ];

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Callback function for successful Facebook login
    const facebookLoginResponse = ({ accessToken }) => {
        // Check if tokenId is available
        if (accessToken) {
            // Call handleAccountVerification function with the tokenId
            handleAccountVerification(accessToken);
        }
    };

    // Callback function for successful Google login
    const googleLoginResponse = ({ tokenId }) => {
        // Check if tokenId is available
        if (tokenId) {
            // Call handleAccountVerification function with the tokenId
            handleAccountVerification(tokenId);
        }
    };

    const googleLoginFailure = (error) => {
        console.log(error);
    };

    const handleAccountVerification = (tokenId) => {
        // Set the API status to loading
        setVerifyApiStatus(Status.LOADING);

        const bio = userDetails?.bio;

        let loginTypeToUse;
        let emailToUse;
        let mobileNumberToUse;

        // Check if the loginType is not provided
        if (!loginType) {
            // Check the loginType from userDetails.bio
            if (bio.loginType === EMAIL_LK) {
                // If the loginType is email, assign the values to emailToUse
                loginTypeToUse = bio.loginType;
                emailToUse = bio.loginId;
            } else if (bio.loginType === PHONE_SK) {
                // If the loginType is PHONE_NUMBER_LK, assign the values to mobileNumberToUse
                loginTypeToUse = bio.loginType;
                mobileNumberToUse = bio.loginId;
            }
        } else {
            loginTypeToUse = loginType;
        }

        // Call the API to verify the account
        api.verifyAccount({
            accountControlType: selectedAction,
            // reason: reason,
            loginType: loginTypeToUse,
            email: emailToUse,
            mobileNumber: mobileNumberToUse,
            password,
            token: tokenId
        })
            .then(({ userResponse }) => {
                setVerifiedState(userResponse);
                // If the API call is successful
                // Set the API status to success
                setVerifyApiStatus(Status.SUCCESS);
                setErrorMessage("");
                if (!loginType) {
                    // If loginType is falsy (undefined, null, false), it means it's not a social account
                    // Open the DeleteDeactivateConfirmationModal
                    setOpenDeleteDeactivateConfirmationModal(true);
                } else {
                    // If loginType is truthy (not falsy), it means it's a social account (Google || Facebook || Apple)
                    // Set the verification step to false and go to account deletion step
                    setIsVerificationStep(false);
                    setIsAccountDeactivationStep(true);
                }
            })
            .catch((error) => {
                // If there's an error in the API call
                // Set the API status to error
                setVerifyApiStatus(Status.ERROR);
                loginType && setSnackOpen(true);
                // Set the error message
                setErrorMessage(error.message);
            });
    };

    const closeSanck = () => {
        setSnackOpen(false);
    };

    const openConfirmationModel = () => {
        setOpenDeleteDeactivateConfirmationModal(true);
    }

    const handleClose = () => {
        setOpenDeleteDeactivateConfirmationModal(false);
    }

    const goToVerificationStep = () => {
        setIsPromptStep(false);
        setIsVerificationStep(true);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        setDeactivatedTime(event.target.value);
    };

    /*********
       Step 1: Prompt
   *   It presents a message informing the user about the consequences of deactivating their account and 
       asks for confirmation before proceeding.
   *********/
    const renderAccountDeactivatePrompt = () => {
        return (
            <ThemeProvider theme={theme}>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <p>{translate("editProfile.deactivatePromptTitle")}</p>
                        <p>{translate("editProfile.deactivatePromptMsg")}</p>
                        <ul>
                            <li>{translate("editProfile.promptList1")}</li>
                            <li>{translate("editProfile.promptList2")}</li>
                            <li>{translate("editProfile.promptList3")}</li>
                        </ul>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={deactivatedTime}
                            onChange={handleChange}
                        >
                            {radioItems.map((item, index) => (
                                <div key={index} className={styles["deactivate-radio-item"]}>
                                    <FormControlLabel
                                        value={item.value}
                                        control={<Radio />}
                                        label={item.label}
                                        labelPlacement="start"
                                    />
                                </div>
                            ))}
                        </RadioGroup>
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button id={styles["account-control-btn"]} onClick={() => goToVerificationStep()}>{translate("editProfile.deactivate")}</Button>
                    </div>
                </div>
            </ThemeProvider>
        )
    }

    /*********
        Step 2: Normal Account Verification 
    *   Component is to collect the user's password and 
        verify it to ensure the account deactivate request is legitimate.
    *********/
    const renderNormalAccountDeactivationVerification = () => {
        return (
            <form autoComplete="off" className={styles["verification-form"]}>
                <div className={styles["form-container"]}>
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{ __html: translate("editProfile.normalDeactivateVerificationMsg").replace(/%s/g, userName) }} />
                    </div>
                    <div className={styles["input-group"]} >
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={4} alignItems="center">
                                    <FormControl fullWidth>
                                        <InputLabel className={styles["input-item-label"]}>{userDetails.bio.loginType === EMAIL_LK ? translate("common.email") : translate("common.phone")}</InputLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    {userDetails.bio.loginType === EMAIL_LK ?
                                        <FormControl fullWidth>
                                            <OutlinedInput
                                                className={styles['input-box']}
                                                disabled={true}
                                                value={userDetails.bio.email}
                                            />
                                        </FormControl>
                                        : <div className={styles['phone-container']}>
                                            <FormControl
                                                variant="outlined"
                                                id={styles["phone-inbox-select"]}
                                            >
                                                <div>
                                                    <img
                                                        alt="img"
                                                        src={countryFlag}
                                                        className={styles["flag-img"]}
                                                    ></img>
                                                    {countryCode}
                                                </div>
                                            </FormControl>
                                            <FormControl fullWidth id={styles["phone-input-box"]}>
                                                <OutlinedInput
                                                    className={styles['input-box']}
                                                    disabled={true}
                                                    value={userDetails.bio.mobileNumber.substr(3)}
                                                />
                                            </FormControl>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} alignItems="center">
                                    <FormControl fullWidth>
                                        <InputLabel className={styles["input-item-label"]}>{translate("common.password")} <span>*</span></InputLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <FormControl fullWidth>
                                        <OutlinedInput
                                            className={styles["input-box"]}
                                            id={styles['outlined-adornment-password']}
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => handlePasswordChange(e)}
                                            value={password}
                                            error={verifyApiStatus === Status.ERROR}
                                            inputProps={{
                                                autoComplete: "new-password"
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        < FormHelperText id={styles["error-message"]}>{errorMessage}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button
                            id={styles["account-control-btn"]}
                            onClick={() => handleAccountVerification()}
                            disabled={!password}
                        >
                            {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : translate("editProfile.deactivateAccount")}
                        </Button>
                    </div>
                </div>
            </form>
        );
    }

    /*********
       Step 3: Social Account Verification
   *   Component is to prompt the user to log in to their social media account 
       to verify their ownership of the Smoove account. 
   *********/
    const renderSocialAccountDeactivationVerification = () => {
        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <h3>{translate("editProfile.verificationTitle")}</h3>
                        <p dangerouslySetInnerHTML={{
                            __html: translate(
                                "editProfile.socialVerificationMsg"
                            ).replace(/%s/g, loginTypeString).replace(/!@#\$%/g, userName)
                        }} />
                    </div>
                    {loginType === GOOGLE_SK && <GoogleLogin
                        clientId={GOOGLE_CLIENT_ID}
                        onSuccess={googleLoginResponse}
                        onFailure={googleLoginFailure}
                        autoLoad={false}
                        render={(renderProps) => (
                            <div className={styles["verify-social-container"]} onClick={renderProps.onClick}>
                                <Button id={styles["account-control-btn"]}>
                                    {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : <>
                                        <AiOutlineGoogle className={styles["social-icon"]} />
                                        <p>{translate("editProfile.continueWithGoogle")}</p>
                                    </>}
                                </Button>
                            </div>
                        )}
                    />}
                    {loginType === FACEBOOK_SK && <FacebookLoginRender
                        appId={FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields={EMAIL_SK}
                        callback={facebookLoginResponse}
                        render={(renderProps) => (
                            <div className={styles["verify-social-container"]} onClick={renderProps.onClick}>
                                <Button id={styles["account-control-btn"]}>
                                    {verifyApiStatus === Status.LOADING ? <CircularProgress className={styles["loading-spinner"]} size={20} /> : <>
                                        <ImFacebook className={styles["social-icon"]} />
                                        <p>{translate("editProfile.continueWithFacebook")}</p>
                                    </>}
                                </Button>
                            </div>
                        )}
                    />}
                </div>
            </>
        )
    }

    /*********
        Step 4: Final Setp for Account Deactivation
    *   Component is to provide a final confirmation step 
        to the user before deactivation their account.  
    *********/
    const renderAccountDeactivation = () => {
        return (
            <>
                <div className={styles["main-content-container"]}>
                    <div className={styles["main-content"]}>
                        <p dangerouslySetInnerHTML={{ __html: translate("editProfile.deactivateAccountMsg").replace(/%s/g, userName) }} />
                    </div>
                    <div className={styles["main-content-btn-container"]}>
                        <Button
                            id={styles["account-control-btn"]}
                            onClick={() => openConfirmationModel()}
                        >
                            {translate("editProfile.deactivateAccount")}
                        </Button>
                    </div>
                </div >
            </>
        )
    }

    // Define a function to render the appropriate deactivation component based on conditions
    const renderDeactivateComponent = () => {
        if (isPromptStep) {
            return (renderAccountDeactivatePrompt());
        } else if (isVerificationStep && !loginType) {
            // Render NormalAccountDeactivationVerification if the condition is met
            return renderNormalAccountDeactivationVerification();
        } else if (isVerificationStep && loginType) {
            // Render SocialAccountDeactivationVerification component if the condition is met
            return renderSocialAccountDeactivationVerification();
        } else if (isAccountDeactivationStep) {
            return renderAccountDeactivation();
        }
    }

    return (
        <>
            <div id={styles["account-control-title"]}>{translate("editProfile.deactivateAccount")}</div>
            {renderDeactivateComponent()}
            <DeleteDeactivateConfirmationModal
                open={openDeleteDeactivateConfirmationModal}
                handleClose={handleClose}
                verifiedState={verifiedState}
                accountControlType={selectedAction}
                deactivatedTime={deactivatedTime}
            />
            <Snack
                open={snackOpen}
                message={errorMessage}
                type={'error'}
                onClose={closeSanck}
            />
        </>
    )
};

export default DeactivateAccount;