import {
  LOGIN_REQUEST,
  RESET_LOGIN_STATE,
  SIGNUP_REQUEST,
  VERIFY_OTP_REQUEST,
  RESEND_OTP_REQUEST,
  RESET_OTP_VERIFICATION_STATE,
  FORGET_PASSWORD_REQUEST,
  RESET_FORGET_PASSWORD_STATE,
  RESET_PASSWORD_REQUEST,
  RESET_CHANGE_PASSWORD_STATE,
  SAVE_OTP,
  SAVE_OTP_RECEIVE_TYPE,
  SOCIAL_LOGIN_REQUEST,
  INITIAlIZE_INTEREST_REQUEST,
  RESET_SIGNUP_STATE,
  LOGOUT,



} from "../../constants";


/**
 * Initiate login api request. Only one value is needed
 * between email or phoneNumber, not both.
 *
 * @param {string} email       - Email with which user want to login
 * @param {string} phoneNumber - Phone number with which user want to login
 * @param {string} password    - Password of the account
 * @param {string} userRole    - User type for the account, by default will be `member`
 */
export const login = (email, phoneNumber, password, ipAddress, userRole = 'member') => ({
  type: LOGIN_REQUEST,
  payload: {
    email,
    phoneNumber,
    password,
    ipAddress,
    userRole,
  },
});

export const socialLogin = (
  loginType,
  token,
  ipAddress,
  secretToken = null,
  userRole = 'member',
) => ({
  type: SOCIAL_LOGIN_REQUEST,
  payload: {
    loginType,
    token,
    ipAddress,
    secretToken,
    userRole,
  },
});

/**
 * Reset state associated with login logic
 */
export const resetLoginState = () => ({
  type: RESET_LOGIN_STATE,

});

/**
 * Initiate forget password api request. Only one value is needed
 * between email or phoneNumber, not both.
 *
 * @param {string} email       - Email of the account to request password reset
 * @param {string} phoneNumber - Phone number of the account to request password reset
 */
export const forgetPassword = (email, phoneNumber) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload: {
    email,
    phoneNumber,
  },
});

/**
 * Reset state associated with Forget Password screen
 */
export const resetForgetPasswordState = () => ({
  type: RESET_FORGET_PASSWORD_STATE,
});

/**
 * Initiate reset password api request.
 *
 * @param {string} otp             - Otp sent to the email/phone number
 * @param {string} password        - New password for the account
 * @param {string} confirmPassword - Confirm password for the account
 */
export const resetPassword = (otp, password, confirmPassword, userIdentifier) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    otp,
    password,
    confirmPassword,
    userIdentifier
  },
});

/**
 * Reset state associated with Reset Password screen
 */
export const resetChangePasswordState = () => ({
  type: RESET_CHANGE_PASSWORD_STATE,
});

export const saveOTP = (otp) => ({
  type: SAVE_OTP,
  payload: { otp }
});

export const saveOTPReceiveType = (otp_type) => ({
  type: SAVE_OTP_RECEIVE_TYPE,
  payload: { otp_type }
});

/**
 * Reset state associated with signup logic
 */
export const resetSignupState = () => ({
  type: RESET_SIGNUP_STATE,
});



/**
 * Initiate signup api request. Only one value is needed
 * between email or phoneNumber, not both.
 *
 * @param {string} email       - Email with which user want to signup
 * @param {string} phoneNumber - Phone number with which user want to signup
 * @param {string} password    - Password of the account
 * @param {string} userRole    - User type for the new account, by default will be `member`
 */
export const signup = (email, phoneNumber, password, userRole = 'member') => ({
  type: SIGNUP_REQUEST,
  payload: {
    email,
    phoneNumber,
    password,
    userRole,
  },
});

/**
 * Initiate verify otp api request.
 *
 * @param {string} otp - otp send to phone for cross-verify
 */
export const verifyOtp = (otp, userIdentifier, ipAddress) => ({
  type: VERIFY_OTP_REQUEST,
  payload: {
    otp,
    userIdentifier,
    ipAddress
  },
});

/**
 * Initiate new otp api request. New otp can be requested
 * for registration process or password reset process.
 * Value of otyType can be `registration` or `resetPassword`
 *
 * @param {string} otpType - Tells whether the action is dispatch for registeration or password reset
 */
export const resendOtp = (otpType, userIdentifier) => ({
  type: RESEND_OTP_REQUEST,
  payload: {
    otpType,
    userIdentifier
  },
});


/**
 * Reset state associated with Otp Verification screen
 */
export const resetOtpVerificationState = () => ({
  type: RESET_OTP_VERIFICATION_STATE,
});
/***
 * user interest
 */

export const fetchInterestList = () => ({
  type: INITIAlIZE_INTEREST_REQUEST,
});
/***
 * update profile
 */

/**
 * Action dispatch to logout user, and enter guest mode.
 */
export const logout = (isSessionExpired = false) => ({
  type: LOGOUT,
  payload: {
    isSessionExpired,
  },
});

