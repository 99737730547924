import { styled, Button as MuiButton } from '@mui/material';
import styles from './Button.module.scss';

// const Button = styled(MuiButton)(({ theme }) => ({
//   borderRadius: '6px',
//   color: '#fff',
//   border: '2px solid$primaryColor',
//   backgroundColor: '#0D5984',
//   textTransform: 'none',
//   fontFamily: 'Roboto',
//   fontStyle: 'normal',
//   fontWeight: '700',
//   fontSize: '16px',
//   '&:hover': {
//     backgroundColor: '#0D5984',
//     border: '2px solid$primaryColor',
//     borderRadius: '6px',
//   },
// }));

const Button = ({ children, variant, size, onClick, id }) => {
  return (
    <MuiButton
      id={id}
      className={styles.customButton}
      variant={variant}
      size={size}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
};

const ButtonSeeAll = ({ children, variant, onClick, id }) => {
  return (
    <MuiButton
      id={styles["btn-see-all"]}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
};

const ButtonOutlined = styled(MuiButton)(({ theme }) => ({
  borderRadius: '6px',
  color: '#979DA0',
  border: '2px solid #FFB703',
  backgroundColor: '#E0E0E0',
  textTransform: 'Capitalize',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#E0E0E0',
    border: '2px solid #FFB703',
  },
}));

const ButtonSkinny = styled(MuiButton)(({ theme }) => ({
  borderRadius: '6px',
  color: '#FFFFFF',
  border: '1px solid #FFB703',
  backgroundColor: '#FFB703',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: 1,
  '&:hover': {
    backgroundColor: '#FFB703',
    border: '1px solid #FFB703',
  },
}));

const ButtonGrey = styled(MuiButton)(({ theme }) => ({
  borderRadius: '6px',
  color: '#010D18',
  border: '1px solid #E0E0E0',
  backgroundColor: '#E0E0E0',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: 1,
  '&:hover': {
    backgroundColor: '#E0E0E0',
    border: '1px solid #E0E0E0',
  },
}));

export { Button, ButtonSkinny, ButtonGrey, ButtonOutlined, ButtonSeeAll };
