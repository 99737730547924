import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  MenuItem,
  FormControl,
  Select,
  Grid,
  CircularProgress,
  Rating
} from '@mui/material';
import {
  ARTICLE_SK,
  ATTRACTION_SK,
  HOTEL_SK,
  LIMITS,
  POST_SK,
  RESTAURANT_SK,
} from '../../../../constants';
import { translate } from '../../../../i18n';
import styles from './SavedTab.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Status } from '../../../../api';
import {
  Unsave,
  Snack,
  RatingStar
} from '../../../../common';
import { history } from "../../../../store";
import {
  fetchFavourites,
  updateFavourites,
  resetFavouriteStatusToDefault
} from '../../../../store/actions';
import { connect } from 'react-redux';

const ArticleItem = ({ item }) => {
  const {
    articleIdentifier,
    coverImg,
    isThumbGenerated,
    avgRating,
    articleTitle,
  } = item;

  const goToDetails = () => {
    history.push(`/${btoa(articleIdentifier)}/${ARTICLE_SK}`);
  };

  return (
    <div className={styles.item} onClick={goToDetails}>
      <img
        alt="img"
        src={
          isThumbGenerated
            ? coverImg.url.thumbUrl
            : coverImg.url.originalUrl
        }
      />
      <div>
        <div>
          <div className={styles.bold}>{articleTitle}</div>
          {/* {shortDescription.slice(0, 50)}&nbsp; */}
          {/* <strong>...{translate('common.readMore')}</strong> */}
        </div>
        <div>
          <RatingStar
            rating={avgRating}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

const AttractionItem = ({ item }) => {
  const {
    attractionIdentifier,
    attractionTitle,
    city,
    isThumbGenerated,
    coverImg,
    avgRating,
  } = item;

  const goToDetails = () => {
    history.push(`/${btoa(attractionIdentifier)}/${ATTRACTION_SK}`);
  };

  return (
    <div className={styles.item} onClick={goToDetails}>
      <img
        alt="img"
        src={
          isThumbGenerated
            ? coverImg.url.thumbUrl
            : coverImg.url.originalUrl
        }
      />
      <div>
        <div className={styles.bold}>{attractionTitle}</div>
        <div className={styles.gray}>{city}</div>
        <div>
          <RatingStar
            rating={avgRating}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

const BusinessItem = ({ item }) => {
  const {
    businessIdentifier,
    entityType,
    businessName,
    city,
    isThumbGenerated,
    profileImg,
    avgRating,
  } = item;

  const goToDetails = () => {
    history.push(`/${btoa(businessIdentifier)}/${entityType}`);
  };

  return (
    <div className={styles.item} onClick={goToDetails}>
      <img
        alt="img"
        src={
          isThumbGenerated
            ? profileImg.url.thumbUrl
            : profileImg.url.originalUrl
        }
      />
      <div>
        <div className={styles.bold}>{businessName}</div>
        <div className={styles.gray}>{city}</div>
        <div>
          <RatingStar
            rating={avgRating}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};



const PostItem = ({ item }) => {
  const {
    businessName,
    // tagLine,
    isThumbGenerated,
    profileImg,
    avgRating,
    postIdentifier,
    postDescription,
    city
  } = item;
  const LIMIT = LIMITS.SAVED_POST_DESCRIPTION_MAX_LENGTH;

  const [readMore, setReadMore] = useState(false);

  const onReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <div
      className={styles.item}
      onClick={() => {
        history.push(`/${window.btoa(postIdentifier)}/${POST_SK}`);
      }}
    >
      <img
        alt="img"
        src={
          // isThumbGenerated
          // ?
          profileImg.url.thumbUrl
          // : profileImg.url.originalUrl
        }
      />
      <div>
        <div className={styles.bold}>{businessName}</div>
        <div className={styles.gray}>{city}</div>
        <div>
          {/* <Rating
            value={parseInt(avgRating)}
            precision={0.5}
            size="small"
            readOnly
          /> */}
          <RatingStar
            rating={avgRating}
            size="small"
          />
        </div>

        {postDescription?.length > LIMIT ? (
          <div className={styles.gray}>
            {postDescription.slice(0, LIMIT)}

            <span className="text-blue" onClick={onReadMore}>
              &nbsp;...{translate("common.readMore")}
            </span>
          </div>
        ) : (
          <div className={styles.gray}>{postDescription}</div>
        )}
      </div>
    </div>
  );
};

const SavedTab = ({
  fetchFavourites: _fetchFavourites,
  fetchFavouriteStatus,
  favourites,
  hasMore,
  logoutStatus,
  updateFavourites: _updateFavourites,
  resetFavouriteStatusToDefault: _resetFavouriteStatusToDefault,
}) => {

  const options = [
    {
      category: 'all',
      name: translate('common.all'),
    },
    {
      category: HOTEL_SK,
      name: translate('common.hotels'),
    },
    {
      category: RESTAURANT_SK,
      name: translate('common.restaurants'),
    },
    {
      category: ARTICLE_SK,
      name: translate('common.articles'),
    },
    {
      category: ATTRACTION_SK,
      name: translate('common.attractions'),
    },
    {
      category: POST_SK,
      name: translate("common.posts"),
    },
  ];

  const [filter, setFilter] = useState(options[0].category);
  const [page, setPage] = useState(1);
  const [snackUnsaveOpen, setSnackUnsaveOpen] = useState(false);

  useEffect(() => {
    if (logoutStatus === Status.DEFAULT && page === 1) {
      _fetchFavourites(filter, page);
    }
  }, [logoutStatus, filter, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    page !== 1 && _fetchFavourites(filter, page)
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    const filter = event.target.value;
    setFilter(filter);
    setPage(1);
  };

  const handleSnackUnsaveClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackUnsaveOpen(false);
    _resetFavouriteStatusToDefault();
  };

  // Update the current page to the next page
  const next = () => setPage(page + 1);

  // Show success Alert and Remove Unsaved Item from local state
  const successCallback = (unsavedItem) => {
    setSnackUnsaveOpen(true);
    _updateFavourites(unsavedItem);
  }

  const renderUnsaveIcon = (item) => {
    return (
      <div className={styles["saved-icon"]}>
        <Unsave item={item} successCallback={successCallback} />
      </div>
    )
  }

  const loader = (<div className={styles['saved-tab-loading']}>
    <CircularProgress size={22} className="loading_spinner" />
  </div>
  );

  if (fetchFavouriteStatus === Status.LOADING && filter === "all" && page === 1) {
    return loader;
  }

  return (
    <div className={styles['saved-tab-container']}>

      {
        favourites.length === 0 && fetchFavouriteStatus === Status.SUCCESS && page === 1 && filter === "all" ? "" : <div id={styles['filter-box']}>
          <FormControl>
            <Select
              className={styles.select_box}
              value={filter}
              size="small"
              IconComponent={KeyboardArrowDownIcon}
              onChange={handleChange}
            >
              {options.map((option, key) => {

                return (
                  <MenuItem
                    id={styles['user-profile-saved-filter-menu']}
                    key={key}
                    value={option.category}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      }
      {
        favourites.length === 0 && fetchFavouriteStatus === Status.SUCCESS ? (
          <div className={styles['nomore-data-info']}>
            <span>{translate('userProfile.noFavourite')}</span>
          </div>) : <div className={styles['main-content']}>
          <InfiniteScroll
            dataLength={favourites.length}
            next={next}
            hasMore={hasMore}
            loader={loader}
            scrollableTarget='profile-info-root'
          >
            <Grid container spacing={2}>
              {favourites.map((item, key) => {
                return (
                  <Grid
                    item
                    key={key}
                    xs={6}
                    sm={4}
                    sx={{ position: 'relative' }}

                  >
                    {renderUnsaveIcon(item)}
                    {(item.entityType === HOTEL_SK || item.entityType === RESTAURANT_SK) && (
                      <BusinessItem
                        item={item}
                        history={history}
                      />
                    )}
                    {item.entityType === POST_SK && (
                      <PostItem
                        item={item}
                        history={history}
                      />
                    )}
                    {item.entityType === ATTRACTION_SK && (
                      <AttractionItem
                        item={item}
                        history={history}
                      />
                    )}
                    {item.entityType === ARTICLE_SK && (
                      <ArticleItem
                        item={item}
                        history={history}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </div>
      }
      <Snack
        open={snackUnsaveOpen}
        onClose={handleSnackUnsaveClose}
        message={translate("homeScreen.unsaveFavouriteSuccessMessage")}
      />
    </div>
  );
};

const mapStateToProps = (({ profile, auth }) => {

  const { favourites, hasMore, fetchFavouriteStatus } = profile;
  const { logoutStatus } = auth;

  return {
    favourites,
    hasMore,
    fetchFavouriteStatus,
    logoutStatus
  }
})

export default connect(mapStateToProps, {
  fetchFavourites,
  updateFavourites,
  resetFavouriteStatusToDefault
})(SavedTab);
