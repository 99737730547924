/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  Rating,
  CircularProgress,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Badge,
  Avatar,
  Typography,
  Box,
  CardHeader,
  styled,
  TextField,
  Stack
} from '@mui/material';
import { translate } from '../../i18n';
import { api, Status } from '../../api';
import cancelIcon from '../../assets/images/Cross_Cancel.png';
import {
  ERROR_SK,
  SUCCESS_SK,
  BLOGGER_SK,
  REVIEW_FILE_DIR_SK,
  LIMITS
} from '../../constants';
import Snack from '../Snack/Snack';
import styles from './CreateEditReviewModal.module.scss';
import { connect } from 'react-redux';
import bloggerMarkIcon from "../../assets/images/icon_blogger_mark.png";
import addPhotoIcon from '../../assets/images/icon_add_review_photo.png';
import {
  getPeriodicTime,
  formattedDate,
  getDate,
  isObject
} from '../../utils';
import { mediaValidation } from '../../utils/validations';
import { Button } from '../Button/Button';
import CloseIcon from "@material-ui/icons/Close";

const { v4: uuidv4 } = require('uuid');

const DEFAULT_RATING = 5;

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #979DA0',
    },
    '& input': {
      fontSize: "16px",
      fontWeight: "400",
      color: "#000"
    },
    '& textarea': {
      fontSize: "16px",
      fontWeight: "400"
    }
  },
});

const CreateEditReviewModal = ({
  open,
  mode = 'create',
  type,
  identifier,
  rating: _rating,
  title: _title,
  description: _description,
  onClose: _onClose,
  loggedInUserIdentifier,
  userDetails,
  createdAt,
  updateReviewList,
}) => {

  const inputRef = useRef();
  const [rating, setRating] = useState(DEFAULT_RATING);
  const [title, setTitle] = useState(_title);
  const [description, setDescription] = useState(_description);
  const [files, setFiles] = useState([]);
  const [reviewStatus, setReviewStatus] = useState(Status.DEFAULT);
  const [tempToken, setTempToken] = useState('');
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [mediaUploadStatus, setMediaUploadStatus] = useState(Status.DEFAULT);
  const [reviewTitleError, setReviewTitleError] = useState(false);
  const [reviewDescriptionError, setReviewDescriptionError] = useState(false);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });

  useEffect(() => {
    // generate random token for complete post
    open && createTempToken();
  }, [open]);

  useEffect(() => {
    if (files.length === uploadedCount && mediaUploadStatus === Status.LOADING) {
      setMediaUploadStatus(Status.SUCCESS);
      addReview();
    }
    else if (mediaUploadStatus === Status.ERROR) {
      setUploadedCount(0);
      setUploadedMedia([]);
    }
  }, [uploadedCount]);

  useEffect(() => {
    if (mode === 'edit') {
      setRating(_rating);
      setTitle(_title);
      setDescription(_description);
    }
  }, [_rating, _title, _description]);

  useEffect(() => {
    if (files.length > 5) {
      setFiles(prevState => {
        return prevState.slice(0, 5);
      });
      handleError(translate('createReview.maxFileLimitMessage'));
    }
  }, [files]);// eslint-disable-line react-hooks/exhaustive-deps

  let clickable = true; // Avoid double click
  const handleSubmit = () => {
    if (!clickable) {
      return;
    }
    setReviewStatus(Status.LOADING);
    if (mode === 'create') {
      if (files.length > 0) {
        getPostPolicyAndUploadMedia();
      }
      else {
        addReview();
      }
    }
  };

  const addReview = () => {
    api
      .addReview({
        type,
        entityIdentifier: identifier,
        rating,
        title: title?.trim(),
        description: description?.trim(),
        identifier: uploadedMedia.length > 0 ? tempToken : null
      })
      .then(() => {
        setReviewStatus(Status.SUCCESS);
        resetState();
        updateReviewList(true);
        _onClose();
      })
      .catch(() => {
        setReviewStatus(Status.ERROR);
        handleError(translate('createReview.generalErrorMessage'));
      });
  };

  const handleTitleChange = (e) => {
    const title_ = e.target.value;
    setTitle(title_);
  };

  const handleDescriptionChange = (e) => {
    let description_ = e.target.value;
    if (description_.length >= 8000) {
      description_ = description_.slice(0, 8000);
    }
    setDescription(description_);
  };

  const handleFileInputChange = () => {
    let files = inputRef.current.files;
    for (let i = 0; i < files.length; i++) {
      handleFileChange(files[i]);
    }
  }

  const handleFileChange = file => {
    if (isValidateMedia(file)) {
      setFiles(prevState => {
        return [
          ...prevState,
          {
            id: file.size.toString(),
            file,
            preview: URL.createObjectURL(file),
          },
        ];
      });
    }
  };

  const isValidateMedia = media => {
    if (!mediaValidation(media)) {
      handleError(translate('createPost.invalidMedia'));
      return false;
    }
    if (checkMediaExistInList(media)) {
      handleError(translate('createPost.duplicateFileRemoved'));
      return false;
    }
    return true;
  };

  async function getPostPolicy() {
    try {
      const { userResponse } = await api.getPostPolicy({
        entityType: REVIEW_FILE_DIR_SK,
        entityIdentifier: tempToken,
      });

      return {
        imagePolicy: userResponse.image,
        videoPolicy: userResponse.video,
        policyCreatedAt: userResponse.policyCreatedAt,
      };
    } catch (error) {
      setMediaUploadStatus(Status.ERROR);
      handleError(translate('createPost.fileUploadFailedMessage'));
      setReviewStatus(Status.ERROR);
      return null;
    }
  }

  async function getPostPolicyAndUploadMedia() {
    setMediaUploadStatus(Status.LOADING);

    let postPolicy = await getPostPolicy();
    if (!isObject(postPolicy)) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i], postPolicy);
    }
  }

  function uploadFile(item, postPolicy) {
    const { imagePolicy, policyCreatedAt } = postPolicy;
    const { file } = item;
    return api
      .uploadMedia({
        file,
        policy: imagePolicy.base64policy,
        signature: imagePolicy.signature,
        policyCreatedAt,
        loggedInUserIdentifier,
        entityIdentifier: tempToken,
        fileDirectory: REVIEW_FILE_DIR_SK,
        localFileIdentifier: file.size.toString(),
      })
      .then(response => {
        setUploadedMedia(prevState => [
          ...prevState,
          { ...response.userResponse },
        ]);
        setUploadedCount(prevState => prevState + 1);
        return null;
      })
      .catch(e => {
        setMediaUploadStatus(Status.ERROR);
        handleError(translate('createPost.fileExtNotSupportedMessage'));
        setReviewStatus(Status.ERROR);
      });
  }

  const createTempToken = () => {
    setTempToken(uuidv4().split('-').join(''));
  }

  /**
  * check if media already present in uploaded list
  */
  function checkMediaExistInList(media) {
    return files.some(
      el => el.id === media.size.toString(),
    );
  };

  const removeFile = id => {
    clearInput();
    setFiles(prevState => {
      return prevState.filter(item => item.id !== id);
    });
  };

  const clearInput = () => {
    inputRef.current.value = '';
  }

  const handleError = (error) => {
    showSnack({
      type: ERROR_SK,
      message: error,
    });
  };

  const showSnack = ({ message, type }) => {
    setSnackData({
      open: true,
      type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleClose = () => {
    if (mode === 'create') {
      resetState();
    }
    _onClose();
  }

  const resetState = () => {
    setRating(DEFAULT_RATING);
    setTitle('');
    setDescription('');
    setFiles([]);
    setUploadedMedia([]);
    setUploadedCount(0);
    clearInput();
    setReviewStatus(Status.DEFAULT);
  };

  const renderHeader = () => {
    return (
      <>
        <IconButton id={styles['dialog-close-icon']} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box id={styles['create-review-title']}>
          <div>
            {translate('createReview.title')}
          </div>
          <Rating
            value={rating}
            size='small'
            name="simple-controlled"
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
      </>
    );
  };

  const renderContent = () => {
    const avatar = (userDetails.bio.userRole === BLOGGER_SK
      ? <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<img
          id={styles["create-post-badge"]}
          alt='badgeimg'
          src={bloggerMarkIcon}
        />}
      >
        <Avatar
          src={userDetails.bio.profileImg?.isThumbGenerated ?
            userDetails.bio.profileImg?.url?.thumbUrl
            : userDetails.bio.profileImg?.url?.originUrl}
          id={styles['create-post-blogger-avatar']}
        />
      </Badge>
      : <Avatar
        src={userDetails.bio.profileImg?.isThumbGenerated ?
          userDetails.bio.profileImg?.url?.thumbUrl
          : userDetails.bio.profileImg?.url?.originUrl}
        id={styles['create-post-avatar']}
      />
    );
    const avatarTitle = (
      <Typography
        id={styles['create-post-profile-name']}
      >
        {userDetails.bio.name}
      </Typography>
    );
    const subheader = (
      <Box id='create-review-subheader'>
        {mode === 'edit' ?
          getPeriodicTime(createdAt)
          : formattedDate(getDate().iso8601Date)
        }
      </Box>
    )

    return (
      <>
        <CardHeader
          avatar={avatar}
          title={avatarTitle}
          subheader={subheader}
          id={styles["card-header-style"]}
        />
        <CssTextField
          id="outlined-multiline-flexible"
          placeholder={translate('createReview.titleLabel')}
          fullWidth
          size='small'
          value={title}
          InputProps={{
            inputProps: {
              maxLength: LIMITS.REVIEW_TITLE_MAX_LENGTH,
            },
          }}
          onChange={(e) => handleTitleChange(e)}
        />
        <CssTextField
          id="outlined-multiline-flexible"
          placeholder={translate('createReview.descriptionLabel')}
          multiline
          minRows={6}
          maxRows={6}
          fullWidth
          size='small'
          value={description}
          onChange={(e) => handleDescriptionChange(e)}
          sx={{ pt: '16px' }}
        />
        {mode === 'create' &&
          <Stack
            direction='row'
            alignItems='center'
            sx={{ pt: '13px' }}
          >
            <label htmlFor='add-photo-input'>
              <input
                disabled={files.length >= 5}
                type="file"
                accept={[
                  'image/png',
                  'image/jpeg',
                ]}
                multiple
                hidden
                id="add-photo-input"
                name="add-photo-input"
                ref={inputRef}
                onChange={handleFileInputChange}
              />
              <img
                id={styles['create-review-photo-icon']}
                alt='icon'
                src={addPhotoIcon}
                style={{ opacity: files.length >= 5 ? 0.5 : 1 }}
              />
            </label>
            <div id={styles['create-review-photo-label']}>
              {translate('createReview.addPhoto')}
            </div>
          </Stack>
        }
        {files.length !== 0 &&
          <Box id={styles['create-review-photo-wrap']}>
            {files.map((file, key) => (
              <Box id={styles['create-review-photo-box']}>
                <IconButton
                  id={styles['close-button-style']}
                  onClick={() => removeFile(file.id)}
                  sx={{ pt: '3px' }}
                >
                  <CloseIcon />
                </IconButton>
                <img
                  id={styles['create-review-photo']}
                  alt='img'
                  src={file.preview}
                />
              </Box>
            ))}
          </Box>
        }
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Button
        id={styles[reviewTitleError ? "disable-button" : "button"]}
        variant='contained'
        size='small'
        onClick={handleSubmit}
        disabled={reviewTitleError}
      >
        {translate('createReview.save')}
      </Button>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        scroll="body"
        PaperProps={{
          id: styles['create-review-dialog'],
        }}
        onClose={handleClose}
      >
        {reviewStatus === Status.LOADING && (
          <div id={styles['loading-circle']}>
            <CircularProgress className="loading_spinner" />
          </div>
        )}
        <DialogTitle>{renderHeader()}</DialogTitle>
        <DialogContent>{renderContent()}</DialogContent>
        <DialogActions id={styles['footer-container']}>{renderFooter()}</DialogActions>
        <Snack
          open={snackData.open}
          type={snackData.type}
          message={snackData.message}
          onClose={handleSnackClose}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ profile }) => {

  const { userIdentifier: loggedInUserIdentifier, userDetails, } = profile;

  return {
    loggedInUserIdentifier,
    userDetails
  }
}

export default connect(mapStateToProps, null)(CreateEditReviewModal);
