import React, { useState } from 'react';
import { Button, Carousel } from '../../../../../common';
import {
  ARTICLE_SK,
  ATTRACTION_SK,
  BLOGGER_SK,
  HOTEL_SK,
  RESTAURANT_SK,
  RECOMMENDATIONS_LK,
} from '../../../../../constants';
import { translate } from '../../../../../i18n';
import SnackBar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  ArticleListItem,
  BloggerListItem,
  BusinessListItem,
} from './components';
import { Box } from '@mui/material';
import { withRouter } from 'react-router';
import { isNonEmptyString } from '../../../../../utils';
import styles from './RandomSection.module.scss';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RandomSection = ({
  items,
  type,
  baseMediaUrl,
  baseMediaThumbUrl,
  loggedInUserIdentifier,
  showLoginPrompt,
  history,
}) => {
  const [snackOpen, setOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');
  const handleOpenSanck = (message) => {
    setSnackMsg(message);
    setOpen(true);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSeeAllClicked = (type) => {
    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }

    switch (type) {
      case ATTRACTION_SK:
        history.push(`/list/${type}s/${RECOMMENDATIONS_LK}`);
        break;

      case HOTEL_SK:
      case RESTAURANT_SK:
        history.push(`/list/${type}s`, {
          state: 'flag',
        });
        break;

      default:
        history.push(`/list/${type}s`);
        break;
    }
  };

  const title =
    type === ARTICLE_SK
      ? translate('homeScreen.popularArticles')
      : type === ATTRACTION_SK
        ? translate('homeScreen.recommendations')
        : type === HOTEL_SK
          ? translate('common.restTime')
          : type === RESTAURANT_SK
            ? translate('common.mealTime')
            : translate('common.bloggers');

  const renderTitle = (type) => (
    <Box className={styles.titleBox}>
      <Box className={styles.title}>
        <span>{title}</span>
      </Box>
      <Box className={styles.seeMoreBox}>
        <Button
          id={styles['random-seemore']}
          variant="contained"
          size="small"
          onClick={() => handleSeeAllClicked(type)}
        >
          {translate('common.seeAll')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      {type !== undefined && items.length !== 0 && (
        <div className={styles['random-section']}>
          {renderTitle(type)}
          <div className={styles['list']}>
            <Carousel
              items={items}
              count={2}
              md={2}
              sm={1}
              type={type}
              baseMediaUrl={baseMediaUrl}
              baseMediaThumbUrl={baseMediaThumbUrl}
              loggedInUserIdentifier={loggedInUserIdentifier}
              component={
                type === HOTEL_SK || type === RESTAURANT_SK
                  ? BusinessListItem
                  : type === BLOGGER_SK
                    ? BloggerListItem
                    : ArticleListItem
              }
              openSnack={handleOpenSanck}
              showLoginPrompt={showLoginPrompt}
            />
          </div>
        </div>
      )}
      <SnackBar
        id="my-snack"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          className={styles['alertColor']}
        >
          {snackMsg}
        </Alert>
      </SnackBar>
    </>
  );
};

export default withRouter(RandomSection);
