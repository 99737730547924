/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from "react";
import styles from "./ArticleDetails.module.scss";
import { Redirect, useParams, withRouter } from "react-router";
import Carousel from "react-multi-carousel";
import {
  BackIcon,
  BackToTopIcon,
  CopyLinkIcon,
  // CreatePostIcon,
  CreateReviewIcon,
  RatingStar,
  Spinner,
} from "../../common";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
} from "@mui/material";
import myFlag from "../../assets/countryFlags/Myanmar_95.png";
import enFlag from "../../assets/countryFlags/United_Kingdom_44.png";
// import saveIcon from "../../assets/images/icon_save.png";
// import saveFilledIcon from "../../assets/images/icon_save_filled.png";
// import shareIcon from "../../assets/images/icon_share.png";
// import openingIcon from "../../assets/images/icon_opening_text.png";
// import closingIcon from "../../assets/images/icon_closing_text.png";
// import moreReviewIcon from "../../assets/images/icon_more_review.png";
// import lessReviewIcon from "../../assets/images/icon_less_review.png";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  CssBaseline,
} from "@material-ui/core";
import { getCurrentLocale, translate } from "../../i18n";
import { api, Status } from "../../api";
import {
  ARTICLE_SK,
  ASYNC_STORAGE_KEYS,
  ATTRACTION_SK,
  // BLOGGER_SK,
  BURMESE_LANGUAGE_CODE_LK,
  DESCENDING_SK,
  ENGLISH_LANGUAGE_CODE_LK,
  HOTEL_SK,
  LIMITS,
  NEARBY_ATTRACTIONS_LK,
  NEARBY_LK,
  RESTAURANT_SK,
  SAVE_SK,
  UNSAVE_SK,
} from "../../constants";
import { connect } from "react-redux";
import { isNonEmptyString, shuffle } from "../../utils";
import {
  saveFavourite,
  deleteFavourite,
  resetSharePostStatusToDefault,
} from "../../store/actions";
import SnackBar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditShareModal from "../../common/EditShareModal/EditShareModal";
import { AppBar } from "../../common";
import { Grid } from "@mui/material";
import { People as PeopleIcon } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Snack } from "../../common";
import cn from "classnames";
import { updateViewCount } from "../../store/actions";
import Reviews from "../Reviews/Reviews";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 800, min: 441 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  xmobile: {
    breakpoint: { max: 441, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const FIRST_PAGE = 1;
const loggedInUserIdentifier = localStorage.getItem(
  ASYNC_STORAGE_KEYS.userIdentifier
);

const ArticleDetails = ({
  history,
  baseMediaUrl,
  baseMediaThumbUrl,
  favouriteStatus,
  favouriteIdentifier,
  sharePostStatus,
  saveFavourite: _saveFavourite,
  deleteFavourite: _deleteFavourite,
  resetSharePostStatusToDefault: _resetSharePostStatusToDefault,
  updateViewCount: _updateViewCount,
}) => {
  let { id: _articleIdentifier } = useParams();
  const articleIdentifier = window.atob(_articleIdentifier);
  const c = new URLSearchParams(window.location.search).get("c");
  const [language, setLanguage] = useState(getCurrentLocale());
  const [favApiStatus, setFavApiStatus] = useState(Status.DEFAULT);
  const [articleStatus, setArticleStatus] = useState(Status.DEFAULT);
  const [article, setArticle] = useState({});
  const [businessesStatus, setBusinessesStatus] = useState(Status.DEFAULT);
  const [businesses, setBusinesses] = useState({
    hotels: [],
    restaurants: [],
  });
  /**
   * viewCountFlag => view count need to be increase only for pull 2 refresh
   * and first time api hit, if user hit translate button, view count
   * should not increase
   */
  const [recommendedAttractionsStatus, setRecommendedAttractionsStatus] =
    useState(Status.DEFAULT);
  const [recommendedAttractions, setRecommendedAttractions] = useState([]);
  const [page, setPage] = useState(FIRST_PAGE);
  const [reviewsState, setReviewsState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { apiStatus: Status.DEFAULT, data: [], count: 0 }
  );
  const [readMore, setReadMore] = useState(false);
  const [snackOpen, setOpen] = useState(false);
  const [shareSnackOpen, setShareSanckOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [shareFlag, setShareFlag] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  // const [reviewDetailsOpen, setReviewDetailsOpen] = useState(false);
  //const [selectedReview, setSelectedReview] = useState({});
  const [expand, setExpandReview] = useState(false);
  //const [_favouriteIdentifier, setFavouriteIdentifier] = useState(favouriteIdentifier);
  const [copySnackOpen, setCopySnackOpen] = useState(false);
  const [snackSaveOpen, setSnackSaveOpen] = useState(false);
  const [snackUnsaveOpen, setSnackUnsaveOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isReviewListUpdated, setIsReviewListUpdated] = useState(false);

  useEffect(() => {
    //increase view count after 5 sec
    setTimeout(() => {
      increaseViewCount();
      readMoreApi();
    }, 5000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const readMoreApi = () => {
    api.readMore({
      readEntity: ARTICLE_SK,
      identifier: articleIdentifier,
    });
  };

  const increaseViewCount = () => {
    setArticle((prevState) => {
      const updatedView = prevState.totalViews + 1;
      _updateViewCount(articleIdentifier, updatedView);
      return {
        ...prevState,
        totalViews: prevState.totalViews + 1,
      };
    });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleShareSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShareSanckOpen(false);
  };

  const handleCopySnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopySnackOpen(false);
  };

  const handleOpenShareModal = () => {
    setShareFlag(true);
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  const updateReviewList = (updated) => {
    setIsReviewListUpdated(updated ? true : false);
  };

  useEffect(() => {
    setArticleStatus(Status.LOADING);
    api
      .article({
        articleIdentifier,
        language,
        updateViewCount: 1,
      })
      .then(({ userResponse }) => {
        setArticle(userResponse);
        setArticleStatus(Status.SUCCESS);

        setDescription(userResponse.description);
        document.getElementById("description-div").innerHTML =
          userResponse.description;
        document.getElementById("description-preview-div").innerHTML =
          userResponse.description.slice(
            0,
            LIMITS.ARTICLE_DETAILS_DESCRIPTION_MAX_LENGTH
          ) + " ";

        //setViewCountFlag(false);

        setRecommendedAttractionsStatus(Status.LOADING);
        api
          .fetchRecommendedAttractions({
            identifier: articleIdentifier,
          })
          .then(({ userResponse }) => {
            setRecommendedAttractions(userResponse);
            setRecommendedAttractionsStatus(Status.SUCCESS);
          })
          .catch(() => setRecommendedAttractionsStatus(Status.ERROR));

        if (reviewsState.apiStatus === Status.DEFAULT) {
          refreshList();
        }

        if (businessesStatus === Status.DEFAULT) {
          fetchBusinesses();
        }
      })
      .catch(() => {
        setArticleStatus(Status.ERROR);
      });
  }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      page !== 1 &&
      articleStatus === Status.SUCCESS &&
      reviewsState.apiStatus !== Status.DEFAULT
    ) {
      refreshList(page);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBusinesses = () => {
    setBusinessesStatus(Status.LOADING);
    api
      .fetchBusinessByArticle({
        articleIdentifier,
      })
      .then(({ userResponse: businesses }) => {
        let hotels =
          businesses.hotel.length > 0 ? shuffle(businesses.hotel) : [];
        let restaurants =
          businesses.restaurant.length > 0
            ? shuffle(businesses.restaurant)
            : [];
        let _businesses = {
          hotels,
          restaurants,
        };
        setBusinesses(_businesses);
        setBusinessesStatus(Status.SUCCESS);
      })
      .catch(() => setBusinessesStatus(Status.ERROR));
  };

  const refreshList = (_page = 1) => {
    setReviewsState({ apiStatus: Status.LOADING });
    api
      .fetchReviews({
        type: ARTICLE_SK,
        identifier: articleIdentifier,
        page: _page,
        sortOrder: DESCENDING_SK,
      })
      .then(({ userResponse: { reviewList, totalReviewCount } }) => {
        if (_page === 1) {
          setReviewsState({
            data: reviewList,
            count: totalReviewCount,
            apiStatus: Status.SUCCESS,
          });
        } else {
          setReviewsState({
            data: [...reviewsState.data, ...reviewList],
            apiStatus: Status.SUCCESS,
          });
        }
      })
      .catch(() => {
        setReviewsState({ apiStatus: Status.ERROR });
      });
  };

  const save = () => {
    if (favouriteStatus !== Status.LOADING) {
      setFlag(true);
      if (article?.isSaved === SAVE_SK) {
        _saveFavourite(
          article?.articleIdentifier,
          ARTICLE_SK,
          loggedInUserIdentifier
        );
      } else {
        _deleteFavourite(
          article?.favouriteIdentifier,
          ARTICLE_SK,
          article?.articleIdentifier
        );
      }
    }
  };

  const handleSnackSaveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackSaveOpen(false);
  };

  const handleSnackUnsaveClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackUnsaveOpen(false);
  };

  const handleOnSave = (item) => {
    if (favApiStatus !== Status.LOADING) {
      setFavApiStatus(Status.LOADING);
      const recommend = recommendedAttractions.map((attr) => {
        if (attr.identifier === item.identifier) {
          return {
            ...attr,
            isSaved: attr.isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK,
          };
        } else {
          return attr;
        }
      });
      setRecommendedAttractions(recommend);

      if (item.isSaved === SAVE_SK) {
        api
          .saveFavourite({
            entityIdentifier: item.identifier,
            entityType: ATTRACTION_SK,
            userIdentifier: loggedInUserIdentifier,
          })
          .then(({ userResponse: { favouriteIdentifier } }) => {
            setFavApiStatus(Status.SUCCESS);
            setSnackSaveOpen(true);
            const recommend = recommendedAttractions.map((attr) => {
              if (attr.identifier === item.identifier) {
                return {
                  ...attr,
                  favouriteIdentifier,
                  isSaved: attr.isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK,
                };
              } else {
                return attr;
              }
            });

            setRecommendedAttractions(recommend);
          })
          .catch(() => {
            setFavApiStatus(Status.ERROR);
          });
      } else {
        api
          .deleteFavourite({
            favouriteIdentifier: item.favouriteIdentifier,
          })
          .then(() => {
            setFavApiStatus(Status.SUCCESS);
            setSnackUnsaveOpen(true);
            const recommend = recommendedAttractions.map((attr) => {
              if (attr.identifier === item.identifier) {
                return {
                  ...attr,
                  favouriteIdentifier: "",
                  isSaved: SAVE_SK,
                };
              } else {
                return attr;
              }
            });

            setRecommendedAttractions(recommend);
          })
          .catch(() => {
            setFavApiStatus(Status.ERROR);
          });
      }
    }
  };

  useEffect(() => {
    if (favouriteStatus === Status.SUCCESS) {
      let _article = {
        ...article,
        favouriteIdentifier: favouriteIdentifier,
        isSaved: article.isSaved === SAVE_SK ? UNSAVE_SK : SAVE_SK,
      };
      setArticle(_article);
      if (flag) {
        setOpen(true);
        setFlag(false);
      }
    }
  }, [favouriteStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sharePostStatus === Status.SUCCESS) {
      if (shareFlag) {
        setShareSanckOpen(true);
        setShareFlag(false);
        _resetSharePostStatusToDefault();
      }
    }
  }, [sharePostStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeLanguage = () => {
    setLanguage((prevState) => {
      if (prevState === ENGLISH_LANGUAGE_CODE_LK) {
        return BURMESE_LANGUAGE_CODE_LK;
      }
      return ENGLISH_LANGUAGE_CODE_LK;
    });
    setExpandReview(false);
  };

  const updateAvgRating = (avgRating) => {
    setArticle((prevState) => {
      return {
        ...prevState,
        avgRating: avgRating,
      };
    });
  };

  // const handleLoadMoreReviews = () => {
  //   if (reviewsState.apiStatus !== Status.LOADING) {
  //     setPage(prevState => prevState + 1);
  //   }
  // };

  // const seeReviewDetails = review => {
  //   setSelectedReview(review);
  //   setReviewDetailsOpen(true);
  // }

  // const updateReviewList = (isHasNew) => {
  //   if (isHasNew) {
  //     refreshList();
  //   }
  // };

  const goToBusinessList = (type) => {
    history.push(`/list/${type}s`);
  };

  const goToBusinessProfile = (type, identifier) => {
    history.push(`/${btoa(identifier)}/${type}`);
  };

  if (!isNonEmptyString(localStorage.getItem(ASYNC_STORAGE_KEYS.sessionId))) {
    return <Redirect to="/" />;
  }

  // show loaded on center of screen if going first time on screen
  if (articleStatus === Status.LOADING) {
    return <Spinner />;
  }

  if (articleStatus === Status.ERROR) {
    return <Redirect to="/404" />;
  }

  // const renderAvatar = ({ userDetails }) => {
  //   return userDetails.userRole === BLOGGER_SK ? (
  //     <Badge
  //       overlap="circular"
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "right",
  //       }}
  //       badgeContent={
  //         <img id="create-post-badge" alt="badgeimg" src={bloggerMarkIcon} />
  //       }
  //     >
  //       <Avatar
  //         src={`${
  //           userDetails.profileImg?.isThumGenerated
  //             ? baseMediaThumbUrl
  //             : baseMediaUrl
  //         }${userDetails.profileImg?.url}`}
  //         id="create-post-blogger-avatar"
  //       />
  //     </Badge>
  //   ) : (
  //     <Avatar
  //       src={`${
  //         userDetails.profileImg?.isThumGenerated
  //           ? baseMediaThumbUrl
  //           : baseMediaUrl
  //       }${userDetails.profileImg?.url}`}
  //       id="create-post-avatar"
  //     />
  //   );
  // };

  const renderHeading = () => (
    <div id={styles.heading}>
      <Grid container id={styles.heading_grid_root}>
        <Grid item xs={12} md={5}>
          <Grid container direction="column" id={styles.description_grid}>
            <Grid item xs={2} sm={3} />
            <Grid item xs={7} sm={6}>
              <div id={styles.description}>
                <div id={styles.description_title}>{article.title}</div>
                <br />
                <div id={styles.short_description}>
                  {article.shortDescription}
                </div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} id={styles.bottom_grid_item}>
              <Stack direction="column" spacing={2} alignItems="flex-end">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Stack
                    direction="row"
                    columnGap={1}
                    id={styles.views}
                    alignItems="flex-end"
                  >
                    <PeopleIcon id={styles.viewicon} />
                    <span id={styles.view_label}>
                      {`${article.totalViews} ${article.totalViews > 1
                        ? translate("articleDetails.views")
                        : translate("articleDetails.view")
                        }`}
                    </span>
                  </Stack>
                  <Stack
                    id={styles.views}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <RatingStar rating={article.avgRating} size="small" />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span id={styles.lang_label}>
                      {translate("articleDetails.seeTranslation")}
                    </span>
                    <img
                      alt="img"
                      id={styles.flag_icon}
                      src={
                        language === BURMESE_LANGUAGE_CODE_LK ? enFlag : myFlag
                      }
                      onClick={changeLanguage}
                    />
                  </Stack>
                  <CopyLinkIcon
                    text={`${article.copyLinkUrl}?c=1`}
                    onCopy={() => setCopySnackOpen(true)}
                  />
                  <IconButton
                    onClick={save}
                    className={styles.save_icon_button}
                  >
                    {article?.isSaved === SAVE_SK ? (
                      <BookmarkBorderIcon
                        className={styles["save-icon-style"]}
                      />
                    ) : (
                      <BookmarkIcon className={styles["save-icon-style"]} />
                    )}
                  </IconButton>
                  {/* <img
                    alt='img'
                    id='share-icon'
                    src={shareIcon}
                    onClick={handleOpenShareModal}
                  /> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={styles.cover_img_wrap}>
            <img
              id={styles.cover_img}
              alt="img"
              src={article?.coverImg?.url?.originalUrl}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  const renderDescription = () => (
    <div>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div
            className={styles["description-section"]}
            onClick={() => setReadMore(!readMore)}
          >
            <span id="description-div" hidden={!readMore}></span>
            <span id="description-preview-div" hidden={readMore}></span>
            {!readMore &&
              description.length >
              LIMITS.ARTICLE_DETAILS_DESCRIPTION_MAX_LENGTH && (
                <span className={cn(styles.more, "text-blue")}>
                  ...{translate("common.readMore")}
                </span>
              )}
          </div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );

  const renderNearbyAttractions = () => (
    <div className={styles["attraction-container"]}>
      <div className={styles["attraction-section"]}>
        {recommendedAttractions.length !== 0 && (
          <Grid
            container
            spacing={{
              xs: 1,
              md: 2,
            }}
          >
            <Grid item xs={1} />
            <Grid item xs={10}>
              <div className={styles["nearby-attraction-wrapper"]}>
                <div className={styles["nearby-attraction-title"]}>
                  {translate("articleDetails.nearbyAttractionsTitle")}
                </div>
                <div
                  className={styles["button"]}
                  onClick={() =>
                    history.push(`/list/attractions/${NEARBY_LK}`, {
                      state: article?.state,
                    })
                  }
                >
                  {translate("common.seeAll")}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <Carousel
          swipeable={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          draggable={true}
          centerMode={true}
          showDots={false}
          height={310}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          className={styles["carousel-container"]}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
        >
          {recommendedAttractions.map((attraction, key) => {
            return (
              <div key={key} id={styles["attraction-item"]}>
                <IconButton
                  id={styles["recommend-save-icon"]}
                  onClick={() => handleOnSave(attraction)}
                  tabindex="-1"
                >
                  {attraction.isSaved === SAVE_SK ? (
                    <BookmarkBorderIcon className={styles["save-icon-style"]} />
                  ) : (
                    <BookmarkIcon className={styles["save-icon-style"]} />
                  )}
                </IconButton>
                <img
                  alt="attraction-img"
                  src={
                    attraction.coverImg.isThumbGenerated
                      ? attraction.coverImg.url.thumbUrl
                      : attraction.coverImg.url.originalUrl
                  }
                  className={styles["attraction-img"]}
                />
                <div id={styles["attraction-name"]}>
                  <span>{attraction.name}</span>
                </div>
                <div
                  id={styles["img-protected"]}
                  onClick={() =>
                    history.push(
                      `/${btoa(attraction.identifier)}/${ATTRACTION_SK}`
                    )
                  }
                ></div>
                <div id={styles["attraction-box"]}>
                  <div
                    id={styles["attraction-overlay"]}
                    onClick={() =>
                      history.push(
                        `/${btoa(attraction.identifier)}/${ATTRACTION_SK}`
                      )
                    }
                  >
                    <p className={styles["attraction-short-description"]}>
                      {attraction.shortDescription}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );

  // const renderReviews = () => (
  //   <Grid container>
  //     <Grid item xs={1} />
  //     <Grid item xs={10}>
  //       <div className='review-section'>
  //         <Accordion>
  //           <AccordionSummary
  //             aria-controls="panel1a-content"
  //             id="panel1a-header"
  //           >
  //             <div className='expand' onClick={() => setExpandReview(!expand)}>
  //               <span id='expand-label'>{translate('common.reviews')}</span>
  //               <div><img alt='img' src={expand ? lessReviewIcon : moreReviewIcon} className='more-review-icon' /></div>
  //             </div>

  //           </AccordionSummary>
  //           <AccordionDetails>
  //             {reviewsState.apiStatus === Status.LOADING
  //               ? <div style={{ flex: 1 }}><Spinner /></div>
  //               : reviewsState.apiStatus === Status.SUCCESS && reviewsState.data.length === 0
  //                 ? <div className='no-review-label'> {translate('businessProfile.noReview')}</div>
  //                 : <div className='review-details'>
  //                   <div className='details'>
  //                     <Grid container spacing={{
  //                       xs: 0,
  //                       sm: 2,
  //                       md: 2
  //                     }}>
  //                       {reviewsState.data.map((item, key) => {
  //                         return (
  //                           <Grid key={key} item xs={12} sm={6} md={4}>
  //                             <div className='details-item'>
  //                               <div className='preview-text bubble bubble-bottom-left' onClick={() => seeReviewDetails(item)}>
  //                                 <div className='preview-item'>
  //                                   <div>
  //                                     <div style={{ textAlign: 'center' }}>
  //                                       <Rating readOnly value={item.rating} precision={0.5} size='small' />
  //                                     </div>
  //                                     {item.description &&
  //                                       <div>
  //                                         <img alt='img' src={openingIcon} style={{ width: '15px', }} /><br />
  //                                         <span className='text'>
  //                                           {item.description?.length > LIMITS.REVIEW_PREVIEW_MAX_LENGTH
  //                                             ? <>
  //                                               <span>
  //                                                 {`${item.description?.slice(0, LIMITS.REVIEW_PREVIEW_MAX_LENGTH)}... `}
  //                                               </span>
  //                                               <span style={{ color: '#010D18', fontWeight: '600', cursor: 'pointer' }}>{translate('common.more')}</span>
  //                                             </>
  //                                             : <span>{item.description}</span>
  //                                           }
  //                                         </span>&nbsp;&nbsp;
  //                                         <img alt='img' src={closingIcon} style={{ width: '15px', position: 'absolute', }} />
  //                                       </div>
  //                                     }
  //                                     <div>
  //                                       {item.mediaUrl.length !== 0 &&
  //                                         <div style={{ display: 'gird', gridTemplateColumns: 'auto auto auto', padding: '20px 20px 0', textAlign: 'center' }}>
  //                                           {item.mediaUrl.map((media, key) => {
  //                                             return <img key={key} alt='img' src={`${baseMediaUrl}${media.filePath}`} style={{ width: '70px', height: '70px', objectFit: 'cover', margin: '3px', borderRadius: '5px' }} />
  //                                           })}
  //                                         </div>
  //                                       }
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                               <div className='person-info'>
  //                                 {renderAvatar(item)}
  //                                 <div className='info'>
  //                                   <div className='name'>{item.userDetails.name}</div>
  //                                   <div className='city'>{item.userDetails.location}</div>
  //                                   <div className='time'>{getPeriodicTime(item.createdAt)}</div>
  //                                 </div>
  //                               </div>
  //                             </div>
  //                           </Grid>
  //                         )
  //                       })}
  //                     </Grid>
  //                   </div>
  //                   {reviewsState.data.length !== reviewsState.count && <div style={{ display: 'flex', justifyContent: 'center', }}>
  //                     <Button
  //                       id='seemore-btn'
  //                       variant='contained'
  //                       size='small'
  //                       onClick={handleLoadMoreReviews}
  //                     >
  //                       {translate('common.seeMore')}
  //                     </Button>
  //                   </div>}
  //                 </div>}
  //           </AccordionDetails>
  //         </Accordion>
  //       </div>
  //     </Grid>
  //     <Grid item xs={1} />
  //   </Grid>
  // );

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const renderNearbyHotels = () => (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          variant="quilted"
          cols={4}
          gap={12}
        >
          {businesses.hotels.slice(0, 5).map((item, key) =>
            key === 1 ? (
              <React.Fragment key={key}>
                <ImageListItem cols={1} id={styles["business-list-item"]}>
                  <div
                    id={styles["business-title"]}
                    className={language === "my" && styles.lineHight}
                  >
                    {translate("articleDetails.hotelsNearby").split(" ")[0]}
                    <br />
                    {translate("articleDetails.hotelsNearby").split(" ")[1]}
                  </div>
                </ImageListItem>
                <ImageListItem
                  cols={2}
                  id={styles["business-list-item"]}
                  onClick={() => goToBusinessProfile(HOTEL_SK, item.identifier)}
                >
                  <img
                    {...srcset(
                      `${item.isThumbGenerated
                        ? item?.coverImg.url.thumbUrl
                        : item?.coverImg.url.originalUrl
                      }`,
                      121,
                      1,
                      1
                    )}
                    alt={item.name}
                    loading="lazy"
                    className={styles["business-img"]}
                  />
                  <ImageListItemBar id={styles["item-bar"]} title={item.name} />
                </ImageListItem>
              </React.Fragment>
            ) : (
              <ImageListItem
                key={key}
                cols={key === 3 ? 2 : 1}
                id={styles["business-list-item"]}
                onClick={() => goToBusinessProfile(HOTEL_SK, item.identifier)}
              >
                <img
                  {...srcset(
                    `${item?.isThumbGenerated
                      ? item.coverImg.url.thumbUrl
                      : item.coverImg.url.originalUrl
                    }`,
                    121,
                    1,
                    1
                  )}
                  alt={item.name}
                  loading="lazy"
                  className={styles["business-img"]}
                />
                <ImageListItemBar id={styles["item-bar"]} title={item.name} />
              </ImageListItem>
            )
          )}
        </ImageList>
        {renderSeeMoreButton(HOTEL_SK)}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );

  const renderNearbyRestaurants = () => (
    <Grid container>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          variant="quilted"
          cols={4}
          gap={12}
        >
          <ImageListItem cols={1} id={styles["business-list-item"]}>
            <div
              id={styles["business-title"]}
              className={language === "my" && styles["lineHight"]}
            >
              {translate("articleDetails.restaurantsNearby").split(" ")[0]}
              <br />
              {translate("articleDetails.restaurantsNearby").split(" ")[1]}
            </div>
          </ImageListItem>
          {businesses.restaurants.slice(0, 5).map((item, key) => (
            <ImageListItem
              key={key}
              cols={key === 1 || key === 2 ? 2 : 1}
              id={styles["business-list-item"]}
              onClick={() =>
                goToBusinessProfile(RESTAURANT_SK, item.identifier)
              }
            >
              <img
                {...srcset(
                  `${item?.isThumbGenerated
                    ? item.coverImg.url.thumbUrl
                    : item.coverImg.url.originalUrl
                  }`,
                  121,
                  1,
                  1
                )}
                alt={item.name}
                loading="lazy"
                className={styles["business-img"]}
              />
              <ImageListItemBar id={styles["item-bar"]} title={item.name} />
            </ImageListItem>
          ))}
        </ImageList>
        {renderSeeMoreButton(RESTAURANT_SK)}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );

  const renderSeeMoreButton = (type) => (
    <div id={styles["seemore-wrapper"]}>
      <div className={styles["seemore"]} onClick={() => goToBusinessList(type)}>
        {translate("articleDetails.seeAll")}
      </div>
    </div>
  );

  const renderBusinessMobile = (data, type) => (
    <div className={styles["attraction-section"]}>
      <div>
        <Grid
          container
          spacing={{
            xs: 1,
            md: 2,
          }}
        >
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div className={styles["business-nearby"]}>
              {type === HOTEL_SK
                ? translate("articleDetails.hotelsNearby")
                : translate("articleDetails.restaurantsNearby")}
            </div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={12} />
        </Grid>
        <div>
          <Carousel
            swipeable={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            draggable={true}
            centerMode={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
          >
            {data.map((item, key) => {
              return (
                <div
                  key={key}
                  className={styles["attraction-card"]}
                  onClick={() => goToBusinessProfile(type, item.identifier)}
                >
                  <img
                    alt="img"
                    src={
                      item?.isThumbGenerated
                        ? item.coverImg.url.thumbUrl
                        : item.coverImg.url.originalUrl
                    }
                    className={styles["business-img"]}
                  />
                  <div id={styles["label"]}>{item.name}</div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );

  return (
    <div id="article-details">
      <CssBaseline />
      <AppBar />
      <div id="back-to-top-anchor" />
      <BackIcon isCopy={c} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderHeading()}
        </Grid>
        <Grid item xs={12}>
          {renderDescription()}
        </Grid>
        <Grid item xs={12}>
          {recommendedAttractions.length !== 0 && renderNearbyAttractions()}
        </Grid>
        {/* <Grid item xs={12} id='review-grid'>
          {renderReviews()}
        </Grid> */}
        <Grid item xs={12} id={styles['review-grid']}>
          <Reviews
            entityStatus={articleStatus}
            isReviewListUpdated={isReviewListUpdated}
            type={ARTICLE_SK}
            identifier={articleIdentifier}
          />
        </Grid>
        <Grid item xs={12}>
          <div id={styles["business-section"]}>
            <br />
            <div id={styles["business-desktop"]}>
              {businesses.hotels.length !== 0 && renderNearbyHotels()}
            </div>
            {businesses.hotels.length !== 0 && (
              <div id={styles["business-mobile"]}>
                {renderBusinessMobile(businesses.hotels, HOTEL_SK)}
                {renderSeeMoreButton(HOTEL_SK)}
              </div>
            )}
            <br />
            <div id={styles["business-desktop"]}>
              {businesses.restaurants.length !== 0 && renderNearbyRestaurants()}
            </div>
            {businesses.restaurants.length !== 0 && (
              <div id={styles["business-mobile"]}>
                {renderBusinessMobile(businesses.restaurants, RESTAURANT_SK)}
                {renderSeeMoreButton(RESTAURANT_SK)}
              </div>
            )}
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
      <CreateReviewIcon
        identifier={articleIdentifier}
        type={ARTICLE_SK}
        updateReviewList={updateReviewList}
        title={article.title}
        updateAvgRating={updateAvgRating}
      />
      {/* <CreatePostIcon /> */}
      <BackToTopIcon />
      {/* <ReviewDetails
        open={reviewDetailsOpen}
        review={selectedReview}
        baseMediaUrl={baseMediaUrl}
        baseMediaThumbUrl={baseMediaThumbUrl}
        handleClose={() => setReviewDetailsOpen(false)}
      /> */}
      <EditShareModal
        open={openShareModal}
        identifier={article.articleIdentifier}
        mode="share"
        type={ARTICLE_SK}
        handleClose={handleCloseShareModal}
      />
      <SnackBar
        id="my-snack"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          className={styles["alert-success"]}
        >
          {article?.isSaved === SAVE_SK
            ? translate("homeScreen.unsaveFavouriteSuccessMessage")
            : translate("homeScreen.saveFavouriteSuccessMessage")}
        </Alert>
      </SnackBar>
      <Snack
        open={snackSaveOpen}
        onClose={handleSnackSaveClose}
        message={translate("homeScreen.saveFavouriteSuccessMessage")}
      />
      <Snack
        open={snackUnsaveOpen}
        onClose={handleSnackUnsaveClose}
        message={translate("homeScreen.unsaveFavouriteSuccessMessage")}
      />
      <SnackBar
        id="my-snack"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={shareSnackOpen}
        autoHideDuration={2000}
        onClose={handleShareSnackClose}
      >
        <Alert
          onClose={handleShareSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {translate("homeScreen.postShareSuccessMessage")}
        </Alert>
      </SnackBar>
      <SnackBar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={copySnackOpen}
        autoHideDuration={2000}
        onClose={handleCopySnackClose}
      >
        <Alert
          onClose={handleCopySnackClose}
          severity="success"
          className={styles["alert-success"]}
        >
          {translate("postListItem.copyLinkSuccessMessage")}
        </Alert>
      </SnackBar>
    </div>
  );
};

const mapStateToProps = ({ app, home }) => {
  const { baseMediaUrl, baseMediaThumbUrl } = app;
  const { favouriteStatus, favouriteIdentifier, sharePostStatus } = home;
  return {
    baseMediaUrl,
    baseMediaThumbUrl,
    favouriteStatus,
    favouriteIdentifier,
    sharePostStatus,
  };
};
export default connect(mapStateToProps, {
  saveFavourite,
  deleteFavourite,
  resetSharePostStatusToDefault,
  updateViewCount,
})(withRouter(ArticleDetails));
