import React from 'react';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import Carousel from "react-multi-carousel";

const CommonCarousel = ({
  style,
  items,
  count = 4,
  md = 3,
  sm = 2,
  type,
  baseMediaUrl,
  loggedInUserIdentifier,
  component: Item,
  openSnack,
  showLoginPrompt,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: count,
      slidesToSlide: count // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 450 },
      items: md,
      slidesToSlide: md // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 450, min: 0 },
      items: sm,
      slidesToSlide: sm // optional, default to 1.
    }
  };
  return (
    <Carousel
      swipeable={true}
      autoPlay={false}
      autoPlaySpeed={5000}
      draggable={true}
      centerMode={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"

    >
      {items.map((item, key) => {
        return (
          <Item
            style={style}
            item={item}
            key={key}
            type={type}
            loggedInUserIdentifier={loggedInUserIdentifier}
            baseMediaUrl={baseMediaUrl}
            baseMediaThumbUrl={baseMediaUrl}
            openSnack={openSnack}
            showLoginPrompt={showLoginPrompt}
          />
        )
      })}
    </Carousel>
  );
};

export default CommonCarousel;