import { Status, } from '../../api';
import {
    FETCH_CONTRACTS_LOADING,
    FETCH_CONTRACTS_SUCCESS,
    FETCH_CONTRACTS_ERROR,
    FETCH_MORE_CONTRACTS_LOADING,
    FETCH_MORE_CONTRACTS_SUCCESS,
    FETCH_MORE_CONTRACTS_ERROR,
} from '../../constants';

const initialState = {
    hotels: [],
    restaurants: [],
    /**
     * Correspond to first set of business contracts
     */
    businessContractsStatus: Status.DEFAULT,
    businessContractsErrorMessage: '',
    /**
     * Correspond to loading more business contracts
     */
    moreBusinessContractsStatus: Status.DEFAULT,
    moreBusinessContractsErrorMessage: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CONTRACTS_LOADING:
            return {
                ...state,
                businessContractsStatus: Status.LOADING,
                businessContractsErrorMessage: '',
            };
        case FETCH_CONTRACTS_SUCCESS: {
            const { hotels, restaurants, } = payload;
            return {
                ...state,
                businessContractsStatus: Status.SUCCESS,
                businessContractsErrorMessage: '',
                hotels,
                restaurants,
            };
        }
        case FETCH_CONTRACTS_ERROR:
            return {
                ...state,
                businessContractsStatus: Status.ERROR,
                businessContractsErrorMessage: payload.errorMessage,
            };
        case FETCH_MORE_CONTRACTS_LOADING:
            return {
                ...state,
                moreBusinessContractsStatus: Status.LOADING,
                moreBusinessContractsErrorMessage: '',
            };
        case FETCH_MORE_CONTRACTS_SUCCESS: {
            const { hotels, restaurants, } = payload;
            return {
                ...state,
                moreBusinessContractsStatus: Status.SUCCESS,
                moreBusinessContractsErrorMessage: '',
                hotels: [...state.hotels, ...hotels],
                restaurants: [...state.restaurants, ...restaurants],
            };
        }
        case FETCH_MORE_CONTRACTS_ERROR:
            return {
                ...state,
                moreBusinessContractsStatus: Status.ERROR,
                moreBusinessContractsErrorMessage: payload.errorMessage,
            };
        default:
            return state;
    }
};
