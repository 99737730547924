import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core';
import {
  Dialog,
  IconButton,
  Grid,
  CircularProgress,
  TextField,
  styled,
} from '@mui/material';
import { translate } from '../../../../i18n';
import {
  ALBUM_FILE_DIR_SK,
  ERROR_SK,
  IMAGE_SK,
  LIMITS,
  SUCCESS_SK,
  VIDEO_SK,
} from '../../../../constants';
import uploadIcon from '../../../../assets/images/icon_upload.png';
import addPhotoIcon from '../../../../assets/images/icon_add_circle.png';
import { isNonEmptyString, isObject } from '../../../../utils';
import { api, Status } from '../../../../api';
import { mediaValidation } from '../../../../utils/validations';
import { Button, ButtonOutlined, Snack } from '../../../../common';
const { v4: uuidv4 } = require('uuid');

const maxFileCount = LIMITS.ALBUM_FILE_LIMIT;
// const maxImageSize = LIMITS.IMAGE_MAX_SIZE;
// const formats = ['gif', 'jpg', 'jpeg', 'png'];

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFB703',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FFB703',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#FFB703',
    },
  },
});

const CreateAlbumModal = ({
  open,
  loggedInUserIdentifier,
  close: _close,
  updateAlbumList: _updateAlbumList,
}) => {
  const classes = useStyles();
  let inputRef = null;
  const [files, setFiles] = useState([]);
  const [albumName, setAlbumName] = useState('');
  const [albumDescription, setAlbumDescription] = useState('');
  const [tempToken, setTempToken] = useState('');
  const [apiStatus, setApiStatus] = useState(Status.DEFAULT); // eslint-disable-line no-unused-vars
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [mediaUploadStatus, setMediaUploadStatus] = useState(Status.DEFAULT);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackData, setSnackData] = useState({
    open: false,
    type: SUCCESS_SK,
    message: '',
  });

  useEffect(() => {
    // generate random token for complete post
    open && createTempToken();
  }, [open]);

  useEffect(() => {
    if (
      files.length === uploadedCount &&
      mediaUploadStatus === Status.LOADING
    ) {
      setMediaUploadStatus(Status.SUCCESS);
      uploadAlbumData();
    } else if (mediaUploadStatus === Status.ERROR) {
      setUploadedCount(0);
      setUploadedMedia([]);
    }
  }, [uploadedCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkFileLimit();
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  const createTempToken = () => {
    setTempToken(uuidv4().split('-').join(''));
  };

  const createAlbum = () => {
    if (isNonEmptyString(albumName) && files.length > 0 && !loading) {
      setLoading(true);
      setMediaUploadStatus(Status.LOADING);
      let filesToUpload = files.map((el) => {
        return el.file;
      });
      getPostPolicyAndUploadMedia(filesToUpload);
    }
  };

  async function getPostPolicyAndUploadMedia(mediaList) {
    let postPolicy = await getPostPolicy();
    if (!isObject(postPolicy)) {
      return;
    }
    for (let i = 0; i < mediaList.length; i++) {
      uploadFile(mediaList[i], postPolicy);
    }
  }

  async function getPostPolicy() {
    try {
      const { userResponse } = await api.getPostPolicy({
        entityType: ALBUM_FILE_DIR_SK,
        entityIdentifier: tempToken,
      });
      return {
        imagePolicy: userResponse.image,
        videoPolicy: userResponse.video,
        policyCreatedAt: userResponse.policyCreatedAt,
      };
    } catch (error) {
      setMediaUploadStatus(Status.ERROR);
      handleError(translate('createPost.fileUploadFailedMessage'));
      return null;
    }
  }

  function uploadFile(item, postPolicy) {
    const { imagePolicy, videoPolicy, policyCreatedAt } = postPolicy;

    return api
      .uploadMedia({
        file: item,
        policy:
          item.type === 'video/mp4'
            ? videoPolicy.base64policy
            : imagePolicy.base64policy,
        signature:
          item.type === 'video/mp4'
            ? videoPolicy.signature
            : imagePolicy.signature,
        policyCreatedAt,
        loggedInUserIdentifier,
        entityIdentifier: tempToken,
        fileDirectory: ALBUM_FILE_DIR_SK,
        localFileIdentifier: item.size.toString(),
      })
      .then((response) => {
        setUploadedMedia((prevState) => [
          ...prevState,
          { ...response.userResponse },
        ]);
        setUploadedCount((prevState) => prevState + 1);
        return null;
      })
      .catch((e) => {
        setMediaUploadStatus(Status.ERROR);
        handleError(translate('createPost.fileExtNotSupportedMessage'));
      });
  }

  const uploadAlbumData = () => {
    setApiStatus(Status.LOADING);
    api
      .createAlbum({
        albumName,
        albumDescription,
        tempToken: uploadedMedia.length > 0 && tempToken,
      })
      .then((response) => {
        setApiStatus(Status.SUCCESS);
        setLoading(false);
        const {
          userResponse: { albumIdentifier },
        } = response;

        const album = {
          albumName,
          imageCount: uploadedMedia.filter((el) => el.fileType === IMAGE_SK)
            .length,
          videoCount: uploadedMedia.filter((el) => el.fileType === VIDEO_SK)
            .length,
          fileCount: uploadedMedia.length,
          filePath: uploadedMedia[0]?.path,
          fileType: uploadedMedia[0]?.fileType,
          identifier: albumIdentifier,
        };

        _updateAlbumList(album);
        _close();
        resetState();
      })
      .catch((error) => {
        setApiStatus(Status.ERROR);
        handleError(translate('createPost.fileUploadFailedMessage'));
      });
  };

  /**
   * check if media already present in uploaded list
   */
  function checkMediaExistInList(media) {
    return files.some(
      (el) =>
        el.file.size.toString() === media.size.toString() &&
        el.file.name === media.name
    );
  }

  const handleFileInputChange = () => {
    let files = inputRef.files;
    for (let i = 0; i < files.length; i++) {
      handleFileChange(files[i]);
    }
  };

  const handleFileChange = (file) => {
    if (isValidateMedia(file)) {
      setFiles((prevState) => {
        return [
          ...prevState,
          {
            id: file.size.toString(),
            type: file.type,
            file,
            preview: URL.createObjectURL(file),
          },
        ];
      });
    }
  };

  const removeFile = (id) => {
    setFiles((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });
  };

  const isValidateMedia = (media) => {
    if (!mediaValidation(media, true)) {
      showSnack({
        type: ERROR_SK,
        message: translate('createPost.invalidMedia'),
      });
      return false;
    }
    if (checkMediaExistInList(media)) {
      showSnack({
        type: ERROR_SK,
        message: translate('createPost.duplicateFileRemoved'),
      });
      return false;
    }
    return true;
  };

  const checkFileLimit = () => {
    //check file limit
    if (files.length > maxFileCount) {
      setFiles((prevState) => {
        return prevState.slice(0, maxFileCount);
      });
      showSnack({
        type: ERROR_SK,
        message: translate('createPost.maxLengthValidation'),
      });
    }
  };

  const handleError = (error) => {
    showSnack({
      type: ERROR_SK,
      message: error,
    });
    setUploadedCount(0);
    setUploadedMedia([]);
    setApiStatus(Status.DEFAULT);
    setMediaUploadStatus(Status.DEFAULT);
    setLoading(false);
    createTempToken();
  };

  const showSnack = ({ message, type }) => {
    setSnackData({
      open: true,
      type,
      message,
    });
  };

  const handleSnackClose = () => {
    setSnackData((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  // eslint-disable-next-line no-unused-vars
  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onClose = () => {
    if (!loading) {
      _close();
      resetState();
    }
  };

  const resetState = () => {
    setAlbumName('');
    setAlbumDescription('');
    setFiles([]);
    setUploadedCount(0);
    setApiStatus(Status.DEFAULT);
    setMediaUploadStatus(Status.DEFAULT);
    setLoading(false);
    setUploadedMedia([]);
    createTempToken();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          id: 'create-album-dialog-container',
        }}
      >
        <div className="create-album-dialog">
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <HighlightOffIcon fontSize="large" />
          </IconButton>
          {loading && (
            <div id="loading-circle">
              <CircularProgress className="loading_spinner" />
            </div>
          )}
          <div className="create-album-container">
            <div className="create-album-title">
              {translate('userProfile.createAlbumTitle')}
            </div>
            <div className="create-album-form">
              <br />
              <CssTextField
                required
                fullWidth
                size="small"
                label={translate('userProfile.name')}
                value={albumName}
                onChange={(e) => {
                  setAlbumName(e.target.value);
                }}
              />
              <br />
              <br />
              <CssTextField
                fullWidth
                size="small"
                label={translate('userProfile.description')}
                value={albumDescription}
                onChange={(e) => {
                  setAlbumDescription(e.target.value);
                }}
              />
              <br />
              <br />
              <label htmlFor="album-file-input">
                <input
                  id="album-file-input"
                  type="file"
                  accept={['image/*', 'video/*']}
                  multiple
                  hidden
                  disabled={files.length > 0}
                  ref={(ref) => {
                    inputRef = ref;
                  }}
                  onChange={handleFileInputChange}
                />
                <div className="drop-area">
                  <div className="drop-zone-container">
                    <div className="drop-zone">
                      {files.length === 0 ? (
                        <div>
                          <img
                            id="upload-img"
                            alt="upload-img"
                            src={uploadIcon}
                          />
                          <div id="label">
                            {translate('userProfile.createAlbumFileInputLabel')}
                          </div>
                        </div>
                      ) : (
                        <div className="preview-section">
                          <Grid container spacing={1}>
                            {files.map((file, key) => {
                              return (
                                <Grid
                                  key={key}
                                  item
                                  xs={4}
                                  sm={3}
                                  md={3}
                                  lg={2}
                                >
                                  <div className="item">
                                    <CancelIcon
                                      onClick={(e) => {
                                        e.preventDefault();
                                        removeFile(file.id);
                                      }}
                                    />
                                    {file.type.includes(VIDEO_SK) ? (
                                      <video
                                        alt="previewvideo"
                                        className="preview-img"
                                        src={file.preview}
                                        autoPlay={false}
                                      />
                                    ) : (
                                      <img
                                        alt="previewimg"
                                        className="preview-img"
                                        src={file.preview}
                                      />
                                    )}
                                  </div>
                                </Grid>
                              );
                            })}
                            {files.length !== maxFileCount && (
                              <Grid item xs={4} sm={3} md={3} lg={2}>
                                <label htmlFor="add-photo-input">
                                  <input
                                    type="file"
                                    accept={['image/*', 'video/*']}
                                    id="add-photo-input"
                                    name="add-photo-input"
                                    ref={(ref) => {
                                      inputRef = ref;
                                    }}
                                    multiple
                                    hidden
                                    onChange={handleFileInputChange}
                                  />
                                  <div id="upload-palette">
                                    <img alt="addimg" src={addPhotoIcon} />
                                  </div>
                                </label>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      )}
                    </div>
                    <div id="message">
                      {`${translate(
                        'userProfile.albumFileCountLimitMessage'
                      )} ${maxFileCount}.`}
                    </div>
                  </div>
                </div>
              </label>
              <div className="footer">
                {isNonEmptyString(albumName) && files.length > 0 && !loading ? (
                  <Button
                    id="button"
                    variant="contained"
                    size="small"
                    onClick={createAlbum}
                  >
                    {translate('userProfile.createAlbum')}
                  </Button>
                ) : (
                  <ButtonOutlined id="button" variant="contained" size="small">
                    {translate('userProfile.createAlbum')}
                  </ButtonOutlined>
                )}
              </div>
            </div>
          </div>
        </div>
        <Snack
          open={snackData.open}
          type={snackData.type}
          message={snackData.message}
          onClose={handleSnackClose}
        />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute !important',
    right: '5px',
    top: 0,
    color: '#000 !important',
  },
}));

export default CreateAlbumModal;
