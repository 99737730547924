/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MdLocationOn } from "react-icons/md";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { translate } from "../../../../../../i18n";
import { Status } from "../../../../../../api";
import statesAndDivisions from "../../../../../../config/statesAndDivisions";
import { getBuildConfig } from "../../../../../../config/buildConfig";
import { fetchWeather } from "../../../../../../store/actions";

import { SmooveIcon, SmooveIcons, Spinner } from "../../../../../../common";
import { history } from "../../../../../../store";

import backgroundImage from "../../../../../../assets/images/weather_background.jpg";
import styles from "./Weather.module.scss";

const weatherIconBaseUrl = "https://openweathermap.org/img/wn/";
const baseAwsUrl = getBuildConfig().baseAwsUrl;

const Weather = ({
  fetchWeather: _fetchWeather,
  weatherStatus,
  weatherData,
  location,
}) => {
  const { cities } = statesAndDivisions;
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState("");
  const [bgImage, setBgImage] = useState(backgroundImage);
  const [geoApiStatus, setGeoApiStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    if (open) {
      getCurrentState();
    }
  }, [open]);

  useEffect(() => {
    if (city) {
      _fetchWeather(city);
    }
  }, [city]);

  const getCurrentState = () => {
    const { latitude, longitude } = location;
    if (latitude || longitude) {
      const geoApiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;

      fetch(geoApiUrl)
        .then((response) => response.json())
        .then((data) => {
          const currentState = data.principalSubdivision;
          cities.forEach((city) => {
            if (currentState.includes(city.state)) {
              setBgImage(city.backgroundImage);
              setCity(city);
            }
          });
        })
        .catch((error) => {
          setGeoApiStatus(Status.ERROR);
        });

    } else {
      setBgImage(cities[0].backgroundImage);
      setCity(cities[0]);
    }
  };

  const handleChange = (event) => {
    let city = event.target.value;
    setCity(city);
    setBgImage(city.backgroundImage);
  };

  const handleReload = () => {
    history.push("/");
    window.location.reload();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="for-shalgoers-item-wrapper">
      <Tooltip title="Weather" placement="left">
        <div role="button" className="ic-service-container" onClick={handleOpen}>
          <SmooveIcon name={`${SmooveIcons.IcWeather} ic-service`} />
        </div>
      </Tooltip>

      <Dialog
        id={styles["weather-dialog"]}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
      >
        <div className={styles["weather-content-inner-container"]}>
          {(weatherStatus !== Status.ERROR && geoApiStatus !== Status.ERROR) &&
            <img
              alt="img"
              src={`${baseAwsUrl}${bgImage}`}
              className={styles["weather-bg-img"]}
            />
          }
          <IconButton
            aria-label="close"
            className={weatherStatus === Status.ERROR || geoApiStatus === Status.ERROR ? styles["noconnection-close-button"] : styles["close-button"]}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {weatherStatus === Status.ERROR || geoApiStatus === Status.ERROR ? (
            <div className={styles["weather-content"]}>
              <div>
                <span className={styles["weather-title"]}>
                  <div className={styles["weather-table"]}>
                    {translate("shalgoerServices.weatherInfo")}
                  </div>
                </span>
              </div>
              <div className={styles["weather-unavailable-container"]}>
                <div className={styles["weather-unavailable"]}>
                  {translate("shalgoerServices.exchangeRateUnavailable")}
                </div>
                <div className={styles["unavailable-reload"]} onClick={handleReload}>
                  <strong>{translate("common.reload")}.</strong>
                </div>
              </div>
            </div>
          )
            : <div className={styles["weather-dialog"]}>
              <div className={styles["weather-left-side"]}>
                <div className={styles["weather-info-title"]}>
                  {translate("shalgoerServices.weatherInfo")}
                </div>
                {weatherStatus === Status.LOADING ? <div className={styles["weather-loading"]}><Spinner /></div> : weatherStatus === Status.SUCCESS ? (
                  <div className={styles["current-weather"]}>
                    <div>
                      <img
                        alt="img"
                        src={`${weatherIconBaseUrl}${weatherData.current.icon}@4x.png`}
                        className={styles["current-weather-icon"]}
                      />
                    </div>
                    <div id={styles["current-weather-detail"]}>
                      <div className={styles["current-weather-box"]}>
                        <div
                          className={styles["current-weather-desc"]}
                        >
                          {`${weatherData.current.desc}`}
                        </div>
                        <div
                          className={styles["current-weather-temp"]}
                        >
                          {`${Math.floor(weatherData.current.temp)}°C `}
                        </div>
                      </div>
                      <div className={styles["current-weather-date"]}>
                        {weatherData.current.date}
                      </div>
                      {city?.state && <div className={styles["current-state"]}>
                        <MdLocationOn className={styles["location-icon"]} />
                        <span>
                          {translate(
                            `statesAndDivisions.${city.state
                              ?.replaceAll(" ", "")
                              .toLowerCase()}`
                          )}
                          {translate("common.comma")}&nbsp;{translate(`common.myanmar`)}
                        </span>
                      </div>}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={styles["weather-right-side"]}>
                <div className={styles["forecasts"]}>
                  <div className={styles["right-side-top"]}>
                    <div id={styles["weather-howabout-title"]}>
                      <span>{translate("shalgoerServices.howAbout")}</span>
                    </div>
                    <div className={styles["weather-select-box"]}>
                      <FormControl
                        variant="outlined"
                        className={styles["form-control"]}
                      >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          className={styles["weather-select"]}
                          displayEmpty
                          value={city}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={{
                            classes: { paper: styles["menu-style"] },
                          }}
                        >
                          {_.map(cities, (city, key) => {
                            return (
                              <MenuItem key={key} value={city}>
                                <span>
                                  {translate(
                                    `statesAndDivisions.${city.state
                                      ?.replaceAll(" ", "")
                                      .toLowerCase()}`
                                  )}
                                </span>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className={styles["daily-forecast-container"]}>
                    {weatherData !== null
                      ? _.map(weatherData.daily, (day, key) => {
                        return (
                          <div
                            key={key}
                            className={styles["daily-forecast-item"]}
                          >
                            <div id={styles["day"]}>{day.dayName}</div>
                            <div id={styles["icon"]}>
                              <img
                                alt="img"
                                src={`${weatherIconBaseUrl}${day.icon}@2x.png`}
                              />
                            </div>
                            <div id={styles["temp"]}>{`${Math.floor(day.temp.min)} - ${Math.floor(
                              day.temp.max
                            )}°C`}</div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              </div>
            </div>}
        </div>
      </Dialog >
    </div >
  );
};

const mapStateToProps = ({ services, app }) => {
  const { weatherStatus, weatherData, weatherErrorMessage } = services;
  const { location } = app;
  return {
    weatherStatus,
    weatherData,
    weatherErrorMessage,
    location,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchWeather })
)(Weather);
