import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import {
  BLOG_SK,
  POST_SK,
  PUBLIC_SK,
  NONE_SK,
  ARTICLE_SK,
  ATTRACTION_SK,
  PLI_HOME_MODE_LK,
  PLI_HISTORY_MODE_LK,
  PLI_DETAIL_MODE_LK,
  PLI_PROFILE_MODE_LK,
} from '../../constants/stringConstants';
import { isNonEmptyString } from '../../utils';
import { api } from '../../api';
import HeaderSection from './HeaderSection';
import BodySection from './BodySection';
import FooterSection from './FooterSection';

const defaultProps = {
  mode: PLI_HOME_MODE_LK,
  baseMediaUrl: '',
  baseMediaThumbUrl: '',
  loggedInUserIdentifier: '',
  profileScreenIdentifier: '',
  deletePost: () => { },
  deleteBlog: () => { },
  detailModeOperations: {},
  updateStatusInList: () => { },
};

const PostListItem = ({
  /**
   * Mode tells in which screen PostListItem component is used.
   *
   * It could be
   * 1. Home screen
   * 2. History screen
   * 3. Post Detail screen aka CommentListScreen
   * 4. Friends Profile screen
   * 5. Business profile Screen
   */
  mode,
  /**
   * object containing the data regarding the post/article
   */
  item,
  /**
   * base media url, which need to be appended with images, to form complete url
   */
  baseMediaUrl,
  /**
   * base media url, which need to be appended with images, to form complete url
   */
  baseMediaThumbUrl,
  /**
   * Unique id of the current logged in user
   */
  loggedInUserIdentifier,
  /**
   * If PostListItem is being rendered in Friends/Business profile screen
   * the identifier that points to the profile in it.
   */
  profileScreenIdentifier,
  /**
   * Function, which dispatch an action, to delete the post
   */
  deletePost,
  /**
   * Function, which dispatch an action, to delete the blog
   */
  deleteBlog,
  /**
   * Update follow status for other post from same user
   */
  updateStatusInList,
  /**
   * Collection of functiuon
   */
  detailModeOperations,
  sharePostStatus,
  sharedPostIdentifier,
  reloadProfileScreen,
  showLoginPrompt,
  containerStyle,
  updateReaction: _updateReactionInApp,
  updateCommentCount: _updateCommentCountInApp,
  setPosts,
  savePostInBusiness
}) => {
  const classes = useStyles(); // eslint-disable-line no-unused-vars
  const {
    copyLinkUrl,
    type,
    identifier,
    isShared,
    createdAt,
    description: _description,
    visibility: _visibility,
    currentUserReaction: _currentUserReaction,
    totalShares: _totalShares,
    totalLikes: _totalLikes = 0,
    totalComments: _totalComments,
    locationAddress,
    userDetails,
  } = item;
  const {
    type: sharedType,
    visibility: sharedVisibility,
    identifier: parentPostIdentifier,
  } = item.parentDetails || {};
  let parentPostVisibility = sharedVisibility;
  if (sharedType === ARTICLE_SK || sharedType === ATTRACTION_SK) {
    parentPostVisibility = PUBLIC_SK; // Article is always public
  }
  // Local states
  const [followStatus, setFollowStatus] = useState({
    localValue: userDetails?.networkStatusWithCurrentUser?.followStatus, // Value which gets change as user interact with PostListItem
    backupValue: userDetails?.networkStatusWithCurrentUser?.followStatus, // Value use to check for latest value from redux
  });
  const [sharesCount, setSharesCount] = useState({
    localValue: _totalShares,
    backupValue: _totalShares,
  });
  const [commentsCount, setCommentsCount] = useState({
    localValue: _totalComments,
    backupValue: _totalComments,
  });
  const [likesCount, setLikesCount] = useState({
    localValue: _totalLikes,
    backupValue: _totalLikes,
  });
  const [reaction, setReaction] = useState({
    localValue: _currentUserReaction,
    loading: false, // If api is being hit, set it to true
    backupValue: _currentUserReaction,
  });
  const [visibility, setVisibility] = useState({
    localValue: _visibility,
    backupValue: _visibility,
  });
  const [description, setDescription] = useState({
    localValue: _description,
    backupValue: _description,
  });

  useEffect(() => {
    if (type === POST_SK || type === BLOG_SK) {
      const {
        visibility: newVisibilityValue,
        description: newDescriptionValue,
        currentUserReaction: newCurrentUserReaction,
        totalShares: newTotalSharesValue,
        totalLikes: newTotalLikesValue,
        totalComments: newTotalCommentsValue,
        userDetails: {
          networkStatusWithCurrentUser: {
            followStatus: newFollowStatusValue,
          } = {},
        } = {},
      } = item;
      if (followStatus.backupValue !== newFollowStatusValue) {
        setFollowStatus({
          localValue: newFollowStatusValue,
          backupValue: newFollowStatusValue,
        });
      }
      if (likesCount.backupValue !== newTotalLikesValue) {
        setLikesCount({
          localValue: newTotalLikesValue,
          backupValue: newTotalLikesValue,
        });
      }
      if (sharesCount.backupValue !== newTotalSharesValue) {
        setSharesCount({
          localValue: newTotalSharesValue,
          backupValue: newTotalSharesValue,
        });
      }
      if (commentsCount.backupValue !== newTotalCommentsValue) {
        setCommentsCount({
          localValue: newTotalCommentsValue,
          backupValue: newTotalCommentsValue,
        });
      }
      if (reaction.backupValue !== newCurrentUserReaction) {
        setReaction({
          localValue: newCurrentUserReaction,
          loading: false,
          backupValue: newCurrentUserReaction,
        });
      }
      if (visibility.backupValue !== newVisibilityValue) {
        setVisibility({
          localValue: newVisibilityValue,
          backupValue: newVisibilityValue,
        });
      }
      if (description.backupValue !== newDescriptionValue) {
        setDescription({
          localValue: newDescriptionValue,
          backupValue: newDescriptionValue,
        });
      }
    }
  }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPostOnlyContainsDescription = () =>
    !(item.isShared || item.mediaUrl.length > 0);

  const changeCommentsCount = (newValue) => {
    _updateCommentCountInApp(identifier, newValue);
    setCommentsCount((prevState) => ({
      ...prevState,
      localValue: newValue,
    }));
  };

  const updateDescriptionAndVisibility = (newDescription, newVisibility) => {
    setDescription((prevState) => ({
      ...prevState,
      localValue: newDescription,
    }));
    setVisibility((prevState) => ({
      ...prevState,
      localValue: newVisibility,
    }));
    if (mode === PLI_DETAIL_MODE_LK) {
      detailModeOperations.updateDescriptionAndVisibilityInPreviousScreen?.(
        newDescription,
        newVisibility
      );
      detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
        ...prevState,
        description: newDescription,
        visibility: newVisibility,
      }));
    } else if (mode === PLI_HISTORY_MODE_LK) {
      detailModeOperations.setHomeCacheDirty?.();
    }
  };

  const updateReaction = (currentSelectedReaction) => {
    const { localValue: previousUserReaction } = reaction;

    if (!isNonEmptyString(loggedInUserIdentifier)) {
      showLoginPrompt();
      return;
    }

    // update the reaction if it is changed
    if (currentSelectedReaction !== previousUserReaction) {
      setReaction((prevState) => ({
        ...prevState,
        loading: true,
        localValue: currentSelectedReaction,
      }));
      if (mode === PLI_DETAIL_MODE_LK) {
        detailModeOperations.updateReactionInPreviousScreen?.(
          currentSelectedReaction
        );
        detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
          ...prevState,
          currentUserReaction: currentSelectedReaction,
        }));
      }
    }

    if (currentSelectedReaction === NONE_SK) {
      _updateReactionInApp(
        identifier,
        currentSelectedReaction,
        likesCount.localValue - 1
      );
      setLikesCount((prevState) => ({
        ...prevState,
        localValue: prevState.localValue - 1,
      }));
      if (mode === PLI_DETAIL_MODE_LK) {
        detailModeOperations.updateLikesCountInPreviousScreen?.(-1);
        detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
          ...prevState,
          totalLikes: prevState.totalLikes - 1,
        }));
      }
    } else if (previousUserReaction === NONE_SK) {
      _updateReactionInApp(
        identifier,
        currentSelectedReaction,
        likesCount.localValue + 1
      );
      setLikesCount((prevState) => ({
        ...prevState,
        localValue: prevState.localValue + 1,
      }));
      if (mode === PLI_DETAIL_MODE_LK) {
        detailModeOperations.updateLikesCountInPreviousScreen?.(+1);
        detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
          ...prevState,
          totalLikes: prevState.totalLikes + 1,
        }));
      }
    } else {
      _updateReactionInApp(
        identifier,
        currentSelectedReaction,
        likesCount.localValue
      );
    }
    /**
     * @Question Why api is not called from redux-saga & used directly here?
     *
     * @Explanation Not every thing needed to be controlled via redux & redux-saga,
     * this api response, is used no where except this component.
     *
     * Prefer Local state first, if the response won't be shared across components.
     */
    api
      .updateReaction({
        type: POST_SK,
        reaction: currentSelectedReaction,
        identifier,
      })
      .then(() => {
        setReaction((prevState) => ({
          ...prevState,
          loading: false,
        }));
      })
      .catch(() => {
        setReaction((prevState) => ({
          ...prevState,
          loading: false,
          localValue: previousUserReaction,
        }));
        if (mode === PLI_DETAIL_MODE_LK) {
          detailModeOperations.updateReactionInPreviousScreen?.(
            previousUserReaction
          );
          detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
            ...prevState,
            currentUserReaction: previousUserReaction,
          }));
        }
        if (currentSelectedReaction === NONE_SK) {
          setLikesCount((prevState) => ({
            ...prevState,
            localValue: prevState.localValue + 1,
          }));
          if (mode === PLI_DETAIL_MODE_LK) {
            detailModeOperations.updateLikesCountInPreviousScreen?.(1);
            detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
              ...prevState,
              totalLikes: prevState.totalLikes + 1,
            }));
          }
        } else if (previousUserReaction === NONE_SK) {
          setLikesCount((prevState) => ({
            ...prevState,
            localValue: prevState.localValue - 1,
          }));
          if (mode === PLI_DETAIL_MODE_LK) {
            detailModeOperations.updateLikesCountInPreviousScreen?.(-1);
            detailModeOperations.updatePostInCommentScreen?.((prevState) => ({
              ...prevState,
              totalLikes: prevState.totalLikes - 1,
            }));
          }
        }
      });
    if (mode === PLI_HISTORY_MODE_LK) {
      detailModeOperations.setHomeCacheDirty?.();
    }
  };

  /**
   * If PostListItem is opened in CommentListScreen, that means
   * it is in detailMode, so if reaction is updated in CommentListScreen
   * it should reflect in PostListItem instance present in Home Screen,
   * this callback will help achieve that
   */
  const updateReactionInPreviousScreen = (currentSelectedReaction) => {
    setReaction((prevState) => ({
      ...prevState,
      localValue: currentSelectedReaction,
    }));
  };

  /**
   * If PostListItem is opened in CommentListScreen, that means
   * it is in detailMode, so if reaction is updated in CommentListScreen
   * it should change likeCount in PostListItem instance present in Home Screen,
   * this callback will help achieve that
   *
   * @param {number} changeLikeCountByFactor - can be either 1 or -1
   */
  const updateLikesCountInPreviousScreen = (changeLikeCountByFactor) => {
    setLikesCount((prevState) => ({
      ...prevState,
      localValue: prevState.localValue + changeLikeCountByFactor,
    }));
    if (mode === PLI_HISTORY_MODE_LK) {
      detailModeOperations.setHomeCacheDirty?.();
    }
  };

  const updateSharesCountInPreviousScreen = () => {
    if (mode === PLI_PROFILE_MODE_LK) {
      setSharesCount((prevState) => ({
        ...prevState,
        localValue: prevState.localValue + 1,
      }));
    }
  };

  return (
    <Card className={containerStyle}>
      <HeaderSection
        // url={url}
        type={type}
        mode={mode}
        time={createdAt}
        locationAddress={locationAddress}
        identifier={identifier}
        userDetails={userDetails}
        visibility={visibility.localValue}
        description={description.localValue}
        isPostOnlyContainsDescription={isPostOnlyContainsDescription()}
        loggedInUserIdentifier={loggedInUserIdentifier}
        profileScreenIdentifier={profileScreenIdentifier}
        profileImgUrl={
          userDetails?.profileImg?.isThumbGenerated
            ? userDetails?.profileImg?.url?.thumbUrl
            : userDetails?.profileImg?.url?.originalUrl
        }
        deletePost={deletePost}
        deleteBlog={deleteBlog}
        updateStatusInList={updateStatusInList}
        //followStatus={followStatus.localValue}
        //changeFollowStatus={changeFollowStatus}
        updateDescriptionAndVisibility={updateDescriptionAndVisibility}
        showLoginPrompt={showLoginPrompt}
        reloadProfileScreen={reloadProfileScreen}
      />
      <BodySection
        item={{
          ...item,
          description: description.localValue,
          visibility: visibility.localValue,
        }}
        loggedInUserIdentifier={loggedInUserIdentifier}
        postOwnerIdentifier={userDetails?.identifier}
        profileScreenIdentifier={profileScreenIdentifier}
        baseMediaUrl={baseMediaUrl}
        baseMediaThumbUrl={baseMediaThumbUrl}
        mode={mode}
        changeCommentsCount={changeCommentsCount}
        detailModeOperations={{
          ...(mode === PLI_HISTORY_MODE_LK && { deletePost }),
          ...(mode === PLI_PROFILE_MODE_LK && {
            updateFollowStatusInPreviousScreen:
              detailModeOperations.updateFollowStatusInPreviousScreen,
          }),
          updateReactionInPreviousScreen,
          updateLikesCountInPreviousScreen,
          updateDescriptionAndVisibilityInPreviousScreen:
            updateDescriptionAndVisibility,
        }}
        updateStatusInList={updateStatusInList}
        showLoginPrompt={showLoginPrompt}
        reloadProfileScreen={reloadProfileScreen}
      />
      <FooterSection
        mode={mode}
        isShared={isShared}
        item={item}
        sharedType={sharedType}
        childPostVisibility={visibility.localValue}
        parentPostVisibility={parentPostVisibility}
        loggedIn={isNonEmptyString(loggedInUserIdentifier)}
        loggedInUserIdentifier={loggedInUserIdentifier}
        identifier={identifier}
        parentPostIdentifier={parentPostIdentifier}
        url={copyLinkUrl}
        likesCount={likesCount.localValue}
        sharesCount={sharesCount.localValue}
        commentsCount={commentsCount.localValue}
        reaction={reaction.localValue}
        reactionLoading={reaction.loading}
        updateReaction={updateReaction}
        incrementShareCount={updateSharesCountInPreviousScreen}
        changeCommentsCount={changeCommentsCount}
        addCommentInCommentScreen={
          mode === PLI_DETAIL_MODE_LK &&
          detailModeOperations.addCommentInCommentScreen
        }
        historyCommentLogic={detailModeOperations.setHomeCacheDirty}
        postOwnerIdentifier={userDetails?.identifier}
        showLoginPrompt={showLoginPrompt}
        reloadProfileScreen={reloadProfileScreen}
        setPosts={setPosts}
        savePostInBusiness={savePostInBusiness}
      />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '10%',
    paddingRight: '10%',
    marginBottom: theme.spacing(3),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {},
}));

PostListItem.defaultProps = defaultProps;

export default PostListItem;
